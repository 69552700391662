//copy this to create a new all world template.  database eventually.


var assessmentMixin  = {
    methods: {
      selectAssessmentInfo: function (regionName,worldName) {
        var worldsArrays={
            SAWDC:SAWDC_Quiz_Object,
            CAW:CAW_Quiz_Object,
            WAW:WAW_Form_Object,
            NAW:NAW_Quiz_Object,
            EAW:EAW_Quiz_Object,
            NCTEJW: NCTEJW_Quiz_Object,
  
          }
          return worldsArrays[regionName][worldName]
      },
      selectAssessmentRegionInfo: function (regionName) {
        var worldsArrays={
            SAWDC:SAWDC_Quiz_Object,
            CAW:CAW_Quiz_Object,
            WAW:WAW_Form_Object,
            NAW:NAW_Quiz_Object,
            EAW:EAW_Quiz_Object,
            NCTEJW: NCTEJW_Quiz_Object,

  
          }
          return worldsArrays[regionName]
      }
    }
  };
  export default assessmentMixin



  var question1text="What was one career that you learned about that is appealing to you?";
  var question1index=1;
  //["Agricultural Equipment Operators","Environmental Engineers","Biological Technicians"]),
 var question2text="Which employer do you want to learn more about?";
 var question2index=2;

 var question3text="Are you interested in pursuing a career in the [] world?";
 var question3index=3;
var question3Options=[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}];
 var question4text="Is there anything else you want to tell us about your experience or about what you learned?";
 var question4index=4;

 class Question{
  constructor(questionText,questionIndex,questionOptions) {
    this.questionText = questionText
    this.questionindex = questionIndex;
    this.questionAnswer = "";
    this.questionOptions=questionOptions;
    this.questionSelect=null;

  }
}
  /*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var SAWDC_Quiz_Object={ 
    aerospace_and_aviation:{
      question1:new Question(
        question1text,
        question1index,
         [
          "Avionics Mechanic",
          "A&P Mechanic",
          " Aerospace Engineer",
          " A&P Technician",
          " Shipping and Receiving Handler",
          "Quality Control Inspector",
          "Industrial Engineers",
          "Software QA Analysts and Testers",
          "Commercial Pilots",
          "Machinists"
         ]),
       question2:new Question(
        question2text,
         question2index,
         ["Airbus",
          "ST Engineering",
          "Coastal Alabama Community College",
          "None"]),
       
       question3:new Question(
        question3text.replace("[]", "Aerospace & Aviation"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )

    },
    construction:{
      question1:new Question(
        question1text,
        question1index,
         [
          "Electrician",
          "Heating/Air/ Refrigeration Mechanics",
          "Plumbers, Pipefitters, and Steamfitters",
          "Construction Laborers",
          "Carpenters",
          "Construction Supervisor",
          "Construction Managers",
          "Painter",
          "Equipment Operator",
          "Administrative Assistant"
         ]),
       question2:new Question(
        question2text,
         question2index,
         ["McAbee Construction",
          "Performance Contractors, Inc",
          "Turner Industries Group",
          "None"          
           

         ]),
       
       question3:new Question(
        question3text.replace("[]", "Construction"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
     
    },
    energy:{
      question1:new Question(
        question1text,
        question1index,
         [
          "Electrical Power Line Installers",
          "Power Plant Operator",
          "Water Plant Operator",
          "Administrative Assistant",
          "Customer Service Representatives",
          "Production Manager",
          "Maintenance Manager",
          "Control Valve Mechanic",
          "Industrial Machinery Mechanic",
          "General & Operations Managers"
         ]),
       question2:new Question(
        question2text,
         question2index,
         ["Alabama Power",
          "Daphne Utilities",
          "None"
          
           

         ]),
       
       question3:new Question(
        question3text.replace("[]", "Energy"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    engineering_and_design:{
      question1:new Question(
        question1text,
        question1index,
         [
          "Civil Engineers",
          "Electrical Engineers",
          "Industrial Engineers",
          "Mechanical Engineers",
          "Aerospace Engineer",
          "Visual Merchandiser",
          "Graphic Designer",
          "Marine Engineer",
          "Floral Designers"
         ]),
       question2:new Question(
        question2text,
         question2index,
         ["Alabama Power/Southern Company",
          "Hargrove Engineering",
          "None"
          
           

         ]),
       
       question3:new Question(
        question3text.replace("[]", "Engineering and Design"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    healthcare:{
      question1:new Question(
        question1text,
        question1index,
         [ 
          "Registered Nurse",
          "Personal Care Aides",
          "Nursing Assistant",
          "Medical Assistants",
          "Charge Nurse",
          "Office Assistant",
          "Medical Secretary",
          "Home Health Aide",
          "Childcare Worker",
          "Administrative Assistant"
         ]),
       question2:new Question(
        question2text,
         question2index,
         ["Mobile Public Schools",
         "None"

           

         ]),
       
       question3:new Question(
        question3text.replace("[]", "Healthcare"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    hospitality_and_tourism:{
      question1:new Question(
        question1text,
        question1index,
         [ "Restaurant Manager",
          "Chefs & Head Cooks",
          "Hotel General Manager",]),
       question2:new Question(
        question2text,
         question2index,
         [
          "Spectrum Resorts",
          "None"


         ]),
       
       question3:new Question(
        question3text.replace("[]", "Hospitality and Tourism"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    information_technology:{
      question1:new Question(
        question1text,
        question1index,
         [
          "Computer User Support Specialist",
          "Software Quality Engineer",
          "Computer Systems Analysts",
          "Computer Programmer",
          "IT Director",
          "General & Operations Managers",
          "Network Administrator",
          "Sales Representative",
          "Customer Service Representatives"
         ]),
       question2:new Question(
        question2text,
         question2index,
         [
          "Provalus",
          "None"


         ]),
       
       question3:new Question(
        question3text.replace("[]", "Information Technology"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    manufacturing:{
      question1:new Question(
        question1text,
        question1index,
         [
          "Precision Machinist",
          "Welders",
          "Shipping and Receiving Materials Handler",
          "Team Assemblers",
          "Production Workers",
          "Production Manager",
          "Industrial Machinery Mechanics",
          "Paper Machine Operator",
          "Quality Control Inspector",
          "Chemical Operator"
         ]),
       question2:new Question(
        question2text,
         question2index,
         [
          "AM/NS Calvert",
          "BASF",
          "Evonik",
          "Westervelt",
          /*"Weyerhauser",
          "Chevron",*/
          "None"
          

         ]),
       
       question3:new Question(
        question3text.replace("[]", "Manufacturing"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    public_service:{
      question1:new Question(
        question1text,
        question1index,
         [
          "Patrol Officers",
          "Firefighters",
          "Corrections Officer",
          "Administrative Assistant",
          "Highway Maintenance Worker",
          "Office Clerk",
          "City Clerk",
          "Dispatchers",
          "Case Manager",
          "Recreation Workers"
         ]),
       question2:new Question(
        question2text,
         question2index,
         [
          "Mobile Fire and Rescue",
          "None"


         ]),
       
       question3:new Question(
        question3text.replace("[]", "Public Service"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    safety:{
      question1:new Question(
        question1text,
        question1index,
         ["Occupational Safety & Health",
          "Construction Safety Specialist",
          "Industrial Hygenist",
         ]),
       question2:new Question(
        question2text,
         question2index,
         [
           "None",

         ]),
       
       question3:new Question(
        question3text.replace("[]", "Safety"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    shipbuilding_and_repair:{
      question1:new Question(
        question1text,
        question1index,
         [
          "Marine Engineer",
          "Welder",
          "Plumbers, Pipefitters, and Steamfitters",
          "Team Assemblers",
          "Electrician",
          "Production Manager",
          "Precision Machinist",
          "Painter",
          "Fabricator",
          "Layout Workers, Metal and Plastic"
         ]),
       question2:new Question(
        question2text,
         question2index,
         [
          "Cooper Group",
          "Austal",
          "Ingalls",
          "None"
          

         ]),
       
       question3:new Question(
        question3text.replace("[]", "Shipbuilding & Repair"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    transportation_distribution_and_logistics:{
      question1:new Question(
        question1text,
        question1index,
         [
          "Truck Drivers",
          "Bus Driver",
          "Mail Carrier",
          "Delivery Driver",
          "Forklift Driver",
          "Cargo Supervisor",
          "Stocker",
          "Diesel Mechanic",
          "Cargo & Freight Agent"
         ]),
       question2:new Question(
        question2text,
         question2index,
         [
          "Alabama Trucking Association",
          "Cooper Group",
          "None"
          

         ]),
       
       question3:new Question(
        question3text.replace("[]", "Transportation, Distribution & Logistics"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
  
   
  }
  
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var NAW_Quiz_Object={ 
    agriculture_food_and_natural_resources:{
      question1:new Question(
        question1text,
        question1index,
         ["Farm Manager",
          "Environmental Engineer",
          "Forester"
         ]),
       question2:new Question(
        question2text,
         question2index,
         ["None"]),
       
       question3:new Question(
        question3text.replace("[]", "Agriculture, Food, & Natural Resources"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )

    },
    architecture_and_construction:{
      question1:new Question(
        question1text,
        question1index,
         ["Architect",
         "Electrician",
         "Civil Engineer"]),
       question2:new Question(
        question2text,
         question2index,
         ["None"]),
       
       question3:new Question(
        question3text.replace("[]", "Architecture & Construction"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )

    },
    education_and_training:{
      question1:new Question(
        question1text,
        question1index,
         ["Elementary School Teachers",
          "Secondary School Teachers",
          "Guidance Counselor"
         ]),
       question2:new Question(
        question2text,
         question2index,
         ["Athens State University",
          "Bevill State Community College",
          "Calhoun Community College",
          "Drake State Technical & Community College",
          "None"]),
       
       question3:new Question(
        question3text.replace("[]", "Education & Training"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )

    },
    energy_and_utilities:{
      question1:new Question(
        question1text,
        question1index,
         ["Earth Driller",
          "Electrical Power Line Installers",
          "Nuclear Engineer"
         ]),
       question2:new Question(
        question2text,
         question2index,
         ["None"]),
       
       question3:new Question(
        question3text.replace("[]", "Energy & Utilities"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )

    },
    healthcare:{
      question1:new Question(
        question1text,
        question1index,
         ["Dental Hygienist",
          "Registered Nurse",
          "Nursing Assistant"
         ]),
       question2:new Question(
        question2text,
         question2index,
         ["None"]),
       
       question3:new Question(
        question3text.replace("[]", "Healthcare"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )

    },
    hospitality_and_tourism:{
      question1:new Question(
        question1text,
        question1index,
         ["Food Service Managers",
          "Chefs/Head Cooks",
          "Lodging Managers"
         ]),
       question2:new Question(
        question2text,
         question2index,
         ["None"]),
       
       question3:new Question(
        question3text.replace("[]", "Hospitality & Tourism"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )

    },
    modern_manufacturing:{
      question1:new Question(
        question1text,
        question1index,
         ["Team Assemblers",
          "Maintenance and Repair Workers",
          "Supervisors of Production"
         ]),
       question2:new Question(
        question2text,
         question2index,
         ["None"]),
       
       question3:new Question(
        question3text.replace("[]", "Modern Manufacturing"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )

    },
    modern_manufacturing_auto:{
      question1:new Question(
        question1text,
        question1index,
         ["Industrial Machinery Mechanic",
          "Welding",
          "Precision Machinist"
         ]),
       question2:new Question(
        question2text,
         question2index,
         ["Mazda Toyota Manufacturing",
          "None"]),
       
       question3:new Question(
        question3text.replace("[]", "Modern Manufacturing-Automotive"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )

    },
    law_public_safety_and_corrections:{
      question1:new Question(
        question1text,
        question1index,
         ["Emergency Management Directors",
          "Firefighters",
          "Patrol / Police Officer"
         ]),
       question2:new Question(
        question2text,
         question2index,
         ["None"]),
       
       question3:new Question(
        question3text.replace("[]", "Law, Public Safety, & Corrections"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )

    },
    transportation_distribution_and_logistics:{
      question1:new Question(
        question1text,
        question1index,
         ["Automotive Service Technicians",
          "CDL Drivers",
          "Diesel Mechanic"
         ]),
       question2:new Question(
        question2text,
         question2index,
         ["None"]),
       
       question3:new Question(
        question3text.replace("[]", "Transportation, Distribution & Logistics"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )

    },
    aerospace:{
      question1:new Question(
        question1text,
        question1index,
         ["Aerospace Engineering & Operations Technicians",
          "Avionics Mechanic",
          "A&P Mechanic"
         ]),
       question2:new Question(
        question2text,
         question2index,
         ["None"]),
       
       question3:new Question(
        question3text.replace("[]", "Aerospace"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )

    },
    business_and_finance:{
      question1:new Question(
        question1text,
        question1index,
         ["Investment Fund Managers",
          "Accountants & Auditors",
          "Financial Analysts"
         ]),
       question2:new Question(
        question2text,
         question2index,
         ["None"]),
       
       question3:new Question(
        question3text.replace("[]", "Business & Finance"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )

    },
    communications_media_and_marketing:{
      question1:new Question(
        question1text,
        question1index,
         ["Advertising Director",
          "Graphic Designer",
          "Marketing Manager"
         ]),
       question2:new Question(
        question2text,
         question2index,
         ["None"]),
       
       question3:new Question(
        question3text.replace("[]", "Communications, Media & Marketing"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )

    },
   cybersecurity_and_information_technology:{
    question1:new Question(
      question1text,
      question1index,
       ["Database Administrator",
        "Software Developer",
        "Information Security Analyst"
       ]),
     question2:new Question(
      question2text,
       question2index,
       ["None"]),
     
     question3:new Question(
      question3text.replace("[]", "Cybersecurity & Information Technology"),
      question3index,
       question3Options),
     question4:new Question(
      question4text,
      question4index,
        []
      )

  },
   
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var EAW_Quiz_Object={ 
    agriculture_and_natural_resources:{
      question1:new Question(
        question1text,
        question1index,
        ["Agronomist",
        "Environmental Engineer",
        "Forester",
        "Logging Equipment Operator",
        "Poultry Service Technician",
        "Wildlife Biologist"]           ),
       question2:new Question(
        question2text,
         question2index,
         [
          "Ag-Pro",
          "Alabama Cooperative Extension Service",
          "Alabama Forestry Commission",
          "Alabama Green Industry",
          "Alabama Poultry and Egg Association",
          "Alfa Farmers",
          "Calhoun County Conservation District",
          "Choccolocco Creek Watershed",
          "USDA",
          "ForestryWorks",
          "None"
          ]),
       
       question3:new Question(
        question3text.replace("[]", "Agriculture & Natural Resources"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    automotive_and_manufacturing:{
      question1:new Question(
        question1text,
        question1index,
        ["Cabinetmakers",
        "CNC Operator",
        "Industrial Machinery Mechanic",
        "Precision Machinist",
        "Quality Control Specialist",
        "Welding",
        ]           ),
       question2:new Question(
        question2text,
         question2index,
         [
          "Anniston Army Depot",
          "Bridgewater Interiors",
          "Honda Manufacturing of Alabama",
          "Kronospan",
          "KTH",
          "M&H Valve",
          "New Flyer of America",
          "AIDT",
          "Southwire",
          "Steel Fab",
          "Tape Craft",
          "Tyler Union",
          "Wellborn Cabinet",
          
          ]),
       
       question3:new Question(
        question3text.replace("[]", "Automotive & Manufacturing"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    communications_media_and_marketing:{
      question1:new Question(
        question1text,
        question1index,
        [
          "Advertising Director",
          "Graphic Designer",
          "Market Research Analyst",
          "Public Relations Specialist",
          "Marketing Manager",
          "Art Director",
          ]          ),
       question2:new Question(
        question2text,
         question2index,
         [
          "Appalachian Digital Media",
          "City of Oxford",
          "Gadsden City Schools",
          "Oxford Performing Arts Center",
          "Oxford Public Library",
          "Spectrum Reach",
          "The Chamber, Gadsden and Etowah County",
          "Venture Marketing Group",
          
         ]),
       
       question3:new Question(
        question3text.replace("[]", "Communication, Media & Marketing"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    construction:{
      question1:new Question(
        question1text,
        question1index,
        [
          "Carpenters",
          "Construction and Building Inspectors",
          "Construction Management",
          "Electrician",
          "HVACR",
          "Ironworker"
          
          ]          ),
       question2:new Question(
        question2text,
         question2index,
         [
          "Alabama Construction Recruitment Institute",
          "Anniston Water Works & Sewer Board",
          "City of Oxford Building Department",
          "Hale Building Materials",
          "Hurst Construction",
          "Industrial Rental",
          "Iron Workers Local 92",
          "IRONWORKER SKILLS INSTITUTE",
          "Littleton Electric Service",
          "Ra-Lin and Associates",
          "Southland Machinery",
          "Thompson CAT",
          "Webb Concrete & Building Materials",
          
          ]),
       
       question3:new Question(
        question3text.replace("[]", "Construction"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    energy_and_utilities:{
      question1:new Question(
        question1text,
        question1index,
        [
          "Earth Driller",
          "Electrical Power Line Installers",
          "Nuclear Engineer",
          "Environmental Engineer Technician",
          "Geoscientist",
          "Water Plant Operator",
          
          ]          ),
       question2:new Question(
        question2text,
         question2index,
         [
          "Alabama Power Company",
          "Sparklight",
          "Spire",
          
          ]),
       
       question3:new Question(
        question3text.replace("[]", "Energy & Utilities"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    engineering:{
      question1:new Question(
        question1text,
        question1index,
        [
          "Engineering",
          "Biosystems Engineers",
          "Chemical Engineers",
          "Civil Engineers",
          "Electrical Engineers",
          "Industrial Engineers",
          "Mechanical Engineers",
          
          ]          ),
       question2:new Question(
        question2text,
         question2index,
         [
          "Alabama Technology Network",
          "Eastman",
          "Elbit Systems of America",
          "Jacksonville State University",
          "Transglobal",
          
          ]),
       
       question3:new Question(
        question3text.replace("[]", "Engineering & Design"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    healthcare:{
      question1:new Question(
        question1text,
        question1index,
        [
          "Dental Hygienist",
          "Diagnostic Sonography",
          "Nursing Assistant",
          "Phlebotomy",
          "Physical Therapy Assistant",
          "Registered Nurse",
          
          
          
          ]          ),
       question2:new Question(
        question2text,
         question2index,
         [
          "Alabama Department of Rehabilitation Services",
          "Coosa Valley Medical Center",
          "Designer Smiles",
          "Gadsden Regional Medical Center",
          "Jacksonville Health and Rehabilitation",
          "Regional Medical Center",
          
          ]),
       
       question3:new Question(
        question3text.replace("[]", "Healthcare"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    information_technology:{
      question1:new Question(
        question1text,
        question1index,
        [
          "Computer User Support Specialist",
          "Database Administrator",
          "Information Security Analyst",
          "IT Project Manager",
          "Software Programmer",
          "Web Developer",
          
          ]          ),
       question2:new Question(
        question2text,
         question2index,
         ["None"]),
       
       question3:new Question(
        question3text.replace("[]", "Information Technology"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    public_safety:{
      question1:new Question(
        question1text,
        question1index,
        [
          "Criminal Investigator",
          "Dispatchers",
          "Emergency Management Directors",
          "Emergency Medical Technician",
          "Firefighters",
          "Patrol / Police Officer",
          
          
          
          ]          ),
       question2:new Question(
        question2text,
         question2index,
         [
          "Alabama National Guard",
          "Anniston Fire Department",
          "Calhoun County Emergency Management Agency",
          "Calhoun County Sheriff’s Office",
          "Oxford Fire Department",
          "Oxford Police Department",
          "Oxford Health Care Authority",
          "Jacksonville Fire Department",
          "911 Calhoun County",
          
          ]),
       
       question3:new Question(
        question3text.replace("[]", "Public Safety"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
   
    transportation_distribution_and_logistics:{
      question1:new Question(
        question1text,
        question1index,
        [
          "Automotive Service Technicians",
          "CDL Drivers",
          "Diesel Mechanic",
          "Dispatchers",
          "Logistics Engineers",
          "Warehousing",
          
          ]          ),
       question2:new Question(
        question2text,
         question2index,
         [
          "B.R. Williams",
          "City of Oxford",
          "Gadsden State Community College",
          "New Flyer of America",
          "Wellborn Cabinet",
          
          ]),
       
       question3:new Question(
        question3text.replace("[]", "Transportation, Distribution & Logistics"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
   
   
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  
  
  var WAW_Form_Object={ 

    agriculture:{
         question1:new Question(
          question1text,
          question1index,
           ["Agricultural Engineer","Agronomist","Forester"]),
         question2:new Question(
          question2text,
           question2index,
           ["Natural Resource Conservation Service","None"]),
         
         question3:new Question(
          question3text.replace("[]", "Agriculture"),
          question3index,
           question3Options),
         question4:new Question(
          question4text,
          question4index,
            []
          )


    },
    cosmetology:{
      question1:new Question(
        question1text,
        question1index,
         ["Esthetician",
          "Hairstylist",
          "Massage Therapist"
          ]),
       question2:new Question(
        question2text,
         question2index,
         ["None"]),
       
       question3:new Question(
        question3text.replace("[]", "Cosmetology"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    construction:{
      question1:new Question(
        question1text,
        question1index,
         ["Carpenters",
          "Electrician",
          "HVAC Mechanic",
          ]),
       question2:new Question(
        question2text,
         question2index,
         ["Dixie Mechanical, Inc.",
          "Harrison Construction",
          "Jolly Heating and Air Conditioning, Inc.",
          "McAbee Construction, Inc.",
          "Premier Service Company, INC",
          "The Builders Group",
          "WAR Construction, Inc",
          "The CAT Rental Store - Thompson Tractor"
          ]),
       
       question3:new Question(
        question3text.replace("[]", "Construction"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    education:{
      question1:new Question(
        question1text,
        question1index,
         ["Elementary School Teachers",
          "Psychologist",
          "Guidance Counselor",
          ]),
       question2:new Question(
        question2text,
         question2index,
         ["None"]),
       
       question3:new Question(
        question3text.replace("[]", "Education"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    energy_and_utilities:{
      question1:new Question(
        question1text,
        question1index,
         ["Water Treatment Plant Operator",
          "Line Installation & Maintenance",
          "Refinery Operator",
          ]),
       question2:new Question(
        question2text,
         question2index,
         ["Alabama Power",
         "Hunt Refining Company",
         "Spore",
         "None"]),
       
       question3:new Question(
        question3text.replace("[]", "Energy and Utilities"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
  
    engineering_and_environment:{
      question1:new Question(
        question1text,
        question1index,
         ["Aerospace Engineer",
          "Civil Engineer",
          "Mechanical Engineer",
          ]),
       question2:new Question(
        question2text,
         question2index,
         ["Alabama Power",
         "Mercedes-Benz U.S. International, Inc.",
         "Nucor Steel Tuscaloosa",
         "None"]),
       
       question3:new Question(
        question3text.replace("[]", "Engineering and Environment"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    healthcare:{
      question1:new Question(
        question1text,
        question1index,
         ["Registered Nurse",
          "Dental Hygienist",
          "Paramedic",
          ]),
       question2:new Question(
        question2text,
         question2index,
         ["DCH","Tuscaloosa VA Medical Center","None"]
         ),
       
       question3:new Question(
        question3text.replace("[]", "Healthcare"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    hospitality_and_tourism:{
      question1:new Question(
        question1text,
        question1index,
         ["Chef/Head Cook",
          "Food Service Manager",
          "Park Ranger",
          ]),
       question2:new Question(
        question2text,
         question2index,
         ["Hampton Inns of Tuscaloosa","None"]),
       
       question3:new Question(
        question3text.replace("[]", "Hospitality and Tourism"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    manufacturing_auto_logistics:{
      question1:new Question(
        question1text,
        question1index,
         ["CNC Operator",
          "Welder",
         "Industrial Machinery Mechanic",
          ]),
       question2:new Question(
        question2text,
         question2index,
         ["ARD Logistics",
          "BF Goodrich-Michelin NA",
          "Bolta US, Inc.",
          "Bolzoni Auramo Inc",
          "Buffalo Rock",
          "Lear Corporation",
          "Mercedes-Benz U.S. International, Inc.",
          "Nucor Steel Tuscaloosa",
          "Phifer Incorporated",
          "SMP Automotive Systems of Alabama Inc.",
          "Schnellecke Logistics Alabama",
          "The Westervelt Company",
          "ZF Chassis Systems, LLC",
        
          "None"
          ]),
       
       question3:new Question(
        question3text.replace("[]", "Manufacturing, Auto, Logistics"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
    public_safety:{
      question1:new Question(
        question1text,
        question1index,
         ["Emergency Medical Technician", 
          "Firefighter",
          "Police Officer",
          ]),
       question2:new Question(
        question2text,
         question2index,
         ["ALEA",
          "AL Army National Guard",
          "City of Tuscaloosa",
          "Northport Police Department",
          "Tuscaloosa County Sheriff",
          "None"]),
       
       question3:new Question(
        question3text.replace("[]", "Public Safety"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
          []
        )
         
    },
   transportation:{
    question1:new Question(
      question1text,
      question1index,
       ["Diesel Mechanic",
        "Automotive Mechanic",
        "Truck Driver"
        ]),
     question2:new Question(
      question2text,
       question2index,
       ['Southland Transportation Group',
        'Tuscaloosa Chevrolet',
        'None']),
     
     question3:new Question(
      question3text.replace("[]", "Transportation"),
      question3index,
       question3Options),
     question4:new Question(
      question4text,
      question4index,
        ["Southland Transportation Group",
          "Tuscaloosa Chevrolet",
          "None"
          ]
      )
         
    },
  
   
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var CAW_Quiz_Object={ 
    agriculture:{

      question1:new Question(
        question1text,
        question1index,
        //career select options"
        ["Agricultural Equipment Operators",
         "Environmental Engineers",
        "Biological Technicians"]
        ),
       question2:new Question(
        question2text,
         question2index,
         //employer select options
         ["Natural Resource Conservation Service","Bonnie Plant Farms","None"]),
       
       question3:new Question(
         //replace with full fancy world name 
        question3text.replace("[]", "Agriculture"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
        //always empty array
          []
        )

         
    },
    architecture_construction_and_utilities:{
  
      question1:new Question(
        question1text,
        question1index,
        //career select options
        ["Welders",
      "Heating/Air/ Refrigeration Mechanics",
      "Plumbers/Pipefitters/Steamfitters",
      "Line Workers",
      "Distribution Specialist/Engineer",
      "Water Resource Specialist",
      ]
        ),
       question2:new Question(
        question2text,
         question2index,
         //employer select options
         ["Dixie Electric Heating and Air",
         "Alabama Power Company","Central Alabama Electric","Windstream","None"]),
       
       question3:new Question(
         //replace with full fancy world name 
        question3text.replace("[]", "Architecture & Construction"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
        //always empty array
          []
        )

         
    },
    communication_and_information_technology:{
      question1:new Question(
        question1text,
        question1index,
        //career select options
        ["Computer User Support Specialists",
      "Network & Computer System Administrators",
      "Software Developers",]
        ),
       question2:new Question(
        question2text,
         question2index,
         //employer select options
         ["Fearless","XiR Repair","Skylight Digital","United States Air Force","None"]
         ),
       
       question3:new Question(
         //replace with full fancy world name 
        question3text.replace("[]", "Communication & Information Technology"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
        //always empty array
          []
        )

         
    },
    education_and_training:{
      question1:new Question(
        question1text,
        question1index,
        //career select options
        ["Elementary School Teachers",
      "Secondary School Teachers",
      "Guidance Counselor"]
        ),
       question2:new Question(
        question2text,
         question2index,
         //employer select options
         ["Teach for America","None"]
         ),
       
       question3:new Question(
         //replace with full fancy world name 
        question3text.replace("[]", "Education & Training"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
        //always empty array
          []
        )
         
    },

    healthcare:{
      question1:new Question(
        question1text,
        question1index,
        //career select options
        ["Registered Nurse",
      "Pharmacy Technician",
      "Respiratory Therapist"]
        ),
       question2:new Question(
        question2text,
         question2index,
         //employer select options
         ["EAMC","Vaughan Regional","None"]
         ),
       
       question3:new Question(
         //replace with full fancy world name 
        question3text.replace("[]", "Health Science"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
        //always empty array
          []
        )
         
    },
    hospitality_and_tourism:{
      question1:new Question(
        question1text,
        question1index,
        //career select options
        ["Food Service Managers",
      "Chefs/Head Cooks",
      "Lodging Managers"]
        ),
       question2:new Question(
        question2text,
         question2index,
         //employer select options
         ["Ascent","Cities of Auburn and Opelika","None"]
         ),
       
       question3:new Question(
         //replace with full fancy world name 
        question3text.replace("[]", "Hopitality & Tourism"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
        //always empty array
          []
        )
         
    },
    manufacturing:{
      question1:new Question(
        question1text,
        question1index,
        //career select options
        ["Team Assemblers & Fabricators",
        "Maintenance and Repair Workers",
        "Supervisors of Production"]
        ),
       question2:new Question(
        question2text,
         question2index,
         //employer select options
         ["GKN",
         "To Your Health Sprouted Flour",
         "Fras-le",
         "Golden State Foods",
         "Hyundai",
         "Neptune Technology Group, Inc.",
         "SiO2",
         "Rexnord",
         "Rausch & Pausch (RAPA)",
         "Hager",
         "International Paper",
         "James Hardie",
         "Vectorply",
         "Hyundai Mobis",
         "WestRock",
         "ThermoFisher Scientific",
         "Pharmavite",
         "None"]
         ),
       
       question3:new Question(
         //replace with full fancy world name 
        question3text.replace("[]", "Manufacturing"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
        //always empty array
          []
        )
         
    },
    public_service:{
      question1:new Question(
        question1text,
        question1index,
        //career select options
        ["Police Officers",
      "Firefighters",
      "Emergency Medical Technicians/Paramedics"]
        ),
       question2:new Question(
        question2text,
         question2index,
         //employer select options
         ["ALEA","Phenix City","None"]
         ),
       
       question3:new Question(
         //replace with full fancy world name 
        question3text.replace("[]", "Public Service"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
        //always empty array
          []
        )
         
    },

    transportation_distribution_and_logistics:{
      question1:new Question(
        question1text,
        question1index,
        //career select options
        ["Stock & Material Movers",
        "Truck Drivers",
        "Mechanics/Diesel Engine Specialists",]
        ),
       question2:new Question(
        question2text,
         question2index,
         //employer select options
         ["Larry Puckett Chevrolet","Amazon","None"]
         ),
       
       question3:new Question(
         //replace with full fancy world name 
        question3text.replace("[]", "Transportation, Distribution & Logistics"),
        question3index,
         question3Options),
       question4:new Question(
        question4text,
        question4index,
        //always empty array
          []
        )
         
    },
  
  
   
  }
  
  
/*########################################################################################################################*/
var NCTEJW_Quiz_Object={ 
  aerospace_and_aviation:{
    question1:new Question(
      question1text,
      question1index,
       [
        "Avionics Mechanic",
        "A&P Mechanic",
        " Aerospace Engineer",
        " A&P Technician",
        " Shipping and Receiving Handler",
        "Quality Control Inspector",
        "Industrial Engineers",
        "Software QA Analysts and Testers",
        "Commercial Pilots",
        "Machinists"
       ]),
     question2:new Question(
      question2text,
       question2index,
       ["Airbus",
        "ST Engineering",
        "Coastal Alabama Community College",
        "None"]),
     
     question3:new Question(
      question3text.replace("[]", "Aerospace & Aviation"),
      question3index,
       question3Options),
     question4:new Question(
      question4text,
      question4index,
        []
      )

  },
  construction:{
    question1:new Question(
      question1text,
      question1index,
       [
        "Electrician",
        "Heating/Air/ Refrigeration Mechanics",
        "Plumbers, Pipefitters, and Steamfitters",
        "Construction Laborers",
        "Carpenters",
        "Construction Supervisor",
        "Construction Managers",
        "Painter",
        "Equipment Operator",
        "Administrative Assistant"
       ]),
     question2:new Question(
      question2text,
       question2index,
       ["McAbee Construction",
        "Performance Contractors, Inc",
        "Turner Industries Group",
        "None"          
         

       ]),
     
     question3:new Question(
      question3text.replace("[]", "Construction"),
      question3index,
       question3Options),
     question4:new Question(
      question4text,
      question4index,
        []
      )
   
  },
  energy:{
    question1:new Question(
      question1text,
      question1index,
       [
        "Electrical Power Line Installers",
        "Power Plant Operator",
        "Water Plant Operator",
        "Administrative Assistant",
        "Customer Service Representatives",
        "Production Manager",
        "Maintenance Manager",
        "Control Valve Mechanic",
        "Industrial Machinery Mechanic",
        "General & Operations Managers"
       ]),
     question2:new Question(
      question2text,
       question2index,
       ["Alabama Power",
        "Daphne Utilities",
        "None"
        
         

       ]),
     
     question3:new Question(
      question3text.replace("[]", "Energy"),
      question3index,
       question3Options),
     question4:new Question(
      question4text,
      question4index,
        []
      )
       
  },
  engineering_and_design:{
    question1:new Question(
      question1text,
      question1index,
       [
        "Civil Engineers",
        "Electrical Engineers",
        "Industrial Engineers",
        "Mechanical Engineers",
        "Aerospace Engineer",
        "Visual Merchandiser",
        "Graphic Designer",
        "Marine Engineer",
        "Floral Designers"
       ]),
     question2:new Question(
      question2text,
       question2index,
       ["Alabama Power/Southern Company",
        "Hargrove Engineering",
        "None"
        
         

       ]),
     
     question3:new Question(
      question3text.replace("[]", "Engineering and Design"),
      question3index,
       question3Options),
     question4:new Question(
      question4text,
      question4index,
        []
      )
       
  },
  healthcare:{
    question1:new Question(
      question1text,
      question1index,
       [ 
        "Registered Nurse",
        "Personal Care Aides",
        "Nursing Assistant",
        "Medical Assistants",
        "Charge Nurse",
        "Office Assistant",
        "Medical Secretary",
        "Home Health Aide",
        "Childcare Worker",
        "Administrative Assistant"
       ]),
     question2:new Question(
      question2text,
       question2index,
       ["Mobile Public Schools",
       "None"

         

       ]),
     
     question3:new Question(
      question3text.replace("[]", "Healthcare"),
      question3index,
       question3Options),
     question4:new Question(
      question4text,
      question4index,
        []
      )
       
  },
  hospitality_and_tourism:{
    question1:new Question(
      question1text,
      question1index,
       [ "Restaurant Manager",
        "Chefs & Head Cooks",
        "Hotel General Manager",]),
     question2:new Question(
      question2text,
       question2index,
       [
        "Spectrum Resorts",
        "None"


       ]),
     
     question3:new Question(
      question3text.replace("[]", "Hospitality and Tourism"),
      question3index,
       question3Options),
     question4:new Question(
      question4text,
      question4index,
        []
      )
       
  },
  information_technology:{
    question1:new Question(
      question1text,
      question1index,
       [
        "Computer User Support Specialist",
        "Software Quality Engineer",
        "Computer Systems Analysts",
        "Computer Programmer",
        "IT Director",
        "General & Operations Managers",
        "Network Administrator",
        "Sales Representative",
        "Customer Service Representatives"
       ]),
     question2:new Question(
      question2text,
       question2index,
       [
        "Provalus",
        "None"


       ]),
     
     question3:new Question(
      question3text.replace("[]", "Information Technology"),
      question3index,
       question3Options),
     question4:new Question(
      question4text,
      question4index,
        []
      )
       
  },
  manufacturing:{
    question1:new Question(
      question1text,
      question1index,
       [
        "Precision Machinist",
        "Welders",
        "Shipping and Receiving Materials Handler",
        "Team Assemblers",
        "Production Workers",
        "Production Manager",
        "Industrial Machinery Mechanics",
        "Paper Machine Operator",
        "Quality Control Inspector",
        "Chemical Operator"
       ]),
     question2:new Question(
      question2text,
       question2index,
       [
        "AM/NS Calvert",
        "BASF",
        "Evonik",
        "Westervelt",
        /*"Weyerhauser",
        "Chevron",*/
        "None"
        

       ]),
     
     question3:new Question(
      question3text.replace("[]", "Manufacturing"),
      question3index,
       question3Options),
     question4:new Question(
      question4text,
      question4index,
        []
      )
       
  },
  public_service:{
    question1:new Question(
      question1text,
      question1index,
       [
        "Patrol Officers",
        "Firefighters",
        "Corrections Officer",
        "Administrative Assistant",
        "Highway Maintenance Worker",
        "Office Clerk",
        "City Clerk",
        "Dispatchers",
        "Case Manager",
        "Recreation Workers"
       ]),
     question2:new Question(
      question2text,
       question2index,
       [
        "Mobile Fire and Rescue",
        "None"


       ]),
     
     question3:new Question(
      question3text.replace("[]", "Public Service"),
      question3index,
       question3Options),
     question4:new Question(
      question4text,
      question4index,
        []
      )
       
  },
  safety:{
    question1:new Question(
      question1text,
      question1index,
       ["Occupational Safety & Health",
        "Construction Safety Specialist",
        "Industrial Hygenist",
       ]),
     question2:new Question(
      question2text,
       question2index,
       [
         "None",

       ]),
     
     question3:new Question(
      question3text.replace("[]", "Safety"),
      question3index,
       question3Options),
     question4:new Question(
      question4text,
      question4index,
        []
      )
       
  },
  shipbuilding_and_repair:{
    question1:new Question(
      question1text,
      question1index,
       [
        "Marine Engineer",
        "Welder",
        "Plumbers, Pipefitters, and Steamfitters",
        "Team Assemblers",
        "Electrician",
        "Production Manager",
        "Precision Machinist",
        "Painter",
        "Fabricator",
        "Layout Workers, Metal and Plastic"
       ]),
     question2:new Question(
      question2text,
       question2index,
       [
        "Cooper Group",
        "Austal",
        "Ingalls",
        "None"
        

       ]),
     
     question3:new Question(
      question3text.replace("[]", "Shipbuilding & Repair"),
      question3index,
       question3Options),
     question4:new Question(
      question4text,
      question4index,
        []
      )
       
  },
  transportation_distribution_and_logistics:{
    question1:new Question(
      question1text,
      question1index,
       [
        "Truck Drivers",
        "Bus Driver",
        "Mail Carrier",
        "Delivery Driver",
        "Forklift Driver",
        "Cargo Supervisor",
        "Stocker",
        "Diesel Mechanic",
        "Cargo & Freight Agent"
       ]),
     question2:new Question(
      question2text,
       question2index,
       [
        "Alabama Trucking Association",
        "Cooper Group",
        "None"
        

       ]),
     
     question3:new Question(
      question3text.replace("[]", "Transportation, Distribution & Logistics"),
      question3index,
       question3Options),
     question4:new Question(
      question4text,
      question4index,
        []
      )
       
  },

 
}


  
  
  