//copy this to create a new all world template.  database eventually.

var resourceLibraryMixin = {
    methods: {
      selectTabsInfo: function (regionName) {
        var worldsArrays={
            SAWDC:SAWDC_School_Object,
            CAW:CAW_Tabs_Object,
            WAW:WAW_Tabs_Object,
            NAW:NAW_School_Object,
            EAW:EAW_Tabs_Object,
            NCTEJW:NCTEJW_Tabs_Object

  
          }
          return worldsArrays[regionName]
      }
    }
  };
  export default resourceLibraryMixin
  /*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var SAWDC_School_Object={ 
  tabs:[
    {
    tabName:"Coastal Alabama Community College",
    tabContent:{
      tabDescription:"Coastal Alabama Community College provides university parallel programs that transfer to senior colleges and universities and workforce development and technical skills training that increases access to employment, enhances career advancement, and supports local economic needs.",
      tabLink:"http://www.coastalalabama.edu/programs/ ",
      tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'-h5TvxNexX8',srcOrHref:'https://youtu.be/-h5TvxNexX8'},
      }
    },
    {
      tabName:"Bishop State Community College",
      tabContent:{
        tabDescription:"Bishop State Community College is a state-supported, open-admission, urban community college located in Mobile, Alabama. It is the college’s mission to provide students with open access to a quality and affordable education that equips them with the skills and knowledge needed to successfully enter the workforce and thrive.",
        tabLink:"https://www.bishop.edu/programs/career-technical-education ",
        tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'mc1p2JexPfE',srcOrHref:'https://youtu.be/mc1p2JexPfE'},
        }
      },
      {
        tabName:"Reid State Technical College",
        tabContent:{
          tabDescription:"Reid State Technical College is a degree-granting, two-year institution that provides quality academic and technical education to students from diverse backgrounds and abilities. The college promotes economic growth by preparing a qualified workforce for business and industry.",
          tabLink:"https://www.rstc.edu/programs",
          tabMedia:{imageOrVideo:'',videoType:'',videoId:'',srcOrHref:''},
          }
        },
        {
          tabName:"University of Mobile",
          tabContent:{
            tabDescription:"With over 90 areas of study, University of Mobile believes strong academics is vital in a changing world. Faculty of the highest caliber train and educate students to be the best in their field of study.",
            tabLink:"https://umobile.edu/academics/",
            tabMedia:{imageOrVideo:'',videoType:'',videoId:'',srcOrHref:''},
            }
        },
        {
          tabName:"University of South Alabama",
          tabContent:{
            tabDescription:"Today, USA provides high-quality education in business, the liberal arts, education, engineering, computing, the sciences and healthcare. It offers more than 100 undergraduate, graduate and doctoral programs through its 10 colleges and schools. The University campus includes USA Technology & Research Park, an incubator of economic development and technological innovation. On the east side of Mobile Bay, USA’s Baldwin County Campus provides eastern shore residents convenient access to educational programs. The University of South Alabama encompasses a comprehensive health system composed of two hospitals — USA University Hospital and USA Children’s & Women’s Hospital — along with the USA Mitchell Cancer Institute and the USA Physicians Group. This combination of advanced healthcare services has improved the lives of countless residents throughout the entire region.",
            tabLink:"https://www.southalabama.edu",
            tabMedia:{imageOrVideo:'',videoType:'',videoId:'',srcOrHref:''},
            }
        },
        {
          tabName:"Spring Hill College",
          tabContent:{
            tabDescription:"Spring Hill College offers academic programs for undergraduate students, graduate students and adult learners in the Jesuit, Catholic educational tradition. At Spring Hill College, the average class size is 17 students and our student to faculty ratio is 14:1. Through a combination of experiences both inside and outside of the classroom, our students are encouraged to actively explore their skills, talents, aspirations and career potential. Internships, service and networking opportunities complement this process and prepare them for the next step on their personal and career paths.",
            tabLink:"https://www.shc.edu/",
            tabMedia:{imageOrVideo:'',videoType:'',videoId:'',srcOrHref:''},
            }
        },
        {
          tabName:"Career Look Book",
          tabContent:{
            tabDescription:"Use the Career Look Book below to begin preparing a successful future. It contains helpful activities and resources such as a sample job application and interview tips.",
            tabLink:"https://sawdcalabamaworks.com/wp-content/uploads/High-School-Look-Book.-2.20.2020.pdf",
            tabMedia:{imageOrVideo:'',videoType:'',videoId:'',srcOrHref:''},
            }
        },
        {
          tabName:"Financial Literacy Resources",
          tabContent:{
            tabDescription:"Watch the video to learn about tips on how to save money and use the expense calculator below to begin creating a budget for the future!",
            tabLink:"https://sawdcalabamaworks.com/expense-calculator/",
            tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'a5UMk5H0P_A',srcOrHref:'https://youtu.be/a5UMk5H0P_A'},
            }
        },
        {
          tabName:"WOO Brochure",
          tabContent:{
            tabDescription:"",
            tabLink:"https://sawdcalabamaworks.com/wp-content/uploads/SAWDC-WoO-2021-Bklt-PROOF-REV-4.12.21.pdf",
            tabMedia:{imageOrVideo:'',videoType:'',videoId:'',srcOrHref:''},
            }
        },
  ]
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var NAW_School_Object={
     
    tabs:[         {
      tabName:"Bevill State Community College",
      tabContent:{
        tabDescription:"Bevill State Community College provides an ideal environment for learning and professional development. As a public, two-year, 'open door' institution, Bevill State Community College is committed to providing comprehensive education and educational support services for people in West Central Alabama and beyond. ",
        tabLink:"https://www.bscc.edu/",
        game:"https://romantic-einstein-a95603.netlify.app/triviagame",
        tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'XFhQLoAadGo',srcOrHref:'https://youtu.be/XFhQLoAadGo'},
      }
  },
  {
    tabName:"Athens State University",
    tabContent:{
      tabDescription:"Founded in 1822, Athens State University is Alabama’s oldest continuously operating institution of higher learning and has been providing academic excellence to students for almost 200 years. As one of only a few “upper-division” universities in the entire country, Athens State is a destination for transfer students and students with more than 36 hours of credit who may have paused their education and are seeking to finish their degree. Offering over 50 undergraduate degree programs and options in addition to graduate programs, Athens State focuses on providing affordable, flexible, and high-quality options for all learners. At Athens State, we understand it isn't how you began your education that matters. It’s how you finish. And a strong finish is only the beginning.",
      tabLink:"https://www.athens.edu/",
      tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'3l0LqLQbDYc',srcOrHref:'https://youtu.be/3l0LqLQbDYc'},
    }
},{
  tabName:"Calhoun Community College",
  tabContent:{
    tabDescription:"Calhoun Community College exemplifies the two-year college mission of commitment to excellence in teaching and service and is the region’s leader in education and workforce development.",
    tabLink:"https://calhoun.edu/",
    tabMedia:{imageOrVideo:'',videoType:'',videoId:'',srcOrHref:''},
  }
},{
  tabName:"Drake State Technical & Community College",
  tabContent:{
    tabDescription:"Drake State Community and Technical College offers flexible, affordable university-transfer and technical degrees, certificates, adult and continuing education, and customized skills training to fulfill the diverse workforce needs of employers.",
    tabLink:"https://www.drakestate.edu/",
    tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'jqc75nN-i4o',srcOrHref:'https://youtu.be/jqc75nN-i4o'},
  }
},
  ]
 

  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var EAW_Tabs_Object={ 
    tabs:[         {
        tabName:"Southern Union State Community College",
        tabContent:{
          tabDescription:"The Academic Division at Southern Union State Community College offers the Associate in Science (A.S.) and Associate in Applied Science (A.A.S.) degrees. Students may also earn Certificates in some curriculum areas. ",
          tabLink:"https://www.suscc.edu/programs/officeofdean.cms",
          tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'jxk10UukKrM',srcOrHref:'https://youtu.be/jxk10UukKrM'},
        }
    },
    {
        tabName:"Gadsden State Community College",
        tabContent:{
          tabDescription:"Take a look at our 70+ programs of study and find the right fit for you, your schedule and your family. ",
          tabLink:"https://www.gadsdenstate.edu/programs-of-study/program-divisions.cms",
          tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'P7FIfMq5uek',srcOrHref:'https://youtu.be/P7FIfMq5uek'},
        }
    },
    {
        tabName:"Jacksonville State University",
        tabContent:{
          tabDescription:"At Jacksonville State University, you can earn a degree in more than 95 academic programs and concentrations, within a community that supports you every step of the way.",
          tabLink:"http://www.jsu.edu/academics/index.html",
          tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'5vE12gmkrOs',srcOrHref:'https://youtu.be/5vE12gmkrOs'},
        }
    },
    {
      tabName:"Central Alabama Community College",
      tabContent:{
        tabDescription:"Central Alabama Community College is a two-year institution of higher learning located in Alexander City, Alabama. The college enrolls 1700 students and has been accredited by the Commission on Colleges of the Southern Association of Colleges and Schools since 1969.",
        tabLink:"https://www.cacc.edu/admissions/programs/",
        tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'3yqKLWuKwL8',srcOrHref:'https://youtu.be/3yqKLWuKwL8'},
        }
    },
    {
      tabName:"Worlds of Work -  Student Guide",
      tabContent:{
        tabDescription:"View the Worlds of Work Student Guide below to learn more about the various worlds you just visited. Learn about the various careers in each world and what it takes to get started on your path to a successful career.",
        tabLink:"https://issuu.com/eastalabamaworks/docs/wow_book_2021_final",
        tabMedia:{imageOrVideo:'',videoType:'',videoId:'',srcOrHref:''},
        }
    },
    {
      tabName:"AlabamaWorks Job Search",
      tabContent:{
        tabDescription:"Set of employment tools for job seekers in Alabama. Search jobs, find education and training.",
        tabLink:"https://alabamaworks.alabama.gov/",
        tabMedia:{imageOrVideo:'',videoType:'',videoId:'',srcOrHref:''},
      }
  },
    ]
   
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var WAW_Tabs_Object={ 
    tabs:[
      {
        tabName:"Shelton State Community College",
        tabContent:{
          tabDescription:"One of the largest two-year colleges in the state and a Historically Black College/University (HBCU), Shelton State Community College is diverse in both people and possibilities. The College provides on-campus leadership opportunities, progressive partnerships with local business and industry, and innovative learning experiences. In short, it’s education that works.",
          tabLink:"https://www.sheltonstate.edu/",
          multilink:true,
          links:[
            {title:"Website"
            ,url:"https://www.sheltonstate.edu/"},
            {title:"Degree Programs Brochure",
          url:"https://waw-image.s3.amazonaws.com/wawpdfs/Shelton+State+Comm.+College.+(for+main+education+booth)+pdf.pdf"},
{title:"Associate Degree Nursing"
,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+-+AssociateDegreeNursing+.pdf"},
{title:"Child Development"
,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+-+ChildDevelopment.pdf"},
{title:"Computer Science"
,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+ComputerScience+Rack+Card.pdf"},
{title:"Construction "
,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+-+Construction+(Welding).pdf"},
{title:"Healthcare"
,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+-+healthcare.pdf"},
{title:"Culinary Arts"
,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+-+Hospitality+CulinaryArts.pdf"},
{title:"Business Office Management"
,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+BusinessOfficeManagement.pdf"},
{title:"Engineering Graphic Design Technology"
,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+Construction+-+EngineeringGraphicDesignTechnology.pdf"},
{title:"Welding"
,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+Construction+-+Welding.pdf"},
{title:"Construction- AC and Refrigeration Tech"
,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+Construction+(AirConditioningAndRefrigerationTechnology).pdf"},
{title:"Construction-Electrical Technology"
,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+Construction+(ElectricalTechnology).pdf"},
{title:"Cosmetology- Salon and Spa"  ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+Cosmetology+-+SalonandSpa.pdf"},
{title:"Engineering",url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+engineering+.pdf"},
{title:"Nursing Assistant",url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+healthcare-+NursingAssistant.pdf"},
{title:"Practical Nursing" ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+healthcare-+PracticalNursing.pdf"},
{title:"Respiratory Therapy Rack Cards ",url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+healthcare-+Respiratory+Therapy+Rack+Cards+(June+2019).pdf"},
{title:"Automotive Mechanics",url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+Manufacturing%2C+Automotive%2C+and+Logistics+AutomotiveMechanics.pdf"},
{title:"Computerized Numerical Control ",url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+Manufacturing%2C+Automotive%2C+and+Logistics+ComputerizedNumericalControl.pdf"},
{title:"Electrical Technology",url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+Manufacturing%2C+Automotive%2C+and+Logistics+ElectricalTechnology.pdf"},
{title:"Industrial Electronics",url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+Manufacturing%2C+Automotive%2C+and+Logistics+IndustrialElectronics.pdf"},
{title:"Machine Tool Technology",url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+Manufacturing%2C+Automotive%2C+and+Logistics+MachineToolTechnology.pdf"},
{title:"Modern Manufacturing " ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+Manufacturing%2C+Automotive%2C+and+Logistics+ModernManufacturing+Rack+Card.pdf"},
{title:"Diesel Mechanics" ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+transportation+-DieselMechanics.pdf"},
          ],
          tabMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'ZRoRuELSNlc',
          srcOrHref:'https://www.youtube.com/watch?v=ZRoRuELSNlc'},
        }
    },
    {
      tabName:"Alabama Department of Rehabiliation Services",
      tabContent:{
        tabDescription:"From birth through adulthood, we have programs and a highly skilled, compassionate staff who can help assess your situation and provide the most appropriate treatment, therapies and services. Whatever we can do to help you reach your maximum potential, we’ll be with you and your family all the way.",
        tabLink:"https://rehab.alabama.gov/",
        multilink:true,
        links:[{title:"Website" ,url:"https://rehab.alabama.gov/"},
    ],
        tabMedia:{imageOrVideo:'image',
        videoType:'',
        videoId:'adrs-logo',
        srcOrHref:'adrs-logo'
      
      },
      }
  },
    {
        tabName:"The Onin Group",
        tabContent:{
          tabDescription:"The Ōnin Group is a privately-held company focused on the staffing industry with a mission to create opportunity and empower people. Each year, high school students graduate but struggle to find meaningful careers. The Ōnin Group sponsors the High School Ready to Work program that helps connect high school students with career paths and local employers. Ask your school counselor about bringing the High School Ready to Work program to your school today.",
          tabLink:"http://www.oningroup.com/ready-to-work/",
          multilink:true,
          links:[{title:"Website" ,url:"http://www.oningroup.com/ready-to-work/"},
          {title:"Onin Pdf" ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/Onin.pdf"},],
          tabMedia:{imageOrVideo:'video',videoType:'youtube',videoUrl:'https://vimeo.com/536063092/4784d86772',videoId:'yMouzb9mlts'},
        }
    },
    {
      tabName:"Tuscaloosa Public Library",
      tabContent:{
        tabDescription:"The Tuscaloosa Public Library serves the 192,000 residents of Tuscaloosa County, as well as students attending the University of Alabama, Shelton State Community College, and Stillman College.  TPL offers a full array of library services and materials from books in all formats including downloadable (e-books) to DVDs, from reference assistance to computer classes, and from children’s story times to book discussions. Materials and programs are designed to meet the needs of our community. The library currently has four service outlets: The Main Library, Brown Branch, Weaver Bolden Branch, and the Bookmobiles.",
        tabLink:"https://www.tuscaloosa-library.org/",
        multilink:false,
        links:[{title:"" ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+Manufacturing%2C+Automotive%2C+and+Logistics+ModernManufacturing+Rack+Card.pdf"},
        {title:"" ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+transportation+-DieselMechanics.pdf"},],
        tabMedia:{imageOrVideo:'image',
        videoType:'',
        videoId:'tpl-logo',
        srcOrHref:'tpl-logo'},
      }
  },
  {
    tabName:"ALABAMA NATIONAL GUARD",
    tabContent:{
      tabDescription:"The Alabama National Guard is a reserve component of the United States Armed Forces. Composed of National Guard military members and units from each state, the District of Columbia, and the territories of Guam and the Virgin Islands. Part-time service serving the local communities with Full-time benefits.",
      tabLink:"https://www.nationalguard.com/",
      multilink:true,
      links:[{title:"website" ,url:"https://www.nationalguard.com/"},
      {title:"education benefits" ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/110935167_educational_benefits_pdf.pdf"},],
      tabMedia:{imageOrVideo:'image',
      videoType:'',
      videoId:'national-guard-logo',
      srcOrHref:'national-guard-logo'},
    }
},

    {
        tabName:"The University of Alabama College of Education",
        tabContent:{
          tabDescription:"The University of Alabama has long been concerned with programs for the preparation of public school personnel. In 1844, only 13 years after the University’s establishment in 1831, a Normal Department was provided for those who wished to prepare for teaching careers. In 1872, the Normal Department become the Normal School. By 1899, the Trustees had replaced the Normal School with the School of Pedagogy and Psychology, which in 1902 became the School of Philosophy and Education. In 1908, the school was reorganized as the Department of Education; one year later further organizational changes resulted in the name being changed to the School of Education."

          +"The present College of Education was established in 1928. Until 1924, the University’s offerings in teacher education were almost entirely limited to undergraduate programs for elementary and secondary teachers. Graduate work in school administration was added in 1924; and all programs, undergraduate and graduate, were greatly expanded during 1927 and 1928. Doctoral programs were authorized in 1951. Currently, the College of Education is divided into six departments.",
          tabLink:"https://education.ua.edu/",
          multilink:false,
          links:[{title:"" ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+Manufacturing%2C+Automotive%2C+and+Logistics+ModernManufacturing+Rack+Card.pdf"},
          {title:"" ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/SSCC+transportation+-DieselMechanics.pdf"},],
          tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'cnqpQgV7qoM',srcOrHref:'https://vimeo.com/536063123/97cd93af22'},
        }
    },
    {
      tabName:"Stillman College",
      tabContent:{
        tabDescription:"Stillman College is a liberal arts institution with a historical and formal affiliation with the Presbyterian Church (U.S.A.). It is committed to fostering academic excellence, to providing opportunities for diverse populations, and to maintaining a strong tradition of preparing students for leadership and service by fostering experiential learning and community engagement designed to equip and empower Stillman’s students and its constituents.",
        tabLink:"https://stillman.edu/",
        multilink:true,
        links:[{title:"Learn More" ,url:"https://stillman.edu/"},
        {title:"brochure" ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/Stillman.pdf"},],
        tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'VoFIRwrj2F4',srcOrHref:'https://youtu.be/3yqKLWuKwL8'},
        }
    },
    {
      tabName:"Alabama Institute for Deaf and Blind Tuscaloosa Regional Center",
      tabContent:{
        tabDescription:"Early Intervention services for any developmental delays in children; birth to age 3. Support services and resources for Deaf and Blind individuals; birth to Senior Citizens. Employees Early Childhood Teachers, Speech Therapists, Physical Therapists, Occupational Therapists, Special Instructors, Teachers of the Deaf and Teachers of the Visually Impaired, Interpreters, Job Developers, Sign Language Instructors, Drivers, Case Managers, Assistive Technology Instructors.",
        tabLink:"",
        multilink:true,
        links:[{title:"Website" ,url:"http://aidb.org/Tuscaloosa"},
        {title:"Brochure" ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/AIDB.pdf"},],
        tabMedia:{imageOrVideo:'',videoType:'',videoId:'',srcOrHref:''},
        }
    },
    {
      tabName:"Bevill State Community College",
      tabContent:{
        tabDescription:"Bevill State's four main campuses, and one instructional site, offer university parallel and applied technology educational opportunities to over one-quarter million people in a seven county area. With a service area that spans over 4,600 square miles, from the Birmingham city limits to the Mississippi state line, Your Story Starts at Bevill State!"

        +"Dual Enrollment - Eligible high school students may enroll in college classes concurrently with high school classes, either on the College campus or at the high school, and receive both high school and college credit. "
        
       + "Accelerated Enrollment - Eligible high school students may enroll in college classes concurrently with high school classes and receive college credit. "
        
       + "Upward Bound Program (Fayette, Hamilton and Sumiton Campuses)"
       + "Fayette Upward Bound  - The Fayette program serves nine schools located within the five counties of the Bevill State Community College Service Area: Winfield High School in Marion County; Lamar County and South Lamar High School in Lamar County; Hubbertville, Berry, and Fayette County High School in Fayette County; Gordo, Pickens County and Aliceville High School in Pickens County." ,
        column2:"Hamilton Upward Bound - The Hamilton program serves nine high schools in Lamar, Marion, and Winston County. "
        
        + "Sumiton Upward Bound - The Sumiton Campus serves nine high schools in Walker and Jefferson County. Those schools are Carbon Hill, Cordova, Corner, Curry, Dora, Jasper, Minor, Oak Grove, and Oakman High Schools." 
         
        + "Upward Bound Math & Science - Fayette Campus Fayette Upward Bound Math and Science The Fayette program serves nine schools located within the nine counties of the Bevill State Community College Service Area: Brilliant, Hamilton, and Marion County High Schools in Marion County; Lamar County and Sulligent High Schools in Lamar County; Berry, Fayette County, and Hubbertville High Schools in Fayette County; Pickens County High School in Pickens County. "
         
        + "Educational Talent Search - Fayette, Hamilton and Sumiton Campuses The Talent Search Program identifies and assists individuals from disadvantaged backgrounds who have the potential to succeed in higher education.",
       
        tabLink:"http://www.bscc.edu/",
        multilink:false,
        links:[{title:"Learn More" ,url:"http://www.bscc.edu/"},
       ],
        game:"https://romantic-einstein-a95603.netlify.app/triviagame",
        tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'RLaHq2VZJ3w',srcOrHref:'https://youtu.be/RLaHq2VZJ3w'},
      }
    },
    {
      tabName:"Wallace Community College Selma",
      tabContent:{
        tabDescription:"The College shares a vision of a learning-centered education that is responsive to the needs of our service area and is manifested by quality teaching, opportunities for learning, effective educational support services, and access to a quality education. Challenged by change and innovation, the College will move forward to create a future responsive to the diverse needs of students, community, and state.",
        tabLink:"http://www.wccs.edu/",
        multilink:false,
        links:[{title:"Learn More" ,url:"http://www.wccs.edu/"},
        ],
        tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'BXCtUpj7g1E',srcOrHref:'https://www.youtube.com/watch?v=BXCtUpj7g1E'},
        }
    },
    {
      tabName:"The University of West Alabama - Division of Economic & Workforce Development",
      tabContent:{
        tabDescription:"The Division of Economic and Workforce Development at UWA is committed to working to create a seamless workforce development process for the region that support and enhances economic development and workforce training through effective collaboration with employers and communities within West Alabama. Through its LINCS program the Division employs mobile training units to serve a 10-county region and bring training programs to communities, industry and schools.",
        tabLink:"http://www.uwaworks.com/",
        multilink:false,
        links:[{title:"Learn More" ,url:"http://www.uwaworks.com/"},
         ],
        tabMedia:{imageOrVideo:'image',videoType:'',videoId:'uwa-logo',srcOrHref:'uwa-logo'},
        }
    },
    {
      tabName:"Alabama Technology Network",
      tabContent:{
        tabDescription:"ATN provides extensive workforce training, technical assistance, & engineering services to incumbent workers across the state in areas of Continuous Improvement, Environmental Health & Safety, Food Safety, Industrial Maintenance & Technology, and Leadership & Management Development.",
        tabLink:"https://www.atn.org/",
        multilink:true,
        links:[{title:"Brochure" ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/ATN.pdf"},
        {title:"Learn More" ,url:"https://www.atn.org/"},],
        tabMedia:{imageOrVideo:'',videoType:'',videoId:'',srcOrHref:''},
        }
    },
    {
      tabName:"Alabama Works and AIDT",
      tabContent:{
        tabDescription:"AIDT is a state agency established to build a healthy state economy by recruiting and training a skilled workforce to attract new industries to the state and to expand existing industries. As a division of the Alabama Department of Commerce, AIDT provides a full range of customized technical training programs that are offered at no cost to employers and to the trainees.",
        tabLink:"https://www.aidt.edu/",
        multilink:true,
        links:[{title:"Learn More" ,url:"https://www.aidt.edu/"},
        {title:"Brochure" ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/AIDT.pdf"},],
        tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'tIMP4mvMv1Q',srcOrHref:'https://youtu.be/tIMP4mvMv1Q'},
        }
    },



  ],
   
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var CAW_Tabs_Object={ 
    tabs:[
      {
        tabName:"Healthcare Activity",
        tabGroup:"activityGroup",

        tabContent:{
          multilink:false,
          title:"View Activity",
          tabDescription:"The heart is a muscle which functions as a very powerful pump to transport blood around the body. It beats somewhere between 60 and 100 times a minute, but can beat even faster than that if needed. Nutrients and oxygen are transported to cells in the body and waste products taken away. The right side of the heart receives blood from the body and pumps it to the lungs, while the left side pumps from the lungs to the rest of the body. Just before each beat the heart fills with blood, it then contracts which squeezes the blood along.          When we need more energy,  for example to run, the heart beats faster to pump more oxygen around the body. When your heart beats it sends a wave of pressure through all of your veins in your body. You can feel this pressure in some areas of the body (such as when a vein passes over a piece of bone). We call this your pulse. One place to feel your pulse is on your wrist in a straight line at the base of your thumb. If you count your pulse for 1 minute that gives you your pulse rate. You can do it for shorter periods but you must remember to then convert it to beats per minute. So if you time it for 30 seconds you would have to double your pulse count to get the number of beats per minute. When the heart beats faster, the pulse rate is higher.",
          tabLink:"https://www.teachengineering.org/content/cub_/activities/cub_human/cub_human_lesson05_activity2_worksheet.pdf",
          tabMedia:
          { imageOrVideo:'image',
            videoType:'',
            videoId:'healthcare-activity',
            srcOrHref:'healthcare-activity'},
        }
    }, 
    {
      tabName:"Construction Activity",
      tabGroup:"activityGroup",

      tabContent:{
        multilink:false,
        title:"View Activity",
        tabDescription:"Tough construction jobs require tough tools, plain and simple. Professional construction work requires high-quality tools like a power saw, drill, sander, etc.  These tools are essential for great work and should be built to be long-lasting and efficient. One of the most important tools that every professional construction worker or contractor uses on a daily basis is the tape measure. A tape measure is a must-have item in any toolkit. It is what allows you to make precise, accurate measurements to ensure that every project is completed correctly. Have you ever heard “Measure twice, cut once”? Unless you know how to use a tape measure and correctly read its markings, your best efforts can still be slightly off. Unfortunately, “off” just won’t do when it comes to the construction industry. The simple tape measure is not so simple. It can actually be used for tasks other than  measuring the length between point A and point B. It can determine the inside width of a bookshelf, help find studs in a wall and draw a perfect circle.",
        tabLink:"https://centralalabamaworks.com/wp-content/uploads/Construction-Activity.pdf",
        tabMedia:{imageOrVideo:'image',videoType:'',videoId:'construction-activity',srcOrHref:'construction-activity'},
      }
  },
  {
    tabName:"Manufacturing Activity",
    tabGroup:"activityGroup",

    tabContent:{
      multilink:false,
      title:"View Activity",

      tabDescription:"A strong manufacturing base leads to more research and development, innovation, productivity, exports, and jobs. Manufacturing helps raise living standards and generates more jobs than any other sector. There are nearly 300,000 manufacturing jobs in Alabama, producing items that people don't always think about. Cars, paper, equipment- we all know that these are manufactured. What about water, vitamins, skateboards and vials for vaccines? YES! All these items are manufactured in Region 5!",
      tabLink:"https://centralalabamaworks.com/wp-content/uploads/Cube-Manufacturing-Instructions-and-template.pdf",
      tabMedia:{imageOrVideo:'image',videoType:'',videoId:'manufacturing-activity',srcOrHref:'manufacturing-activity'},
    }
},
{
  tabName:"Transportation Activity",
  tabGroup:"activityGroup",

  tabContent:{
    multilink:false,
    title:"View Activity",

    tabDescription:"Alabama sits at the heart of the fastest-growing region in the U.S., and the state has the logistics infrastructure to help the region keep growing. Alabama’s transportation infrastructure allows worldwide connectivity: six interstate highways, 3,700 miles of railroad tracks, 92 airports, 1,300 miles of commercially navigable waterways, and a deep-water seaport that is one of the nation’s busiest.Transportation and logistics is the planning, management and movement of people, materials and products by road, air, rail and water. Determining the most cost effective and quickest route for deliveries is extremely important. It ultimately impacts us all.",
    tabLink:"https://centralalabamaworks.com/wp-content/uploads/Transportation-Activity.pdf",
       tabMedia:{imageOrVideo:'image',videoType:'',videoId:'transport-activity',srcOrHref:'transport-activity'},
  }
},
{
  tabName:"Career Discovery Student Guide",
  tabGroup:'activityGroup',
  tabContent:{
    multilink:false,
    tabDescription:"View the Career Discovery Student Guide below to learn more about the various worlds you just visited. Learn about the various careers in each world and what it takes to get started on your path to a successful career.",
    tabLink:"https://centralalabamaworks.com/wp-content/uploads/2022-Career-Discovery-Student-Guide-1-1.pdf",
    tabMedia:{imageOrVideo:'image',videoType:'',videoId:'',srcOrHref:'student-guide-2022'},
  }
},
      {
            tabName:"Tuskegee University",
            tabContent:{
              multilink:false,
              tabDescription:"Tuskegee University is an independent and state-related institution of higher education. Its programs serve a student body that is coeducational as well as racially, ethnically, and religiously diverse. The courses currently offered at TU have over 50 degrees including 39 Bachelor's, 13 Master's, 2 Doctors of Philosophy: one in Materials Science and Engineering, and one in Integrative BioSciences, and the Doctor of Veterinary Medicine.",
              tabLink:"https://info.tuskegee.edu/video-a-look-into-caens?hsCtaTracking=bb173ee4-be52-4d5f-909b-f9a80cec277f%7Cb2905782-540f-4f2f-9998-5cfd83b54842",
              tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'M4QaRXazSc8',srcOrHref:'https://youtu.be/M4QaRXazSc8'},
            }
        },
        {
            tabName:"Central Alabama Community College",
            tabContent:{
              multilink:false,
              tabDescription:"Your world is shaped by who you are. Central Alabama Community College offers education / training career paths and flexible scheduling that provides a world of opportunity for state-of-the-art education and training for a wide range of ages, backgrounds, and life goals, which prepare you for a rewarding future.",
              tabLink:"https://www.cacc.edu/admissions/programs/associate-in-science-degree/education-career-paths/",
              tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'44GMFGFkbG4',srcOrHref:'https://youtu.be/44GMFGFkbG4'},
            }
        },
        {
            tabName:"Chattahoochee Valley Community College",
            tabContent:{
              multilink:false,
              tabDescription:"Chattahoochee Valley Community College promotes student success and is committed to enriching our community by offering accessible, quality and engaging educational opportunities through academic transfer, career and technical education, workforce development and adult education.",
              tabLink:"http://www.cv.edu/",
              tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'18S2rckq0lM',srcOrHref:'https://youtu.be/18S2rckq0lM'},
            }
        },
        {
            tabName:"Trenholm State Community College",
            tabContent:{
              multilink:false,
              tabDescription:"Trenholm State Community College, founded in 1966, is a multi-campus institution serving the River Region of Alabama with Associate's Degrees, as well as a wide range of certificate and workforce training options. Trenholm's advantages include small class sizes, hands-on learning, dedicated faculty, day and night classes, online options, affordable tuition, generous financial aid, a diverse community and vibrant student life.",
              tabLink:"https://www.trenholmstate.edu/",
              tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'MYkzwMLikdM',srcOrHref:'https://www.youtube.com/watch?v=MYkzwMLikdM'},
            }
        },
        {
            tabName:"Southern Union State Community College",
            tabContent:{
              multilink:false,
              tabDescription:"No matter your career or personal goals, Southern Union can help set you on the path to success. Our college serves residents of an eight county area of East and Central Alabama as well as neighboring Georgia counties. To ensure a comprehensive college experience, students at Southern Union can choose from a variety of activities, clubs and academic honoraries in which to participate.The success of our students is the testimony of our tradition of excellence. Whether they are entering the workforce, or moving on to continue their education, they leave Southern Union with a firm educational foundation.",
              tabLink:"https://www.suscc.edu/current-students/opelika.cms",
              tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'e0qYkj4t658',srcOrHref:'https://www.youtube.com/watch?v=e0qYkj4t658'},
            }
        },
        {
            tabName:"Marion Military",
            tabContent:{
              multilink:false,
              tabDescription:"Marion Military Institute, a two-year public institution of higher learning, educates and develops cadets as future leaders through an immersive experiential military environment which integrates intellectual, leadership, character and physical development in order to prepare them for success in four-year colleges, U.S. service academies, and in military and civilian careers.",
              tabLink:" https://marionmilitary.edu/",
              tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'ZVX9FjM7Sjo',srcOrHref:'https://www.youtube.com/watch?v=ZVX9FjM7Sjo'},
            }
        },
        {
            tabName:"Wallace Community College Selma",
            tabContent:{
              multilink:false,
              tabDescription:"Wallace Community College Selma shares a vision of a learning-centered education that is responsive to the needs of our service area and is manifested by quality teaching, opportunities for learning, effective educational support services, and access to a quality education. Challenged by change and innovation, the College will move forward to create a future responsive to the diverse needs of students, community, and state.",
              tabLink:"https://www.wccs.edu/",
              tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'BXCtUpj7g1E',srcOrHref:'https://www.youtube.com/watch?v=BXCtUpj7g1E'},
            }
        },
        {
            tabName:"Auburn University",
            tabContent:{
              multilink:false,
              tabDescription:"Auburn University has established itself as an excellent comprehensive, public land-grant university. However, excellence is not enough. We aspire to become a world-renowned institution that excels in education, research, and service—and to become a model of higher education. We understand that higher education is on the precipice of change. We intend to lead and shape that change.",
              tabLink:"https://www.auburn.edu/",
              tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'ssrWKiZOyS0',srcOrHref:'https://www.youtube.com/watch?v=ssrWKiZOyS0'},
            }
        },
        {
          tabName:"Alabama State University",
          tabContent:{
            multilink:false,
            tabDescription:"Today, Alabama State University, located in Montgomery, Ala., is a widely respected, world-class institution of higher learning which welcomes students of all races. We offer nearly 50 undergraduate and graduate degree programs, from our historic teacher education program to our new, high-demand programs in health sciences, new Ph.D. in microbiology  and minor in international business.",
            tabLink:"https://www.alasu.edu/",
            tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'mfrSDDjEos8',srcOrHref:'https://www.youtube.com/watch?v=mfrSDDjEos8'},
          }
      },
        {
          tabName:"ADRS",
          tabGroup:'partnerGroup',
          tabContent:{
            multilink:false,
            tabDescription:"Created by the Alabama Legislature in 1994, the Alabama Department of Rehabilitation Services (ADRS) is the state agency that serves Alabamians with disabilities from birth throughout their lives. Our “continuum of care” approach means that help is here at every stage of a person’s life.",
            tabLink:" https://www.rehab.alabama.gov/",
            tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'21pXLuzkqu4',srcOrHref:'https://youtu.be/21pXLuzkqu4'},
          }
      },
      {
        tabName:"AlabamaWorks",
        tabGroup:"partnerGroup",

        tabContent:{
          multilink:false,
          tabDescription:"AlabamaWorks stands for opportunity, innovation, accountability and inclusion with the vision of a better future for Alabama in which communities, business, and industry are supported in a collaborative process to build prosperity through the opportunity of meaningful work and a growing economy. Our mission is to recruit, train, and empower a highly skilled workforce driven by business and industry needs and to be the competitive advantage for Alabama’s economic growth. Whether you’re an employer, a job seeker or a student, AlabamaWorks is the springboard for your success and promises to provide profitability and economic growth by creating opportunities for success and an improved quality of life for Alabamians.",
          tabLink:"https://alabamaworks.com/",
          tabMedia:{imageOrVideo:'video',videoType:'vimeo',videoId:'654683125',videoUrl:'https://vimeo.com/654683125/b247a170db'},
        }
    },



    ],
    teachers:[],
    
  
  
   
  }
  
   /*********************** */
   var NCTEJW_Tabs_Object={ 
    tabs:[
      {
      tabName:"Coastal Alabama Community College",
      tabContent:{
        tabDescription:"Coastal Alabama Community College provides university parallel programs that transfer to senior colleges and universities and workforce development and technical skills training that increases access to employment, enhances career advancement, and supports local economic needs.",
        tabLink:"http://www.coastalalabama.edu/programs/ ",
        tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'-h5TvxNexX8',srcOrHref:'https://youtu.be/-h5TvxNexX8'},
        }
      },
      {
        tabName:"Bishop State Community College",
        tabContent:{
          tabDescription:"Bishop State Community College is a state-supported, open-admission, urban community college located in Mobile, Alabama. It is the college’s mission to provide students with open access to a quality and affordable education that equips them with the skills and knowledge needed to successfully enter the workforce and thrive.",
          tabLink:"https://www.bishop.edu/programs/career-technical-education ",
          tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'mc1p2JexPfE',srcOrHref:'https://youtu.be/mc1p2JexPfE'},
          }
        },
        {
          tabName:"Reid State Technical College",
          tabContent:{
            tabDescription:"Reid State Technical College is a degree-granting, two-year institution that provides quality academic and technical education to students from diverse backgrounds and abilities. The college promotes economic growth by preparing a qualified workforce for business and industry.",
            tabLink:"https://www.rstc.edu/programs",
            tabMedia:{imageOrVideo:'',videoType:'',videoId:'',srcOrHref:''},
            }
          },
          {
            tabName:"University of Mobile",
            tabContent:{
              tabDescription:"With over 90 areas of study, University of Mobile believes strong academics is vital in a changing world. Faculty of the highest caliber train and educate students to be the best in their field of study.",
              tabLink:"https://umobile.edu/academics/",
              tabMedia:{imageOrVideo:'',videoType:'',videoId:'',srcOrHref:''},
              }
          },
          {
            tabName:"University of South Alabama",
            tabContent:{
              tabDescription:"Today, USA provides high-quality education in business, the liberal arts, education, engineering, computing, the sciences and healthcare. It offers more than 100 undergraduate, graduate and doctoral programs through its 10 colleges and schools. The University campus includes USA Technology & Research Park, an incubator of economic development and technological innovation. On the east side of Mobile Bay, USA’s Baldwin County Campus provides eastern shore residents convenient access to educational programs. The University of South Alabama encompasses a comprehensive health system composed of two hospitals — USA University Hospital and USA Children’s & Women’s Hospital — along with the USA Mitchell Cancer Institute and the USA Physicians Group. This combination of advanced healthcare services has improved the lives of countless residents throughout the entire region.",
              tabLink:"https://www.southalabama.edu",
              tabMedia:{imageOrVideo:'',videoType:'',videoId:'',srcOrHref:''},
              }
          },
          {
            tabName:"Spring Hill College",
            tabContent:{
              tabDescription:"Spring Hill College offers academic programs for undergraduate students, graduate students and adult learners in the Jesuit, Catholic educational tradition. At Spring Hill College, the average class size is 17 students and our student to faculty ratio is 14:1. Through a combination of experiences both inside and outside of the classroom, our students are encouraged to actively explore their skills, talents, aspirations and career potential. Internships, service and networking opportunities complement this process and prepare them for the next step on their personal and career paths.",
              tabLink:"https://www.shc.edu/",
              tabMedia:{imageOrVideo:'',videoType:'',videoId:'',srcOrHref:''},
              }
          },
          {
            tabName:"Career Look Book",
            tabContent:{
              tabDescription:"Use the Career Look Book below to begin preparing a successful future. It contains helpful activities and resources such as a sample job application and interview tips.",
              tabLink:"https://sawdcalabamaworks.com/wp-content/uploads/High-School-Look-Book.-2.20.2020.pdf",
              tabMedia:{imageOrVideo:'',videoType:'',videoId:'',srcOrHref:''},
              }
          },
          {
            tabName:"Financial Literacy Resources",
            tabContent:{
              tabDescription:"Watch the video to learn about tips on how to save money and use the expense calculator below to begin creating a budget for the future!",
              tabLink:"https://sawdcalabamaworks.com/expense-calculator/",
              tabMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'a5UMk5H0P_A',srcOrHref:'https://youtu.be/a5UMk5H0P_A'},
              }
          },
          {
            tabName:"WOO Brochure",
            tabContent:{
              tabDescription:"",
              tabLink:"https://sawdcalabamaworks.com/wp-content/uploads/SAWDC-WoO-2021-Bklt-PROOF-REV-4.12.21.pdf",
              tabMedia:{imageOrVideo:'',videoType:'',videoId:'',srcOrHref:''},
              }
          },
    ]
  }
  
  