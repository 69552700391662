import { render, staticRenderFns } from "./it_form.vue?vue&type=template&id=a1320b4e&scoped=true&"
import script from "./it_form.vue?vue&type=script&lang=js&"
export * from "./it_form.vue?vue&type=script&lang=js&"
import style0 from "./it_form.vue?vue&type=style&index=0&id=a1320b4e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1320b4e",
  null
  
)

export default component.exports