
var badgeSrcMixin = {
    methods: {
      selectBadgeSrc: function (region,inputName) {
        var fullWorldName={
          sawdc:{
            aerospace_and_aviation:"aerospace",
            construction:"construct",
            energy:"energy",
            engineering_and_design:"engineer",
            healthcare:"health",
            hospitality_and_tourism:"tourism",
            information_technology:"it",
            manufacturing:"manufacture",
            public_service:"law",
            safety:"safety",
            shipbuilding_and_repair:"shipbuild",
            transportation_distribution_and_logistics:"transport"
          },
          waw:{
            agriculture:"ag",
            construction:"construct",
            cosmetology:"cosmetology",
            education:"educate",
            energy_and_utilities:"energy",
            engineering_and_environment:"engineer",
            healthcare:"health",
            hospitality_and_tourism:"tourism",
            manufacturing_auto_logistics:"manufacture",
            public_safety:"safety",
            transportation:"transport"
          },
          caw:{
            agriculture:"ag",
            architecture_construction_and_utilities:"construct",
            communication_and_information_technology:"it",
            education_and_training:"educate",
            energy_and_utilities:"energy",
            healthcare:"health",
            hospitality_and_tourism:"tourism",
            manufacturing:"manufacture",
            public_service:"law",
            transportation_distribution_and_logistics:"transport"
          },
          naw:{
            //career_resources:" Career Resources",
            modern_manufacturing:"manufacture",
            aerospace:"aerospace",
            agriculture_food_and_natural_resources:"ag",
            architecture_and_construction:"construct",
            modern_manufacturing_auto:"auto",
            business_and_finance:"finance",
            communications_media_and_marketing:"marketing",
            cybersecurity_and_information_technology:"it",
            education_and_training:"educate",
            energy_and_utilities:"energy",
            healthcare:"health",
            hospitality_and_tourism:"tourism",
            law_public_safety_and_corrections:"law",
            transportation_distribution_and_logistics:"transport",

          },
          eaw:{
            agriculture_and_natural_resources:"ag",
            automotive_and_manufacturing:"manufacture",
            communications_media_and_marketing:"marketing",
            construction:"construct",
            energy_and_utilities:"energy",
            engineering:"engineer",
            healthcare:"health",
            information_technology:"it",
            public_safety:"safety",
            transportation_distribution_and_logistics:"transport",
          },
        nctejw:{ 
          }
          }
          return fullWorldName[region][inputName]
      }
    }
  };
  export default  badgeSrcMixin;
