
import { getField, updateField } from 'vuex-map-fields';
class badge {
  constructor(badgeName,badgeSrc) {
    this.badgeName= badgeName
    this.badgeSrc= badgeSrc
 
  }
}
class mission {
  constructor(missionName) {
    this.missionName= missionName
 
  }
}
var missionsObjects={
  healthcare:[], 
  construction:[] ,
  aerospace:[], 
  aerospace_and_aviation:[],
  communications_media_and_marketing:[],
  energy_and_utilities:[],
  hospitality_and_tourism:[], 
    //sawdc exclusive
  /*#############################################################*/
  energy:[],
  engineering_and_design:[],
  information_technology:[],
  manufacturing:[],
  public_service:[],
  safety:[], 
  shipbuilding_and_repair:[], 
  transportation_distribution_and_logistics:[], 
  //waw
  /*#############################################################*/
  agriculture:[],      
  cosmetology:[],      
  education:[], 
  engineering_and_environment:[], 
  manufacturing_auto_logistics:[], 
  public_safety:[], 
  transportation:[], 
  // eaw
  agriculture_and_natural_resources:[],
  automotive_and_manufacturing:[],
  engineering:[],
  //caw 
  communication_and_information_technology:[],
  architecture_construction_and_utilities:[],
  //naw 
  career_resources:[],
  modern_manufacturing:[],
  agriculture_food_and_natural_resources:[],
  modern_manufacturing_auto:[],
  business_and_finance:[],
  cybersecurity_and_information_technology:[],
  education_and_training:[],
  law_public_safety_and_corrections:[],
  
}
var achieveObjects={
  healthcare:[], 
  construction:[] ,
  aerospace:[], 
  aerospace_and_aviation:[],

  communications_media_and_marketing:[],
  energy_and_utilities:[],
  hospitality_and_tourism:[], 
    //sawdc exclusive
  /*#############################################################*/
  energy:[],
  engineering_and_design:[],
  information_technology:[],
  manufacturing:[],
  public_service:[],
  safety:[], 
  shipbuilding_and_repair:[], 
  transportation_distribution_and_logistics:[], 
  //waw
  /*#############################################################*/
  agriculture:[],      
  cosmetology:[],      
  education:[], 
  engineering_and_environment:[], 
  manufacturing_auto_logistics:[], 
  public_safety:[], 
  transportation:[], 
  // eaw
  agriculture_and_natural_resources:[],
  automotive_and_manufacturing:[],
  engineering:[],
  //caw 
  communication_and_information_technology:[],
  architecture_construction_and_utilities:[],
  //naw 
  career_resources:[],
  modern_manufacturing:[],
  agriculture_food_and_natural_resources:[],
  modern_manufacturing_auto:[],
  business_and_finance:[],
  cybersecurity_and_information_technology:[],
  education_and_training:[],
  law_public_safety_and_corrections:[],
}
class achieve {
  constructor(achieveName) {
    this.achieveName= achieveName
 
  }
}
class awardSet {
    constructor() {
      this.badges=[]
      this.points= 0
      this.secretAchievements=[]
    //  this.pointsremaining= q2;
      this.completedMissions = missionsObjects;
      this.completedAchievements = achieveObjects;
    }
  }

const state = {
   
      currentSession: new awardSet()

      };
  const getters = {
    getField,

  };
  const mutations = {
    updateField,
    ADD_BADGE(state, payload) {
     
      state.currentSession.badges.push(new badge(payload.badgeName,payload.badgeSrc)) ;
    },
    ADD_POINTS(state, payload) {
      //console.log(payload.points)
      

      state.currentSession.points+=payload.points;
    },
    
    ADD_COMP_MISSIONS(state, payload) {
     console.log(payload.missionName)
     console.log(payload.world)
     
      state.currentSession.completedMissions[payload.world].push(new mission(payload.missionName)) ;
      console.log(state.currentSession.completedMissions[payload.world])

    },
    ADD_COMP_ACHIEVE(state, payload) {
     console.log(payload.world)
      state.currentSession.completedAchievements[payload.world].push(new achieve(payload.achieveName)) ;
      console.log(state.currentSession.completedAchievements[payload.world])

    },
    ADD_SECRET_ACHIEVE(state, payload) {
      //console.log(payload.world)
       state.currentSession.secretAchievements.push(new achieve(payload.achieveName)) ;
 
     },
  };
  const actions = {
   
    }
    const awards_store = {
        namespaced: true,
        actions,
        mutations,
        state,
        //modules,
        getters
      };
       
  export default awards_store;
  