
var worldNameMixin = {
    methods: {
      selectProduct: function (region,inputName) {
        var fullWorldName={
          sawdc:{
            aerospace_and_aviation:"Aerospace & Aviation",
            construction:"Construction",
            energy:"Energy",
            engineering_and_design:"Engineering & Design",
            healthcare:"Healthcare",
            hospitality_and_tourism:"Hospitality & Tourism",
            information_technology:"Information Technology",
            manufacturing:"Manufacturing",
            public_service:"Public Service",
            safety:"Safety",
            shipbuilding_and_repair:"Shipbuilding & Repair",
            transportation_distribution_and_logistics:"Transportation, Distribution & Logistics"
          },
          waw:{
            agriculture:"Agriculture",
            construction:"Construction",
            cosmetology:"Cosmetology",
            education:"Education",
            energy_and_utilities:"Energy & Utilities",
            engineering_and_environment:"Engineering & Environment",
            healthcare:"Healthcare",
            hospitality_and_tourism:"Hospitality & Tourism",
            manufacturing_auto_logistics:"Manufacturing, Auto, Logistics",
            public_safety:" Public Safety",
            shipbuilding_and_repair:"Shipbuilding & Repair",
            transportation:"Transportation"
          },
          caw:{
            manufacturing:" Manufacturing",
            healthcare:"Healthcare",
            architecture_construction_and_utilities:"Architecture, Construction & Utilities",
            energy_and_utilities:"Energy & Utilities",
            communication_and_information_technology:"Communication & Information Technology",
            transportation_distribution_and_logistics:"Transportation, Distribution & Logistics",
            agriculture:"Agriculture",
            education_and_training:" Education & Training",
            hospitality_and_tourism:"Hospitality & Tourism",
            public_service:"Public Service",
          },
          naw:{
            aerospace:"Aerospace",
            agriculture_food_and_natural_resources:"Agriculture, Food & Natural Resources",
            architecture_and_construction:"Architecture & Construction",
            business_and_finance:"Business & Finance",
            communications_media_and_marketing:"Communications, Media & Marketing",
            cybersecurity_and_information_technology:" Cybersecurity & Information Technology",
            education_and_training:"Education & Training",
            energy_and_utilities:"Energy & Utilities",
            healthcare:"Healthcare",
            hospitality_and_tourism:"Hospitality & Tourism",
            modern_manufacturing:"Modern Manufacturing",
            modern_manufacturing_auto:"Modern Manufacturing-Auto",
            law_public_safety_and_corrections:"Law, Public Safety, & Corrections",
            transportation_distribution_and_logistics:"Transportation, Distribution & Logistics",
   

          },
          eaw:{
            agriculture_and_natural_resources:"Agriculture & Natural Resources",
            automotive_and_manufacturing:"Automotive & Manufacturing",
            communications_media_and_marketing:"Communications, Media & Marketing",
            construction:"Construction",
            energy_and_utilities:"Energy & Utilities",
            engineering:"Engineering",
            healthcare:"Healthcare",
            information_technology:"Information Technology",
            public_safety:"Public Safety",
            transportation_distribution_and_logistics:"Transportation, Distribution, and Logistics",
          },
           /*********************** */
  nctejw:{ 
    aerospace_and_aviation:"Aerospace & Aviation",
    construction:"Construction",
    energy:"Energy",
    engineering_and_design:"Engineering & Design",
    healthcare:"Healthcare",
    hospitality_and_tourism:"Hospitality & Tourism",
    information_technology:"Information Technology",
    manufacturing:"Manufacturing",
    public_service:"Public Service",
    safety:"Safety",
    shipbuilding_and_repair:"Shipbuilding & Repair",
    transportation_distribution_and_logistics:"Transportation, Distribution & Logistics"
  }
  
  
          }
          return fullWorldName[region][inputName]
      }
    }
  };
  export default worldNameMixin;
