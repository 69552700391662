//copy this to create a new all world template.  database eventually.

var bikeInfoMixin  = {
    methods: {
      selectBikeInfo: function (regionName) {
        var worldsArrays={
            SAWDC:SAWDC_Home_Object,
            CAW:CAW_Home_Object,
            WAW:WAW_Home_Object,
            NAW:NAW_Home_Object,
            EAW:EAW_Home_Object,
            NCTEJW:NCTEJW_Quiz_Object
  
          }
          return worldsArrays[regionName]
      }
    }
  };
  export default bikeInfoMixin
  /*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var SAWDC_Home_Object={ 
    welcomeVideo:{type:"",link:"https://www.youtube.com/embed/YcwU3vtyu0E?playlist=YcwU3vtyu0E&loop=1&rel=0"},

     column1:{
            static:true,
            logos:[
              {src:"https://sawdcalabamaworks.com/wp-content/uploads/woo-logo.jpg", metaprep:""}]
     },
     column2:{
      static:true,
      logos:[
        {src:"https://sawdcalabamaworks.com/wp-content/uploads/alabama-power-logo.jpg", metaprep:""}]
     },
     column3:{
       static:true,
       logos:[
        {src:"https://sawdcalabamaworks.com/wp-content/uploads/cacc-logo.jpg", metaprep:""}]
    },
    column4:{
      static:true,
      logos:[
        {src:"https://sawdcalabamaworks.com/wp-content/uploads/mcps-logo.jpg", metaprep:""}]
    },
    column5:{
       static:false,
       active:true,
       logos:[
       {src:"https://sawdcalabamaworks.com/wp-content/uploads/amns-logo.jpg", metaprep:""},
       {src:"https://sawdcalabamaworks.com/wp-content/uploads/ar-group-logo.jpg", metaprep:""},
       {src:"https://sawdcalabamaworks.com/wp-content/uploads/ascension-logo.jpg", metaprep:""},
       {src:"https://sawdcalabamaworks.com/wp-content/uploads/austal-logo.jpg", metaprep:""},
       {src:"https://sawdcalabamaworks.com/wp-content/uploads/basf-logo.jpg", metaprep:""},
       {src:"https://sawdcalabamaworks.com/wp-content/uploads/bishop-logo.jpg", metaprep:""},
       {src:"https://sawdcalabamaworks.com/wp-content/uploads/evonik-logo.jpg", metaprep:""},
       {src:"https://sawdcalabamaworks.com/wp-content/uploads/hargrove-logo.jpg", metaprep:""},
       {src:"https://sawdcalabamaworks.com/wp-content/uploads/infirmary-logo.jpg", metaprep:""},
       {src:"https://sawdcalabamaworks.com/wp-content/uploads/ingalls-logo.jpg", metaprep:""},
       {src:"https://sawdcalabamaworks.com/wp-content/uploads/mobile-county-logo.jpg", metaprep:""}
      ],
      swiperOption5:{spaceBetween: 30,
        centeredSlides: true,
        effect: 'fade',
        autoplay: {
          delay: 6400,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }}
   },
   column6:{
      static:false,
      active:true,
      logos:[
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/aidt-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/bbva-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/boys-girls-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/daphne-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/gateway-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/gssc-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/reid-state-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/riviera-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/usa-logo.jpg", metaprep:""}
    ],
    swiperOption6:{spaceBetween: 30,
      centeredSlides: true,
      effect: 'fade',
      autoplay: {
        delay: 7400,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }}
  }

}
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var WAW_Home_Object={ 
    welcomeVideo:{type:"",link:"https://www.youtube.com/embed/OfQmyh-KhEk?playlist=OfQmyh-KhEk&loop=1&rel=0"},
    

      column1:{
        active:true,
        static:false,
        logos:[
          {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/mercedes-benz-logo.jpg",metaprep:""} ,
         { src:"https://waw-image.s3.amazonaws.com/wawfrontimages/bfgoodrich-logo.jpg",metaprep:""},
         {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/bolzoni-logo.jpg",metaprep:""},
         {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/go-build-logo.jpg", metaprep:""},
         {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/transfervr-logo.jpg", metaprep:""},],
      swiperOption1:{spaceBetween: 30,
       centeredSlides: true,
       effect: 'fade',
       autoplay: {
         delay: 7400,
         disableOnInteraction: false
       },
       pagination: {
         el: '.swiper-pagination',
         clickable: true
       },
       navigation: {
         nextEl: '.swiper-button-next',
         prevEl: '.swiper-button-prev'
       }}
 
      },
      column2:{
        active:true,
        static:false,
        logos:[
         { src:"https://waw-image.s3.amazonaws.com/wawfrontimages/mcabee-logo.jpg",metaprep:""},
         {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/phifer-logo.jpg",metaprep:""},
         {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/shelton-state-logo.jpg", metaprep:""},
         {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/smp-logo.jpg",metaprep:""} ],
      swiperOption2:{spaceBetween: 30,
       centeredSlides: true,
       effect: 'fade',
       autoplay: {
         delay: 6400,
         disableOnInteraction: false
       },
       pagination: {
         el: '.swiper-pagination',
         clickable: true
       },
       navigation: {
         nextEl: '.swiper-button-next',
         prevEl: '.swiper-button-prev'
       }}
 
      },
      column3:{
        active:true,
        static:false,
        logos:[
         { src:"https://waw-image.s3.amazonaws.com/wawfrontimages/harrison-logo.jpg",metaprep:""},
         {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/nucor-logo.jpg",metaprep:""},
         {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/premier-service-logo.jpg", metaprep:""},
         {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/buffalorock-logo.jpg", metaprep:""},
         {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/ellis-logo.jpg", metaprep:""}
        
        ],
      swiperOption3:{spaceBetween: 30,
       centeredSlides: true,
       effect: 'fade',
       autoplay: {
         delay: 8000,
         disableOnInteraction: false
       },
       pagination: {
         el: '.swiper-pagination',
         clickable: true
       },
       navigation: {
         nextEl: '.swiper-button-next',
         prevEl: '.swiper-button-prev'
       }}
 
      },
     column4:{
      active:true,
      static:false,
      logos:[
       { src:"https://waw-image.s3.amazonaws.com/wawfrontimages/bolta-logo.jpg",metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/builders-group-logo.jpg",metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/dch-logo.jpg", metaprep:""},
    {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/hunt-logo.jpg",metaprep:""},
    {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/onin-logo.jpg",metaprep:""},
    {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/spire-logo.jpg",metaprep:""},
    {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/war-logo.jpg",metaprep:""},
    {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/westrock-logo.jpg",metaprep:""},
    {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/knightsigns-logo.jpg",metaprep:""},
    {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/lear-logo.jpg",metaprep:""},
    {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/zf-chasis-logo.jpg",metaprep:""} ],
    swiperOption4:{spaceBetween: 30,
     centeredSlides: true,
     effect: 'fade',
     autoplay: {
       delay: 5000,
       disableOnInteraction: false
     },
     pagination: {
       el: '.swiper-pagination',
       clickable: true
     },
     navigation: {
       nextEl: '.swiper-button-next',
       prevEl: '.swiper-button-prev'
     }}

    },
     column5:{
      active:true,
      static:false,
      logos:[
       { src:"https://waw-image.s3.amazonaws.com/wawfrontimages/alabama-power-logo.jpg",metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/ard-logo.jpg",metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/atn-logo.jpg", metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/aw-aidt-logo.jpg",metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/bevill-state-logo.jpg",metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/dixie-mechanical-logo.jpg", metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/schnellecke-logo.jpg",metaprep:""},
         {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/lavender-logo.jpg",metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/tuscaloosa-tourism-logo.jpg", metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/blg-logo.jpg",metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/uac-engineering-logo.jpg",metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/tuscaloosa-city-logo.jpg", metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/synovus-logo.jpg",metaprep:""},   
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/enviva-logo.jpg",metaprep:""},    
 
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/southland-logo.jpg",metaprep:""} ],
    swiperOption5:{spaceBetween: 30,
     centeredSlides: true,
     effect: 'fade',
     autoplay: {
       delay: 9402,
       disableOnInteraction: false
     },
     pagination: {
       el: '.swiper-pagination',
       clickable: true
     },
     navigation: {
       nextEl: '.swiper-button-next',
       prevEl: '.swiper-button-prev'
     }}

    },
    column6:{
      active:true,
      static:false,
      logos:[
       { src:"https://waw-image.s3.amazonaws.com/wawfrontimages/bryant-bank-logo.jpg",metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/chevy-logo.jpg",metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/jolly-logo.jpg", metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/university-alabama-logo.jpg",metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/uwa-logo.jpg",metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/wallace-logo.jpg",metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/hamptoninn-logo.jpg", metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/holidayinn-logo.jpg",metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/bibb-logo.jpg",metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/lincoln-logo.jpg",metaprep:""},
       {src:"https://waw-image.s3.amazonaws.com/wawfrontimages/alabama-auto-logo.jpg",metaprep:""}
       ],
    swiperOption6:{spaceBetween: 30,
     centeredSlides: true,
     effect: 'fade',
     autoplay: {
       delay: 9780,
       disableOnInteraction: false
     },
     pagination: {
       el: '.swiper-pagination',
       clickable: true
     },
     navigation: {
       nextEl: '.swiper-button-next',
       prevEl: '.swiper-button-prev'
     }}

    }
   
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var NAW_Home_Object={ 
    welcomeVideo:{type:"",link:"https://www.youtube.com/embed/YSYBr0Bx62w?playlist=YSYBr0Bx62w&loop=1&rel=0"},
      column1:{
             static:true,
             logos:[
               {src:"https://www.northalabamaworks.com/wp-content/uploads/naw-wow-logo.jpg", metaprep:""}]
      },
      column2:{
       static:true,
       logos:[
         {src:"https://www.northalabamaworks.com/wp-content/uploads/bevill-logo.jpg", metaprep:""}]
      },
      column3:{
        static:true,
        logos:[
         {src:"https://www.northalabamaworks.com/wp-content/uploads/athens-logo.jpg", metaprep:""}]
     },
     column4:{
       static:true,
       logos:[
         {src:"https://www.northalabamaworks.com/wp-content/uploads/mazda-logo.jpg", metaprep:""}]
     },
     column5:{
        static:true,
        logos:[
        {src:"https://www.northalabamaworks.com/wp-content/uploads/freedom-logo.jpg", metaprep:""}]
    },
    column6:{
      static:false,
      active: true,
       logos:[
       {src:"", metaprep:""}]
   }
   
  
   
  }
   /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var CAW_Home_Object={ 
    welcomeVideo:{type:"",link:"https://www.youtube.com/embed/SwIYVdXqkAI?controls=0&playlist=SwIYVdXqkAI&loop=1&rel=0"},
    
      column1:{

       active:true,
       static:false,
       logos:[
        { src:"https://centralalabamaworks.com/wp-content/uploads/alabama-power-logo.jpg",metaprep:""},
        {src:"https://centralalabamaworks.com/wp-content/uploads/auburn-opelika-logo.jpg",metaprep:""},
        {src:"https://centralalabamaworks.com/wp-content/uploads/phenix-city.jpg", metaprep:""}, ],
     swiperOption1:{spaceBetween: 30,
      centeredSlides: true,
      effect: 'fade',
      autoplay: {
        delay: 4200,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }}

     
      },
      column2:{

        active:true,
        static:false,
        logos:[
         { src:"https://centralalabamaworks.com/wp-content/uploads/go-build-logo-1.jpg",metaprep:""},
         {src:"https://centralalabamaworks.com/wp-content/uploads/eamc.jpg",metaprep:""},
         {src:"https://centralalabamaworks.com/wp-content/uploads/james-hardie-logo.jpg", metaprep:""}, ],
      swiperOption2:{spaceBetween: 30,
       centeredSlides: true,
       effect: 'fade',
       autoplay: {
         delay: 4200,
         disableOnInteraction: false
       },
       pagination: {
         el: '.swiper-pagination',
         clickable: true
       },
       navigation: {
         nextEl: '.swiper-button-next',
         prevEl: '.swiper-button-prev'
       }}
 
      
       },
      column3:{

        active:true,
        static:false,
        logos:[
         { src:"https://centralalabamaworks.com/wp-content/uploads/hyundai-logo-1.jpg",metaprep:""},
         {src:"https://centralalabamaworks.com/wp-content/uploads/hager-logo.jpg",metaprep:""},
         {src:"https://centralalabamaworks.com/wp-content/uploads/trenholm-state-logo-1.jpg", metaprep:""}, ],
      swiperOption3:{spaceBetween: 30,
       centeredSlides: true,
       effect: 'fade',
       autoplay: {
         delay: 4200,
         disableOnInteraction: false
       },
       pagination: {
         el: '.swiper-pagination',
         clickable: true
       },
       navigation: {
         nextEl: '.swiper-button-next',
         prevEl: '.swiper-button-prev'
       }}
 
      
       },
     column4:{

      active:true,
      static:false,
      logos:[
       { src:"https://centralalabamaworks.com/wp-content/uploads/baptist-logo.jpg",metaprep:""},
       {src:"https://centralalabamaworks.com/wp-content/uploads/central-community-2.jpg",metaprep:""},
       {src:"https://centralalabamaworks.com/wp-content/uploads/neptune-2.jpg", metaprep:""},
       {src:"https://centralalabamaworks.com/wp-content/uploads/southern-union-logo.jpg", metaprep:""},],
    swiperOption4:{spaceBetween: 30,
     centeredSlides: true,
     effect: 'fade',
     autoplay: {
       delay: 3500,
       disableOnInteraction: false
     },
     pagination: {
       el: '.swiper-pagination',
       clickable: true
     },
     navigation: {
       nextEl: '.swiper-button-next',
       prevEl: '.swiper-button-prev'
     }}

    
     },
     column5:{
       active:true,
        static:false,
        logos:[

          {src:"https://centralalabamaworks.com/wp-content/uploads/bullock-1.jpg",metaprep:"" },
          {src:"https://centralalabamaworks.com/wp-content/uploads/aidt-2.jpg",metaprep:"" },
          {src:"https://centralalabamaworks.com/wp-content/uploads/central-electric-1.jpg",metaprep:""} ,
          {src:"https://centralalabamaworks.com/wp-content/uploads/chatt-valley-1.jpg",metaprep:""}, 
          {src:"https://centralalabamaworks.com/wp-content/uploads/dixie-hvac-1.jpg",metaprep:""},
          {src:"https://centralalabamaworks.com/wp-content/uploads/frasle-logo.jpg",metaprep:""}, 
          {src:"https://centralalabamaworks.com/wp-content/uploads/gkn-aero-1.jpg",metaprep:""},  ],
          swiperOption5:{spaceBetween: 30,
            centeredSlides: true,
            effect: 'fade',
            autoplay: {
              delay: 2200,
              disableOnInteraction: false
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }}

    },
    column6:{
      active:true,
       static:false,
       logos:[
        {src:"https://centralalabamaworks.com/wp-content/uploads/int-paper-logo.jpg", metaprep:""}, 
        {src:"https://centralalabamaworks.com/wp-content/uploads/mont-chamber-1.jpg",metaprep:""}, 
        {src:"https://centralalabamaworks.com/wp-content/uploads/rapa-logo.jpg",metaprep:""}, 
        {src:"https://centralalabamaworks.com/wp-content/uploads/selma-dallas-1.jpg",metaprep:""}, 
        {src:"https://centralalabamaworks.com/wp-content/uploads/vaughan-1.jpg",metaprep:""}, 
        {src:"https://centralalabamaworks.com/wp-content/uploads/wallace-college-1.jpg" ,metaprep:""}, 
        {src:"https://centralalabamaworks.com/wp-content/uploads/westrock-1.jpg",metaprep:""}, 
        
        ],
        swiperOption6:{ spaceBetween: 30,
          centeredSlides: true,
          effect: 'fade',
          autoplay: {
            delay: 2200,
            disableOnInteraction: false
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }}

   }
   
  
   
  }
    /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var EAW_Home_Object={ 
    welcomeVideo:{type:"",link:"https://www.youtube.com/embed/nEeTSvAHo_k?playlist=nEeTSvAHo_k&loop=1&rel=0"},

      column1:{
        active:true,
             static:true,
             logos:[
               {src:"https://eastalabamaworks.com/wp-content/uploads/east-wow-logo.jpg", metaprep:""}],
               swiperOption1:{ spaceBetween: 30,
                centeredSlides: true,
                effect: 'fade',
                autoplay: {
                  delay: 6300,
                  disableOnInteraction: false
                },
                pagination: {
                  el: '.swiper-pagination',
                  clickable: true
                },
                navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev'
                }}
      },
      column2:{
        active:true,
       static:false,
       logos:[
        {src:"https://eastalabamaworks.com/wp-content/uploads/alabama-power-logo-1.jpg" , metaprep:""},
        {src:"https://eastalabamaworks.com/wp-content/uploads/go-build-logo-1.jpg" , metaprep:""},
        {src:"https://eastalabamaworks.com/wp-content/uploads/honda-logo.jpg" , metaprep:""},
        {src:"https://eastalabamaworks.com/wp-content/uploads/jacksonville-state-logo.jpg", metaprep:""},
        {src:"https://eastalabamaworks.com/wp-content/uploads/oxford-logo-1.jpg" , metaprep:""},
        {src:"https://eastalabamaworks.com/wp-content/uploads/regions-logo.jpg" , metaprep:""},
        {src:"https://eastalabamaworks.com/wp-content/uploads/sunny-king-logo.jpg" , metaprep:""},
        {src:"https://eastalabamaworks.com/wp-content/uploads/wellborn-logo.jpg" , metaprep:""},
        ],
        swiperOption2:{ spaceBetween: 30,
          centeredSlides: true,
          effect: 'fade',
          autoplay: {
            delay: 9400,
            disableOnInteraction: false
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }}
      },
      column3:{
        active:true,
        static:false,
        logos:[
          {src:"https://eastalabamaworks.com/wp-content/uploads/aidt-logo.jpg" ,metaprep:""},
          {src:"https://eastalabamaworks.com/wp-content/uploads/bbt-logo.jpg" ,metaprep:""},
          {src:"https://eastalabamaworks.com/wp-content/uploads/br-williams-logo.jpg" ,metaprep:""}, 
          {src:"https://eastalabamaworks.com/wp-content/uploads/bridgewater-logo.jpg" ,metaprep:""}, 
          {src:"https://eastalabamaworks.com/wp-content/uploads/gadsden-state-logo-2.jpg" ,metaprep:""},
          {src:"https://eastalabamaworks.com/wp-content/uploads/kronospan-logo.jpg" ,metaprep:""},
          {src:"https://eastalabamaworks.com/wp-content/uploads/littleton-logo.jpg" ,metaprep:""}, 
          {src:"https://eastalabamaworks.com/wp-content/uploads/new-leaf-logo.jpg" ,metaprep:""}],
          swiperOption3:{ spaceBetween: 30,
            centeredSlides: true,
            effect: 'fade',
            autoplay: {
              delay: 7400,
              disableOnInteraction: false
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }}
     },
     column4:{
       active:true,
       static:false,
       logos:[
        {src:"https://eastalabamaworks.com/wp-content/uploads/aama-logo.jpg" ,metaprep:""},
        {src:"https://eastalabamaworks.com/wp-content/uploads/abc-logo.jpg" ,metaprep:""},
        {src:"https://eastalabamaworks.com/wp-content/uploads/alabama-poultry-logo.jpg" ,metaprep:""}, 
        {src:"https://eastalabamaworks.com/wp-content/uploads/alfa-logo.jpg" ,metaprep:""},
        {src:"https://eastalabamaworks.com/wp-content/uploads/anniston-water-logo.jpg" ,metaprep:""} ,
        {src:"https://eastalabamaworks.com/wp-content/uploads/calhoun-logo.jpg" ,metaprep:""},
        {src:"https://eastalabamaworks.com/wp-content/uploads/rmc-logo.jpg" ,metaprep:""},
        {src:"https://eastalabamaworks.com/wp-content/uploads/southwire-logo.jpg" ,metaprep:""}, 
        {src:"https://eastalabamaworks.com/wp-content/uploads/spire-logo.jpg" ,metaprep:""},],
        swiperOption4:{ spaceBetween: 30,
          centeredSlides: true,
          effect: 'fade',
          autoplay: {
            delay: 10200,
            disableOnInteraction: false
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }}
     },
     column5:{
       active:true,
        static:false,
        logos:[
          {src:"https://eastalabamaworks.com/wp-content/uploads/community-foundation-logo.jpg" ,metaprep:""},
          {src:"https://eastalabamaworks.com/wp-content/uploads/coosa-valley-logo.jpg" ,metaprep:""},
          {src:"https://eastalabamaworks.com/wp-content/uploads/heffin-logo.jpg" ,metaprep:""},
          {src:"https://eastalabamaworks.com/wp-content/uploads/hurst-logo.jpg" ,metaprep:""},
          {src:"https://eastalabamaworks.com/wp-content/uploads/new-flyer-logo.jpg",metaprep:""},
          {src:"https://eastalabamaworks.com/wp-content/uploads/ra-lin-logo.jpg",metaprep:""},
          {src:"https://eastalabamaworks.com/wp-content/uploads/randolph-county-logo.jpg",metaprep:""},
          {src:"https://eastalabamaworks.com/wp-content/uploads/webb-logo.jpg" ,metaprep:""},
          {src:"https://eastalabamaworks.com/wp-content/uploads/wm-grocery-logo.jpg" ,metaprep:""},
          ],
          swiperOption5:{ spaceBetween: 30,
            centeredSlides: true,
            effect: 'fade',
            autoplay: {
              delay: 9400,
              disableOnInteraction: false
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }}
    },
    column6:{
      active:true,
       static:true,
       logos:[{src:"https://eastalabamaworks.com/wp-content/uploads/eastman-logo-2.jpg", metaprep:""}
       ],
       swiperOption6:{ spaceBetween: 30,
         centeredSlides: true,
         effect: 'fade',
         autoplay: {
           delay: 9400,
           disableOnInteraction: false
         },
         pagination: {
           el: '.swiper-pagination',
           clickable: true
         },
         navigation: {
           nextEl: '.swiper-button-next',
           prevEl: '.swiper-button-prev'
         }}
   }

  
   
  }
   
  /*********************** */
  var NCTEJW_Quiz_Object={ 
    welcomeVideo:{type:"",link:"https://www.youtube.com/embed/EtxkUN-AQoI?loop=1&rel=0"},

    column1:{
           static:true,
           logos:[
             {src:"https://nctejw.s3.us-east-2.amazonaws.com/nctejwfrontimages/monitor-default.jpg", metaprep:""}]
    },
    column2:{
     static:true,
     logos:[
       {src:"https://sawdcalabamaworks.com/wp-content/uploads/alabama-power-logo.jpg", metaprep:""}]
    },
    column3:{
      static:true,
      logos:[
       {src:"https://sawdcalabamaworks.com/wp-content/uploads/cacc-logo.jpg", metaprep:""}]
   },
   column4:{
     static:true,
     logos:[
       {src:"https://sawdcalabamaworks.com/wp-content/uploads/mcps-logo.jpg", metaprep:""}]
   },
   column5:{
      static:false,
      active:true,
      logos:[
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/amns-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/ar-group-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/ascension-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/austal-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/basf-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/bishop-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/evonik-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/hargrove-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/infirmary-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/ingalls-logo.jpg", metaprep:""},
      {src:"https://sawdcalabamaworks.com/wp-content/uploads/mobile-county-logo.jpg", metaprep:""}
     ],
     swiperOption5:{spaceBetween: 30,
       centeredSlides: true,
       effect: 'fade',
       autoplay: {
         delay: 6400,
         disableOnInteraction: false
       },
       pagination: {
         el: '.swiper-pagination',
         clickable: true
       },
       navigation: {
         nextEl: '.swiper-button-next',
         prevEl: '.swiper-button-prev'
       }}
  },
  column6:{
     static:false,
     active:true,
     logos:[
     {src:"https://sawdcalabamaworks.com/wp-content/uploads/aidt-logo.jpg", metaprep:""},
     {src:"https://sawdcalabamaworks.com/wp-content/uploads/bbva-logo.jpg", metaprep:""},
     {src:"https://sawdcalabamaworks.com/wp-content/uploads/boys-girls-logo.jpg", metaprep:""},
     {src:"https://sawdcalabamaworks.com/wp-content/uploads/daphne-logo.jpg", metaprep:""},
     {src:"https://sawdcalabamaworks.com/wp-content/uploads/gateway-logo.jpg", metaprep:""},
     {src:"https://sawdcalabamaworks.com/wp-content/uploads/gssc-logo.jpg", metaprep:""},
     {src:"https://sawdcalabamaworks.com/wp-content/uploads/reid-state-logo.jpg", metaprep:""},
     {src:"https://sawdcalabamaworks.com/wp-content/uploads/riviera-logo.jpg", metaprep:""},
     {src:"https://sawdcalabamaworks.com/wp-content/uploads/usa-logo.jpg", metaprep:""}
   ],
   swiperOption6:{spaceBetween: 30,
     centeredSlides: true,
     effect: 'fade',
     autoplay: {
       delay: 7400,
       disableOnInteraction: false
     },
     pagination: {
       el: '.swiper-pagination',
       clickable: true
     },
     navigation: {
       nextEl: '.swiper-button-next',
       prevEl: '.swiper-button-prev'
     }}
 }
  }
  
  
  