
import { getField, updateField } from 'vuex-map-fields';
class interactedElement{
  constructor(visited, elementid,elementname,interactiontype,component) {
    this.visited = visited;
    this.elementid = elementid;
    this.elementname=elementname;
    this.interactiontype=interactiontype
    this.componentlocation=component

  } 
}

class PageInteractions{
    constructor(page) {
      this.page = page;
      this.pagevisited=false;
      this.clickedelements = {};
      this.suitabilityQuizTaken=false;
      this.classAssessmentTaken=false;
      this.visitedSidebarElementNames=[];
      this.AvailablePoints=400;
      this.careersLearned=[];
      this.pointsAchieved=0;
      this.videoswatched=[];
      this.employmentVideoWatched=false;
    }
  }

const state = {
    //shared 
/*#############################################################*/
healthcare: {
  pagestate:new PageInteractions("healthcare")

},
construction: {
  pagestate:new
   PageInteractions("construction")

},
aerospace_and_aviation: {
  pagestate:new PageInteractions("aerospace_and_aviation")

},      
communications_media_and_marketing:{
  pagestate:new PageInteractions("communications_media_and_marketing")

},
energy_and_utilities: {
  pagestate:new PageInteractions("energy_and_utilities")

  },
  hospitality_and_tourism: {
    pagestate:new PageInteractions("hospitality_and_tourism")

  },
  //sawdc exclusive
/*#############################################################*/
 

    energy: {
      pagestate:new PageInteractions("energy")

      },
      engineering_and_design: {
        pagestate:new PageInteractions("engineering_and_design")

      },

      information_technology: {
        pagestate:new PageInteractions("information_technology")

      },
      

      manufacturing: {
        pagestate:new PageInteractions("manufacturing")

      },
      public_service: {
        pagestate:new PageInteractions("public_service")

      },
      safety: {
        pagestate:new PageInteractions("safety")

      },
      shipbuilding_and_repair: {
        pagestate:new PageInteractions("shipbuilding_and_repair")

      },
      transportation_distribution_and_logistics: {
        pagestate:new PageInteractions("transportation_distribution_and_logistics")

      },

 //waw
/*#############################################################*/
agriculture: {
  pagestate:new PageInteractions("agriculture")

},      
cosmetology: {
  pagestate:new PageInteractions("cosmetology")

},      


    education: {
      pagestate:new PageInteractions("education")

    },
    engineering_and_environment: {
      pagestate:new PageInteractions("engineering_and_environment")

    },
 

    manufacturing_auto_logistics: {
      pagestate:new PageInteractions("manufacturing_auto_logistics")

    },

    public_safety: {
      pagestate:new PageInteractions("public_safety")

    },
    transportation: {
      pagestate:new PageInteractions("transportation")

    },
  // eaw
  agriculture_and_natural_resources:{
    pagestate:new PageInteractions("agriculture_and_natural_resources")

  },
  automotive_and_manufacturing:{
    pagestate:new PageInteractions("automotive_and_manufacturing")

  },

  engineering:{
    pagestate:new PageInteractions("engineering")

  },
  //caw 
  communication_and_information_technology:{
    pagestate:new PageInteractions("communication_and_information_technology")
  
  },
  architecture_construction_and_utilities:{
    pagestate:new PageInteractions("architecture_construction_and_utilities")

  },



  

//naw 

aerospace: {
  pagestate:new PageInteractions("aerospace")

},
modern_manufacturing:{
  pagestate:new PageInteractions("modern_manufacturing")

},
agriculture_food_and_natural_resources:{
  pagestate:new PageInteractions("agriculture_food_and_natural_resources")

},

modern_manufacturing_auto:{
  pagestate:new PageInteractions("modern_manufacturing_auto")

},
business_and_finance:{
  pagestate:new PageInteractions("business_and_finance")

},
cybersecurity_and_information_technology:{
  pagestate:new PageInteractions("cybersecurity_and_information_technology")

},
education_and_training:{
  pagestate:new PageInteractions("education_and_training")

},

law_public_safety_and_corrections:{
  pagestate:new PageInteractions("law_public_safety_and_corrections")

},


  };
  const getters = {
    getField,

  };
  const mutations = {
    updateField,
    ADD_ELEMENTINTERACTION(state, payload) {
      //console.log(payload.worldname)
      //console.log(payload)
      if(
        (payload.isSidebarComponent ==true) 
        && 
        !(state[payload.worldname].pagestate.visitedSidebarElementNames.indexOf(payload.elementid) > -1)
        )
      {
        state[payload.worldname].pagestate.visitedSidebarElementNames.push(payload.elementid)
      }
      state[payload.worldname].pagestate.clickedelements[payload.elementid] = new interactedElement(
        payload.elementstate,
        payload.elementid,
        payload.elementname,
        payload.interactiontype,
        payload.component);
    },
    SET_PAGEVISIT(state, payload) {
      //console.log(payload.world)
      //console.log(payload)
      state[payload.worldname].pagestate.pagevisited=true;
    },
    ADD_CAREERLEARNED(state, payload) {
      console.log(state[payload.worldname].pagestate);
      state[payload.worldname].pagestate.careersLearned.push(payload.careerId);
      console.log(state[payload.worldname].pagestate.careersLearned)
    },
    SET_TOPLEVELPROPERTY(state, payload) {
      //console.log(payload.propertyid)
      //console.log(payload)
      state[payload.worldname].pagestate[payload.propertyid] = payload.value;
    },
    ADD_VIDEOWATCHED(state, payload) {
      //console.log(payload.propertyid)
      //console.log(payload)
      state[payload.worldname].pagestate.employmentVideoWatched=true;
      state[payload.worldname].pagestate.videoswatched.push(payload.videoId) 
    }
    ,
    ADD_POINTS(state, payload) {
      //console.log(payload.points)
      //console.log(payload.worldname)

      state[payload.worldname].pagestate.pointsAchieved+=payload.points;
         //console.log( state[payload.worldname].pagestate.pointsAchieved)    
    }

  };
  const actions = {
   
    }
    const interaction_tracking_store = {
        namespaced: true,
        actions,
        mutations,
        state,
        //modules,
        getters
      };
       
  export default interaction_tracking_store;
  