//copy this to create a new all world template.  database eventually.

var employersInfoMixin  = {
    methods: {
      selectEmployersInfo: function (regionName,worldName) {
        var worldsArrays={
            SAWDC:SAWDC_Employers_Objects,
            CAW:CAW_Quiz_Object,
            WAW:WAW_Quiz_Object,
            NAW:NAW_Quiz_Object,
            EAW:EAW_Quiz_Object,
            NCTEJW:NCTEJW_Quiz_Object

  
          }
          return worldsArrays[regionName][worldName]
      }
    }
  };
  export default employersInfoMixin
  /*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var SAWDC_Employers_Objects={ 
    aerospace_and_aviation:{
      empForBox:[
      {
        employername:'Airbus',
        employersite:"https://www.airbus.com",
        employerLogo:"airbus-thumb.png",
        employerp1:"At Airbus, we believe that it’s not just what we make, but how we make it that counts. We are convinced that our customers, investors and the communities we are working with not only ask us to create products, jobs and shareholder returns, but to promote responsible, sustainable and inclusive business practices and act with integrity.",
        employerp2:"As a proven leader in the global aerospace sector, Airbus designs, produces and delivers innovative solutions with the aim to create a better-connected, safer and more prosperous world.  These cutting-edge products and services – which span the commercial aircraft, helicopter, defense, security and space segments – benefit from our wide-ranging expertise and continued emphasis on innovation.",
        employerp3:"A commercial aircraft manufacturer, with Space and Defense as well as Helicopters Divisions, Airbus is the largest aeronautics and space company in Europe and a worldwide leader. Airbus has built on its strong European heritage to become truly international – with roughly 180 locations and 12,000 direct suppliers globally. The company has aircraft and helicopter final assembly lines across Asia, Europe and the Americas, and has achieved a more than sixfold order book increase since 2000."
            ,images:[
            ]
            ,videos:[
              {
                videoType:'vimeo',videoUrl:"https://vimeo.com/521691260",videoId:'521691260'
              },
              {
                videoType:'vimeo',videoUrl:"https://vimeo.com/521690403",videoId:'521690403'
              }
            ]
        },
        {
          employername:'Coastal Alabama Community College',
          employersite:"http://www.coastalalabama.edu/programs/alabama_aviation_center/",
          employerLogo:"cacc-logo.jpg",
          employerp1:"The Alabama Gulf Coast serves as home to many leaders in the Aviation industry, including Airbus Final Assembly Line (FAL), VT Mobile Aerospace Engineering, Continental Motors, Segers Aero Corporation, Carlisle, Certified Aviation Services, and UTC Aerospace System.  These industries need trained, reliable employees to keep their operations moving, and our programs are designed to prepare you for these career opportunities.",
          employerp2:"",
          employerp3:""
              ,images:[
              ]
              ,videos:[
                {
                  videoType:'youtube',videoUrl:"https://youtu.be/Fl1kxJPhHrI",videoId:'Fl1kxJPhHrI'
                }
              ]
          },
        {
      employername:'ST Engineering',
      employerLogo:"st-engineering-thumb.png",
        employersite:"https://www.stengg.com/en/singapore-technologies-engineering",
        employerp1:"We keep the world flying safely. We have over 45 years of experience in aviation, backed by a highly experienced team of engineers and technicians across facilities in Asia Pacific, the U.S. and Europe.",
        employerp2:"We have proven solutions for practically every stage of an aircraft life cycle, from design and engineering, original equipment manufacturing, nose-to-tail aftermarket and maintenance services as well as assets management and leasing. Through passenger-to-freighter conversion or refurbishment, we give ageing aircraft a new lease of life. ",
        employerp3:"We are the world’s largest airframe MRO solution provider with the capacity to work on up to 44 widebody, 26 narrow body and 24 general aviation aircraft at any one time. As an OEM, we specialize in engine nacelle and composite panels, and are the only company offering Airbus conversions using OEM data. Anticipating the rising demand for autonomous and artificial intelligence, we provide unmanned air systems as solutions for urban city application."
                        ,images:[
            ]
      ,videos:[
          
          {
          
          videoType:'vimeo',videoUrl:"https://vimeo.com/517249009",videoId:'517249009'
          }]},                 
    
  ],    carouselVideos:[
  ]

 } ,
    construction:{
      empForBox:[{
        employername:'McAbee Construction',
        employersite:"https://www.mcabeeconstruction.com/",
        employerLogo:"mcabee-logo.jpg",
        employerp1:"McAbee Construction, Inc. was founded in 1962 by Leroy McAbee Sr. and has set the standards for mechanical and industrial construction and fabrication of piping, vessels, modules, sheet metal and autoclaves. This provides our clients in the United States and around the world with the highest quality, timely execution and fair pricing."
        +"With a strong, focused management team, committed people, resources and facilities, McAbee can assure that each project is completed to the customer's satisfaction.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[ {
                videoType:'youtube',videoUrl:"https://vimeo.com/521691260",videoId:'nhOH4mza5Lw'
              },
              {
                videoType:'youtube',videoUrl:"https://vimeo.com/521690403",videoId:'61W0wtGsVl4'
              }],
        clickable:false
        },    
        {
          employername:'Performance Contractors, Inc',
          employersite:"https://www.performance-contractors.com/",
          employerLogo:"performance-logo.jpg",
          employerp1:"We began as a merit shop general industrial contractor back in 1979. Since then, we’ve grown to provide full-service construction through all phases of the industrial construction process—from site prep through start-up. Performance serves a wealth of industries – from chemical and power, to automotive and refinery – with construction, turnaround, and maintenance services that put safety and quality at the forefront of every project and decision. Everything that we do is based on our performance; it’s our namesake and our crowning jewel. We hang our hat on a good, safe day’s work and our ability to do the job to the best of our ability.",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[              
            {
            videoType:'youtube',
            videoUrl:"https://vimeo.com/521690403",
            videoId:'Oi5x3bc6LwM'
          }],
          clickable:false
        },  
        {
           employername:'Turner Industries Group',
           employersite:"https://www.turner-industries.com/",
           employerLogo:"turner-logo.jpg",
           employerp1:"Turner Industries employees build, maintain and service the nation’s heavy industrial sector helping make the life we live possible by supporting the companies that produce fuel, energy, and products essential to our daily existence. Since 1961, Turner has steadily worked to provide one solution for our clients success. To do this we find, develop and support the very best people for the jobs we perform throughout our four divisions: Construction; Maintenance and Turnarounds; Fabrication; and Equipment and Specialty Services.",
           employerp2:"",
           employerp3:"",
           images:[],
           videos:[
            {
              videoType:'youtube',
              videoUrl:"https://vimeo.com/521690403",
              videoId:'5UpIrbxsXzo'
            }
           ],
           clickable:false
        },
        ],
      carouselVideos:[]
     
    },
    energy:{
  
      empForBox:[  {
        employername:'Alabama Power',
        employersite:"https://www.alabamapower.com/our-company/careers.html",
        employerLogo:"alabama-power-logo.jpg",
        employerp1:"We offer competitive prices, reliable electricity supply, and stellar service to 1.4 million homes, businesses and industries in the southern two-thirds of Alabama. Alabama Power is a subsidiary of Southern Company, one of the nation’s largest producers of energy.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[
          {
          videoType:'youtube',
          videoUrl:"https://youtu.be/5cV0HqSQvYg",
          videoId:'5cV0HqSQvYg'
          },
          {
            videoType:'youtube',
            videoUrl:"https://youtu.be/kl1dN_Jttps",
            videoId:'kl1dN_Jttps'
            },
            {
              videoType:'youtube',
              videoUrl:"https://youtu.be/RiLj9zTGUo0",
              videoId:''
              },
              {
                videoType:'youtube',
                videoUrl:"https://youtu.be/94QD_CY8jOE",
                videoId:'94QD_CY8jOE'
                },
                {
                  videoType:'youtube',
                  videoUrl:"https://youtu.be/VZuVA1hAavY",
                  videoId:'VZuVA1hAavY'
                  },
                  {
                    videoType:'youtube',
                    videoUrl:"https://youtu.be/LZiVeexOCEY",
                    videoId:'LZiVeexOCEY'
                    },
     
        ],
        clickable:false
        },            
       {
         employername:'Daphne Utilities',
         employersite:"https://www.daphneutilities.com/",
         employerLogo:"daphne-logo.jpg",
         employerp1:"Daphne Utilities is committed to providing our customers with the highest-quality, uninterrupted utility services at affordable rates. Throughout the year, we strive to meet this commitment through regular maintenance, system upgrades and construction of new utility infrastructure.",
         employerp2:"",
         employerp3:"",
         images:[],
         videos:[{
          videoType:'youtube',
          videoUrl:"https://vimeo.com/521690403",
          videoId:'0O9u-tKeyNE'
        }],
         clickable:false
       },  
  
      ],
      carouselVideos:[]    
    },
    engineering_and_design:{
      empForBox:[  {
        employername:'Alabama Power',
        employersite:"https://www.alabamapower.com/our-company/careers.html",
        employerLogo:"alabama-power-logo.jpg",
        employerp1:"We offer competitive prices, reliable electricity supply, and stellar service to 1.4 million homes, businesses and industries in the southern two-thirds of Alabama. Alabama Power is a subsidiary of Southern Company, one of the nation’s largest producers of energy.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',
          videoUrl:"https://youtu.be/VZuVA1hAavY",
          videoId:'VZuVA1hAavY'
        }],
        clickable:false
        },
        {
          employername:'Hargrove Engineering',
          employersite:"https://www.hargrove-epc.com/join-our-team/",
          employerLogo:"hargrove-logo.jpg",
          employerp1:"A 100% Employee Owned Company Giving 110%. Design, build and maintain a safe, fully compliant facility with Hargrove. Our firm has innovated sound solutions for the process, manufacturing, and energy industries since 1995.",
          employerp2:"What we build best are relationships®. We serve clients in long-term support relationships in multiple modes of service: onsite support arrangements, plant-level small projects and consulting roles, and larger capital projects. Everything we do focuses on best practices to ensure safe, efficient and profitable operations with your company's best interest in mind.",
          employerp3:"",
          images:[],
          videos:[  {     videoType:'youtube',
          videoUrl:"https://vimeo.com/fusionpoint/review/503239295/c67a8fb082",
          videoId:'RYW9Kkd_atw'}],
          clickable:false
      }, ],
      carouselVideos:[]
         
    },
    healthcare:{ empForBox:[  {
      employername:'Mobile Public Schools',
      employersite:"https://www.mcpss.com/jobopportunities",
      employerLogo:"mcps-logo.jpg",
      employerp1:"Mobile County Public School System is a school district based in unincorporated Mobile County, Alabama, United States. The system currently serves areas of Mobile County, including the city of Mobile, with the exception of the cities of Saraland, Satsuma and Chickasaw.",
      employerp2:"",
      employerp3:"",
      images:[],
      videos:[{
        videoType:'youtube',
        videoUrl:"",
        videoId:'Cytkw4JWjNc'
      }],
      clickable:false
      }],
    carouselVideos:[]},
    information_technology:{
  
      empForBox:[ {
        employername:'Provalus',
        employersite:"https://provalus.com/apply/",
        employerLogo:"provalus-logo.jpg",
        employerp1:"Provalus is purpose-driven, elevating under-served communities by creating technology, business and support careers for undiscovered talent in the U.S.  Provalus offers Support, BPO and ITO services. We hire and develop the best and brightest untapped talent in our small towns and rural communities to deliver a remarkable experience for clients and end-users alike. By creating opportunities where there were none; with companies that believe in America’s future, Provalus is generating a dedicated superior workforce. We are fueling a new era of technology talent equipped to strengthen America’s future, PROVIDING OUTSOURCING VALUE FROM THE U.S",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',
          videoUrl:"https://youtu.be/MDkFlrg0QkY",
          videoId:'MDkFlrg0QkY'
        }],
        clickable:false
        }],
      carouselVideos:[]     
    },
    hospitality_and_tourism:{
      empForBox:[  {
        employername:'Spectrum Resorts',
        employersite:"https://spectrumresorts.com/about-spectrum/careers/",
        employerLogo:"spectrum-logo.jpg",
        employerp1:"Spectrum Resorts is the premier rental management company along the Alabama Gulf Coast, providing over 500 luxury rentals at both The Beach Club in Gulf Shores and Turquoise Place in Orange Beach Alabama. As the exclusive on-site rental provider at these two properties, Spectrum provides our guests with exclusive perks, special discounts and personalized service for their vacation experience.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',
          videoUrl:"https://youtu.be/O8mM2F9uSDY",
          videoId:'O8mM2F9uSDY'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/OSt4k676oXc",
          videoId:'OSt4k676oXc'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/KQIBtcpCJjo",
          videoId:'KQIBtcpCJjo'
        },],
        clickable:false
        }],
      carouselVideos:[]
         
    },
    
    manufacturing:{
      empForBox:[ {
        employername:'AM/NS Calvert',
        employersite:"https://najobs.arcelormittal.com/calvert/go/AMNS-Calvert-Careers/4276400/",
        employerLogo:"amns-logo.jpg",
        employerp1:"AM/NS Calvert is a 50/50 joint venture between ArcelorMittal and Nippon Steel Corp. (NSC). The steel processing plant is located in Calvert, Alabama, about 35 miles north of Mobile.  The plant has the capacity to produce 5.3 million tons of flat rolled carbon steel products annually. The facility serves the automotive, construction, pipe and tube, service center, and appliance/ HVAC industries with an impressive portfolio of steel grades for high-value applications including hot rolled bands, hot rolled pickled and oiled, cold rolled, and advanced coated products. ",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',
          videoUrl:"https://youtu.be/LQ_NqFbE5v4",
          videoId:'LQ_NqFbE5v4'
        },
        {
          videoType:'youtube',
          videoUrl:"https://youtu.be/aBBT29AyyXw",
          videoId:'aBBT29AyyXw'
        }],
        clickable:false
        },
        {
          employername:'BASF',
          employersite:"https://www.basf.com/global/en/careers/jobs.html#%7B%7D",
          employerLogo:"basf-logo.jpg",
          employerp1:"At BASF, we create chemistry for a sustainable future. We combine economic success with environmental protection and social responsibility. The approximately 122,000 employees in the BASF Group work on contributing to the success of our customers in nearly all sectors and almost every country in the world. Our portfolio is organized into six segments: Chemicals, Materials, Industrial Solutions, Surface Technologies, Nutrition & Care and Agricultural Solutions.",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[{
            videoType:'youtube',
            videoUrl:"https://youtu.be/E47GzGkzRQA",
            videoId:'E47GzGkzRQA'
          },
          {
            videoType:'youtube',
            videoUrl:"https://youtu.be/ALtIKm2cWE4",
            videoId:'ALtIKm2cWE4'
          },
          {
            videoType:'youtube',
            videoUrl:"https://youtu.be/uLMu2WbYwF4",
            videoId:'uLMu2WbYwF4'
          }],
          clickable:false
          },
          {
            employername:'Evonik',
            employersite:"https://careers.evonik.com/en/",
            employerLogo:"evonik-logo.jpg",
            employerp1:"Evonik is one of the world leaders in specialty chemicals. The company is active in more than 100 countries around the world. Evonik goes far beyond chemistry to create innovative, profitable and sustainable solutions for customers. More than 32,000 employees work together for a common purpose: We want to improve life, today and tomorrow.",
            employerp2:"",
            employerp3:"",
            images:[],
            videos:[{
              videoType:'youtube',
              videoUrl:"https://youtu.be/1nltc600Krw",
              videoId:'1nltc600Krw'
            }],
            clickable:false
            },
            {
              employername:'Westervelt',
              employersite:"https://www.westervelt.com/",
              employerLogo:"westervelt-logo.jpg",
              employerp1:"At The Westervelt Company, we are stewards of the land. We believe in sustainable management, conservation and protection of our natural resources. We are committed to our core values of ensuring the safety and well-being of our employees, providing the highest quality products and services for our customers, and developing long-lasting relationships in the communities where we live and work.",
              employerp2:"",
              employerp3:"",
              images:[],
              videos:[{
                videoType:'youtube',
                videoUrl:"https://youtu.be/9UW4MQn5z7E",
                videoId:'9UW4MQn5z7E'
              },{
                videoType:'youtube',
                videoUrl:"https://youtu.be/X_rsH-P9yNk",
                videoId:'X_rsH-P9yNk'
              }],
              clickable:false
              }
      ],
      carouselVideos:[]
         
    },
    public_service:{
      empForBox:[ {
        employername:'Mobile Fire and Rescue',
        employersite:"https://www.cityofmobile.org/fire/",
        employerLogo:"mobile-fire-logo.jpg",
        employerp1:"The City of Mobile is a vastly growing port city filled with business, tourism, tradition, and family, all worthy of being protected. Mobile Fire-Rescue Department (MFRD) is an internationally recognized, all-hazards fire department, capable of responding to and mitigating most any emergency situation. From hazardous materials to urban rescue, fire suppression, emergency medical services, and more, Mobile Fire-Rescue Department is committed to providing the best services to the citizens, families, and friends of Mobile, Alabama.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',
          videoUrl:"https://www.youtube.com/watch?v=zX-VW94eIZQ",
          videoId:'zX-VW94eIZQ'
        }],
        clickable:false
        }],
      carouselVideos:[]
         
    },
    safety:{
      empForBox:[],
      carouselVideos:[]
         
    },
    shipbuilding_and_repair:{
  
      empForBox:[ {
        employername:'The Cooper Group of Companies',
        employersite:"https://www.coopertsmith.com/",
        employerLogo:"cooper-logo.jpg",
        employerp1:"The Cooper Group of Companies has no greater asset than its people. Our employees come to us from all over the southeast, and they are what makes our company an industry leader. Whether you currently have a successful job in the maritime or forestry industry and are looking for a proven and established company to take your career to the next level, or you're new to the industry and want to be trained by the finest crews on the most state-of-the-art equipment in the industry, the Cooper Group is the company for you.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',
          videoUrl:"https://youtu.be/ywJrZmCquOI",
          videoId:'ywJrZmCquOI'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/J2Z_Qa1pdqI",
          videoId:'J2Z_Qa1pdqI'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/RHeRNqXNExg",
          videoId:'RHeRNqXNExg'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/Itjqq_KY8fU",
          videoId:'Itjqq_KY8fU'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/5GeOw30KSQI",
          videoId:'5GeOw30KSQI'
        }],
        clickable:false
        },{
          employername:'Austal',
          employersite:"https://www.austaljobs.com/",
          employerLogo:"austal-logo.jpg",
          employerp1:"Austal USA is an advanced ship manufacturer, servicer and sustainment provider. Headquartered in Mobile, Alabama with facilities in San Diego, Singapore and Washington, D.C., the company employs approximately 4,000 people and designs and constructs two ship programs for the U.S. Navy – Independence-variant Littoral Combat Ship (even numbered hulls) and Expeditionary Fast Transport (EPF). The company’s supplier network includes over 1155 companies across 44 states supporting more than 34,000 U.S. jobs. Austal USA entered the defense market in 2004 and through its innovative ship manufacturing processes, quickly became the fifth largest shipbuilder in North America. ",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[{
            videoType:'youtube',
            videoUrl:"https://www.youtube.com/watch?v=Fn60BrAPfDM",
            videoId:'Fn60BrAPfDM'
          },{
            videoType:'youtube',
            videoUrl:"https://www.youtube.com/watch?v=vboOPJoe4FE",
            videoId:'vboOPJoe4FE'
          },
          {
            videoType:'youtube',
            videoUrl:"https://www.youtube.com/watch?v=_UQHSmG10O0",
            videoId:'_UQHSmG10O0'
          },],
          clickable:false
          },{
            employername:'Ingalls',
            employersite:"https://ingalls.huntingtoningalls.com/careers/",
            employerLogo:"ingalls-logo.jpg",
            game:"https://romantic-einstein-a95603.netlify.app/ingallstrivia",
            employerp1:"Ingalls Shipbuilding is located in Pascagoula, Mississippi on 800 acres of the most important real estate in America. With 11,500 employees, Ingalls is the largest manufacturing employer in Mississippi and a major contributor to the economic growth of both Mississippi and Alabama. Our 82-year legacy has continuously proven we have the talent, experience and facilities to simultaneously build more classes of ships than any other shipyard in America.",
            employerp2:"",
            employerp3:"",
            images:[],
            videos:[{
              videoType:'youtube',
              videoUrl:"https://www.youtube.com/watch?v=Ddjk7uQblUE",
              videoId:'Ddjk7uQblUE'
            },
            {
              videoType:'youtube',
              videoUrl:"https://www.youtube.com/watch?v=fYBvaGFlY_k",
              videoId:'fYBvaGFlY_k'
            },
            {
              videoType:'youtube',
              videoUrl:"https://www.youtube.com/watch?v=yG9ai_CAI-c",
              videoId:'yG9ai_CAI-c'
            },],
            clickable:false
            }],
      carouselVideos:[]  
    },
    transportation_distribution_and_logistics:{
      empForBox:[ {
        employername:'The Cooper Group of Companies',
        employersite:"https://www.coopertsmith.com/",
        employerLogo:"cooper-logo.jpg",
        employerp1:"The Cooper Group of Companies has no greater asset than its people. Our employees come to us from all over the southeast, and they are what makes our company an industry leader. Whether you currently have a successful job in the maritime or forestry industry and are looking for a proven and established company to take your career to the next level, or you're new to the industry and want to be trained by the finest crews on the most state-of-the-art equipment in the industry, the Cooper Group is the company for you.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',
          videoUrl:"https://youtu.be/ywJrZmCquOI",
          videoId:'ywJrZmCquOI'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/34-TV4unHJw",
          videoId:'34-TV4unHJw'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/yKAQw9yyAEI",
          videoId:'yKAQw9yyAEI'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/J2Z_Qa1pdqI",
          videoId:'J2Z_Qa1pdqI'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/RHeRNqXNExg",
          videoId:'RHeRNqXNExg'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/Itjqq_KY8fU",
          videoId:'Itjqq_KY8fU'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/5GeOw30KSQI",
          videoId:'5GeOw30KSQI'
        }],
        clickable:false
        },
        {
          employername:'Alabama Trucking Association',
          employersite:"https://www.alabamatrucking.org/about/fast-facts-and-stats/",
          employerLogo:"alabama-trucking.jpg",
          employerp1:"The Alabama Trucking Association represents state trucking operations since 1938 for the promotion of fair and reasonable regulation of the industry.",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[{
            videoType:'youtube',
            videoUrl:"https://www.youtube.com/watch?v=nVro8UeI480",
            videoId:'nVro8UeI480'
          },],
          clickable:false
          }],
      carouselVideos:[]
         
    },
  
   
  }
  
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var NAW_Quiz_Object={ 
    agriculture_food_and_natural_resources:{
      empForBox:[],
      carouselVideos:[
      ]
    },
    architecture_and_construction:{
      empForBox:[
        {
          employername:'Freedom Real Estate & Capital, LLC',
          employersite:"https://www.freedomrealestate.biz/about/",
          employerLogo:"freedom-logo.jpg",
          employerp1:"Freedom was established in 2014 to invest and manage commercial real estate. We developed a long-term investment strategy around commercial rental income properties in the Huntsville, Alabama area. Our key focus was, and is, to own, hold, and manage long-term commercial real estate to return a reasonable profit. We concentrated our efforts on renovating and modernizing spaces to attract and retain premium tenants. We also partnered with investors and firms to develop properties and improve our community. We set out to do business in a way to be recognized for our ethics, fair play, and to achieve win-win results for all stakeholders. We have continued on that path.",
          employerp2:"",
          employerp3:""
              ,images:[
              ]
              ,videos:[]
        },
      ],
      carouselVideos:[]
         
    },
    education_and_training:{
      empForBox:[
      {
        employername:'Athens State University',
        employersite:"https://www.athens.edu/",
        employerLogo:"athens-logo.jpg",
        employerp1:"Founded in 1822, Athens State University is Alabama’s oldest continuously operating institution of higher learning and has been providing academic excellence to students for almost 200 years. As one of only a few “upper-division” universities in the entire country, Athens State is a destination for transfer students and students with more than 36 hours of credit who may have paused their education and are seeking to finish their degree. Offering over 50 undergraduate degree programs and options in addition to graduate programs, Athens State focuses on providing affordable, flexible, and high-quality options for all learners. At Athens State, we understand it isn't how you began your education that matters. It’s how you finish. And a strong finish is only the beginning.",
        employerp2:"",
        employerp3:""
            ,images:[
            ]
            ,videos:[{
              videoType:'youtube',videoUrl:"https://youtu.be/w-0HjSjTGf0",videoId:'w-0HjSjTGf0'
            },
            {
              videoType:'youtube',videoUrl:"https://youtu.be/3l0LqLQbDYc",videoId:'3l0LqLQbDYc'
            }]
      },
      {
        employername:'Bevill State Community College',
        employersite:"https://www.bscc.edu/",
        employerLogo:"bevill-logo.jpg",
        employerp1:"Bevill State Community College provides an ideal environment for learning and professional development. As a public, two-year, 'open door' institution, Bevill State Community College is committed to providing comprehensive education and educational support services for people in West Central Alabama and beyond.",
        employerp2:"",
        employerp3:""
            ,images:[
            ]
            ,videos:[{
              videoType:'youtube',videoUrl:"https://youtu.be/XFhQLoAadGo",videoId:'XFhQLoAadGo'
            }]
      }, 
      {
        employername:'Calhoun Community College',
        employersite:"https://calhoun.edu/",
        employerLogo:"calhoun-logo.jpg",
        employerp1:"Calhoun Community College exemplifies the two-year college mission of commitment to excellence in teaching and service and is the region’s leader in education and workforce development.",
        employerp2:"",
        employerp3:""
            ,images:[
            ]
            ,videos:[
            ]
      },
      {
        employername:'Drake State Technical & Community College',
        employersite:"https://www.drakestate.edu/",
        employerLogo:"drake-logo.jpg",
        employerp1:"Drake State Community and Technical College offers flexible, affordable university-transfer and technical degrees, certificates, adult and continuing education, and customized skills training to fulfill the diverse workforce need of employers.",
        employerp2:"",
        employerp3:""
            ,images:[
            ]
            ,videos:[
            ]
      },                
    
  ],
  carouselVideos:[
  ]
},
    energy_and_utilities:{
      empForBox:[],
      carouselVideos:[]
         
    },
    healthcare:{
      empForBox:[],
      carouselVideos:[]
         
    },
    hospitality_and_tourism:{
      empForBox:[],
      carouselVideos:[]
         
    },
    modern_manufacturing_auto:{
      empForBox:[
      {
        employername:'Mazda Toyota Manufacturing',
        employersite:"https://www.findabetterjob.com/MazdaToyota",
        employerLogo:"mazda-logo.jpg",
        employerp1:"Mazda Toyota Manufacturing is a jointly owned-and-operated automotive production plant. The $2.311 billion facility is expected to create up to 4,000 jobs and will have the capacity to assemble up to 300,000 vehicles a year, beginning in 2021.",
        employerp2:"",
        employerp3:""
            ,images:[
            ]
            ,videos:[
              {
                videoType:'youtube',videoUrl:"https://youtu.be/9oyMa9SHL-k",videoId:'9oyMa9SHL-k'
              },
              {
                videoType:'youtube',videoUrl:"https://youtu.be/3nhvXMpglbo",videoId:'3nhvXMpglbo'
              },
            ]
      },              
    
  ],
  carouselVideos:[
  ]
},
    modern_manufacturing:{
      empForBox:[],
      carouselVideos:[]
         
    },
    law_public_safety_and_corrections:{
      empForBox:[],
      carouselVideos:[]
         
    },
    transportation_distribution_and_logistics:{
      empForBox:[],
      carouselVideos:[]
         
    },
    aerospace:{
      empForBox:[],
      carouselVideos:[]
         
    },
    business_and_finance:{
    empForBox:[],
    carouselVideos:[]
         
    },
    communications_media_and_marketing:{
    empForBox:[],
    carouselVideos:[]
         
    },
    cybersecurity_and_information_technology:{
    empForBox:[],
    carouselVideos:[]
         
    },
   
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var EAW_Quiz_Object={
    agriculture_and_natural_resources:{
    empForBox:[
      {
        employername:'Calhoun Country Conservation District',
        employersite:"https://alconservationdistricts.gov/",
        employerLogo:"cccd-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
        },    
        {
          employername:'Ag Pro ',
          employersite:"https://agprocompanies.com/",
          employerLogo:"ag-pro-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
        },  
        {
           employername:'Alabama Cooperative Extension Service',
           employersite:"https://www.aces.edu/",
           employerLogo:"extension-logo.jpg",
           employerp1:"",
           employerp2:"",
           employerp3:"",
           images:[],
           videos:[],
           clickable:false
        },
        {
          employername:'Alabama Forestry Commission',
          employersite:"https://forestry.alabama.gov/",
          employerLogo:"alabama-forestry-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
          },            
         {
           employername:'Alabama Green Industry ',
           employersite:"https://www.agitc.org/",
           employerLogo:"alabama-green-logo.jpg",
           employerp1:"",
           employerp2:"",
           employerp3:"",
           images:[],
           videos:[],
           clickable:false
         },  
         {
            employername:'Alabama Poultry and Egg Association ',
            employersite:"https://www.alabamapoultry.org/",
            employerLogo:"alabama-poultry-logo.jpg",
            employerp1:"",
            employerp2:"",
            employerp3:"",
            images:[],
             videos:[],
             clickable:false
             },   
        {
            employername:'Alfa Farmers',
            employersite:"https://alfafarmers.org/",
            employerLogo:"alfa-logo.jpg",
            employerp1:"",
            employerp2:"",
            employerp3:"",
            images:[],
            videos:[],
            clickable:false
        },          
 
      {
        employername:'Choccolocco Creek Watershed',
        employersite:"https://choccoloccocreek.org/",
        employerLogo:"choccolocco-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
    },     
    {
      employername:'USDA',
      employersite:"https://www.usda.gov/our-agency/careers",
      employerLogo:"usda-logo.jpg",
      employerp1:"",
      employerp2:"",
      employerp3:"",
      images:[],
      videos:[],
      clickable:false
  }, 
  {
    employername:'ForestryWorks',
    employersite:"https://www.forestryworks.com/careers",
    employerLogo:"forestry-logo.jpg",
    employerp1:"",
    employerp2:"",
    employerp3:"",
    images:[],
    videos:[],
    clickable:false
},         
      ],
    carouselVideos:[
      {
        videoType:'youtube',
        title:"Ag Pro - Ag Pro Careers",
        website:"www.agprocompanies.com",
        link:"https://youtu.be/9giKKWu46TI",
        videoId:'9giKKWu46TI'
      },
      {
        videoType:'youtube',
        title:"Alabama Cooperative Extension System - Guide to Careers in Agriculture",
        website:"aces.edu",
        link:"https://youtu.be/bEIYIGx61Pk",
        videoId:'bEIYIGx61Pk'
      },
      {
        videoType:'youtube',
        title:" Forestry Works - Workforce Development Overview",
        website:"forestry.alabama.gov",
        link:"https://youtu.be/W58WZJYmGH4",
        videoId:'W58WZJYmGH4'
      },
      {
        videoType:'youtube',
        title:" Alabama Green Industry",
        website:" agitc.org",
        link:"https://youtu.be/Nwll7p8Jg-I",
        videoId:'Nwll7p8Jg-I'
      },
      {
        videoType:'youtube',
        title:" Alabama Poultry & Egg Assoc - Miller Center Video 2019",
        website:"alabamapoultry.org",
        link:"https://youtu.be/0V0goSAIEpg",
        videoId:'0V0goSAIEpg'
      },
      {
        videoType:'youtube',
        title:"ALFA - Farming Feeds Alabama - Wheat and Feed Grains",
        website:"alfafarmers.org",
        link:"https://youtu.be/I8FQs1rGq94",
        videoId:'I8FQs1rGq94'
      },
      {
        videoType:'youtube',
        title:" Forestry Works - Mill Management",
        website:" forestryworks.com",
        link:"https://youtu.be/m6TL-_S7-ig",
        videoId:'m6TL-_S7-ig'
      },


    ]

},
    automotive_and_manufacturing:{
      empForBox:[        
        {
        employername:'Anniston Army Depot',
        employersite:"https://www.anad.army.mil/",
        employerLogo:"anniston-army-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
        },
        {
          employername:'Bridgewater Interiors',
          employersite:"https://bridgewater-interiors.com/",
          employerLogo:"bridgewater-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
          },
          {
            employername:'Central Alabama Community College ',
            employersite:"https://www.cacc.edu/",
            employerLogo:"central-alabama-logo.jpg",
            employerp1:"",
            employerp2:"",
            employerp3:"",
            images:[],
            videos:[],
            clickable:false
            },           
            {
              employername:'Gadsden State Community College',
              employersite:"https://www.gadsdenstate.edu/",
              employerLogo:"gadsden-state-logo.jpg",
              employerp1:"",
              employerp2:"",
              employerp3:"",
              images:[],
              videos:[],
              clickable:false
              },           {
                employername:'Honda Manufacturing of Alabama ',
                employersite:"https://www.hondaalabama.com/",
                employerLogo:"honda-logo.jpg",
                employerp1:"",
                employerp2:"",
                employerp3:"",
                images:[],
                videos:[],
                clickable:false
                },          {
                  employername:'Kronospan',
                  employersite:"https://www.kronospan-worldwide.com/",
                  employerLogo:"kronospan-logo.jpg",
                  employerp1:"",
                  employerp2:"",
                  employerp3:"",
                  images:[],
                  videos:[],
                  clickable:false
                  },           
                  {
                    employername:'KTH',
                    employersite:"https://www.kth.net/",
                    employerLogo:"kth-logo.jpg",
                    employerp1:"",
                    employerp2:"",
                    employerp3:"",
                    images:[],
                    videos:[],
                    clickable:false
                    },
                    {
                      employername:'M&H Valve',
                      employersite:"https://www.mh-valve.com/",
                      employerLogo:"mh-valve-logo.jpg",
                      employerp1:"",
                      employerp2:"",
                      employerp3:"",
                      images:[],
                      videos:[],
                      clickable:false
                      },
                      {
                        employername:'New Flyer of America',
                        employersite:"https://www.newflyer.com/",
                        employerLogo:"new-flyer-logo.jpg",
                        employerp1:"",
                        employerp2:"",
                        employerp3:"",
                        images:[],
                        videos:[],
                        clickable:false
                        },
                        {
                          employername:'AIDT',
                          employersite:"https://www.aidt.edu/",
                          employerLogo:"aidt-logo.jpg",
                          employerp1:"",
                          employerp2:"",
                          employerp3:"",
                          images:[],
                          videos:[],
                          clickable:false
                          },
                          {
                            employername:'Southern Union State Community College',
                            employersite:"https://www.suscc.edu/",
                            employerLogo:"southern-union-logo.jpg",
                            employerp1:"",
                            employerp2:"",
                            employerp3:"",
                            images:[],
                            videos:[],
                            clickable:false
                            },
                            {
                              employername:"Southwire",
                              employersite:"https://www.southwire.com/",
                              employerLogo:"southwire-logo.jpg",
                              employerp1:"",
                              employerp2:"",
                              employerp3:"",
                              images:[],
                              videos:[],
                              clickable:false
                              },                            {
                                employername:"Steel Fab",
                                employersite:"https://www.steelfab-inc.com/",
                                employerLogo:"steel-fab-logo.jpg",
                                employerp1:"",
                                employerp2:"",
                                employerp3:"",
                                images:[],
                                videos:[],
                                clickable:false
                                },
                                {
                                  employername:"Tape Craft",
                                  employersite:"https://tapecraft.ykkamericas.com/",
                                  employerLogo:"tapecraft-logo.jpg",
                                  employerp1:"",
                                  employerp2:"",
                                  employerp3:"",
                                  images:[],
                                  videos:[],
                                  clickable:false
                                  },
                                {
                                  employername:"Tyler Union",
                                  employersite:"https://tylerunion.com/",
                                  employerLogo:"tyler-union-logo.jpg",
                                  employerp1:"",
                                  employerp2:"",
                                  employerp3:"",
                                  images:[],
                                  videos:[],
                                  clickable:false
                                  },
                                  {
                                    employername:"Wellborn Cabinet",
                                    employersite:"https://www.wellborn.com/",
                                    employerLogo:"wellborn-logo.jpg",
                                    employerp1:"",
                                    employerp2:"",
                                    employerp3:"",
                                    images:[],
                                    videos:[],
                                    clickable:false
                                    },

                  ],
      carouselVideos:[
        {
          videoType:'youtube',
          title:"CACC Automotive Manufacturing Technolog",
          website:"cacc.edu",
          link:" https://youtu.be/xwsRUZbx5ec",
          videoId:'xwsRUZbx5ec'
        },
        {
          videoType:'youtube',
          title:"CACC Machine Shop",
          website:"cacc.edu",
          link:"https://youtu.be/sQl0ZkjR4FM",
          videoId:'sQl0ZkjR4FM'
        },
        {
          videoType:'youtube',
          title:"CACC Industrial Electronics Technician",
          website:" cacc.edu",
          link:"https://youtu.be/tzfwe0lgvqQ",
          videoId:'tzfwe0lgvqQ'
        },
        {
          videoType:'youtube',
          title:"Gadsden State - Precision Machining",
          website:"gadsdenstate.edu",
          link:"https://youtu.be/iOrqYWNgMr8",
          videoId:'iOrqYWNgMr8'
        },
        {
          videoType:'youtube',
          title:" Gadsden State - Mechatronics",
          website:"gadsdenstate.edu",
          link:"https://youtu.be/8YugD1JLWV4",
          videoId:'8YugD1JLWV4'
        },
        {
          videoType:'youtube',
          title:" New Flyer Anniston - The Tour",
          website:"newflyer.com",
          link:"https://youtu.be/pV4ST6mrsAU",
          videoId:'pV4ST6mrsAU'
        },
        {
          title:" AIDT Promo 2021",
          link:"https://youtu.be/0irgJmGPOyU",
          videoId:'0irgJmGPOyU',          
          website:" aidt.edu",
          videoType:'youtube',
        },
        {
          title:"SUSCC - Technical Division - Mechatronics",
          link:" https://youtu.be/_DYgKK5tmyw",
          videoId:'_DYgKK5tmyw',          
          website:"suscc.edu",
          videoType:'youtube',
        },
        {
          title:": SUSCC - Technical Division - Plastics Engineering",
          link:"https://youtu.be/gQ14heSEVk8",
          videoId:'gQ14heSEVk8',          
          website:"suscc.edu",
          videoType:'youtube',
        },
        {
          title:"Southwire Corporate Video",
          link:"https://youtu.be/5enMrfKG0Vw",
          videoId:'5enMrfKG0Vw',          
          website:"southwire.com",
          videoType:'youtube',
        },
        {
          title:"Anniston Army Depot - Pathways Program",
          link:"https://youtu.be/VEBb7izyAyc",
          videoId:'VEBb7izyAyc',          
          website:"anad.army.mil",
          videoType:'youtube',
        },

        {
          title:" Bridgewater Interiors",
          link:" https://youtu.be/iuBK2_J5Ww4",
          videoId:'iuBK2_J5Ww4',          
          website:"bridgewater-interiors.com",
          videoType:'youtube',
        },

        {
          title:"Honda Manufacturing of Alabama",
          link:"https://youtu.be/qSfp6IYZvek",
          videoId:'qSfp6IYZvek',          
          website:"hondaalabama.com",
          videoType:'youtube',
        },
        {
          title:"Tape Craft",
          link:"https://youtu.be/8MfNGjeLT3w",
          videoId:'8MfNGjeLT3w',          
          website:"tapecraft.ykknorthamerica.com",
          videoType:'youtube',
        },
        {
          title:"Wellborn Cabinet - HR",
          link:"https://youtu.be/WYV_w_7aRcM",
          videoId:'WYV_w_7aRcM',          
          website:"wellborn.com",
          videoType:'youtube',
        },
      ]
         
    },
    communications_media_and_marketing:{
      empForBox:[                                
        {
          employername:"Appalachian Digital Media ",
          employersite:"https://www.appalachiandigitalmedia.com/",
          employerLogo:"appalachian-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
          },
          {
            employername:"City of Oxford",
            employersite:"https://www.oxfordal.gov/",
            employerLogo:"oxford-logo.jpg",
            employerp1:"",
            employerp2:"",
            employerp3:"",
            images:[],
            videos:[],
            clickable:false
            },
            {
              employername:"Gadsden State Community College",
              employersite:"https://www.gadsdenstate.edu/",
              employerLogo:"gadsden-state-logo.jpg",
              employerp1:"",
              employerp2:"",
              employerp3:"",
              images:[],
              videos:[],
              clickable:false
              },
              {
                employername:"Oxford Performing Arts Center ",
                employersite:"https://www.oxfordpac.org/",
                employerLogo:"oxford-arts-logo.jpg",
                employerp1:"",
                employerp2:"",
                employerp3:"",
                images:[],
                videos:[],
                clickable:false
                },
                {
                  employername:"Oxford Public Library ",
                  employersite:"https://www.oxfordpl.org/",
                  employerLogo:"oxford-library-logo.jpg",
                  employerp1:"",
                  employerp2:"",
                  employerp3:"",
                  images:[],
                  videos:[],
                  clickable:false
                  },
                  {
                    employername:"Spectrum Reach",
                    employersite:"https://www.spectrumreach.com/",
                    employerLogo:"spectrum-logo.jpg",
                    employerp1:"",
                    employerp2:"",
                    employerp3:"",
                    images:[],
                    videos:[],
                    clickable:false
                    },
                    {
                      employername:"The Chamber, Gadsden and Etowah County",
                      employersite:"http://www.etowahchamber.org/",
                      employerLogo:"chamber-logo.jpg",
                      employerp1:"",
                      employerp2:"",
                      employerp3:"",
                      images:[],
                      videos:[],
                      clickable:false
                      },
                      {
                        employername:"Venture Marketing Group",
                        employersite:"https://venturemarketinggroup.net/",
                        employerLogo:"venture-logo.jpg",
                        employerp1:"",
                        employerp2:"",
                        employerp3:"",
                        images:[],
                        videos:[],
                        clickable:false
                        }
                  ],
      carouselVideos:[
        {
          title:"Appalachian Digital Media",
          link:"https://youtu.be/kGaSWIFi3so",
          videoId:'kGaSWIFi3so',          
          website:"appalachiandigitalmedia.com",
          videoType:'youtube',
        },
        {
          title:"SpectrumReach - Digital Marketing Analyst",
          link:"https://youtu.be/lV9JwuLPVsk",
          videoId:'lV9JwuLPVsk',          
          website:"spectrumreach.com",
          videoType:'youtube',
        },
        {
          title:"The Chamber - Jon Vo Video",
          link:"https://youtu.be/w8FBEiDQgc0",
          videoId:'w8FBEiDQgc0',          
          website:"etowahchamber.org",
          videoType:'youtube',
        },
        {
          title:"Venture Marketing Group",
          link:"https://youtu.be/EGRDNouObvo",
          videoId:'EGRDNouObvo',          
          website:"venturemarketinggroup.net",
          videoType:'youtube',
        },
        {
          title:"WOW 2021 - Oxford Performing Arts Center - Technical Director",
          link:"https://youtu.be/RjswJneuXms",
          videoId:'RjswJneuXms',          
          website:"https://www.oxfordpac.org/",
          videoType:'youtube',
        },
        {
          title:"Gadsden State - Public Relations/Marketing",
          link:"https://youtu.be/ns5gCy0wxhA",
          videoId:'ns5gCy0wxhA',          
          website:"https://www.gadsdenstate.edu/",
          videoType:'youtube',
        },
     

      ]
         
    },
    construction:{
      empForBox:[ 
        {employername:"Central Alabama Community College",
      employersite:"https://www.cacc.edu/",
      employerLogo:"central-alabama-logo.jpg",
      employerp1:"",
      employerp2:"",
      employerp3:"",
      images:[],
      videos:[],
      clickable:false
      },
      {
        employername:"City of Oxford",
        employersite:"https://www.oxfordal.gov/",
        employerLogo:"oxford-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
        },
        {
          employername:"Gadsden State Community College",
          employersite:"https://www.gadsdenstate.edu/",
          employerLogo:"gadsden-state-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
          },
          {
            employername:"Go Build Alabama",
            employersite:"https://gobuildalabama.com/",
            employerLogo:"go-build-logo.jpg",
            employerp1:"",
            employerp2:"",
            employerp3:"",
            images:[],
            videos:[],
            clickable:false
            },
            {
              employername:"Hurst Construction",
              employersite:"https://www.hurstconstruction.com/",
              employerLogo:"hurst-logo.jpg",
              employerp1:"",
              employerp2:"",
              employerp3:"",
              images:[],
              videos:[],
              clickable:false
              },
              {
                employername:"Ironworker Skills Institute ",
                employersite:"https://ironworkerskills.com/",
                employerLogo:"ironworker-skills-logo.jpg",
                employerp1:"",
                employerp2:"",
                employerp3:"",
                images:[],
                videos:[],
                clickable:false
                },
                {
                  employername:"Littleton Electric Service",
                  employersite:"https://www.littletonelectric.com/",
                  employerLogo:"littleton-logo.jpg",
                  employerp1:"",
                  employerp2:"",
                  employerp3:"",
                  images:[],
                  videos:[],
                  clickable:false
                  },                       
                  {
                    employername:"Ra-Lin and Associates",
                    employersite:"https://www.ra-lin.com/",
                    employerLogo:"ra-lin-logo.jpg",
                    employerp1:"",
                    employerp2:"",
                    employerp3:"",
                    images:[],
                    videos:[],
                    clickable:false
                    },
                    {
                      employername:"Southern Union State Community College",
                      employersite:"https://www.suscc.edu/",
                      employerLogo:"southern-union-logo.jpg",
                      employerp1:"",
                      employerp2:"",
                      employerp3:"",
                      images:[],
                      videos:[],
                      clickable:false
                      },
                      {
                        employername:"Southland Machinery",
                        employersite:"https://www.southlandmachinery.com/",
                        employerLogo:"southland-logo.jpg",
                        employerp1:"",
                        employerp2:"",
                        employerp3:"",
                        images:[],
                        videos:[],
                        clickable:false
                        },
                        {
                          employername:"Thompson CAT",
                          employersite:"https://thompsontractor.com/",
                          employerLogo:"thompson-logo.jpg",
                          employerp1:"",
                          employerp2:"",
                          employerp3:"",
                          images:[],
                          videos:[],
                          clickable:false
                          },
                          {
                            employername:"Webb Concrete & Building Materials",
                            employersite:"https://www.webbconcrete.com/",
                            employerLogo:"webb-logo.jpg",
                            employerp1:"",
                            employerp2:"",
                            employerp3:"",
                            images:[],
                            videos:[],
                            clickable:false
                            },
                            {
                              employername:"Local 92",
                              employersite:"http://ironworkerslocal92.org/",
                              employerLogo:"local-92-logo.jpg",
                              employerp1:"",
                              employerp2:"",
                              employerp3:"",
                              images:[],
                              videos:[],
                              clickable:false
                              },
                              {
                                employername:"Alabama Construction Recruitment Institute",
                                employersite:"https://gobuildalabama.com/",
                                employerLogo:"acri-logo.jpg",
                                employerp1:"",
                                employerp2:"",
                                employerp3:"",
                                images:[],
                                videos:[],
                                clickable:false
                                },
                                {
                                  employername:"Industrial Rental",
                                  employersite:"https://www.facebook.com/industrialrental.org/",
                                  employerLogo:"industrial-rental-logo.jpg",
                                  employerp1:"",
                                  employerp2:"",
                                  employerp3:"",
                                  images:[],
                                  videos:[],
                                  clickable:false
                                  }, ],
      carouselVideos:[
        {
          title:"CACC Welding",
          link:"https://youtu.be/pxj12c1Y_W4",
          videoId:'pxj12c1Y_W4',          
          website:"cacc.edu",
          videoType:'youtube',
        },
        {
          title:"City of Oxford Building Department",
          link:"https://youtu.be/WAuJkKS0JgY",
          videoId:'WAuJkKS0JgY',          
          website:"oxfordal.gov",
          videoType:'youtube',
        },
        {
          title:"Gadsden State - HVACR",
          link:"https://youtu.be/3Rbd9CQz5vw",
          videoId:'3Rbd9CQz5vw',          
          website:"gadsdenstate.edu",
          videoType:'youtube',
        },
        {
          title:": Go Build Alabama - Mason",
          link:" https://youtu.be/ckbldODdF_w",
          videoId:'ckbldODdF_w',          
          website:"gobuildalabama.com",
          videoType:'youtube',
        },
        {
          title:"Hurst Construction",
          link:"https://youtu.be/CLT3bucqVbo",
          videoId:'CLT3bucqVbo',          
          website:"hurstconstruction.com",
          videoType:'youtube',
        },
        {
          title:": Ironworkers Skills Institute",
          link:"",
          videoId:'salr5pA_raw',          
          website:"ironworkerskills.com",
          videoType:'youtube',
        },
        {
          title:" SUSCC - Technical Division - Air Conditioning and Refrigeration",
          link:"https://youtu.be/c-Bs2EbQz5g",
          videoId:'c-Bs2EbQz5g',          
          website:"suscc.edu",
          videoType:'youtube',
        },        {
          title:"WEBB Concrete & Building Materials",
          link:"https://youtu.be/iFUna9SMW0w",
          videoId:'iFUna9SMW0w',          
          website:"webbconcrete.com",
          videoType:'youtube',
        },
        {
          title:"Construction World (Generic)",
          link:"https://youtu.be/TlMAlcdd4ho",
          videoId:'TlMAlcdd4ho',          
          website:"",
          videoType:'youtube',
        },
      ]
         
    },
    energy_and_utilities:{
      empForBox:[                          
        {
        employername:"Alabama Power Company",
        employersite:"https://www.alabamapower.com/",
        employerLogo:"alabama-power-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
        },
        {
          employername:"Sparklight",
          employersite:"https://business.sparklight.com/",
          employerLogo:"sparklight-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
        },
        {
          employername:"Spire",
          employersite:"https://spireenergy.com/",
          employerLogo:"spire-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
         clickable:false
        } ],
      carouselVideos:[
        {
          title:"Alabama Power",
          link:" https://youtu.be/Z0ZgyxOj0js",
          videoId:'Z0ZgyxOj0js',          
          website:"alabamapower.com",
          videoType:'youtube',
        },
        {
          title:"Spire",
          link:" https://youtu.be/Rx663ZNjeb4",
          videoId:'Rx663ZNjeb4',          
          website:" spireenergy.com",
          videoType:'youtube',
        },        
       
      ]
         
    },
    engineering:{
      empForBox:[
        {
          employername:"Alabama Technology Network",
          employersite:"https://www.atn.org/",
          employerLogo:"alabama-tech-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
        },         {
          employername:"Eastman",
          employersite:"https://www.eastman.com",
          employerLogo:"eastman-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
        } ,
        {
          employername:"Gadsden State Community College",
          employersite:"https://www.gadsdenstate.edu/",
          employerLogo:"gadsden-state-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
        },
        {
          employername:"Jacksonville State University ",
          employersite:"http://jsu.edu/",
          employerLogo:"jacksonville-state-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
        } ,
        {
          employername:"Transglobal",
          employersite:"https://www.transgloballlc.com/",
          employerLogo:"transglobal-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
        } 
       ],
      carouselVideos:[
        {
          title:"Alabama Technology Network - Metal Industry in Alabama",
          link:"https://youtu.be/waDL5r846A8",
          videoId:'waDL5r846A8',          
          website:"atn.org",
          videoType:'youtube',
        },
        {
          title:"Eastman",
          link:"https://youtu.be/6Wz84x4m6jg",
          videoId:'6Wz84x4m6jg',          
          website:"eastman.com",
          videoType:'youtube',
        },        
        {
          title:"Gadsden State - Civil Engineering",
          link:"https://youtu.be/ePZdxSSe_5I",
          videoId:'ePZdxSSe_5I',          
          website:"gadsdenstate.edu",
          videoType:'youtube',
        },        
        {
          title:"Gadsden State - Engineering Design Technology",
          link:"",
          videoId:'gZciCAvUHHg',          
          website:"gadsdenstate.edu",
          videoType:'youtube',
        },        
        {
          title:" Jacksonville State University - Center for Manufacturing Support",
          link:" https://youtu.be/XLkwAQv6wEU",
          videoId:'XLkwAQv6wEU',          
          website:" jsu.edu",
          videoType:'youtube',
        },
        {
          title:"Transglobal Services",
          link:"https://youtu.be/lWZpMCsC4jw",
          videoId:'lWZpMCsC4jw',          
          website:"transglobalus.com",
          videoType:'youtube',
        },
      ]
         
    },
    healthcare:{
      empForBox:[
        {
          employername:"Alabama Department of Rehabilitation Services",
          employersite:"https://www.rehab.alabama.gov/",
          employerLogo:"adrs-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
        },
        {
          employername:"Central Alabama Community College",
          employersite:"https://www.cacc.edu/",
          employerLogo:"central-alabama-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
        },
        {
          employername:"Coosa Valley Medical Center",
          employersite:"https://www.cvhealth.net/",
          employerLogo:"coosa-valley-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
        },
        {
          employername:"Designer Smiles",
          employersite:"https://www.bracesbybenton.com/",
          employerLogo:"designer-smiles-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
        },
        {
          employername:"Gadsden Regional Medical Center",
          employersite:"https://www.gadsdenregional.com/",
          employerLogo:"gadsden-regional-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
        },
        {
          employername:"Gadsden State Community College",
          employersite:"https://www.gadsdenstate.edu/",
          employerLogo:"gadsden-state-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
        },
        {
          employername:"Jacksonville Health and Rehabilitation",
          employersite:"https://jacksonvillehealthandrehab.com/",
          employerLogo:"jacksonville-health-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
        },
        {
          employername:"Jacksonville State University",
          employersite:"http://www.jsu.edu/",
          employerLogo:"jacksonville-state-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
        },
        {
          employername:"Regional Medical Center ",
          employersite:"https://rmccares.org/",
          employerLogo:"rmc-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
        },
        {
          employername:"Southern Union State Community College",
          employersite:"https://www.suscc.edu/",
          employerLogo:"southern-union-logo.jpg",
          employerp1:"",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[],
          clickable:false
        },
        ],
      carouselVideos:[
        {
          title:" CACC Nursing",
          link:"https://youtu.be/lGDbVG7VwDU",
          videoId:'lGDbVG7VwDU',          
          website:"cacc.edu",
          videoType:'youtube',
        },
        {
          title:"Gadsden State - Diagnostic Medical Sonography",
          link:" https://youtu.be/AOdbJVFJ4SM",
          videoId:'AOdbJVFJ4SM',          
          website:"gadsdenstate.edu",
          videoType:'youtube',
        },        
        {
          title:" Gadsden State - Medical Lab Technology / Phlebotomy",
          link:"",
          videoId:'n64VoMbZCNs',          
          website:"gadsdenstate.edu",
          videoType:'youtube',
        },        
        {
          title:"Gadsden State - Radiologic Technology",
          link:"",
          videoId:'ibgi5-d5vuY',          
          website:"gadsdenstate.edu",
          videoType:'youtube',
        },        
        {
          title:"Gadsden Regional Medical Center",
          link:"",
          videoId:'SZ-tK8cHhHg',          
          website:" gadsdenregional.com",
          videoType:'youtube',
        },
        {
          title:" RMC (Regional Medical Center)",
          link:"",
          videoId:'pSXbUkbkeJs',          
          website:" rmccares.org",
          videoType:'youtube',
        },
        {
          title:"SUSCC - Health Sciences Division - EMS Program",
          link:"",
          videoId:'YlXRJQqk6ic',          
          website:"suscc.edu",
          videoType:'youtube',
        },        
        {
          title:"SUSCC - Health Sciences Division - Surgical Technology",
          link:"",
          videoId:'fHm69DBt5Y0',          
          website:"suscc.edu",
          videoType:'youtube',
        },        
        {
          title:"JSU Department of Nursing",
          link:"",
          videoId:'doSwDaadLx0',          
          website:"jsu.edu",
          videoType:'youtube',
        },        
        {
          title:"JSU Respiratory Therapy",
          link:"https://youtu.be/_06V0VX6XKA",
          videoId:'_06V0VX6XKA',          
          website:" jsu.edu",
          videoType:'youtube',
        },
        {
          title:"Designer Smiles",
          link:"https://youtu.be/Y8FUnk5YDHQ",
          videoId:'Y8FUnk5YDHQ',          
          website:" jsu.edu",
          videoType:'youtube',
        },
        
      ]
         
    },
    information_technology:{
      empForBox:[        {
        employername:"Central Alabama Community College",
        employersite:"http://cacc.edu/",
        employerLogo:"central-alabama-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
      },],
      carouselVideos:[
        {
          title:"CACC Computer Information Systems",
          link:"https://youtu.be/mebdFNoGUlU",
          videoId:'mebdFNoGUlU',          
          website:"cacc.edu",
          videoType:'youtube',
        },
       
      ]
         
    },
    public_safety:{
      empForBox:[  {
        employername:"Alabama National Guard",
        employersite:"https://al.ng.mil/",
        employerLogo:"alabama-guard-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
      },
      {
        employername:"Anniston Fire Department",
        employersite:"https://www.annistonal.gov/fire-department/",
        employerLogo:"anniston-fire-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
      },
      {
        employername:"Calhoun County Emergency Management Agency",
        employersite:"http://www.calhounema.org/",
        employerLogo:"calhoun-ema-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
      },
      {
        employername:"Calhoun County Sheriff’s Office",
        employersite:"https://www.calcoso.org/",
        employerLogo:"calhoun-sheriff-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
      },
      {
        employername:"Oxford Fire Department",
        employersite:"https://www.oxfordal.gov/departments/fire-department",
        employerLogo:"oxford-fire-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
      },
      {
        employername:"Jacksonville Fire Department",
        employersite:"https://www.jacksonville-al.org/fire",
        employerLogo:"jacksonville-fire-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
      },
      {
        employername:"911 Calhoun County",
        employersite:"http://www.calhoun911.org/",
        employerLogo:"calhoun-911-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
      },

      {
        employername:"Oxford Police Department",
        employersite:"https://www.oxfordal.gov/departments/police-department",
        employerLogo:"oxford-police-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
      }
    ],
      carouselVideos:[
        {
          title:"Anniston Fire Department",
          link:" https://youtu.be/ky45-97Nvvo",
          videoId:'ky45-97Nvvo',          
          website:"annistonal.gov/fire-departmen",
          videoType:'youtube',
        },
        {
          title:"Calhoun County EMA",
          link:"https://youtu.be/3ZUMCS0vcD8",
          videoId:'3ZUMCS0vcD8',          
          website:"calhounema.org",
          videoType:'youtube',
        },        
        {
          title:"Oxford Fire Department",
          link:"https://youtu.be/zA9EmaPWJ-g",
          videoId:'zA9EmaPWJ-g',          
          website:"oxfordal.gov/departments/fire-department",
          videoType:'youtube',
        },        
        {
          title:"Oxford Police Department",
          link:"https://youtu.be/p9cmcu-S2GM",
          videoId:'p9cmcu-S2GM',          
          website:"oxfordal.gov/departments/police-department",
          videoType:'youtube',
        },        
        {
          title:"",
          link:"",
          videoId:'',          
          website:"",
          videoType:'youtube',
        },
       
       
      ]
         
    },
   
    transportation_distribution_and_logistics:{
      empForBox:[{
        employername:"B.R. Williams",
        employersite:"https://www.brwilliams.com/",
        employerLogo:"br-williams-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
      },
      {
        employername:"City of Oxford",
        employersite:"https://www.oxfordal.gov/",
        employerLogo:"oxford-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
      },
      {
        employername:"Gadsden State Community College",
        employersite:"https://www.gadsdenstate.edu/",
        employerLogo:"gadsden-state-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
      },
      {
        employername:"New Flyer of America",
        employersite:"https://www.newflyer.com/",
        employerLogo:"new-flyer-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
      },
      {
        employername:"Wellborn Cabinet",
        employersite:"https://www.wellborn.com//",
        employerLogo:"wellborn-logo.jpg",
        employerp1:"",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
        clickable:false
      }],
      carouselVideos:[
        {
          title:" BR Williams Trucking",
          link:"https://youtu.be/-mjtuYI-QkI",
          videoId:'-mjtuYI-QkI',          
          website:"brwilliams.com",
          videoType:'youtube',
        },
        {
          title:"City of Oxford Fleet Services",
          link:"",
          videoId:'SPXP32bZX8E',          
          website:"",
          videoType:'youtube',
        },        
        {
          title:"Gadsden State - Diesel Technology",
          link:"",
          videoId:'roDYsRYEyAM',          
          website:"gadsdenstate.edu",
          videoType:'youtube',
        },        
        {
          title:"New Flyer Anniston - Meet our Family",
          link:"https://youtu.be/oaQJ9v77aFg",
          videoId:'oaQJ9v77aFg',          
          website:"newflyer.com",
          videoType:'youtube',
        },        
        {
          title:"Wellborn Cabinet - HR",
          link:"https://youtu.be/WYV_w_7aRcM",
          videoId:'WYV_w_7aRcM',          
          website:"wellborn.com",
          videoType:'youtube',
        },
      ]
         
    },
   
   
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var WAW_Quiz_Object={ 
    construction:{
      empForBox:[
        {
           employername:'Dixie Mechanical, Inc. ',
           employersite:"http://dixiemechanical.com/",
           employerLogo:"dixie-mechanical-logo.jpg",
           employerp1:"Dixie Mechanical, Inc. employees have more than 100 combined years of service experience in the fabrication industry for the power & energy, oil & gas, and chemical sectors. With a custom fabrication shop of 20,000 square feet and 4.5 acres of lay down area, we can handle any size project you may have.",
           employerp2:"Dixie’s capabilities include the fabrication of large bore and small bore piping, with diameters ranging up to 72” OD and wall thickness up to 21/4”. Our experience also includes welding in valves, creating assemblies upwards to 100’ in length, and fabricating modular pipe racks. We have access to rail, barge, and traditional roadway delivery methods.",
           employerp3:"All of our weld procedures and operators are tested and certified in accordance with ASME Section IX. We are proficient not only in carbon and stainless steel fabrication, but also in more critical metals like chrome moly (11/4, 21/4, 5 and 91), duplex stainless, and nickel alloys. We are an authorized user of the ASME BPVC “S” & “U” Stamps, as well as the National Board “R” Stamp.",
           brochure:"https://waw-image.s3.amazonaws.com/wawpdfs/Dixie+Mechanical.pdf",
           images:[],
           videos:[{
                  
                }]
        },    
        {
          employername:'Lavender, Inc.',
          employersite:"https://s3.amazonaws.com/files.formstack.com/uploads/4407186/110935167/833608808/110935167_2021_lavender_brochure_final_email.pdf",
          employerLogo:"lavender-logo.jpg",
          employerp1:"As a General & Mechanical Contractor, Lavender, Inc. has proudly provided excellence for over 35 years now to customers. While our Headquarters is located in Aliceville, AL., our satellite offices are located in Columbus, MS., New Orleans, LA., Demopolis, AL., Tuscaloosa, AL., Pennington, AL. and Montgomery, AL. ",
          employerp2:"We take great pride in our employees and clients who have remained loyal to us over the years. Clients have commended us as being a company that is honest and dependable, providing the highest quality of work. We have 300 full time employees and 500 part time employees with a database of 1,000s of craftsmen used for mill outages and shutdowns. " ,
          employerp3:" Lavender, Inc. offers a variety of services for code and non-code work, including machine work, steel fabrication, pipe fabrication, on site erection, machinery installation, concrete foundations, underground utilities, site work, crane rentals, boiler maintenance, and industrial maintenance.",
          brochure:"",
          images:[],
          videos:[{
                 
               }]
       },   
       {
        employername:'TransferVR',
        employersite:"https://www.transfrvr.com/",
        employerLogo:"transfervr-logo.jpg",
        employerp1:"TRANSFR IS ON A MISSION.We are building the classroom-to-career pathway by delivering training that gives people the skills they need to enter the workforce."
       +" Our technology provides immersive training, shown to have higher retention when compared to video tutorials, slide presentations, or other training methods. We focus on developing a pipeline of talent for jobs that are going unfilled, working with technical/community colleges, high schools, and workforce development departments while reducing cost and risk for employers.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[]
    },  
        {
           employername:'Harrison Construction',
           employersite:"http://www.harrison-const.com/",
           employerLogo:"harrison-logo.jpg",
           employerp1:"J.T. Harrison Construction Co., Inc., began operations in Tuscaloosa, Alabama in April 1996.  We began with, and still maintain, a philosophy based on three simple protocols – Experience, Integrity, and Innovation.  Our continuing goal is to provide each client with the highest standards of these three traits.",
           employerp2:"We are proud of the success we have achieved in 20 years of operation.  Our success is due, in part, to the fact that we endeavor to meet the needs of each of our clients.  We structure each individual project in order to meet the Owner’s budget and time frame.",
           employerp3:"Our company is service oriented and staffed with extremely knowledgeable personnel that possess outstanding expertise in the construction industry.  We take pride in the fact that we employ the most qualified and experienced Project Managers, Superintendents, and Office Personnel in this area.  Our staff is dedicated to performing to the best of their ability."

           +"J.T. Harrison Construction Co., Inc., was formed with a vast amount of experience in both General Contracting and Construction Management.  We approach each project with the goal of maintaining the Owner’s trust and confidence.  This approach has and always will be the foundation of this firm."
           ,
           images:[],
           videos:[
            {
              videoType:'youtube',videoUrl:"https://youtu.be/PX5lIdna740",videoId:'PX5lIdna740'
         }
           ]
        },    
        {
          employername:'DSL Electric',
          employersite:"",
          employerLogo:"dsl-logo.jpg",
          employerp1:"Electrical contractor that performs work in the industrial and commercial fields with new construction and maintenance.",
          employerp2:"" ,
          employerp3:"",
          brochure:"",
          images:[],
          videos:[{
                 
               }]
       },  
        {
            employername:'Jolly Heating and Air Conditioning, Inc. ',
            employersite:"http://www.jollyheatingandair.com/",
            employerLogo:"jolly-logo.jpg",
            employerp1:"Jolly Heating and Air is a fast growing mechanical heating, ventilation, and air conditioning corporation servicing, installing, and maintaining residential, commercial, and industrial equipment in Tuscaloosa County.  Jolly HVAC is a family business that is locally owned and operated.  Getting its start from a backyard shop, it now operates out of the 15,000 square foot fabrication warehouse.   ",
            employerp2:"",
            employerp3:"",
            images:[],
            videos:[{
                 videoType:'youtube',videoUrl:"https://youtu.be/ktc58ealB3o",videoId:'ktc58ealB3o'
            }]
        },    
        {
          employername:'United Rentals',
          employersite:"https://www.unitedrentals.com/",
          employerLogo:"united-rental-logo.jpg",
          employerp1:"United Rentals is the largest equipment rental company in the world, with a store network nearly three times the size of any other provider and locations in 49 states and ten Canadian provinces.",
          employerp2:"Our mission is to deploy the best people, equipment and solutions to enable our customers to safely build a better and stronger future." ,
          employerp3:"",
          brochure:"",
          images:[],
          videos:[{
                 
               }]
       },  
        {
            employername:'McAbee Construction, Inc ',
            employersite:"http://www.mcabeeconstruction.com/",
            employerLogo:"mcabee-logo.jpg",
            employerp1:"McAbee Construction is a mechanical contractor serving heavy industrial customers in the paper, chemical, rubber, automotive, steel, aerospace, power, food and beverage industries. McAbee also offers fabrication of pressure vessels, pressure piping and sheet metal along with HVAC and crane rental services. McAbee Construction has been is business since 1962 and we take pride in our motto \"Safety First, Quality and Productivity\" ",
            employerp2:"",
            employerp3:"",
            brochure:"https://waw-image.s3.amazonaws.com/wawpdfs/McAbee.pdf",
            images:[],
            videos:[{
              videoType:'youtube',videoUrl:"https://youtu.be/bhBcKyuk06s",videoId:'bhBcKyuk06s'
         }]
        },    
        {
            employername:'Premier Service Company, Inc. ',
            employersite:"http://www.premierservicecompany.com/",
            employerLogo:"premier-service-logo.jpg",
            employerp1:"Premier Service Company is a full service heating, cooling, electrical, plumbing and alarm company serving the residential, commercial and industrial markets of West Alabama. ",
            employerp2:"",
            employerp3:"",
            brochure:"https://waw-image.s3.amazonaws.com/wawpdfs/Premier+Service+Company.pdf",
            images:[],
            videos:[             { videoType:'youtube',videoUrl:"https://youtu.be/WgiXsvMGO2E",videoId:'WgiXsvMGO2E'}
          ]
         },    
         {
          employername:'Tuscaloosa City Infrastructure and Public Services',
          employersite:"https://www.tuscaloosa.com/__aws/media/10300733_2020-annual-report_ips_71621.pdf",
          employerLogo:"tuscaloosa-public-services-logo.jpg",
          employerp1:"The City of Tuscaloosa Human Resources Department provides support to the mayor of the City of Tuscaloosa in the management and governing of the City’s human resources.",
          employerp2:"This information is intended to provide individuals interested in public service careers with the City an opportunity to be made aware of jobs that become available and general information about those jobs.",
          employerp3:"",
          images:[],
          videos:[
                 {
                  videoType:'vimeo',videoUrl:"https://vimeo.com/618982198/637ef037aa",videoId:'zZJxU66KaAs'
                 }]

        }, 
         {
             employername:'The Builders Group ',
             employersite:"http://www.thebuildersgroup.info/",
             employerLogo:"builders-group-logo.jpg",
             employerp1:"The Builders Group is a diversified residential construction company. We build houses! TBG was established in 2001 in Tuscaloosa, Alabama. We have expanded and established two locations in Tuscaloosa and Huntsville.   ",
             employerp2:"",
             employerp3:"",
             images:[],
             videos:[{
                     videoType:'youtube',videoUrl:"https://vimeo.com/536063078/8642406f96",videoId:'pVL2KrrkuDg'
               }]
         },    

         {
             employername:'WAR Construction, Inc ',
             employersite:"http://www.warconstruction.com/",
             employerLogo:"war-logo.jpg",
             employerp1:"WAR Construction, Inc. is a full service general contractor specializing in traditional bid-build, construction management, and design build. The company has been in business over 60 years, with continuous growth. We are currently looking for ambitious team members to help us continue our growth into the future. ",
             employerp2:"",
             employerp3:"",
             images:[],
             videos:[]
         },      
         {
          employername:'The CAT Rental Store - Thompson Tractor',
          employersite:"https://thompsontractor.com/",
          employerLogo:"cat-rental-logo.jpg",
          employerp1:"Thompson Tractor Company is the full-line Caterpillar dealer for Alabama and northwest Florida and the Hyundai forklift dealer for most of Georgia. Thompson Tractor specializes in sales, rental and service of Caterpillar products, including earthmoving, construction and materials handling equipment, along with diesel engines used for electric power generation, on-highway and marine propulsion applications. Thompson Tractor has been serving our community since 1957.",          employerp2:"",
          employerp3:"",
          brochure:"https://waw-image.s3.amazonaws.com/wawpdfs/CAT_Thompson+Tractor.pdf",
          images:[],
          videos:[{
                   videoType:'youtube',videoUrl:"https://vimeo.com/536063109/6c09bdef06",videoId:'WOsVB81pG1o'
            }]
      },                                           

      ],
      
      carouselVideos:[]
         
    },
    cosmetology:{
      empForBox:[],
      carouselVideos:[]
         
    },
    agriculture:{
     empForBox: [  
       {
      employername:'Natural Resource Conservation Service',
      employersite:"https://www.nrcs.usda.gov/wps/portal/nrcs/site/national/home/",
      employerLogo:"nrcs-logo.jpg",
      employerp1:"The Natural Resources Conservation Service (NRCS) draws on a long history of helping people help the land. For more than 80 years, NRCS and its forunner agencies have worked in close partnerships with farmers and ranchers, local and state governments, and other federal agencies to maintain healthy and productive working landscapes",
      employerp2:"",
      employerp3:"",
      brochure:"",
      images:[],
      videos:[{
        videoType:'youtube',
        videoUrl:"https://youtu.be/cUIAZcjjB_g"
        ,videoId:'cUIAZcjjB_g'
   }]
  },
  {
    employername:'Peco Foods, Inc',
    employersite:"https://pecofoods.com/",
    employerLogo:"peco-logo.jpg",
    employerp1: "Peco, an industry leading supplier, is recognized for our dedication to customer satisfaction, competitive cost and total quality management. Delivering quality poultry products for the industrial, retail and food service markets across the U.S. and around the globe. Collaboration, transparency and partnership are the core of our relationships with customers, supplier partners and team members.",
    employerp2:"",
    employerp3:"",
    images:[],
    videos:[]
  },

],
      carouselVideos:[]
         
     },
    education:{
      empForBox:[],
    carouselVideos:[]
         
    },
    energy_and_utilities:{
      empForBox:[
        {
          employername:'Alabama Power ',
          employersite:"http://www.alabamapower.com/",
          employerLogo:"alabama-power-logo.jpg",
          employerp1:"Alabama is a beautiful place with extraordinary natural resources. Protecting those resources, while providing reliable, affordable electricity for our customers is at the heart of our company’s mission and why we work hard to provide value to the communities we serve.  Alabama Power is one of the most respected companies in Alabama. We value our employees because we know they are our most valuable asset. We realize that every job and every employee who performs it is vital to the overall success of our company. There are lots of great reasons to join our team.",
          employerp2:"",
          employerp3:"",
          brochure:"",
          images:[],
          videos:[
            {
              videoType:'youtube',
              videoUrl:"https://youtu.be/yMsZ27eernI",
              videoId:'yMsZ27eernI'
            },
            {
              videoType:'youtube',
              videoUrl:"https://youtu.be/a4TvolCpy9U",
              videoId:'a4TvolCpy9U'
            },
            {
              videoType:'youtube',
              videoUrl:"https://youtu.be/tTumjWLePnE",
              videoId:'tTumjWLePnE'
            },
            {
              videoType:'youtube',
              videoUrl:"https://youtu.be/lxmP4yEocEw",
              videoId:'lxmP4yEocEw'
            },
          ]
       },    
       {
        employername:'TransferVR',
        employersite:"https://www.transfrvr.com/",
        employerLogo:"transfervr-logo.jpg",
        employerp1:"TRANSFR IS ON A MISSION.We are building the classroom-to-career pathway by delivering training that gives people the skills they need to enter the workforce."
       +" Our technology provides immersive training, shown to have higher retention when compared to video tutorials, slide presentations, or other training methods. We focus on developing a pipeline of talent for jobs that are going unfilled, working with technical/community colleges, high schools, and workforce development departments while reducing cost and risk for employers.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[]
    },  
    {
      employername:'Tuscaloosa City Infrastructure and Public Services',
      employersite:"https://www.tuscaloosa.com/__aws/media/10300733_2020-annual-report_ips_71621.pdf",
      employerLogo:"tuscaloosa-public-services-logo.jpg",
      employerp1:"The City of Tuscaloosa Human Resources Department provides support to the mayor of the City of Tuscaloosa in the management and governing of the City’s human resources.",
      employerp2:"This information is intended to provide individuals interested in public service careers with the City an opportunity to be made aware of jobs that become available and general information about those jobs.",
      employerp3:"",
      images:[],
      videos:[
             {
              videoType:'vimeo',videoUrl:"https://vimeo.com/618982198/637ef037aa",videoId:'zZJxU66KaAs'
             }]

    }, 
       {
          employername:'Hunt Refining Company',
          employersite:"http://www.huntrefining.com/",
          employerLogo:"hunt-logo.jpg",
          employerp1:"Hunt Refining Company is a privately held petroleum refiner headquartered in Tuscaloosa, Alabama. The company markets asphalt, transportation fuels, light oils and other refinery products predominantly in the southeastern United States.",
          employerp2:"",
          employerp3:"",
   brochure:"https://waw-image.s3.amazonaws.com/wawpdfs/Hunt.pdf",
          images:[],
          videos:[{
                  videoType:'youtube',videoUrl:"https://youtu.be/zZJxU66KaAs",videoId:'zZJxU66KaAs'
                 }]
       },    
       {
        employername:'Enviva',
        employersite:"https://www.envivabiomass.com",
        employerLogo:"enviva-logo.jpg",
        employerp1:"Enviva is a leading global energy company specializing in sustainable wood bioenergy. We are the world’s largest producer of sustainable wood pellets, which provide a low-carbon alternative to fossil fuels.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
                videoType:'vimeo',videoUrl:"https://vimeo.com/618184753/6a37a67971",videoId:'zZJxU66KaAs'
               }]
     }, 
       {
           employername:'Spire',
           employersite:"https://www.spireenergy.com/",
           employerLogo:"spire-logo.jpg",
           employerp1:"Every day we serve more than 1.7 million customers making us the fifth largest publicly traded natural gas company in the country. We help families and business owners fuel their daily lives through our gas utilities serving Alabama, Mississippi and Missouri. Our natural gas-related businesses include Spire Marketing, Spire STL Pipeline and Spire Storage. We are committed to transforming our business and pursuing growth through 1) growing organically, 2) investing in infrastructure, 3) advancing through innovation.",
           employerp2:"",
           employerp3:"",
           images:[],
           videos:[{
                videoType:'youtube',videoUrl:"https://vimeo.com/536063071/3b32d4ecb2",videoId:'FxRBvTUf9lA'
           }]
       },

      ],
    carouselVideos:[]
         
    },
  
    engineering_and_environment:{
      empForBox:[
        {
          employername:'Mercedes-Benz U.S. International, Inc.',
          employersite:" https://www.mbusa.com/en/careers",
          employerLogo:"mercedes-benz-logo.jpg",
          employerp1:"Welcome to Tuscaloosa County Alabama, U.S.A, and the production location of the Mercedes-Benz GLE SUV, GLS SUV, GLE Coupe, and the Mercedes-Maybach GLS. Mercedes-Benz U.S. International (MBUSI) started with a clean \"sheet of paper\" in terms of vehicle concept, in the way it would produce Mercedes-Benz quality in the design of the factory and in the establishment of a young corporate culture. The result is Mercedes-Benz vehicles that are continually setting the standard in the industry. We build superior quality automobiles here in Alabama using Mercedes-Benz and MBUSI’s original production system which is a combination of American, Japanese, and German automotive best practices. This process contributes to the quality manufacturing of our vehicles in a safe, efficient, and predictable way.",
          employerp2:"",
          employerp3:"",
  
          images:[],
          videos:[{
            videoType:'youtube',videoUrl:"https://vimeo.com/539367624/3a63897025",videoId:'rtkfAt3ebaY'
            
  
               }]
       },    
       {
           employername:'Nucor Steel Tuscaloosa',
           employersite:" http://www.nucor.com/",
           employerLogo:"nucor-logo.jpg",
           employerp1:"Nucor is North America's leading producer of steel and steel products, with operating facilities primarily in the U.S. and Canada. Products produced include: carbon and alloy steel -- in bars, beams, sheet and plate; hollow structural section tubing; electrical conduit; steel piling; steel joists and joist girders; steel deck; fabricated concrete reinforcing steel; cold finished steel; steel fasteners; metal building systems; steel grating; and wire and wire mesh. We are also North America's largest recycler.",
           employerp2:"",
           employerp3:"",
           images:[],
           videos:[{
                videoType:'youtube',videoUrl:"https://vimeo.com/536063052/d85867ae9a",videoId:'_jzg8fjk0tg'
           }]
       },

       {
        employername:'Alabama Power Company',
        employersite:"https://www.alabamapower.com/our-company.html",
        employerLogo:"alabama-power-logo.jpg",
        employerp1:"Alabama is a beautiful place with extraordinary natural resources. Protecting those resources, while providing reliable, affordable electricity for our customers is at the heart of our company’s mission and why we work hard to provide value to the communities we serve.  Alabama Power is one of the most respected companies in Alabama. We value our employees because we know they are our most valuable asset. We realize that every job and every employee who performs it is vital to the overall success of our company. There are lots of great reasons to join our team.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'vimeo',videoUrl:"https://vimeo.com/fusionpoint/review/503239101/196b93c109",videoId:'503239101'
        },
        {
          videoType:'vimeo',videoUrl:"https://vimeo.com/fusionpoint/review/506304004/f10a52dcfa",videoId:'506304004'
        },
        {
          videoType:'vimeo',videoUrl:"https://vimeo.com/fusionpoint/review/503240238/50782111aa",videoId:'503240238'
        },
        {
          videoType:'vimeo',videoUrl:"https://vimeo.com/fusionpoint/review/503239295/c67a8fb082",videoId:'503239295'
        },
      ],
      },

      {
        employername:'University of Alabama College of Engineering ',
        employersite:"https://eng.ua.edu/",
        employerLogo:"uac-engineering-logo.jpg",
        employerp1:"The College of Engineering offers many activities students can pursue while at the Capstone. Our programs are designed so our students can meet their personal education goals while also allowing them to experience life fully at a major liberal arts and research university. Our students are customizing their individual areas of interest and maximizing their engineering educational program. As the demand for engineers and computer scientists continues to grow, UA's College of Engineering is focused on preparing our students for the infinite number of opportunities available.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[
      ],
      },

      ],
    carouselVideos:[]
         
    },
    healthcare:{
      empForBox:[
        {
          employername:'DCH Health Systems',
          employersite:"https://www.dchsystem.com/",
          employerLogo:"dch-logo.jpg",
          employerp1:"For over a century, the DCH Health System has had local ownership and leadership, and we continually evolve to offer advanced caring to West Alabama. Today, the DCH Health System includes DCH Regional Medical Center, Northport Medical Center, and Fayette Medical Center. DCH Regional Medical Center is the cornerstone of the DCH system. The 583-bed Regional Medical Center offers a variety of specialty units and advanced services, including cancer, cardiology, robotic and minimally invasive surgery, and the region's advanced trauma center. ",
          employerp2:"Northport Medical Center is a 204-bed community hospital that offers a full range of inpatient and outpatient services. The facility also operates important specialty services, including the DCH Rehabilitation Pavilion and North Harbor Pavilion for mental health. Fayette Medical Center, through a lease agreement with the DCH Health System, is a 61-bed rural hospital that offers the residents of Fayette County inpatient care, along with sophisticated diagnostic equipment, surgical techniques and specialty clinics. A 122-bed nursing home on site is fully accredited and licensed for intermediate and skilled nursing care. Through these hospitals, the Health System provides a wide range of health care services. Click here to learn more about our services.",
          employerp3:"Mission: We serve to improve the health of our patients and community."
          +"Vision: To be the provider of choice in our market by delivering excellent care to patients and families in West Alabama.",
          images:[],
          videos:[{
            videoType:'youtube',videoUrl:"https://youtu.be/QP-vcqPCLSk",videoId:'QP-vcqPCLSk'
       }]
       },            {
        employername:'Bibb Medical Center',
        employersite:"https://www.bibbmed.com/",
        employerLogo:"bibb-logo.jpg",
        employerp1:"Bibb Medical Center is a 35-bed acute care facility. Inpatient care, post-acute care rehabilitation, intravenous therapy, wound care, and other quality services are provided in our clean, comfortable environment by our dedicated team of professionals. The system is also comprised of a 131 bed Nursing Home, Rural Health Clinic and Continuing Care Retirement Community that complement the Hospital and over-all continuum of care.",
        employerp2:"Outpatient healthcare services provided by BMC include Emergency Care, Laboratory Services, Radiology, Respiratory and Physical Therapy. In recent years BMC has expanded hospital and nursing facilities to meet the changing needs of a diverse community.",
        employerp3:"",
        images:[],
        videos:[{
     }]
     },   {
          employername:'Tuscaloosa VA Medical Center',
          employersite:"https://www.tuscaloosa.va.gov/",
          employerLogo:"va-logo.jpg",
          employerp1:"The Tuscaloosa VA Medical Center is a Level-3 Tertiary Care Facility located in Tuscaloosa, Alabama. We serve eligible Veterans across 13 counties in the West Alabama catchment area. We specialize in Primary Care, Mental Health Care, Long-term Care and some select Specialty Care Services. A list of services provided at the Tuscaloosa VA can be found in our interactive Resource Guide below.",
          employerp2:"",
          employerp3:"",
          brochure:"https://www.tuscaloosa.va.gov/TUSCALOOSA/docs/Guide_to_Services_TVAMC.pdf",
          images:[],
          videos:[{
                 
               }]
       }, 
       {
        employername:'Hospice of West Alabama',
        employersite:"https://hospiceofwestal.com/",
        employerLogo:"hospice-west-logo.jpg",
        employerp1:"Hospice of West Alabama (HOWA) has provided care to terminally ill patients and their families since May of 1982. We are incorporated as a 501(c)(3) private non-profit organization and governed by a 25-member Board of Directors.",
        employerp2:"The agency is the oldest provider of hospice services in the West Alabama area and is a member of the United Way of West Alabama. HOWA is certified to provide services under the Medicare and Medicaid programs and is an approved provider for the Blue Cross Blue Shield of Alabama. In 1998, HOWA became the first community-based hospice in Alabama to attain accreditation by The Joint Commission.",
        employerp3:"Hospice of West Alabama is the only agency serving West Alabama with its own free-standing inpatient facility, which has served more than 6,500 patients since it opened on November 1, 2004."+"The agency staff of more than 90 individuals serves hundreds of patients annually in Tuscaloosa, Pickens, Greene, Hale, Bibb, Fayette, and Lamar counties.",
        brochure:"https://waw-image.s3.amazonaws.com/wawpdfs/110935167_howa_brochure-medicine_ministry_2020.pdf",
        images:[],
        videos:[{
               
             }]
     }, 
     {
      employername:'River Oaks Health',
      employersite:"https://www.riveroakshealth.org/",
      employerLogo:"river-oaks-logo.jpg",
      employerp1:"River Oaks Health is focused on individual and family behavioral health and well-being. We provide therapy and counseling services through our Employee Assistance and Psychiatry and Counseling programs.",
      employerp2:"",
      employerp3:"",
      images:[],
      videos:[{
             
           }]
   },     
   {
    employername:'IRBH',
    employersite:"https://www.irbh.org/",
    employerLogo:"indian-river-logo.jpg",
    employerp1:"Indian Rivers is a comprehensive behavioral health provider. We offer a rich continuum of care for mental illness, substance abuse, and developmental disabilities. Established in 1969 with the goal of providing excellent care to individuals coping with behavioral health needs. ",
    employerp2:"We treat a variety of diagnoses such as A.D.H.D., depression, anxiety, thought disorders, mood disorders, psychotic disorders, substance abuse, and developmental disabilities.",
    employerp3:"",
    images:[],
    videos:[{
           
         }]
 },     
      ],
    carouselVideos:[]
         
    },
    hospitality_and_tourism:{
      empForBox:[
        {
          employername:'Hampton Inns of Tuscaloosa ',
          employersite:"http://www.hamptoninn.com",
          employerLogo:"hampton-logo.jpg",
          employerp1:"Our hotels are in the focused service segment. Meaning when you visit one of our locations you can expect friendly efficient service and clean comfortable surroundings. All our amenities are offered to our guest at NO extra cost so when you come to Hampton Inn you get everything including the HOT BREAKFAST BAR at no additional cost to you and your guest. We are known for making the hotel experience fun for our guests and for our teams!",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[{
                 
               }]
       },    
       {
        employername:' Hampton Inn Tuscaloosa-East',
        employersite:"https://www.hilton.com/en/hotels/tclbmhx-hampton-tuscaloosa-east/",
        employerLogo:"hampton-tuscaloosa-east-logo.jpg",
        employerp1:"Our hotels are in the focused service segment. Meaning when you visit one of our locations you can expect friendly efficient service and clean comfortable surroundings. All our amenities are offered to our guest at NO extra cost so when you come to Hampton Inn you get everything including the HOT BREAKFAST BAR at no additional cost to you and your guest. We are known for making the hotel experience fun for our guests and for our teams!",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
               
             }]
     },   
        {
          employername:' Hampton Inn Tuscaloosa-University',
          employersite:"https://www.hilton.com/en/hotels/tclalhx-hampton-tuscaloosa-university/",
          employerLogo:"hampton-tuscalooosa-univ-logo.jpg",
          employerp1:"Our hotels are in the focused service segment. Meaning when you visit one of our locations you can expect friendly efficient service and clean comfortable surroundings. All our amenities are offered to our guest at NO extra cost so when you come to Hampton Inn you get everything including the HOT BREAKFAST BAR at no additional cost to you and your guest. We are known for making the hotel experience fun for our guests and for our teams!",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[{
                
              }]
      },  
      {
        employername:'Holiday Inn Express & Suites Tuscaloosa East',
        employersite:"https://hiexpress.com/cottondaleal",
        employerLogo:"holidayinn-logo.jpg",
        employerp1:"Welcome to the brand new Holiday Inn Express & Suites located in East Tuscaloosa, Alabama! Our property is conveniently located off I-20/59 at Exit 77 . We are approximately 7 miles away from The University of Alabama and welcome fans and prospective students from all over! We are also located 13 miles away from the Mercedes Benz US International plant in Vance, AL and are proud to serve their employees and vendors.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
              
            }]
    }, 
    {
      employername:'Tuscaloosa Tourism & Sports',
      employersite:"https://www.visittuscaloosa.com",
      employerLogo:"tuscaloosa-tourism-logo.jpg",
      employerp1:"Tuscaloosa Tourism & Sports is a Destination Marketing Organization that's mission is to bring visitors to our community through conferences, conventions, leisure travel, group tours and sporting events. We help create economic impact from those visitors overnight stays in our hotels, dining in our incredible restaurants and shopping in our retail establishments.",
      employerp2:"",
      employerp3:"",
      images:[],
      videos:[{
            
          }]
    }, 
      ],
    carouselVideos:[]
         
    },
    manufacturing_auto_logistics:{
      empForBox:[
        {
          employername:'ARD Logistics ',
          employersite:"http://www.ardlogistics.com/",
          employerLogo:"ard-logo.jpg",
          employerp1:"ARD Logistics has a global network of resources to meet your supply chain opportunities and rise to the challenge. The roster of well-known, global brands we service reflects our track record of success and the breadth of services we successfully perform for them. While we are compliant in the most current process and supply chain certifications, we tailor each solution to the exact supply chain challenge your company faces. We are industry agnostic, whether its Aerospace to Pharmaceuticals, our dedicated employees are passionate to address your challenge with proven solutions. ARD is the company you want making your supply chain more efficient and effective.",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[{
            videoType:'youtube',videoUrl:"https://youtu.be/H8IfiLwzWBo",videoId:'H8IfiLwzWBo'
            
  
               }]
       },  
       {
        employername:'ARCH Precision Components - Tuscaloosa',
        employersite:"https://archglobalprecision.com/",
        employerLogo:"arch-logo.jpg",
        employerp1:"ARCH is a consolidated contract manufacturing company, a combination of engineering expertise and precision-machining technologies to serve the medical, aerospace and defense, and industrial markets. We offer you a synergy of resources to reduce your manufacturing costs, optimize the performance of your components and products, and mitigate your supply chain risks. Our facilities are strategically located across the U.S. to optimize your outsourced precision-machining needs.",        employerp2:"",
        employerp3:"Founded in 2011 by Strength Capital Partners, ARCH is structured to provide the capacity, flexibility, and scalability you need to succeed. We provide design-for-manufacturability, prototyping, on-site application testing, program management, technical support, and customized services. Working with ARCH, your business gains the advantage of an expanding range of cutting-edge resources for all your precision-machining needs.",
        images:[],
        videos:[]
     },    
       {
          employername:'BF Goodrich-Michelin NA',
          employersite:"http://www.bfgoodrichtires.com/",
          employerLogo:"bfgoodrich-logo.jpg",
          employerp1:"Dedicated to the improvement of sustainable mobility, Michelin designs, manufactures and sells tires for every type of vehicle, including airplanes, automobiles, bicycles, earthmovers, farm equipment, heavy-duty trucks and motorcycles.  Michelin also offers a full range of innovative services and solutions that help make mobility safer, more efficient and more environmentally friendly. To create unique mobility experiences, Michelin publishes travel guides, hotel and restaurant guides, maps and road atlases.",
          employerp2:"Headquartered in Greenville, S.C., Michelin North America employs more than 20,000 people and operates 19 major manufacturing plants in the U.S. and Canada. The Tuscaloosa, Alabama facility manufactures BFGoodrich brand passenger and light truck tires (original equipment and replacement market) and racing slicks.",
        //  employerp3:"With more than 100 years of heritage, BFGoodrich® Tires is dedicated to providing high-performance tires for those who have a passion for driving in virtually any environment. Combining technical expertise with 40 years of motorsports experience, BFGoodrich® Tires delivers tires for a full range of driving experiences from ultra-high performance street, to off-road terrain with one common theme – extreme performance. "
        //  +"Michelin is recognized as an  \"Employer of Choice\" by the people who work here. They stay at Michelin because they have careers with a purpose at a company with a purpose. In fact, more than 97 percent of Michelin employees say they choose to move forward together with our company each year. "
        //  + "Michelin’s status as a great place to work has been validated by the leading authorities on workplace culture:  "
        //  +"·       Forbes list of “Most Diverse Place to Work” and “Best Employer for New Grads” "
         // +"·       Fortune list of “World Most Admired Companies” " 
          //+"·       Voted on the top 50 Employers list by the readers of Minority Engineer magazine, Women Engineer magazine and STEM Workforce Diversity magazine. "
          //+"·       Top Company for Women to work for in Transportation." 
          //+"Michelin takes an advanced approach to career development, ensuring that you will come for a job but stay for a career. More than 75 percent of our managers are promoted from within, and we offer multiple career development paths to challenge employees to grow and realize their purpose across the span of their individual careers."
         // ,
          brochure:"https://waw-image.s3.amazonaws.com/wawpdfs/BF+Goodrich-Michelin.pdf",
          images:[],
          videos:[{
            videoType:'youtube',videoUrl:"https://youtu.be/bjODCp5MnSg",videoId:'bjODCp5MnSg'
            
  
               }]
       },    
       {
           employername:'Bolta US, Inc.',
           employersite:" http://www.bolta.com/en.html",
           employerLogo:"bolta-logo.jpg",
           employerp1:"Bolta develops and produces surface-finished components – predominantly for the automotive industry. A focus that benefits all parties involved. As a partner of the most prestigious manufacturers we continually help them to shape their image. The results are components that emphasize our customers’ quality and brand values – and bring them to life. From nameplates to decorative trims and elements all the way to large and particularly complex components: We transform technical challenges into first-class solutions. Worldwide – with consistently high quality standards.",
           employerp2:"“High Performance Surface Technologies” is more than an advertising slogan for us – it defines our performance promise: The perfect combination of performance and quality.",
           employerp3:"    Welcome to Bolta Werke!",
           images:[],
           videos:[{ videoType:'youtube',videoUrl:"https://youtu.be/l1tBklRsB00",videoId:'l1tBklRsB00'}]
       },
       {
        employername:'Bolzoni Auramo Inc',
        employersite:"https://us.bolzonigroup.com/company/manufacturing_plants.php",
        employerLogo:"bolzoni-logo.jpg",
        employerp1:"The Bolzoni Auramo Inc. plant in Sulligent manufactures hydraulically operated attachments for fork lift trucks and cylinders providing excellent product quality and on-time deliveries.",
        employerp2:"Our manufacturing facilities are equipped with inspection equipment incorporating the latest technologies, while quality control inspectors verify accuracy and spot-check products to make sure that performances meet or exceed specifications. The well recognized products and services quality provided are reached thanks to the adoption of most up-to-date production techniques and by the use of best-in-class practices",
        employerp3:"The Sulligent plant has a building area of 301,000 square foot. The manufacturing facility employees either work directly with or support GMAW welding of forklift truck attachments, horizontal and vertical CNC machine centres, internal and external CNC grinding, gear cutting, and assembling.",
        images:[],
        videos:[{
          videoType:'youtube',link:"",videoId:'a-Z71qT5z4w'
          

             }]
     },    
     {
      employername:'Lear Corporation',
      employersite:"https://www.lear.com/",
      employerLogo:"lear-logo.jpg",
      employerp1:" Lear Corporation is ranked #148 on the Fortune 500 with world-class products designed, engineered and manufactured by a diverse team of talented employees. Our vision is to be consistently recognized as the supplier of choice, an employer of choice, the investment of choice and a company that supports the communities where we do business.",
      employerp2:"",
      employerp3:"",
      images:[],
      videos:[{
        videoType:'youtube',videoUrl:"https://youtu.be/OK-6CE8kTjY",videoId:'OK-6CE8kTjY'
        

           }]
   },      {
    employername:'Buffalo Rock',
    employersite:"https://www.buffalorock.com/distribution-centers/tuscaloosa/",
    employerLogo:"buffalorock-logo.jpg",
    employerp1:"The Lee family started Buffalo Rock Company in 1901, and it has been an iconic part of the beverage industry ever since. The mission of the Buffalo Rock Company is to be the premier provider of beverages and food products. We have 10 divisions inclusive of 2,100 employee-partners who are committed to this mission."
    +"With over 119 years of experience in the beverage industry, Buffalo Rock has been recognized throughout the years as an innovator and has won numerous industry awards; namely the Donald M. Kendall Bottler of the Year Award (the highest honor bestowed upon Pepsi bottlers) as well as Beverage Industry’s Bottler of the year (the highest recognition bestowed in the industry)."
    +"Buffalo Rock is the largest single family-owned Pepsi bottler in the United States. In addition, as part of Keurig Dr Pepper’s Pepsi system, Buffalo Rock is the 2nd largest Dr Pepper bottler, and largest Sunkist and Canada Dry bottler.",
    employerp2:"We are proud to be a neighborhood company, made up of dedicated men and women who strive to be the best each and every day.",
    employerp3:"",
    images:[],
    videos:[{
      videoType:'youtube',videoUrl:"https://youtu.be/DiUmP320-X8",videoId:'DiUmP320-X8'
    }]
 },   
 {
  employername:'Westervelt Lumber',
  employersite:"https://westervelt.com/",
  employerLogo:"westervelt-logo.jpg",
  employerp1:"With more than 50 years of lumber manufacturing experience, Westervelt Lumber produces a wide variety of high-performance products for customers in both domestic and international markets. Providing high quality Southern yellow pine lumber is what we do best.",
  employerp2:"",
  employerp3:"",
  images:[],
  videos:[{
    videoType:'vimeo',videoUrl:"https://vimeo.com/536063173/109c4d925a",videoId:'9xn_qRM2BHQ'
}]
},  
     {
        employername:'Mercedes-Benz U.S. International, Inc.',
        employersite:" https://www.mbusa.com/en/careers",
        employerLogo:"mercedes-benz-logo.jpg",
        employerp1:"Welcome to Tuscaloosa County Alabama, U.S.A, and the production location of the Mercedes-Benz GLE SUV, GLS SUV, GLE Coupe, and the Mercedes-Maybach GLS. Mercedes-Benz U.S. International (MBUSI) started with a clean \"sheet of paper\" in terms of vehicle concept, in the way it would produce Mercedes-Benz quality in the design of the factory and in the establishment of a young corporate culture. The result is Mercedes-Benz vehicles that are continually setting the standard in the industry. We build superior quality automobiles here in Alabama using Mercedes-Benz and MBUSI’s original production system which is a combination of American, Japanese, and German automotive best practices. This process contributes to the quality manufacturing of our vehicles in a safe, efficient, and predictable way.",
        employerp2:"",
        employerp3:"",

        images:[],
        videos:[{
          videoType:'youtube',videoUrl:"https://youtu.be/rtkfAt3ebaY",videoId:'rtkfAt3ebaY'
          

             }]
     },    
     {
         employername:'Nucor Steel Tuscaloosa',
         employersite:" http://www.nucor.com/",
         employerLogo:"nucor-logo.jpg",
         employerp1:"Nucor is North America's leading producer of steel and steel products, with operating facilities primarily in the U.S. and Canada. Products produced include: carbon and alloy steel -- in bars, beams, sheet and plate; hollow structural section tubing; electrical conduit; steel piling; steel joists and joist girders; steel deck; fabricated concrete reinforcing steel; cold finished steel; steel fasteners; metal building systems; steel grating; and wire and wire mesh. We are also North America's largest recycler.",
         employerp2:"",
         employerp3:"",
         images:[],
         videos:[{
              videoType:'youtube',videoUrl:"https://youtu.be/_jzg8fjk0tg",videoId:'_jzg8fjk0tg'
         }]
     },
     {
      employername:'Phifer Incorporated ',
      employersite:"http://www.phifer.com/",
      employerLogo:"phifer-logo.jpg",
      employerp1:"Phifer Incorporated is a leader in the insect screen, solar control fabrics, drawn wire, engineered products and designed fabrics industries. As the world’s largest producer of aluminum and fiberglass insect screening, Phifer has capitalized on its wire drawing and textile weaving expertise to flourish in these and other related markets. Our products keep pace with technological innovations and design trends, and our workforce reflects the diversity, international versatility and customer service commitment expected in today’s marketplace.",
      employerp2:"",
      employerp3:"",
      images:[],
      videos:[
             
        {
          videoType:'youtube',videoUrl:"https://youtu.be/8uo48UDyvMo",videoId:'8uo48UDyvMo'
     }]
   },    
   {
      employername:'SMP Automotive Systems of Alabama Inc.',
      employersite:"http://www.smp-automotive.com/",
      employerLogo:"smp-logo.jpg",
      employerp1:"SMP is a leading expert in high quality interior and exterior modules for the automotive industry. We are a member of the Samvardhana Motherson Group, one of the 21 largest automotive suppliers in the world. Our products characterize the inside and outside of the most admired vehicles worldwide.",
      employerp2:"",
      employerp3:"",

      images:[],
      videos:[{
        videoType:'youtube',videoUrl:"https://vimeo.com/539367641/f6a5fd18cb",videoId:'0fJKZZLRNYw'
   }]
  },    
   {
       employername:'Schnellecke Logistics Alabama',
       employersite:"http://www.schnellecke.com/",
       employerLogo:"schnellecke-logo.jpg",
       employerp1:"The Schnellecke Group is an international family company that offers a variety of logistics services. From small beginnings in Wolfsburg, Schnellecke has developed into a globally operating company in over 80 years. Schnellecke is still owned by the family. Today it is the third generation that directs the company's fortunes from Wolfsburg.",
       employerp2:"With our areas of logistics and production, we cover essential parts of the supply chain for companies all over the world. Our portfolio is rounded off by the planning and construction of logistics properties.",
       employerp3:"",
       images:[],
       videos:[{
            videoType:'youtube',videoUrl:"https://vimeo.com/536063060/a6af702ad6",videoId:'y3QtcVIfJnU'
       }]
   },
   
{
  employername:'ZF Chassis Systems, LLC',
  employersite:"https://www.zf.com/usa_canada/en/home/home.html",
  employerLogo:"zf-chassis-logo.jpg",
  employerp1:"ZF’s Tuscaloosa plant produces the axles for the Mercedes Benz U.S. International plant in Vance, AL. This is where the world’s supply of the GLE, GLE Coupe, GLE Maybach, and GLS SUVs are manufactured. ZF Tuscaloosa is MBUSI’s largest and longest standing Tier 1 supplier we make approximately 10% of the entire vehicle and have been in Tuscaloosa since 1997.",
  employerp2:"",
  employerp3:"",
  brochure:"http://westalabamaworks.com/wp-content/uploads/ZF.pdf",
  game:"https://romantic-einstein-a95603.netlify.app/zfgame",

  images:[],
  videos:[{
       videoType:'youtube',videoUrl:"https://vimeo.com/536933752/6b20da205b ",videoId:'p1RhJDYscfE'
  }]
},
{
  employername:'TransferVR',
  employersite:"https://www.transfrvr.com/",
  employerLogo:"transfervr-logo.jpg",
  employerp1:"TRANSFR IS ON A MISSION.We are building the classroom-to-career pathway by delivering training that gives people the skills they need to enter the workforce."
 +" Our technology provides immersive training, shown to have higher retention when compared to video tutorials, slide presentations, or other training methods. We focus on developing a pipeline of talent for jobs that are going unfilled, working with technical/community colleges, high schools, and workforce development departments while reducing cost and risk for employers.",
  employerp2:"",
  employerp3:"",
  images:[],
  videos:[]
},  
{
  employername:'WestRock',
  employersite:"https://www.westrock.com",
  employerLogo:"westrock-logo.jpg",
  employerp1: "WestRock is a leading global paper and packaging company with a vision to be the premier partner and unrivaled provider of sustainable, winning solutions to our customers across the globe. We have 50,000 team members working in 30 countries at 300 facilities around the world. Every day, our 50,000 team members combine practical innovations and rigorous execution across hundreds of operating and business facilities around the world to connect people to"+
  "products.",
  employerp2:"",
  employerp3:"",

  images:[],
  videos:[{
  }]
},
{
  employername:'BLG Logistics Inc.',
  employersite:"https://www.blgus.com",
  employerLogo:"blg-logo.jpg",
  employerp1: "BLG Logistics, Inc. is the U.S. subsidiary of the BLG LOGISTICS GROUP AG & Co. KG, which was founded in 1877 in Bremen, Germany and provides over 16,000 jobs worldwide.",
  employerp2:"We are a global leader in third-party logistics services, providing unique value-added solutions to highly technical clients. We utilize our international expertise, innovative technology, and developed workforce to bring the best of global logistics right to your doorstep.",
  employerp3:"",
  images:[],
  videos:[]
},
{
  employername:'Peco Foods, Inc',
  employersite:"https://pecofoods.com/",
  employerLogo:"peco-logo.jpg",
  employerp1: "Peco, an industry leading supplier, is recognized for our dedication to customer satisfaction, competitive cost and total quality management. Delivering quality poultry products for the industrial, retail and food service markets across the U.S. and around the globe. Collaboration, transparency and partnership are the core of our relationships with customers, supplier partners and team members.",
  employerp2:"",
  employerp3:"",
  images:[],
  videos:[]
},
{
  employername:'The Lincoln Electric Company',
  employersite:"https://www.lincolnelectric.com",
  employerLogo:"lincoln-logo.jpg",
  employerp1: "For over 125 years, Lincoln Electric has been the world leader in the design, development and manufacture of arc welding products, automated joining, assembly and cutting systems, plasma and oxy-fuel cutting and has a leading global position in brazing and soldering alloys.",
  employerp2:"Renowned as The Welding Experts® , our solutions are used across diverse industry sectors in over 160 countries. Headquartered in Cleveland, Ohio, we operate 55 manufacturing locations in 18 countries and generated $2.7B in revenue in 2020.",
  employerp3:"",
  brochure:"https://waw-image.s3.amazonaws.com/wawpdfs/110935167_mc0999.pdf",

  images:[],
  videos:[]
},

{
  employername:'Enviva',
  employersite:"https://www.envivabiomass.com",
  employerLogo:"enviva-logo.jpg",
  employerp1:"Enviva is a leading global energy company specializing in sustainable wood bioenergy. We are the world’s largest producer of sustainable wood pellets, which provide a low-carbon alternative to fossil fuels.",
  employerp2:"",
  employerp3:"",
  images:[],
  videos:[{
          videoType:'vimeo',videoUrl:"https://vimeo.com/618184753/6a37a67971",videoId:'zZJxU66KaAs'
         }]
}, 





      ],
    carouselVideos:[]
         
    },
    public_safety:{
      empForBox:[

        {
          employername:'ALEA',
          employersite:"https://www.alea.gov/",
          employerLogo:"alea-logo.jpg",
          employerp1:"The state of Alabama Law Enforcement Agency (ALEA) is committed to identifying and recruiting professional men and woman who are interested in joining our law enforcement family to protect and serve the public with honesty, integrity and humility. ALEA’s renowned law enforcement agency offers career specialization throughout the state to most notably include: Patrol, Criminal Investigation, Dignitary Protection (protecting the states top political leaders), EOD/SWAT, Aviation Unit Member, and Criminal Justice Training Instructor.",
          employerp2:"To work closely with the residents, businesses, civic groups and other agencies to solve the problems of crime and the fear of crime; to provide crime prevention and education programs; by incorporating the principles of community and problem oriented policing.",
          employerp3:"To fulfill our mission, the Tuscaloosa Police Department is dedicated to maintaining an efficient and effective organization for the administration of police services. We resolve to provide a quality work environment and the development of its members through effective, proactive, positive leadership and training.",
          images:[],
          videos:[{
            videoType:'youtube',link:"",videoId:'Ec1qLxQ3TRw'
            
  
               }]
       },    

       {
        employername:'City of Tuscaloosa Police Department',
        employersite:"https://www.tuscaloosa.com/pd",
        employerLogo:"tuscaloosa-city-police-logo.jpg",
        employerp1:"Our mission is to serve all people within our jurisdiction with respect, fairness and compassion. We are devoted to the prevention of crime, the protection of life and property, and the preservation of peace, order and safety."

        
        +"To safeguard the constitutional rights of every citizen and the quality of life they have grown to expect by ensuring that federal, state and local laws are enforced in a fair, consistent and swift manner.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'vimeo',videoUrl:"https://vimeo.com/393780299",videoId:'zZJxU66KaAs'
         },]
        }, 
        {
          employername:'AL Army National Guard',
          employersite:"https://www.nationalguard.com/select-your-state/AL",
          employerLogo:"army-logo.jpg",
          employerp1:"The Guard dates back to 1636, when Citizen-Soldiers formed militias to defend community and country. And for 377 years, the Guard has stayed true to its roots. Enlisting in the National Guard means more time at home. Training typically requires one weekend each month, with a two-week training period once each year. Get a degree with money for school, learn job skills that translate to the civilian world, make bonds that last a lifetime and earn pride for life. When you become a Guard Soldier, your family will thank you, your country will thank you and your future will owe you. Contact a recruiter to find more specifics about your opportunities in the Alabama Army National Guard.",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[]
      }, 
      {
        employername:'Northport Police Department',
        employersite:"https://www.cityofnorthport.org/departments/police-department",
        employerLogo:"northport-logo.jpg",
        employerp1:"The Northport Police Department provides a variety of Community Oriented services to the Citizens of Northport. Our Mission is to be the model Police Department of excellence in servicing the needs of the community by partnering with the community and others to achieve:",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[]
    }, 
    {
      employername:'Tuscaloosa County Sheriff',
      employersite:"https://www.tcsoal.org/",
      employerLogo:"sheriff-tusc-logo.jpg",
      employerp1:"The Tuscaloosa Sheriff’s Office is dedicated to accomplishing the organizational mission of a commitment to quality service with an emphasis on integrity, professionalism, and community spirit. Through cooperation, communications, problem solving partnerships, and dedication, our county can and will address the needs to provide necessary services and therefore enhance the quality of life for our citizens while reducing the fear of crime.",
      employerp2:"",
      employerp3:"",
      images:[],
      videos:[]
    }, 
      ],
    carouselVideos:[]
         
    },
   transportation:{
    empForBox:[
      {
        employername:'Southland Transportation Group',
        employersite:"http://southlandtransportationgroup.com",
        employerLogo:"southland-logo.jpg",
        employerp1:"Southland Transportation Group offers the newest line up of light, medium, and heavy duty trucks from International Trucks. Our selection of IC Bus products are the safest in the country with collision mitigation from Bendix. Our trailer center offers the newest from Wabash, Transcraft, and Benson, as well as an extensive selection of parts. Our pre-owned trucks are carefully selected by our professional experts, so you know they’re vehicles you can trust. Each location also provides the best in parts , service , rental , and lease . We will help you maintain your commercial truck to keep it running for years.",
        employerp2:"",
        employerp3:"",
        brochure:"http://westalabamaworks.com/wp-content/uploads/Southland-Transportation-Group.pdf",
        images:[],
        videos:[{
               
             }]
     },    
     {
        employername:'Tuscaloosa Chevrolet',
        employersite:"https://www.tuscaloosachevrolet.com/Career-Opportunities",
        employerLogo:"chevy-logo.jpg",
        employerp1:"Customers from all over central and western Alabama seek out Tuscaloosa Chevrolet because our large selection of quality, new and pre-owned vehicles are the best values around. When they arrive we depend on automotive professionals to help them select the proper vehicle or address their service needs, all while answering their questions along the way.",
        employerp2:"",
        employerp3:"",
       images:[],
        videos:[]
     },    
    
     {
      employername:'TransferVR',
      employersite:"https://www.transfrvr.com/",
      employerLogo:"transfervr-logo.jpg",
      employerp1:"TRANSFR IS ON A MISSION.We are building the classroom-to-career pathway by delivering training that gives people the skills they need to enter the workforce."
     +" Our technology provides immersive training, shown to have higher retention when compared to video tutorials, slide presentations, or other training methods. We focus on developing a pipeline of talent for jobs that are going unfilled, working with technical/community colleges, high schools, and workforce development departments while reducing cost and risk for employers.",
      employerp2:"",
      employerp3:"",
      images:[],
      videos:[]
  },  

    ],
    carouselVideos:[]
         
    },
  
   
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var CAW_Quiz_Object={ 
    agriculture:{
      empForBox:[
      {
        employername:'Natural Resource Conservation Service ',
        employersite:"https://www.nrcs.usda.gov/wps/portal/nrcs/site/national/home/",
        employerLogo:"nrcs-logo.jpg",
        employerp1:"The Natural Resources Conservation Service (NRCS) draws on a long history of helping people help the land. For more than 80 years, NRCS and its forunner agencies have worked in close partnerships with farmers and ranchers, local and state governments, and other federal agencies to maintain healthy and productive working landscapes",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
                videoType:'youtube',link:"https://www.youtube.com/watch?v=cUIAZcjjB_g&list=TLPQMjQwMzIwMjHqCZpyRvoORg",videoId:'cUIAZcjjB_g'
              }]
        },
        {
          employername:'Bonnie Plant Farms',
          employersite:"https://www.bonnieplants.com",
          employerLogo:"bonnie-logo.jpg",
          employerp1:"Bonnie Plants is the leading producer in the United States of live vegetable and herb plants for home gardeners.    Bonnie was founded in 1918 on the principle of providing access for home gardeners to fresh vegetables.   What started as one small farm in Alabama – has grown to nearly $400 million in sales – with over 80 greenhouse growing operations in 40 states, and a direct sales force of over 700 who deliver plants fresh to stores every day.    Our mission today is simple, but we believe powerful:  To grow and deliver quality plants that enhance peoples’ lives every day.",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[{
                  videoType:'youtube',link:"https://www.youtube.com/watch?v=Pv2iNm-Gdjg",videoId:'Pv2iNm-Gdjg'
                }]
          },  
  ],    carouselVideos:[

    {
      videoType:'youtube',
      title:"Ag Pro - Ag Pro Careers",
      website:"www.agprocompanies.com",
      link:"https://youtu.be/9giKKWu46TI",
      videoId:'521691260'
    },
    {
      videoType:'youtube',
      title:"Alabama Cooperative Extension System - Guide to Careers in Agriculture",
      website:"aces.edu",
      link:"https://youtu.be/bEIYIGx61Pk",
      videoId:'bEIYIGx61Pk'
    },
    {
      videoType:'youtube',
      title:" Forestry Works - Workforce Development Overview",
      website:"https://www.forestryworks.com/careers",
      link:"https://youtu.be/W58WZJYmGH4",
      videoId:'W58WZJYmGH4'
    },

  ]

    
},
    healthcare:{
      empForBox:[
      {
        employername:'EAMC',
        employersite:"https://www.eamc.org/",
        employerLogo:"eamc.jpg",
        employerp1:"In order to achieve our vision, East Alabama Medical Center has made a commitment to the continued pursuit of excellence with all of our partners to improve the quality of life for those we serve, to measure our progress against the highest standards in quality of care and quality of service, and to be fiscally responsible with our assets.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/D8RlH7LUKJ8",videoId:'D8RlH7LUKJ8'
        }],
      },
      {
        employername:'Vaughan Regional',
        employersite:"https://www.vaughanregional.com/",
        employerLogo:"vaughan.jpg",
        employerp1:"Vaughan Regional Medical Center is a sole community provider hospital located in historic Selma, Alabama. The 175-bed Joint Commission-accredited facility employs more than 600 people.he hospital's campus is located in Dallas County, which is nestled along the banks of the Alabama River. The community is well-known for its beautiful landscape and recreational opportunities. Residents are able to enjoy the comfort and conveniences of a small community. Yet more importantly, the Vaughan family prides itself on providing excellent care and customer services to the patients, family members and visitors on our campus.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[
          {
            videoType:'youtube',link:"https://youtu.be/Mou9QuiXqMo",videoId:'Mou9QuiXqMo'
          }
        ],
      },
      {
        employername:'Baptist First',
        employersite:"https://www.baptistfirst.org/",
        employerLogo:"baptist-logo.jpg",
        multilink:false,
        employerp1:"At Baptist First, Our doctors and clinicians come to us with a variety of impressive educational backgrounds, exceptional training and clinical experience. Each brings their own unique talents and a passionate commitment to help our patients achieve health and well-being. Together we combine our skills to serve patients in Central Alabama and beyond." ,
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'vimeo',videoUrl:"https://vimeo.com/654251854",videoId:'654251854'
          
        }]
      },
      {
        employername:'Bullock County Hospital',
        employersite:"http://www.bullockcountyhospital.com/",
        employerLogo:"bullock-logo.jpg",
        multilink:false,
        employerp1:"Bullock County Hospital, one of Alabama's oldest rural hospitals, treats more than 12,045 patients annually. The hospital provides 24-hour emergency care, inpatient medical care, physical therapy, home health services, EKG procedures, CT, X-rays, ultrasound, mammograms, laboratory tests, and more. It is also home to the Self Recovery Drug and Alcohol Addiction Program, Gateway Adult Psychiatric Unit and the Golden Years Geriatric Psych Unit. The hospital impacts the overall health and wellness of the residents in Bullock and surrounding counties." ,
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/zNDihSQEVjs",videoId:'zNDihSQEVjs'
        },
        {
          videoType:'youtube',link:"https://youtu.be/8RNGFVt62Fo",videoId:'8RNGFVt62Fo'
        }],
      }     
  ],

carouselVideos:[      {
  videoType:'youtube',
  title:"Ag Pro - Ag Pro Careers",
  website:"www.agprocompanies.com",
  link:"https://youtu.be/9giKKWu46TI",
  videoId:'521691260'
},
{
  videoType:'youtube',
  title:"Alabama Cooperative Extension System - Guide to Careers in Agriculture",
  website:"aces.edu",
  link:"https://youtu.be/bEIYIGx61Pk",
  videoId:'bEIYIGx61Pk'
},
{
  videoType:'youtube',
  title:" Forestry Works - Workforce Development Overview",
  website:"https://www.forestryworks.com/careers",
  link:"https://youtu.be/W58WZJYmGH4",
  videoId:'W58WZJYmGH4'
},
]
},
    manufacturing:{
    empForBox:[
      {
        employername:'To Your Health Sprouted Flour',
        employersite:"https://healthyflour.com/",
        employerLogo:"to-your-health-logo.jpg",
        employerp1:"Starting in a kitchen in 2005 baking for a few friends, word quickly spread and opportunities arose to bake for customers of several local and regional CSAs and co-ops. I wanted to make sprouted baked goods available to several additional markets and some retail outlets, so my husband Jeff built a commercial kitchen for my little venture in his new barn in September of 2006. The business then obtained state licensing as a food processor, hired two part-time employees and was “on the grow”.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/phDCOoGNPNk",videoId:'phDCOoGNPNk'
        }]
      },
      {
        employername:'GKN',
        employersite:"https://www.gkn.com/",
        employerLogo:"gkn-aero.jpg",
        employerp1:"GKN Aerospace is the world’s leading multi-technology tier 1 aerospace supplier. The Company was formally known as Guest, Keen, and Nettlefolds and can trace its origins back to 1759 during the birth of the industrial revolution. As a global company serving the world’s leading aircraft manufacturers, GKN Aerospace develops, builds and supplies an extensive range of advanced aerospace systems, components and technologies focusing on military and civil markets. GKN Aerospace employs approximately 17,000 people in more than 36 facilities across 3 continents. Our mission here at GKN Aerospace ACS, is to grow as a world-class provider of complex aerospace structures, assemblies and solutions that exceed our customers’ expectation in a global marketplace.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[
          {
            videoType:'youtube',link:"https://youtu.be/Al0NoRSyIdQ",videoId:'Al0NoRSyIdQ'
          }
        ],
      },
      {
        employername:'Fras-le',
        employersite:"https://www.fras-le.com/us-ca/",
        employerLogo:"frasle.jpg",
        employerp1:"At Fras-le you would be a part of the Randon firm of companies, a leader in the friction industry. Established in 1949 and headquartered in Caxias do Sul Brazil, Randon and its companies are globally recognized and dedicated to the training and development of their people, quality of products and sustainability and growth. Whether it's brake pads for yellow school buses — or rail shoes for trains — our company serves a vital role in the friction market. But we can't succeed on the reputation of our business alone — we succeed by bringing employees together to make an impact.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[
          {
            videoType:'youtube',link:"https://youtu.be/UnLP6lqK5_w",videoId:'UnLP6lqK5_w'
          }
        ],
      },
      {
        employername:'Golden State Foods',
        employersite:"https://goldenstatefoods.com/",
        employerLogo:"gsf-logo.jpg",
        employerp1:"Golden State Foods is one of the largest diversified suppliers to the foodservice industry. Headquartered in Irvine, California, the $5 billion company is values-based with proven performance in superior quality, innovation and customer service. GSF currently services 100+ leading brands (125,000+ restaurants/stores) from its 50+ locations on five continents. Its core businesses include: processing and distribution of liquid products, protein, produce, dairy and other services. The company employs approximately 6,000 associates and is 100 percent management-owned and run. Golden State Foods also operates a national non-profit organization, the GSF Foundation.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/xFrb1Bgqg1g",videoId:'xFrb1Bgqg1g'
        }]
      }, 
      {
        employername:'Hyundai',
        employersite:"https://www.hyundaiusa.com",
        employerLogo:"hyundai.jpg",
        employerp1:"To create and keep a world-class organization like Hyundai at the forefront, we happily strive to include everyone. Wonderfully talented people make Hyundai what it is today. We continue to attract, recruit and hire, the most talented people in the industry and they come with different backgrounds and beliefs, interests and inspiration. We partner with like-minded suppliers to help us grow. And we support more than 820 dealers across the country in giving every customer a positive Hyundai experience.  ",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',videoUrl:"https://youtu.be/P23qm-k90j4",videoId:'P23qm-k90j4'
        }]
      },
      {
        employername:'Neptune Technology Group, Inc.',
        employersite:"https://www.neptunetg.com/",
        employerLogo:"neptune.jpg",
        employerp1:"Neptune Technology Group Inc. is a technology company serving more than 4,000 water utilities across North America. We make data actionable using effective software and hardware tools that are interconnected by a smart network, with expertise and experience specifically focused on the business of water.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/LzwyXKeZh-Q",videoId:'LzwyXKeZh-Q'
        }]
      },
      {
        employername:'SiO2',
        employersite:"https://www.sio2ms.com/",
        employerLogo:"sio2-logo.jpg",
        employerp1:"SiO2 Materials Science is a materials science company with deep roots in chemistry and engineering and developed by an interdisciplinary team of world class scientists, engineers, and product development experts.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/2sof_R8uCtg",videoId:'2sof_R8uCtg'
        }]
      },
      {
        employername:'Rexnord',
        employersite:"https://www.rexnord.com/products-services/process-motion-control/couplings",
        employerLogo:"rexnord-logo.jpg",
        employerp1:"Rexnord Corporation has been a good place to work through a commitment to being innovative for 128 years. We combine a proven legacy of quality and growth with the newest thinking about technology, digital solutions and smart products in our industry. We’re looking for innovative, energetic people who want to help solve tomorrow’s challenges today. Our Process & Motion Control platform creates components that keep industries moving, from Aerospace to Energy to Consumer Goods. Our Water Management platform is innovating the safest and most efficient water solutions to protect human health and the environment. Our Rexnord Innovation Center is inventing new ways to develop and validate products and processes.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/2ut-BWQkA4o",videoId:'2ut-BWQkA4o'
        }]
      },
      {
        employername:'Rausch & Pausch (RAPA)',
        employersite:"https://www.rapa.com/en/",
        employerLogo:"rapa-logo.jpg",
        employerp1:"RAPA is a company with a long tradition and a forward-looking orientation. RAPA’s success story began as early as 1920 – and in a very manageable way. In Selb, at Badershof, the technician August Pausch founded a small handicraft business together with the merchant Hans Rausch. Their abbreviations “Ra” and “Pa” also gave the company its name. But it did not stop there. From small beginnings, RAPA has evolved from generation to generation, expanding its product portfolio and entering new markets. Internationalization has also been driven forward. At the company headquarters in Selb, the administration, production and logistics are housed on a 45,000 square meter site. In the meantime, RAPA is active nationally and internationally and is present with a plant in North America and its own sales office in China. Thus, since 1920, RAPA has successfully grown from a traditional family business to a global medium-sized company with almost 1000 employees worldwide. Nevertheless, RAPA is and remains an independent family business already in its fourth generation and is 100% family-owned. This ensures that RAPA is inseparably linked to the Pausch family of entrepreneurs. The management duo takes care of the company’s fortunes, in particular its further development, future strategies and visions.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/DcLG7KRPl-M",videoId:'DcLG7KRPl-M'
        }]
      },
      {
        employername:'Hager',
        employersite:"https://www.hagerco.com/",
        employerLogo:"hager-logo.jpg",
        employerp1:"Since Hager’s inception in 1849 our Hager brand commercial hinges have always been made right here in America. And it’s not just hinges. The bulk of Hager’s traditional door hardware lines are American-made. Currently, Hager Companies stands alone as the only provider of a full line of door hardware that is US-based.  Our corporate headquarters is in St. Louis, Missouri, where our legacy began more than 170 years ago, and our manufacturing plant in Montgomery, Alabama, creates the bulk of our traditional product lines. For us, it’s important to support every community around the world, but particularly the community where our story began.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/4dSr2EtJslc",videoId:'4dSr2EtJslc'
        },
        {
          videoType:'youtube',link:"https://youtu.be/__pxdAiV7y4",videoId:'__pxdAiV7y4'
        }]
      },
      {
        employername:'International Paper',
        employersite:"https://www.internationalpaper.com/",
        employerLogo:"int-paper-logo.jpg",
        employerp1:"International Paper transforms renewable resources into recyclable products that people depend on every day. They create packaging products that protect and promote goods, enable worldwide commerce and keep consumers safe. They also make pulp for diapers, tissue and other personal hygiene products that promote health and wellness.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/zbdf1Kuu6eY",videoId:'zbdf1Kuu6eY'
        }]
      },
      {
        employername:'James Hardie',
        employersite:"https://www.jameshardie.com/",
        employerLogo:"james-hardie-logo.jpg",
        employerp1:"James Hardie Industries is the world’s #1 producer and marketer of high-performance fiber cement and fiber gypsum building solutions. They employ a diverse global workforce of approximately 4,800 employees across North America, Europe, Australia, New Zealand, and the Philippines. James Hardie has opportunities available in manufacturing, production, sales, marketing, engineering, supply chain, accounting and finance, information technology, and more.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/1Z9NyQ-p0rY",videoId:'1Z9NyQ-p0rY'
        }]
      },
      {
        employername:'Vectorply',
        employersite:"https://vectorply.com/",
        employerLogo:"vectorply-logo.jpg",
        employerp1:"Vectorply is a world leader in the development, manufacturing and distribution of composite reinforcement fabrics engineered to optimize the stiffness, strength, weight and cost of customer-specific applications. We produce more than 350 unique styles of fabrics for an unlimited number of composite applications. Fibers such as E-glass, carbon, aramid are stitch-bonded in a variety of weights and architectures to help customers achieve their goals with optimized laminates.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/AF04nY_Crs8",videoId:'AF04nY_Crs8'
        }]
      },
      {
        employername:'Hyundai Mobis',
        employersite:"https://en.mobis.co.kr/main/index.do",
        employerLogo:"mobis-logo.jpg",
        employerp1:"Hyundai Mobis redefines the value of means of transportation by expanding it to a “Lifetime Partner in Automobiles and Beyond” based on products and services of the highest quality and achieves symbiotic coexistence with stakeholders by carrying out the vision of “Together for a Better Future.” In addition, to strengthen our presence and competitiveness as a company specializing in automotive parts, we have established the mid- and long-term vision of “Design the Future, Gain the Edge” and are making concerted efforts to achieve the goal.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/2ZJls1HGCDw",videoId:'2ZJls1HGCDw'
        }]
      },
      {
        employername:'WestRock',
        employersite:"https://www.westrock.com/",
        employerLogo:"westrock.jpg",
        employerp1:"We’re more than a supplier: we’re a partner that provides a competitive advantage, a partner that can deliver consistent quality and superior service, a partner that fuels innovation to foster real, sustainable growth. We have a long history of entrepreneurship, leadership and innovation. That history includes game-changing developments in papermaking, packaging design and retail solutions, as well as strategically building and strengthening our company's capabilities over time.  With operations around the world and expertise in every shopping category, we focus on working with brands to help them win across the board by providing an unbeatable portfolio of paper and packaging products. We’re building unbeatable packaging solutions together.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/61G6-sIIRtY",videoId:'61G6-sIIRtY'
        }]
      }, 
      {
        employername:'ThermoFisher Scientific',
        employersite:"https://www.thermofisher.com/us/en/home.html",
        employerLogo:"thermofisher-logo.jpg",
        employerp1:"Thermo Fisher Scientific Inc. is the world leader in serving science, with annual revenue of approximately $35 billion. Our Mission is to enable our customers to make the world healthier, cleaner and safer. Whether our customers are accelerating life sciences research, solving complex analytical challenges, improving patient diagnostics and therapies or increasing productivity in their laboratories, we are here to support them. Our global team of more than 90,000 colleagues delivers an unrivaled combination of innovative technologies, purchasing convenience and pharmaceutical services through our industry-leading brands, including Thermo Scientific, Applied Biosystems, Invitrogen, Fisher Scientific, Unity Lab Services and Patheon.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/McAM9j1ZU6o",videoId:'McAM9j1ZU6o'
        }]
      },
      {
        employername:'Pharmavite',
        employersite:"https://www.pharmavite.com/",
        employerLogo:"pharmavite-logo.jpg",
        employerp1:"In 1971, Pharmavite was founded in Los Angeles, California with the belief that there had to be a better way to being healthy than relying on prescriptions. It’s in our DNA to help make the world a better place. 50 years ago, our founders aimed to improve health and wellness by focusing on the basics - complete nutrition through essential nutrients and minerals. Since that day, we’ve been a leader in the health and wellness industry and have earned the trust of consumers, healthcare professionals, and retailers alike.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/GQ2KoD7Oz_A",videoId:'GQ2KoD7Oz_A'
        }]
      },
      {
        employername:'MGM Chamber',
        employersite:"https://www.montgomerychamber.com/",
        employerLogo:"mont-chamber.jpg",
        employerp1:"The Montgomery Area Chamber of Commerce catalyzes business and community leadership to improve the economic prosperity & quality of place of Montgomery and the river region. The Chamber is a fully-integrated economic development organization, dedicated to both job creation and job preservation. As a member-based business organization, we work hard to grow markets for our members, grow the River Region’s economy, and increase the quality of life for all.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{              videoType:'youtube',videoUrl:"https://youtu.be/4tknKfIy3fg",videoId:'4tknKfIy3fg'
      }
  
    
  ],
  }        
  ],
carouselVideos:[      {
  videoType:'youtube',
  title:"Ag Pro - Ag Pro Careers",
  website:"www.agprocompanies.com",
  link:"https://youtu.be/9giKKWu46TI",
  videoId:'521691260'
},
{
  videoType:'youtube',
  title:"Alabama Cooperative Extension System - Guide to Careers in Agriculture",
  website:"aces.edu",
  link:"https://youtu.be/bEIYIGx61Pk",
  videoId:'bEIYIGx61Pk'
},
{
  videoType:'youtube',
  title:" Forestry Works - Workforce Development Overview",
  website:"https://www.forestryworks.com/careers",
  link:"https://youtu.be/W58WZJYmGH4",
  videoId:'W58WZJYmGH4'
},
{
  videoType:'youtube',
  title:"",
  website:"",
  link:"",
  videoId:''
},
{
  videoType:'youtube',
  title:"",
  website:"",
  link:"",
  videoId:''
},
{
  videoType:'youtube',
  title:"",
  website:"",
  link:"",
  videoId:''
},
{
  videoType:'youtube',
  title:"",
  website:"",
  link:"",
  videoId:''
},]
},
    public_service:{
      empForBox:[
      {
        employername:'ALEA',
        employersite:" https://www.alea.gov/",
        employerLogo:"alea-logo.jpg",
        employerp1:"The state of Alabama Law Enforcement Agency (ALEA) is committed to identifying and recruiting professional men and woman who are interested in joining our law enforcement family to protect and serve the public with honesty, integrity and humility. ALEA’s renowned law enforcement agency offers career specialization throughout the state to most notably include: Patrol, Criminal Investigation, Dignitary Protection (protecting the states top political leaders), EOD/SWAT, Aviation Unit Member, and Criminal Justice Training Instructor.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/Ec1qLxQ3TRw",videoId:'Ec1qLxQ3TRw'
        }],
      },
      {
        employername:'Phenix City',
        employersite:"https://phenixcityal.us/",
        employerLogo:"phenix-city.jpg",
        employerp1:"Phenix City Fire Rescue is citizen-oriented to protect life and property and provide emergency service to the community through: A highly trained professional work force, modern technology and equipment, fire inspection and prevention activities, aggressive fire and safety education program, all in an efficient and professional manner.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/Xlx__4WARSU",videoId:'Xlx__4WARSU'
        }],
      },    
    ],
    
    carouselVideos:[      {
      videoType:'youtube',
      title:"Ag Pro - Ag Pro Careers",
      website:"www.agprocompanies.com",
      link:"https://youtu.be/9giKKWu46TI",
      videoId:'521691260'
    },
    {
      videoType:'youtube',
      title:"Alabama Cooperative Extension System - Guide to Careers in Agriculture",
      website:"aces.edu",
      link:"https://youtu.be/bEIYIGx61Pk",
      videoId:'bEIYIGx61Pk'
    },
    {
      videoType:'youtube',
      title:" Forestry Works - Workforce Development Overview",
      website:"https://www.forestryworks.com/careers",
      link:"https://youtu.be/W58WZJYmGH4",
      videoId:'W58WZJYmGH4'
    },
    {
      videoType:'youtube',
      title:"",
      website:"",
      link:"",
      videoId:''
    },
    {
      videoType:'youtube',
      title:"",
      website:"",
      link:"",
      videoId:''
    },
    {
      videoType:'youtube',
      title:"",
      website:"",
      link:"",
      videoId:''
    },
    {
      videoType:'youtube',
      title:"",
      website:"",
      link:"",
      videoId:''
    },]
  },
    transportation_distribution_and_logistics:{
      empForBox:[
      {
        employername:'Larry Puckett Chevrolet',
        employersite:"https://www.larrypuckettchevrolet.com/careers/",
        employerLogo:"larry-puckett-logo.jpg",
        employerp1:"Larry Puckett Chevrolet features an on-site Express Oil Change and Service Center. Express Oil Change & Service Center is the leading automotive service center in the South, with more than 217 locations across eight states. The Express Oil Change process has been honed to give our customers a quick oil change and premium vehicle maintenance checks, all performed by friendly, high-quality service technicians.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'vimeo',videoUrl:"https://vimeo.com/455864854",videoId:'455864854'
        }],
      },
      {
        employername:'Four Star Freightliner',
        employersite:"https://www.fourstarfreightliner.com/",
        employerLogo:"four-star-logo.jpg",
        multilink:false,
        employerp1:"Four Star Freightliner grew from two former Dealerships located in central and southern Alabama. Jerry A. Kocan bought the assets and assumed the locations of the Freightliner Dealerships formerly known as Coblentz Truck Center (Montgomery, Alabama) and Freightliner Trucks of Dothan (Dothan, Alabama) from Daimler. Both Dealerships held a long history of success within their communities. These two locations were purchased in 1999 and brought together in January of 2000.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/nLEkMCDPYNs",videoId:'nLEkMCDPYNs'
        },
        {
          videoType:'youtube',link:"https://youtu.be/nPNH0DW85ko",videoId:'nPNH0DW85ko'
        }],
      },
      {
        employername:'Amazon',
        employersite:"https://www.aboutamazon.com/workplace/facilities",
        employerLogo:"mont-chamber.jpg",
        employerp1:"Amazon announced plans for a brand-new 650,000 square-foot IXD facility in Montgomery. Located between I-65 and Highway 31, north of Hyundai Boulevard, the new facility represents a $100M capital investment, and will provide 500 jobs to the River Region. This is the first Amazon facility of any type to be announced in the Montgomery metro area and is the only Amazon IXD facility in the state of Alabama. ",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',videoUrl:"https://youtu.be/UAKPoAn2cB0",videoId:'UAKPoAn2cB0'
        }],
      }, 
      {
        employername:'AL Trucking',
        employersite:"https://www.alabamatrucking.org/",
        employerLogo:"ata-logo.jpg",
        employerp1:"Trucking is the dominate transportation source in Alabama. No other mode delivers the goods as effectively and efficiently as trucking. We are the wheels of Alabama’s diverse and thriving economy. For 80 years, the Alabama Trucking Association has been there for trucking wherever the rubber meets the road, and we are still finding ways to keep progress on schedule ",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{}],
      },  
    ],    carouselVideos:[      {
      videoType:'youtube',
      title:"Ag Pro - Ag Pro Careers",
      website:"www.agprocompanies.com",
      link:"https://youtu.be/9giKKWu46TI",
      videoId:'521691260'
    },
    {
      videoType:'youtube',
      title:"Alabama Cooperative Extension System - Guide to Careers in Agriculture",
      website:"aces.edu",
      link:"https://youtu.be/bEIYIGx61Pk",
      videoId:'bEIYIGx61Pk'
    },
    {
      videoType:'youtube',
      title:" Forestry Works - Workforce Development Overview",
      website:"https://www.forestryworks.com/careers",
      link:"https://youtu.be/W58WZJYmGH4",
      videoId:'W58WZJYmGH4'
    },
    {
      videoType:'youtube',
      title:"",
      website:"",
      link:"",
      videoId:''
    },
    {
      videoType:'youtube',
      title:"",
      website:"",
      link:"",
      videoId:''
    },
    {
      videoType:'youtube',
      title:"",
      website:"",
      link:"",
      videoId:''
    },
    {
      videoType:'youtube',
      title:"",
      website:"",
      link:"",
      videoId:''
    },]
  },
  communication_and_information_technology:{
    empForBox:[      {
      employername:'Fearless',
      employersite:"https://fearless.tech/careers",
      employerLogo:"fearless-logo.jpg",
      employerp1:"Hi, we're Fearless. We're a full stack digital services firm from Baltimore with a location in Montgomery. We deliver sleek, modern, and user-friendly software designed to push the boundaries of possibility. It's our mission to build software with a soul — tools that empower communities and make a difference — so we can create a world where good software powers the things that matter.",
      employerp2:"",
      employerp3:"",
      images:[],
      videos:[{
        videoType:'youtube',videoUrl:"https://youtu.be/wayD8jcr08U",videoId:'wayD8jcr08U'
      },{
        videoType:'youtube',videoUrl:"https://youtu.be/wayD8jcr08U",videoId:'wySfl5AXTG4'
      }],
    },
    {
      employername:'Xi Repair',
      employersite:"https://www.xirepair.com/",
      employerLogo:"xirepair-logo.jpg",
      employerp1:"XiRepair is the fastest growing consumer electronics repair, sales and data recovery companies in the Alabama. With more locations opening across the state in coming months. XiRepair aims to give customers access to same day affordable cell phone repairs, smart phone data recovery services and even access to new devices at great prices!",
      employerp2:"",
      employerp3:"",
      images:[],
      videos:[{
        videoType:'youtube',videoUrl:"https://youtu.be/abqWCCBiB_I",videoId:'abqWCCBiB_I'
      }],
    },
    {
      employername:'Skylight Digital',
      employersite:"https://skylight.digital/",
      employerLogo:"skylight-logo.jpg",
      employerp1:"Skylight was launched in June of 2017. We’re currently made up of 57 people and one ferret. A lot of us originally worked for civic-oriented groups such as 18F, the U.S. Digital Service, and the Presidential Innovation Fellows. Now, as part of Skylight, we’re working hard to cultivate an incredible team and culture.",
      employerp2:"What brings us together is a passion for creating better outcomes for the public through great government digital services. We’re basically impact junkies. But we’re also engineers, developers, designers, product managers, consultants, writers, speakers, inventors, roboticists, gamers, authors, veterans, entrepreneurs, parents, and more. Such a diverse team always gives us a unique perspective on things.",
      employerp3:"Our official headquarters is in sunny Sarasota, Florida, but we’re a distributed and remote-friendly team currently spread across 24 different states. Sometimes the nature of our client work calls for closer proximity to their location. So far, we’ve worked with clients located in places like Washington DC, Hartford (Connecticut), Montgomery (Alabama), Baltimore (Maryland), and Ottawa (Canada).",
      images:[],
      videos:[{
        videoType:'youtube',videoUrl:"https://youtu.be/w5Tdmp_Ywp8",videoId:'w5Tdmp_Ywp8'
      }],
    },
    {
      employername:'United States Air Force',
      employersite:"https://www.goang.com/?utm_source=Google&utm_medium=PaidSearch&utm_campaign=ANG&utm_wing=AL187&gclid=CjwKCAiAhreNBhAYEiwAFGGKPCZRBEG3BpJL3jWu_Iq-fnR7ZTtMtyAB8qv1iSVdaju7rWA-2ypLghoCl9UQAvD_BwE&gclsrc=aw.ds",
      employerLogo:"airforce-logo.jpg",
      employerp1:"Since the early days of the 20th Century, Air Guardsmen have been taking action to protect our nation and assist our citizens in times of need.",
      employerp2:"",
      employerp3:"",
      images:[],
      videos:[{
        videoType:'youtube',videoUrl:"https://youtu.be/RPsV1tnFpQc",videoId:'RPsV1tnFpQc'
      }],
    },   ],
    carouselVideos:[      ]
    },
    hospitality_and_tourism:{
      empForBox:[
      {
        employername:'Ascent',
        employersite:"https://ascent-hospitality.com/career",
        employerLogo:"ascent-logo.jpg",
        employerp1:"Ascent Hospitality is a vertically integrated hotel development and management company formed in early 2000. Our company's foundation is built on partnership, the spirit of teamwork, as evident from the single vision that has been nurtured and executed for over 15 years by the two principals with every distinct personalities. From our inception, we have adhered to one simple principle: 'Always deliver what is promised, and always execute what is committed.' Our current portfolio consist of multiple Selected Services, and Full Service Hotels comprising of over 3,000 luxurious rooms in major leisure, business, and convention markets.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/BTIs5rj40YA",videoId:'BTIs5rj40YA'
        },
        {
          videoType:'youtube',link:"https://youtu.be/u83ecGXCykk",videoId:'u83ecGXCykk'
        },
        {
          videoType:'youtube',link:"https://youtu.be/1bmMwwCjXtg",videoId:'1bmMwwCjXtg'
        },
        {
          videoType:'youtube',link:"https://youtu.be/r_VPsyxYz2k",videoId:'r_VPsyxYz2k'
        },
        ],
      },
      {
        employername:'Cities of Auburn and Opelika',
        employersite:"https://careerpath.chooserestaurants.org/",
        multilink:false,
        employerLogo:"auburn-tourism.jpg",
        employerp1:"Auburn-Opelika Tourism is a non-profit service organization driving economic impact and improving the quality of life in our community through tourism and travel. Auburn-Opelika is modern hospitality. Here, you'll find a boutique town that's budget-friendly, a backyard full of Alabama pines, trails, and waterways to explore, year-round gentle seasons, legendary restaurants, a hub for engineering, a vibrant and youthful community, and venues that are extensions of our forward-thinking mentality and rooted traditions.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/Zw8XmsMlEKc",videoId:'Zw8XmsMlEKc'
        },
        {
          videoType:'youtube',link:"https://youtu.be/P1sAIdF9mPw",videoId:'P1sAIdF9mPw'
        },
        {
          videoType:'youtube',link:"https://youtu.be/qdrOvWp-LlU",videoId:'qdrOvWp-LlU'
        },
        {
          videoType:'youtube',link:"https://youtu.be/5OJvOaO5ddk",videoId:'5OJvOaO5ddk'
        },
        {
          videoType:'youtube',link:"https://youtu.be/byV3vi8IE10",videoId:'byV3vi8IE10'
        },
        {
          videoType:'youtube',link:"https://youtu.be/s89-geLg-3I",videoId:'s89-geLg-3I'
        },
        {
          videoType:'youtube',link:"https://youtu.be/PF0gb3eny70",videoId:'PF0gb3eny70'
        },
        {
          videoType:'youtube',link:"https://youtu.be/u0d__zzILxg",videoId:'u0d__zzILxg'
        },
        {
          videoType:'youtube',link:"https://youtu.be/UURLW94R_Ic",videoId:'UURLW94R_Ic'
        },
        
        ],
      },   
    ],    carouselVideos:[      {
      videoType:'youtube',
      title:"Ag Pro - Ag Pro Careers",
      website:"www.agprocompanies.com",
      link:"https://youtu.be/9giKKWu46TI",
      videoId:'521691260'
    },
    {
      videoType:'youtube',
      title:"Alabama Cooperative Extension System - Guide to Careers in Agriculture",
      website:"aces.edu",
      link:"https://youtu.be/bEIYIGx61Pk",
      videoId:'bEIYIGx61Pk'
    },
    {
      videoType:'youtube',
      title:" Forestry Works - Workforce Development Overview",
      website:"forestry.alabama.gov",
      link:"https://youtu.be/W58WZJYmGH4",
      videoId:'W58WZJYmGH4'
    },
]
  },
    energy_and_utilities:{empForBox:[
      {
        employername:'Alabama Power Company',
        employersite:"https://www.alabamapower.com/our-company.html",
        employerLogo:"alabama-power-logo.jpg",
        employerp1:"Alabama is a beautiful place with extraordinary natural resources. Protecting those resources, while providing reliable, affordable electricity for our customers is at the heart of our company’s mission and why we work hard to provide value to the communities we serve.  Alabama Power is one of the most respected companies in Alabama. We value our employees because we know they are our most valuable asset. We realize that every job and every employee who performs it is vital to the overall success of our company. There are lots of great reasons to join our team.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'vimeo',videoUrl:"https://vimeo.com/fusionpoint/review/503239101/196b93c109",videoId:'503239101'
        },
        {
          videoType:'vimeo',videoUrl:"https://vimeo.com/fusionpoint/review/506304004/f10a52dcfa",videoId:'506304004'
        },
        {
          videoType:'vimeo',videoUrl:"https://vimeo.com/fusionpoint/review/503240238/50782111aa",videoId:'503240238'
        },
        {
          videoType:'vimeo',videoUrl:"https://vimeo.com/fusionpoint/review/503239295/c67a8fb082",videoId:'503239295'
        },
      ],
      },
      {
        employername:'Central Alabama Electric Coop',
        employersite:"https://caec.coop/",
        employerLogo:"central-electric.jpg",
        employerp1:"Central Alabama Electric Cooperative is a not for profit, member-owned electric distribution utility serving more than 45,000 meters in a 10-county area of central Alabama just north of Montgomery. The cooperative’s service area covers most of the rural areas of Autauga, Elmore, Coosa and Chilton counties as well as members in Tallapoosa, Talladega, Bibb, Perry, Dallas and Lowndes counties. The cooperative is a $334 million company with 6,000 miles of electric distribution line and 2,000 miles of fiber line serviced by over 135 employees.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/p4VbdxxPZ30",videoId:'p4VbdxxPZ30'
        },
        {
          videoType:'youtube',link:"https://youtu.be/vB49jRU09Kg",videoId:'vB49jRU09Kg'
        }
      ],
      },
      {
        employername:'Dixie Electric Cooperative',
        employersite:"https://www.dixie.coop/",
        employerLogo:"dixie-coop.jpg",
        employerp1:"Dixie Electric Cooperative is one of the fastest growing electric cooperatives in Alabama. As a member-owned electric utility, we are owned by those we serve. Unlike an investor-owned utility that is in business to make a profit for investors and shareholders, your cooperative is a non-profit organization.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/TyWokaks8iw",videoId:'TyWokaks8iw'
        },
        {
          videoType:'youtube',link:"https://youtu.be/Yjdjb4ZjU8E&t",videoId:'Yjdjb4ZjU8E&t'
        }],
      },    
    ],    carouselVideos:[
      {
        videoType:'youtube',
        title:"Ag Pro - Ag Pro Careers",
        website:"www.agprocompanies.com",
        link:"https://youtu.be/9giKKWu46TI",
        videoId:'521691260'
      },
      {
        videoType:'youtube',
        title:"Alabama Cooperative Extension System - Guide to Careers in Agriculture",
        website:"aces.edu",
        link:"https://youtu.be/bEIYIGx61Pk",
        videoId:'bEIYIGx61Pk'
      },
      {
        videoType:'youtube',
        title:" Forestry Works - Workforce Development Overview",
        website:"forestry.alabama.gov",
        link:"https://youtu.be/W58WZJYmGH4",
        videoId:'W58WZJYmGH4'
      },
      {
        videoType:'youtube',
        title:"",
        website:"",
        link:"",
        videoId:''
      },
      {
        videoType:'youtube',
        title:"",
        website:"",
        link:"",
        videoId:''
      },
      {
        videoType:'youtube',
        title:"",
        website:"",
        link:"",
        videoId:''
      },
      {
        videoType:'youtube',
        title:"",
        website:"",
        link:"",
        videoId:''
      },
    ]
  },
    education_and_training:{
      empForBox:[
        {
          employername:'Teach for America',
          employersite:"https://www.teachforamerica.org/profile/create?utm_source=google&utm_medium=cpc&utm_content=mktg_recruitment_fy21&gclid=CjwKCAiAhreNBhAYEiwAFGGKPCMWjKiZ8_hJiCFR3swsq809I6LuBuwaBfghs-kVAHYiCU1vEZyaphoCSKQQAvD_BwE",
          employerLogo:"teach-america-logo.jpg",
          employerp1:"Teach For America is a diverse network of leaders working to confront educational inequity through teaching and at every sector of society to create a country free from this injustice. Together, we are driving impact across the country to achieve our vision that one day all children in this nation will have the opportunity to attain an excellent education.",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[{
            videoType:'youtube',link:"https://youtu.be/IUSpl1Za1QM",videoId:'IUSpl1Za1QM'
          },
          {
            videoType:'youtube',link:"https://youtu.be/MphgJNwULr0",videoId:'MphgJNwULr0'
          },
          {
            videoType:'youtube',link:"https://youtu.be/lXAz-BpDSLg",videoId:'lXAz-BpDSLg'
          }],
        },  
    ],

    carouselVideos:[
      {
        videoType:'youtube',
        title:"Ag Pro - Ag Pro Careers",
        website:"www.agprocompanies.com",
        link:"https://youtu.be/9giKKWu46TI",
        videoId:'521691260'
      },
      {
        videoType:'youtube',
        title:"Alabama Cooperative Extension System - Guide to Careers in Agriculture",
        website:"aces.edu",
        link:"https://youtu.be/bEIYIGx61Pk",
        videoId:'bEIYIGx61Pk'
      },
      {
        videoType:'youtube',
        title:" Forestry Works - Workforce Development Overview",
        website:"forestry.alabama.gov",
        link:"https://youtu.be/W58WZJYmGH4",
        videoId:'W58WZJYmGH4'
      },
      {
        videoType:'youtube',
        title:"",
        website:"",
        link:"",
        videoId:''
      },
      {
        videoType:'youtube',
        title:"",
        website:"",
        link:"",
        videoId:''
      },
      {
        videoType:'youtube',
        title:"",
        website:"",
        link:"",
        videoId:''
      },
      {
        videoType:'youtube',
        title:"",
        website:"",
        link:"",
        videoId:''
      },
    ]
  },
    architecture_construction_and_utilities:{
      empForBox:[
        {
          employername:'Dixie Electric Heating and Air',
          employersite:"https://calldixie.com/",
          employerLogo:"dixie-hvac.jpg",
          employerp1:"Dixie Electric, Plumbing & Air is Central Alabama’s trusted electrical, plumbing and air contractor. Since 1908, our family-owned business has earned a reputation for quality.",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[{
            videoType:'youtube',link:"https://youtu.be/Yjdjb4ZjU8E",videoId:'Yjdjb4ZjU8E'
          },
          {
            videoType:'youtube',link:"https://youtu.be/TyWokaks8iw",videoId:'TyWokaks8iw'
          },
        ],
        },
      {
        employername:'Windstream',
        employersite:"https://www.windstream.com/high-speed-internet?utm_campaign=google_brand_t1_g19_internet_ppc&utm_medium=sembran&utm_source=Google&utm_term=windstream%20internet&utm_content=etas&gclid=CjwKCAiAhreNBhAYEiwAFGGKPFsCGlWc1zRAh76JtbHK59mYv8DzNsP7QTO9gmQBSEjrJp3J74i0ZxoCUKUQAvD_BwE",
        employerLogo:"windstream-logo.jpg",
        employerp1:"Windstream Holdings, Inc., also doing business as Windstream Communications or Windstream, is a provider of voice and data network communications, and managed services, to businesses in the United States.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/x_jF90xE1bE",videoId:'x_jF90xE1bE'
        },
        
      ],
      },  
      {
        employername:'Alabama Power Company',
        employersite:"https://www.alabamapower.com/our-company.html",
        employerLogo:"alabama-power-logo.jpg",
        employerp1:"Alabama is a beautiful place with extraordinary natural resources. Protecting those resources, while providing reliable, affordable electricity for our customers is at the heart of our company’s mission and why we work hard to provide value to the communities we serve.  Alabama Power is one of the most respected companies in Alabama. We value our employees because we know they are our most valuable asset. We realize that every job and every employee who performs it is vital to the overall success of our company. There are lots of great reasons to join our team.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'vimeo',videoUrl:"https://vimeo.com/fusionpoint/review/503239101/196b93c109",videoId:'503239101'
        },
        {
          videoType:'vimeo',videoUrl:"https://vimeo.com/fusionpoint/review/506304004/f10a52dcfa",videoId:'506304004'
        },
        {
          videoType:'vimeo',videoUrl:"https://vimeo.com/fusionpoint/review/503240238/50782111aa",videoId:'503240238'
        },
        {
          videoType:'vimeo',videoUrl:"https://vimeo.com/fusionpoint/review/503239295/c67a8fb082",videoId:'503239295'
        },
      ],
      },
      {
        employername:'Central Alabama Electric Coop',
        employersite:"https://caec.coop/",
        employerLogo:"central-electric.jpg",
        employerp1:"Central Alabama Electric Cooperative, Inc., (CAEC) is a not-for profit, member-owned electric distribution utility with more than 40,000 meters, serving 100,000 consumers across 6,000 miles of line in 10 central Alabama counties, primarily north of Montgomery. Ranked in the top 25 percent of companies located in Alabama, CAEC is a $193 million company with annual sales of $89 million and employs more than of 120 people. As a Touchstone Energy® cooperative, CAEC is committed to providing reliable power at a competitive price while investing in our communities to improve our quality of life.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/p4VbdxxPZ30",videoId:'p4VbdxxPZ30'
        },
        {
          videoType:'youtube',link:"https://youtu.be/vB49jRU09Kg",videoId:'vB49jRU09Kg'
        }
      ],
      },
      {
        employername:'Dixie Electric Cooperative',
        employersite:"https://www.dixie.coop/",
        employerLogo:"dixie-coop.jpg",
        employerp1:"Dixie Electric Cooperative is one of the fastest growing electric cooperatives in Alabama. As a member-owned electric utility, we are owned by those we serve. Unlike an investor-owned utility that is in business to make a profit for investors and shareholders, your cooperative is a non-profit organization.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[],
      }
    ],    carouselVideos:[
      {
        videoType:'youtube',
        title:"Ag Pro - Ag Pro Careers",
        website:"www.agprocompanies.com",
        link:"https://youtu.be/9giKKWu46TI",
        videoId:'521691260'
      },
      {
        videoType:'youtube',
        title:"Alabama Cooperative Extension System - Guide to Careers in Agriculture",
        website:"aces.edu",
        link:"https://youtu.be/bEIYIGx61Pk",
        videoId:'bEIYIGx61Pk'
      },
      {
        videoType:'youtube',
        title:" Forestry Works - Workforce Development Overview",
        website:"forestry.alabama.gov",
        link:"https://youtu.be/W58WZJYmGH4",
        videoId:'W58WZJYmGH4'
      },
    /*  {
        videoType:'youtube',
        title:"",
        website:"",
        link:"",
        videoId:''
      },
     */
    ]
  },
    
  }
  
   /*********************** */
   var NCTEJW_Quiz_Object={ 
    aerospace_and_aviation:{
      empForBox:[
      {
        employername:'Airbus',
        employersite:"https://www.airbus.com",
        employerLogo:"airbus-thumb.png",
        employerp1:"At Airbus, we believe that it’s not just what we make, but how we make it that counts. We are convinced that our customers, investors and the communities we are working with not only ask us to create products, jobs and shareholder returns, but to promote responsible, sustainable and inclusive business practices and act with integrity.",
        employerp2:"As a proven leader in the global aerospace sector, Airbus designs, produces and delivers innovative solutions with the aim to create a better-connected, safer and more prosperous world.  These cutting-edge products and services – which span the commercial aircraft, helicopter, defense, security and space segments – benefit from our wide-ranging expertise and continued emphasis on innovation.",
        employerp3:"A commercial aircraft manufacturer, with Space and Defense as well as Helicopters Divisions, Airbus is the largest aeronautics and space company in Europe and a worldwide leader. Airbus has built on its strong European heritage to become truly international – with roughly 180 locations and 12,000 direct suppliers globally. The company has aircraft and helicopter final assembly lines across Asia, Europe and the Americas, and has achieved a more than sixfold order book increase since 2000."
            ,images:[
            ]
            ,videos:[
              {
                videoType:'vimeo',videoUrl:"https://vimeo.com/521691260",videoId:'521691260'
              },
              {
                videoType:'vimeo',videoUrl:"https://vimeo.com/521690403",videoId:'521690403'
              }
            ]
        },
        {
          employername:'Coastal Alabama Community College',
          employersite:"http://www.coastalalabama.edu/programs/alabama_aviation_center/",
          employerLogo:"cacc-logo.jpg",
          employerp1:"The Alabama Gulf Coast serves as home to many leaders in the Aviation industry, including Airbus Final Assembly Line (FAL), VT Mobile Aerospace Engineering, Continental Motors, Segers Aero Corporation, Carlisle, Certified Aviation Services, and UTC Aerospace System.  These industries need trained, reliable employees to keep their operations moving, and our programs are designed to prepare you for these career opportunities.",
          employerp2:"",
          employerp3:""
              ,images:[
              ]
              ,videos:[
                {
                  videoType:'youtube',videoUrl:"https://youtu.be/Fl1kxJPhHrI",videoId:'Fl1kxJPhHrI'
                }
              ]
          },
        {
      employername:'ST Engineering',
      employerLogo:"st-engineering-thumb.png",
        employersite:"https://www.stengg.com/en/singapore-technologies-engineering",
        employerp1:"We keep the world flying safely. We have over 45 years of experience in aviation, backed by a highly experienced team of engineers and technicians across facilities in Asia Pacific, the U.S. and Europe.",
        employerp2:"We have proven solutions for practically every stage of an aircraft life cycle, from design and engineering, original equipment manufacturing, nose-to-tail aftermarket and maintenance services as well as assets management and leasing. Through passenger-to-freighter conversion or refurbishment, we give ageing aircraft a new lease of life. ",
        employerp3:"We are the world’s largest airframe MRO solution provider with the capacity to work on up to 44 widebody, 26 narrow body and 24 general aviation aircraft at any one time. As an OEM, we specialize in engine nacelle and composite panels, and are the only company offering Airbus conversions using OEM data. Anticipating the rising demand for autonomous and artificial intelligence, we provide unmanned air systems as solutions for urban city application."
                        ,images:[
            ]
      ,videos:[
          
          {
          
          videoType:'vimeo',videoUrl:"https://vimeo.com/517249009",videoId:'517249009'
          }]},                 
    
  ],    carouselVideos:[
  ]

 } ,
    construction:{
      empForBox:[{
        employername:'McAbee Construction',
        employersite:"https://www.mcabeeconstruction.com/",
        employerLogo:"mcabee-logo.jpg",
        employerp1:"McAbee Construction, Inc. was founded in 1962 by Leroy McAbee Sr. and has set the standards for mechanical and industrial construction and fabrication of piping, vessels, modules, sheet metal and autoclaves. This provides our clients in the United States and around the world with the highest quality, timely execution and fair pricing."
        +"With a strong, focused management team, committed people, resources and facilities, McAbee can assure that each project is completed to the customer's satisfaction.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[ {
                videoType:'youtube',videoUrl:"https://vimeo.com/521691260",videoId:'nhOH4mza5Lw'
              },
              {
                videoType:'youtube',videoUrl:"https://vimeo.com/521690403",videoId:'61W0wtGsVl4'
              }],
        clickable:false
        },    
        {
          employername:'Performance Contractors, Inc',
          employersite:"https://www.performance-contractors.com/",
          employerLogo:"performance-logo.jpg",
          employerp1:"We began as a merit shop general industrial contractor back in 1979. Since then, we’ve grown to provide full-service construction through all phases of the industrial construction process—from site prep through start-up. Performance serves a wealth of industries – from chemical and power, to automotive and refinery – with construction, turnaround, and maintenance services that put safety and quality at the forefront of every project and decision. Everything that we do is based on our performance; it’s our namesake and our crowning jewel. We hang our hat on a good, safe day’s work and our ability to do the job to the best of our ability.",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[              
            {
            videoType:'youtube',
            videoUrl:"https://vimeo.com/521690403",
            videoId:'Oi5x3bc6LwM'
          }],
          clickable:false
        },  
        {
           employername:'Turner Industries Group',
           employersite:"https://www.turner-industries.com/",
           employerLogo:"turner-logo.jpg",
           employerp1:"Turner Industries employees build, maintain and service the nation’s heavy industrial sector helping make the life we live possible by supporting the companies that produce fuel, energy, and products essential to our daily existence. Since 1961, Turner has steadily worked to provide one solution for our clients success. To do this we find, develop and support the very best people for the jobs we perform throughout our four divisions: Construction; Maintenance and Turnarounds; Fabrication; and Equipment and Specialty Services.",
           employerp2:"",
           employerp3:"",
           images:[],
           videos:[
            {
              videoType:'youtube',
              videoUrl:"https://vimeo.com/521690403",
              videoId:'5UpIrbxsXzo'
            }
           ],
           clickable:false
        },
        ],
      carouselVideos:[]
     
    },
    energy:{
  
      empForBox:[  {
        employername:'Alabama Power',
        employersite:"https://www.alabamapower.com/our-company/careers.html",
        employerLogo:"alabama-power-logo.jpg",
        employerp1:"We offer competitive prices, reliable electricity supply, and stellar service to 1.4 million homes, businesses and industries in the southern two-thirds of Alabama. Alabama Power is a subsidiary of Southern Company, one of the nation’s largest producers of energy.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[
          {
          videoType:'youtube',
          videoUrl:"https://youtu.be/5cV0HqSQvYg",
          videoId:'5cV0HqSQvYg'
          },
          {
            videoType:'youtube',
            videoUrl:"https://youtu.be/kl1dN_Jttps",
            videoId:'kl1dN_Jttps'
            },
            {
              videoType:'youtube',
              videoUrl:"https://youtu.be/RiLj9zTGUo0",
              videoId:''
              },
              {
                videoType:'youtube',
                videoUrl:"https://youtu.be/94QD_CY8jOE",
                videoId:'94QD_CY8jOE'
                },
                {
                  videoType:'youtube',
                  videoUrl:"https://youtu.be/VZuVA1hAavY",
                  videoId:'VZuVA1hAavY'
                  },
                  {
                    videoType:'youtube',
                    videoUrl:"https://youtu.be/LZiVeexOCEY",
                    videoId:'LZiVeexOCEY'
                    },
     
        ],
        clickable:false
        },            
       {
         employername:'Daphne Utilities',
         employersite:"https://www.daphneutilities.com/",
         employerLogo:"daphne-logo.jpg",
         employerp1:"Daphne Utilities is committed to providing our customers with the highest-quality, uninterrupted utility services at affordable rates. Throughout the year, we strive to meet this commitment through regular maintenance, system upgrades and construction of new utility infrastructure.",
         employerp2:"",
         employerp3:"",
         images:[],
         videos:[{
          videoType:'youtube',
          videoUrl:"https://vimeo.com/521690403",
          videoId:'0O9u-tKeyNE'
        }],
         clickable:false
       },  
  
      ],
      carouselVideos:[]    
    },
    engineering_and_design:{
      empForBox:[  {
        employername:'Alabama Power',
        employersite:"https://www.alabamapower.com/our-company/careers.html",
        employerLogo:"alabama-power-logo.jpg",
        employerp1:"We offer competitive prices, reliable electricity supply, and stellar service to 1.4 million homes, businesses and industries in the southern two-thirds of Alabama. Alabama Power is a subsidiary of Southern Company, one of the nation’s largest producers of energy.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',
          videoUrl:"https://youtu.be/VZuVA1hAavY",
          videoId:'VZuVA1hAavY'
        }],
        clickable:false
        },
        {
          employername:'Hargrove Engineering',
          employersite:"https://www.hargrove-epc.com/join-our-team/",
          employerLogo:"hargrove-logo.jpg",
          employerp1:"A 100% Employee Owned Company Giving 110%. Design, build and maintain a safe, fully compliant facility with Hargrove. Our firm has innovated sound solutions for the process, manufacturing, and energy industries since 1995.",
          employerp2:"What we build best are relationships®. We serve clients in long-term support relationships in multiple modes of service: onsite support arrangements, plant-level small projects and consulting roles, and larger capital projects. Everything we do focuses on best practices to ensure safe, efficient and profitable operations with your company's best interest in mind.",
          employerp3:"",
          images:[],
          videos:[  {     videoType:'youtube',
          videoUrl:"https://vimeo.com/fusionpoint/review/503239295/c67a8fb082",
          videoId:'RYW9Kkd_atw'}],
          clickable:false
      }, ],
      carouselVideos:[]
         
    },
    healthcare:{ empForBox:[  {
      employername:'Mobile Public Schools',
      employersite:"https://www.mcpss.com/jobopportunities",
      employerLogo:"mcps-logo.jpg",
      employerp1:"Mobile County Public School System is a school district based in unincorporated Mobile County, Alabama, United States. The system currently serves areas of Mobile County, including the city of Mobile, with the exception of the cities of Saraland, Satsuma and Chickasaw.",
      employerp2:"",
      employerp3:"",
      images:[],
      videos:[{
        videoType:'youtube',
        videoUrl:"",
        videoId:'Cytkw4JWjNc'
      }],
      clickable:false
      }],
    carouselVideos:[]},
    information_technology:{
  
      empForBox:[ {
        employername:'Provalus',
        employersite:"https://provalus.com/apply/",
        employerLogo:"provalus-logo.jpg",
        employerp1:"Provalus is purpose-driven, elevating under-served communities by creating technology, business and support careers for undiscovered talent in the U.S.  Provalus offers Support, BPO and ITO services. We hire and develop the best and brightest untapped talent in our small towns and rural communities to deliver a remarkable experience for clients and end-users alike. By creating opportunities where there were none; with companies that believe in America’s future, Provalus is generating a dedicated superior workforce. We are fueling a new era of technology talent equipped to strengthen America’s future, PROVIDING OUTSOURCING VALUE FROM THE U.S",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',
          videoUrl:"https://youtu.be/MDkFlrg0QkY",
          videoId:'MDkFlrg0QkY'
        }],
        clickable:false
        }],
      carouselVideos:[]     
    },
    hospitality_and_tourism:{
      empForBox:[  {
        employername:'Spectrum Resorts',
        employersite:"https://spectrumresorts.com/about-spectrum/careers/",
        employerLogo:"spectrum-logo.jpg",
        employerp1:"Spectrum Resorts is the premier rental management company along the Alabama Gulf Coast, providing over 500 luxury rentals at both The Beach Club in Gulf Shores and Turquoise Place in Orange Beach Alabama. As the exclusive on-site rental provider at these two properties, Spectrum provides our guests with exclusive perks, special discounts and personalized service for their vacation experience.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',
          videoUrl:"https://youtu.be/O8mM2F9uSDY",
          videoId:'O8mM2F9uSDY'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/OSt4k676oXc",
          videoId:'OSt4k676oXc'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/KQIBtcpCJjo",
          videoId:'KQIBtcpCJjo'
        },],
        clickable:false
        }],
      carouselVideos:[]
         
    },
    
    manufacturing:{
      empForBox:[ {
        employername:'AM/NS Calvert',
        employersite:"https://najobs.arcelormittal.com/calvert/go/AMNS-Calvert-Careers/4276400/",
        employerLogo:"amns-logo.jpg",
        employerp1:"AM/NS Calvert is a 50/50 joint venture between ArcelorMittal and Nippon Steel Corp. (NSC). The steel processing plant is located in Calvert, Alabama, about 35 miles north of Mobile.  The plant has the capacity to produce 5.3 million tons of flat rolled carbon steel products annually. The facility serves the automotive, construction, pipe and tube, service center, and appliance/ HVAC industries with an impressive portfolio of steel grades for high-value applications including hot rolled bands, hot rolled pickled and oiled, cold rolled, and advanced coated products. ",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',
          videoUrl:"https://youtu.be/LQ_NqFbE5v4",
          videoId:'LQ_NqFbE5v4'
        },
        {
          videoType:'youtube',
          videoUrl:"https://youtu.be/aBBT29AyyXw",
          videoId:'aBBT29AyyXw'
        }],
        clickable:false
        },
        {
          employername:'BASF',
          employersite:"https://www.basf.com/global/en/careers/jobs.html#%7B%7D",
          employerLogo:"basf-logo.jpg",
          employerp1:"At BASF, we create chemistry for a sustainable future. We combine economic success with environmental protection and social responsibility. The approximately 122,000 employees in the BASF Group work on contributing to the success of our customers in nearly all sectors and almost every country in the world. Our portfolio is organized into six segments: Chemicals, Materials, Industrial Solutions, Surface Technologies, Nutrition & Care and Agricultural Solutions.",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[{
            videoType:'youtube',
            videoUrl:"https://youtu.be/E47GzGkzRQA",
            videoId:'E47GzGkzRQA'
          },
          {
            videoType:'youtube',
            videoUrl:"https://youtu.be/ALtIKm2cWE4",
            videoId:'ALtIKm2cWE4'
          },
          {
            videoType:'youtube',
            videoUrl:"https://youtu.be/uLMu2WbYwF4",
            videoId:'uLMu2WbYwF4'
          }],
          clickable:false
          },
          {
            employername:'Evonik',
            employersite:"https://careers.evonik.com/en/",
            employerLogo:"evonik-logo.jpg",
            employerp1:"Evonik is one of the world leaders in specialty chemicals. The company is active in more than 100 countries around the world. Evonik goes far beyond chemistry to create innovative, profitable and sustainable solutions for customers. More than 32,000 employees work together for a common purpose: We want to improve life, today and tomorrow.",
            employerp2:"",
            employerp3:"",
            images:[],
            videos:[{
              videoType:'youtube',
              videoUrl:"https://youtu.be/1nltc600Krw",
              videoId:'1nltc600Krw'
            }],
            clickable:false
            },
            {
              employername:'Westervelt',
              employersite:"https://www.westervelt.com/",
              employerLogo:"westervelt-logo.jpg",
              employerp1:"At The Westervelt Company, we are stewards of the land. We believe in sustainable management, conservation and protection of our natural resources. We are committed to our core values of ensuring the safety and well-being of our employees, providing the highest quality products and services for our customers, and developing long-lasting relationships in the communities where we live and work.",
              employerp2:"",
              employerp3:"",
              images:[],
              videos:[{
                videoType:'youtube',
                videoUrl:"https://youtu.be/9UW4MQn5z7E",
                videoId:'9UW4MQn5z7E'
              },{
                videoType:'youtube',
                videoUrl:"https://youtu.be/X_rsH-P9yNk",
                videoId:'X_rsH-P9yNk'
              }],
              clickable:false
              }
      ],
      carouselVideos:[]
         
    },
    public_service:{
      empForBox:[ {
        employername:'Mobile Fire and Rescue',
        employersite:"https://www.cityofmobile.org/fire/",
        employerLogo:"mobile-fire-logo.jpg",
        employerp1:"The City of Mobile is a vastly growing port city filled with business, tourism, tradition, and family, all worthy of being protected. Mobile Fire-Rescue Department (MFRD) is an internationally recognized, all-hazards fire department, capable of responding to and mitigating most any emergency situation. From hazardous materials to urban rescue, fire suppression, emergency medical services, and more, Mobile Fire-Rescue Department is committed to providing the best services to the citizens, families, and friends of Mobile, Alabama.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',
          videoUrl:"https://www.youtube.com/watch?v=zX-VW94eIZQ",
          videoId:'zX-VW94eIZQ'
        }],
        clickable:false
        }],
      carouselVideos:[]
         
    },
    safety:{
      empForBox:[],
      carouselVideos:[]
         
    },
    shipbuilding_and_repair:{
  
      empForBox:[ {
        employername:'The Cooper Group of Companies',
        employersite:"https://www.coopertsmith.com/",
        employerLogo:"cooper-logo.jpg",
        employerp1:"The Cooper Group of Companies has no greater asset than its people. Our employees come to us from all over the southeast, and they are what makes our company an industry leader. Whether you currently have a successful job in the maritime or forestry industry and are looking for a proven and established company to take your career to the next level, or you're new to the industry and want to be trained by the finest crews on the most state-of-the-art equipment in the industry, the Cooper Group is the company for you.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',
          videoUrl:"https://youtu.be/ywJrZmCquOI",
          videoId:'ywJrZmCquOI'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/J2Z_Qa1pdqI",
          videoId:'J2Z_Qa1pdqI'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/RHeRNqXNExg",
          videoId:'RHeRNqXNExg'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/Itjqq_KY8fU",
          videoId:'Itjqq_KY8fU'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/5GeOw30KSQI",
          videoId:'5GeOw30KSQI'
        }],
        clickable:false
        },{
          employername:'Austal',
          employersite:"https://www.austaljobs.com/",
          employerLogo:"austal-logo.jpg",
          employerp1:"Austal USA is an advanced ship manufacturer, servicer and sustainment provider. Headquartered in Mobile, Alabama with facilities in San Diego, Singapore and Washington, D.C., the company employs approximately 4,000 people and designs and constructs two ship programs for the U.S. Navy – Independence-variant Littoral Combat Ship (even numbered hulls) and Expeditionary Fast Transport (EPF). The company’s supplier network includes over 1155 companies across 44 states supporting more than 34,000 U.S. jobs. Austal USA entered the defense market in 2004 and through its innovative ship manufacturing processes, quickly became the fifth largest shipbuilder in North America. ",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[{
            videoType:'youtube',
            videoUrl:"https://www.youtube.com/watch?v=Fn60BrAPfDM",
            videoId:'Fn60BrAPfDM'
          },{
            videoType:'youtube',
            videoUrl:"https://www.youtube.com/watch?v=vboOPJoe4FE",
            videoId:'vboOPJoe4FE'
          },
          {
            videoType:'youtube',
            videoUrl:"https://www.youtube.com/watch?v=_UQHSmG10O0",
            videoId:'_UQHSmG10O0'
          },],
          clickable:false
          },{
            employername:'Ingalls',
            employersite:"https://ingalls.huntingtoningalls.com/careers/",
            employerLogo:"ingalls-logo.jpg",
            game:"https://romantic-einstein-a95603.netlify.app/ingallstrivia",
            employerp1:"Ingalls Shipbuilding is located in Pascagoula, Mississippi on 800 acres of the most important real estate in America. With 11,500 employees, Ingalls is the largest manufacturing employer in Mississippi and a major contributor to the economic growth of both Mississippi and Alabama. Our 82-year legacy has continuously proven we have the talent, experience and facilities to simultaneously build more classes of ships than any other shipyard in America.",
            employerp2:"",
            employerp3:"",
            images:[],
            videos:[{
              videoType:'youtube',
              videoUrl:"https://www.youtube.com/watch?v=Ddjk7uQblUE",
              videoId:'Ddjk7uQblUE'
            },
            {
              videoType:'youtube',
              videoUrl:"https://www.youtube.com/watch?v=fYBvaGFlY_k",
              videoId:'fYBvaGFlY_k'
            },
            {
              videoType:'youtube',
              videoUrl:"https://www.youtube.com/watch?v=yG9ai_CAI-c",
              videoId:'yG9ai_CAI-c'
            },],
            clickable:false
            }],
      carouselVideos:[]  
    },
    transportation_distribution_and_logistics:{
      empForBox:[ {
        employername:'The Cooper Group of Companies',
        employersite:"https://www.coopertsmith.com/",
        employerLogo:"cooper-logo.jpg",
        employerp1:"The Cooper Group of Companies has no greater asset than its people. Our employees come to us from all over the southeast, and they are what makes our company an industry leader. Whether you currently have a successful job in the maritime or forestry industry and are looking for a proven and established company to take your career to the next level, or you're new to the industry and want to be trained by the finest crews on the most state-of-the-art equipment in the industry, the Cooper Group is the company for you.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',
          videoUrl:"https://youtu.be/ywJrZmCquOI",
          videoId:'ywJrZmCquOI'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/34-TV4unHJw",
          videoId:'34-TV4unHJw'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/yKAQw9yyAEI",
          videoId:'yKAQw9yyAEI'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/J2Z_Qa1pdqI",
          videoId:'J2Z_Qa1pdqI'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/RHeRNqXNExg",
          videoId:'RHeRNqXNExg'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/Itjqq_KY8fU",
          videoId:'Itjqq_KY8fU'
        },{
          videoType:'youtube',
          videoUrl:"https://youtu.be/5GeOw30KSQI",
          videoId:'5GeOw30KSQI'
        }],
        clickable:false
        },
        {
          employername:'Alabama Trucking Association',
          employersite:"https://www.alabamatrucking.org/about/fast-facts-and-stats/",
          employerLogo:"alabama-trucking.jpg",
          employerp1:"The Alabama Trucking Association represents state trucking operations since 1938 for the promotion of fair and reasonable regulation of the industry.",
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[{
            videoType:'youtube',
            videoUrl:"https://www.youtube.com/watch?v=nVro8UeI480",
            videoId:'nVro8UeI480'
          },],
          clickable:false
          }],
      carouselVideos:[]
         
    },
  
   
  }
  
  
  
  
  
  