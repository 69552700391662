<template>
  <div  id="app">
    <div id="wrongSize"><img :src="require(`@/assets/tilt-message.jpg`)"></div>
    <Header2 v-if="['home', 'researchLab','formhome','resourceLibrary'].indexOf($route.name) > -1" />
    <vue-page-transition name="overlay-left-right">
    <router-view/>
    </vue-page-transition>
    <Robot v-if="(['home',].indexOf($route.name) > -1) && (this.robotDisplay)" :currentRegion="envRegion" />
  <!--  <audio ref="audioAmbient" src="@/assets/sound/Ambient.mp3"></audio>-->
  </div>
</template>
 
<script>
import { AmbientEventBus } from './eventbuses/ambientEventBus';

import Header2 from './components/Header2'
import Robot from './components/robot'
import { mapGetters, /*mapActions*/ } from "vuex";


export default {
  
  data() {
    return {
      kaas: true,
      interruptAmbient:false,
      robotDisplay:true,
      envRegion: process.env.VUE_APP_CURRENT_REGION
    };
  },
    created() {
     // AmbientEventBus.$on('videoplay', () =>
      
     //   this.pauseAmbient()
  //    );
  //    AmbientEventBus.$on('videoend', () =>
      
  ////      this.restartAmbient()
   //   );
         /*const bodyScrollLock = require('body-scroll-lock');
// eslint-disable-next-line no-unused-vars
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
// eslint-disable-next-line no-unused-vars
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
const targetElement = document.querySelector('#app');
disableBodyScroll(targetElement);
console.log(targetElement)
      // when the app is created run the set user method
      // this uses Vuex to check if a user is signed in
      // check out mutations in the store.js file
    //  this.setUser();*/
    },
  mounted() {
 
    //this.authAction();
      /*//console.warn(this.envRegion);
      console.log(process.env.BASE_URL);
        //console.warn("PLAY");*/
  },
  computed: {
    ...mapGetters(["getUser", "isUserAuth"])
  },
  methods: {
        setUser() {
      //this.$store.dispatch("setUser");
    },
          eventFired(text) {
        console.log(text)
      },
  //  ...mapActions(["authAction"]),
    /*playAmbient() {
      var a = this.$refs.audioAmbient;
      if (a.paused && (this.interruptAmbient ==false)) {
        a.loop=true;
        a.play();
      } 
    },*/
    showRobot(bool){
          this.robotDisplay=bool;
    },
   /*
      pauseAmbient() {
      var a = this.$refs.audioAmbient;
      a.pause();
      this.interruptAmbient=true;

      a.currentTime = 0;
  
    },      
      restartAmbient() {
      this.interruptAmbient = false;
       this.playAmbient();
    },  
    */
  },
  components:{
    Header2,
    Robot
  }
  
};
const clickHandler = function(clickCount) {
  console.log(`The button has been clicked ${clickCount} times!`)
}
AmbientEventBus.$on('videoplay', clickHandler);

</script>




<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');
html, body{
  overflow: hidden !important;
}
#wrongSize{
    display: none;
    background-color: #1A2B57;
  }
  .logout-btn {
    position: fixed;
    top: 0;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;
    font-size: 1.5vw;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    margin: 1vh 0vw;
    color: #de1a21 !important;
    right: 1vw;
    z-index: 999999999;
    background-color: lightgrey;
    padding: 1vh 1vw;
    border-radius: 1vw;
    box-shadow: 2px 2px 4px #B8B9BE, -2px -2px 4px #fff;
}
  #tabletIn .col-6{
    border-radius: 17px;
  }
#app {
    overflow: hidden !important;
    height: 100vh;
}
body { 
  font-family: 'Open Sans', Helvetica, Arial, sans-serif !important;
}
img {
    max-width: 100%;
}
#lottieWorldAnim svg {
    width: 100vw;
    height: auto !important;
}
.col-form-label{
  font-size:16px !important;
}
.d-block.valid-feedback span {
  font-size: 18px;
  color: green;
}
.text-danger {
    color: #ff1818 !important;
}
.hello h1 {
    position: absolute;
    top: -1000px;
}
h1, h2, h3, h4, h5, h6, .title, .sub-title {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  font-weight: 600 !important;
  color:#14424d;
}
h4{
  color:white;
}
 .fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to {
  opacity: 0
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #505050;
}
.l-play, .l-pause {
    position: absolute;
    top:0px;
    z-index:999;
}
.l-play{
    right: 30px;
}
.l-pause {
    right: 0;
}
.progress {
    height: 3vh !important;
    border: .0625rem solid #d1d9e6;
    margin-bottom: 1rem;
    overflow: hidden;
    font-size: .75rem;
    font-weight: 600;
    box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff;
}
.progress-bar.progress-bar-striped.progress-bar-animated {
    /* padding: 2vh; */
    background-color: #31a1c1;
  }
button.next-button {
    position: absolute;
    right: 1vw;
    bottom: 11vh;
    border: 0px;
    background-color: rgba(0,0,0,0);
}
.show-button .b-icon{
box-shadow: none !important;
}
.sub-back {
    position: fixed !important;
    top: 12vh !important;
}
.sub-back .b-icon{
box-shadow: none !important;
}
.back-button{
    box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
    border-radius: 150px;
    width: 70px;
    height: 70px;
    position: absolute;
    color: #de1a21 !important;
    z-index: 9;
    top: 6vh;
    left: 4vw;
    border: 0px;
}
.show-button{
  position: absolute;
    top: 6vh;
    left: 4vw;
    transition: all 2s ease-in;
}
.hid-button{
  position: absolute;
    top: 15vh;
    left: 4vw;
    transition: all 2s ease-in;
    z-index: 2;
}
#nav {
    z-index: 999999999;
    position: absolute;
    width: 44vw;
    height: 26vh;
    bottom: -5vh;
    left: 28vw;
    background-image: url('./assets/nav-menu.png');
    background-position: 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;
    display:flex;
    align-items:center;
    justify-content:center;
}
#nav a {
    border-radius: 12px;
    background: #e5e5e5;
    box-shadow: 6px 6px 12px #B8B9BE, -6px -6px 12px #fff;
    width: 6vw;
    height: 10vh;
    line-height: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1vw;
    color: #de1a21 !important;
    font-size: .75vw;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    text-align: center;
    font-weight: bolder;
    text-transform: uppercase;
    }
#nav a:active {
  background: #e5e5e5;
  border-radius: 12px;
  box-shadow: inset 2px 2px 5px #B8B9BE, inset -3px -3px 7px #fff;
}
#nav a:hover {
  text-decoration: none;
  color: #ef2b99;
}
#tabletIn .form-group .btn {
    font-size: 1vw;
    font-weight: bold;
    color: #de1a21;
    background: #e5e5e5;
  border-radius: 12px;
  margin-right: 1vw;
  box-shadow: 6px 6px 12px #B8B9BE, -6px -6px 12px #fff;
  border: 0px solid white;
}
#tabletIn .form-group .active {
    box-shadow: inset 2px 2px 5px #B8B9BE, inset -3px -3px 7px #fff !important;
}
#nav a.router-link-exact-active {
  background: #e5e5e5;
  border-radius: 12px;
  box-shadow: inset 2px 2px 5px #B8B9BE, inset -3px -3px 7px #fff;
}
.nav-link {
    padding-bottom: 0.75rem !important;
    background: #e5e5e5;
    font-weight: 600 !important;
  border-radius: 12px;
  color: #2c3e50;
  box-shadow: 6px 6px 12px #B8B9BE, -6px -6px 12px #fff;
  margin: 0vh 1vw 1vh 0vw;
}
.nav-link .b-icon.bi{
  vertical-align: middle !important;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    box-shadow: inset 2px 2px 5px #B8B9BE, inset -3px -3px 7px #fff;
    background-color: #e5e5e5 !important;
    color: #0e2332 !important;
}
.toasted {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;
  font-weight: bold !important;
  background-color: white;
}
.b-toaster-slot {
    z-index: 9999999999;
    font-weight: bold;
}
a{
  text-transform: uppercase;
}
#blinkLight{
  animation: animLight 3s linear infinite;
}
@keyframes animLight {
  0% { opacity: 1; }
  10% { opacity: 1; }
  15% { opacity: 0; }
  100% { opacity: 0; }
}
.activeDepressed {
background: #e5e5e5;
    border-radius: 12px;
    box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff;
}
#opacity-fill{
  opacity: .90;
}
#backTablet{
  width: 100vw;
  position: absolute;
  z-index: 2;
}
#tabletIn{
    position: absolute;
    z-index: 2;
    width: 80vw;
    height: 53vh;
    overflow: scroll;
    overflow-x: hidden;
    top: 19vh;
    left: 10vw;
}
.show-box{
  position: absolute;
  top: 6vh;
  left: 0vw;
  transition: all 2s ease-in;
  background-color: #f1f1f1 !important;
}
.hid-box{
  position: absolute;
  top: 100vh;
  left: 0vw;
  transition:all 1s ease-out;
  
}
.circle {
    width: 5vw;
    height: 10vh;
    margin: 0px;
    font-size: 11px;
}
.b-icon.bi {
    border-radius: 150px;
    box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff;
    background-color: transparent;
}
.res-tabs .tab-pane {
  padding:3vh 1vw;
}
.res-tabs .nav-link{
  background-color: rgba(0,0,0,0) !important;
  color: white !important;
  font-size: 0.8vw;
  
}
.res-tabs {
        height: 35vh;
    overflow: scroll;
}
.res-tabs h4{
    color: white !important;
}
.res-tabs .nav-pills .nav-link.active {
   box-shadow: 6px 6px 12px #B8B9BE, -6px -6px 12px #fff;
   background-color: #007bff !important;
}
#manufacturing_auto_logistics .waw .right-vid-button {
    right: -8vw !important;
}
#communication_and_information_technology .caw .employer-header {
  display: none;
}
.scroll-ind {
  display: none !important;
}
.row{
  margin-right: 0px !important;
  margin-left:0px !important;
}
.tabs .activityGroup{
    background-color: #de1a21 !important;
}
.tabs .partnerGroup{
  background-color: #062830 !important;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  #nav {
    width: 53vw;
    height: 22vh;
    bottom: -5vh;
    left: 22.5vw;
    }
    #nav div{
      width: 9vw !important;
      font-size: 1vw !
    }
    #nav a{
      width: 9vw !important;
    }
    .b-toaster.b-toaster-top-right, .b-toaster.b-toaster-top-left, .b-toaster.b-toaster-top-center, .b-toaster.b-toaster-top-full {
    top: 0;
    right: 15vw !important;
    }
    .tutorial-text {
    top: 11vh;
    height:10vh;
    }
    .sponsorLogos{
    width: 77.7vw !important;
    height: 10vh !important;
    top: 8.7vh !important;
    left: 10.6vw !important;
    }
    .mid-mission-control h2{
      top: 19.5vh !important;
    }
    .left-mission, .right-mission {
      top: 18vh !important;
    }
    .mission-left, .mission-right, .mission-center{
      top: 26vh !important;
      height: 43vh !important;
    }
    .next-tut-button {
    height: 29.5vh!important;
    width: 57vw!important;
    }
    .b-toaster-top-right {
      top: 0;
    }
    .mid-mission-control .mission-center {
    left: 36vw;
    height: 31vh !important;
    }
    #tabletIn {
    position: absolute;
    z-index: 2;
    width: 80vw;
    height: 40vh;
    overflow: scroll;
    overflow-x: hidden;
    top: 14vh;
    left: 10vw;
    }
    #Foreground {
    left: -27vw !important;
    bottom: -25vh !important;
    width: 154vw !important;
    }
    #Logo_Carousel{
    left: -1.37vw !important;
    top: -38vh !important;
    width: 101.6vw !important;
    }
    #shipTube{
      width:60% !important;
      left: 19.6vw !important;
    }
    #lottieWorldAnim svg {
    /* background-color: white; */
    width: 135vw !important;
    height: auto !important;
    }
  #backTablet {
    height: auto !important;
    width: 100vw !important;
    left: 0vw;
}
    .show-box {
    position: absolute;
    top: 10vh;
    left: 0vw;
    transition: all 1s ease-in;
}
.neu-button{
    border: 0px solid white;
    width: 27vw;
    height: 7vh;
}
#lottie-robot {
    position: absolute;
    z-index: 999;
    bottom: -8vh !important;
    height: 50vh;
    left: 1vw !important;
}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
#wrongSize{
    position: absolute !important;
    display: inline !important;
    z-index: 9999999999;
    width: 100vw;
    height: 100vh;
    top:0vh;
    left: 0vw;
    background-color: #1A2B57 !important;
  }
    #wrongSize img {
    margin: auto;
    text-align: center;
    display: block;
}
  h1{
    font-size: 46px;
    color: white;
  }
}
@media only screen and (max-width: 960px){
  #nav {
    z-index: 999999999;
    position: absolute;
    width: 100vw;
    height: 39vh;
    bottom: 0vh;
    left: 0;
    background-image: url(/img/nav-menu.1060dcba.png);
    background-position: 0;
    background-size: 98% 100%;
}
  .sponsorLogos {
    position: absolute;
    width: 98.3vw !important;
    height: 35vh;
    top: 2.3vh !important;
    left: 1.69vw !important;
    z-index: 6;
    text-align: center;
}
.sponsorLogos img {
    width: 22vw !important;
    border-radius: 2vw;
}
#Logo_Carousel {
    position: absolute;
    left: -17vw;
    top: -50vh !important;
    z-index: 6;
    width: 134vw;
}
  #nav a {
    border-radius: 12px;
    background: #e5e5e5;
    box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
    width: 21vw;
    height: 17vh;
    line-height: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1vw;
    color: #de1a21 !important;
    font-size: 2.75vw;
}
#Foreground {
    position: absolute;
    left: 0;
    bottom: 0vh !important;
    z-index: 5;
}
  #lottieWorldAnim svg {
    width: 269vw !important;
    height: auto !important;
    margin-left: -160vw;
}
h1.top-covering-title {
    height: 20vh !important;
}
#tabletIn {
    background-color: white;
    width: 100vw !important;
    left: 0 !important;
    height: 80vh !important;
    top: -10vh !important;
    padding: 8vh 2vw !important;
}
.show-button {
    top: -10vh !important;
    left: 0vw !important;
}
#tabletIn .col-6 {
    border-radius: 17px;
    width: 100vw !important;
    max-width: 100vw !important;
    flex: 100% !important;
}
#tabletIn p {
    font-size: 5vw !important;
    margin: 1vh 0;
}
.neu-button{
    width: 88vw !important;
    height: 9vh !important;
    }
/*
  #wrongSize{
    position: absolute !important;
    z-index: 9999999999999;
    width: 100vw;
    height: 100vh;
    top:0vh;
    left: 0vw;
    background-color: black;
  }
  h1{
    font-size: 46px;
    color: white;
  }
  */
}
@media (max-width: 600px) {
  #tabletIn {
    padding: 11vh 2vw !important;
}
.videoToggle {
    width: 29vw;
    padding: 0!important;
    display: inline-block;
    position: static !important;
    left: 6vw;
}
  .careers-left{
    padding-bottom: 200px;
  }
  .educationTab .nav-tabs {
    margin-top: -2vh !important;
    position: static !important;
    border: 0px solid white;
}
.educationTab .card {
    height: auto !important;
}
.educationTab .educ-tabs {
    width: 100% !important;
    max-width: 100% !important;
    margin-top: 2vh !important;
    height: auto;
}
  .caw .scrollLogos img, .waw .scrollLogos img, .naw .scrollLogos img {
    width: 33vw !important;
    float: left;
    margin-right: 2vw;
    margin-bottom: 2vh;
}
.scrollLogos.col-6 {
    height: auto !important;
  }
  .top-assess.col-6 {
    position: absolute;
    top: 96vh;
    padding-bottom: 200px;
}
.naw .scrollLogos .logoCont {
    width: 42vw !important;
    display: inline-block;
    margin: 0 1vw 0.5vh;
    height: 19vh !important;
    margin-top: 3vh;
}
.naw #tabletIn #tabletIn .tabs {
    width: 100vw !important;
    display: inline-block;
    margin-top: 10px;
    position: static !important;
    left: 0vw !important;
}
#tabletIn .logoSec {
    width: 100vw;
    display: inline-block;
    margin-top: 60px;
    position: static;
    left: 2vw;
}
.neu-body h2{
  position: static !important;
}
  #home .swiper-container {
    width: 30vw!important;
    height: 7vh!important;
}
  
  .show-button {
    top: -6vh !important;
    left: 0vw !important;
    }
  .job-content {
    height: auto !important;
    }
    #app {
    overflow: hidden !important;
    height: 100vh;
      }
    .neu-body {
      height: auto !important;
    }
  .twinkling, .clouds, .stars{
    display: none !important;
  }
  .sidebar .hid-box {
    top: 112vh !important;
    }
  .show-box {
    left: 0vw !important;
    }
    #tabletIn {
    top: -6vh !important;
    height: 86vh !important;
    margin-bottom: 100px !important;
    }
  .res-tabs .nav-link {
    font-size: 2.8vw  !important;
}
  .overlay-left, .overlay-right{
    background-image: none !important;
  }
  .mid-mission-control h2 {
    top: 8vh !important;
    color: white !important;
    font-size: 3vw !important;
    width: 80vw !important;
    left: 10vw !important;
}
#nav div{
  box-shadow: none !important;
}
#nav a {
    height: 10vh !important;
    box-shadow: none !important;
}
#Top {
    display: none !important;
}
.mission-left li p, .mission-right li p {
    font-size: 12px !important;
    margin-bottom: 1vh !important;
}
  .left-mission{
    left: 6.7vw !important;
    top: 8vh !important;
  }
  .right-mission{
    left: 84.7vw !important;
    top: 8vh !important;
}
.mission-left{
    left: 0 !important;
    width: 100vw !important;
    height: auto !important;
    margin-top: 15vh !important;
    position: static !important;
}
.mission-center{
    left: 0 !important;
    width: 100vw !important;
    height: auto !important;
    position: static !important;
    margin-top: 0vh !important;
}
.res-tabs {
        top: 5vh !important;
    height: 100% !important;
    width: 100vw !important;
    left: 0 !important;
}
.res-tabs .col-6{
  max-width: 100% !important;
  width: 100% !important;
  flex: auto !important;
}
.res-tabs .neu-button {
    width: 76vw !important;
    height: 9vh !important;
}
.res-tabs .videoToggle iframe {
    width: 100% !important;
    height: 38vh;
}
.mission-right{
    left: 0 !important;
    width: 100vw !important;
    height: auto !important;
     position: static !important;
    margin-top: 2vh !important;
}
.mission-center h4 {
    font-size: 12px !important;
}
#lottie-desks {
    display: none !important;
}
.mid-mission-control {
    background-color: #505050;
    height: auto !important;
    overflow: scroll !important;
    position: static !important;
    margin-bottom: 200px !important;
}
#missionLog{
  display: none !important;
}
  .educationTab .neu-button{
    max-width: 100% !important;
  }
  .video-slide .videoToggle{
    width: 70vw !important;
    left: 15vw !important;
    }
    .videoToggle{
    width: 100% !important;
    left: 0vw !important;
    }
   .right-vid-button, .left-vid-button{
    width: 12vw !important;
    }
    .mission-center-bottom {
    display: none !important;
    }
  .sawdc .neu-body h2 {
    display: none !important;
}
#tabletIn .form-group .btn {
    font-size: 5vw !important;
    }
  .sidebar #nav {
    width: 100vw !important;
    height: 14vh !important;
    bottom: 0vh !important;
    left: 0vw !important;
}
.sawdc .scrollLogos .logoCont{
    width: 40vw !important;
}
.formQ {
    margin-left: 2vw !important;
}
.educationTab .nav-tabs .nav-link{
  margin-bottom: 19px !important;
}
  #researchBackground {
    display: none !important;
}
  .circle {
    display: none !important;
  }
  #shipTube {
    height: 35vh !important;
    }
  .educ-tabs{
    width: 100% !important;
    max-width: 100% !important;
    margin-top: 25vh !important;
  }
  body{
    overflow: hidden  !important;
  }
  .job-buttons{
    width: 100% !important;
  }
  .job-title {
        font-size: 18px !important;
    top: 0vh !important;
    width: 80vw !important;
    left: 2vw !important;
    position: relative !important;
}
#tabletIn .col-6 {
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius: 0;
    box-shadow: none;
}
.rightJobSpace.col-6 {
    margin-top: 15px !important;
}
.rightJobSpace .col-6 .b-overlay-wrap{
    height: auto !important;
    }
    .card {
    height: 23vh !important;
}
  .sub-back {
    position: fixed !important;
    top: 0vh !important;
    left: 0vw !important;
    z-index: 9999;
  }
  .valid-feedback, .assessment .d-block.valid-feedback span, .valid-feedback b {
    font-size: 26px !important;
    line-height: 21px !important;
  }
  
  .l-play, .l-pause{
    display: none !important;
  }
  .show-box #tabletIn p{
    font-size:16px !important;
  }
  #nav a {
    height: 10vh !important;
  }
  h1.top-covering-title {
    height: 20vh !important;
    font-size: 1.6em !important;
}
  .hello .row .col #tabBG {
    position: absolute;
    left: -169vw !important;
    top: -8vh !important;
    width: 411vw !important;
    height: 88vh !important;
    z-index: 2 !important;
}
.tabContentWhite h2 {
    position: absolute;
    width: 100vw !important;
    left: -11vw !important;
    top: -9vh !important;
    font-size: 6vw !important;
    line-height: 23px !important;
}
  .tabContentWhite {
    left: -49vw !important;
    position: absolute !important;
    height: 39vh !important;
    width: 78vw !important;
    top: 14vh;
}
.research-lab-nav {
    top: 29vh;
}
   .sponsorLogos {
    z-index: 9999999 !important;
}
#nav{
  height:20vh !important;
}
#Foreground, #Mouse_Droid{
  display: none !important;
}
#Background {
    position: absolute !important;
    bottom: -14vh !important;
    z-index: 4;
}
#nav{
  background-image: none !important;
  background-color: #2c3e50 !important;
}
}
/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 
#wrongSize{
    position: absolute !important;
    display: inline !important;
    z-index: 9999999999;
    width: 100vw;
    height: 100vh;
    top:0vh;
    left: 0vw;
    background-color: #1A2B57;
  }
    #wrongSize img {
    margin: auto;
    text-align: center;
    display: block;
}
  h1{
    font-size: 46px;
    color: white;
  }
}
/* Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 
#wrongSize{
    position: absolute !important;
    display: inline !important;
    z-index: 9999999999;
    width: 100vw;
    height: 100vh;
    top:0vh;
    left: 0vw;
    background-color: #1A2B57;
  }
    #wrongSize img {
    margin: auto;
    text-align: center;
    display: block;
}
  h1{
    font-size: 46px;
    color: white;
  }
}
/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 
#wrongSize{
    position: absolute !important;
    display: inline !important;
    z-index: 9999999999;
    width: 100vw;
    height: 100vh;
    top:0vh;
    left: 0vw;
    background-color: #1A2B57;
  }
    #wrongSize img {
    margin: auto;
    text-align: center;
    display: block;
}
  h1{
    font-size: 46px;
    color: white;
  }
}
@media only screen 
  and (min-device-width: 1366px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .tutorial-text {
    width: 27vw !important;
    height: 11vh !important;
    top: 11vh !important;
    }
}
</style>
