
import { getField, updateField } from 'vuex-map-fields';
// eslint-disable-next-line no-unused-vars



import { collectionRef } from '../../../_services/firebase/db'
class Question{
  constructor(questionText,questionIndex,questionOptions) {
    this.questionText = questionText
    this.questionindex = questionIndex;
    this.questionAnswer = "";
    this.questionOptions=questionOptions;
    this.questionSelect=null;

  }
}
class Form{
    constructor(q1,q2,q3,q4) {
      this.formCompleted=false;
      this.question1 = q1;
      this.question2 = q2;
      this.question3 = q3;
      this.question4 = q4;
    }
  }


const state = {
  caw:{      
    agriculture: {
    formstate:new Form(
    new Question("What was one career that you learned about that is appealing to you?",1,["Agricultural Equipment Operators","Environmental Engineers","Biological Technicians"]),
    new Question("Which employer do you want to learn more about?",2,["Natural Resource Conservation Service","None"]),
    new Question("Are you interested in pursuing a career in the Agriculture world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
    new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
    )

  },
  architecture_construction_and_utilities: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["Welders",
      "Heating/Air/ Refrigeration Mechanics",
      "Plumbers/Pipefitters/Steamfitters",
      ]),
      new Question("Which employer do you want to learn more about?",2,["Dixie Electric Heating and Air",
      "Rabren","None"]),
      new Question("Are you interested in pursuing a career in the Architecture & Construction world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
  education_and_training:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["Elementary School Teachers",
      "Secondary School Teachers",
      "Guidance Counselor"]),
      new Question("Which employer do you want to learn more about?",2,["None"]),
      new Question("Are you interested in pursuing a career in the Education and Training world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )
  },

  energy_and_utilities:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["Line Workers",
      "Distribution Specialist/Engineer",
      "Water Resource Specialist",]),
      new Question("Which employer do you want to learn more about?",2,["Alabama Power Company","Central Alabama Electric","Dixie Electric Co-op", "None"]),
      new Question("Are you interested in pursuing a career in the Energy and Utilities world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )
  },

  communication_and_information_technology:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["Computer User Support Specialists",
      "Network & Computer System Administrators",
      "Software Developers",]),
      new Question("Which employer do you want to learn more about?",2,["None"]),
      new Question("Are you interested in pursuing a career in the Communication and Information Technology world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )
  },

  healthcare:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["Registered Nurse",
      "Pharmacy Technician",
      "Respiratory Therapist"]),
      new Question("Which employer do you want to learn more about?",2,["EAMC","Vaughan Regional"]),
      new Question("Are you interested in pursuing a career in the Health Science world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )
  },
  hospitality_and_tourism:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["Food Service Managers",
      "Chefs/Head Cooks",
      "Lodging Managers"]),
      new Question("Which employer do you want to learn more about?",2,["Ascent","None"]
      ),
      new Question("Are you interested in pursuing a career in the Hospitality and Tourism world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )
  },
  manufacturing: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["Team Assemblers & Fabricators",
      "Maintenance and Repair Workers",
      "Supervisors of Production"]),
      new Question("Which employer do you want to learn more about?",2,["GKN",
      "To Your Health Sprouted Flour",
      "Fras-le",
      "Golden State Foods",
      "Hyundai",
      "Neptune Technology Group, Inc.",
      "SiO2",
      "Rexnord",
      "None"]),
      new Question("Are you interested in pursuing a career in the Manufacturing world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
  public_service: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["Patrol Officers",
      "Firefighters",
      "Emergency Medical Technicians/Paramedics"]),
      new Question("Which employer do you want to learn more about?",2,["ALEA","Phenix City","None"]),
      new Question("Are you interested in pursuing a career in the Public Service world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )
  },
  transportation_distribution_and_logistics: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["Stock & Material Movers",
      "Truck Drivers",
      "Mechanics/Diesel Engine Specialists",]),
      new Question("Which employer do you want to learn more about?",2,["Larry Puckett Chevrolet","None"]
      ),
      new Question("Are you interested in pursuing a career in the Transportation, Distribution and Logistics world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
},

waw:{
agriculture:{
  formstate:new Form(
  new Question("Switch to Mixin ",1,["Stock & Material Movers",
  "Truck Drivers",
  "Mechanics/Diesel Engine Specialists",]),
  new Question("switch to mixin q2 ",2,["Larry Puckett Chevrolet","None"]
  ),
  new Question("switch to mixin q3?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
  new Question("switch to mixin q4?",4,[])
  )},
cosmetology:{
  formstate:new Form(
    new Question("Switch to Mixin ",1,["Stock & Material Movers",
    "Truck Drivers",
    "Mechanics/Diesel Engine Specialists",]),
    new Question("switch to mixin q2 ",2,["Larry Puckett Chevrolet","None"]
    ),
    new Question("switch to mixin q3?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
    new Question("switch to mixin q4?",4,[])
    )
},
construction:{
  formstate:new Form(
    new Question("Switch to Mixin ",1,["Stock & Material Movers",
    "Truck Drivers",
    "Mechanics/Diesel Engine Specialists",]),
    new Question("switch to mixin q2 ",2,["Larry Puckett Chevrolet","None"]
    ),
    new Question("switch to mixin q3?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
    new Question("switch to mixin q4?",4,[])
    )
},
education:{
  formstate:new Form(
    new Question("Switch to Mixin ",1,["Stock & Material Movers",
    "Truck Drivers",
    "Mechanics/Diesel Engine Specialists",]),
    new Question("switch to mixin q2 ",2,["Larry Puckett Chevrolet","None"]
    ),
    new Question("switch to mixin q3?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
    new Question("switch to mixin q4?",4,[])
    )
},
energy_and_utilities:{
  formstate:new Form(
    new Question("Switch to Mixin ",1,["Stock & Material Movers",
    "Truck Drivers",
    "Mechanics/Diesel Engine Specialists",]),
    new Question("switch to mixin q2 ",2,["Larry Puckett Chevrolet","None"]
    ),
    new Question("switch to mixin q3?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
    new Question("switch to mixin q4?",4,[])
    )
},
engineering_and_environment:{
  formstate:new Form(
    new Question("Switch to Mixin ",1,["Stock & Material Movers",
    "Truck Drivers",
    "Mechanics/Diesel Engine Specialists",]),
    new Question("switch to mixin q2 ",2,["Larry Puckett Chevrolet","None"]
    ),
    new Question("switch to mixin q3?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
    new Question("switch to mixin q4?",4,[])
    )
},
healthcare:{
  formstate:new Form(
    new Question("Switch to Mixin ",1,["Stock & Material Movers",
    "Truck Drivers",
    "Mechanics/Diesel Engine Specialists",]),
    new Question("switch to mixin q2 ",2,["Larry Puckett Chevrolet","None"]
    ),
    new Question("switch to mixin q3?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
    new Question("switch to mixin q4?",4,[])
    )
},
hospitality_and_tourism:{
  formstate:new Form(
    new Question("Switch to Mixin ",1,["Stock & Material Movers",
    "Truck Drivers",
    "Mechanics/Diesel Engine Specialists",]),
    new Question("switch to mixin q2 ",2,["Larry Puckett Chevrolet","None"]
    ),
    new Question("switch to mixin q3?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
    new Question("switch to mixin q4?",4,[])
    )
},
manufacturing_auto_logistics:{
  formstate:new Form(
    new Question("Switch to Mixin ",1,["Stock & Material Movers",
    "Truck Drivers",
    "Mechanics/Diesel Engine Specialists",]),
    new Question("switch to mixin q2 ",2,["Larry Puckett Chevrolet","None"]
    ),
    new Question("switch to mixin q3?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
    new Question("switch to mixin q4?",4,[])
    )
},
public_safety:{
  formstate:new Form(
    new Question("Switch to Mixin ",1,["Stock & Material Movers",
    "Truck Drivers",
    "Mechanics/Diesel Engine Specialists",]),
    new Question("switch to mixin q2 ",2,["Larry Puckett Chevrolet","None"]
    ),
    new Question("switch to mixin q3?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
    new Question("switch to mixin q4?",4,[])
    )
},
transportation:{
  formstate:new Form(
    new Question("Switch to Mixin ",1,["Stock & Material Movers",
    "Truck Drivers",
    "Mechanics/Diesel Engine Specialists",]),
    new Question("switch to mixin q2 ",2,["Larry Puckett Chevrolet","None"]
    ),
    new Question("switch to mixin q3?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
    new Question("switch to mixin q4?",4,[])
    )
},

},
eaw:{
  agriculture_and_natural_resources: {
    formstate:new Form(
    new Question("What was one career that you learned about that is appealing to you?",1,["Agronomist",
    "Environmental Engineer",
    "Forester",
    "Logging Equipment Operator",
    "Poultry Service Technician",
    "Wildlife Biologist"]),
    new Question("Which employer was most appealing to you or that you want to learn more about?",2,[
      "Ag-Pro",
      "Alabama Cooperative Extension Service",
      "Alabama Forestry Commission",
      "Alabama Green Industry",
      "Alabama Poultry and Egg Association",
      "Alfa Farmers",
      "Calhoun County Conservation District",
      "Choccolocco Creek Watershed",
      "USDA",
      "ForestryWorks",
      "None"
      ]),
    new Question("Are you interested in pursuing a career in the Agriculture world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
    new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
    )

  },
  automotive_and_manufacturing:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["Cabinetmakers",
      "CNC Operator",
      "Industrial Machinery Mechanic",
      "Precision Machinist",
      "Quality Control Specialist",
      "Welding",
      ]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,[
        "Anniston Army Depot",
        "Bridgewater Interiors",
        "Honda Manufacturing of Alabama",
        "Kronospan",
        "KTH",
        "M&H Valve",
        "New Flyer of America",
        "AIDT",
        "Southwire",
        "Steel Fab",
        "Tape Craft",
        "Tyler Union",
        "Wellborn Cabinet",
        
        ]),
      new Question("Are you interested in pursuing a career in the Automotive & Manufacturing world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )
   },
   communications_media_and_marketing: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,[
        "Advertising Director",
        "Graphic Designer",
        "Market Research Analyst",
        "Public Relations Specialist",
        "Marketing Manager",
        "Art Director",
        ]
        ),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,[
        "Appalachian Digital Media",
        "City of Oxford",
        "Gadsden City Schools",
        "Oxford Performing Arts Center",
        "Oxford Public Library",
        "Spectrum Reach",
        "The Chamber, Gadsden and Etowah County",
        "Venture Marketing Group",
        
        ]),
      new Question("Are you interested in pursuing a career in the  Communications, Media and Marketing world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
  construction:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,[
        "Carpenters",
        "Construction and Building Inspectors",
        "Construction Management",
        "Electrician",
        "HVACR",
        "Ironworker"
        
        ]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,[
        "Alabama Construction Recruitment Institute",
        "Anniston Water Works & Sewer Board",
        "City of Oxford Building Department",
        "Hale Building Materials",
        "Hurst Construction",
        "Industrial Rental",
        "Iron Workers Local 92",
        "IRONWORKER SKILLS INSTITUTE",
        "Littleton Electric Service",
        "Ra-Lin and Associates",
        "Southland Machinery",
        "Thompson CAT",
        "Webb Concrete & Building Materials",
        
        ]),
      new Question("Are you interested in pursuing a career in the Construction world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )
  },
  energy_and_utilities:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,[
        "Earth Driller",
        "Electrical Power Line Installers",
        "Nuclear Engineer",
        "Environmental Engineer Technician",
        "Geoscientist",
        "Water Plant Operator",
        
        ]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,[
        "Alabama Power Company",
        "Sparklight",
        "Spire",
        
        ]),
      new Question("Are you interested in pursuing a career in the Energy & Utilities world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )
  },      
  engineering:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,[
        "Engineering",
        "Biosystems Engineers",
        "Chemical Engineers",
        "Civil Engineers",
        "Electrical Engineers",
        "Industrial Engineers",
        "Mechanical Engineers",
        
        ]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,[
        "Alabama Technology Network",
        "Eastman",
        "Elbit Systems of America",
        "Jacksonville State University",
        "Transglobal",
        
        ]),
      new Question("Are you interested in pursuing a career in the Engineering world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )
  },
  healthcare:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,[
        "Dental Hygienist",
        "Diagnostic Sonography",
        "Nursing Assistant",
        "Phlebotomy",
        "Physical Therapy Assistant",
        "Registered Nurse",
        
        
        
        ]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,[
        "Alabama Department of Rehabilitation Services",
        "Coosa Valley Medical Center",
        "Designer Smiles",
        "Gadsden Regional Medical Center",
        "Jacksonville Health and Rehabilitation",
        "Regional Medical Center",
        
        ]),
      new Question("Are you interested in pursuing a career in the Healthcare world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )
  },

  information_technology:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,[
"Computer User Support Specialist",
"Database Administrator",
"Information Security Analyst",
"IT Project Manager",
"Software Programmer",
"Web Developer",

]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["None"]),
      new Question("Are you interested in pursuing a career in the Information Technology world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )
  },
  public_safety:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,[
        "Criminal Investigator",
        "Dispatchers",
        "Emergency Management Directors",
        "Emergency Medical Technician",
        "Firefighters",
        "Patrol / Police Officer",
        
        
        
        ]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,[
        "Alabama National Guard",
        "Anniston Fire Department",
        "Calhoun County Emergency Management Agency",
        "Calhoun County Sheriff’s Office",
        "Oxford Fire Department",
        "Oxford Police Department",
        "Oxford Health Care Authority",
        "Jacksonville Fire Department",
        "911 Calhoun County",
        
        ]),
      new Question("Are you interested in pursuing a career in the Public Safety  world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )
  },

  transportation_distribution_and_logistics:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,[
        "Automotive Service Technicians",
        "CDL Drivers",
        "Diesel Mechanic",
        "Dispatchers",
        "Logistics Engineers",
        "Warehousing",
        
        ]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,[
        "B.R. Williams",
        "City of Oxford",
        "Gadsden State Community College",
        "New Flyer of America",
        "Wellborn Cabinet",
        
        ]),
      new Question("Are you interested in pursuing a career in the Transportation, Distribution and Logistics world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )
  },
},
naw:{
  agriculture_food_and_natural_resources:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
aerospace:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
architecture_and_construction:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
business_and_finance:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
communications_media_and_marketing:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
cybersecurity_and_information_technology:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
education_and_training:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
energy_and_utilities:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
healthcare:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
hospitality_and_tourism:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
law_public_safety_and_corrections:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
modern_manufacturing_auto:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
modern_manufacturing:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
transportation_distribution_and_logistics:{
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  }
},
nctejw:{
  aerospace_and_aviation: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
  construction: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },

  energy: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
  engineering_and_design: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
  healthcare: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
 hospitality_and_tourism: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
  information_technology: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
 manufacturing: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
 public_service: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
  safety: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
  shipbuilding_and_repair: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Shipbuilding & Repair world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
  transportation_distribution_and_logistics: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Supply Chain world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )
  },
},
sawdc:{
  aerospace_and_aviation: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
  construction: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },

  energy: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
  engineering_and_design: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
  healthcare: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
 hospitality_and_tourism: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
  information_technology: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
 manufacturing: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
 public_service: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
  safety: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Aerospace & Aviation world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
  shipbuilding_and_repair: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Shipbuilding & Repair world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )

  },
  transportation_distribution_and_logistics: {
    formstate:new Form(
      new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
      new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
      new Question("Are you interested in pursuing a career in the Supply Chain world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
      new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
      )
  },
},

      education:{
        formstate:new Form(
          new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
          new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
          new Question("Are you interested in pursuing a career in the Education world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
          new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
          )
      },

      manufacturing_auto_logistics:{
        formstate:new Form(
          new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
          new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
          new Question("Are you interested in pursuing a career in the Manufacturing, Auto, Logistics world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
          new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
          )
      },




      shipbuilding_and_repair: {
        formstate:new Form(
          new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
          new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
          new Question("Are you interested in pursuing a career in the Shipbuilding & Repair world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
          new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
          )

      },
      transportation_distribution_and_logistics: {
        formstate:new Form(
          new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
          new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
          new Question("Are you interested in pursuing a career in the Supply Chain world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
          new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
          )
      },
      safety: {
        formstate:new Form(
          new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
          new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
          new Question("Are you interested in pursuing a career in the Safety world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
          new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
          )
      },
      engineering_and_design: {
        formstate:new Form(
          new Question("What was one career that you learned about that is appealing to you?",1,["1","2","3","4","5"]),
          new Question("Which employer was most appealing to you or that you want to learn more about?",2,["1","2","3","4","5"]),
          new Question("Are you interested in pursuing a career in the Engineering and Design world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
          new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
          )

      },
      healthcare: {
        formstate:new Form(
          new Question("What was one career that you learned about that is appealing to you?",1,["Registered Nurse",
            "Pharmacy Technician",
            "Respiratory Therapist"]),
          new Question("Which employer was most appealing to you or that you want to learn more about?",2,["EAMC","Vaughan Regional"]),
          new Question("Are you interested in pursuing a career in the Healthcare world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
          new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
          )

      },
      information_technology: {
        formstate:new Form(
          new Question("What was one career that you learned about that is appealing to you?",1,["Computer User Support Specialists",
          "Database/ Network/ Computer System Administrators",
          "Software Developers"]),
          new Question("Which employer was most appealing to you or that you want to learn more about?",2,["None"]),
          new Question("Are you interested in pursuing a career in the Information Technology world?",3,[{text: 'Yes', value: 'yes'} ,{text:"No",value:'no'}]),
          new Question("Is there anything else you want to tell us about your experience or about what you learned?",4,[])
          )

      },

  };
  const getters = {
    getField,

  };
  const mutations = {
    updateField,
    SET_QUESTION(state, payload) {
 
      state[payload.region][payload.formid].formstate[payload.questionid].questionAnswer = payload.value;
    },
    STATE_FORM(state, payload) {
////console.log(payload);
      state[payload.region][payload.formid].formstate.formCompleted=payload.value;

    },

  };
  const actions = {
    SUBMIT_FORM(state,payload) {
      console.log(payload.region);
      console.log(payload.formid);
       console.log(state.state)
      // eslint-disable-next-line no-unused-vars
      var currentWorld= state.state[payload.region][payload.formid]
      console.log([payload.region])

   

      const userRef = collectionRef('codeAssigments');
      console.log(userRef);
       // return addUserToUsersCollection(data, userRef)

      //this.commit()
            //state[payload.region][payload.formid].formstate.formCompleted=payload.value;
          /*AnswersEntryDataService.submitWorld(payload.region,currentWorld)
            .then(() => {
              console.log("Created new item successfully!");
         
    
            })
            .catch(e => {
                          this.$bvToast.toast(`${e}`, {
                            title: `Email Failed`,
                            autoHideDelay: 5000,
                            appendToast: true
                          })     
              console.log(e);
            });*/
          }
    }
    const assessment_form_store = {
        namespaced: true,
        actions,
        mutations,
        state,
        //modules,
        getters
      };
       
  export default assessment_form_store;
  