<template>
 <div>
  <header >
      <nav v-on:click="handleplay"  id="nav" class="flex items-center">     
        <router-link v-on:click="handleplay"  to="/">Bridge</router-link>
        <router-link v-on:click="handleplay"  to="/worlds">Research Lab</router-link>
        <router-link  v-on:click="handleplay" to="/formssummary">Mission Control</router-link>
        <router-link  v-on:click="handleplay" to="/resourceLibrary">
        <span v-if="region!='sawdc'">Resource Library</span>
        <span v-else-if="region=='sawdc'">Education &amp; Resources  </span>
        
        </router-link>
      </nav>
  </header>

  <!--<a class="logout-btn" @click="logoutUser()">LOGOUT</a>-->

  <audio ref="audioDoor" src="@/assets/sound/Servo_19.mp3"></audio>

 </div>
</template>

<script>

import firebase from "firebase";
import { mapActions } from 'vuex';
 export default {
  props: {
    menuTypeToggle: String,
    
  },
  data(){
    return{
               region:process.env.VUE_APP_CURRENT_REGION.toLowerCase(),
    }
  },
  methods: {
        ...mapActions('auth', ['logoutUser']),

    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("sign-in");
        });
    }, handleplay() {
       //    //console.warn("hndleplay");
         //   console.log(this)
     // this.$parent.pauseAmbient()
 var a = this.$refs.audioDoor;     
 //console.log(this.$parent);
           a.play()
 //    this.$parent.restartAmbient()

    },

  },
};
</script>