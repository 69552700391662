//copy this to create a new all world template.  database eventually.

var educationInfoMixin  = {
    methods: {
      selectEducationTabsInfo: function (regionName,worldName) {
        var worldsArrays={
            SAWDC:SAWDC_Tabs_Objects,
            CAW:CAW_Quiz_Object,
            WAW:WAW_Quiz_Object,
            NAW:NAW_Quiz_Object,
            EAW:EAW_Tabs_Object,
            NCTEJW:NCTEJW_Tabs_Object

  
          }
          return worldsArrays[regionName][worldName]
      }
    }
  };
  export default educationInfoMixin
  /*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var SAWDC_Tabs_Objects={ 
    aerospace_and_aviation:[
        {
        tabName:'Pathway',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
          [
            {
            cardTitle:"Pathways",
            cardLink:"",
            cardImage:"aerospace-pathway.jpg",
            cardText:"pathways"
            }
          ],
        ]
        }, 
        {
        tabName:'Programs',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                cardTitle:"Flight Works",
                cardLink:"https://www.flightworksalabama.com/education",
                cardImage:"fwa-logo.jpg",
                cardText:"Spearheaded by Airbus and the State of Alabama, Flight Works Alabama is a comprehensive aerospace exhibition and education center in Mobile. Flight Works offers camps, workshops, and programs to help you get your start in the aerospace and aviation industry. "
                }],[{
                cardTitle:"National Flight Academy",
                cardLink:"https://www.nationalflightacademy.com/",
                cardImage:"nfa-logo.jpg",
                cardText:"Experience one of the most exciting and immersive learning adventures in the world aboard the world’s largest simulated aircraft carrier. An experience for rising 7-12th graders that inspires interest in Science, Technology, Engineering and Mathematics (STEM)",
                }
            ],
        ]
        },
        {
        tabName:'Coastal Alabama Aviation College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
          [
            {
              cardTitle:"Coastal Alabama Aviation College",
              cardImage:"cacc-logo.jpg",
              cardText:"The Alabama Gulf Coast serves as home to many leaders in the Aviation industry, including Airbus Final Assembly Line (FAL), VT Mobile Aerospace Engineering, Continental Motors, Segers Aero Corporation, Carlisle, Certified Aviation Services, and UTC Aerospace System.  These industries need trained, reliable employees to keep their operations moving, and our programs are designed to prepare you for these career opportunities.",  
              cardLink:"http://www.coastalalabama.edu/programs/alabama_aviation_center/"
            }
           ]
        ]
        },
        {
        tabName:'Career Resources',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
              [
                { 
                cardTitle:"ACRI/Go Build",
                cardImage:"go-build.jpg",
                cardText:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades.",  
                cardLink:"https://gobuildalabama.com/ "
                }
              ]        
            ]
        },            
  ]
    ,
    construction:[
        {
        tabName:'Pathway',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
          [
            {
            cardTitle:"Pathways",
            cardLink:"",
            cardImage:"construction-pathway.jpg",
            cardText:"pathways"
            }
          ],
        ]
        }, 
        {
        tabName:'Bishop State College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                cardTitle:"A/C Refrigeration Program",
                cardLink:"https://www.bishop.edu/programs/career-technical-education/a-c-refrigeration-technology",
                cardImage:"bishop-college-logo.jpg",
                cardText:"The Air Conditioning & Refrigeration Technology program prepares graduates for entry-level positions in the construction, installation, operation, and maintenance of climate control equipment as they learn basic principles and technical skills for the industry."
                }],
                [{
                cardTitle:"Electrical Technology Program",
                cardLink:"https://www.bishop.edu/programs/career-technical-education/electrical-technology",
                cardImage:"bishop-college-logo.jpg",
                cardText:"The Electrical Technology program is designed to provide the student with an opportunity to acquire the skills, knowledge, and understanding needed to enter the occupation as an entry-level electrician.",
                }],
                [{
                    cardTitle:"Welding Program",
                    cardLink:" https://www.bishop.edu/programs/career-technical-education/welding-technology",
                    cardImage:"bishop-college-logo.jpg",
                    cardText:"Welding is a skill used by many trades: sheet metal workers, iron workers, diesel mechanics, boilermakers, carpenters, marine construction, steamfitters, glaziers, repair and maintenance personnel in applications ranging from the sculpture home hobbyist to heavy fabrication of bridges, ships and many other projects.",
                    }
            ]]},
            {
                tabName:'Reid State College',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[

                    [{
                        cardTitle:"Industrial Electricity Program",
                        cardLink:"https://www.rstc.edu/industryelectric",
                        cardImage:"reid-college-logo.jpg",
                        cardText:"The associate degree and certificate programs are intended to produce graduates who are prepared for employment as entry-level industrial electricians, electronics technicians, or computer industry technicians.",
                        }],[{
                            cardTitle:"Industrial Maintenance Program",
                            cardLink:"https://www.rstc.edu/industrialmaintenance",
                            cardImage:"reid-college-logo.jpg",
                            cardText:"The associate degree and certificate programs are intended to produce graduates who are prepared for employment as entry-level industrial electricians, electronics technicians, or computer industry technicians.",
                            }],[{
                                cardTitle:"Welding Program",
                                cardLink:"https://www.rstc.edu/weldingtechnology",
                                cardImage:"reid-college-logo.jpg",
                                cardText:"The diploma and certificates programs are intended to produce graduates who are prepared for employment as entry-level structural and / or pipe welders.",
                                }
                ]],
        },
        {
        tabName:'Career Resources',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
              [
                { 
                cardTitle:"ACRI/Go Build",
                cardImage:"go-build.jpg",
                cardText:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades.",  
                cardLink:"https://gobuildalabama.com/ "
                }
              ]        
            ]
        },            
  ],
    energy:[
        {
        tabName:'Pathway',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
          [
            {
            cardTitle:"Pathways",
            cardLink:"",
            cardImage:"energy-pathway.jpg",
            cardText:"pathways"
            }
          ],
        ]
        }, 
        {
        tabName:'Bishop State College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                cardTitle:"Electrical Technology Program",
                cardLink:"https://www.bishop.edu/programs/career-technical-education/electrical-technology",
                cardImage:"bishop-college-logo.jpg",
                cardText:"The Electrical Technology program is designed to provide the student with an opportunity to acquire the skills, knowledge, and understanding needed to enter the occupation as an entry-level electrician."
                },
                {
                    cardTitle:"Lineworker Program",
                    cardLink:"https://www.bishop.edu/programs/workforce-development/line-worker-program",
                    cardImage:"bishop-college-logo.jpg",
                    cardText:"Alabama Power and Bishop State Community College are partnering to offer a training program to prepare students to work as electric utility lineworkers."
                    }
            ],
        ]
        },
        {
            tabName:'Reid State College',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                    cardTitle:"Industrial Electricity Program",
                    cardLink:"https://www.rstc.edu/industryelectric",
                    cardImage:"reid-college-logo.jpg",
                    cardText:"The associate degree and certificate programs are intended to produce graduates who are prepared for employment as entry-level industrial electricians, electronics technicians, or computer industry technicians."
                    }],[{
                    cardTitle:"Industrial Maintenance Program",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/electrical-technology",
                    cardImage:"reid-college-logo.jpg",
                    cardText:"The associate degree and certificate programs are intended to produce graduates who are prepared for employment as entry-level industrial electricians, electronics technicians, or computer industry technicians.",
                    }
                ],
            ]
            },
            {
                tabName:'University of South Alabama ',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                        cardTitle:"College of Engineering",
                        cardLink:"https://www.southalabama.edu/colleges/engineering/",
                        cardImage:"usa-logo.jpg",
                        cardText:"At the University of South Alabama, our College of Engineering is ready to help you prepare for a successful career."
                        }],
                ]
                },
        {
        tabName:'Career Resources',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
              [
                { 
                cardTitle:"ACRI/Go Build",
                cardImage:"go-build.jpg",
                cardText:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades.",  
                cardLink:"https://gobuildalabama.com/ "
                }
              ]        
            ]
        },            
  ],
    engineering_and_design:[
        {
        tabName:'Pathway',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
          [
            {
            cardTitle:"Pathways",
            cardLink:"",
            cardImage:"engineering-pathway.jpg",
            cardText:"pathways"
            }
          ],
        ]
        }, 
        {
        tabName:'Bishop State College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                cardImage:"bishop-college-logo.jpg",
                cardTitle:"Electronics Engineering Technology",
                cardText:"The Electronics Engineering Technology program is designed to prepare students for exciting, fast paced occupations.",
                cardLink:"https://www.bishop.edu/programs/career-technical-education/electronics-engineering-technology",
                },
                {
                    cardImage:"bishop-college-logo.jpg",
                    cardTitle:"Drafting & Design Technology",
                    cardText:"In the drafting and design technology program, students gain a thorough knowledge of technical drawing communication through the use of computer-aided drafting (CAD).",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/drafting-design-technology",
                    }
            ],
        ]
        },
        {
            tabName:'Coastal Alabama College',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                        cardImage:"cacc-logo.jpg",
                        cardTitle:"Mathematics & Engineering ",
                        cardText:"The College offers Associate of Science degrees in Mathematics and Engineering.",
                        cardLink:"http://www.coastalalabama.edu/engineering/",
                    }
                ],
            ]
        },
        {
            tabName:'University of South Alabama',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                        cardImage:"usa-main-logo.jpg",
                        cardTitle:"College of Engineering",
                        cardText:"At the University of South Alabama, our College of Engineering is ready to help you prepare for a successful career.",
                        cardLink:"https://www.southalabama.edu/colleges/engineering/",
                    }
                ],
            ]
        },
        {
            tabName:'Spring Hill College',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                        cardImage:"spring-hill-logo.jpg",
                        cardTitle:"Dual Degree Engineering",
                        cardText:"Designing aircraft, spacecraft, or missiles. Learn the science behind machines and the energy that makes them work. Be involved in energy exploration and tackle issues related to global warming.",
                        cardLink:"https://www.shc.edu/academics/freshmen-transfer/majors/dual-degree-engineering/",
                    }
                ],
            ]
        },
        {
        tabName:'Career Resources',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
              [
                { 
                cardTitle:"ACRI/Go Build",
                cardImage:"go-build.jpg",
                cardText:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades.",  
                cardLink:"https://gobuildalabama.com/ "
                }
              ]        
            ]
        },            
  ],
    healthcare:[
        {
            tabName:'Pathway',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
              [
                {
                cardTitle:"Pathways",
                cardLink:"",
                cardImage:"healthcare-pathway.jpg",
                cardText:"pathways"
                }
              ],
            ]
            },
            {
                    tabName:'University of Mobile',
                    media:{type:'image',link:"www.emp1.com"},
                    cardRows:[
                        [
                            {
                                cardImage:"mobile-university-logo.jpg",
                                cardTitle:"College of Health Professions",
                                cardText:"Healthcare is a demanding field, and there is a high demand for healthcare professionals in an industry that is one of the fastest growing in the nation. ",
                                cardLink:"https://umobile.edu/academics/college-of-health-professions/",
                            }
                        ],
                    ]
            },
            {
                tabName:'University of Mobile - Nursing',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"mobile-university-logo.jpg",
                            cardTitle:"Traditional Bachelor of Science in Nursing (TBSN)",
                            cardText:"Healthcare is a demanding field, and there is a high demand for healthcare professionals in an industry that is one of the fastest growing in the nation. ",
                            cardLink:"https://umobile.edu/academics/school-of-nursing/bsnursing/",
                        },
                        {
                            cardImage:"mobile-university-logo.jpg",
                            cardTitle:"RN to BSN (Adult)",
                            cardText:"Healthcare is a demanding field, and there is a high demand for healthcare professionals in an industry that is one of the fastest growing in the nation. ",
                            cardLink:"https://umobile.edu/academics/school-of-nursing/rn-to-bsn-adult/",
                        }
                    ],
                ]
                },
                {
                    tabName:'University of Mobile - Sports',
                    media:{type:'image',link:"www.emp1.com"},
                    cardRows:[
                        [
                            {
                                cardImage:"mobile-university-logo.jpg",
                                cardTitle:"Kinesiology",
                                cardText:"Healthcare is a demanding field, and there is a high demand for healthcare professionals in an industry that is one of the fastest growing in the nation. ",
                                cardLink:"https://umobile.edu/academics/school-health-sports-science/kinesiology/",
                            },
                            {
                                cardImage:"mobile-university-logo.jpg",
                                cardTitle:"Exercise Science",
                                cardText:"Healthcare is a demanding field, and there is a high demand for healthcare professionals in an industry that is one of the fastest growing in the nation. ",
                                cardLink:"https://umobile.edu/bachelor-of-science-in-exercise-science/",
                            },
                            {
                                cardImage:"mobile-university-logo.jpg",
                                cardTitle:"School of Health and Sports Science",
                                cardText:"Healthcare is a demanding field, and there is a high demand for healthcare professionals in an industry that is one of the fastest growing in the nation. ",
                                cardLink:"https://umobile.edu/academics/school-health-sports-science/",
                            }
                        ],
                    ]
            },
            {
                tabName:'Bishop State',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"bishop-logo.jpg",
                            cardTitle:"Physical Therapy Assistant",
                            cardText:"The Health Science Professions department offers a variety of health-related programs for students interested in helping, caring, & serving others. From an emergency medical technician to a certified nurse assistant to many other related areas, Health Science Professions has a program for you!",
                            cardLink:"https://www.bishop.edu/programs/health-science-professions/physical-therapist-assistant",
                        },
                        {
                            cardImage:"bishop-logo.jpg",
                            cardTitle:"Health Information Technology",
                            cardText:"The Health Science Professions department offers a variety of health-related programs for students interested in helping, caring, & serving others. From an emergency medical technician to a certified nurse assistant to many other related areas, Health Science Professions has a program for you!",
                            cardLink:"https://www.bishop.edu/programs/health-science-professions/health-information-technology",
                        },
                        {
                            cardImage:"bishop-logo.jpg",
                            cardTitle:"Medical Coding Short Certificate",
                            cardText:"The Health Science Professions department offers a variety of health-related programs for students interested in helping, caring, & serving others. From an emergency medical technician to a certified nurse assistant to many other related areas, Health Science Professions has a program for you!",
                            cardLink:"https://www.bishop.edu/programs/health-science-professions/medical-coding-short-certificate",
                        }
                    ],
                ]
            },
            {
                tabName:'Bishop State - Nursing',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"bishop-logo.jpg",
                            cardTitle:"Nursing",
                            cardText:"The Health Science Professions department offers a variety of health-related programs for students interested in helping, caring, & serving others. From an emergency medical technician to a certified nurse assistant to many other related areas, Health Science Professions has a program for you!",
                            cardLink:"https://www.bishop.edu/programs/health-science-professions/nursing",
                        }
                    ],
                ]
            },
            {
                tabName:'University of South Alabama',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"usa-main-logo.jpg",
                            cardTitle:"Professional Health Sciences - Healthcare Management (BS)",
                            cardText:"The Department of EMS Education prepares students for careers in Emergency Medical Services. To meet the expanding role of prehospital providers, the Department of EMS Education offers three certificate programs, EMT, AEMT and Paramedic, as well as a Bachelor of Science in Emergency Medical Services.",
                            cardLink:"https://www.southalabama.edu/bulletin/current/degrees-programs/emerg-medical-serv-training/healthcare-management-bs/index.html",
                        },
                        {
                            cardImage:"usa-main-logo.jpg",
                            cardTitle:"Emergency Medical Services",
                            cardText:"The Department of EMS Education prepares students for careers in Emergency Medical Services. To meet the expanding role of prehospital providers, the Department of EMS Education offers three certificate programs, EMT, AEMT and Paramedic, as well as a Bachelor of Science in Emergency Medical Services.",
                            cardLink:"https://www.southalabama.edu/bulletin/current/degrees-programs/emerg-medical-serv-training/emergency-medical-services-bs/index.html",
                        },
                        {
                            cardImage:"usa-main-logo.jpg",
                            cardTitle:"Exercise Science (BS) - Health and Fitness Concentration",
                            cardText:"The Department of Health, Kinesiology, and Sport offers state and nationally accredited undergraduate programs of study in Physical Education (P-12), Health Education (6-12), and a combined Health (6-12) and Physical Education (P-12) program that lead to Alabama Class B Educator Preparation and Certification. ",
                            cardLink:"https://www.southalabama.edu/bulletin/current/degrees-programs/health-kinesiology-sport/exercise-science-bs-health/index.html",
                        }
                    ],
                ]
            },
            {
                tabName:'University of South Alabama - Nursing',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"usa-main-logo.jpg",
                            cardTitle:"Nursing BSN",
                            cardText:"The College of Nursing is dedicated to the education of professional nurses who provide the highest quality health care to the communities they serve. The practice of nursing is both an art and a science; the focus of nursing is the diagnosis and treatment of human responses to actual or potential health problems.",
                            cardLink:"https://www.southalabama.edu/bulletin/current/degrees-programs/nursing/nursing-bs-rn-bsn/index.html",
                        },
                        {
                            cardImage:"usa-main-logo.jpg",
                            cardTitle:"Nursing (BS) - Accelerated",
                            cardText:"The College of Nursing is dedicated to the education of professional nurses who provide the highest quality health care to the communities they serve. The practice of nursing is both an art and a science; the focus of nursing is the diagnosis and treatment of human responses to actual or potential health problems.",
                            cardLink:"https://www.southalabama.edu/bulletin/current/degrees-programs/nursing/nursing-bs-accelerated/index.html",
                        },
                        {
                            cardImage:"usa-main-logo.jpg",
                            cardTitle:"Nursing (BSN) - Traditional",
                            cardText:"The College of Nursing is dedicated to the education of professional nurses who provide the highest quality health care to the communities they serve. The practice of nursing is both an art and a science; the focus of nursing is the diagnosis and treatment of human responses to actual or potential health problems.",
                            cardLink:"https://www.southalabama.edu/bulletin/current/degrees-programs/nursing/nursing-bs-traditional/index.html",
                        }
                    ],
                ]
            },
            {
                tabName:'Coastal Alabama',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"cacc-logo.jpg",
                            cardTitle:"EMS",
                            cardText:"To prepare competent entry-level Paramedics in the cognitive (Knowledge) psychomotor (skills), and Affective (behavior) learning domains with or without exit points at the Advanced Emergency Medical Technician; Emergency Medical Technician and/or Emergency Medical Responder levels.",
                            cardLink:"http://www.coastalalabama.edu/ems/",
                        },
                        {
                            cardImage:"cacc-logo.jpg",
                            cardTitle:"Surgical Technology",
                            cardText:"The mission of the Surgical Technology Department at the College is to develop competent and caring surgical technologists.  These individuals will be able to function as part of the surgical team in any surgical setting.",
                            cardLink:"http://www.coastalalabama.edu/surgtech/",
                        },
                        {
                            cardImage:"cacc-logo.jpg",
                            cardTitle:"Veterinary Technology",
                            cardText:"Have you always dreamed of a career helping animals? You can make that dream a reality through a career in Veterinary Technology. ",
                            cardLink:"http://www.coastalalabama.edu/vettech/",
                        }
                    ],
                ]
            },
            {
                tabName:'Coastal Alabama - Dental',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"cacc-logo.jpg",
                            cardTitle:"Dental Assisting",
                            cardText:"This program prepares individuals to assist with the care of dental patients under the direct supervision of a dentist.  Dental assistants help with the provision of patient care, office administration, and laboratory procedures. ",
                            cardLink:"http://www.coastalalabama.edu/dentalassisting/",
                        }
                    ],
                ]
            },
            {
                tabName:'Coastal Alabama - Nursing',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"cacc-logo.jpg",
                            cardTitle:"Nursing",
                            cardText:"Welcome To Coastal Alabama Nursing",
                            cardLink:"http://www.coastalalabama.edu/nursing/",
                        }
                    ],
                ]
            },
            {
                tabName:'Reid State',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"reid-state-logo.jpg",
                            cardTitle:"Health Careers",
                            cardText:"We are proud of our accomplishments in our Health Careers' Division and look forward to helping you achieve your desired goals. We offer several options in course sequencing to help you be successful. ",
                            cardLink:"https://www.rstc.edu/healthcareers",
                        },
                        {
                            cardImage:"reid-state-logo.jpg",
                            cardTitle:"Nursing Assistant Program",
                            cardText:"3 weeks for Training for Business Industry (TBI); 6 weeks of traditional learning; and this course is embedded in the Health Science Program (HPS) for one semester.",
                            cardLink:"https://www.rstc.edu/nursingassistant",
                        }
                    ],
                ]
            },
            {
                tabName:'Springhill College',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"springhill-logo.jpg",
                            cardTitle:"Nursing",
                            cardText:"Spring Hill College offers two curricula for students to pursue their Bachelor of Science in Nursing degree.",
                            cardLink:"https://www.shc.edu/academics/freshmen-transfer/majors/nursing/",
                        },
                        {
                            cardImage:"springhill-logo.jpg",
                            cardTitle:"Pre-Health",
                            cardText:"Interested in health and medicine? Gain the skills you need for to build a successful career as a physician, dentist, veterinarian, or pharmacist as a pre-health major at Spring Hill College.",
                            cardLink:"https://www.shc.edu/academics/freshmen-transfer/majors/pre-health/",
                        }
                    ],
                ]
            },
        ],
    information_technology:[
        {
            tabName:'Pathway',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
              [
                {
                cardTitle:"Pathways",
                cardLink:"",
                cardImage:"it-pathway.jpg",
                cardText:"pathways"
                }
              ],
            ]
            },
            {
                tabName:'University of South Alabama',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                  [
                    {
                    cardTitle:"Information Systems and Technology: Health Informatics",
                    cardLink:"https://www.southalabama.edu/colleges/soc/cist/hi-program-requirements.html",
                    cardImage:"usa-main-logo.jpg",
                    cardText:"In the Department of IST we provide a variety of paths to a computing career, and we invite you join us when choosing yours."
                    },
                    {
                        cardTitle:"Information Systems and Technology: Bachelor of Science in Information Systems (ISC)",
                        cardLink:"https://www.southalabama.edu/colleges/soc/cist/isc-program-requirements.html",
                        cardImage:"usa-main-logo.jpg",
                        cardText:"In the Department of IST we provide a variety of paths to a computing career, and we invite you join us when choosing yours."
                        },
                        {
                            cardTitle:"Information Systems and Technology (BS)",
                            cardLink:"https://www.southalabama.edu/bulletin/current/degrees-programs/information-systems-and-technology/information-technology-bs/index.html",
                            cardImage:"usa-main-logo.jpg",
                            cardText:"In the Department of IST we provide a variety of paths to a computing career, and we invite you join us when choosing yours."
                            }
                  ],
                ]
                },
                {
                    tabName:'University of Mobile',
                    media:{type:'image',link:"www.emp1.com"},
                    cardRows:[
                      [
                        {
                        cardTitle:"Computer Information Systems",
                        cardLink:"https://umobile.edu/academics/school-of-business/computer-information-systems/",
                        cardImage:"mobile-university-logo.jpg",
                        cardText:"Welcome to University of Mobile!"
                        }
                      ],
                    ]
                    },
                    {
                        tabName:'Coastal Alabama',
                        media:{type:'image',link:"www.emp1.com"},
                        cardRows:[
                          [
                            {
                            cardTitle:"Information Technology (USA only)",
                            cardLink:"https://catalog.coastalalabama.edu/information-technology-usa-only",
                            cardImage:"cacc-logo.jpg",
                            cardText:"Welcome to Coastal Alabama!"
                            }
                          ],
                        ]
                        },
    ],
    hospitality_and_tourism:[
        {
            tabName:'Pathway',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
              [
                {
                cardTitle:"Pathways",
                cardLink:"",
                cardImage:"tourism-pathway.jpg",
                cardText:"pathways"
                }
              ],
            ]
            },
            {
                tabName:'Bishop State',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                  [
                    {
                    cardTitle:"Culinary Arts",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/culinary-arts",
                    cardImage:"bishop-logo.jpg",
                    cardText:"The Culinary Arts program of Bishop State Community College provides organized, specialized learning experiences to prepare students for occupations within the food service industry."
                    }
                  ],
                ]
                },
                {
                    tabName:'Coastal Alabama',
                    media:{type:'image',link:"www.emp1.com"},
                    cardRows:[
                      [
                        {
                        cardTitle:"Hospitality and Culinary Management",
                        cardLink:"http://www.coastalalabama.edu/hospitalityandculinary/",
                        cardImage:"cacc-logo.jpg",
                        cardText:"Hospitality Management, Event Planning, Pastry/Baking and Culinary Programs of the Highest Standards"
                        }
                      ],
                    ]
                    },
                    {
                        tabName:'University of South Alabama',
                        media:{type:'image',link:"www.emp1.com"},
                        cardRows:[
                            [
                                {
                                    cardImage:"usa-main-logo.jpg",
                                    cardTitle:"Hospitality and Tourism Management (BS)",
                                    cardText:"Improve the well-being of people along the Gulf Coast by producing hospitality, tourism and ecotourism professionals who possess fiscal, social, environmental and professional competencies through education and training, job placement and partnerships.",
                                    cardLink:"https://www.southalabama.edu/bulletin/current/degrees-programs/hospitality-tourism-management/hospitality-tourism-management-bs/index.html",
                                }
                            ],
                        ]
                    },
    ],
    manufacturing:[
        {
            tabName:'Pathway',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
              [
                {
                cardTitle:"Pathways",
                cardLink:"",
                cardImage:"manufacturing-pathway.jpg",
                cardText:"pathways"
                }
              ],
            ]
            },
        {
        tabName:'Coastal Alabama College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardImage:"cacc-logo.jpg",
                    cardTitle:"Machine Tool Technology",
                    cardText:"Located on the Thomasville and Atmore Campuses, this program prepares students for a fast-paced, high-demand career in Machine Tool Technology.",
                    cardLink:"http://www.coastalalabama.edu/machine/",
                }
            ],
        ]
    },
    {
        tabName:'Reid State College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardImage:"reid-college-logo.jpg",
                    cardTitle:"Reid State Technical College",
                    cardText:"Reid State Technical College is a degree-granting, two-year institution that provides quality academic and technical education to students from diverse backgrounds and abilities.",
                    cardLink:"https://www.rstc.edu/",
                }
            ],
        ]
    },
    {
        tabName:'Bishop State College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardImage:"bishop-college-logo.jpg",
                    cardTitle:"Automotive Technology",
                    cardText:"The Automotive Technology program offers detailed modernized training for students to become qualified automotive service and maintenance technicians.",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/electronics-engineering-technology ",
                }],
                [{
                    cardImage:"bishop-college-logo.jpg",
                    cardTitle:"Diesel Technology",
                    cardText:"The Diesel Technology program provides training, knowledge and skills for those who desire to learn how to troubleshoot and repair truck and construction equipment systems.",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/diesel-technology",
                }],
                [{
                    cardImage:"bishop-college-logo.jpg",
                    cardTitle:"Process & Maintenance Technology",
                    cardText:"The Process and Maintenance Technology program prepares graduates for entry-level process and maintenance positions in steel and stainless steel manufacturing, chemical and other plant operation related industries.",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/process-maintenance-technology",
                }
            ],
        ]
    },
    {
        tabName:'Career Resources',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
              [
                { 
                cardTitle:"ACRI/Go Build",
                cardImage:"go-build.jpg",
                cardText:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades.",  
                cardLink:"https://gobuildalabama.com/ "
                }
              ]        
            ]
        },],
    public_service:[
        {
        tabName:'Pathway',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
          [
            {
            cardTitle:"Pathways",
            cardLink:"",
            cardImage:"public-service-pathway.jpg",
            cardText:"pathways"
            }
          ],
        ]
        },
        {
            tabName:'University of Mobile',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
              [
                {
                cardTitle:"University of Mobile",
                cardLink:"https://umobile.edu/academics/college-of-arts-and-sciences/criminology/",
                cardImage:"mobile-university-logo.jpg",
                cardText:"Welcome to University of Mobile!"
                }
              ],
            ]
            },
        {
            tabName:'University of South Alabama',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                        cardImage:"usa-main-logo.jpg",
                        cardTitle:"University of South Alabama",
                        cardText:"Undergraduate students at the University of South Alabama can choose to major in political science or criminal justice.",
                        cardLink:"https://www.southalabama.edu/colleges/artsandsci/pscj/",
                    }
                ],
            ]
        },
        {
            tabName:'Coastal Alabama College',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                        cardImage:"cacc-logo.jpg",
                        cardTitle:"Coastal Alabama Community College",
                        cardText:"Located on the Thomasville and Atmore Campuses, this program prepares students for a fast-paced, high-demand career in Machine Tool Technology.",
                        cardLink:"https://catalog.coastalalabama.edu/liberal-arts/criminal-justice-pathway",
                    }
                ],
            ]
        },
        {
            tabName:'Bishop State',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                        cardImage:"bishop-logo.jpg",
                        cardTitle:"Criminal Justice",
                        cardText:"Criminal justice is the system of law enforcement used within all stages of criminal proceedings involving the police, lawyers, courts, and corrections. ",
                        cardLink:"https://www.bishop.edu/news/criminal-justice-courses-at-bishop-state",
                    }
                ],
            ]
        },
    ],
    safety:[{
        tabName:'Pathway',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
          [
            {
            cardTitle:"Pathways",
            cardLink:"",
            cardImage:"safety-pathway.jpg",
            cardText:"pathways"
            }
          ],
        ]
        },
        {
            tabName:'Coastal Alabama College',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                        cardImage:"cacc-logo.jpg",
                        cardTitle:"Coastal Alabama Community College",
                        cardText:"Adult Education Classes We Offer at No Cost, Microsoft Office, Office Administration 1 and 2, ...",
                        cardLink:"http://www.coastalalabama.edu/adulteducation/adult_education_classes/",
                    }
                ],
            ]
        },
        {
            tabName:'University of Mobile',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
              [
                {
                cardTitle:"University of Mobile",
                cardLink:"https://umobile.edu/academics/personal-safety-and-security/",
                cardImage:"mobile-university-logo.jpg",
                cardText:"Welcome to University of Mobile!"
                }
              ],
            ]
            },
        {
            tabName:'Career Resources',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                  [
                    { 
                    cardTitle:"ACRI/Go Build",
                    cardImage:"go-build.jpg",
                    cardText:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades.",  
                    cardLink:"https://gobuildalabama.com/ "
                    }
                  ]        
                ]
            },],
    shipbuilding_and_repair:[

        {
            tabName:'Pathway',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
              [
                {
                cardTitle:"Pathways",
                cardLink:"",
                cardImage:"shipbuild-pathway.jpg",
                cardText:"pathways"
                }
              ],
            ]
            },
        {
        tabName:'Coastal Alabama College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardImage:"cacc-logo.jpg",
                    cardTitle:"Machine Tool Technology",
                    cardText:"Located on the Thomasville and Atmore Campuses, this program prepares students for a fast-paced, high-demand career in Machine Tool Technology.",
                    cardLink:"http://www.coastalalabama.edu/machine/",
                }
            ],
        ]
    },
    {
        tabName:'Bishop State College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardImage:"bishop-college-logo.jpg",
                    cardTitle:"Automotive Technology",
                    cardText:"The Automotive Technology program offers detailed modernized training for students to become qualified automotive service and maintenance technicians.",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/electronics-engineering-technology ",
                }],
                [{
                    cardImage:"bishop-college-logo.jpg",
                    cardTitle:"Diesel Technology",
                    cardText:"The Diesel Technology program provides training, knowledge and skills for those who desire to learn how to troubleshoot and repair truck and construction equipment systems.",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/diesel-technology",
                }],
               [{
                    cardImage:"bishop-college-logo.jpg",
                    cardTitle:"Process & Maintenance Technology",
                    cardText:"The Process and Maintenance Technology program prepares graduates for entry-level process and maintenance positions in steel and stainless steel manufacturing, chemical and other plant operation related industries.",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/process-maintenance-technology",
                }
            ],
        ]
    },
    {
        tabName:'Career Resources',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
              [
                { 
                cardTitle:"ACRI/Go Build",
                cardImage:"go-build.jpg",
                cardText:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades.",  
                cardLink:"https://gobuildalabama.com/ "
                }
              ]        
            ]
        },
        {
            tabName:'Career Training',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                        cardImage:"maritime-logo.jpg",
                        cardTitle:"AIDT Maritime Training Center",
                        cardText:"The AIDT Maritime Training Center is available to all maritime and industrial companies and offers instruction in industry standards, upgraded technologies, and custom training.",
                        cardLink:"https://maritime.aidt.edu/index.php/mtc-career-path",
                    }
                ],
            ]
        },
    ],
    transportation_distribution_and_logistics:[
        
        {
            tabName:'Pathway',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
              [
                {
                cardTitle:"Pathways",
                cardLink:"",
                cardImage:"transport-pathway.jpg",
                cardText:"pathways"
                }
              ],
            ]
            },
            {
                tabName:'University of South Alabama',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"usa-main-logo.jpg",
                            cardTitle:"University of South Alabama",
                            cardText:"The Supply Chain and Logistics Management (SCLM) major is designed to equip students with the skill sets necessary for successful careers in supply chain-related fields. ",
                            cardLink:"https://www.southalabama.edu/colleges/mcob/supplychain/",
                        }
                    ],
                ]
            },
            {
                tabName:'Coastal Alabama College',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"cacc-logo.jpg",
                            cardTitle:"Coastal Alabama Community College",
                            cardText:"Adult Education Classes We Offer at No Cost, Microsoft Office, Office Administration 1 and 2, ...",
                            cardLink:"http://www.coastalalabama.edu/adulteducation/adult_education_classes/",
                        }
                    ],
                ]
            },
        {
        tabName:'Reid State College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardImage:"reid-college-logo.jpg",
                    cardTitle:"Commercial Truck Driving",
                    cardText:"The Commercial Truck Driving program provides the knowledge to work in a high paying industry, in a short period of time. It will provide accessible, quality educational opportunities that will provide individuals with the knowledge, technical skills, and attitudes necessary to obtain entry-level employment in the commercial truck driving profession.",
                    cardLink:"https://www.rstc.edu/comtruckdriving",
                }
            ],
        ]
    },
    {
        tabName:'Bishop State College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardImage:"bishop-college-logo.jpg",
                    cardTitle:"Truck Driving Program",
                    cardText:"Bishop State Community College’s Commercial Vehicle Driving Course is an eight-week program that will help you develop the necessary skills needed to obtain a CDL Class A license.",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/truck-driving ",
                }
            ],
        ]
    },
    {
        tabName:'Career Resources',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
              [
                { 
                cardTitle:"ACRI/Go Build",
                cardImage:"go-build.jpg",
                cardText:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades.",  
                cardLink:"https://gobuildalabama.com/ "
                }
              ]        
            ]
        },
    ],
  
   
  }
  
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var NAW_Quiz_Object={ 
    career_services:{
  
         
    },
    advanced_manufacturing:{
  
         
    },
    aerospace:{
  
         
    },
    agriculture_food_and_natural_resources:{
  
         
    },
    architecture_and_construction:{
  
         
    },
    automotive_and_manufacturing:{
  
         
    },
    business_and_finance:{
  
         
    },
    communications_media_and_marketing:{
  
         
    },
    cybersecurity_and_information_technology:{
  
         
    },
    education_and_training:{
  
         
    },
    energy_and_utilities:{
  
         
    },
   healthcare:{
  
         
    },
  law_public_safety_and_corrections:{
  
         
    },
  transportation_distribution_and_logistics:{
  
         
    },
   
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var EAW_Tabs_Object={ 
    agriculture_and_natural_resources:[
        {
            tabName:'School Programs',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                        [
                            {
                                cardTitle:"CACC Career Explorer",
                                cardLink:"https://www.cacc.edu",
                                cardImage:"central-alabama-logo.jpg",
                                cardText:"Discover career paths, educational opportunities, and in-demand careers based on your interests!"
                            }
                        ],
                        [  {
                            cardTitle:"Gadsden State Community College",
                            cardLink:"https://www.gadsdenstate.edu",
                            cardImage:"gadsden-state-logo.jpg",
                            cardText:"Take a look at our 70+ programs of study and find the right fit for you, your schedule and your family. "
                        },],
                        [ {
                            cardTitle:"Jacksonville State University",
                            cardLink:"http://www.jsu.edu",
                            cardImage:"jacksonville-state-logo.jpg",
                            cardText:"At Jacksonville State University, you can earn a degree in more than 95 academic programs and concentrations, within a community that supports you every step of the way."
                        },],
                        [{
                            cardTitle:"Southern Union State Community College",
                            cardLink:"https://www.suscc.edu/",
                            cardImage:"southern-union-logo.jpg",
                            cardText:"The Academic Division at Southern Union State Community College offers the Associate in Science (A.S.) and Associate in Applied Science (A.A.S.) degrees. Students may also earn Certificates in some curriculum areas. "
                        },],   
                    ]
            },        
         
            ],
    automotive_and_manufacturing:[
          {
    tabName:'School Programs',
    media:{type:'image',link:"www.emp1.com"},
    cardRows:[
        [
            {
                cardTitle:"Central Alabama Community College",
                cardLink:"https://www.cacc.edu",
                cardImage:"central-alabama-logo.jpg",
                cardText:"Discover career paths, educational opportunities, and in-demand careers based on your interests!"
            }
        ],
        [  {
            cardTitle:"Gadsden State Community College",
            cardLink:"https://www.gadsdenstate.edu",
            cardImage:"gadsden-state-logo.jpg",
            cardText:"Take a look at our 70+ programs of study and find the right fit for you, your schedule and your family. "
        },],
        [ {
            cardTitle:"Jacksonville State University",
            cardLink:"http://www.jsu.edu",
            cardImage:"jacksonville-state-logo.jpg",
            cardText:"At Jacksonville State University, you can earn a degree in more than 95 academic programs and concentrations, within a community that supports you every step of the way."
        },],
        [
        {
            cardTitle:"Southern Union State Community College",
            cardLink:"https://www.suscc.edu/",
            cardImage:"southern-union-logo.jpg",
            cardText:"The Academic Division at Southern Union State Community College offers the Associate in Science (A.S.) and Associate in Applied Science (A.A.S.) degrees. Students may also earn Certificates in some curriculum areas. "
        },],   
    ] 
            
    },         
    ],
    communications_media_and_marketing:[ {
        tabName:'School Programs',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardTitle:"Central Alabama Community College",
                    cardLink:"https://www.cacc.edu",
                    cardImage:"central-alabama-logo.jpg",
                    cardText:"Discover career paths, educational opportunities, and in-demand careers based on your interests!"
                }
            ],
            [  {
                cardTitle:"Gadsden State Community College",
                cardLink:"https://www.gadsdenstate.edu",
                cardImage:"gadsden-state-logo.jpg",
                cardText:"Take a look at our 70+ programs of study and find the right fit for you, your schedule and your family. "
            },],
            [ {
                cardTitle:"Jacksonville State University",
                cardLink:"http://www.jsu.edu",
                cardImage:"jacksonville-state-logo.jpg",
                cardText:"At Jacksonville State University, you can earn a degree in more than 95 academic programs and concentrations, within a community that supports you every step of the way."
            },],
            [
            {
                cardTitle:"Southern Union State Community College",
                cardLink:"https://www.suscc.edu/",
                cardImage:"southern-union-logo.jpg",
                cardText:"The Academic Division at Southern Union State Community College offers the Associate in Science (A.S.) and Associate in Applied Science (A.A.S.) degrees. Students may also earn Certificates in some curriculum areas. "
            },],   
        ] 
                
        }, 
      ],
    construction:[  {
        tabName:'School Programs',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardTitle:"Central Alabama Community College",
                    cardLink:"https://www.cacc.edu",
                    cardImage:"central-alabama-logo.jpg",
                    cardText:"Discover career paths, educational opportunities, and in-demand careers based on your interests!"
                }
            ],
            [  {
                cardTitle:"Gadsden State Community College",
                cardLink:"https://www.gadsdenstate.edu",
                cardImage:"gadsden-state-logo.jpg",
                cardText:"Take a look at our 70+ programs of study and find the right fit for you, your schedule and your family. "
            },],
            [ {
                cardTitle:"Jacksonville State University",
                cardLink:"http://www.jsu.edu",
                cardImage:"jacksonville-state-logo.jpg",
                cardText:"At Jacksonville State University, you can earn a degree in more than 95 academic programs and concentrations, within a community that supports you every step of the way."
            },],
            [
            {
                cardTitle:"Southern Union State Community College",
                cardLink:"https://www.suscc.edu/",
                cardImage:"southern-union-logo.jpg",
                cardText:"The Academic Division at Southern Union State Community College offers the Associate in Science (A.S.) and Associate in Applied Science (A.A.S.) degrees. Students may also earn Certificates in some curriculum areas. "
            },],   
        ]
                
        },  
                      
                           ],
    energy_and_utilities:[  {
        tabName:'School Programs',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardTitle:"Central Alabama Community College",
                    cardLink:"https://www.cacc.edu",
                    cardImage:"central-alabama-logo.jpg",
                    cardText:"Discover career paths, educational opportunities, and in-demand careers based on your interests!"
                }
            ],
            [  {
                cardTitle:"Gadsden State Community College",
                cardLink:"https://www.gadsdenstate.edu",
                cardImage:"gadsden-state-logo.jpg",
                cardText:"Take a look at our 70+ programs of study and find the right fit for you, your schedule and your family. "
            },],
            [ {
                cardTitle:"Jacksonville State University",
                cardLink:"http://www.jsu.edu",
                cardImage:"jacksonville-state-logo.jpg",
                cardText:"At Jacksonville State University, you can earn a degree in more than 95 academic programs and concentrations, within a community that supports you every step of the way."
            },],
            [
            {
                cardTitle:"Southern Union State Community College",
                cardLink:"https://www.suscc.edu/",
                cardImage:"southern-union-logo.jpg",
                cardText:"The Academic Division at Southern Union State Community College offers the Associate in Science (A.S.) and Associate in Applied Science (A.A.S.) degrees. Students may also earn Certificates in some curriculum areas. "
            },],   
        ]
        }, 
  ],
    engineering:[  {
        tabName:'School Programs',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardTitle:"Central Alabama Community College",
                    cardLink:"https://www.cacc.edu",
                    cardImage:"central-alabama-logo.jpg",
                    cardText:"Discover career paths, educational opportunities, and in-demand careers based on your interests!"
                }
            ],
            [  {
                cardTitle:"Gadsden State Community College",
                cardLink:"https://www.gadsdenstate.edu",
                cardImage:"gadsden-state-logo.jpg",
                cardText:"Take a look at our 70+ programs of study and find the right fit for you, your schedule and your family. "
            },],
            [ {
                cardTitle:"Jacksonville State University",
                cardLink:"http://www.jsu.edu",
                cardImage:"jacksonville-state-logo.jpg",
                cardText:"At Jacksonville State University, you can earn a degree in more than 95 academic programs and concentrations, within a community that supports you every step of the way."
            },],
            [
            {
                cardTitle:"Southern Union State Community College",
                cardLink:"https://www.suscc.edu/",
                cardImage:"southern-union-logo.jpg",
                cardText:"The Academic Division at Southern Union State Community College offers the Associate in Science (A.S.) and Associate in Applied Science (A.A.S.) degrees. Students may also earn Certificates in some curriculum areas. "
            },],   
        ]
                
        }, 
  ],
    healthcare:[ {
        tabName:'School Programs',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardTitle:"Central Alabama Community College",
                    cardLink:"https://www.cacc.edu",
                    cardImage:"central-alabama-logo.jpg",
                    cardText:"Discover career paths, educational opportunities, and in-demand careers based on your interests!"
                }
            ],
            [  {
                cardTitle:"Gadsden State Community College",
                cardLink:"https://www.gadsdenstate.edu",
                cardImage:"gadsden-state-logo.jpg",
                cardText:"Take a look at our 70+ programs of study and find the right fit for you, your schedule and your family. "
            },],
            [ {
                cardTitle:"Jacksonville State University",
                cardLink:"http://www.jsu.edu",
                cardImage:"jacksonville-state-logo.jpg",
                cardText:"At Jacksonville State University, you can earn a degree in more than 95 academic programs and concentrations, within a community that supports you every step of the way."
            },],
            [
            {
                cardTitle:"Southern Union State Community College",
                cardLink:"https://www.suscc.edu/",
                cardImage:"southern-union-logo.jpg",
                cardText:"The Academic Division at Southern Union State Community College offers the Associate in Science (A.S.) and Associate in Applied Science (A.A.S.) degrees. Students may also earn Certificates in some curriculum areas. "
            },],   
        ] 
                
        }, 
   ],
    information_technology:[  {
        tabName:'School Programs',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardTitle:"Central Alabama Community College",
                    cardLink:"https://www.cacc.edu",
                    cardImage:"central-alabama-logo.jpg",
                    cardText:"Discover career paths, educational opportunities, and in-demand careers based on your interests!"
                }
            ],
            [  {
                cardTitle:"Gadsden State Community College",
                cardLink:"https://www.gadsdenstate.edu",
                cardImage:"gadsden-state-logo.jpg",
                cardText:"Take a look at our 70+ programs of study and find the right fit for you, your schedule and your family. "
            },],
            [ {
                cardTitle:"Jacksonville State University",
                cardLink:"http://www.jsu.edu",
                cardImage:"jacksonville-state-logo.jpg",
                cardText:"At Jacksonville State University, you can earn a degree in more than 95 academic programs and concentrations, within a community that supports you every step of the way."
            },],
            [
            {
                cardTitle:"Southern Union State Community College",
                cardLink:"https://www.suscc.edu/",
                cardImage:"southern-union-logo.jpg",
                cardText:"The Academic Division at Southern Union State Community College offers the Associate in Science (A.S.) and Associate in Applied Science (A.A.S.) degrees. Students may also earn Certificates in some curriculum areas. "
            },],   
        ] 
                
        },  
            ],
    public_safety:[  {
        tabName:'School Programs',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardTitle:"Central Alabama Community College",
                    cardLink:"https://www.cacc.edu",
                    cardImage:"central-alabama-logo.jpg",
                    cardText:"Discover career paths, educational opportunities, and in-demand careers based on your interests!"
                }
            ],
            [  {
                cardTitle:"Gadsden State Community College",
                cardLink:"https://www.gadsdenstate.edu",
                cardImage:"gadsden-state-logo.jpg",
                cardText:"Take a look at our 70+ programs of study and find the right fit for you, your schedule and your family. "
            },],
            [ {
                cardTitle:"Jacksonville State University",
                cardLink:"http://www.jsu.edu",
                cardImage:"jacksonville-state-logo.jpg",
                cardText:"At Jacksonville State University, you can earn a degree in more than 95 academic programs and concentrations, within a community that supports you every step of the way."
            },],
            [
            {
                cardTitle:"Southern Union State Community College",
                cardLink:"https://www.suscc.edu/",
                cardImage:"southern-union-logo.jpg",
                cardText:"The Academic Division at Southern Union State Community College offers the Associate in Science (A.S.) and Associate in Applied Science (A.A.S.) degrees. Students may also earn Certificates in some curriculum areas. "
            },],   
        ]
                
        }, 
   ],
   
    transportation_distribution_and_logistics:[  {
        tabName:'School Programs',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardTitle:"CACC Career Explorer",
                    cardLink:"https://www.cacc.edu",
                    cardImage:"central-alabama-logo.jpg",
                    cardText:"Discover career paths, educational opportunities, and in-demand careers based on your interests!"
                }
            ],
            [  {
                cardTitle:"Gadsden State Community College",
                cardLink:"https://www.gadsdenstate.edu",
                cardImage:"gadsden-state-logo.jpg",
                cardText:"Take a look at our 70+ programs of study and find the right fit for you, your schedule and your family. "
            },],
            [ {
                cardTitle:"Jacksonville State University",
                cardLink:"http://www.jsu.edu",
                cardImage:"jacksonville-state-logo.jpg",
                cardText:"At Jacksonville State University, you can earn a degree in more than 95 academic programs and concentrations, within a community that supports you every step of the way."
            },],
            [
            {
                cardTitle:"Southern Union State Community College",
                cardLink:"https://www.suscc.edu/",
                cardImage:"southern-union-logo.jpg",
                cardText:"The Academic Division at Southern Union State Community College offers the Associate in Science (A.S.) and Associate in Applied Science (A.A.S.) degrees. Students may also earn Certificates in some curriculum areas. "
            },],   
        ]
                
        }, 
  ],
   
   
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var WAW_Quiz_Object={ 
    agriculture:[        {
        tabName:'Career Resources',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
                        [{
                        cardTitle:"Careers in Agriculture",
                        cardImage:"alfa-logo.jpg",
                        cardText:"Learn more about careers in Agriculture by viewing this comprehensive brochure produced by Alabama Farmers Federation.",  
                        cardLink:"https://alfafarmers.org/wp-content/uploads/2020/04/Ag_Careers_Booklet_Final.pdf"
                        },
                    ],[{
                        cardTitle:"Simply Southern TV",
                        cardImage:"simply-southern-logo.jpg",
                        cardText:"Simply Southern TV explores all of Alabama, from barnyards and backroads to metros and highways, to bring you the best stories focused on farming, gardening, forestry, rural living and youth in agriculture. ",  
                        cardLink:"https://simplysoutherntv.net/"
                        },
                    ],
                ]
        },
        {
        tabName:'Field Trips',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
                    [
                        { 
                        cardTitle:"Alfa Virtual Field Trips",
                        cardImage:"alfa-logo.jpg",
                        cardText:"Take a trip to farms across the state of Alabama without even leaving your home! These field trips cover row crops, fruits and vegetables, beef cattle, honeybees, a tree nursery and forestry.",  
                        cardLink:"https://alfafarmers.org/virtual-field-trip/"
                        }
                    ],
                ]
        },
        {
            tabName:'Literacy',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                        [
                            {
                            cardTitle:"Alabama Ag in the Classroom",
                            cardLink:"https://www.alabamaaitc.org/",
                            cardImage:"alabama-ag-logo.jpg",
                            cardText:"Alabama Agriculture in the Classroom (AITC) is an interdisciplinary educational program that promotes Ag literacy for students in all grade levels K-12."
                            }
                        ],  
                    ]
            },           
    ],
    cosmetology:[



    ],
    construction:[
  
         
    ],
    education:[
  
         
    ],
    energy_and_utilities:[
  
         
    ],
  
    engineering_and_environment:[
  
         
    ],
    healthcare:[
  
         
    ],
    hospitality_and_tourism:[
  
         
    ],
    manufacturing_auto_logistics:[
  
         
    ],
    public_safety:[
  
         
    ],
   transportation:[
  
         
    ],
  
   
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var CAW_Quiz_Object={ 
    agriculture:[

      {
      tabName:'Pathways',
      media:{type:'image',link:"www.emp1.com"},
      cardRows:[
                  [
                      {
                      cardTitle:"Central AlabamaWorks!",
                      cardLink:"https://centralalabamaworks.com/wp-content/uploads/Agriculture-Student-Guide2022.pdf",
                      cardImage:"career-discovery.jpg",
                      cardText:"Learn more about the various pathways available in this world by clicking below."
                      }
                  ],  
              ]
      },
      {
      tabName:'Career Resources',
      media:{type:'image',link:"www.emp1.com"},
      cardRows:[
                      [{
                      cardTitle:"Careers in Agriculture",
                      cardImage:"alfa.jpg",
                      cardText:"Learn more about careers in Agriculture by viewing this comprehensive brochure produced by Alabama Farmers Federation.",  
                      cardLink:"https://alfafarmers.org/wp-content/uploads/2020/04/Ag_Careers_Booklet_Final.pdf"
                      },
                  ]
              ]
      },
      {
      tabName:'Field Trips',
      media:{type:'image',link:"www.emp1.com"},
      cardRows:[
                  [
                      { 
                      cardTitle:"Alfa Virtual Field Trips",
                      cardImage:"alfa.jpg",
                      cardText:"Take a trip to farms across the state of Alabama without even leaving your home! These field trips cover row crops, fruits and vegetables, beef cattle, honeybees, a tree nursery and forestry.",  
                      cardLink:"https://alfafarmers.org/virtual-field-trip/"
                      }],
                      [{ 
                        cardTitle:"Simply Southern TV",
                        cardImage:"simply-southern-logo.jpg",
                        cardText:"Simply Southern TV explores all of Alabama, from barnyards and backroads to metros and highways, to bring you the best stories focused on farming, gardening, forestry, rural living and youth in agriculture.",  
                        cardLink:"https://simplysoutherntv.net/"
                        }
                  ] 
              ]
      },           
  ],
  healthcare:[
   
      {
      tabName:'Pathways',
      media:{type:'image',link:"www.emp1.com"},
      cardRows:[
                  [
                      {
                      cardTitle:"Central AlabamaWorks!",
                      cardLink:"https://centralalabamaworks.com/wp-content/uploads/Healthcare-Student-Guide2022.pdf",
                      cardImage:"career-discovery.jpg",
                      cardText:"Learn more about the various pathways available in this world by clicking below."
                      }
                  ],  
              ]
      },
    ],
    manufacturing:[
     
        {
        tabName:'Pathways',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
                    [
                        {
                        cardTitle:"Central AlabamaWorks!",
                        cardLink:"https://centralalabamaworks.com/wp-content/uploads/Manufacturing-Student-Guide2022.pdf",
                        cardImage:"career-discovery.jpg",
                        cardText:"Learn more about the various pathways available in this world by clicking below."
                        }
                    ],  
                ]
        },

    ],
    public_service:[

        {
        tabName:'Pathways',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
                    [
                        {
                        cardTitle:"Central AlabamaWorks!",
                        cardLink:"https://centralalabamaworks.com/wp-content/uploads/Public-Service-Student-Guide2022.pdf",
                        cardImage:"career-discovery.jpg",
                        cardText:"Learn more about the various pathways available in this world by clicking below."
                        }
                    ],  
                ]
        },
        
    ],
    transportation_distribution_and_logistics:[

        {
        tabName:'Pathways',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
                    [
                      {
                        cardTitle:"Central AlabamaWorks!",
                        cardLink:"https://centralalabamaworks.com/wp-content/uploads/Transportation-Student-Guide2022.pdf",
                        cardImage:"career-discovery.jpg",
                        cardText:"Learn more about the various pathways available in this world by clicking below."
                        }
                    ],  
                ]
        },
        {
        tabName:'Organizations',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
                    [
                        {
                        cardTitle:"AL Trucking Assoc.",
                        cardImage:"ata-logo.jpg",
                        cardText:"Trucking is the dominate transportation source in Alabama. No other mode delivers the goods as effectively and efficiently as trucking. We are the wheels of Alabama’s diverse and thriving economy. For 80 years, the Alabama Trucking Association has been there for trucking wherever the rubber meets the road, and we are still finding ways to keep progress on schedule.",  
                        cardLink:"https://www.alabamatrucking.org/"
                        }],
                        [{
                        cardTitle:"TechForce Foundation",
                        cardImage:"techforce-logo.jpg",
                        cardText:"TechForce Foundation is a nonprofit that champions students to and through their education and into careers as professional technicians.",  
                        cardLink:"https://techforce.org/"
                        },
                    ]
                ]
        },
    ],
    communication_and_information_technology:[
    
        {
        tabName:'Pathways',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
                    [
                        {
                            cardTitle:"Central AlabamaWorks!",
                            cardLink:"https://centralalabamaworks.com/wp-content/uploads/IT-Student-Guide2022.pdf",
                            cardImage:"career-discovery.jpg",
                            cardText:"Learn more about the various pathways available in this world by clicking below."
                        }
                    ],  
                ]
        },  
      ],
    hospitality_and_tourism:[
   
        {
        tabName:'Pathways',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
                    [
                        {
                            cardTitle:"Central AlabamaWorks!",
                            cardLink:"https://centralalabamaworks.com/wp-content/uploads/Hospitality-Student-Guide2022.pdf",
                            cardImage:"career-discovery.jpg",
                            cardText:"Learn more about the various pathways available in this world by clicking below."
                        }
                    ],  
                ]
        },
        {
        tabName:'Career Resources',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
                    [
                        {
                        cardTitle:"National Restaurant Association",
                        cardImage:"nraef-logo.jpg",
                        cardText:"Restaurant Career Paths allows users to explore potential avenues to advance through careers, including both lateral and advancement transitions. Using this tool, a user can identify a pathway to move from entry roles to management careers.",  
                        cardLink:"https://careerpath.chooserestaurants.org/"
                        }]
                ]
        },
    ],
    energy_and_utilities:[

        {
        tabName:'Pathways',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
                    [
                        {
                            cardTitle:"Central AlabamaWorks!",
                            cardLink:"https://centralalabamaworks.com/wp-content/uploads/Student-Guide-Energy-and-Utilities.pdf",
                            cardImage:"career-discovery.jpg",
                            cardText:"Learn more about the various pathways available in this world by clicking below."
                        }
                    ],  
                ]
        }
         
    ],
    education_and_training:[

      {
      tabName:'Pathways',
      media:{type:'image',link:"www.emp1.com"},
      cardRows:[
                  [
                      {
                          cardTitle:"Central AlabamaWorks!",
                          cardLink:"https://centralalabamaworks.com/wp-content/uploads/Education-Student-Guide2022.pdf",
                          cardImage:"career-discovery.jpg",
                          cardText:"Learn more about the various pathways available in this world by clicking below."
                      }
                  ],  
              ]
      },    

  ],
  architecture_construction_and_utilities:[
   
    {
    tabName:'Pathways',
    media:{type:'image',link:"www.emp1.com"},
    cardRows:[
                [
                    {
                        cardTitle:"Central AlabamaWorks!",
                        cardLink:"https://centralalabamaworks.com/wp-content/uploads/Construction-Student-Guide2022.pdf",
                        cardImage:"career-discovery.jpg",
                        cardText:"Learn more about the various pathways available in this world by clicking below."
                    }
                ],  
            ]
    },        
],
  
  
   
  }
  
  
  
  
   /*********************** */
   var NCTEJW_Tabs_Object={
    aerospace_and_aviation:[
        {
        tabName:'Pathway',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
          [
            {
            cardTitle:"Pathways",
            cardLink:"",
            cardImage:"aerospace-pathway.jpg",
            cardText:"pathways"
            }
          ],
        ]
        }, 
        {
        tabName:'Programs',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                cardTitle:"Flight Works",
                cardLink:"https://www.flightworksalabama.com/education",
                cardImage:"fwa-logo.jpg",
                cardText:"Spearheaded by Airbus and the State of Alabama, Flight Works Alabama is a comprehensive aerospace exhibition and education center in Mobile. Flight Works offers camps, workshops, and programs to help you get your start in the aerospace and aviation industry. "
                }],[{
                cardTitle:"National Flight Academy",
                cardLink:"https://www.nationalflightacademy.com/",
                cardImage:"nfa-logo.jpg",
                cardText:"Experience one of the most exciting and immersive learning adventures in the world aboard the world’s largest simulated aircraft carrier. An experience for rising 7-12th graders that inspires interest in Science, Technology, Engineering and Mathematics (STEM)",
                }
            ],
        ]
        },
        {
        tabName:'Coastal Alabama Aviation College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
          [
            {
              cardTitle:"Coastal Alabama Aviation College",
              cardImage:"cacc-logo.jpg",
              cardText:"The Alabama Gulf Coast serves as home to many leaders in the Aviation industry, including Airbus Final Assembly Line (FAL), VT Mobile Aerospace Engineering, Continental Motors, Segers Aero Corporation, Carlisle, Certified Aviation Services, and UTC Aerospace System.  These industries need trained, reliable employees to keep their operations moving, and our programs are designed to prepare you for these career opportunities.",  
              cardLink:"http://www.coastalalabama.edu/programs/alabama_aviation_center/"
            }
           ]
        ]
        },
        {
        tabName:'Career Resources',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
              [
                { 
                cardTitle:"ACRI/Go Build",
                cardImage:"go-build.jpg",
                cardText:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades.",  
                cardLink:"https://gobuildalabama.com/ "
                }
              ]        
            ]
        },            
  ]
    ,
    construction:[
        {
        tabName:'Pathway',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
          [
            {
            cardTitle:"Pathways",
            cardLink:"",
            cardImage:"construction-pathway.jpg",
            cardText:"pathways"
            }
          ],
        ]
        }, 
        {
        tabName:'Bishop State College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                cardTitle:"A/C Refrigeration Program",
                cardLink:"https://www.bishop.edu/programs/career-technical-education/a-c-refrigeration-technology",
                cardImage:"bishop-college-logo.jpg",
                cardText:"The Air Conditioning & Refrigeration Technology program prepares graduates for entry-level positions in the construction, installation, operation, and maintenance of climate control equipment as they learn basic principles and technical skills for the industry."
                }],
                [{
                cardTitle:"Electrical Technology Program",
                cardLink:"https://www.bishop.edu/programs/career-technical-education/electrical-technology",
                cardImage:"bishop-college-logo.jpg",
                cardText:"The Electrical Technology program is designed to provide the student with an opportunity to acquire the skills, knowledge, and understanding needed to enter the occupation as an entry-level electrician.",
                }],
                [{
                    cardTitle:"Welding Program",
                    cardLink:" https://www.bishop.edu/programs/career-technical-education/welding-technology",
                    cardImage:"bishop-college-logo.jpg",
                    cardText:"Welding is a skill used by many trades: sheet metal workers, iron workers, diesel mechanics, boilermakers, carpenters, marine construction, steamfitters, glaziers, repair and maintenance personnel in applications ranging from the sculpture home hobbyist to heavy fabrication of bridges, ships and many other projects.",
                    }
            ]]},
            {
                tabName:'Reid State College',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[

                    [{
                        cardTitle:"Industrial Electricity Program",
                        cardLink:"https://www.rstc.edu/industryelectric",
                        cardImage:"reid-college-logo.jpg",
                        cardText:"The associate degree and certificate programs are intended to produce graduates who are prepared for employment as entry-level industrial electricians, electronics technicians, or computer industry technicians.",
                        }],[{
                            cardTitle:"Industrial Maintenance Program",
                            cardLink:"https://www.rstc.edu/industrialmaintenance",
                            cardImage:"reid-college-logo.jpg",
                            cardText:"The associate degree and certificate programs are intended to produce graduates who are prepared for employment as entry-level industrial electricians, electronics technicians, or computer industry technicians.",
                            }],[{
                                cardTitle:"Welding Program",
                                cardLink:"https://www.rstc.edu/weldingtechnology",
                                cardImage:"reid-college-logo.jpg",
                                cardText:"The diploma and certificates programs are intended to produce graduates who are prepared for employment as entry-level structural and / or pipe welders.",
                                }
                ]],
        },
        {
        tabName:'Career Resources',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
              [
                { 
                cardTitle:"ACRI/Go Build",
                cardImage:"go-build.jpg",
                cardText:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades.",  
                cardLink:"https://gobuildalabama.com/ "
                }
              ]        
            ]
        },            
  ],
    energy:[
        {
        tabName:'Pathway',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
          [
            {
            cardTitle:"Pathways",
            cardLink:"",
            cardImage:"energy-pathway.jpg",
            cardText:"pathways"
            }
          ],
        ]
        }, 
        {
        tabName:'Bishop State College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                cardTitle:"Electrical Technology Program",
                cardLink:"https://www.bishop.edu/programs/career-technical-education/electrical-technology",
                cardImage:"bishop-college-logo.jpg",
                cardText:"The Electrical Technology program is designed to provide the student with an opportunity to acquire the skills, knowledge, and understanding needed to enter the occupation as an entry-level electrician."
                },
                {
                    cardTitle:"Lineworker Program",
                    cardLink:"https://www.bishop.edu/programs/workforce-development/line-worker-program",
                    cardImage:"bishop-college-logo.jpg",
                    cardText:"Alabama Power and Bishop State Community College are partnering to offer a training program to prepare students to work as electric utility lineworkers."
                    }
            ],
        ]
        },
        {
            tabName:'Reid State College',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                    cardTitle:"Industrial Electricity Program",
                    cardLink:"https://www.rstc.edu/industryelectric",
                    cardImage:"reid-college-logo.jpg",
                    cardText:"The associate degree and certificate programs are intended to produce graduates who are prepared for employment as entry-level industrial electricians, electronics technicians, or computer industry technicians."
                    }],[{
                    cardTitle:"Industrial Maintenance Program",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/electrical-technology",
                    cardImage:"reid-college-logo.jpg",
                    cardText:"The associate degree and certificate programs are intended to produce graduates who are prepared for employment as entry-level industrial electricians, electronics technicians, or computer industry technicians.",
                    }
                ],
            ]
            },
            {
                tabName:'University of South Alabama ',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                        cardTitle:"College of Engineering",
                        cardLink:"https://www.southalabama.edu/colleges/engineering/",
                        cardImage:"usa-logo.jpg",
                        cardText:"At the University of South Alabama, our College of Engineering is ready to help you prepare for a successful career."
                        }],
                ]
                },
        {
        tabName:'Career Resources',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
              [
                { 
                cardTitle:"ACRI/Go Build",
                cardImage:"go-build.jpg",
                cardText:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades.",  
                cardLink:"https://gobuildalabama.com/ "
                }
              ]        
            ]
        },            
  ],
    engineering_and_design:[
        {
        tabName:'Pathway',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
          [
            {
            cardTitle:"Pathways",
            cardLink:"",
            cardImage:"engineering-pathway.jpg",
            cardText:"pathways"
            }
          ],
        ]
        }, 
        {
        tabName:'Bishop State College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                cardImage:"bishop-college-logo.jpg",
                cardTitle:"Electronics Engineering Technology",
                cardText:"The Electronics Engineering Technology program is designed to prepare students for exciting, fast paced occupations.",
                cardLink:"https://www.bishop.edu/programs/career-technical-education/electronics-engineering-technology",
                },
                {
                    cardImage:"bishop-college-logo.jpg",
                    cardTitle:"Drafting & Design Technology",
                    cardText:"In the drafting and design technology program, students gain a thorough knowledge of technical drawing communication through the use of computer-aided drafting (CAD).",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/drafting-design-technology",
                    }
            ],
        ]
        },
        {
            tabName:'Coastal Alabama College',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                        cardImage:"cacc-logo.jpg",
                        cardTitle:"Mathematics & Engineering ",
                        cardText:"The College offers Associate of Science degrees in Mathematics and Engineering.",
                        cardLink:"http://www.coastalalabama.edu/engineering/",
                    }
                ],
            ]
        },
        {
            tabName:'University of South Alabama',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                        cardImage:"usa-main-logo.jpg",
                        cardTitle:"College of Engineering",
                        cardText:"At the University of South Alabama, our College of Engineering is ready to help you prepare for a successful career.",
                        cardLink:"https://www.southalabama.edu/colleges/engineering/",
                    }
                ],
            ]
        },
        {
            tabName:'Spring Hill College',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                        cardImage:"spring-hill-logo.jpg",
                        cardTitle:"Dual Degree Engineering",
                        cardText:"Designing aircraft, spacecraft, or missiles. Learn the science behind machines and the energy that makes them work. Be involved in energy exploration and tackle issues related to global warming.",
                        cardLink:"https://www.shc.edu/academics/freshmen-transfer/majors/dual-degree-engineering/",
                    }
                ],
            ]
        },
        {
        tabName:'Career Resources',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
              [
                { 
                cardTitle:"ACRI/Go Build",
                cardImage:"go-build.jpg",
                cardText:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades.",  
                cardLink:"https://gobuildalabama.com/ "
                }
              ]        
            ]
        },            
  ],
    healthcare:[
        {
            tabName:'Pathway',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
              [
                {
                cardTitle:"Pathways",
                cardLink:"",
                cardImage:"healthcare-pathway.jpg",
                cardText:"pathways"
                }
              ],
            ]
            },
            {
                    tabName:'University of Mobile',
                    media:{type:'image',link:"www.emp1.com"},
                    cardRows:[
                        [
                            {
                                cardImage:"mobile-university-logo.jpg",
                                cardTitle:"College of Health Professions",
                                cardText:"Healthcare is a demanding field, and there is a high demand for healthcare professionals in an industry that is one of the fastest growing in the nation. ",
                                cardLink:"https://umobile.edu/academics/college-of-health-professions/",
                            }
                        ],
                    ]
            },
            {
                tabName:'University of Mobile - Nursing',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"mobile-university-logo.jpg",
                            cardTitle:"Traditional Bachelor of Science in Nursing (TBSN)",
                            cardText:"Healthcare is a demanding field, and there is a high demand for healthcare professionals in an industry that is one of the fastest growing in the nation. ",
                            cardLink:"https://umobile.edu/academics/school-of-nursing/bsnursing/",
                        },
                        {
                            cardImage:"mobile-university-logo.jpg",
                            cardTitle:"RN to BSN (Adult)",
                            cardText:"Healthcare is a demanding field, and there is a high demand for healthcare professionals in an industry that is one of the fastest growing in the nation. ",
                            cardLink:"https://umobile.edu/academics/school-of-nursing/rn-to-bsn-adult/",
                        }
                    ],
                ]
                },
                {
                    tabName:'University of Mobile - Sports',
                    media:{type:'image',link:"www.emp1.com"},
                    cardRows:[
                        [
                            {
                                cardImage:"mobile-university-logo.jpg",
                                cardTitle:"Kinesiology",
                                cardText:"Healthcare is a demanding field, and there is a high demand for healthcare professionals in an industry that is one of the fastest growing in the nation. ",
                                cardLink:"https://umobile.edu/academics/school-health-sports-science/kinesiology/",
                            },
                            {
                                cardImage:"mobile-university-logo.jpg",
                                cardTitle:"Exercise Science",
                                cardText:"Healthcare is a demanding field, and there is a high demand for healthcare professionals in an industry that is one of the fastest growing in the nation. ",
                                cardLink:"https://umobile.edu/bachelor-of-science-in-exercise-science/",
                            },
                            {
                                cardImage:"mobile-university-logo.jpg",
                                cardTitle:"School of Health and Sports Science",
                                cardText:"Healthcare is a demanding field, and there is a high demand for healthcare professionals in an industry that is one of the fastest growing in the nation. ",
                                cardLink:"https://umobile.edu/academics/school-health-sports-science/",
                            }
                        ],
                    ]
            },
            {
                tabName:'Bishop State',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"bishop-logo.jpg",
                            cardTitle:"Physical Therapy Assistant",
                            cardText:"The Health Science Professions department offers a variety of health-related programs for students interested in helping, caring, & serving others. From an emergency medical technician to a certified nurse assistant to many other related areas, Health Science Professions has a program for you!",
                            cardLink:"https://www.bishop.edu/programs/health-science-professions/physical-therapist-assistant",
                        },
                        {
                            cardImage:"bishop-logo.jpg",
                            cardTitle:"Health Information Technology",
                            cardText:"The Health Science Professions department offers a variety of health-related programs for students interested in helping, caring, & serving others. From an emergency medical technician to a certified nurse assistant to many other related areas, Health Science Professions has a program for you!",
                            cardLink:"https://www.bishop.edu/programs/health-science-professions/health-information-technology",
                        },
                        {
                            cardImage:"bishop-logo.jpg",
                            cardTitle:"Medical Coding Short Certificate",
                            cardText:"The Health Science Professions department offers a variety of health-related programs for students interested in helping, caring, & serving others. From an emergency medical technician to a certified nurse assistant to many other related areas, Health Science Professions has a program for you!",
                            cardLink:"https://www.bishop.edu/programs/health-science-professions/medical-coding-short-certificate",
                        }
                    ],
                ]
            },
            {
                tabName:'Bishop State - Nursing',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"bishop-logo.jpg",
                            cardTitle:"Nursing",
                            cardText:"The Health Science Professions department offers a variety of health-related programs for students interested in helping, caring, & serving others. From an emergency medical technician to a certified nurse assistant to many other related areas, Health Science Professions has a program for you!",
                            cardLink:"https://www.bishop.edu/programs/health-science-professions/nursing",
                        }
                    ],
                ]
            },
            {
                tabName:'University of South Alabama',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"usa-main-logo.jpg",
                            cardTitle:"Professional Health Sciences - Healthcare Management (BS)",
                            cardText:"The Department of EMS Education prepares students for careers in Emergency Medical Services. To meet the expanding role of prehospital providers, the Department of EMS Education offers three certificate programs, EMT, AEMT and Paramedic, as well as a Bachelor of Science in Emergency Medical Services.",
                            cardLink:"https://www.southalabama.edu/bulletin/current/degrees-programs/emerg-medical-serv-training/healthcare-management-bs/index.html",
                        },
                        {
                            cardImage:"usa-main-logo.jpg",
                            cardTitle:"Emergency Medical Services",
                            cardText:"The Department of EMS Education prepares students for careers in Emergency Medical Services. To meet the expanding role of prehospital providers, the Department of EMS Education offers three certificate programs, EMT, AEMT and Paramedic, as well as a Bachelor of Science in Emergency Medical Services.",
                            cardLink:"https://www.southalabama.edu/bulletin/current/degrees-programs/emerg-medical-serv-training/emergency-medical-services-bs/index.html",
                        },
                        {
                            cardImage:"usa-main-logo.jpg",
                            cardTitle:"Exercise Science (BS) - Health and Fitness Concentration",
                            cardText:"The Department of Health, Kinesiology, and Sport offers state and nationally accredited undergraduate programs of study in Physical Education (P-12), Health Education (6-12), and a combined Health (6-12) and Physical Education (P-12) program that lead to Alabama Class B Educator Preparation and Certification. ",
                            cardLink:"https://www.southalabama.edu/bulletin/current/degrees-programs/health-kinesiology-sport/exercise-science-bs-health/index.html",
                        }
                    ],
                ]
            },
            {
                tabName:'University of South Alabama - Nursing',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"usa-main-logo.jpg",
                            cardTitle:"Nursing BSN",
                            cardText:"The College of Nursing is dedicated to the education of professional nurses who provide the highest quality health care to the communities they serve. The practice of nursing is both an art and a science; the focus of nursing is the diagnosis and treatment of human responses to actual or potential health problems.",
                            cardLink:"https://www.southalabama.edu/bulletin/current/degrees-programs/nursing/nursing-bs-rn-bsn/index.html",
                        },
                        {
                            cardImage:"usa-main-logo.jpg",
                            cardTitle:"Nursing (BS) - Accelerated",
                            cardText:"The College of Nursing is dedicated to the education of professional nurses who provide the highest quality health care to the communities they serve. The practice of nursing is both an art and a science; the focus of nursing is the diagnosis and treatment of human responses to actual or potential health problems.",
                            cardLink:"https://www.southalabama.edu/bulletin/current/degrees-programs/nursing/nursing-bs-accelerated/index.html",
                        },
                        {
                            cardImage:"usa-main-logo.jpg",
                            cardTitle:"Nursing (BSN) - Traditional",
                            cardText:"The College of Nursing is dedicated to the education of professional nurses who provide the highest quality health care to the communities they serve. The practice of nursing is both an art and a science; the focus of nursing is the diagnosis and treatment of human responses to actual or potential health problems.",
                            cardLink:"https://www.southalabama.edu/bulletin/current/degrees-programs/nursing/nursing-bs-traditional/index.html",
                        }
                    ],
                ]
            },
            {
                tabName:'Coastal Alabama',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"cacc-logo.jpg",
                            cardTitle:"EMS",
                            cardText:"To prepare competent entry-level Paramedics in the cognitive (Knowledge) psychomotor (skills), and Affective (behavior) learning domains with or without exit points at the Advanced Emergency Medical Technician; Emergency Medical Technician and/or Emergency Medical Responder levels.",
                            cardLink:"http://www.coastalalabama.edu/ems/",
                        },
                        {
                            cardImage:"cacc-logo.jpg",
                            cardTitle:"Surgical Technology",
                            cardText:"The mission of the Surgical Technology Department at the College is to develop competent and caring surgical technologists.  These individuals will be able to function as part of the surgical team in any surgical setting.",
                            cardLink:"http://www.coastalalabama.edu/surgtech/",
                        },
                        {
                            cardImage:"cacc-logo.jpg",
                            cardTitle:"Veterinary Technology",
                            cardText:"Have you always dreamed of a career helping animals? You can make that dream a reality through a career in Veterinary Technology. ",
                            cardLink:"http://www.coastalalabama.edu/vettech/",
                        }
                    ],
                ]
            },
            {
                tabName:'Coastal Alabama - Dental',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"cacc-logo.jpg",
                            cardTitle:"Dental Assisting",
                            cardText:"This program prepares individuals to assist with the care of dental patients under the direct supervision of a dentist.  Dental assistants help with the provision of patient care, office administration, and laboratory procedures. ",
                            cardLink:"http://www.coastalalabama.edu/dentalassisting/",
                        }
                    ],
                ]
            },
            {
                tabName:'Coastal Alabama - Nursing',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"cacc-logo.jpg",
                            cardTitle:"Nursing",
                            cardText:"Welcome To Coastal Alabama Nursing",
                            cardLink:"http://www.coastalalabama.edu/nursing/",
                        }
                    ],
                ]
            },
            {
                tabName:'Reid State',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"reid-state-logo.jpg",
                            cardTitle:"Health Careers",
                            cardText:"We are proud of our accomplishments in our Health Careers' Division and look forward to helping you achieve your desired goals. We offer several options in course sequencing to help you be successful. ",
                            cardLink:"https://www.rstc.edu/healthcareers",
                        },
                        {
                            cardImage:"reid-state-logo.jpg",
                            cardTitle:"Nursing Assistant Program",
                            cardText:"3 weeks for Training for Business Industry (TBI); 6 weeks of traditional learning; and this course is embedded in the Health Science Program (HPS) for one semester.",
                            cardLink:"https://www.rstc.edu/nursingassistant",
                        }
                    ],
                ]
            },
            {
                tabName:'Springhill College',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"springhill-logo.jpg",
                            cardTitle:"Nursing",
                            cardText:"Spring Hill College offers two curricula for students to pursue their Bachelor of Science in Nursing degree.",
                            cardLink:"https://www.shc.edu/academics/freshmen-transfer/majors/nursing/",
                        },
                        {
                            cardImage:"springhill-logo.jpg",
                            cardTitle:"Pre-Health",
                            cardText:"Interested in health and medicine? Gain the skills you need for to build a successful career as a physician, dentist, veterinarian, or pharmacist as a pre-health major at Spring Hill College.",
                            cardLink:"https://www.shc.edu/academics/freshmen-transfer/majors/pre-health/",
                        }
                    ],
                ]
            },
        ],
    information_technology:[
        {
            tabName:'Pathway',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
              [
                {
                cardTitle:"Pathways",
                cardLink:"",
                cardImage:"it-pathway.jpg",
                cardText:"pathways"
                }
              ],
            ]
            },
            {
                tabName:'University of South Alabama',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                  [
                    {
                    cardTitle:"Information Systems and Technology: Health Informatics",
                    cardLink:"https://www.southalabama.edu/colleges/soc/cist/hi-program-requirements.html",
                    cardImage:"usa-main-logo.jpg",
                    cardText:"In the Department of IST we provide a variety of paths to a computing career, and we invite you join us when choosing yours."
                    },
                    {
                        cardTitle:"Information Systems and Technology: Bachelor of Science in Information Systems (ISC)",
                        cardLink:"https://www.southalabama.edu/colleges/soc/cist/isc-program-requirements.html",
                        cardImage:"usa-main-logo.jpg",
                        cardText:"In the Department of IST we provide a variety of paths to a computing career, and we invite you join us when choosing yours."
                        },
                        {
                            cardTitle:"Information Systems and Technology (BS)",
                            cardLink:"https://www.southalabama.edu/bulletin/current/degrees-programs/information-systems-and-technology/information-technology-bs/index.html",
                            cardImage:"usa-main-logo.jpg",
                            cardText:"In the Department of IST we provide a variety of paths to a computing career, and we invite you join us when choosing yours."
                            }
                  ],
                ]
                },
                {
                    tabName:'University of Mobile',
                    media:{type:'image',link:"www.emp1.com"},
                    cardRows:[
                      [
                        {
                        cardTitle:"Computer Information Systems",
                        cardLink:"https://umobile.edu/academics/school-of-business/computer-information-systems/",
                        cardImage:"mobile-university-logo.jpg",
                        cardText:"Welcome to University of Mobile!"
                        }
                      ],
                    ]
                    },
                    {
                        tabName:'Coastal Alabama',
                        media:{type:'image',link:"www.emp1.com"},
                        cardRows:[
                          [
                            {
                            cardTitle:"Information Technology (USA only)",
                            cardLink:"https://catalog.coastalalabama.edu/information-technology-usa-only",
                            cardImage:"cacc-logo.jpg",
                            cardText:"Welcome to Coastal Alabama!"
                            }
                          ],
                        ]
                        },
    ],
    hospitality_and_tourism:[
        {
            tabName:'Pathway',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
              [
                {
                cardTitle:"Pathways",
                cardLink:"",
                cardImage:"tourism-pathway.jpg",
                cardText:"pathways"
                }
              ],
            ]
            },
            {
                tabName:'Bishop State',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                  [
                    {
                    cardTitle:"Culinary Arts",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/culinary-arts",
                    cardImage:"bishop-logo.jpg",
                    cardText:"The Culinary Arts program of Bishop State Community College provides organized, specialized learning experiences to prepare students for occupations within the food service industry."
                    }
                  ],
                ]
                },
                {
                    tabName:'Coastal Alabama',
                    media:{type:'image',link:"www.emp1.com"},
                    cardRows:[
                      [
                        {
                        cardTitle:"Hospitality and Culinary Management",
                        cardLink:"http://www.coastalalabama.edu/hospitalityandculinary/",
                        cardImage:"cacc-logo.jpg",
                        cardText:"Hospitality Management, Event Planning, Pastry/Baking and Culinary Programs of the Highest Standards"
                        }
                      ],
                    ]
                    },
                    {
                        tabName:'University of South Alabama',
                        media:{type:'image',link:"www.emp1.com"},
                        cardRows:[
                            [
                                {
                                    cardImage:"usa-main-logo.jpg",
                                    cardTitle:"Hospitality and Tourism Management (BS)",
                                    cardText:"Improve the well-being of people along the Gulf Coast by producing hospitality, tourism and ecotourism professionals who possess fiscal, social, environmental and professional competencies through education and training, job placement and partnerships.",
                                    cardLink:"https://www.southalabama.edu/bulletin/current/degrees-programs/hospitality-tourism-management/hospitality-tourism-management-bs/index.html",
                                }
                            ],
                        ]
                    },
    ],
    manufacturing:[
        {
            tabName:'Pathway',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
              [
                {
                cardTitle:"Pathways",
                cardLink:"",
                cardImage:"manufacturing-pathway.jpg",
                cardText:"pathways"
                }
              ],
            ]
            },
        {
        tabName:'Coastal Alabama College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardImage:"cacc-logo.jpg",
                    cardTitle:"Machine Tool Technology",
                    cardText:"Located on the Thomasville and Atmore Campuses, this program prepares students for a fast-paced, high-demand career in Machine Tool Technology.",
                    cardLink:"http://www.coastalalabama.edu/machine/",
                }
            ],
        ]
    },
    {
        tabName:'Reid State College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardImage:"reid-college-logo.jpg",
                    cardTitle:"Reid State Technical College",
                    cardText:"Reid State Technical College is a degree-granting, two-year institution that provides quality academic and technical education to students from diverse backgrounds and abilities.",
                    cardLink:"https://www.rstc.edu/",
                }
            ],
        ]
    },
    {
        tabName:'Bishop State College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardImage:"bishop-college-logo.jpg",
                    cardTitle:"Automotive Technology",
                    cardText:"The Automotive Technology program offers detailed modernized training for students to become qualified automotive service and maintenance technicians.",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/electronics-engineering-technology ",
                }],
                [{
                    cardImage:"bishop-college-logo.jpg",
                    cardTitle:"Diesel Technology",
                    cardText:"The Diesel Technology program provides training, knowledge and skills for those who desire to learn how to troubleshoot and repair truck and construction equipment systems.",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/diesel-technology",
                }],
                [{
                    cardImage:"bishop-college-logo.jpg",
                    cardTitle:"Process & Maintenance Technology",
                    cardText:"The Process and Maintenance Technology program prepares graduates for entry-level process and maintenance positions in steel and stainless steel manufacturing, chemical and other plant operation related industries.",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/process-maintenance-technology",
                }
            ],
        ]
    },
    {
        tabName:'Career Resources',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
              [
                { 
                cardTitle:"ACRI/Go Build",
                cardImage:"go-build.jpg",
                cardText:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades.",  
                cardLink:"https://gobuildalabama.com/ "
                }
              ]        
            ]
        },],
    public_service:[
        {
        tabName:'Pathway',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
          [
            {
            cardTitle:"Pathways",
            cardLink:"",
            cardImage:"public-service-pathway.jpg",
            cardText:"pathways"
            }
          ],
        ]
        },
        {
            tabName:'University of Mobile',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
              [
                {
                cardTitle:"University of Mobile",
                cardLink:"https://umobile.edu/academics/college-of-arts-and-sciences/criminology/",
                cardImage:"mobile-university-logo.jpg",
                cardText:"Welcome to University of Mobile!"
                }
              ],
            ]
            },
        {
            tabName:'University of South Alabama',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                        cardImage:"usa-main-logo.jpg",
                        cardTitle:"University of South Alabama",
                        cardText:"Undergraduate students at the University of South Alabama can choose to major in political science or criminal justice.",
                        cardLink:"https://www.southalabama.edu/colleges/artsandsci/pscj/",
                    }
                ],
            ]
        },
        {
            tabName:'Coastal Alabama College',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                        cardImage:"cacc-logo.jpg",
                        cardTitle:"Coastal Alabama Community College",
                        cardText:"Located on the Thomasville and Atmore Campuses, this program prepares students for a fast-paced, high-demand career in Machine Tool Technology.",
                        cardLink:"https://catalog.coastalalabama.edu/liberal-arts/criminal-justice-pathway",
                    }
                ],
            ]
        },
        {
            tabName:'Bishop State',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                        cardImage:"bishop-logo.jpg",
                        cardTitle:"Criminal Justice",
                        cardText:"Criminal justice is the system of law enforcement used within all stages of criminal proceedings involving the police, lawyers, courts, and corrections. ",
                        cardLink:"https://www.bishop.edu/news/criminal-justice-courses-at-bishop-state",
                    }
                ],
            ]
        },
    ],
    safety:[{
        tabName:'Pathway',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
          [
            {
            cardTitle:"Pathways",
            cardLink:"",
            cardImage:"safety-pathway.jpg",
            cardText:"pathways"
            }
          ],
        ]
        },
        {
            tabName:'Coastal Alabama College',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                        cardImage:"cacc-logo.jpg",
                        cardTitle:"Coastal Alabama Community College",
                        cardText:"Adult Education Classes We Offer at No Cost, Microsoft Office, Office Administration 1 and 2, ...",
                        cardLink:"http://www.coastalalabama.edu/adulteducation/adult_education_classes/",
                    }
                ],
            ]
        },
        {
            tabName:'University of Mobile',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
              [
                {
                cardTitle:"University of Mobile",
                cardLink:"https://umobile.edu/academics/personal-safety-and-security/",
                cardImage:"mobile-university-logo.jpg",
                cardText:"Welcome to University of Mobile!"
                }
              ],
            ]
            },
        {
            tabName:'Career Resources',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                  [
                    { 
                    cardTitle:"ACRI/Go Build",
                    cardImage:"go-build.jpg",
                    cardText:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades.",  
                    cardLink:"https://gobuildalabama.com/ "
                    }
                  ]        
                ]
            },],
    shipbuilding_and_repair:[

        {
            tabName:'Pathway',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
              [
                {
                cardTitle:"Pathways",
                cardLink:"",
                cardImage:"shipbuild-pathway.jpg",
                cardText:"pathways"
                }
              ],
            ]
            },
        {
        tabName:'Coastal Alabama College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardImage:"cacc-logo.jpg",
                    cardTitle:"Machine Tool Technology",
                    cardText:"Located on the Thomasville and Atmore Campuses, this program prepares students for a fast-paced, high-demand career in Machine Tool Technology.",
                    cardLink:"http://www.coastalalabama.edu/machine/",
                }
            ],
        ]
    },
    {
        tabName:'Bishop State College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardImage:"bishop-college-logo.jpg",
                    cardTitle:"Automotive Technology",
                    cardText:"The Automotive Technology program offers detailed modernized training for students to become qualified automotive service and maintenance technicians.",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/electronics-engineering-technology ",
                }],
                [{
                    cardImage:"bishop-college-logo.jpg",
                    cardTitle:"Diesel Technology",
                    cardText:"The Diesel Technology program provides training, knowledge and skills for those who desire to learn how to troubleshoot and repair truck and construction equipment systems.",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/diesel-technology",
                }],
               [{
                    cardImage:"bishop-college-logo.jpg",
                    cardTitle:"Process & Maintenance Technology",
                    cardText:"The Process and Maintenance Technology program prepares graduates for entry-level process and maintenance positions in steel and stainless steel manufacturing, chemical and other plant operation related industries.",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/process-maintenance-technology",
                }
            ],
        ]
    },
    {
        tabName:'Career Resources',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
              [
                { 
                cardTitle:"ACRI/Go Build",
                cardImage:"go-build.jpg",
                cardText:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades.",  
                cardLink:"https://gobuildalabama.com/ "
                }
              ]        
            ]
        },
        {
            tabName:'Career Training',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
                [
                    {
                        cardImage:"maritime-logo.jpg",
                        cardTitle:"AIDT Maritime Training Center",
                        cardText:"The AIDT Maritime Training Center is available to all maritime and industrial companies and offers instruction in industry standards, upgraded technologies, and custom training.",
                        cardLink:"https://maritime.aidt.edu/index.php/mtc-career-path",
                    }
                ],
            ]
        },
    ],
    transportation_distribution_and_logistics:[
        
        {
            tabName:'Pathway',
            media:{type:'image',link:"www.emp1.com"},
            cardRows:[
              [
                {
                cardTitle:"Pathways",
                cardLink:"",
                cardImage:"transport-pathway.jpg",
                cardText:"pathways"
                }
              ],
            ]
            },
            {
                tabName:'University of South Alabama',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"usa-main-logo.jpg",
                            cardTitle:"University of South Alabama",
                            cardText:"The Supply Chain and Logistics Management (SCLM) major is designed to equip students with the skill sets necessary for successful careers in supply chain-related fields. ",
                            cardLink:"https://www.southalabama.edu/colleges/mcob/supplychain/",
                        }
                    ],
                ]
            },
            {
                tabName:'Coastal Alabama College',
                media:{type:'image',link:"www.emp1.com"},
                cardRows:[
                    [
                        {
                            cardImage:"cacc-logo.jpg",
                            cardTitle:"Coastal Alabama Community College",
                            cardText:"Adult Education Classes We Offer at No Cost, Microsoft Office, Office Administration 1 and 2, ...",
                            cardLink:"http://www.coastalalabama.edu/adulteducation/adult_education_classes/",
                        }
                    ],
                ]
            },
        {
        tabName:'Reid State College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardImage:"reid-college-logo.jpg",
                    cardTitle:"Commercial Truck Driving",
                    cardText:"The Commercial Truck Driving program provides the knowledge to work in a high paying industry, in a short period of time. It will provide accessible, quality educational opportunities that will provide individuals with the knowledge, technical skills, and attitudes necessary to obtain entry-level employment in the commercial truck driving profession.",
                    cardLink:"https://www.rstc.edu/comtruckdriving",
                }
            ],
        ]
    },
    {
        tabName:'Bishop State College',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
            [
                {
                    cardImage:"bishop-college-logo.jpg",
                    cardTitle:"Truck Driving Program",
                    cardText:"Bishop State Community College’s Commercial Vehicle Driving Course is an eight-week program that will help you develop the necessary skills needed to obtain a CDL Class A license.",
                    cardLink:"https://www.bishop.edu/programs/career-technical-education/truck-driving ",
                }
            ],
        ]
    },
    {
        tabName:'Career Resources',
        media:{type:'image',link:"www.emp1.com"},
        cardRows:[
              [
                { 
                cardTitle:"ACRI/Go Build",
                cardImage:"go-build.jpg",
                cardText:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades.",  
                cardLink:"https://gobuildalabama.com/ "
                }
              ]        
            ]
        },
    ],
   
}


  