var careersInfoMixin  = {
    methods: {
      selectJobPageInfo: function (regionName,worldName) {
        var worldsArrays={
            SAWDC:SAWDC_JobPage_Object,
            CAW:CAW_Quiz_Object,
            WAW:WAW_Quiz_Object,
            NAW:NAW_Quiz_Object,
            EAW:EAW_Quiz_Object,
            NCTEJW:NCTEJW_Quiz_Object
  
          }
          //console.log(worldsArrays[regionName][worldName])
          return worldsArrays[regionName][worldName]
      }
    }
  };
  export default careersInfoMixin
  /*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var SAWDC_JobPage_Object={ 
    aerospace_and_aviation:{
        mediaInfo:{
          videoType:'',link:"aerospace-careers",videoId:'', imageOrVideo:'img',srcOrHref:'aerospace-careers'
        },
        jobs:[ 
            {
              jobtitle:'Aerospace Engineering & Operations Technicians',
              salary:"$36,760 – $96,020",
              description:"Operate, install, adjust, and maintain integrated computer/communications systems, consoles, simulators, and other data acquisition, test, and measurement instruments and equipment, which are used to launch, track, position, and evaluate air and space vehicles. May record and interpret test data.", 
              jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'1eOnm-61cDE',srcOrHref:'https://youtu.be/1eOnm-61cDE'},
              education:"Post-secondary certificate",
              id_gamification:"aaa-1",
              communicationbuttons:[
                     {
                      title:"Quality Control",
                      innerText:"Test aircraft systems under simulated operational conditions, performing systems readiness tests"
                     },
                     {
                      title:"Analysis",
                      innerText:"Identify required data, data acquisition plans, and test parameters, setting up equipment to conform to these specifications."
                     },
                    {
                      title:"Teamwork",
                      innerText:"Confer with engineering personnel regarding details and implications of test procedures and results."
                     },
                     {
                      title:"Maintenance",
                      innerText:"Adjust, repair, or replace faulty components of test setups and equipment."
                     },
                     {
                      title:"Tool Usage",
                      innerText:"Fabricate and install parts and systems to be tested in test equipment, using hand tools, power tools, and test instruments."
                     },
                     {
                      title:"Systems Design",
                      innerText:"Design electrical and mechanical systems for avionic instrumentation applications."
                     },
              ]
          },
                    {



              jobtitle:'Avionics Mechanic',
              salary:"$55,200",
              description:"Install, inspect, test, adjust, or repair avionics equipment, such as radar, radio, navigation, and missile control systems in aircraft or space vehicles.", 
              jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'oByvKU2CwHs',srcOrHref:'https://youtu.be/oByvKU2CwHs'},
              education:"On-The Job Training/Technical Training",
              id_gamification:"aaa-2",
              communicationbuttons:[
                     {
                      title:"Quality Control",
                      innerText:"Test and troubleshoot instruments, components, and assemblies, using circuit testers, oscilloscopes, or voltmeters."
                     },
                     {
                      title:"Maintenance",
                      innerText:"Adjust, repair, or replace malfunctioning components or assemblies, using hand tools or soldering irons."
                     },
                     {
                      title:"Tool Usage",
                      innerText:"Install electrical and electronic components, assemblies, and systems in aircraft, using hand tools, power tools, or soldering irons."
                     },
                     {
                      title:"Analysis",
                      innerText:"Interpret flight test data to diagnose malfunctions and systemic performance problems."
                     },
                     {
                      title:"Teamwork",
                      innerText:"Coordinate work with that of engineers, technicians, and other aircraft maintenance personnel"                     },
                     {
                      title:"Prototyping",
                      innerText:"Assemble prototypes or models of circuits, instruments, and systems for use in testing."
                     },
              ]
          },
          {


              jobtitle:"A&P Mechanic",
              description:"Airframe and Powerplant Mechanic, Diagnose, adjust, repair, or overhaul aircraft engines and assemblies, such as hydraulic and pneumatic systems.",
              salary:"$55,900",
              education: "On-The Job Training/Technical Training",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/ncXP05WGGCM",videoId:"ncXP05WGGCM"},
              id_gamification:"aaa-3",
              communicationbuttons:[
                     {
                      title:"Quality Control",
                      innerText:"Examine and inspect aircraft components, including landing gear, hydraulic systems, and deicers to locate cracks, breaks, leaks, or other problems."                     
                      },
                     {
                      title:"Analysis",
                      innerText:"Read and interpret maintenance manuals, service bulletins, and other specifications to determine the feasibility and method of repairing or replacing malfunctioning or damaged components."
                     },
                     {
                      title:"Tool Usage",
                      innerText:"Assemble and install electrical, plumbing, mechanical, hydraulic, and structural components and accessories, using hand or power tools."
                     },
                     {
                      title:"Teamwork ",
                      innerText:"Communicate with other workers to coordinate fitting and alignment of heavy parts, or to facilitate processing of repair parts."
                     },
                     {
                      title:"Multitask",
                      innerText:" Examine engines through specially designed openings while working from ladders or scaffolds, or use hoists or lifts to remove the entire engine from an aircraft."                    },
                     {
                      title:"Troubleshoot",
                      innerText:"Listen to operating engines to detect and diagnose malfunctions, such as sticking or burned valves."
                     },
              ]
          },
          {


            jobtitle:"Aerospace Engineer",
            description:"Perform engineering duties in designing, constructing, and testing aircraft, missiles, and spacecraft. May conduct basic and applied research to evaluate adaptability of materials and equipment to aircraft design and manufacture. May recommend improvements in testing equipment and techniques.",
            salary:"$117,200",
            education: "",
            jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/bnxcib-8S4s",videoId:"bnxcib-8S4s"},
            id_gamification:"aaa-4",
            communicationbuttons:[
                   {
                    title:"Mathematics",
                    innerText:"Formulate mathematical models or other methods of computer analysis to develop, evaluate, or modify designs."                     
                    },
                   {
                    title:"System Design",
                    innerText:"Design or engineer filtration systems that reduce harmful emissions."
                   },
                   {
                    title:"Fabricate",
                    innerText:"Fabricate defective sections or parts, using metal fabricating machines, saws, brakes, shears, and grinders."
                   },
                   {
                    title:"Organized",
                    innerText:"Maintain records of performance reports for future reference."
                   },
                   {
                    title:"Leadership",
                    innerText:"Direct aerospace research and development programs."                    },
                   {
                    title:"Research",
                    innerText:"Research new materials to determine quality or conformance to environmental standards."
                   },
            ]
        },
        {


          jobtitle:"A&P Technician",
          description:"Assemble, fit, fasten, and install parts of airplanes, space vehicles, or missiles, such as tails, wings, fuselage, bulkheads, stabilizers, landing gear, rigging and control equipment, or heating and ventilating systems.",
          salary:"$55,100",
          education: "",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/TSn6ghApPKM",videoId:"TSn6ghApPKM"},
          id_gamification:"aaa-5",
          communicationbuttons:[
                 {
                  title:"Analysis",
                  innerText:"Read blueprints, illustrations, or specifications to determine layouts, sequences of operations, or identities or relationships of parts."                     
                  },
                 {
                  title:"Maintain",
                  innerText:"Adjust, repair, rework, or replace parts or assemblies to ensure proper operation."
                 },
                 {
                  title:"Fabricate",
                  innerText:"Fabricate parts needed for assembly or installation, using shop machinery or equipment."
                 },
                 {
                  title:"Tool Usage ",
                  innerText:"Attach brackets, hinges, or clips to secure or support components or subassemblies, using bolts, screws, rivets, chemical bonding, or welding."
                 },
                 {
                  title:"Welding",
                  innerText:"Weld tubing and fittings or solder cable ends, using tack welders, induction brazing chambers, or other equipment."                    },
                 {
                  title:"Operate Machines",
                  innerText:"Set up or operate machines or systems to crimp, cut, bend, form, swage, flare, bead, burr, or straighten tubing, according to specifications."
                 },
          ]
      },
      {


        jobtitle:"Shipping and Receiving Handler",
        description:"Manually move freight, stock, luggage, or other materials, or perform other general labor. Includes all manual laborers not elsewhere classified.",
        salary:"$31,000",
        education: "",
        jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/wQr_uQMpmEM",videoId:" wQr_uQMpmEM"},
        id_gamification:"aaa-6",
        communicationbuttons:[
               {
                title:"Detailed",
                innerText:"Attach identifying tags to containers or mark them with identifying information."                     
                },
                {
                  title:"Task Oriented",
                  innerText:"Read work orders or receive oral instructions to determine work assignments or material or equipment needs."                     
                  },{
                    title:"Physical Labor",
                    innerText:"Stack cargo in locations, such as transit sheds or in holds of ships as directed, using pallets or cargo boards."                     
                    },{
                      title:"Safety",
                      innerText:"Guide loads being lifted to prevent swinging."                     
                      },{
                        title:"Tool Usage",
                        innerText:"Rig or dismantle props or equipment, such as frames, scaffolding, platforms, or backdrops, using hand tools."                     
                        },{
                          title:"Organized",
                          innerText:"Maintain equipment storage areas to ensure that inventory is protected."                     
                          },
        ]
    },
    {


      jobtitle:"Quality Control Inspector",
      description:"Inspect, test, sort, sample, or weigh nonagricultural raw materials or processed, machined, fabricated, or assembled parts or products for defects, wear, and deviations from specifications. May use precision measuring instruments and complex test equipment.",
      salary:"$62,200",
      education: "",
      jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/RCTxteXoiAA",videoId:"RCTxteXoiAA"},
      id_gamification:"aaa-7",
      communicationbuttons:[
             {
              title:"Analysis",
              innerText:"Mark items with details, such as grade or acceptance-rejection status."                     
              },
             {
              title:"Communicate",
              innerText:"Notify supervisors or other personnel of production problems."
             },
             {
              title:"Physical Labor",
              innerText:"Make minor adjustments to equipment, such as turning setscrews to calibrate instruments to required tolerances."
             },
             {
              title:"Solution Focused",
              innerText:"Recommend necessary corrective actions, based on inspection results."
             },
             {
              title:"Organized",
              innerText:"Write test or inspection reports describing results, recommendations, or needed repairs."                    },
             {
              title:"Detailed",
              innerText:"Analyze test data, making computations as necessary, to determine test results."
             },
      ]
  },
  {


    jobtitle:"Industrial Engineers",
    description:"Design, develop, test, and evaluate integrated systems for managing industrial production processes, including human work factors, quality control, inventory control, logistics and material flow, cost analysis, and production coordination.",
    salary:"$95,400",
    education: "",
    jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/kNXMJBQ6oL4",videoId:"kNXMJBQ6oL4"},
    id_gamification:"aaa-8",
    communicationbuttons:[
           {
            title:"Analysis",
            innerText:"Analyze statistical data and product specifications to determine standards and establish quality and reliability objectives of finished product."                     
            },
           {
            title:"Communicate",
            innerText:"Communicate with management and user personnel to develop production and design standards."
           },
           {
            title:"Solution Focused",
            innerText:"Evaluate precision and accuracy of production and testing equipment and engineering drawings to formulate corrective action plan."
           },
           {
            title:"Organized",
            innerText:"Complete production reports, purchase orders, and material, tool, and equipment lists."
           },
           {
            title:"Mathematics",
            innerText:"Apply statistical methods and perform mathematical calculations to determine manufacturing processes, staff requirements, and production standards."                    },
           {
            title:"System Design",
            innerText:"Formulate sampling procedures and designs and develop forms and instructions for recording, evaluating, and reporting quality and reliability data."
           },
    ]
},
{


  jobtitle:"Software QA Analysts and Testers",
  description:"Develop and execute software tests to identify software problems and their causes. Test system modifications to prepare for implementation. Document software and application defects using a bug tracking system and report defects to software or web developers. Create and maintain databases of known defects. May participate in software design reviews to provide input on functional requirements, operational characteristics, product designs, and schedules.",
  salary:"$96,400",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/Xr-EoC9DHyc",videoId:"Xr-EoC9DHyc"},
  id_gamification:"aaa-9",
  communicationbuttons:[
         {
          title:"Analysis",
          innerText:"Identify, analyze, and document problems with program function, output, online screen, or content."                     
          },
         {
          title:"Communicate",
          innerText:"Participate in product design reviews to provide input on functional requirements, product designs, schedules, or potential problems."
         },
         {
          title:"Solution Focused",
          innerText:"Provide feedback and recommendations to developers on software usability and functionality."
         },
         {
          title:"Organized",
          innerText:"Document software defects, using a bug tracking system, and report defects to software developers."
         },
         {
          title:"System Design",
          innerText:"Design test plans, scenarios, scripts, or procedures."                    },
         {
          title:"User Testing",
          innerText:"Coordinate user or third-party testing."
         },
  ]
},
{


  jobtitle:"Commercial Pilots",
  description:"Pilot and navigate the flight of fixed-wing aircraft on nonscheduled air carrier routes, or helicopters. Requires Commercial Pilot certificate. Includes charter pilots with similar certification, and air ambulance and air tour pilots. Excludes regional, national, and international airline pilots.",
  salary:"$80,500",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/oey4mi_QV48",videoId:"oey4mi_QV48"},
  id_gamification:"aaa-10",
  communicationbuttons:[
         {
          title:"Communicate",
          innerText:"Contact control towers for takeoff clearances, arrival instructions, and other information, using radio equipment."                     
          },
         {
          title:"Analysis",
          innerText:"Choose routes, altitudes, and speeds that will provide the fastest, safest, and smoothest flights."
         },
         {
          title:"Safety",
          innerText:"Order changes in fuel supplies, loads, routes, or schedules to ensure safety of flights."
         },
         {
          title:"Supervise",
          innerText:"Supervise other crew members."
         },
         {
          title:"Teaching",
          innerText:"Instruct other pilots and student pilots in aircraft operations."                    },
         {
          title:"Monitoring",
          innerText:"Monitor engine operation, fuel consumption, and functioning of aircraft systems during flights."
         },
  ]
},
{


  jobtitle:"Machinists",
  description:"Set up and operate a variety of machine tools to produce precision parts and instruments out of metal. Includes precision instrument makers who fabricate, modify, or repair mechanical instruments. May also fabricate and modify parts to make or repair machine tools or maintain industrial machines, applying knowledge of mechanics, mathematics, metal properties, layout, and machining procedures.",
  salary:"$54,500",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/OWNXI09WI14",videoId:"OWNXI09WI14"},
  id_gamification:"aaa-11",
  communicationbuttons:[
         {
          title:"Detailed",
          innerText:"Calculate dimensions or tolerances, using instruments, such as micrometers or vernier calipers."                     
          },
         {
          title:"Machine Usage",
          innerText:"Machine parts to specifications, using machine tools, such as lathes, milling machines, shapers, or grinders."
         },
         {
          title:"Communicate",
          innerText:"Confer with engineering, supervisory, or manufacturing personnel to exchange technical information."
         },
         {
          title:"Analysis",
          innerText:"Evaluate machining procedures and recommend changes or modifications for improved efficiency or adaptability."
         },
         {
          title:"Artistic",
          innerText:"Prepare working sketches for the illustration of product appearance."                    },
         {
          title:"Monitoring",
          innerText:"Monitor the feed and speed of machines during the machining process."
         },
  ]
}

         ],
     
        description:{
          aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
        }
    },
    construction:{
      mediaInfo:{
        videoType:'youtube',link:"https://www.youtube.com/watch?v=cUIAZcjjB_g&list=TLPQMjQwMzIwMjHqCZpyRvoORg",videoId:'pH5PpKnaRk4',imageOrVideo:'video',srcOrHref:'aerospace-careers'
      },
        jobs:[ 
            {
              jobtitle:'Electrician',
              salary:"$47,500",
              description:"Install, maintain, and repair electrical wiring, equipment, and fixtures. Ensure that work is in accordance with relevant codes. May install or service street lights, intercom systems, or electrical control systems.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'l6IWPbx4ZXw',
              srcOrHref:''},
              education:"",
              id_gamification:"construc-1",
              communicationbuttons:[
                     {
                      title:"Critical Analysis",
                      innerText:"Plan layout and installation of electrical wiring, equipment, or fixtures, based on job specifications and local codes."
                     },
                     {
                      title:"Design",
                      innerText:"Prepare sketches or follow blueprints to determine the location of wiring or equipment and to ensure conformance to building and safety codes."
                     },
                    {
                      title:"Troubleshoot",
                      innerText:"Diagnose malfunctioning systems, apparatus, or components, using test equipment and hand tools to locate the cause of a breakdown and correct the problem."
                     },
                     {
                      title:"Instructing",
                      innerText:"Advise management on whether continued operation of equipment could be hazardous."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Inspect electrical systems, equipment, or components to identify hazards, defects, or the need for adjustment or repair, and to ensure compliance with codes."
                     },
                     {
                      title:"Hand Tools",
                      innerText:"Repair or replace wiring, equipment, or fixtures, using hand tools or power tools"

                     },
              ]
          },
                    {



              jobtitle:'Heating/Air/Refrigeration Mechanics',
              salary:"$40,900",
              description:"Install or repair heating, central air conditioning, HVAC, or refrigeration systems, including oil burners, hot-air furnaces, and heating stoves.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'PNcQwekI2KQ',
              srcOrHref:''},
              education:"",
              id_gamification:"construc-2",
              communicationbuttons:[
                     {
                      title:"Electrical",
                      innerText:"Test electrical circuits or components for continuity, using electrical test equipment."
                     },
                     {
                      title:"Repair",
                      innerText:"Repair or replace defective equipment, components, or wiring."
                     },
                     {
                      title:"Customer Service",
                      innerText:"Discuss heating or cooling system malfunctions with users to isolate problems or to verify that repairs corrected malfunctions."
                     },
                     {
                      title:"Safety",
                      innerText:"Comply with all applicable standards, policies, or procedures, such as safety procedures or the maintenance of a clean work area."
                     },
                     {
                      title:"Organization",
                      innerText:"Keep records of repairs and replacements made and causes of malfunctions."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Inspect and test systems to verify system compliance with plans and specifications or to detect and locate malfunctions."
                     },
              ]
          },
          {


              jobtitle:"Ironworker",
              description:"Raise, place, and unite iron or steel girders, columns, and other structural members to form completed structures or structural frameworks. May erect metal storage tanks and assemble prefabricated metal buildings.",
              salary:"$32,000 - $63,000",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"UCtNJp6sd8I"},
              id_gamification:"construc-3",
              communicationbuttons:[
                     {
                      title:"Analysis",
                      innerText:"Read specifications or blueprints to determine the locations, quantities, or sizes of materials required."
                      },
                     {
                      title:"Reading",
                      innerText:"Connect columns, beams, and girders with bolts, following blueprints and instructions from supervisors"
                     },
                     {
                      title:"Hand Tools",
                      innerText:"Force structural steel members into final positions, using turnbuckles, crowbars, jacks, or hand tools."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Verify vertical and horizontal alignment of structural steel members, using plumb bobs, laser equipment, transits, or levels."
                     },
                     {
                      title:"Precision",
                      innerText:"Fabricate metal parts, such as steel frames, columns, beams, or girders, according to blueprints or instructions from supervisors."
                     },
                     {
                      title:"Welding",
                      innerText:"Cut, bend, or weld steel pieces, using metal shears, torches, or welding equipment."
                     },
              ]
          },
          {
      
      
            jobtitle:"Plumbers, Pipefitters, and Steamfitters",
            description:"Assemble, install, alter, and repair pipelines or pipe systems that carry water, steam, air, or other liquids or gases. May install heating and cooling equipment and mechanical control systems. Includes sprinkler fitters.",
            salary:"$47,200",
            education: "",
            jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/uF3-2wFnf0E",videoId:"uF3-2wFnf0E"},
            id_gamification:"construc-4",
            communicationbuttons:[
                   {
                    title:"Hand Tools",
                    innerText:"Cut, thread, or hammer pipes to specifications, using tools such as saws, cutting torches, pipe threaders, or pipe benders."                     
                    },
                   {
                    title:"Organized",
                    innerText:"Lay out full scale drawings of pipe systems, supports, or related equipment, according to blueprints."
                   },
                   {
                    title:"Analysis",
                    innerText:"Inspect, examine, or test installed systems or pipe lines, using pressure gauge, hydrostatic testing, observation, or other methods."
                   },
                   {
                    title:"Supervise",
                    innerText:"Direct helpers engaged in pipe cutting, preassembly, or installation of plumbing systems or components."
                   },
                   {
                    title:"Welding",
                    innerText:"Weld small pipes or special piping, using specialized techniques, equipment, or materials, such as computer-assisted welding or microchip fabrication."                    },
                   {
                    title:"Organized",
                    innerText:"Estimate time, material, or labor costs for use in project plans."
                   },
            ]
        },
        {
    
    
          jobtitle:"Construction Laborers",
          description:"Perform tasks involving physical labor at construction sites. May operate hand and power tools of all types: air hammers, earth tampers, cement mixers, small mechanical hoists, surveying and measuring equipment, and a variety of other equipment and instruments. May clean and prepare sites, dig trenches, set braces to support the sides of excavations, erect scaffolding, and clean up rubble, debris, and other waste materials. May assist other craft workers.",
          salary:"$32,900",
          education: "",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/s1n6nBhVzdo",videoId:"s1n6nBhVzdo"},
          id_gamification:"construc-5",
          communicationbuttons:[
                 {
                  title:"Physical Labor",
                  innerText:"Dig ditches or trenches, backfill excavations, or compact and level earth to grade specifications, using picks, shovels, pneumatic tampers, or rakes."                     
                  },
                 {
                  title:"Safety",
                  innerText:"Clean or prepare construction sites to eliminate possible hazards."
                 },
                 {
                  title:"Maintenance",
                  innerText:"Lubricate, clean, or repair machinery, equipment, or tools."
                 },
                 {
                  title:"Cleanliness",
                  innerText:"Clean or prepare construction sites to eliminate possible hazards."
                 },
                 {
                  title:"Concrete Work ",
                  innerText:"Smooth or finish freshly poured cement or concrete, using floats, trowels, screeds, or powered cement finishing tools."                    },
                 {
                  title:"Teamwork",
                  innerText:"Provide assistance to craft workers, such as carpenters, plasterers, or masons."
                 },
          ]
      },
      {
  
  
        jobtitle:"Carpenters",
        description:"Construct, erect, install, or repair structures and fixtures made of wood and comparable materials, such as concrete forms; building frameworks, including partitions, joists, studding, and rafters; and wood stairways, window and door frames, and hardwood floors. May also install cabinets, siding, drywall, and batt or roll insulation. Includes brattice builders who build doors or brattices (ventilation walls or partitions) in underground passageways.",
        salary:"$41,000",
        education: "",
        jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/i-tiC2Y-038",videoId:"i-tiC2Y-038"},
        id_gamification:"construc-6",
        communicationbuttons:[
               {
                title:"Safety",
                innerText:"Follow established safety rules and regulations and maintain a safe and clean environment."                     
                },
               {
                title:"Tool Usage",
                innerText:"Shape or cut materials to specified measurements, using hand tools, machines, or power saws."
               },
               {
                title:"Detailed",
                innerText:"Measure and mark cutting lines on materials, using a ruler, pencil, chalk, and marking gauge."
               },
               {
                title:"Communicate",
                innerText:"Arrange for subcontractors to deal with special areas, such as heating or electrical wiring work."
               },
               {
                title:"Data Entry",
                innerText:"Maintain records, document actions, and present written progress reports."                    },
               {
                title:"Finishing",
                innerText:"Finish surfaces of woodwork or wallboard in houses or buildings, using paint, hand tools, or paneling."
               },
        ]
    },
    {


      jobtitle:"Construction Supervisor",
      description:"Directly supervise and coordinate activities of construction or extraction workers.",
      salary:"$59,700",
      education: "",
      jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/B7YiI3UkxFw",videoId:"B7YiI3UkxFw"},
      id_gamification:"construc-7",
      communicationbuttons:[
             {
              title:"Analysis",
              innerText:"Inspect work progress, equipment, or construction sites to verify safety or to ensure that specifications are met."                     
              },
             {
              title:"Supervise",
              innerText:"Supervise, coordinate, or schedule the activities of construction or extractive workers."
             },
             {
              title:"Planning",
              innerText:"Estimate material or worker requirements to complete jobs."
             },
             {
              title:"Communicate",
              innerText:"Confer with managerial or technical personnel, other departments, or contractors to resolve problems or to coordinate activities."
             },
             {
              title:"Teaching",
              innerText:"Train workers in construction methods, operation of equipment, safety procedures, or company policies."                    },
             {
              title:"Data Entry",
              innerText:"Record information, such as personnel, production, or operational data on specified forms or reports."
             },
      ]
  },
  {


    jobtitle:"Construction Managers",
    description:"Plan, direct, or coordinate, usually through subordinate supervisory personnel, activities concerned with the construction and maintenance of structures, facilities, and systems. Participate in the conceptual development of a construction project and oversee its organization, scheduling, budgeting, and implementation. Includes managers in specialized construction fields, such as carpentry or plumbing.",
    salary:"$94,500",
    education: "",
    jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/SiamJadljrw",videoId:"SiamJadljrw"},
    id_gamification:"construc-8",
    communicationbuttons:[
           {
            title:"Project Management",
            innerText:"Plan, schedule, or coordinate construction project activities to meet deadlines."                     
            },
           {
            title:"Communicate",
            innerText:"Interpret and explain plans and contract terms to representatives of the owner or developer, including administrative staff, workers, or clients."
           },
           {
            title:"Supervise",
            innerText:"Direct and supervise construction or related workers."
           },
           {
            title:"Business",
            innerText:"Prepare contracts or negotiate revisions to contractual agreements with architects, consultants, clients, suppliers, or subcontractors."
           },
           {
            title:"Safety",
            innerText:"Investigate damage, accidents, or delays at construction sites to ensure that proper construction procedures are being followed."                    },
           {
            title:"Analysis",
            innerText:"Study job specifications to determine appropriate construction methods."
           },
    ]
},
{


  jobtitle:"Painter",
  description:"Paint walls, equipment, buildings, bridges, and other structural surfaces, using brushes, rollers, and spray guns. May remove old paint to prepare surface prior to painting. May mix colors or oils to obtain desired color or consistency.",
  salary:"$35,700",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/MhpyAMBkwIQ",videoId:"MhpyAMBkwIQ"},
  id_gamification:"construc-9",
  communicationbuttons:[
         {
          title:"Detailed",
          innerText:"Fill cracks, holes, or joints with caulk, putty, plaster, or other fillers, using caulking guns or putty knives."                     
          },
         {
          title:"Cautious",
          innerText:"Cover surfaces with dropcloths or masking tape and paper to protect surfaces during painting."
         },
         {
          title:"Finishing",
          innerText:"Smooth surfaces, using sandpaper, scrapers, brushes, steel wool, or sanding machines."
         },
         {
          title:"Painting",
          innerText:"Apply primers or sealers to prepare new surfaces, such as bare wood or metal, for finish coats."
         },
         {
          title:"Physical Labor",
          innerText:"Erect scaffolding or swing gates, or set up ladders, to work above ground level."                    },
         {
          title:"Artistic",
          innerText:"Mix and match colors of paint, stain, or varnish with oil or thinning and drying additives to obtain desired colors and consistencies."
         },
  ]
},
{


  jobtitle:"Equipment Operator",
  description:"Operate one or several types of power construction equipment, such as motor graders, bulldozers, scrapers, compressors, pumps, derricks, shovels, tractors, or front-end loaders to excavate, move, and grade earth, erect structures, or pour concrete or other hard surface pavement. May repair and maintain equipment in addition to other duties.",
  salary:"$40,900",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/_zm6Wud8hp4",videoId:"_zm6Wud8hp4"},
  id_gamification:"construc-10",
  communicationbuttons:[
         {
          title:"Communicate",
          innerText:"Coordinate machine actions with other activities, positioning or moving loads in response to hand or audio signals from crew members."                     
          },
         {
          title:"Cautious",
          innerText:"Take actions to avoid potential hazards or obstructions, such as utility lines, other equipment, other workers, or falling objects."
         },
         {
          title:"Safety",
          innerText:"Locate underground services, such as pipes or wires, prior to beginning work."
         },
         {
          title:"Physical Labor",
          innerText:"Repair and maintain equipment, making emergency adjustments or assisting with major repairs as necessary."
         },
         {
          title:"Organized",
          innerText:"Keep records of material or equipment usage or problems encountered."                    },
         {
          title:"Operate Machinery",
          innerText:"Load and move dirt, rocks, equipment, or other materials, using trucks, crawler tractors, power cranes, shovels, graders, or related equipment."
         },
  ]
},
{


  jobtitle:"Administrative Assistant",
  description:"Perform routine administrative functions such as drafting correspondence, scheduling appointments, organizing and maintaining paper and electronic files, or providing information to callers.",
  salary:"$35,900",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/zDqK9DaCDg4",videoId:"zDqK9DaCDg4"},
  id_gamification:"construc-11",
  communicationbuttons:[
         {
          title:"Communicate",
          innerText:"Answer telephones and give information to callers, take messages, or transfer calls to appropriate individuals."                     
          },
         {
          title:"People Skills",
          innerText:"Greet visitors or callers and handle their inquiries or direct them to the appropriate persons according to their needs."
         },
         {
          title:"Data Entry",
          innerText:"Create, maintain, and enter information into databases."
         },
         {
          title:"Computer Usage",
          innerText:"Use computers for various applications, such as database management or word processing."
         },
         {
          title:"Organized",
          innerText:"Establish work procedures or schedules and keep track of the daily work of clerical staff."                    },
         {
          title:"Supervise",
          innerText:"Supervise other clerical staff and provide training and orientation to new staff."
         },
  ]
}

         ],
     
        description:{
          aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
        }
     
    },
    energy:{
      mediaInfo:{
        videoType:'youtube',link:"",videoId:'En9Bjtvq-48',imageOrVideo:'image',srcOrHref:'aerospace-careers'
      },
        jobs:[ 
            {
              jobtitle:'Electrical Power Line Installers',
              salary:"$76,000",
              description:'Install or repair cables or wires used in electrical power or distribution systems. May erect poles and light or heavy duty transmission towers',
            
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'qJbC9pYtLK4',
              srcOrHref:''},
              education:"",
              id_gamification:"energy-1",
              communicationbuttons:[
                     {
                      title:"safety",
                      innerText:"Adhere to safety practices and procedures, such as checking equipment regularly and erecting barriers around work areas."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Test conductors, according to electrical diagrams and specifications, to identify corresponding conductors and to prevent incorrect connections."
                     },
                    {
                      title:"Repair",
                      innerText:"Open switches or attach grounding devices to remove electrical hazards from disturbed or fallen lines or to facilitate repairs."
                     },
                     {
                      title:"Vehicle Operation",
                      innerText:"Drive vehicles equipped with tools and materials to job sites."
                     },
                     {
                      title:"Operate Machinery",
                      innerText:"Dig holes, using augers, and set poles, using cranes and power equipment."
                     },
                     {
                      title:"Troubleshoot",
                      innerText:"Inspect and test power lines and auxiliary equipment to locate and identify problems, using reading and testing instruments"
                     },
              ]
          },
                    {



              jobtitle:'Nuclear Engineers',
              salary:"$66,000 - $151,000",
              description:"Conduct research on nuclear engineering projects or apply principles and theory of nuclear science to problems concerned with release, control, and use of nuclear energy and nuclear waste disposal.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'RX_V2Tt0c4w',
              srcOrHref:''},
              education:'',
              id_gamification:"energy-2",
              communicationbuttons:[
                     {
                      title:"Design",
                      innerText:"Design or develop nuclear equipment, such as reactor cores, radiation shielding, or associated instrumentation or control mechanisms."
                     },
                     {
                      title:"Critical Analysis",
                      innerText:"Examine accidents to obtain data that can be used to design preventive measures."
                     },
                     {
                      title:"Instructing",
                      innerText:"Write operational instructions to be used in nuclear plant operation or nuclear fuel or waste handling and disposal"
                     },
                     {
                      title:"Research",
                      innerText:"Conduct environmental studies related to topics such as nuclear power generation, nuclear waste disposal, or nuclear weapon deployment."
                     },
                     {
                      title:"Safety",
                      innerText:"Initiate corrective actions or order plant shutdowns in emergency situations."
                     },
                     {
                      title:"Active Learning",
                      innerText:"Keep abreast of developments and changes in the nuclear field by reading technical journals or by independent study and research."
                     },
              ]
          },
          {


              jobtitle:"Power Plant Operator",
              description:"Control, operate, or maintain machinery to generate electric power. Includes auxiliary equipment operators.",
              salary:"$76,400",
              education: "",
              id_gamification:"energy-3",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"Ug9QtgaRplo"},
                          
              communicationbuttons:[
                     {
                      title:"Maintenance",
                      innerText:"Clean, lubricate, or maintain equipment, such as generators, turbines, pumps, or compressors, to prevent failure or deterioratio"
                      },
                     {
                      title:"Analysis",
                      innerText:"Analyze the layout, instrumentation, or function of electrical generation or transmission facilities."
                     },
                     {
                      title:"Observation",
                      innerText:"Monitor power plant equipment and indicators to detect evidence of operating problems."
                     },
                     {
                      title:"Machine Operation",
                      innerText:"Adjust controls to generate specified electrical power or to regulate the flow of power between generating stations and substations."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Examine and test electrical power distribution machinery and equipment, using testing devices."
                     },
                     {
                      title:"Communicate",
                      innerText:"Communicate with systems operators to regulate and coordinate line voltages and transmission loads and frequencies."
                     },
              ]
          },
          {
      
      
            jobtitle:"Water Plant Operator",
            description:"Operate or control an entire process or system of machines, often through the use of control boards, to transfer or treat water or wastewater.",
            salary:"$40,800",
            education: "",
            jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/pABK8Di1Y5Q",videoId:"pABK8Di1Y5Q"},
            id_gamification:"energy-4",
            communicationbuttons:[
                   {
                    title:"Maintenance",
                    innerText:"Add chemicals, such as ammonia, chlorine, or lime, to disinfect and deodorize water and other liquids."                     
                    },
                   {
                    title:"Analysis",
                    innerText:"Collect and test water and sewage samples, using test equipment and color analysis standards."
                   },
                   {
                    title:"Observation",
                    innerText:"Record operational data, personnel attendance, or meter and gauge readings on specified forms."
                   },
                   {
                    title:"Machine Operation",
                    innerText:"Operate and adjust controls on equipment to purify and clarify water, process or dispose of sewage, and generate power."
                   },
                   {
                    title:"Quality Control",
                    innerText:"Inspect equipment or monitor operating conditions, meters, and gauges to determine load requirements and detect malfunctions."                    },
                   {
                    title:"Supervise",
                    innerText:"Perform routine administrative functions such as drafting correspondence, scheduling appointments, organizing and maintaining paper and electronic files, or providing information to callers."
                   },
            ]
        },
        {
    
    
          jobtitle:"Administrative Assistant",
          description:"Perform routine administrative functions such as drafting correspondence, scheduling appointments, organizing and maintaining paper and electronic files, or providing information to callers.",
          salary:"$37,200",
          education: "",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/zDqK9DaCDg4",videoId:"zDqK9DaCDg4"},
          id_gamification:"energy-5",
          communicationbuttons:[
                 {
                  title:"Communicate",
                  innerText:"Answer telephones and give information to callers, take messages, or transfer calls to appropriate individuals."                     
                  },
                 {
                  title:"People Skills",
                  innerText:"Greet visitors or callers and handle their inquiries or direct them to the appropriate persons according to their needs."
                 },
                 {
                  title:"Data Entry",
                  innerText:"Create, maintain, and enter information into databases."
                 },
                 {
                  title:"Computer Usage",
                  innerText:"Use computers for various applications, such as database management or word processing."
                 },
                 {
                  title:"Organized",
                  innerText:"Establish work procedures or schedules and keep track of the daily work of clerical staff."                    },
                 {
                  title:"Supervise",
                  innerText:"Supervise other clerical staff and provide training and orientation to new staff."
                 },
          ]
      },
      {
  
  
        jobtitle:"Customer Service Representatives",
        description:"Interact with customers to provide basic or scripted information in response to routine inquiries about products and services. May handle and resolve general complaints. Excludes individuals whose duties are primarily installation, sales, repair, and technical support.",
        salary:"$45,700",
        education: "",
        jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/0-ERXRCB6Nc",videoId:"0-ERXRCB6Nc"},
        id_gamification:"energy-6",
        communicationbuttons:[
               {
                title:"Communicate",
                innerText:"Confer with customers by telephone or in person to provide information about products or services, take or enter orders, cancel accounts, or obtain details of complaints."                     
                },
               {
                title:"People Skills",
                innerText:"Resolve customers' service or billing complaints by performing activities such as exchanging merchandise, refunding money, or adjusting bills."
               },
               {
                title:"Data Entry",
                innerText:"Complete contract forms, prepare change of address records, or issue service discontinuance orders, using computers."
               },
               {
                title:"Sales",
                innerText:"Solicit sales of new or additional services or products."
               },
               {
                title:"Organized",
                innerText:"Keep records of customer interactions or transactions, recording details of inquiries, complaints, or comments, as well as actions taken."                    },
               {
                title:"Analysis",
                innerText:"Review insurance policy terms to determine whether a particular loss is covered by insurance."
               },
        ]
    },
    {


      jobtitle:"Production Manager",
      description:"Directly supervise and coordinate the activities of production and operating workers, such as inspectors, precision workers, machine setters and operators, assemblers, fabricators, and plant and system operators. Excludes team or work leaders.",
      salary:"$81,500",
      education: "",
      jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/dflNKhApmBE",videoId:"dflNKhApmBE"},
      id_gamification:"energy-7",
      communicationbuttons:[
             {
              title:"Organized",
              innerText:"Keep records of employees' attendance and hours worked."                     
              },
             {
              title:"Detailed",
              innerText:"Inspect materials, products, or equipment to detect defects or malfunctions."
             },
             {
              title:"Communicate",
              innerText:"Confer with other supervisors to coordinate operations and activities within or between departments."
             },
             {
              title:"Training",
              innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
             },
             {
              title:"Leadership",
              innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."                    },
             {
              title:"Analysis",
              innerText:"Determine standards, budgets, production goals, and rates, based on company policies, equipment and labor availability, and workloads."
             },
      ]
  },
  {


    jobtitle:"Maintenance Manager",
    description:"Directly supervise and coordinate the activities of mechanics, installers, and repairers. May also advise customers on recommended services. Excludes team or work leaders.",
    salary:"$78,800",
    education: "",
    jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/8D6tWZDT2m8",videoId:"8D6tWZDT2m8"},
    id_gamification:"energy-8",
    communicationbuttons:[
           {
            title:"Leadership",
            innerText:"Recommend or initiate personnel actions, such as hires, promotions, transfers, discharges, or disciplinary measures."                     
            },
           {
            title:"Planning",
            innerText:"Compute estimates and actual costs of factors such as materials, labor, or outside contractors."
           },
           {
            title:"Training",
            innerText:"Conduct or arrange for worker training in safety, repair, or maintenance techniques, operational procedures, or equipment use."
           },
           {
            title:"Tool Usage",
            innerText:"Perform skilled repair or maintenance operations, using equipment such as hand or power tools, hydraulic presses or shears, or welding equipment."
           },
           {
            title:"Organized",
            innerText:"Monitor tool and part inventories and the condition and maintenance of shops to ensure adequate working conditions."                    },
           {
            title:"Communicate",
            innerText:"Meet with vendors or suppliers to discuss products used in repair work."
           },
    ]
},
{


  jobtitle:"Control Valve Mechanic",
  description:"Install, repair, and maintain mechanical regulating and controlling devices, such as electric meters, gas regulators, thermostats, safety and flow valves, and other mechanical governors.",
  salary:"$46,200",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/vCUW9kkUYFQ",videoId:"vCUW9kkUYFQ"},
  id_gamification:"energy-9",
  communicationbuttons:[
         {
          title:"Data Entry",
          innerText:"Record maintenance information, including test results, material usage, and repairs made."                     
          },
         {
          title:"Finance",
          innerText:"Collect money due on delinquent accounts."
         },
         {
          title:"Tool Usage",
          innerText:"Disconnect or remove defective or unauthorized meters, using hand tools."
         },
         {
          title:"Fabricate",
          innerText:"Recondition displacement type gas meters and governors, fabricating, machining, or modifying parts needed for repairs."
         },
         {
          title:"Troubleshoot",
          innerText:"Investigate instances of illegal tapping into service lines."                    },
         {
          title:"Writing Skills",
          innerText:"Recommend and write up specifications for changes in hardware, such as house wiring."
         },
  ]
},
{


  jobtitle:"Industrial Machinery Mechanic",
  description:"Repair, install, adjust, or maintain industrial production and processing machinery or refinery and pipeline distribution systems. May also install, dismantle, or move machinery and heavy equipment according to plans.",
  salary:"$70,100",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/ROJQXBuEnEY",videoId:"ROJQXBuEnEY"},
  id_gamification:"energy-10",
  communicationbuttons:[
         {
          title:"Troubleshoot",
          innerText:"Repair or maintain the operating condition of industrial production or processing machinery or equipment."                     
          },
         {
          title:"Data Entry",
          innerText:"Record repairs and maintenance performed."
         },
         {
          title:"Detailed",
          innerText:"Examine parts for defects, such as breakage or excessive wear."
         },
         {
          title:"Analysis",
          innerText:"Analyze test results, machine error messages, or information obtained from operators to diagnose equipment problems."
         },
         {
          title:"Leadership",
          innerText:"Assign schedules to work crews."                    },
         {
          title:"Training",
          innerText:"Demonstrate equipment functions and features to machine operators."
         },
  ]
},
{


  jobtitle:"General & Operations Managers",
  description:"Plan, direct, or coordinate the operations of public or private sector organizations, overseeing multiple departments or locations. Duties and responsibilities include formulating policies, managing daily operations, and planning the use of materials and human resources, but are too diverse and general in nature to be classified in any one functional area of management or administration, such as personnel, purchasing, or administrative services. Usually manage through subordinate supervisors. Excludes First-Line Supervisors.",
  salary:"$118,600",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/2rSN2E1_aPQ",videoId:"2rSN2E1_aPQ"},
  id_gamification:"energy-11",
  communicationbuttons:[
         {
          title:"Leadership",
          innerText:"Direct and coordinate activities of businesses or departments concerned with the production, pricing, sales, or distribution of products."                     
          },
         {
          title:"Coordinate",
          innerText:"Prepare staff work schedules and assign specific duties."
         },
         {
          title:"Analysis",
          innerText:"Direct or coordinate financial or budget activities to fund operations, maximize investments, or increase efficiency."
         },
         {
          title:"Sales",
          innerText:"Set prices or credit terms for goods or services, based on forecasts of customer demand."
         },
         {
          title:"Marketing",
          innerText:"Develop or implement product-marketing strategies, including advertising campaigns or sales promotions."                    },
         {
          title:"Communicate",
          innerText:"Recommend locations for new facilities, or oversee the remodeling or renovating of current facilities."
         },
  ]
}


         ],
     
        description:{
          aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
        }
         
    },
    engineering_and_design:{
      mediaInfo:{
        videoType:'youtube',link:"https://www.youtube.com/watch?v=cUIAZcjjB_g&list=TLPQMjQwMzIwMjHqCZpyRvoORg",videoId:'veg8CcB-xnI',imageOrVideo:'video',srcOrHref:'aerospace-careers'
      },
        jobs:[ 
            {
              jobtitle:'Civil Engineers',
              salary:"$89,800",
              description:"Perform engineering duties in planning, designing, and overseeing construction and maintenance of building structures and facilities, such as roads, railroads, airports, bridges, harbors, channels, dams, irrigation projects, pipelines, power plants, and water and sewage systems.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'-XIQSrYi0O0',
              srcOrHref:'https://youtu.be/-XIQSrYi0O0'},
              education:"",
              id_gamification:"ead-1",
              communicationbuttons:[
                     {
                      title:"Monitoring",
                      innerText:"Inspect project sites to monitor progress and ensure conformance to design specifications and safety or sanitation standards."
                     },
                     {
                      title:"Instructing",
                      innerText:"Provide technical advice to industrial or managerial personnel regarding design, construction, program modifications, or structural repairs"
                     },
                    {
                      title:"Critical Analysis",
                      innerText:"Test soils or materials to determine the adequacy and strength of foundations, concrete, asphalt, or steel."
                     },
                     {
                      title:"Management",
                      innerText:"Manage and direct the construction, operations, or maintenance activities at project site."
                     },
                     {
                      title:"Design",
                      innerText:"Design energy-efficient or environmentally sound civil structures."
                     },
                     {
                      title:"Computer Literacy",
                      innerText:"Plan and design transportation or hydraulic systems or structures, using computer-assisted design or drawing tools."
                     },
              ]
          },
          {


              jobtitle:"Art Director",
              description:"Formulate design concepts and presentation approaches for visual productions and media, such as print, broadcasting, video, and film. Direct workers engaged in artwork or layout design.",
              salary:"$33,000 - $106,000",
              education: "",
              id_gamification:"ead-3",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"0xZz2t-Ex1I"},
                          
              communicationbuttons:[
                     {
                      title:"Management",
                      innerText:"Manage own accounts and projects, working within budget and scheduling requirements."
                      },
                     {
                      title:"Quality Control",
                      innerText:"Review and approve art materials, copy materials, and proofs of printed copy developed by staff members."
                     },
                     {
                      title:"Collaborate",
                      innerText:"Work with creative directors to develop design solutions."
                     },
                     {
                      title:"Design",
                      innerText:"Create custom illustrations or other graphic elements."
                     },
                     {
                      title:"Analysis",
                      innerText:"Review illustrative material to determine if it conforms to standards and specifications."
                     },
                     {
                      title:"Creativity",
                      innerText:"Conceptualize and help design interfaces for multimedia games, products, and devices."
                     },
              ]
          },
          {
      
      
            jobtitle:"Electrical Engineers",
            description:"Research, design, develop, test, or supervise the manufacturing and installation of electrical equipment, components, or systems for commercial, industrial, military, or scientific use.",
            salary:"$103,300",
            education: "",
            jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/fhAZ2tZj84A",videoId:"fhAZ2tZj84A"},
            id_gamification:"ead-4",
            communicationbuttons:[
                   {
                    title:"Computer Literacy",
                    innerText:"Operate computer-assisted engineering or design software or equipment to perform engineering tasks."                     
                    },
                   {
                    title:"Communicate",
                    innerText:"Confer with engineers, customers, or others to discuss existing or potential engineering projects or products."
                   },
                   {
                    title:"Detail Oriented",
                    innerText:"Perform detailed calculations to compute and establish manufacturing, construction, or installation standards or specifications."
                   },
                   {
                    title:"Management",
                    innerText:"Supervise or train project team members, as necessary."
                   },
                   {
                    title:"Analysis",
                    innerText:"Conduct field surveys or study maps, graphs, diagrams, or other data to identify and correct power system problems."                    },
                   {
                    title:"Design",
                    innerText:"Design electrical systems or components that minimize electric energy requirements, such as lighting systems designed to account for natural lighting."
                   },
            ]
        },
        {
    
    
          jobtitle:"Industrial Engineers",
          description:"Design, develop, test, and evaluate integrated systems for managing industrial production processes, including human work factors, quality control, inventory control, logistics and material flow, cost analysis, and production coordination.",
          salary:"$101,900",
          education: "",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/kNXMJBQ6oL4",videoId:"kNXMJBQ6oL4"},
          id_gamification:"ead-5",
          communicationbuttons:[
                 {
                  title:"Teamwork",
                  innerText:"Communicate with management and user personnel to develop production and design standards."                     
                  },
                 {
                  title:"Critical Analysis",
                  innerText:"Evaluate precision and accuracy of production and testing equipment and engineering drawings to formulate corrective action plan."
                 },
                 {
                  title:"Management",
                  innerText:"Direct workers engaged in product measurement, inspection, and testing activities to ensure quality control and reliability."
                 },
                 {
                  title:"Systems Design",
                  innerText:"Plan and establish sequence of operations to fabricate and assemble parts or products and to promote efficient utilization."
                 },
                 {
                  title:"Problem Solving",
                  innerText:"Recommend methods for improving utilization of personnel, material, and utilities."                    },
                 {
                  title:"Organization",
                  innerText:"Complete production reports, purchase orders, and material, tool, and equipment lists."
                 },
          ]
      },
      {



jobtitle:'Mechanical Engineers',
salary:"$99,300",
description:"Perform engineering duties in planning and designing tools, engines, machines, and other mechanically functioning equipment. Oversee installation, operation, maintenance, and repair of equipment such as centralized heat, gas, water, and steam systems.",
jobMedia:{imageOrVideo:'video',
videoType:'youtube',
videoId:'bDg2UcaZzcg',
srcOrHref:''},
education:"",
id_gamification:"ead-2",
communicationbuttons:[
       {
        title:"Analysis",
        innerText:"Read and interpret blueprints, technical drawings, schematics, or computer-generated reports."
       },
       {
        title:"Teamwork",
        innerText:"Confer with engineers or other personnel to implement operating procedures, resolve system malfunctions, or provide technical information."
       },
       {
        title:"Project Management",
        innerText:"Develop, coordinate, or monitor all aspects of production, including selection of manufacturing methods, fabrication, or operation of product designs."
       },
       {
        title:"Quality Control",
        innerText:"Recommend design modifications to eliminate machine or system malfunctions."
       },
       {
        title:"Design",
        innerText:"Design integrated mechanical or alternative systems, such as mechanical cooling systems with natural ventilation systems, to improve energy efficiency."
       },
       {
        title:"Critical Analysis",
        innerText:"Conduct research that tests or analyzes the feasibility, design, operation, or performance of equipment, components, or system"
       },
]
},
{


  jobtitle:"Aerospace Engineer",
  description:"Perform engineering duties in designing, constructing, and testing aircraft, missiles, and spacecraft. May conduct basic and applied research to evaluate adaptability of materials and equipment to aircraft design and manufacture. May recommend improvements in testing equipment and techniques.",
  salary:"$109,900",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/bnxcib-8S4s",videoId:"bnxcib-8S4s"},
  id_gamification:"ead-6",
  communicationbuttons:[
         {
          title:"Mathematics",
          innerText:"Formulate mathematical models or other methods of computer analysis to develop, evaluate, or modify designs."                     
          },
         {
          title:"System Design",
          innerText:"Design or engineer filtration systems that reduce harmful emissions."
         },
         {
          title:"Fabricate",
          innerText:"Fabricate defective sections or parts, using metal fabricating machines, saws, brakes, shears, and grinders."
         },
         {
          title:"Organized",
          innerText:"Maintain records of performance reports for future reference."
         },
         {
          title:"Leadership",
          innerText:"Direct aerospace research and development programs."                    },
         {
          title:"Research",
          innerText:"Research new materials to determine quality or conformance to environmental standards."
         },
  ]
},
{


  jobtitle:"Visual Merchandiser",
  description:"Plan and erect commercial displays, such as those in windows and interiors of retail stores and at trade exhibitions.",
  salary:"$33,600",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/w961haPAZR8",videoId:"w961haPAZR8"},
  id_gamification:"ead-7",
  communicationbuttons:[
         {
          title:"Planning",
          innerText:"Plan commercial displays to entice and appeal to customers."                     
          },
         {
          title:"Detailed",
          innerText:"Arrange properties, furniture, merchandise, backdrops, or other accessories, as shown in prepared sketches."
         },
         {
          title:"Creativity",
          innerText:"Develop ideas or plans for merchandise displays or window decorations."
         },
         {
          title:"Visual Design",
          innerText:"Select themes, lighting, colors, or props to be used."
         },
         {
          title:"Computer Usage",
          innerText:"Use computers to produce signage."                    },
         {
          title:"Communicate",
          innerText:"Instruct sales staff in color coordination of clothing racks or counter displays."
         },
  ]
},
{


  jobtitle:"Graphic Designer",
  description:"Design or create graphics to meet specific commercial or promotional needs, such as packaging, displays, or logos. May use a variety of mediums to achieve artistic or decorative effects.",
  salary:"$43,400",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/dt6td67yF9E",videoId:"dt6td67yF9E"},
  id_gamification:"ead-8",
  communicationbuttons:[
         {
          title:"Critical Thinking",
          innerText:"Determine size and arrangement of illustrative material and copy, and select style and size of type."                     
          },
         {
          title:"Communicate",
          innerText:"Confer with clients to discuss and determine layout design."
         },
         {
          title:"Design",
          innerText:"Create designs, concepts, and sample layouts, based on knowledge of layout principles and esthetic design concepts."
         },
         {
          title:"Quality Control",
          innerText:"Review final layouts and suggest improvements as needed."
         },
         {
          title:"Active Learning",
          innerText:"Research new software or design concepts."                    },
         {
          title:"Detail Oriented",
          innerText:"Mark up, paste, and assemble final layouts to prepare layouts for printer."
         },
  ]
},{
  jobtitle:'Marine Engineer',
  salary:"$103,400",
  description:"Design, develop, and evaluate the operation of marine vessels, ship machinery, and related equipment, such as power supply and propulsion systems.",
  jobMedia:{imageOrVideo:'video',
  videoType:'youtube',
  videoId:'445nfaA6gdI',
  srcOrHref:''},
  education:"",
  id_gamification:"ead-9",
  communicationbuttons:[
         {
          title:"Design",
          innerText:"Design complete hull and superstructure according to specifications and test data, in conformity with standards of safety, efficiency, and economy."
         },
         {
          title:"Supervise",
          innerText:"Supervise other engineers and crew members and train them for routine and emergency duties."
         },
        {
          title:"Analysis",
          innerText:"Study design proposals and specifications to establish basic characteristics of craft, such as size, weight, speed, propulsion, displacement, and draft."
         },
         {
          title:"Problem Solving",
          innerText:"Evaluate performance of craft during dock and sea trials to determine design changes and conformance with national and international standards."
         },
         {
          title:"Organized",
          innerText:"Prepare plans, estimates, design and construction schedules, and contract specifications, including any special provisions."
         },
         {
          title:"Quality Control",
          innerText:"Conduct environmental, operational, or performance tests on marine machinery and equipment."
         },
  ]
},
{
  jobtitle:'Floral Designers',
  salary:"$25,200",
  description:"Design, cut, and arrange live, dried, or artificial flowers and foliage.",
  jobMedia:{imageOrVideo:'video',
  videoType:'youtube',
  videoId:'OF4-CWR2XE8',
  srcOrHref:''},
  education:"",
  id_gamification:"ead-10",
  communicationbuttons:[
         {
          title:"Communicate",
          innerText:"Confer with clients regarding price and type of arrangement desired and the date, time, and place of delivery."
         },
         {
          title:"Design",
          innerText:"Select flora and foliage for arrangements, working with numerous combinations to synthesize and develop new creations."
         },
        {
          title:"Logistics",
          innerText:"Order and purchase flowers and supplies from wholesalers and growers."
         },
         {
          title:"Training",
          innerText:"Conduct classes or demonstrations, or train other workers."
         },
         {
          title:"Creativity",
          innerText:"Create and change in-store and window displays, designs, and looks to enhance a shop's image."
         },
         {
          title:"Sales",
          innerText:"Wrap and price completed arrangements."
         },
  ]
},

         ],
     
        description:{
          aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
        }
         
    },
    healthcare:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/qm6c7KDIQq0",videoId:'qm6c7KDIQq0',imageOrVideo:'video',srcOrHref:'https://youtu.be/qm6c7KDIQq0'
      },
        jobs:[
            {
              jobtitle:'Phlebotomists',
              salary:"$23,000 - $43,000",
              description:"Draw blood for tests, transfusions, donations, or research. May explain the procedure to patients and assist in the recovery of patients with adverse reactions.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'A3c4BlLri_U',
              srcOrHref:'https://youtu.be/A3c4BlLri_U'},
              education:"",
              id_gamification:"hc-1",
              communicationbuttons:[
                     {
                      title:"Safety",
                      innerText:"Dispose of contaminated sharps, in accordance with applicable laws, standards, and policies."
                     },
                     {
                      title:"Organize",
                      innerText:"Organize or clean blood-drawing trays, ensuring that all instruments are sterile and all needles, syringes, or related items are of first-time use."
                     },
                    {
                      title:"Detail Oriented",
                      innerText:"Collect specimens at specific time intervals for tests, such as those assessing therapeutic drug levels."
                     },
                     {
                      title:"Computer Literacy",
                      innerText:"Enter patient, specimen, insurance, or billing information into computer."
                     },
                     {
                      title:"Instructing",
                      innerText:"Explain fluid or tissue collection procedures to patients."
                     },
                     {
                      title:"Testing",
                      innerText:"Conduct hemoglobin tests to ensure donor iron levels are normal."
                     },
              ]
          },
                    {



              jobtitle:'Registered Nurse',
              salary:"$58,300",
              description:"Assess patient health problems and needs, develop and implement nursing care plans, and maintain medical records. Administer nursing care to ill, injured, convalescent, or disabled patients. May advise patients on health maintenance and disease prevention or provide case management. Licensing or registration required.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'x4Y_2fpET0U',
              srcOrHref:'https://youtu.be/x4Y_2fpET0U'},
              education:"",
              id_gamification:"hc-2",
              communicationbuttons:[
                     {
                      title:"Communicate",
                      innerText:"Consult and coordinate with healthcare team members to assess, plan, implement, or evaluate patient care plans."
                     },
                     {
                      title:"Social Perceptiveness",
                      innerText:"Assess the needs of individuals, families, or communities, including assessment of individuals' home or work environments, to identify potential health or safety problems."
                     },
                     {
                      title:"Monitoring",
                      innerText:"Monitor, record, and report symptoms or changes in patients' conditions."
                     },
                     {
                      title:"Service Oriented",
                      innerText:"Assess the needs of individuals, families, or communities, including assessment of individuals' home or work environments, to identify potential health or safety problems."
                     },
                     {
                      title:"Instructing",
                      innerText:"Instruct individuals, families, or other groups on topics such as health education, disease prevention, or childbirth and develop health improvement programs."
                     },
                     {
                      title:"Detail Oriented",
                      innerText:"Administer medications to patients and monitor patients for reactions or side effects."
                     },
              ]
          },
          {


              jobtitle:"Pharmacist",
              description:"Dispense drugs prescribed by physicians and other health practitioners and provide information to patients about medications and their use. May advise physicians and other health practitioners on the selection, dosage, interactions, and side effects of medications.",
              salary:"$108,000 - $160,000",
              education: "",
              id_gamification:"hc-3",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"ploFnvE1770"},
                          
              communicationbuttons:[
                     {
                      title:"Detail Oriented",
                      innerText:"Review prescriptions to assure accuracy, to ascertain the needed ingredients, and to evaluate their suitability."
                      },
                     {
                      title:"Instructing",
                      innerText:"Provide information and advice regarding drug interactions, side effects, dosage, and proper medication storage."
                     },
                     {
                      title:"Organized",
                      innerText:"Order and purchase pharmaceutical supplies, medical supplies, or drugs, maintaining stock and storing and handling it properly."
                     },
                     {
                      title:"Active Learning",
                      innerText:"Analyze prescribing trends to monitor patient compliance and to prevent excessive usage or harmful interactions."
                     },
                     {
                      title:"Customer Service",
                      innerText:"Advise customers on the selection of medication brands, medical equipment, or healthcare supplies"
                     },
                     {
                      title:"Management",
                      innerText:"Manage pharmacy operations, hiring or supervising staff, performing administrative duties, or buying or selling non-pharmaceutical merchandise."
                     },
              ]
          },
          {
      
      
            jobtitle:"Personal Care Aides",
            description:"Provide personalized assistance to individuals with disabilities or illness who require help with personal care and activities of daily living support (e.g., feeding, bathing, dressing, grooming, toileting, and ambulation). May also provide help with tasks such as preparing meals, doing light housekeeping, and doing laundry. Work is performed in various settings depending on the needs of the care recipient and may include locations such as their home, place of work, out in the community, or at a daytime nonresidential facility.",
            salary:"$20,600",
            education: "",
            jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/7llPqIH-DdQ",videoId:"7llPqIH-DdQ"},
            id_gamification:"hc-4",
            communicationbuttons:[
                   {
                    title:"Organized",
                    innerText:"Prepare and maintain records of client progress and services performed, reporting changes in client condition to manager or supervisor. "                     
                    },
                   {
                    title:"Teamwork",
                    innerText:"Participate in case reviews, consulting with the team caring for the client, to evaluate the client's needs and plan for continuing services."
                   },
                   {
                    title:"Physical Labor",
                    innerText:"Perform housekeeping duties, such as cooking, cleaning, washing clothes or dishes, or running errands."
                   },
                   {
                    title:"Instructing",
                    innerText:"Instruct or advise clients on issues, such as household cleanliness, utilities, hygiene, nutrition, or infant care."
                   },
                   {
                    title:"Cooking",
                    innerText:"Plan, shop for, or prepare nutritious meals or assist families in planning, shopping for, or preparing nutritious meals."                    },
                   {
                    title:"Driving",
                    innerText:"Transport clients to locations outside the home, such as to physicians' offices or on outings, using a motor vehicle."
                   },
            ]
        },
        {
    
    
          jobtitle:"Nursing Assistant",
          description:"Provide or assist with basic care or support under the direction of onsite licensed nursing staff. Perform duties such as monitoring of health status, feeding, bathing, dressing, grooming, toileting, or ambulation of patients in a health or nursing facility. May include medication administration and other health-related tasks. Includes nursing care attendants, nursing aides, and nursing attendants.",
          salary:"$23,900",
          education: "",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/wlSsdECNaiU",videoId:"wlSsdECNaiU"},
          id_gamification:"hc-5",
          communicationbuttons:[
                 {
                  title:"Observe",
                  innerText:"Document or otherwise report observations of patient behavior, complaints, or physical symptoms to nurses."                     
                  },
                 {
                  title:"Safety",
                  innerText:"Review patients' dietary restrictions, food allergies, and preferences to ensure patient receives appropriate diet."
                 },
                 {
                  title:"Communicate",
                  innerText:"Communicate with patients to ascertain feelings or need for assistance or social and emotional support."
                 },
                 {
                  title:"Instructing",
                  innerText:"Explain medical instructions to patients or family members."
                 },
                 {
                  title:"Teamwork",
                  innerText:"Assist nurses or physicians in the operation of medical equipment or provision of patient care."                    },
                 {
                  title:"Physical Strength",
                  innerText:"Lift or assist others to lift patients to move them on or off beds, examination tables, surgical tables, or stretchers."
                 },
          ]
      },
      {
  
  
        jobtitle:"Medical Assistants",
        description:"Perform administrative and certain clinical duties under the direction of a physician. Administrative duties may include scheduling appointments, maintaining medical records, billing, and coding information for insurance purposes. Clinical duties may include taking and recording vital signs and medical histories, preparing patients for examination, drawing blood, and administering medications as directed by physician.",
        salary:"$28,200",
        education: "",
        jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/ZsgeduDiK_w",videoId:"ZsgeduDiK_w"},
        id_gamification:"hc-6",
        communicationbuttons:[
               {
                title:"Data Entry",
                innerText:"Record patients' medical history, vital statistics, or information such as test results in medical records."                     
                },
               {
                title:"Cleanliness",
                innerText:"Clean and sterilize instruments and dispose of contaminated supplies."
               },
               {
                title:"Communicate",
                innerText:"Explain treatment procedures, medications, diets, or physicians' instructions to patients."
               },
               {
                title:"Organized",
                innerText:"Collect blood, tissue, or other laboratory specimens, log the specimens, and prepare them for testing."
               },
               {
                title:"Social Skills",
                innerText:"Greet and log in patients arriving at office or clinic."                    },
               {
                title:"Operate Machines",
                innerText:"Operate x-ray, electrocardiogram (EKG), or other equipment to administer routine diagnostic tests."
               },
        ]
    },
    {


      jobtitle:"Charge Nurse",
      description:"Care for ill, injured, or convalescing patients or persons with disabilities in hospitals, nursing homes, clinics, private homes, group homes, and similar institutions. May work under the supervision of a registered nurse. Licensing required.",
      salary:"$38,000",
      education: "",
      jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/yTBPYOnNjVU",videoId:"yTBPYOnNjVU"},
      id_gamification:"hc-7",
      communicationbuttons:[
             {
              title:"Data Entry",
              innerText:"Measure and record patients' vital signs, such as height, weight, temperature, blood pressure, pulse, or respiration."                     
              },
             {
              title:"Supervise",
              innerText:"Supervise nurses' aides or assistants."
             },
             {
              title:"Analysis",
              innerText:"Evaluate nursing intervention outcomes, conferring with other healthcare team members as necessary."
             },
             {
              title:"Teamwork",
              innerText:"Work as part of a healthcare team to assess patient needs, plan and modify care, and implement interventions."
             },
             {
              title:"Organized",
              innerText:"Collect samples, such as blood, urine, or sputum from patients, and perform routine laboratory tests on samples."                    },
             {
              title:"Detailed",
              innerText:"Administer prescribed medications or start intravenous fluids, noting times and amounts on patients' charts."
             },
      ]
  },
  {


    jobtitle:"Office Assistant",
    description:"Answer inquiries and provide information to the general public, customers, visitors, and other interested parties regarding activities conducted at establishment and location of departments, offices, and employees within the organization.",
    salary:"$27,100",
    education: "",
    jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/pvH8lSAU6Sw",videoId:"pvH8lSAU6Sw"},
    id_gamification:"hc-8",
    communicationbuttons:[
           {
            title:"Organized",
            innerText:"Operate telephone switchboard to answer, screen, or forward calls, providing information, taking messages, or scheduling appointments."                     
            },
           {
            title:"Social Skills",
            innerText:"Greet persons entering establishment, determine nature and purpose of visit, and direct or escort them to specific destinations."
           },
           {
            title:"Problem Solving",
            innerText:"Hear and resolve complaints from customers or the public."
           },
           {
            title:"Analysis",
            innerText:"Analyze data to determine answers to questions from customers or members of the public."
           },
           {
            title:"Data Entry",
            innerText:"Take orders for merchandise or materials and send them to the proper departments to be filled."                    },
           {
            title:"Mathematics",
            innerText:"Calculate and quote rates for tours, stocks, insurance policies, or other products or services."
           },
    ]
},
{


  jobtitle:"Medical Secretary",
  description:"Perform secretarial duties using specific knowledge of medical terminology and hospital, clinic, or laboratory procedures. Duties may include scheduling appointments, billing patients, and compiling and recording medical charts, reports, and correspondence.",
  salary:"$32,500",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/jpvPQ5oFmHE",videoId:"jpvPQ5oFmHE"},
  id_gamification:"hc-9",
  communicationbuttons:[
         {
          title:"Organized",
          innerText:"Answer telephones and direct calls to appropriate staff."                     
          },
         {
          title:"Data Entry",
          innerText:"Transcribe recorded messages or practitioners' diagnoses or recommendations into patients' medical records."
         },
         {
          title:"Social Skills",
          innerText:"Greet visitors, ascertain purpose of visit, and direct them to appropriate staff."
         },
         {
          title:"Computer Usage",
          innerText:"Schedule and confirm patient diagnostic appointments, surgeries, or medical consultations."
         },
         {
          title:"Detailed",
          innerText:"Interview patients to complete documents, case histories, or forms, such as intake or insurance forms."                    },
         {
          title:"Finance",
          innerText:"Perform bookkeeping duties, such as credits or collections, preparing and sending financial statements or bills, and keeping financial records."
         },
  ]
},
{


  jobtitle:"Home Health Aide",
  description:"Monitor the health status of an individual with disabilities or illness, and address their health-related needs, such as changing bandages, dressing wounds, or administering medication. Work is performed under the direction of offsite or intermittent onsite licensed nursing staff. Provide assistance with routine healthcare tasks or activities of daily living, such as feeding, bathing, toileting, or ambulation. May also help with tasks such as preparing meals, doing light housekeeping, and doing laundry depending on the patient's abilities.",
  salary:"$20,600",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/_AyWpdXW5o",videoId:"_AyWpdXW5o"},
  id_gamification:"hc-10",
  communicationbuttons:[
         {
          title:"Detailed",
          innerText:"Maintain records of patient care, condition, progress, or problems to report and discuss observations with supervisor or case manager."                     
          },
         {
          title:"Physical Labor",
          innerText:"Provide patients with help moving in and out of beds, baths, wheelchairs, or automobiles and with dressing and grooming."
         },
         {
          title:"Social Skills",
          innerText:"Entertain, converse with, or read aloud to patients to keep them mentally healthy and alert."
         },
         {
          title:"Childcare",
          innerText:"Care for children who are disabled or who have sick or disabled parents."
         },
         {
          title:"Therapy",
          innerText:"Massage patients or apply preparations or treatments, such as liniment, alcohol rubs, or heat-lamp stimulation."                    },
         {
          title:"Teaching",
          innerText:"Direct patients in simple prescribed exercises or in the use of braces or artificial limbs."
         },
  ]
},
{


  jobtitle:"Childcare Worker",
  description:"Attend to children at schools, businesses, private households, and childcare institutions. Perform a variety of tasks, such as dressing, feeding, bathing, and overseeing play.",
  salary:"$18,600",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/xjYk0w_MMmw",videoId:"xjYk0w_MMmw"},
  id_gamification:"hc-11",
  communicationbuttons:[
         {
          title:"Communicate",
          innerText:"Communicate with children's parents or guardians about daily activities, behaviors, and related issues."                     
          },
         {
          title:"Empathy",
          innerText:"Support children's emotional and social development, encouraging understanding of others and positive self-concepts."
         },
         {
          title:"Perception",
          innerText:"Identify signs of emotional or developmental problems in children and bring them to parents' or guardians' attention."
         },
         {
          title:"Teaching",
          innerText:"Instruct children in health and personal habits, such as eating, resting, and toilet habits."
         },
         {
          title:"Organized",
          innerText:"Perform general administrative tasks, such as taking attendance, editing internal paperwork, and making phone calls."                    },
         {
          title:"Social Skills",
          innerText:"Read to children and teach them simple painting, drawing, handicrafts, and songs."
         },
  ]
},
{


  jobtitle:"Administrative Assistant",
  description:"Perform routine administrative functions such as drafting correspondence, scheduling appointments, organizing and maintaining paper and electronic files, or providing information to callers.",
  salary:"$34,900",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/zDqK9DaCDg4",videoId:"zDqK9DaCDg4"},
  id_gamification:"hc-12",
  communicationbuttons:[
         {
          title:"Communicate",
          innerText:"Answer telephones and give information to callers, take messages, or transfer calls to appropriate individuals."                     
          },
         {
          title:"People Skills",
          innerText:"Greet visitors or callers and handle their inquiries or direct them to the appropriate persons according to their needs."
         },
         {
          title:"Data Entry",
          innerText:"Create, maintain, and enter information into databases."
         },
         {
          title:"Computer Usage",
          innerText:"Use computers for various applications, such as database management or word processing."
         },
         {
          title:"Organized",
          innerText:"Establish work procedures or schedules and keep track of the daily work of clerical staff."                    },
         {
          title:"Supervise",
          innerText:"Supervise other clerical staff and provide training and orientation to new staff."
         },
  ]
}

         ],
     
        description:{
          aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
        }
         
    },
    information_technology:{
      mediaInfo:{
        videoType:'youtube',link:"https://www.youtube.com/watch?v=cUIAZcjjB_g&list=TLPQMjQwMzIwMjHqCZpyRvoORg",videoId:'cbffW0MeWoY',imageOrVideo:'video',srcOrHref:'aerospace-careers'
      },
        jobs:[ 
            {
              jobtitle:'Database Administrator',
              salary:"$44,000 - $99,000",
              description:"Administer, test, and implement computer databases, applying knowledge of database management systems. Coordinate changes to computer databases. Identify, investigate, and resolve database performance issues, database capacity, and database scalability. May plan, coordinate, and implement security measures to safeguard computer databases",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'GKAnWFOyk8E',
              srcOrHref:''},
              education:"",
              id_gamification:"hat-1",
              communicationbuttons:[
                     {
                      title:"Troubleshoot",
                      innerText:"Test programs or databases, correct errors, and make necessary modifications"
                     },
                     {
                      title:"Safety",
                      innerText:"Develop standards and guidelines to guide the use and acquisition of software and to protect vulnerable information."
                     },
                    {
                      title:"Instructing",
                      innerText:"Train users and answer questions."
                     },
                     {
                      title:"Coding",
                      innerText:"Write and code logical and physical database descriptions and specify identifiers of database to management system or direct others in coding descriptions."
                     },
                     {
                      title:"Critical Analysis",
                      innerText:"Develop data model describing data elements and how they are used, following procedures and using pen, template or computer software."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Test changes to database applications or systems."
                     },
              ]
          },
                    {



              jobtitle:'IT Project Manager',
              salary:"$30,000  - $110,000",
              description:"Plan, initiate, and manage information technology (IT) projects. Lead and guide the work of technical staff. Serve as liaison between business and technical aspects of projects. Plan project stages and assess business implications for each stage. Monitor progress to assure deadlines, standards, and cost targets are met.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'LVkXobqdvbk',
              srcOrHref:''},
              education:"",
              id_gamification:"hat-2",
              communicationbuttons:[
                     {
                      title:"Organization",
                      innerText:"Monitor or track project milestones and deliverables."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Submit project deliverables, ensuring adherence to quality standards."
                     },
                     {
                      title:"Customer Service",
                      innerText:"Assess current or future customer needs and priorities by communicating directly with customers, conducting surveys, or other methods."
                     },
                     {
                      title:"Planning",
                      innerText:"Initiate, review, or approve modifications to project plans."
                     },
                     {
                      title:"Time Management",
                      innerText:"Schedule and facilitate meetings related to information technology projects."
                     },
                     {
                      title:"Critical Analysis",

                      innerText:"Develop implementation plans that include analyses such as cost-benefit or return on investment (ROI)."
                     },
              ]
          },
          {


              jobtitle:"Web Developer",
              description:"Develop and implement websites, web applications, application databases, and interactive web interfaces. Evaluate code to ensure that it is properly structured, meets industry standards, and is compatible with browsers and devices. Optimize website performance, scalability, and server-side code and processes. May develop website infrastructure and integrate websites with other computer applications.",
              salary:"$30,000 - $70,000",
              education: "",
              id_gamification:"hat-3",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"FfxTkIsaTYk"},
                          
              communicationbuttons:[
                     {
                      title:"Programming",
                      innerText:"Design, build, or maintain Web sites, using authoring or scripting languages, content creation tools, management tools, and digital media."
                      },
                     {
                      title:"Analysis",
                      innerText:"Analyze user needs to determine technical requirements."
                     },
                     {
                      title:"Communicate",
                      innerText:"Respond to user email inquiries, or set up automated systems to send responses."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Perform Web site tests according to planned schedules, or after any Web site or product revision."
                     },
                     {
                      title:"Teamwork",
                      innerText:"Confer with management or development teams to prioritize needs, resolve conflicts, develop content criteria, or choose solutions."
                     },
                     {
                      title:"Prototyping",
                      innerText:"Create Web models or prototypes that include physical, interface, logical, or data models."
                     },
              ]
          },
          {


              jobtitle:"Computer User Support Specialist",
              description:"Provide technical assistance to computer users. Answer questions or resolve computer problems for clients in person, via telephone, or electronically. May provide assistance concerning the use of computer hardware and software, including printing, installation, word processing, electronic mail, and operating systems.",
              salary:"$50,500",
              education: "",
              id_gamification:"hat-4",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/xe6GS8kSN1g",videoId:"xe6GS8kSN1g"},
                          
              communicationbuttons:[
                     {
                      title:"Customer Support",
                      innerText:"Answer user inquiries regarding computer software or hardware operation to resolve problems."
                      },
                     {
                      title:"Troubleshoot",
                      innerText:"Read technical manuals, confer with users, or conduct computer diagnostics to investigate and resolve problems or to provide technical assistance and support."
                     },
                     {
                      title:"Instructing",
                      innerText:"Develop training materials and procedures, or train users in the proper use of hardware or software."
                     },
                     {
                      title:"Organization",
                      innerText:"Maintain records of daily data communication transactions, problems and remedial actions taken, or installation activities."
                     },
                     {
                      title:"Communicate",
                      innerText:"Confer with staff, users, and management to establish requirements for new systems or modifications."
                     },
                     {
                      title:"Systems Analysis",
                      innerText:"Prepare evaluations of software or hardware, and recommend improvements or upgrades."
                     },
              ]
          },
          {


              jobtitle:"Software Quality Engineer",
              description:"Develop and execute software tests to identify software problems and their causes. Test system modifications to prepare for implementation. Document software and application defects using a bug tracking system and report defects to software or web developers. Create and maintain databases of known defects. May participate in software design reviews to provide input on functional requirements, operational characteristics, product designs, and schedules.",
              salary:"$87,300",
              education: "",
              id_gamification:"hat-5",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/Xr-EoC9DHyc",videoId:"Xr-EoC9DHyc"},
                          
              communicationbuttons:[
                     {
                      title:"Detailed",
                      innerText:"Document software defects, using a bug tracking system, and report defects to software developers."
                      },
                     {
                      title:"Systems Analysis",
                      innerText:"Identify, analyze, and document problems with program function, output, online screen, or content."
                     },
                     {
                      title:"Teamwork",
                      innerText:"Participate in product design reviews to provide input on functional requirements, product designs, schedules, or potential problems."
                     },
                     {
                      title:"Organized",
                      innerText:"Plan test schedules or strategies in accordance with project scope or delivery dates."
                     },
                     {
                      title:"Troubleshoot",
                      innerText:"Provide feedback and recommendations to developers on software usability and functionality."
                     },
                     {
                      title:"Computer Literacy",
                      innerText:"Install, maintain, or use software testing programs."
                     },
              ]
          },
          {


              jobtitle:"Computer Systems Analysts",
              description:"Analyze science, engineering, business, and other data processing problems to develop and implement solutions to complex applications problems, system administration issues, or network concerns. Perform systems management and integration functions, improve existing computer systems, and review computer system capabilities, workflow, and schedule limitations. May analyze or recommend commercially available software.",
              salary:"$77,000",
              education: "",
              id_gamification:"hat-6",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/T0JdNufvn-o",videoId:"T0JdNufvn-o"},
                          
              communicationbuttons:[
                     {
                      title:"Detailed",
                      innerText:"Test, maintain, and monitor computer programs and systems, including coordinating the installation of computer programs and systems."
                      },
                     {
                      title:"Teaching",
                      innerText:"Train staff and users to work with computer systems and programs."
                     },
                     {
                      title:"Analysis",
                      innerText:"Assess the usefulness of pre-developed application packages and adapt them to a user environment."
                     },
                     {
                      title:"Planning",
                      innerText:"Define the goals of the system and devise flow charts and diagrams describing logical operational steps of programs."
                     },
                     {
                      title:"Support",
                      innerText:"Provide staff and users with assistance solving computer-related problems, such as malfunctions and program problems."
                     },
                     {
                      title:"Programming",
                      innerText:"Use object-oriented programming languages, as well as client and server applications development processes and multimedia and Internet technology."
                     },
              ]
          },
          {


              jobtitle:"Computer Programmer",
              description:"Create, modify, and test the code and scripts that allow computer applications to run. Work from specifications drawn up by software and web developers or other individuals. May develop and write computer programs to store, locate, and retrieve specific documents, data, and information.",
              salary:"$102,200",
              education: "",
              id_gamification:"hat-7",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/4FT15GxJQrE",videoId:"4FT15GxJQrE"},
                          
              communicationbuttons:[
                     {
                      title:"Detailed",
                      innerText:"Correct errors by making appropriate changes and rechecking the program to ensure that the desired results are produced."
                      },
                     {
                      title:"Communicate",
                      innerText:"Consult with managerial, engineering, and technical personnel to clarify program intent, identify problems, and suggest changes."
                     },
                     {
                      title:"Detailed",
                      innerText:"Conduct trial runs of programs and software applications to be sure they will produce the desired information and that the instructions are correct."
                     },
                     {
                      title:"Teamwork",
                      innerText:"Consult with and assist computer operators or system analysts to define and resolve problems in running computer programs."
                     },
                     {
                      title:"Analysis",
                      innerText:"Perform systems analysis and programming tasks to maintain and control the use of computer systems software as a systems programmer."
                     },
                     {
                      title:"Technical Writing",
                      innerText:"Write or contribute to instructions or manuals to guide end users."
                     },
              ]
          },
          {


              jobtitle:"IT Director",
              description:"Plan, direct, or coordinate activities in such fields as electronic data processing, information systems, systems analysis, and computer programming.",
              salary:"$118,100",
              education: "",
              id_gamification:"hat-8",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/WQmsKH7mIqo",videoId:"WQmsKH7mIqo"},
                          
              communicationbuttons:[
                     {
                      title:"Teamwork",
                      innerText:"Meet with department heads, managers, supervisors, vendors, and others, to solicit cooperation and resolve problems."
                      },
                     {
                      title:"Leadership",
                      innerText:"Direct daily operations of department, analyzing workflow, establishing priorities, developing standards and setting deadlines."
                     },
                     {
                      title:"Detailed",
                      innerText:"Assign and review the work of systems analysts, programmers, and other computer-related workers."
                     },
                     {
                      title:"Social Skills",
                      innerText:"Provide users with technical support for computer problems."
                     },
                     {
                      title:"Research",
                      innerText:"Stay abreast of advances in technology."
                     },
                     {
                      title:"Technical Writing",
                      innerText:"Evaluate data processing proposals to assess project feasibility and requirements."
                     },
              ]
          },
          {


              jobtitle:"General & Operations Managers",
              description:"Plan, direct, or coordinate the operations of public or private sector organizations, overseeing multiple departments or locations. Duties and responsibilities include formulating policies, managing daily operations, and planning the use of materials and human resources, but are too diverse and general in nature to be classified in any one functional area of management or administration, such as personnel, purchasing, or administrative services. Usually manage through subordinate supervisors. Excludes First-Line Supervisors.",
              salary:"$158,200",
              education: "",
              id_gamification:"hat-9",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/2rSN2E1_aPQ",videoId:"2rSN2E1_aPQ"},
                          
              communicationbuttons:[
                     {
                      title:"Leadership",
                      innerText:"Direct and coordinate activities of businesses or departments concerned with the production, pricing, sales, or distribution of products."
                      },
                     {
                      title:"Coordinate",
                      innerText:"Prepare staff work schedules and assign specific duties."
                     },
                     {
                      title:"Analysis",
                      innerText:"Direct or coordinate financial or budget activities to fund operations, maximize investments, or increase efficiency."
                     },
                     {
                      title:"Sales",
                      innerText:"Set prices or credit terms for goods or services, based on forecasts of customer demand."
                     },
                     {
                      title:"Marketing",
                      innerText:"Develop or implement product-marketing strategies, including advertising campaigns or sales promotions."
                     },
                     {
                      title:"Communicate",
                      innerText:"Recommend locations for new facilities, or oversee the remodeling or renovating of current facilities."
                     },
              ]
          },
          {


              jobtitle:"Network Administrator",
              description:"Install, configure, and maintain an organization's local area network (LAN), wide area network (WAN), data communications network, operating systems, and physical and virtual servers. Perform system monitoring and verify the integrity and availability of hardware, network, and server resources and systems. Review system and application logs and verify completion of scheduled jobs, including system backups. Analyze network and server resource consumption and control user access. Install and upgrade software and maintain software licenses. May assist in network modeling, analysis, planning, and coordination between network and data communications hardware and software.",
              salary:"$68,700",
              education: "",
              id_gamification:"hat-10",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/H3kIfqDCY14",videoId:"H3kIfqDCY14"},
                          
              communicationbuttons:[
                     {
                      title:"Planning",
                      innerText:"Perform data backups and disaster recovery operations."
                      },
                     {
                      title:"Computer Usage",
                      innerText:"Configure, monitor, and maintain email applications or virus protection software."
                     },
                     {
                      title:"Safety",
                      innerText:"Plan, coordinate, and implement network security measures to protect data, software, and hardware."
                     },
                     {
                      title:"Hands-On",
                      innerText:"Design, configure, and test computer hardware, networking software and operating system software."
                     },
                     {
                      title:"Teaching",
                      innerText:"Train people in computer system use."
                     },
                     {
                      title:"Data Entry",
                      innerText:"Maintain logs related to network functions, as well as maintenance and repair records."
                     },
              ]
          },
          {


              jobtitle:"Sales Representative",
              description:"Sell services to individuals or businesses. May describe options or resolve client problems.",
              salary:"$73,100",
              education: "",
              id_gamification:"hat-11",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/To04x9Um1VM",videoId:"To04x9Um1VM"},
                          
              communicationbuttons:[
                     {
                      title:"Social Skills",
                      innerText:"Answer customers' questions about services, prices, availability, or credit terms."
                      },
                     {
                      title:"Troubleshoot",
                      innerText:"Consult with clients after sales or contract signings to resolve problems and provide ongoing support."
                     },
                     {
                      title:"Active Listening",
                      innerText:"Contact prospective or existing customers to discuss how services can meet their needs."
                     },
                     {
                      title:"Data Entry",
                      innerText:"Create forms or agreements to complete sales."
                     },
                     {
                      title:"Sales",
                      innerText:"Develop sales presentations or proposals to explain service specifications."
                     },
                     {
                      title:"Marketing",
                      innerText:"Distribute promotional materials at meetings, conferences, or tradeshows."
                     },
              ]
          },
          {


              jobtitle:"Customer Service Representatives",
              description:"Interact with customers to provide basic or scripted information in response to routine inquiries about products and services. May handle and resolve general complaints. Excludes individuals whose duties are primarily installation, sales, repair, and technical support.",
              salary:"$45,700",
              education: "",
              id_gamification:"hat-12",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/0-ERXRCB6Nc",videoId:"0-ERXRCB6Nc"},
                          
              communicationbuttons:[
                     {
                      title:"Communicate",
                      innerText:"Confer with customers by telephone or in person to provide information about products or services, take or enter orders, cancel accounts, or obtain details of complaints."
                      },
                     {
                      title:"People Skills",
                      innerText:"Resolve customers' service or billing complaints by performing activities such as exchanging merchandise, refunding money, or adjusting bills."
                     },
                     {
                      title:"Data Entry",
                      innerText:"Complete contract forms, prepare change of address records, or issue service discontinuance orders, using computers."
                     },
                     {
                      title:"Sales",
                      innerText:"Solicit sales of new or additional services or products."
                     },
                     {
                      title:"Organized",
                      innerText:"Keep records of customer interactions or transactions, recording details of inquiries, complaints, or comments, as well as actions taken."
                     },
                     {
                      title:"Analysis",
                      innerText:"Review insurance policy terms to determine whether a particular loss is covered by insurance."
                     },
              ]
          }



         ],
     
        description:{
          aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
        }
         
    },
    hospitality_and_tourism:{
      mediaInfo:{
        videoType:'youtube',link:"https://www.youtube.com/watch?v=j5IJHSpu31w",videoId:'j5IJHSpu31w',imageOrVideo:'video',srcOrHref:'https://www.youtube.com/watch?v=j5IJHSpu31w'
      },
        jobs:[ 
            {
              jobtitle:'Restaurant Managers',
              salary:"$35,000 - $101,000",
              description:"Plan, direct, or coordinate activities of an organization or department that serves food and beverages.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'OzTbTaVPM2w',
              srcOrHref:''},
              education:"",
              id_gamification:"it-1",
              communicationbuttons:[
                     {
                      title:"Scheduling",
                      innerText:"Schedule and receive food and beverage deliveries, checking delivery contents to verify product quality and quantity."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Test cooked food by tasting and smelling it to ensure palatability and flavor conformity."
                     },
                    {
                      title:"Organization",
                      innerText:"Plan menus and food utilization, based on anticipated number of guests, nutritional value, palatability, popularity, and costs."
                     },
                     {
                      title:"Critical Thinking",
                      innerText:"Review work procedures and operational problems to determine ways to improve service, performance, or safety."
                     },
                     {
                      title:"Customer Service",
                      innerText:"Greet guests, escort them to their seats, and present them with menus and wine lists."
                     },
                     {
                      title:"Management",
                      innerText:"Schedule staff hours and assign duties."
                     },
              ]
          },
                    {



              jobtitle:'Chefs & Head Cooks',
              salary:"$30,000 - $77,000",
              description:"Direct and may participate in the preparation, seasoning, and cooking of salads, soups, fish, meats, vegetables, desserts, or other foods. May plan and price menu items, order supplies, and keep records and accounts.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'BtAevVMysC0',
              srcOrHref:''},
              education:"",
              id_gamification:"it-2",
              communicationbuttons:[
                     {
                      title:"Quality Control",
                      innerText:"Check the quality of raw or cooked food products to ensure that standards are met."
                     },
                     {
                      title:"Management",
                      innerText:"Supervise or coordinate activities of cooks or workers engaged in food preparation."
                     },
                     {
                      title:"Creativity",
                      innerText:"Determine how food should be presented and create decorative food displays."
                     },
                     {
                      title:"Critical Analysis",
                      innerText:"Analyze recipes to assign prices to menu items, based on food, labor, and overhead costs."
                     },
                     {
                      title:"Communicate",
                      innerText:"Meet with customers to discuss menus for special occasions, such as weddings, parties, or banquets."
                     },
                     {
                      title:"Instruction",
                      innerText:"Demonstrate new cooking techniques or equipment to staff."
                     },
              ]
          },
          {


              jobtitle:"Lodging Managers",
              description:"Plan, direct, or coordinate activities of an organization or department that provides lodging and other accommodations.",
              salary:"$30,000 - $76,000",
              education: "",
              id_gamification:"it-3",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"N6wsqmVAvnQ"},
                          
              communicationbuttons:[
                     {
                      title:"Customer Service",
                      innerText:"Answer inquiries pertaining to hotel policies and services, and resolve occupants' complaints."
                      },
                     {
                      title:"Teamwork",
                      innerText:"Confer and cooperate with other managers to ensure coordination of hotel activities."
                     },
                     {
                      title:"Instruction",
                      innerText:"Train staff members."
                     },
                     {
                      title:"Management",
                      innerText:"Assign duties to workers, and schedule shifts."
                     },
                     {
                      title:"Coordinate",
                      innerText:"Organize and coordinate the work of staff and convention personnel for meetings to be held at a particular facility."
                     },
                     {
                      title:"Problem Solving",
                      innerText:"Develop and implement policies and procedures for the operation of a department or establishment."
                     },
              ]
          }

         ],
     
        description:{
          aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
        }
         
    },
    manufacturing:{
      mediaInfo:{
        videoType:'youtube',link:"https://www.youtube.com/watch?v=L1kYKXLXf2A",videoId:'L1kYKXLXf2A',imageOrVideo:'video',srcOrHref:'https://www.youtube.com/watch?v=L1kYKXLXf2A'
      },
        jobs:[ 
            {
              jobtitle:'Electrical Engineers',
              salary:"$66,000 - $152,000",
              description:"Research, design, develop, test, or supervise the manufacturing and installation of electrical equipment, components, or systems for commercial, industrial, military, or scientific use.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'fhAZ2tZj84A',
              srcOrHref:''},
              education:"",
              id_gamification:"manufac-1",
              communicationbuttons:[
                     {
                      title:"Computer Literacy",
                      innerText:"Operate computer-assisted engineering or design software or equipment to perform engineering tasks."
                     },
                     {
                      title:"Communicate",
                      innerText:"Confer with engineers, customers, or others to discuss existing or potential engineering projects or products."
                     },
                    {
                      title:"Detail Oriented",
                      innerText:"Perform detailed calculations to compute and establish manufacturing, construction, or installation standards or specifications."

                     },
                     {
                      title:"Management",
                      innerText:"Supervise or train project team members, as necessary."
                     },
                     {
                      title:"Analysis",
                      innerText:"Conduct field surveys or study maps, graphs, diagrams, or other data to identify and correct power system problems."

                     },
                     {
                      title:"Design",
                      innerText:"Design electrical systems or components that minimize electric energy requirements, such as lighting systems designed to account for natural lighting."
                     },
              ]
          },
                    {



              jobtitle:'Chemical Engineers',
              salary:"$75,000 - $164,000",
              description:"Design chemical plant equipment and devise processes for manufacturing chemicals and products, such as gasoline, synthetic rubber, plastics, detergents, cement, paper, and pulp, by applying principles and technology of chemistry, physics, and engineering.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'cFQ4BjDeTME',
              srcOrHref:''},
              education:"",
              id_gamification:"manufac-2",
              communicationbuttons:[
                     {
                      title:"Quality Control",
                      innerText:"Monitor and analyze data from processes and experiments.",
                     },
                     {
                      title:"Safety",
                      innerText:"Develop safety procedures to be employed by workers operating equipment or working in close proximity to ongoing chemical reactions."

                     },
                     {
                      title:"Chemistry",
                      innerText:"Develop processes to separate components of liquids or gases or generate electrical currents, using controlled chemical processes."
                     },
                     {
                      title:"Troubleshoot",
                      innerText:"Troubleshoot problems with chemical manufacturing processes."
                     },
                     {
                      title:"Research",
                      innerText:"Conduct research to develop new and improved chemical manufacturing processes."
                     },
                     {
                      title:"Systems Design",
                      innerText:"Design measurement and control systems for chemical plants based on data collected in laboratory experiments and in pilot plant operations."
                     },
              ]
          },
          {


              jobtitle:"Maintenance Mechanic",
              description:"Perform work involving the skills of two or more maintenance or craft occupations to keep machines, mechanical equipment, or the structure of a building in repair. Duties may involve pipe fitting; HVAC maintenance; insulating; welding; machining; carpentry; repairing electrical or mechanical equipment; installing, aligning, and balancing new equipment; and repairing buildings, floors, or stairs.",
              salary:"$35,000 - $81,000",
              education: "",
              id_gamification:"manufac-3",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"I_mhZjRcH5s"},
                          
              communicationbuttons:[
                     {
                      title:"Machinery Maintenance",
                      innerText:"Dismantle machines, equipment, or devices to access and remove defective parts, using hoists, cranes, hand tools, or power tools."
                      },
                     {
                      title:"Quality Control",
                      innerText:"Inspect, operate, or test machinery or equipment to diagnose machine malfunctions."

                     },
                     {
                      title:"Detail Oriented",
                      innerText:"Plan and lay out repair work, using diagrams, drawings, blueprints, maintenance manuals, or schematic diagrams."
                     },
                     {
                      title:"Welding",
                      innerText:"Operate cutting torches or welding equipment to cut or join metal parts."

                     },
                     {
                      title:"Fabricate",
                      innerText:"Fabricate or repair counters, benches, partitions, or other wooden structures, such as sheds or outbuildings."
                     },
                     {
                      title:"Critical Thinking",
                      innerText:"Estimate costs to repair machinery, equipment, or building structures."
                     },
              ]
          },
          {


              jobtitle:"Precision Machinist",
              description:"Set up and operate a variety of machine tools to produce precision parts and instruments out of metal. Includes precision instrument makers who fabricate, modify, or repair mechanical instruments. May also fabricate and modify parts to make or repair machine tools or maintain industrial machines, applying knowledge of mechanics, mathematics, metal properties, layout, and machining procedures.",
              salary:"$49,900",
              education: "",
              id_gamification:"manufac-4",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/OWNXI09WI14",videoId:"OWNXI09WI14"},
                          
              communicationbuttons:[
                     {
                      title:"Accuracy",
                      innerText:"Calculate dimensions or tolerances, using instruments, such as micrometers or vernier calipers."
                      },
                     {
                      title:"Monitor",
                      innerText:"Monitor the feed and speed of machines during the machining process."
                     },
                     {
                      title:"Analysis",
                      innerText:"Study sample parts, blueprints, drawings, or engineering information to determine methods or sequences of operations needed to fabricate products."
                     },
                     {
                      title:"Organize",
                      innerText:"Separate scrap waste and related materials for reuse, recycling, or disposal."
                     },
                     {
                      title:"Design",
                      innerText:"Design fixtures, tooling, or experimental parts to meet special engineering needs."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Maintain machine tools in proper operational condition."
                     },
              ]
          },
          {


              jobtitle:"Welders",
              description:"Use hand-welding, flame-cutting, hand-soldering, or brazing equipment to weld or join metal components or to fill holes, indentations, or seams of fabricated metal products.",
              salary:"$48,600",
              education: "",
              id_gamification:"manufac-5",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/cETNESaLhcA",videoId:"cETNESaLhcA"},
                          
              communicationbuttons:[
                     {
                      title:"Welding",
                      innerText:"Weld components in flat, vertical, or overhead positions."
                      },
                     {
                      title:"Safety",
                      innerText:"Operate safety equipment and use safe work habits."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
                     },
                     {
                      title:"Hand Tools",
                      innerText:"Position and secure workpieces, using hoists, cranes, wire, and banding machines or hand tools."
                     },
                     {
                      title:"Analysis",
                      innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
                     },
                     {
                      title:"Design",
                      innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
                     },
              ]
          },
          {


              jobtitle:"Shipping and Receiving Materials Handler",
              description:"Manually move freight, stock, luggage, or other materials, or perform other general labor. Includes all manual laborers not elsewhere classified.",
              salary:"$28,400",
              education: "",
              id_gamification:"manufac-6",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/wQr_uQMpmEM",videoId:"wQr_uQMpmEM"},
                          
              communicationbuttons:[
                     {
                      title:"Detailed",
                      innerText:"Attach identifying tags to containers or mark them with identifying information."
                      },
                     {
                      title:"Task Oriented",
                      innerText:"Read work orders or receive oral instructions to determine work assignments or material or equipment needs."
                     },
                     {
                      title:"Physical Labor",
                      innerText:"Stack cargo in locations, such as transit sheds or in holds of ships as directed, using pallets or cargo boards."
                     },
                     {
                      title:"Safety",
                      innerText:"Guide loads being lifted to prevent swinging."
                     },
                     {
                      title:"Tool Usage",
                      innerText:"Rig or dismantle props or equipment, such as frames, scaffolding, platforms, or backdrops, using hand tools."
                     },
                     {
                      title:"Organized",
                      innerText:"Maintain equipment storage areas to ensure that inventory is protected."
                     },
              ]
          },
          {


              jobtitle:"Team Assemblers",
              description:"Work as part of a team having responsibility for assembling an entire product or component of a product. Team assemblers can perform all tasks conducted by the team in the assembly process and rotate through all or most of them, rather than being assigned to a specific task on a permanent basis. May participate in making management decisions affecting the work. Includes team leaders who work as part of the team.",
              salary:"$32,700",
              education: "",
              id_gamification:"manufac-7",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/TSn6ghApPKM",videoId:"TSn6ghApPKM"},
                          
              communicationbuttons:[
                     {
                      title:"Welding",
                      innerText:"Weld components in flat, vertical, or overhead positions."
                      },
                     {
                      title:"Safety",
                      innerText:"Operate safety equipment and use safe work habits."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
                     },
                     {
                      title:"Hand Tools",
                      innerText:"Position and secure workpieces, using hoists, cranes, wire, and banding machines or hand tools."
                     },
                     {
                      title:"Analysis",
                      innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
                     },
                     {
                      title:"Design",
                      innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
                     },
              ]
          },
          {


              jobtitle:"Production Workers",
              description:"Help production workers by performing duties requiring less skill. Duties include supplying or holding materials or tools, and cleaning work area and equipment.",
              salary:"$27,100",
              education: "",
              id_gamification:"manufac-8",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/cznEUK9d5uM",videoId:"cznEUK9d5uM"},
                          
              communicationbuttons:[
                     {
                      title:"Operate Machines",
                      innerText:"Operate machinery used in the production process, or assist machine operators."
                      },
                     {
                      title:"Analysis",
                      innerText:"Examine products to verify conformance to quality standards."
                     },
                     {
                      title:"Safety",
                      innerText:"Observe equipment operations so that malfunctions can be detected, and notify operators of any malfunctions."
                     },
                     {
                      title:"Detailed",
                      innerText:"Separate products according to weight, grade, size, or composition of materials used to produce them."
                     },
                     {
                      title:"Physical Labor",
                      innerText:"Perform minor repairs to machines, such as replacing damaged or worn parts."
                     },
                     {
                      title:"Cleanliness",
                      innerText:"Wash work areas, machines, equipment, vehicles, or products."
                     },
              ]
          },
          {


              jobtitle:"Production Manager",
              description:"Directly supervise and coordinate the activities of production and operating workers, such as inspectors, precision workers, machine setters and operators, assemblers, fabricators, and plant and system operators. Excludes team or work leaders.",
              salary:"$68,000",
              education: "",
              id_gamification:"manufac-9",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/dflNKhApmBE",videoId:"dflNKhApmBE"},
                          
              communicationbuttons:[
                     {
                      title:"Organized",
                      innerText:"Keep records of employees' attendance and hours worked."
                      },
                     {
                      title:"Detailed",
                      innerText:"Inspect materials, products, or equipment to detect defects or malfunctions."
                     },
                     {
                      title:"Communicate",
                      innerText:"Confer with other supervisors to coordinate operations and activities within or between departments."
                     },
                     {
                      title:"Training",
                      innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
                     },
                     {
                      title:"Leadership",
                      innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
                     },
                     {
                      title:"Analysis",
                      innerText:"Determine standards, budgets, production goals, and rates, based on company policies, equipment and labor availability, and workloads."
                     },
              ]
          },
          {


              jobtitle:"Industrial Machinery Mechanics",
              description:"Repair, install, adjust, or maintain industrial production and processing machinery or refinery and pipeline distribution systems. May also install, dismantle, or move machinery and heavy equipment according to plans.",
              salary:"$61,100",
              education: "",
              id_gamification:"manufac-10",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/ROJQXBuEnEY",videoId:"ROJQXBuEnEY"},
                          
              communicationbuttons:[
                     {
                      title:"Data Entry",
                      innerText:"Record parts or materials used and order or requisition new parts or materials, as necessary."
                      },
                     {
                      title:"Analyze",
                      innerText:"Observe and test the operation of machinery or equipment to diagnose malfunctions, using voltmeters or other testing devices."
                     },
                     {
                      title:"Detailed",
                      innerText:"Study blueprints or manufacturers' manuals to determine correct installation or operation of machinery."
                     },
                     {
                      title:"Welding",
                      innerText:"Cut and weld metal to repair broken metal parts, fabricate new parts, or assemble new equipment."
                     },
                     {
                      title:"Machine Usage",
                      innerText:"Repair or maintain the operating condition of industrial production or processing machinery or equipment."
                     },
                     {
                      title:"Organized",
                      innerText:"Assign schedules to work crews."
                     },
              ]
          },
          {


              jobtitle:"Paper Machine Operator",
              description:"Set up, operate, or tend paper goods machines that perform a variety of functions, such as converting, sawing, corrugating, banding, wrapping, boxing, stitching, forming, or sealing paper or paperboard sheets into products.",
              salary:"$45,000",
              education: "",
              id_gamification:"manufac-11",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/wGITtQuRcaE",videoId:"wGITtQuRcaE"},
                          
              communicationbuttons:[
                     {
                      title:"Troubleshoot",
                      innerText:"Examine completed work to detect defects and verify conformance to work orders, and adjust machinery as necessary to correct production problems."
                      },
                     {
                      title:"Analyze",
                      innerText:"Observe operation of various machines to detect and correct machine malfunctions such as improper forming, glue flow, or pasteboard tension."
                     },
                     {
                      title:"Tool Usage",
                      innerText:"Disassemble machines to maintain, repair, or replace broken or worn parts, using hand or power tools."
                     },
                     {
                      title:"Measuring",
                      innerText:"Measure, space, and set saw blades, cutters, and perforators, according to product specifications."
                     },
                     {
                      title:"Physical Labor",
                      innerText:"Lift tote boxes of finished cartons, and dump cartons into feed hoppers."
                     },
                     {
                      title:"Detailed",
                      innerText:"Fill glue and paraffin reservoirs, and position rollers to dispense glue onto paperboard."
                     },
              ]
          },
          {


              jobtitle:"Quality Control Inspector",
              description:"Inspect, test, sort, sample, or weigh nonagricultural raw materials or processed, machined, fabricated, or assembled parts or products for defects, wear, and deviations from specifications. May use precision measuring instruments and complex test equipment.",
              salary:"$46,500",
              education: "",
              id_gamification:"manufac-12",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/RCTxteXoiAA",videoId:"RCTxteXoiAA"},
                          
              communicationbuttons:[
                     {
                      title:"Analysis",
                      innerText:"Mark items with details, such as grade or acceptance-rejection status."
                      },
                     {
                      title:"Communicate",
                      innerText:"Notify supervisors or other personnel of production problems."
                     },
                     {
                      title:"Physical Labor",
                      innerText:"Make minor adjustments to equipment, such as turning setscrews to calibrate instruments to required tolerances."
                     },
                     {
                      title:"Solution Focused",
                      innerText:"Recommend necessary corrective actions, based on inspection results."
                     },
                     {
                      title:"Organized",
                      innerText:"Write test or inspection reports describing results, recommendations, or needed repairs."
                     },
                     {
                      title:"Detailed",
                      innerText:"Analyze test data, making computations as necessary, to determine test results."
                     },
              ]
          },
          {


              jobtitle:"Chemical Operator",
              description:"Operate or tend equipment to control chemical changes or reactions in the processing of industrial or consumer products. Equipment used includes devulcanizers, steam-jacketed kettles, and reactor vessels.",
              salary:"$60,000",
              education: "",
              id_gamification:"manufac-13",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/CN0N14XRJhM",videoId:"CN0N14XRJhM"},
                          
              communicationbuttons:[
                     {
                      title:"Data Entry",
                      innerText:"Record operational data, such as temperatures, pressures, ingredients used, processing times, or test results."
                      },
                     {
                      title:"Machine Usage",
                      innerText:"Control or operate equipment in which chemical changes or reactions take place during the processing of industrial or consumer products."
                     },
                     {
                      title:"Analysis",
                      innerText:"Draw samples of products at specified stages so that analyses can be performed."
                     },
                     {
                      title:"Measuring",
                      innerText:"Measure, weigh, and mix chemical ingredients, according to specifications."
                     },
                     {
                      title:"Detailed",
                      innerText:"Read plant specifications to determine products, ingredients, or prescribed modifications of plant procedures."
                     },
                     {
                      title:"Perceptive",
                      innerText:"Patrol work areas to detect leaks or equipment malfunctions or to monitor operating conditions."
                     },
              ]
          }


         ],
     
        description:{
          aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
        }
         
    },
    public_service:{
      mediaInfo:{
        videoType:'youtube',link:"https://www.youtube.com/watch?v=cUIAZcjjB_g&list=TLPQMjQwMzIwMjHqCZpyRvoORg",videoId:'cUIAZcjjB_g',imageOrVideo:'image',srcOrHref:'public-safety-careers'
      },
        jobs:[ 
            {
              jobtitle:'Patrol Officers',
              salary:"$45,000",
              description:"Maintain order and protect life and property by enforcing local, tribal, state, or federal laws and ordinances. Perform a combination of the following duties: patrol a specific area; direct traffic; issue traffic summonses; investigate accidents; apprehend and arrest suspects, or serve legal processes of courts. Includes police officers working at educational institutions.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'14D91NPvMz8',
              srcOrHref:''},
              education:"",
              id_gamification:"ps-1",
              communicationbuttons:[
                     {
                      title:"Apprehend Suspects",
                      innerText:"Identify, pursue, and arrest suspects and perpetrators of criminal acts."
                     },
                     {
                      title:"Public Safety",
                      innerText:"Provide for public safety by maintaining order, responding to emergencies, protecting people and property, enforcing motor vehicle and criminal laws, and promoting good community relations."
                     },
                    {
                      title:"Active Listening",
                      innerText:"Record facts to prepare reports that document incidents and activities."
                     },
                     {
                      title:"First Aid",
                      innerText:"Render aid to accident victims and other persons requiring first aid for physical injuries."
                     },
                     {
                      title:"Investigate",
                      innerText:"Investigate illegal or suspicious activities."
                     },
                     {
                      title:"Weapons & Tools",
                      innerText:"Use handguns, handcuffs, police vehicles, surveillance video and audio recorders, and two-way radios."
                     },
              ]
          },
                    {



              jobtitle:'Firefighters',
              salary:"$35,800",
              description:"Control and extinguish fires or respond to emergency situations where life, property, or the environment is at risk. Duties may include fire prevention, emergency medical service, hazardous material response, search and rescue, and disaster assistance.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'DNK5dYGn3Fg',
              srcOrHref:''},
              education:"",
              id_gamification:"ps-2",
              communicationbuttons:[
                     {
                      title:"Critical Thinking",
                      innerText:"Assess fires and situations and report conditions to superiors to receive instructions, using two-way radios."
                     },
                     {
                      title:"Hand Tools                   ",
                      innerText:"Create openings in buildings for ventilation or entrance, using axes, chisels, crowbars, electric saws, or core cutters."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Inspect fire sites after flames have been extinguished to ensure that there is no further danger."
                     },
                     {
                      title:"Judgement",
                      innerText:"Move toward the source of a fire, using knowledge of types of fires, construction design, building materials, and physical layout of properties."
                     },
                     {
                      title:"Collaborate",
                      innerText:"Collaborate with police to respond to accidents, disasters, and arson investigation calls."
                     },
                     {
                      title:"Physical Fitness",
                      innerText:"Participate in physical training activities to maintain a high level of physical fitness."
                     },
              ]
          },
          {


              jobtitle:"Corrections Officer",
              description:"Guard inmates in penal or rehabilitative institutions in accordance with established regulations and procedures. May guard prisoners in transit between jail, courtroom, prison, or other point. Includes deputy sheriffs and police who spend the majority of their time guarding prisoners in correctional institutions.",
              salary:"$35,800",
              education: "",
              id_gamification:"ps-3",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/Vcnk_bSJsAc",videoId:"Vcnk_bSJsAc"},
                          
              communicationbuttons:[
                     {
                      title:"Observation",
                      innerText:"Conduct head counts to ensure that each prisoner is present."
                      },
                     {
                      title:"Detail Oriented",
                      innerText:"Inspect conditions of locks, window bars, grills, doors, and gates at correctional facilities to ensure security and help prevent escapes."
                     },
                     {
                      title:"Organized",
                      innerText:"Maintain records of prisoners' identification and charges."
                     },
                     {
                      title:"Writing",
                      innerText:"Record information, such as prisoner identification, charges, and incidents of inmate disturbance, keeping daily logs of prisoner activities."
                     },
                     {
                      title:"Weapon Usage",
                      innerText:"Use weapons, handcuffs, and physical force to maintain discipline and order among prisoners."
                     },
                     {
                      title:"Physical Strength",
                      innerText:"Search for and recapture escapees."
                     },
              ]
          },
          {


              jobtitle:"Administrative Assistant",
              description:"Perform routine administrative functions such as drafting correspondence, scheduling appointments, organizing and maintaining paper and electronic files, or providing information to callers.",
              salary:"$35,500",
              education: "",
              id_gamification:"ps-4",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/zDqK9DaCDg4",videoId:"zDqK9DaCDg4"},
                          
              communicationbuttons:[
                     {
                      title:"Communicate",
                      innerText:"Answer telephones and give information to callers, take messages, or transfer calls to appropriate individuals."
                      },
                     {
                      title:"People Skills",
                      innerText:"Greet visitors or callers and handle their inquiries or direct them to the appropriate persons according to their needs."
                     },
                     {
                      title:"Data Entry",
                      innerText:"Create, maintain, and enter information into databases."
                     },
                     {
                      title:"Computer Usage",
                      innerText:"Use computers for various applications, such as database management or word processing."
                     },
                     {
                      title:"Organized",
                      innerText:"Establish work procedures or schedules and keep track of the daily work of clerical staff."
                     },
                     {
                      title:"Supervise",
                      innerText:"Supervise other clerical staff and provide training and orientation to new staff."
                     },
              ]
          },
          {


              jobtitle:"Highway Maintenance Worker",
              description:"Maintain highways, municipal and rural roads, airport runways, and rights-of-way. Duties include patching broken or eroded pavement and repairing guard rails, highway markers, and snow fences. May also mow or clear brush from along road, or plow snow from roadway.",
              salary:"$31,500",
              education: "",
              id_gamification:"ps-5",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/HJ2naWZeg-s",videoId:"HJ2naWZeg-s"},
                          
              communicationbuttons:[
                     {
                      title:"Driving",
                      innerText:"Drive trucks to transport crews and equipment to work sites."
                      },
                     {
                      title:"Tool Usage",
                      innerText:"Erect, install, or repair guardrails, road shoulders, berms, highway markers, warning signals, and highway lighting, using hand tools and power tools."
                     },
                     {
                      title:"Safety",
                      innerText:"Set out signs and cones around work areas to divert traffic."
                     },
                     {
                      title:"Physical Labor",
                      innerText:"Haul and spread sand, gravel, and clay to fill washouts and repair road shoulders."
                     },
                     {
                      title:"Landscaping",
                      innerText:"Perform roadside landscaping work, such as clearing weeds and brush, and planting and trimming trees."
                     },
                     {
                      title:"Painting",
                      innerText:"Paint traffic control lines and place pavement traffic messages, by hand or using machines."
                     },
              ]
          },
          {


              jobtitle:"Office Clerk",
              description:"Perform duties too varied and diverse to be classified in any specific office clerical occupation, requiring knowledge of office systems and procedures. Clerical duties may be assigned in accordance with the office procedures of individual establishments and may include a combination of answering telephones, bookkeeping, typing or word processing, office machine operation, and filing.",
              salary:"$25,500",
              education: "",
              id_gamification:"ps-6",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/918owLdYnts",videoId:"918owLdYnts"},
                          
              communicationbuttons:[
                     {
                      title:"Computer Usage",
                      innerText:"Operate office machines, such as photocopiers and scanners, facsimile machines, voice mail systems, and personal computers."
                      },
                     {
                      title:"Data Entry",
                      innerText:"Maintain and update filing, inventory, mailing, and database systems, either manually or using a computer."
                     },
                     {
                      title:"Organized",
                      innerText:"Compile, copy, sort, and file records of office activities, business transactions, and other activities."
                     },
                     {
                      title:"Communicate",
                      innerText:"Answer telephones, direct calls, and take messages."
                     },
                     {
                      title:"Finance",
                      innerText:"Collect, count, and disburse money, do basic bookkeeping, and complete banking transactions."
                     },
                     {
                      title:"Reporting",
                      innerText:"Process and prepare documents, such as business or government forms and expense reports."
                     },
              ]
          },
          {


              jobtitle:"City Clerk",
              description:"Perform clerical duties for courts of law, municipalities, or governmental licensing agencies and bureaus. May prepare docket of cases to be called; secure information for judges and court; prepare draft agendas or bylaws for town or city council; answer official correspondence; keep fiscal records and accounts; issue licenses or permits; and record data, administer tests, or collect fees.",
              salary:"$34,900",
              education: "",
              id_gamification:"ps-7",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/jrJPzRAEtHE",videoId:"jrJPzRAEtHE"},
                          
              communicationbuttons:[
                     {
                      title:"Detailed",
                      innerText:"Verify the authenticity of documents, such as foreign identification or immigration documents."
                      },
                     {
                      title:"Data Entry",
                      innerText:"Record and edit the minutes of meetings and distribute to appropriate officials or staff members."
                     },
                     {
                      title:"Communicate",
                      innerText:"Question applicants to obtain required information, such as name, address, or age, and record data on prescribed forms."
                     },
                     {
                      title:"Organized",
                      innerText:"Record and maintain all vital and fiscal records and accounts."
                     },
                     {
                      title:"Finance",
                      innerText:"Perform budgeting duties, such as assisting in budget preparation, expenditure review, or budget administration."
                     },
                     {
                      title:"Research",
                      innerText:"Research information in the municipal archives upon request of public officials or private citizens."
                     },
              ]
          },
          {


              jobtitle:"Dispatchers",
              description:"Operate telephone, radio, or other communication systems to receive and communicate requests for emergency assistance at 9-1-1 public safety answering points and emergency operations centers. Take information from the public and other sources regarding crimes, threats, disturbances, acts of terrorism, fires, medical emergencies, and other public safety matters. May coordinate and provide information to law enforcement and emergency response personnel.",
              salary:"$31,600",
              education: "",
              id_gamification:"ps-8",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/mFB5YEzQUfI",videoId:"mFB5YEzQUfI"},
                          
              communicationbuttons:[
                     {
                      title:"Communicate",
                      innerText:"Question callers to determine their locations, and the nature of their problems to determine type of response needed."
                      },
                     {
                      title:"Analysis",
                      innerText:"Determine response requirements and relative priorities of situations, and dispatch units in accordance with established procedures."
                     },
                     {
                      title:"Data Entry",
                      innerText:"Record details of calls, dispatches, and messages."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Test and adjust communication and alarm systems, and report malfunctions to maintenance units."
                     },
                     {
                      title:"Safety",
                      innerText:"Maintain access to, and security of, highly sensitive materials."
                     },
                     {
                      title:"Monitoring",
                      innerText:"Monitor alarm systems to detect emergencies, such as fires and illegal entry into establishments."
                     },
              ]
          },
          {


              jobtitle:"Case Manager",
              description:"Determine eligibility of persons applying to receive assistance from government programs and agency resources, such as welfare, unemployment benefits, social security, and public housing.",
              salary:"$39,800",
              education: "",
              id_gamification:"ps-9",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/WXVsjrQzx-E",videoId:"WXVsjrQzx-E"},
                          
              communicationbuttons:[
                     {
                      title:"Active Listening",
                      innerText:"Interview benefits recipients at specified intervals to certify their eligibility for continuing benefits."
                      },
                     {
                      title:"Organized",
                      innerText:"Keep records of assigned cases, and prepare required reports."
                     },
                     {
                      title:"Data Entry",
                      innerText:"Compile, record, and evaluate personal and financial data to verify completeness and accuracy, and to determine eligibility status."
                     },
                     {
                      title:"Communicate",
                      innerText:"Answer applicants' questions about benefits and claim procedures."
                     },
                     {
                      title:"Reporting",
                      innerText:"Conduct annual, interim, and special housing reviews and home visits to ensure conformance to regulations."
                     },
                     {
                      title:"Finance",
                      innerText:"Compute and authorize amounts of assistance for programs, such as grants, monetary payments, and food stamps."
                     },
              ]
          },
          {


              jobtitle:"Recreation Workers",
              description:"Conduct recreation activities with groups in public, private, or volunteer agencies or recreation facilities. Organize and promote activities, such as arts and crafts, sports, games, music, dramatics, social recreation, camping, and hobbies, taking into account the needs and interests of individual members.",
              salary:"$24,900",
              education: "",
              id_gamification:"ps-10",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/e33-81TNf8E",videoId:"e33-81TNf8E"},
                          
              communicationbuttons:[
                     {
                      title:"Authority",
                      innerText:"Enforce rules and regulations of recreational facilities to maintain discipline and ensure safety."
                      },
                     {
                      title:"Medical",
                      innerText:"Administer first aid according to prescribed procedures and notify emergency medical personnel when necessary."
                     },
                     {
                      title:"Event Planning",
                      innerText:"Direct special activities or events, such as aquatics, gymnastics, or performing arts."
                     },
                     {
                      title:"Social Skills",
                      innerText:"Greet new arrivals to activities, introducing them to other participants, explaining facility rules, and encouraging participation."
                     },
                     {
                      title:"Project Manage",
                      innerText:"Oversee the purchase, planning, design, construction, and upkeep of recreation facilities and areas."
                     },
                     {
                      title:"Analysis",
                      innerText:"Evaluate recreation areas, facilities, and services to determine if they are producing desired results."
                     },
              ]
          }

         ],
     
        description:{
          aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
        }
         
    },
    safety:{
      mediaInfo:{
        videoType:'youtube',link:"https://www.youtube.com/watch?v=cUIAZcjjB_g&list=TLPQMjQwMzIwMjHqCZpyRvoORg",videoId:'cUIAZcjjB_g',imageOrVideo:'image',srcOrHref:'safety-careers'
      },
        jobs:[ 
            {
              jobtitle:'Occupational Safety & Health',
              salary:"$46,000 - $104,000",
              description:"Collect data on work environments for analysis by occupational health and safety specialists. Implement and conduct evaluation of programs designed to limit chemical, physical, biological, and ergonomic risks to workers.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'8qj18Q4EhUQ',
              srcOrHref:''},
              education:"",
              id_gamification:"safety-1",
              communicationbuttons:[
                     {
                      title:"Quality Control",
                      innerText:"Test workplaces for environmental hazards, such as exposure to radiation, chemical or biological hazards, or excessive noise."
                     },
                     {
                      title:"Cleanliness",
                      innerText:"Prepare or calibrate equipment used to collect or analyze samples."
                     },
                    {
                      title:"Analysis",
                      innerText:"Conduct worker studies to determine whether specific instances of disease or illness are job-related."
                     },
                     {
                      title:"Problem Solving",
                      innerText:"Recommend corrective measures to be applied based on results of environmental contaminant analyses."
                     },
                     {
                      title:"Organized",
                      innerText:"Prepare documents to be used in legal proceedings, testifying in such proceedings when necessary."
                     },
                     {
                      title:"Instructing",
                      innerText:"Train workers in safety procedures related to green jobs, such as the use of fall protection devices or maintenance of proper ventilation during wind turbine construction."
                     },
              ]
          },
                    {



              jobtitle:'Construction Safety Specialist',
              salary:"$46,000 - $104,000",
              description:"Inspect structures using engineering skills to determine structural soundness and compliance with specifications, building codes, and other regulations. Inspections may be general in nature or may be limited to a specific area, such as electrical systems or plumbing.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',

              videoId:'PgnWqyol0Pk',
              srcOrHref:''},
              education:"",
              id_gamification:"safety-2",
              communicationbuttons:[
                     {
                      title:"Observant",
                      innerText:"Monitor installation of plumbing, wiring, equipment, or appliances to ensure that installation is performed properly and is in compliance with applicable regulations."
                     },
                     {
                      title:"Organized",
                      innerText:"Maintain daily logs and supplement inspection records with photographs."
                     },
                     {
                      title:"Equipment Usage",
                      innerText:"Conduct inspections, using survey instruments, metering devices, tape measures, or test equipment."
                     },
                     {
                      title:"Detail Oriented",
                      innerText:"Review and interpret plans, blueprints, site layouts, specifications, or construction methods to ensure compliance to legal requirements and safety regulations."
                     },
                     {
                      title:"Cleanliness",
                      innerText:"Evaluate premises for cleanliness, such as proper garbage disposal or lack of vermin infestation."
                     },
                     {
                      title:"Communicate",
                      innerText:"Confer with owners, violators, or authorities to explain regulations or recommend remedial actions."
                     },
              ]
          },
          {


              jobtitle:"Industrial Hygienist",
              description:"Promote worksite or product safety by applying knowledge of industrial processes, mechanics, chemistry, psychology, and industrial health and safety laws. Includes industrial product safety engineers.",
              salary:"$46,000 - $104,000",
              education: "",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"Z9gbU7KPAOg"},
              id_gamification:"safety-3",
              communicationbuttons:[
                     {
                      title:"Analysis",
                      innerText:"Conduct research to evaluate safety levels for products."
                      },
                     {
                      title:"Instructing",
                      innerText:"Conduct or coordinate worker training in areas such as safety laws and regulations, hazardous condition monitoring, and use of safety equipment."
                     },
                     {
                      title:"Active Learning",
                      innerText:"Maintain and apply knowledge of current policies, regulations, and industrial processes."
                     },
                     {
                      title:"Communicate",
                      innerText:"Report or review findings from accident investigations, facilities inspections, or environmental testing."
                     },
                     {
                      title:"Active Listening",
                      innerText:"Interview employers and employees to obtain information about work environments and workplace incidents."
                     },
                     {
                      title:"Organized",
                      innerText:"Compile, analyze, and interpret statistical data related to occupational illnesses and accidents."
                     },
              ]
          }

         ],
     
        description:{
          aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
        }
         
    },
    shipbuilding_and_repair:{
      mediaInfo:{
        videoType:'youtube',link:"https://www.youtube.com/watch?v=cUIAZcjjB_g&list=TLPQMjQwMzIwMjHqCZpyRvoORg",videoId:'E1t39-yp6YQ',imageOrVideo:'video',srcOrHref:'aerospace-careers'
      },
        jobs:[ 
            {
              jobtitle:'Marine Engineer',
              salary:"$85,700",
              description:"Design, develop, and evaluate the operation of marine vessels, ship machinery, and related equipment, such as power supply and propulsion systems.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'445nfaA6gdI',
              srcOrHref:''},
              education:"",
              id_gamification:"sar-1",
              communicationbuttons:[
                     {
                      title:"Design",
                      innerText:"Design complete hull and superstructure according to specifications and test data, in conformity with standards of safety, efficiency, and economy."
                     },
                     {
                      title:"Supervise",
                      innerText:"Supervise other engineers and crew members and train them for routine and emergency duties."
                     },
                    {
                      title:"Analysis",
                      innerText:"Study design proposals and specifications to establish basic characteristics of craft, such as size, weight, speed, propulsion, displacement, and draft."
                     },
                     {
                      title:"Problem Solving",
                      innerText:"Evaluate performance of craft during dock and sea trials to determine design changes and conformance with national and international standards."
                     },
                     {
                      title:"Organized",
                      innerText:"Prepare plans, estimates, design and construction schedules, and contract specifications, including any special provisions."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Conduct environmental, operational, or performance tests on marine machinery and equipment."
                     },
              ]
          },
                    {



              jobtitle:'Welder',
              salary:"$53,200",
              description:"Use hand-welding, flame-cutting, hand-soldering, or brazing equipment to weld or join metal components or to fill holes, indentations, or seams of fabricated metal products.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'Ydr62_uFPrA',
              srcOrHref:''},
              education:"",
              id_gamification:"sar-2",
              communicationbuttons:[
                     {
                      title:"Welding",
                      innerText:"Weld components in flat, vertical, or overhead positions."
                     },
                     {
                      title:"Safety",
                      innerText:"Operate safety equipment and use safe work habits."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
                     },
                     {
                      title:"Hand Tools",
                      innerText:"Position and secure workpieces, using hoists, cranes, wire, and banding machines or hand tools."
                     },
                     {
                      title:"Analysis",
                      innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
                     },
                     {
                      title:"Design",
                      innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
                     },
              ]
          },
          {


              jobtitle:"Carpenter",
              description:"Construct, erect, install, or repair structures and fixtures made of wood and comparable materials, such as concrete forms; building frameworks, including partitions, joists, studding, and rafters; and wood stairways, window and door frames, and hardwood floors. May also install cabinets, siding, drywall, and batt or roll insulation. Includes brattice builders who build doors or brattices (ventilation walls or partitions) in underground passageways.",
              salary:"$23,500 - $55,000",
              education: "",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"MWfr-7hUQ8c"},
              id_gamification:"sar-3",           
              communicationbuttons:[
                     {
                      title:"Safety",
                      innerText:"Follow established safety rules and regulations and maintain a safe and clean environment."
                      },
                     {
                      title:"Analysis",
                      innerText:"Study specifications in blueprints, sketches, or building plans to prepare project layout and determine dimensions and materials required."
                     },
                     {
                      title:"Measuring",
                      innerText:"Measure and mark cutting lines on materials, using a ruler, pencil, chalk, and marking gauge."
                     },
                     {
                      title:"Hand Tools",
                      innerText:"Shape or cut materials to specified measurements, using hand tools, machines, or power saws."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Verify trueness of structure, using plumb bob and level."
                     },
                     {
                      title:"Organization",
                      innerText:"Maintain records, document actions, and present written progress reports."
                     },
              ]
          },
          {


              jobtitle:"Plumbers, Pipefitters, and Steamfitters",
              description:"Assemble, install, alter, and repair pipelines or pipe systems that carry water, steam, air, or other liquids or gases. May install heating and cooling equipment and mechanical control systems. Includes sprinkler fitters.",
              salary:"$51,500",
              education: "",
              id_gamification:"sar-4",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/uF3-2wFnf0E",videoId:"uF3-2wFnf0E"},
                          
              communicationbuttons:[
                     {
                      title:"Hand Tools",
                      innerText:"Cut, thread, or hammer pipes to specifications, using tools such as saws, cutting torches, pipe threaders, or pipe benders."
                      },
                     {
                      title:"Organized",
                      innerText:"Lay out full scale drawings of pipe systems, supports, or related equipment, according to blueprints."
                     },
                     {
                      title:"Analysis",
                      innerText:"Inspect, examine, or test installed systems or pipe lines, using pressure gauge, hydrostatic testing, observation, or other methods."
                     },
                     {
                      title:"Supervise",
                      innerText:"Direct helpers engaged in pipe cutting, preassembly, or installation of plumbing systems or components."
                     },
                     {
                      title:"Welding",
                      innerText:"Weld small pipes or special piping, using specialized techniques, equipment, or materials, such as computer-assisted welding or microchip fabrication."
                     },
                     {
                      title:"Organized",
                      innerText:"Estimate time, material, or labor costs for use in project plans."
                     },
              ]
          },
          {


              jobtitle:"Team Assemblers",
              description:"Work as part of a team having responsibility for assembling an entire product or component of a product. Team assemblers can perform all tasks conducted by the team in the assembly process and rotate through all or most of them, rather than being assigned to a specific task on a permanent basis. May participate in making management decisions affecting the work. Includes team leaders who work as part of the team.",
              salary:"$44,500",
              education: "",
              id_gamification:"sar-5",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/TSn6ghApPKM",videoId:"TSn6ghApPKM"},
                          
              communicationbuttons:[
                     {
                      title:"Welding",
                      innerText:"Weld components in flat, vertical, or overhead positions."
                      },
                     {
                      title:"Safety",
                      innerText:"Operate safety equipment and use safe work habits."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
                     },
                     {
                      title:"Hand Tools",
                      innerText:"Position and secure workpieces, using hoists, cranes, wire, and banding machines or hand tools."
                     },
                     {
                      title:"Analysis",
                      innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
                     },
                     {
                      title:"Design",
                      innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
                     },
              ]
          },
          {


              jobtitle:"Electrician",
              description:"Install, maintain, and repair electrical wiring, equipment, and fixtures. Ensure that work is in accordance with relevant codes. May install or service street lights, intercom systems, or electrical control systems.",
              salary:"$51,600",
              education: "",
              id_gamification:"sar-6",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/l6IWPbx4ZXw",videoId:"l6IWPbx4ZXw"},
                          
              communicationbuttons:[
                     {
                      title:"Critical Analysis",
                      innerText:"Plan layout and installation of electrical wiring, equipment, or fixtures, based on job specifications and local codes."
                      },
                     {
                      title:"Design",
                      innerText:"Prepare sketches or follow blueprints to determine the location of wiring or equipment and to ensure conformance to building and safety codes."
                     },
                     {
                      title:"Troubleshoot",
                      innerText:"Diagnose malfunctioning systems, apparatus, or components, using test equipment and hand tools to locate the cause of a breakdown and correct the problem."
                     },
                     {
                      title:"Instructing",
                      innerText:"Advise management on whether continued operation of equipment could be hazardous."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Inspect electrical systems, equipment, or components to identify hazards, defects, or the need for adjustment or repair, and to ensure compliance with codes."
                     },
                     {
                      title:"Hand Tools",
                      innerText:"Repair or replace wiring, equipment, or fixtures, using hand tools or power tools."
                     },
              ]
          },
          {


              jobtitle:"Production Manager",
              description:"Directly supervise and coordinate the activities of production and operating workers, such as inspectors, precision workers, machine setters and operators, assemblers, fabricators, and plant and system operators. Excludes team or work leaders.",
              salary:"$73,300",
              education: "",
              id_gamification:"sar-7",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/dflNKhApmBE",videoId:"dflNKhApmBE"},
                          
              communicationbuttons:[
                     {
                      title:"Organized",
                      innerText:"Keep records of employees' attendance and hours worked."
                      },
                     {
                      title:"Detailed",
                      innerText:"Inspect materials, products, or equipment to detect defects or malfunctions."
                     },
                     {
                      title:"Communicate",
                      innerText:"Confer with other supervisors to coordinate operations and activities within or between departments."
                     },
                     {
                      title:"Training",
                      innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
                     },
                     {
                      title:"Leadership",
                      innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
                     },
                     {
                      title:"Analysis",
                      innerText:"Determine standards, budgets, production goals, and rates, based on company policies, equipment and labor availability, and workloads."
                     },
              ]
          },
          {


              jobtitle:"Precision Machinist",
              description:"Set up and operate a variety of machine tools to produce precision parts and instruments out of metal. Includes precision instrument makers who fabricate, modify, or repair mechanical instruments. May also fabricate and modify parts to make or repair machine tools or maintain industrial machines, applying knowledge of mechanics, mathematics, metal properties, layout, and machining procedures.",
              salary:"$52,400",
              education: "",
              id_gamification:"sar-8",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/OWNXI09WI14",videoId:"OWNXI09WI14"},
                          
              communicationbuttons:[
                     {
                      title:"Accuracy",
                      innerText:"Calculate dimensions or tolerances, using instruments, such as micrometers or vernier calipers."
                      },
                     {
                      title:"Monitor",
                      innerText:"Monitor the feed and speed of machines during the machining process."
                     },
                     {
                      title:"Analysis",
                      innerText:"Study sample parts, blueprints, drawings, or engineering information to determine methods or sequences of operations needed to fabricate products."
                     },
                     {
                      title:"Organize",
                      innerText:"Separate scrap waste and related materials for reuse, recycling, or disposal."
                     },
                     {
                      title:"Design",
                      innerText:"Design fixtures, tooling, or experimental parts to meet special engineering needs."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Maintain machine tools in proper operational condition."
                     },
              ]
          },
          {


              jobtitle:"Painter",
              description:"Paint walls, equipment, buildings, bridges, and other structural surfaces, using brushes, rollers, and spray guns. May remove old paint to prepare surface prior to painting. May mix colors or oils to obtain desired color or consistency.",
              salary:"$47,900",
              education: "",
              id_gamification:"sar-9",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/MhpyAMBkwIQ",videoId:"MhpyAMBkwIQ"},
                          
              communicationbuttons:[
                     {
                      title:"Detailed",
                      innerText:"Fill cracks, holes, or joints with caulk, putty, plaster, or other fillers, using caulking guns or putty knives."
                      },
                     {
                      title:"Cautious",
                      innerText:"Cover surfaces with dropcloths or masking tape and paper to protect surfaces during painting."
                     },
                     {
                      title:"Finishing",
                      innerText:"Smooth surfaces, using sandpaper, scrapers, brushes, steel wool, or sanding machines."
                     },
                     {
                      title:"Painting",
                      innerText:"Apply primers or sealers to prepare new surfaces, such as bare wood or metal, for finish coats."
                     },
                     {
                      title:"Physical Labor",
                      innerText:"Erect scaffolding or swing gates, or set up ladders, to work above ground level."
                     },
                     {
                      title:"Artistic",
                      innerText:"Mix and match colors of paint, stain, or varnish with oil or thinning and drying additives to obtain desired colors and consistencies."
                     },
                    
              ]
          },
          {


              jobtitle:"Fabricator",
              description:"Fabricate, position, align, and fit parts of structural metal products.",
              salary:"$46,000",
              education: "",
              id_gamification:"sar-10",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/Pe3n9-r8TP0",videoId:"Pe3n9-r8TP0"},
                          
              communicationbuttons:[
                     {
                      title:"Measuring",
                      innerText:"Verify conformance of workpieces to specifications, using squares, rulers, and measuring tapes."
                      },
                     {
                      title:"Analyze",
                      innerText:"Study engineering drawings and blueprints to determine materials requirements and task sequences."
                     },
                     {
                      title:"Detailed",
                      innerText:"Remove high spots and cut bevels, using hand files, portable grinders, and cutting torches."
                     },
                     {
                      title:"Design",
                      innerText:"Design and construct templates and fixtures, using hand tools."
                     },
                     {
                      title:"Tool Usage",
                      innerText:"Hammer, chip, and grind workpieces to cut, bend, and straighten metal."
                     },
                     {
                      title:"Welding",
                      innerText:"Tack-weld fitted parts together."
                     },
              ]
          },
          {


              jobtitle:"Layout Workers, Metal and Plastic",
              description:"Lay out reference points and dimensions on metal or plastic stock or workpieces, such as sheets, plates, tubes, structural shapes, castings, or machine parts, for further processing. Includes shipfitters.",
              salary:"$41,900",
              education: "",
              id_gamification:"sar-11",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/176-HGCTLqE",videoId:"176-HGCTLqE"},
                          
              communicationbuttons:[
                     {
                      title:"Measuring",
                      innerText:"Locate center lines and verify template positions, using measuring instruments such as gauge blocks, height gauges, and dial indicators."
                      },
                     {
                      title:"Analyze",
                      innerText:"Add dimensional details to blueprints or drawings made by other workers."
                     },
                     {
                      title:"Physical Labor",
                      innerText:"Lift and position workpieces in relation to surface plates, manually or with hoists, and using parallel blocks and angle plates."
                     },
                     {
                      title:"Design",
                      innerText:"Design and prepare templates of wood, paper, or metal."
                     },
                     {
                      title:"Tool Usage",
                      innerText:"Mark curves, lines, holes, dimensions, and welding symbols onto workpieces, using scribes, soapstones, punches, and hand drills."
                     },
                     {
                      title:"Drafting",
                      innerText:"Plan and develop layouts from blueprints and templates, applying knowledge of trigonometry, design, effects of heat, and properties of metals."
                     },
              ]
          }

         ],
     
        description:{
          aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
        }
         
    },
    transportation_distribution_and_logistics:{
      mediaInfo:{
        videoType:'youtube',link:"https://www.youtube.com/watch?v=cUIAZcjjB_g&list=TLPQMjQwMzIwMjHqCZpyRvoORg",videoId:'w0wDnQP1dn8',imageOrVideo:'video',srcOrHref:'aerospace-careers'
      },
        jobs:[ 
            {
              jobtitle:'Truck Drivers',
              salary:"$40,100",
              description:"Drive a tractor-trailer combination or a truck with a capacity of at least 26,001 pounds Gross Vehicle Weight (GVW). May be required to unload truck. Requires commercial drivers' license. Includes tow truck drivers.",
              jobMedia:{imageOrVideo:'video',
              videoType:'youtube',
              videoId:'yz8Oku9fQfQ',
              srcOrHref:''},
              education:"",
              id_gamification:"tdal-1",
              communicationbuttons:[
                     {
                      title:"Quality Control",
                      innerText:"Check vehicles to ensure that mechanical, safety, and emergency equipment is in good working order."
                     },
                     {
                      title:"Organization",
                      innerText:"Maintain logs of working hours or of vehicle service or repair status, following applicable state and federal regulations."
                     },
                    {
                      title:"Detail Oriented",
                      innerText:"Check all load-related documentation for completeness and accuracy."
                     },
                     {
                      title:"Vehicle Operation",
                      innerText:"Maneuver trucks into loading or unloading positions, following signals from loading crew and checking that vehicle and loading equipment are properly positioned."
                     },
                     {
                      title:"Vehicle Maintenance",
                      innerText:"Perform basic vehicle maintenance tasks, such as adding oil, fuel, or radiator fluid or performing minor repairs."
                     },
                     {
                      title:"Critical Thinking",
                      innerText:"Plan or adjust routes based on changing conditions, using computer equipment, global positioning systems (GPS) equipment, or other navigation devices, to minimize fuel consumption and carbon emissions."
                     },
              ]
          },
          {


              jobtitle:"Crane Operator",
              description:"Operate mechanical boom and cable or tower and cable equipment to lift and move materials, machines, or products in many directions.",
              salary:"$37,000 - $63,000",
              education: "",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"t1fclgajTEs"
              },
              id_gamification:"tdal-3",      
              communicationbuttons:[
                     {
                      title:"Judgement ",
                      innerText:"Determine load weights and check them against lifting capacities to prevent overload."
                      },
                     {
                      title:"Machine Operation",
                      innerText:"Move levers, depress foot pedals, or turn dials to operate cranes, cherry pickers, electromagnets, or other moving equipment for lifting, moving, or placing loads."
                     },
                     {
                      title:"Quality Control",
                      innerText:"Inspect and adjust crane mechanisms or lifting accessories to prevent malfunctions or damage."
                     },
                     {
                      title:"Maintenance",
                      innerText:"Inspect cables or grappling devices for wear and install or replace cables, as needed."
                     },
                     {
                      title:"Teamwork",
                      innerText:"Direct helpers engaged in placing blocking or outrigging under cranes."
                     },
                     {
                      title:"Troubleshoot",
                      innerText:"Clean, lubricate, and maintain mechanisms such as cables, pulleys, or grappling devices, making repairs, as necessary."
                     },
              ]
          },
          {


              jobtitle:"Bus Driver",
              description:"Drive bus or motor coach, including regular route operations, charters, and private carriage. May assist passengers with baggage. May collect fares or tickets.",
              salary:"$20,300",
              education: "",
              id_gamification:"tdal-4",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/52b8l3plMBE",videoId:"52b8l3plMBE"},
                          
              communicationbuttons:[
                     {
                      title:"Driving",
                      innerText:"Park vehicles at loading areas so that passengers can board."
                      },
                     {
                      title:"Detailed",
                      innerText:"Inspect vehicles and check gas, oil, and water levels prior to departure."
                     },
                     {
                      title:"Physical Labor",
                      innerText:"Assist passengers, such as elderly or disabled individuals, on and off bus"
                     },
                     {
                      title:"Safety",
                      innerText:"Handle passenger emergencies or disruptions."
                     },
                     {
                      title:"Data Entry",
                      innerText:"Record information, such as cash receipts and ticket fares, and maintain log book."
                     },
                     {
                      title:"Cleaning",
                      innerText:"Maintain cleanliness of bus or motor coach."
                     },
              ]
          },
          {


              jobtitle:"Mail Carrier",
              description:"Sort and deliver mail for the United States Postal Service (USPS). Deliver mail on established route by vehicle or on foot. Includes postal service mail carriers employed by USPS contractors.",
              salary:"$51,500",
              education: "",
              id_gamification:"tdal-5",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/ddvVveMJ2mw",videoId:"ddvVveMJ2mw"},
                          
              communicationbuttons:[
                     {
                      title:"Detailed",
                      innerText:"Sort mail for delivery, arranging it in delivery sequence."
                      },
                     {
                      title:"Responsible",
                      innerText:"Meet schedules for the collection and return of mail."
                     },
                     {
                      title:"Organized",
                      innerText:"Turn in money and receipts collected along mail routes."
                     },
                     {
                      title:"Physical Labor",
                      innerText:"Deliver mail to residences and business establishments along specified routes."
                     },
                     {
                      title:"Analysis",
                      innerText:"Return incorrectly addressed mail to senders."
                     },
                     {
                      title:"Data Entry",
                      innerText:"Enter change of address orders into computers that process forwarding address stickers."
                     },
              ]
          },
          {


              jobtitle:"Delivery Driver",
              description:"Drive a light vehicle, such as a truck or van, with a capacity of less than 26,001 pounds Gross Vehicle Weight (GVW), primarily to pick up merchandise or packages from a distribution center and deliver. May load and unload vehicle.",
              salary:"$41,900",
              education: "",
              id_gamification:"tdal-6",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/nhDMoNCrniA",videoId:"nhDMoNCrniA"},
                          
              communicationbuttons:[
                     {
                      title:"Safety",
                      innerText:"Obey traffic laws and follow established traffic and transportation procedures."
                      },
                     {
                      title:"Organized",
                      innerText:"Turn in receipts and money received from deliveries."
                     },
                     {
                      title:"Navigation",
                      innerText:"Read maps and follow written or verbal geographic directions."
                     },
                     {
                      title:"Detailed",
                      innerText:"Verify the contents of inventory loads against shipping papers."
                     },
                     {
                      title:"Driving",
                      innerText:"Drive vehicles with capacities under three tons to transport materials to and from specified destinations."
                     },
                     {
                      title:"Data Entry",
                      innerText:"Maintain records, such as vehicle logs, records of cargo, or billing statements, in accordance with regulations."
                     },
              ]
          },
          {


              jobtitle:"Forklift Driver",
              description:"Operate industrial trucks or tractors equipped to move materials around a warehouse, storage yard, factory, construction site, or similar location.",
              salary:"$35,200",
              education: "",
              id_gamification:"tdal-7",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/0RYX43tWmw0",videoId:"0RYX43tWmw0"},
                          
              communicationbuttons:[
                     {
                      title:"Driving",
                      innerText:"Move controls to drive gasoline- or electric-powered trucks, cars, or tractors and transport materials between loading, processing, and storage areas."
                      },
                     {
                      title:"Physical Labor",
                      innerText:"Manually or mechanically load or unload materials from pallets, skids, platforms, cars, lifting devices, or other transport vehicles."
                     },
                     {
                      title:"Safety",
                      innerText:"Position lifting devices under, over, or around loaded pallets, skids, or boxes and secure material or products for transport to designated areas."
                     },
                     {
                      title:"Analysis",
                      innerText:"Inspect product load for accuracy and safely move it around the warehouse or facility to ensure timely and complete delivery."
                     },
                     {
                      title:"Detailed",
                      innerText:"Weigh materials or products and record weight or other production data on tags or labels."
                     },
                     {
                      title:"Communicate",
                      innerText:"Signal workers to discharge, dump, or level materials."
                     },
              ]
          },
          {


              jobtitle:"Cargo Supervisor",
              description:"Supervise and coordinate the activities of ground crew in the loading, unloading, securing, and staging of aircraft cargo or baggage. May determine the quantity and orientation of cargo and compute aircraft center of gravity. May accompany aircraft as member of flight crew and monitor and handle cargo in flight, and assist and brief passengers on safety and emergency procedures. Includes loadmasters.",
              salary:"$53,300",
              education: "",
              id_gamification:"tdal-8",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/1KyVS0PrQZM",videoId:"1KyVS0PrQZM"},
                          
              communicationbuttons:[
                     {
                      title:"Math & Physics",
                      innerText:"Determine the quantity and orientation of cargo, and compute an aircraft's center of gravity."
                      },
                     {
                      title:"Communicate",
                      innerText:"Direct ground crews in the loading, unloading, securing, or staging of aircraft cargo or baggage."
                     },
                     {
                      title:"Training",
                      innerText:"Train new employees in areas such as safety procedures or equipment operation."
                     },
                     {
                      title:"Computer Usage",
                      innerText:"Calculate load weights for different aircraft compartments, using charts and computers."
                     },
                     {
                      title:"Travel",
                      innerText:"Accompany aircraft as a member of the flight crew to monitor and handle cargo in flight."
                     },
                     {
                      title:"Analysis",
                      innerText:"Distribute cargo to maximize use of space."
                     },
              ]
          },
          {


              jobtitle:"Stocker",
              description:"Receive, store, and issue merchandise, materials, equipment, and other items from stockroom, warehouse, or storage yard to fill shelves, racks, tables, or customers' orders. May operate power equipment to fill orders. May mark prices on merchandise and set up sales displays.",
              salary:"$29,400",
              education: "",
              id_gamification:"tdal-9",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/sgCea-gpmCo",videoId:"sgCea-gpmCo"},
                          
              communicationbuttons:[
                     {
                      title:"Organize",
                      innerText:"Store items in an orderly and accessible manner in warehouses, tool rooms, supply rooms, or other areas."
                      },
                     {
                      title:"Detailed",
                      innerText:"Examine and inspect stock items for wear or defects, reporting any damage to supervisors."
                     },
                     {
                      title:"Social Skills",
                      innerText:"Answer customers' questions about merchandise and advise customers on merchandise selection."
                     },
                     {
                      title:"Data Entry",
                      innerText:"Receive and count stock items, and record data manually or on computer."
                     },
                     {
                      title:"Physical Labor",
                      innerText:"Stock shelves, racks, cases, bins, and tables with new or transferred merchandise."
                     },
                     {
                      title:"Driving",
                      innerText:"Operate equipment such as forklifts."
                     },
              ]
          },
          {



    jobtitle:'Diesel Mechanic',
    salary:"$45,700",
    description:"Diagnose, adjust, repair, or overhaul buses and trucks, or maintain and repair any type of diesel engines. Includes mechanics working primarily with automobile or marine diesel engines.",
    jobMedia:{imageOrVideo:'video',
    videoType:'youtube',
    videoId:'-PcpkHT8xaU',
    srcOrHref:''},
    education:"",
    id_gamification:"tdal-2",
    communicationbuttons:[
           {
            title:"Vehicle Operation",
            innerText:"Test drive trucks and buses to diagnose malfunctions or to ensure that they are working properly."
           },
           {
            title:"Engine Repair",
            innerText:"Recondition and replace parts, pistons, bearings, gears, and valves."
           },
           {
            title:"Vehicle Maintenance",
            innerText:"Perform routine maintenance such as changing oil, checking batteries, and lubricating equipment and machinery."
           },
           {
            title:"Critical Thinking",
            innerText:"Inspect, test, and listen to defective equipment to diagnose malfunctions, using test instruments such as handheld computers, motor analyzers, chassis charts, or pressure gauges."
           },
           {
            title:"Measure",
            innerText:"Measure vehicle emissions to determine whether they are within acceptable limits."
           },
           {
            title:"Electrical",
            innerText:"Rewire ignition systems, lights, and instrument panels"
           },
    ]
},
{


    jobtitle:"Cargo & Freight Agent",
    description:"Expedite and route movement of incoming and outgoing cargo and freight shipments in airline, train, and trucking terminals and shipping docks. Take orders from customers and arrange pickup of freight and cargo for delivery to loading platform. Prepare and examine bills of lading to determine shipping charges and tariffs.",
    salary:"$49,400",
    education: "",
    id_gamification:"tdal-10",
    jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/V1mFGglgbBE",videoId:"V1mFGglgbBE"},
                
    communicationbuttons:[
           {
            title:"Analysis",
            innerText:"Determine method of shipment and prepare bills of lading, invoices, and other shipping documents."
            },
           {
            title:"Logistics",
            innerText:"Track delivery progress of shipments."
           },
           {
            title:"Physical Labor",
            innerText:"Install straps, braces, and padding to loads to prevent shifting or damage during shipment."
           },
           {
            title:"Organized",
            innerText:"Keep records of all goods shipped, received, and stored."
           },
           {
            title:"Data Entry",
            innerText:"Enter shipping information into a computer by hand or by a hand-held scanner that reads bar codes on goods."
           },
           {
            title:"Communicate",
            innerText:"Notify consignees, passengers, or customers of freight or baggage arrival and arrange for delivery."
           },
    ]
}

         ],
     
        description:{
          aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
        }
         
    },
  
   
  }
  
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var NAW_Quiz_Object={ 
    agriculture_food_and_natural_resources:{
      mediaInfo:{
        videoType:'',link:"agriculture-careers",videoId:'', imageOrVideo:'img',srcOrHref:'agriculture-careers'
      },
      jobs:[
        {
            jobtitle:'Farm Manager',
            salary:" $68,090",
            description:"Farmers, ranchers, and other agricultural managers run establishments that produce crops, livestock, and dairy products. Farmers, ranchers, and other agricultural managers typically work outdoors but also may spend time in an office. Their work is often physically demanding.", 
            jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'b7ULrhxJwPs',srcOrHref:'https://youtu.be/b7ULrhxJwPs'},
            education:"",
            id_gamification:"afnr-1",
            communicationbuttons:[
                   {
                    title:"Data Entry",
                    innerText:"Collect and record growth, production, and environmental data."
                   },
                   {
                    title:"Critical Analysis",
                    innerText:"Determine how to allocate resources and to respond to unanticipated problems, such as insect infestation, drought, and fire."
                   },
                  {
                    title:"Horticulture",
                    innerText:"Determine plant growing conditions, such as greenhouses, hydroponics, or natural settings, and set planting and care schedules."
                   },
                   {
                    title:"Financial",
                    innerText:"Maintain financial, operational, production, or employment records for farms or ranches."
                   },
                   {
                    title:"Sales",
                    innerText:"Negotiate with buyers for the sale, storage, or shipment of crops or livestock."
                   },
                   {
                    title:"Supervise",
                    innerText:"Supervise the construction of farm or ranch structures, such as buildings, fences, drainage systems, wells, or roads."
                   },
            ]
},
{
  jobtitle:'Pest Control Worker',
  salary:"$36,815",
  description:"Pest control workers remove unwanted pests that infest buildings and surrounding areas. Pest control workers must travel to a client’s home or business. Workers often kneel, bend, and crawl into tight spaces to inspect sites. Because there are health risks associated with pesticide use, workers are trained in pesticide safety and, if required by the product label, sometimes wear protective gear, including respirators, gloves, and goggles. Working evenings and weekends is common.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'FApd9FCZ3RQ',srcOrHref:'https://youtu.be/FApd9FCZ3RQ'},
  education:"",
  id_gamification:"afnr-2",
  communicationbuttons:[
         {
          title:"Detailed",
          innerText:"Inspect premises to identify infestation source and extent of damage to property, wall, or roof porosity and access to infested locations."
         },
         {
          title:"Consult",
          innerText:"Recommend treatment and prevention methods for pest problems to clients."
         },
        {
          title:"Cleaning",
          innerText:"Clean work site after completion of job."
         },
         {
          title:"Driving",
          innerText:"Drive truck equipped with power spraying equipment."
         },
         {
          title:"Math",
          innerText:"Measure area dimensions requiring treatment, calculate fumigant requirements, and estimate cost for service."
         },
         {
          title:"Analysis",
          innerText:"Study preliminary reports or diagrams of infested area and determine treatment type required to eliminate and prevent recurrence of infestation."
         },
  ]
},
{
  jobtitle:'Sanitation Laborer',
  salary:"$31,282",
  description:"Refuse and Recyclable Material Collectors collect and dump refuse or recyclable materials from containers into truck that they may drive.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'XR6wkDG4Gzg',srcOrHref:'https://youtu.be/XR6wkDG4Gzg'},
  education:"",
  id_gamification:"afnr-3",
  communicationbuttons:[
         {
          title:"Operate Machines",
          innerText:"Operate automated or semi-automated hoisting devices that raise refuse bins and dump contents into openings in truck bodies."
         },
         {
          title:"Detailed",
          innerText:"Inspect trucks prior to beginning routes to ensure safe operating condition."
         },
        {
          title:"Driving",
          innerText:"Drive trucks, following established routes, through residential streets or alleys or through business or industrial areas."
         },
         {
          title:"Sanitation",
          innerText:"Dump refuse or recyclable materials at disposal sites."
         },
         {
          title:"Maintain",
          innerText:"Refuel trucks or add other fluids, such as oil or brake fluid."
         },
         {
          title:"Communicate",
          innerText:"Communicate with dispatchers concerning delays, unsafe sites, accidents, equipment breakdowns, or other maintenance problems."
         },
  ]
},
{
  jobtitle:'Production Manager',
  salary:"$57,537",
  description:"First-Line Supervisors of Production and Operating Workers directly supervise and coordinate the activities of production and operating workers, such as inspectors, precision workers, machine setters and operators, assemblers, fabricators, and plant and system operators. ", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'dflNKhApmBE',srcOrHref:'https://youtu.be/dflNKhApmBE'},
  education:"",
  id_gamification:"afnr-4",
  communicationbuttons:[
         {
          title:"Organized",
          innerText:"Keep records of employees' attendance and hours worked."
         },
         {
          title:"Detailed",
          innerText:"Inspect materials, products, or equipment to detect defects or malfunctions."
         },
        {
          title:"Communicate",
          innerText:"Confer with other supervisors to coordinate operations and activities within or between departments."
         },
         {
          title:"Training",
          innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
         },
         {
          title:"Leadership",
          innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
         },
         {
          title:"Analysis",
          innerText:"Determine standards, budgets, production goals, and rates, based on company policies, equipment and labor availability, and workloads."
         },
  ]
},
{
  jobtitle:'Welder',
  salary:"$37,332",
  description:"Welders, cutters, solderers, and brazers may work outdoors, often in inclement weather, or indoors, sometimes in a confined area. They may work on a scaffold, high off the ground, and they occasionally must lift heavy objects and work in awkward positions. Most work full time and overtime is common.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'cWbaZEIe7GQ',srcOrHref:'https://youtu.be/cETNESaLhcA'},
  education:"",
  id_gamification:"afnr-5",
  communicationbuttons:[
         {
          title:"Welding",
          innerText:"Weld components in flat, vertical, or overhead positions."
         },
         {
          title:"Safety",
          innerText:"Operate safety equipment and use safe work habits."
         },
        {
          title:"Quality Control",
          innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
         },
         {
          title:"Hand Tools",
          innerText:"Position and secure workpieces, using hoists, cranes, wire, and banding machines or hand tools."
         },
         {
          title:"Analysis",
          innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
         },
         {
          title:"Design",
          innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
         },
  ]
},
      ],
  },
    architecture_and_construction:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/FknPHp4mMNQ",videoId:'FknPHp4mMNQ',imageOrVideo:'video',srcOrHref:'https://youtu.be/FknPHp4mMNQ'
      },
      jobs:[ 
        {
          jobtitle:'Construction Laborers',
          salary:"$33,310",
          description:"Perform tasks involving physical labor at construction sites. May operate hand and power tools of all types: air hammers, earth tampers, cement mixers, small mechanical hoists, surveying and measuring equipment, and a variety of other equipment and instruments. May clean and prepare sites, dig trenches, set braces to support the sides of excavations, erect scaffolding, and clean up rubble, debris, and other waste materials. May assist other craft workers.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'s1n6nBhVzdo',srcOrHref:'https://youtu.be/s1n6nBhVzdo'},
          education:"",
          id_gamification:"arch-1",
          communicationbuttons:[
                 {
                  title:"Physical Labor",
                  innerText:"Dig ditches or trenches, backfill excavations, or compact and level earth to grade specifications, using picks, shovels, pneumatic tampers, or rakes."
                 },
                 {
                  title:"Safety",
                  innerText:"Clean or prepare construction sites to eliminate possible hazards."
                 },
                {
                  title:"Maintenance",
                  innerText:"Lubricate, clean, or repair machinery, equipment, or tools."
                 },
                 {
                  title:"Cleanliness",
                  innerText:"Clean or prepare construction sites to eliminate possible hazards."
                 },
                 {
                  title:"Concrete Work",
                  innerText:"Smooth or finish freshly poured cement or concrete, using floats, trowels, screeds, or powered cement finishing tools."
                 },
                 {
                  title:"Teamwork",
                  innerText:"Provide assistance to craft workers, such as carpenters, plasterers, or masons."
                 },
          ]
      },
      {
        jobtitle:'Electrician',
        salary:"$45,176",
        description:"Install, maintain, and repair electrical wiring, equipment, and fixtures. Ensure that work is in accordance with relevant codes. May install or service street lights, intercom systems, or electrical control systems.", 
        jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'mCGHJjpiOc4',srcOrHref:'https://youtu.be/mCGHJjpiOc4'},
        education:"",
        id_gamification:"arch-2",
        communicationbuttons:[
               {
                title:"Critical Analysis",
                innerText:"Plan layout and installation of electrical wiring, equipment, or fixtures, based on job specifications and local codes."
               },
               {
                title:"Design",
                innerText:"Prepare sketches or follow blueprints to determine the location of wiring or equipment and to ensure conformance to building and safety codes."
               },
              {
                title:"Troubleshoot",
                innerText:"Diagnose malfunctioning systems, apparatus, or components, using test equipment and hand tools to locate the cause of a breakdown and correct the problem."
               },
               {
                title:"Instructing",
                innerText:"Advise management on whether continued operation of equipment could be hazardous."
               },
               {
                title:"Quality Control",
                innerText:"Inspect electrical systems, equipment, or components to identify hazards, defects, or the need for adjustment or repair, and to ensure compliance with codes."
               },
               {
                title:"Hand Tools",
                innerText:"Repair or replace wiring, equipment, or fixtures, using hand tools or power tools."
               },
        ]
    },
    {
      jobtitle:'Construction Supervisor',
      salary:"$57,001",
      description:"Directly supervise and coordinate activities of construction or extraction workers.", 
      jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'B7YiI3UkxFw',srcOrHref:'https://youtu.be/B7YiI3UkxFw'},
      education:"",
      id_gamification:"arch-3",
      communicationbuttons:[
             {
              title:"Analysis",
              innerText:"Inspect work progress, equipment, or construction sites to verify safety or to ensure that specifications are met."
             },
             {
              title:"Supervise",
              innerText:"Supervise, coordinate, or schedule the activities of construction or extractive workers."
             },
            {
              title:"Planning",
              innerText:"Estimate material or worker requirements to complete jobs."
             },
             {
              title:"Communicate",
              innerText:"Confer with managerial or technical personnel, other departments, or contractors to resolve problems or to coordinate activities."
             },
             {
              title:"Teaching",
              innerText:"Train workers in construction methods, operation of equipment, safety procedures, or company policies."
             },
             {
              title:"Data Entry",
              innerText:"Record information, such as personnel, production, or operational data on specified forms or reports."
             },
      ]
  },
  {
    jobtitle:'Carpenters',
    salary:"$39,269",
    description:"Construct, erect, install, or repair structures and fixtures made of wood and comparable materials, such as concrete forms; building frameworks, including partitions, joists, studding, and rafters; and wood stairways, window and door frames, and hardwood floors. May also install cabinets, siding, drywall, and batt or roll insulation. Includes brattice builders who build doors or brattices (ventilation walls or partitions) in underground passageways.", 
    jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'i-tiC2Y-038',srcOrHref:'https://youtu.be/i-tiC2Y-038'},
    education:"",
    id_gamification:"arch-4",
    communicationbuttons:[
           {
            title:"Safety",
            innerText:"Follow established safety rules and regulations and maintain a safe and clean environment."
           },
           {
            title:"Tool Usage",
            innerText:"Shape or cut materials to specified measurements, using hand tools, machines, or power saws."
           },
          {
            title:"Detailed",
            innerText:"Measure and mark cutting lines on materials, using a ruler, pencil, chalk, and marking gauge."
           },
           {
            title:"Communicate",
            innerText:"Arrange for subcontractors to deal with special areas, such as heating or electrical wiring work."
           },
           {
            title:"Data Entry",
            innerText:"Maintain records, document actions, and present written progress reports."
           },
           {
            title:"Finishing",
            innerText:"Finish surfaces of woodwork or wallboard in houses or buildings, using paint, hand tools, or paneling."
           },
    ]
},
{
  jobtitle:'Plumbers, Pipefitters, and Steamfitters',
  salary:"$48,150",
  description:"Assemble, install, alter, and repair pipelines or pipe systems that carry water, steam, air, or other liquids or gases. May install heating and cooling equipment and mechanical control systems. Includes sprinkler fitters.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'uF3-2wFnf0E',srcOrHref:'https://youtu.be/uF3-2wFnf0E'},
  education:"",
  id_gamification:"arch-5",
  communicationbuttons:[
         {
          title:"Hand Tools",
          innerText:"Cut, thread, or hammer pipes to specifications, using tools such as saws, cutting torches, pipe threaders, or pipe benders."
         },
         {
          title:"Organized",
          innerText:"Lay out full scale drawings of pipe systems, supports, or related equipment, according to blueprints."
         },
        {
          title:"Analysis",
          innerText:"Inspect, examine, or test installed systems or pipe lines, using pressure gauge, hydrostatic testing, observation, or other methods."
         },
         {
          title:"Supervise",
          innerText:"Direct helpers engaged in pipe cutting, preassembly, or installation of plumbing systems or components."
         },
         {
          title:"Welding",
          innerText:"Weld small pipes or special piping, using specialized techniques, equipment, or materials, such as computer-assisted welding or microchip fabrication."
         },
         {
          title:"Organized",
          innerText:"Estimate time, material, or labor costs for use in project plans."
         },
  ]
},
{
  jobtitle:'Equipment Operator',
  salary:"$38,026",
  description:"Operating Engineers and Other Construction Equipment Operators operate one or several types of power construction equipment, such as motor graders, bulldozers, scrapers, compressors, pumps, derricks, shovels, tractors, or front-end loaders to excavate, move, and grade earth, erect structures, or pour concrete or other hard surface pavement.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'_zm6Wud8hp4',srcOrHref:'https://youtu.be/_zm6Wud8hp4'},
  education:"",
  id_gamification:"arch-6",
  communicationbuttons:[
         {
          title:"Communicate",
          innerText:"Coordinate machine actions with other activities, positioning or moving loads in response to hand or audio signals from crew members."
         },
         {
          title:"Cautious",
          innerText:"Take actions to avoid potential hazards or obstructions, such as utility lines, other equipment, other workers, or falling objects."
         },
        {
          title:"Safety",
          innerText:"Locate underground services, such as pipes or wires, prior to beginning work."
         },
         {
          title:"Physical Labor",
          innerText:"Repair and maintain equipment, making emergency adjustments or assisting with major repairs as necessary."
         },
         {
          title:"Organized",
          innerText:"Keep records of material or equipment usage or problems encountered."
         },
         {
          title:"Operate Machinery",
          innerText:"Load and move dirt, rocks, equipment, or other materials, using trucks, crawler tractors, power cranes, shovels, graders, or related equipment."
         },
  ]
},
{
  jobtitle:'HVACR Mechanics',
  salary:"$41,557",
  description:"HVACR technicians work mostly in homes, schools, hospitals, office buildings, or factories. Their worksites may be very hot or cold because the heating and cooling systems they must repair may not be working properly and because some parts of these systems are located outdoors. Working in cramped spaces and during irregular hours is common.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'PNcQwekI2KQ',srcOrHref:'https://youtu.be/PNcQwekI2KQ'},
  education:"",
  id_gamification:"arch-7",
  communicationbuttons:[
         {
          title:"Electrical",
          innerText:"Test electrical circuits or components for continuity, using electrical test equipment."
         },
         {
          title:"Repair",
          innerText:"Repair or replace defective equipment, components, or wiring."
         },
        {
          title:"Customer Service",
          innerText:"Discuss heating or cooling system malfunctions with users to isolate problems or to verify that repairs corrected malfunctions."
         },
         {
          title:"Safety",
          innerText:"Comply with all applicable standards, policies, or procedures, such as safety procedures or the maintenance of a clean work area."
         },
         {
          title:"Organization",
          innerText:"Keep records of repairs and replacements made and causes of malfunctions."
         },
         {
          title:"Quality Control",
          innerText:"Inspect and test systems to verify system compliance with plans and specifications or to detect and locate malfunctions."
         },
  ]
},
{
  jobtitle:'Civil Engineer',
  salary:"$99,869",
  description:"Civil engineers design, build, and supervise infrastructure projects and systems. Civil engineers generally work in a variety of locations and conditions. It is common for them to split their time between working in an office and working outdoors at construction sites so that they can monitor operations or solve problems onsite. Most work full time.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'-XIQSrYi0O0',srcOrHref:'https://youtu.be/-XIQSrYi0O0'},
  education:"",
  id_gamification:"arch-8",
  communicationbuttons:[
         {
          title:"Monitoring",
          innerText:"Inspect project sites to monitor progress and ensure conformance to design specifications and safety or sanitation standards."
         },
         {
          title:"Instructing",
          innerText:"Provide technical advice to industrial or managerial personnel regarding design, construction, program modifications, or structural repairs."
         },
        {
          title:"Critical Analysis",
          innerText:"Test soils or materials to determine the adequacy and strength of foundations, concrete, asphalt, or steel."
         },
         {
          title:"Management",
          innerText:"Manage and direct the construction, operations, or maintenance activities at project site."
         },
         {
          title:"Design",
          innerText:"Design energy-efficient or environmentally sound civil structures."
         },
         {
          title:"Computer Literacy",
          innerText:"Plan and design transportation or hydraulic systems or structures, using computer-assisted design or drawing tools."
         },
  ]
},
{
  jobtitle:'Construction Managers',
  salary:"$80,210",
  description:"Construction managers plan, coordinate, budget, and supervise construction projects from start to finish. Construction managers may have a main office but spend most of their time in a field office onsite, where they monitor projects and make decisions about construction activities. Their schedules may vary.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'SiamJadljrw',srcOrHref:'https://youtu.be/SiamJadljrw'},
  education:"",
  id_gamification:"arch-9",
  communicationbuttons:[
         {
          title:"Project Management",
          innerText:"Plan, schedule, or coordinate construction project activities to meet deadlines."
         },
         {
          title:"Communicate",
          innerText:"Interpret and explain plans and contract terms to representatives of the owner or developer, including administrative staff, workers, or clients."
         },
        {
          title:"Supervise",
          innerText:"Direct and supervise construction or related workers."
         },
         {
          title:"Business",
          innerText:"Prepare contracts or negotiate revisions to contractual agreements with architects, consultants, clients, suppliers, or subcontractors."
         },
         {
          title:"Safety",
          innerText:"Investigate damage, accidents, or delays at construction sites to ensure that proper construction procedures are being followed."
         },
         {
          title:"Analysis",
          innerText:"Study job specifications to determine appropriate construction methods."
         },
  ]
},
{
    jobtitle:'Welder',
    salary:"$37,332",
    description:"Welders, cutters, solderers, and brazers may work outdoors, often in inclement weather, or indoors, sometimes in a confined area. They may work on a scaffold, high off the ground, and they occasionally must lift heavy objects and work in awkward positions. Most work full time and overtime is common.", 
    jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'cETNESaLhcA',srcOrHref:'https://youtu.be/cETNESaLhcA'},
    education:"",
    id_gamification:"arch-10",
    communicationbuttons:[
           {
            title:"Welding",
            innerText:"Weld components in flat, vertical, or overhead positions."
           },
           {
            title:"Safety",
            innerText:"Operate safety equipment and use safe work habits."
           },
          {
            title:"Quality Control",
            innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
           },
           {
            title:"Hand Tools",
            innerText:"Position and secure workpieces, using hoists, cranes, wire, and banding machines or hand tools."
           },
           {
            title:"Analysis",
            innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
           },
           {
            title:"Design",
            innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
           },
    ]
},

       ],
  },
    education_and_training:{
      mediaInfo:{
        videoType:'',link:"education-careers",videoId:'', imageOrVideo:'img',srcOrHref:'education-careers'
      },
      jobs:[
        {
          jobtitle:'Elementary School Teachers',
          salary:"$52,067",
          description:"Kindergarten and elementary school teachers instruct young students in basic subjects in order to prepare them for future schooling. Kindergarten and elementary school teachers work in public and private schools. They generally work during school hours when students are present and use nights and weekends to prepare lessons and grade papers. Most kindergarten and elementary school teachers do not work during the summer.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'VRB818Z8Xhs',srcOrHref:'https://youtu.be/VRB818Z8Xhs'},
          education:"",
          id_gamification:"educ-1",
          communicationbuttons:[
                 {
                  title:"Enforce Rules",
                  innerText:"Establish and enforce rules for behavior and procedures for maintaining order among the students for whom they are responsible."
                 },
                 {
                  title:"Learning Strategy",
                  innerText:"Adapt teaching methods and instructional materials to meet students' varying needs and interests."
                 },
                {
                  title:"Communicate",
                  innerText:"Confer with parents or guardians, teachers, counselors, and administrators to resolve students' behavioral and academic problems."
                 },
                 {
                  title:"Critical Thinking",
                  innerText:"Establish clear objectives for all lessons, units, and projects and communicate those objectives to students."
                 },
                 {
                  title:"Monitoring",
                  innerText:"Observe and evaluate students' performance, behavior, social development, and physical health."
                 },
                 {
                  title:"Organization",
                  innerText:"Maintain accurate and complete student records as required by laws, district policies, and administrative regulations."
                 },
          ]
},
{
  jobtitle:'Secondary School Teachers',
  salary:"$53,315",
  description:"High school teachers teach academic lessons and various skills that students will need to attend college and to enter the job market. High school teachers work in schools. They work during school hours but may also work evenings and weekends to prepare lessons and grade papers. Most do not teach during the summer.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'tSeStFsCZrQ',srcOrHref:'https://youtu.be/tSeStFsCZrQ'},
  education:"",
  id_gamification:"educ-2",
  communicationbuttons:[
         {
          title:"Enforce Rules",
          innerText:"Establish and enforce rules for behavior and procedures for maintaining order among the students for whom they are responsible."
         },
         {
          title:"Learning Strategy",
          innerText:"Adapt teaching methods and instructional materials to meet students' varying needs and interests."
         },
        {
          title:"Communicate",
          innerText:"Confer with parents or guardians, teachers, counselors, and administrators to resolve students' behavioral and academic problems."
         },
         {
          title:"Critical Thinking",
          innerText:"Establish clear objectives for all lessons, units, and projects and communicate those objectives to students."
         },
         {
          title:"Monitoring",
          innerText:"Observe and evaluate students' performance, behavior, social development, and physical health."
         },
         {
          title:"Organization",
          innerText:"Maintain accurate and complete student records as required by laws, district policies, and administrative regulations."
         },
  ]
},
{
  jobtitle:'Postsecondary Teachers',
  salary:"$58,983",
  description:"Postsecondary teachers instruct students in a variety of academic subjects beyond the high school level. Most postsecondary teachers work in public and private colleges and universities, professional schools, and junior or community colleges. Most work full time, although part-time work is common.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'ISmxPVS5O2c',srcOrHref:'https://youtu.be/ISmxPVS5O2c'},
  education:"",
  id_gamification:"educ-3",
  communicationbuttons:[
         {
          title:"Content Creation",
          innerText:"Prepare course materials, such as syllabi, homework assignments, and handouts."
         },
         {
          title:"Research",
          innerText:"Conduct research in a particular field of knowledge and publish findings in professional journals, books, or electronic media."
         },
        {
          title:"Learning",
          innerText:"Keep abreast of developments in the field by reading current literature, talking with colleagues, and participating in professional conferences."
         },
         {
          title:"Mentor",
          innerText:"Supervise students' fieldwork, internship, and research work."
         },
         {
          title:"Grading",
          innerText:"Evaluate and grade students' class work, assignments, and papers."
         },
         {
          title:"Teamwork",
          innerText:"Collaborate with colleagues to address teaching and research issues."
         },
  ]
},
{
  jobtitle:'Childcare Worker',
  salary:"$25,460",
  description:"Childcare workers attend to children's needs while helping to foster early development. Attend to children at schools, businesses, private households, and childcare institutions. Perform a variety of tasks, such as dressing, feeding, bathing, and overseeing play.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'xjYk0w_MMmw',srcOrHref:'https://youtu.be/xjYk0w_MMmw'},
  education:"",
  id_gamification:"educ-4",
  communicationbuttons:[
         {
          title:"Safety",
          innerText:"Maintain a safe play environment."
         },
         {
          title:"Commuincate",
          innerText:"Communicate with children's parents or guardians about daily activities, behaviors, and related issues."
         },
        {
          title:"Empathy",
          innerText:"Support children's emotional and social development, encouraging understanding of others and positive self-concepts."
         },
         {
          title:"Event Planning",
          innerText:"Organize and participate in recreational activities and outings, such as games and field trips."
         },
         {
          title:"Detailed",
          innerText:"Keep records on individual children, including daily observations and information about activities, meals served, and medications administered."
         },
         {
          title:"Analysis",
          innerText:"Identify signs of emotional or developmental problems in children and bring them to parents' or guardians' attention."
         },
  ]
},
{
  jobtitle:'Special Education Teacher',
  salary:"$53,288",
  description:"Special education teachers work with students who have a wide range of learning, mental, emotional, and physical disabilities. Most special education teachers work in public schools, teaching students from preschool to high school. Many work the traditional 10-month school year, but some work year round.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'OkpBupS1SSY',srcOrHref:'https://youtu.be/OkpBupS1SSY'},
  education:"",
  id_gamification:"educ-5",
  communicationbuttons:[
         {
          title:"Testing",
          innerText:"Administer standardized ability and achievement tests to students with special needs."
         },
         {
          title:"Teamwork",
          innerText:"Collaborate with other teachers or administrators to develop, evaluate, or revise elementary school programs."
         },
        {
          title:"Learning",
          innerText:"Confer with other staff members to plan or schedule lessons promoting learning, following approved curricula."
         },
         {
          title:"Analysis",
          innerText:"Interpret the results of standardized tests to determine students' strengths and areas of need."
         },
         {
          title:"Detailed",
          innerText:"Develop or implement strategies to meet the needs of students with a variety of disabilities."
         },
         {
          title:"Support",
          innerText:"Encourage students to explore learning opportunities or persevere with challenging tasks to prepare them for later grades."
         },
  ]
},
      ],
  },
    energy_and_utilities:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/En9Bjtvq-48",videoId:'En9Bjtvq-48',imageOrVideo:'video',srcOrHref:'https://youtu.be/En9Bjtvq-48'
      },
      jobs:[ 
        {
          jobtitle:'Computer Systems Analysts',
          salary:"$92,004",
          description:"Analyze science, engineering, business, and other data processing problems to develop and implement solutions to complex applications problems, system administration issues, or network concerns. Perform systems management and integration functions, improve existing computer systems, and review computer system capabilities, workflow, and schedule limitations. May analyze or recommend commercially available software.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'T0JdNufvn-o',srcOrHref:'https://youtu.be/T0JdNufvn-o'},
          education:"",
          id_gamification:"ener-1",
          communicationbuttons:[
                 {
                  title:"Detailed",
                  innerText:"Test, maintain, and monitor computer programs and systems, including coordinating the installation of computer programs and systems."
                 },
                 {
                  title:"Teaching",
                  innerText:"Train staff and users to work with computer systems and programs."
                 },
                {
                  title:"Analysis",
                  innerText:"Assess the usefulness of pre-developed application packages and adapt them to a user environment."
                 },
                 {
                  title:"Planning",
                  innerText:"Define the goals of the system and devise flow charts and diagrams describing logical operational steps of programs."
                 },
                 {
                  title:"Support",
                  innerText:"Provide staff and users with assistance solving computer-related problems, such as malfunctions and program problems."
                 },
                 {
                  title:"Programming",
                  innerText:"Use object-oriented programming languages, as well as client and server applications development processes and multimedia and Internet technology."
                 },
          ]
      },
      {
        jobtitle:'Information Security Analyst',
        salary:"$87,990",
        description:"Plan, implement, upgrade, or monitor security measures for the protection of computer networks and information. Assess system vulnerabilities for security risks and propose and implement risk mitigation strategies. May ensure appropriate security controls are in place that will safeguard digital files and vital electronic infrastructure. May respond to computer security breaches and viruses.", 
        jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'cVZktAEtWE4',srcOrHref:'https://youtu.be/cVZktAEtWE4'},
        education:"",
        id_gamification:"ener-2",
        communicationbuttons:[
               {
                title:"Organize",
                innerText:"Document computer security and emergency measures policies, procedures, and tests."
               },
               {
                title:"Analysis",
                innerText:"Monitor current reports of computer viruses to determine when to update virus protection systems."
               },
              {
                title:"Communicate",
                innerText:"Coordinate implementation of computer system plan with establishment personnel and outside vendors."
               },
               {
                title:"Quality Control",
                innerText:"Perform risk assessments and execute tests of data processing system to ensure functioning of data processing activities and security measures."
               },
               {
                title:"Troubleshoot",
                innerText:"Modify computer security files to incorporate new software, correct errors, or change individual access status."
               },
               {
                title:"Instructing",
                innerText:"Confer with users to discuss issues such as computer data access needs, security violations, and programming changes."
               },
        ]
    },
    {
      jobtitle:'Plumbers, Pipefitters, and Steamfitters',
      salary:"$48,150",
      description:"Assemble, install, alter, and repair pipelines or pipe systems that carry water, steam, air, or other liquids or gases. May install heating and cooling equipment and mechanical control systems. Includes sprinkler fitters.", 
      jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'uF3-2wFnf0E',srcOrHref:'https://youtu.be/uF3-2wFnf0E'},
      education:"",
      id_gamification:"ener-3",
      communicationbuttons:[
             {
              title:"Hand Tools",
              innerText:"Cut, thread, or hammer pipes to specifications, using tools such as saws, cutting torches, pipe threaders, or pipe benders."
             },
             {
              title:"Organized",
              innerText:"Lay out full scale drawings of pipe systems, supports, or related equipment, according to blueprints."
             },
            {
              title:"Analysis",
              innerText:"Inspect, examine, or test installed systems or pipe lines, using pressure gauge, hydrostatic testing, observation, or other methods."
             },
             {
              title:"Supervise",
              innerText:"Direct helpers engaged in pipe cutting, preassembly, or installation of plumbing systems or components."
             },
             {
              title:"Welding",
              innerText:"Weld small pipes or special piping, using specialized techniques, equipment, or materials, such as computer-assisted welding or microchip fabrication."
             },
             {
              title:"Organized",
              innerText:"Estimate time, material, or labor costs for use in project plans."
             },
      ]
  },
  {
    jobtitle:'Electrical Engineers',
    salary:"$103,056",
    description:"Electrical and electronics engineers work in industries including research and development, engineering services, manufacturing, telecommunications, and the federal government. Electrical and electronics engineers generally work indoors in offices. However, they may have to visit sites to observe a problem or a piece of complex equipment.", 
    jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'fhAZ2tZj84A',srcOrHref:'https://youtu.be/fhAZ2tZj84A'},
    education:"",
    id_gamification:"ener-4",
    communicationbuttons:[
           {
            title:"Computer Literacy",
            innerText:"Operate computer-assisted engineering or design software or equipment to perform engineering tasks."
           },
           {
            title:"Communicate",
            innerText:"Confer with engineers, customers, or others to discuss existing or potential engineering projects or products."
           },
          {
            title:"Detail Oriented",
            innerText:"Perform detailed calculations to compute and establish manufacturing, construction, or installation standards or specifications."
           },
           {
            title:"Management",
            innerText:"Supervise or train project team members, as necessary."
           },
           {
            title:"Analysis",
            innerText:"Conduct field surveys or study maps, graphs, diagrams, or other data to identify and correct power system problems."
           },
           {
            title:"Design",
            innerText:"Design electrical systems or components that minimize electric energy requirements, such as lighting systems designed to account for natural lighting."
           },
    ]
},
{
  jobtitle:'Electrical Technician',
  salary:"$55,950",
  description:"Electro-mechanical and mechatronics technologists and technicians operate, test, and maintain electromechanical or robotic equipment. Electrical and electronic engineering technologists and technicians work on teams with electrical engineers. They may work in offices, laboratories, or factories.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'E_Iy34hFITE',srcOrHref:'https://youtu.be/E_Iy34hFITE'},
  education:"",
  id_gamification:"ener-5",
  communicationbuttons:[
         {
          title:"Tool Usage",
          innerText:"Assemble electrical systems or prototypes, using hand tools or measuring instruments."
         },
         {
          title:"Detailed",
          innerText:"Review electrical engineering plans to ensure adherence to design specifications and compliance with applicable electrical codes and standards."
         },
        {
          title:"Critical Analysis",
          innerText:"Review existing electrical engineering criteria to identify necessary revisions, deletions, or amendments to outdated material."
         },
         {
          title:"Data Entry",
          innerText:"Maintain system logs or manuals to document testing or operation of equipment."
         },
         {
          title:"Project Planning",
          innerText:"Calculate design specifications or cost, material, and resource estimates, and prepare project schedules and budgets."
         },
         {
          title:"Computers",
          innerText:"Integrate software or hardware components, using computer, microprocessor, or control architecture."
         },
  ]
},
{
  jobtitle:'Electrician',
  salary:"$45,176",
  description:"Install, maintain, and repair electrical wiring, equipment, and fixtures. Ensure that work is in accordance with relevant codes. May install or service street lights, intercom systems, or electrical control systems.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'mCGHJjpiOc4',srcOrHref:'https://youtu.be/mCGHJjpiOc4'},
  education:"",
  id_gamification:"ener-6",
  communicationbuttons:[
         {
          title:"Critical Analysis",
          innerText:"Plan layout and installation of electrical wiring, equipment, or fixtures, based on job specifications and local codes."
         },
         {
          title:"Design",
          innerText:"Prepare sketches or follow blueprints to determine the location of wiring or equipment and to ensure conformance to building and safety codes."
         },
        {
          title:"Troubleshoot",
          innerText:"Diagnose malfunctioning systems, apparatus, or components, using test equipment and hand tools to locate the cause of a breakdown and correct the problem."
         },
         {
          title:"Instructing",
          innerText:"Advise management on whether continued operation of equipment could be hazardous."
         },
         {
          title:"Quality Control",
          innerText:"Inspect electrical systems, equipment, or components to identify hazards, defects, or the need for adjustment or repair, and to ensure compliance with codes."
         },
         {
          title:"Hand Tools",
          innerText:"Repair or replace wiring, equipment, or fixtures, using hand tools or power tools."
         },
  ]
},
{
  jobtitle:'Civil Engineer',
  salary:"$99,869",
  description:"Civil engineers design, build, and supervise infrastructure projects and systems. Civil engineers generally work in a variety of locations and conditions. It is common for them to split their time between working in an office and working outdoors at construction sites so that they can monitor operations or solve problems onsite. Most work full time.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'-XIQSrYi0O0',srcOrHref:'https://youtu.be/-XIQSrYi0O0'},
  education:"",
  id_gamification:"ener-7",
  communicationbuttons:[
         {
          title:"Monitoring",
          innerText:"Inspect project sites to monitor progress and ensure conformance to design specifications and safety or sanitation standards."
         },
         {
          title:"Instructing",
          innerText:"Provide technical advice to industrial or managerial personnel regarding design, construction, program modifications, or structural repairs."
         },
        {
          title:"Critical Analysis",
          innerText:"Test soils or materials to determine the adequacy and strength of foundations, concrete, asphalt, or steel."
         },
         {
          title:"Management",
          innerText:"Manage and direct the construction, operations, or maintenance activities at project site."
         },
         {
          title:"Design",
          innerText:"Design energy-efficient or environmentally sound civil structures."
         },
         {
          title:"Computer Literacy",
          innerText:"Plan and design transportation or hydraulic systems or structures, using computer-assisted design or drawing tools."
         },
  ]
},
{
  jobtitle:'Electrical Power Line Installer',
  salary:"$64,450",
  description:"Line installers and repairers install or repair electrical power systems and telecommunications cables, including fiber optics. Line workers encounter serious hazards on the job, including working with high-voltage electricity, often at great heights. The work also can be physically demanding. Although most work full time during regular business hours, some work irregular hours on evenings, nights, weekends, and holidays when needed.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'qJbC9pYtLK4',srcOrHref:'https://youtu.be/qJbC9pYtLK4'},
  education:"",
  id_gamification:"ener-8",
  communicationbuttons:[
         {
          title:"Safety",
          innerText:"Adhere to safety practices and procedures, such as checking equipment regularly and erecting barriers around work areas."
         },
         {
          title:"Quality Control",
          innerText:"Test conductors, according to electrical diagrams and specifications, to identify corresponding conductors and to prevent incorrect connections."
         },
        {
          title:"Repair",
          innerText:"Open switches or attach grounding devices to remove electrical hazards from disturbed or fallen lines or to facilitate repairs."
         },
         {
          title:"Vehicle Operation",
          innerText:"Drive vehicles equipped with tools and materials to job sites."
         },
         {
          title:"Operate Machinery",
          innerText:"Dig holes, using augers, and set poles, using cranes and power equipment."
         },
         {
          title:"Troubleshoot",
          innerText:"Inspect and test power lines and auxiliary equipment to locate and identify problems, using reading and testing instruments."
         },
  ]
},
{
  jobtitle:'Power Plant Operator',
  salary:"$89,090",
  description:"Power plant operators, distributors, and dispatchers control the systems that generate and distribute electric power. Most power plant operators, distributors, and dispatchers work full time. Many work rotating 8- or 12-hour shifts.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'Ug9QtgaRplo',srcOrHref:'https://youtu.be/Ug9QtgaRplo'},
  education:"",
  id_gamification:"ener-9",
  communicationbuttons:[
         {
          title:"Maintenance",
          innerText:"Clean, lubricate, or maintain equipment, such as generators, turbines, pumps, or compressors, to prevent failure or deterioration."
         },
         {
          title:"Analysis",
          innerText:"Analyze the layout, instrumentation, or function of electrical generation or transmission facilities."
         },
        {
          title:"Observation",
          innerText:"Monitor power plant equipment and indicators to detect evidence of operating problems."
         },
         {
          title:"Machine Operation",
          innerText:"Adjust controls to generate specified electrical power or to regulate the flow of power between generating stations and substations."
         },
         {
          title:"Quality Control",
          innerText:"Examine and test electrical power distribution machinery and equipment, using testing devices."
         },
         {
          title:"Communicate",
          innerText:"Communicate with systems operators to regulate and coordinate line voltages and transmission loads and frequencies."
         },
  ]
},
{
  jobtitle:'Welder',
  salary:"$37,332",
  description:"Welders, cutters, solderers, and brazers may work outdoors, often in inclement weather, or indoors, sometimes in a confined area. They may work on a scaffold, high off the ground, and they occasionally must lift heavy objects and work in awkward positions. Most work full time and overtime is common.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'cETNESaLhcA',srcOrHref:'https://youtu.be/cETNESaLhcA'},
  education:"",
  id_gamification:"ener-10",
  communicationbuttons:[
         {
          title:"Welding",
          innerText:"Weld components in flat, vertical, or overhead positions."
         },
         {
          title:"Safety",
          innerText:"Operate safety equipment and use safe work habits."
         },
        {
          title:"Quality Control",
          innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
         },
         {
          title:"Hand Tools",
          innerText:"Position and secure workpieces, using hoists, cranes, wire, and banding machines or hand tools."
         },
         {
          title:"Analysis",
          innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
         },
         {
          title:"Design",
          innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
         },
  ]
},
       ],
  },
    healthcare:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/qm6c7KDIQq0",videoId:'qm6c7KDIQq0',imageOrVideo:'video',srcOrHref:'https://youtu.be/qm6c7KDIQq0'
      },
      jobs:[ 
        {
          jobtitle:'Registered Nurse',
          salary:"$54,637",
          description:"Registered nurses (RNs) provide and coordinate patient care and educate patients and the public about various health conditions. Registered nurses usually take one of three education paths: a bachelor’s degree in nursing, an associate’s degree in nursing, or a diploma from an approved nursing program. Registered nurses must be licensed.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'x4Y_2fpET0U',srcOrHref:'https://youtu.be/x4Y_2fpET0U'},
          education:"",
          id_gamification:"heal-1",
          communicationbuttons:[
                 {
                  title:"Communicate",
                  innerText:"Consult and coordinate with healthcare team members to assess, plan, implement, or evaluate patient care plans."
                 },
                 {
                  title:"Social Perceptiveness",
                  innerText:"Assess the needs of individuals, families, or communities, including assessment of individuals' home or work environments, to identify potential health or safety problems."
                 },
                {
                  title:"Monitoring",
                  innerText:"Monitor, record, and report symptoms or changes in patients' conditions."
                 },
                 {
                  title:"Service Oriented",
                  innerText:"Assess the needs of individuals, families, or communities, including assessment of individuals' home or work environments, to identify potential health or safety problems."
                 },
                 {
                  title:"Instructing",
                  innerText:"Instruct individuals, families, or other groups on topics such as health education, disease prevention, or childbirth and develop health improvement programs."
                 },
                 {
                  title:"Detail Oriented",
                  innerText:"Administer medications to patients and monitor patients for reactions or side effects."
                 },
          ]
      },
      {
        jobtitle:'Medical Assistant',
        salary:"$27,737",
        description:"Perform administrative and certain clinical duties under the direction of a physician. Administrative duties may include scheduling appointments, maintaining medical records, billing, and coding information for insurance purposes. Clinical duties may include taking and recording vital signs and medical histories, preparing patients for examination, drawing blood, and administering medications as directed by physician.", 
        jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'ZsgeduDiK_w',srcOrHref:'https://youtu.be/ZsgeduDiK_w'},
        education:"",
        id_gamification:"heal-2",
        communicationbuttons:[
               {
                title:"Data Entry",
                innerText:"Record patients' medical history, vital statistics, or information such as test results in medical records."
               },
               {
                title:"Cleanliness",
                innerText:"Clean and sterilize instruments and dispose of contaminated supplies."
               },
              {
                title:"Communicate",
                innerText:"Explain treatment procedures, medications, diets, or physicians' instructions to patients."
               },
               {
                title:"Organized",
                innerText:"Collect blood, tissue, or other laboratory specimens, log the specimens, and prepare them for testing."
               },
               {
                title:"Social Skills",
                innerText:"Greet and log in patients arriving at office or clinic."
               },
               {
                title:"Operate Machines",
                innerText:"Operate x-ray, electrocardiogram (EKG), or other equipment to administer routine diagnostic tests."
               },
        ]
    },
    {
      jobtitle:'Medical Secretary',
      salary:"$32,438",
      description:"Secretaries and administrative assistants do routine clerical and organizational tasks. Although secretaries and administrative assistants work in nearly every industry, about half of all workers in the occupation are employed in healthcare; education; and professional, scientific, and technical services. Most work full time.", 
      jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'jpvPQ5oFmHE',srcOrHref:'https://youtu.be/jpvPQ5oFmHE'},
      education:"",
      id_gamification:"heal-3",
      communicationbuttons:[
             {
              title:"Organized",
              innerText:"Answer telephones and direct calls to appropriate staff."
             },
             {
              title:"Data Entry",
              innerText:"Transcribe recorded messages or practitioners' diagnoses or recommendations into patients' medical records."
             },
            {
              title:"Social Skills",
              innerText:"Greet visitors, ascertain purpose of visit, and direct them to appropriate staff."
             },
             {
              title:"Computer Usage",
              innerText:"Schedule and confirm patient diagnostic appointments, surgeries, or medical consultations."
             },
             {
              title:"Detailed",
              innerText:"Interview patients to complete documents, case histories, or forms, such as intake or insurance forms."
             },
             {
              title:"Finance",
              innerText:"Perform bookkeeping duties, such as credits or collections, preparing and sending financial statements or bills, and keeping financial records."
             },
      ]
  },
  {
    jobtitle:'Charge Nurse',
    salary:"$38,772",
    description:"Care for ill, injured, or convalescing patients or persons with disabilities in hospitals, nursing homes, clinics, private homes, group homes, and similar institutions. May work under the supervision of a registered nurse. Licensing required.", 
    jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'yTBPYOnNjVU',srcOrHref:'https://youtu.be/yTBPYOnNjVU'},
    education:"",
    id_gamification:"heal-4",
    communicationbuttons:[
           {
            title:"Data Entry",
            innerText:"Measure and record patients' vital signs, such as height, weight, temperature, blood pressure, pulse, or respiration."
           },
           {
            title:"Supervise",
            innerText:"Supervise nurses' aides or assistants."
           },
          {
            title:"Analysis",
            innerText:"Evaluate nursing intervention outcomes, conferring with other healthcare team members as necessary."
           },
           {
            title:"Teamwork",
            innerText:"Work as part of a healthcare team to assess patient needs, plan and modify care, and implement interventions."
           },
           {
            title:"Organized",
            innerText:"Collect samples, such as blood, urine, or sputum from patients, and perform routine laboratory tests on samples."
           },
           {
            title:"Detailed",
            innerText:"Administer prescribed medications or start intravenous fluids, noting times and amounts on patients' charts."
           },
    ]
},
{
  jobtitle:'Pharmacy Technician',
  salary:"$29,911",
  description:"Pharmacy technicians help pharmacists dispense prescription medication to customers or health professionals. Pharmacy technicians work in pharmacies, including those found in drug, general merchandise, and grocery stores, and in hospitals. Most work full time, but many work part time.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'xmQM3R-Ijj4',srcOrHref:'https://youtu.be/xmQM3R-Ijj4'},
  education:"",
  id_gamification:"heal-5",
  communicationbuttons:[
         {
          title:"Customer Service",
          innerText:"Assist customers by answering simple questions, locating items, or referring them to the pharmacist for medication information."
         },
         {
          title:"Detailed",
          innerText:"Receive written prescription or refill requests and verify that information is complete and accurate."
         },
        {
          title:"Data Entry",
          innerText:"Enter prescription information into computer databases."
         },
         {
          title:"Organized",
          innerText:"Order, label, and count stock of medications, chemicals, or supplies and enter inventory data into computer."
         },
         {
          title:"Cleanliness",
          innerText:"Clean and help maintain equipment or work areas and sterilize glassware, according to prescribed methods."
         },
         {
          title:"Stocking",
          innerText:"Price stock and mark items for sale."
         },
  ]
},
{
  jobtitle:'Dental Hygienist',
  salary:"$31,272",
  description:"Administer oral hygiene care to patients. Assess patient oral hygiene problems or needs and maintain health records. Advise patients on oral health maintenance and disease prevention. May provide advanced care such as providing fluoride treatment or administering topical anesthesia.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'TeAtDfCQ0J4',srcOrHref:'https://youtu.be/TeAtDfCQ0J4'},
  education:"",
  id_gamification:"heal-6",
  communicationbuttons:[
         {
          title:"Cleanliness",
          innerText:"Clean calcareous deposits, accretions, and stains from teeth and beneath margins of gums, using dental instruments."
         },
         {
          title:"Data Entry",
          innerText:"Record and review patient medical histories."
         },
        {
          title:"Analysis",
          innerText:"Examine gums, using probes, to locate periodontal recessed gums and signs of gum disease."
         },
         {
          title:"Instructing",
          innerText:"Conduct dental health clinics for community groups to augment services of dentist."
         },
         {
          title:"Customer Service",
          innerText:"Provide clinical services or health education to improve and maintain the oral health of patients or the general public."
         },
         {
          title:"Maintenance",
          innerText:"Maintain dental equipment and sharpen and sterilize dental instruments."
         },
  ]
},
{
  jobtitle:'Health Manager',
  salary:"$77,043",
  description:"Medical and health services managers plan, direct, and coordinate the business activities of healthcare providers. Most medical and health services managers work in offices in healthcare facilities, including hospitals and nursing homes, and group medical practices.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'jmh01pXYn5I',srcOrHref:'https://youtu.be/jmh01pXYn5I'},
  education:"",
  id_gamification:"heal-7",
  communicationbuttons:[
         {
          title:"Supervise",
          innerText:"Direct, supervise and evaluate work activities of medical, nursing, technical, clerical, service, maintenance, and other personnel."
         },
         {
          title:"Strategic",
          innerText:"Develop and implement organizational policies and procedures for the facility or medical unit."
         },
        {
          title:"Managerial",
          innerText:"Establish work schedules and assignments for staff, according to workload, space, and equipment availability."
         },
         {
          title:"Instructing",
          innerText:"Develop or expand and implement medical programs or health services that promote research, rehabilitation, and community health."
         },
         {
          title:"Problem Solving",
          innerText:"Review and analyze facility activities and data to aid planning and cash and risk management and to improve service utilization."
         },
         {
          title:"Training",
          innerText:"Direct or conduct recruitment, hiring, and training of personnel."
         },
  ]
},
{
  jobtitle:'Nurse Practitioner',
  salary:"$94,711",
  description:"Diagnose and treat acute, episodic, or chronic illness, independently or as part of a healthcare team. May focus on health promotion and disease prevention. May order, perform, or interpret diagnostic tests such as lab work and x rays. May prescribe medication. Must be registered nurses who have specialized graduate education.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'smiXChYIRUU',srcOrHref:'https://youtu.be/smiXChYIRUU'},
  education:"",
  id_gamification:"heal-8",
  communicationbuttons:[
         {
          title:"Data Entry",
          innerText:"Maintain complete and detailed records of patients' health care plans and prognoses."
         },
         {
          title:"Technical Writing",
          innerText:"Develop treatment plans, based on scientific rationale, standards of care, and professional practice guidelines."
         },
        {
          title:"Teaching",
          innerText:"Provide patients with information needed to promote health, reduce risk factors, or prevent disease or disability."
         },
         {
          title:"Analysis",
          innerText:"Analyze and interpret patients' histories, symptoms, physical findings, or diagnostic information to develop appropriate diagnoses."
         },
         {
          title:"Detailed",
          innerText:"Prescribe medication dosages, routes, and frequencies, based on such patient characteristics as age and gender."
         },
         {
          title:"Diagnose",
          innerText:"Diagnose or treat chronic health care problems, such as high blood pressure and diabetes."
         },
  ]
},
{
  jobtitle:'Physical Therapy Assistant',
  salary:"$59,123",
  description:"Assist physical therapists in providing physical therapy treatments and procedures. May, in accordance with state laws, assist in the development of treatment plans, carry out routine functions, document the progress of treatment, and modify specific treatments in accordance with patient status and within the scope of treatment plans established by a physical therapist. Generally requires formal training.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'fbpS0uBh7b0',srcOrHref:'https://youtu.be/fbpS0uBh7b0'},
  education:"",
  id_gamification:"heal-9",
  communicationbuttons:[
         {
          title:"Data Entry",
          innerText:"Document patient information, such as notes on their progress."
         },
         {
          title:"Instructing",
          innerText:"Instruct patients in proper body mechanics and in ways to improve functional mobility, such as aquatic exercise."
         },
        {
          title:"Physical Strength",
          innerText:"Secure patients into or onto therapy equipment."
         },
         {
          title:"Cleanliness",
          innerText:"Clean work area and check and store equipment after treatment."
         },
         {
          title:"Communicate",
          innerText:"Communicate with or instruct caregivers or family members on patient therapeutic activities or treatment plans."
         },
         {
          title:"Active Learning",
          innerText:"Attend or conduct continuing education courses, seminars, or in-service activities."
         },
  ]
},
{
  jobtitle:'Clinical Laboratory Technologist',
  salary:"$37,266",
  description:"Clinical laboratory technologists and technicians collect samples and perform tests to analyze body fluids, tissue, and other substances. Many clinical laboratory technologists and technicians work in hospitals. Others work in medical and diagnostic laboratories or doctors’ offices.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'tm2KBoKns78',srcOrHref:'https://youtu.be/tm2KBoKns78'},
  education:"",
  id_gamification:"heal-10",
  communicationbuttons:[
         {
          title:"Analysis",
          innerText:"Analyze laboratory findings to check the accuracy of the results."
         },
         {
          title:"Data Entry",
          innerText:"Enter data from analysis of medical tests or clinical results into computer for storage."
         },
        {
          title:"Organized",
          innerText:"Establish or monitor quality assurance programs or activities to ensure the accuracy of laboratory results."
         },
         {
          title:"Critical Analysis",
          innerText:"Analyze samples of biological material for chemical content or reaction."
         },
         {
          title:"Communicate",
          innerText:"Provide technical information about test results to physicians, family members, or researchers."
         },
         {
          title:"Blood Work",
          innerText:"Harvest cell cultures at optimum time, based on knowledge of cell cycle differences and culture conditions."
         },
  ]
}
       ],
  },
    hospitality_and_tourism:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/j5IJHSpu31w",videoId:'j5IJHSpu31w',imageOrVideo:'video',srcOrHref:'https://youtu.be/j5IJHSpu31w'
      },
      jobs:[
        {
          jobtitle:'Kitchen Manager',
          salary:"$27,775",
          description:"First-Line Supervisors of Food Preparation and Serving Workers directly supervise and coordinate activities of workers engaged in preparing and serving food.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'F3mKvjI62ik',srcOrHref:'https://youtu.be/F3mKvjI62ik'},
          education:"",
          id_gamification:"hosp-1",
          communicationbuttons:[
                 {
                  title:"Office Work",
                  innerText:"Perform various financial activities, such as cash handling, deposit preparation, and payroll."
                 },
                 {
                  title:"Customer Service",
                  innerText:"Resolve customer complaints regarding food service."
                 },
                {
                  title:"Safety",
                  innerText:"Inspect supplies, equipment, and work areas to ensure efficient service and conformance to standards."
                 },
                 {
                  title:"Physical Labor",
                  innerText:"Perform food preparation and serving duties, such as carving meat, preparing flambe dishes, or serving wine and liquor."
                 },
                 {
                  title:"Training",
                  innerText:"Train workers in food preparation, and in service, sanitation, and safety procedures."
                 },
                 {
                  title:"Supervise",
                  innerText:"Supervise and participate in kitchen and dining area cleaning activities."
                 },
          ]
},
{
  jobtitle:'Food Service Managers',
  salary:"$50,444",
  description:"Food service managers are responsible for the daily operation of restaurants or other establishments that prepare and serve food and beverages. Food service managers work in restaurants, hotels, school cafeterias, and other establishments where food is prepared and served. They often work evenings, weekends, and holidays. The work can be hectic, and dealing with dissatisfied customers can be stressful.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'OzTbTaVPM2w',srcOrHref:'https://youtu.be/OzTbTaVPM2w'},
  education:"",
  id_gamification:"hosp-2",
  communicationbuttons:[
         {
          title:"Scheduling",
          innerText:"Schedule and receive food and beverage deliveries, checking delivery contents to verify product quality and quantity."
         },
         {
          title:"Quality Control",
          innerText:"Test cooked food by tasting and smelling it to ensure palatability and flavor conformity."
         },
        {
          title:"Organization",
          innerText:"Plan menus and food utilization, based on anticipated number of guests, nutritional value, palatability, popularity, and costs."
         },
         {
          title:"Critical Thinking",
          innerText:"Review work procedures and operational problems to determine ways to improve service, performance, or safety."
         },
         {
          title:"Customer Service",
          innerText:"Greet guests, escort them to their seats, and present them with menus and wine lists."
         },
         {
          title:"Management",
          innerText:"Schedule staff hours and assign duties."
         },
  ]
},
{
  jobtitle:'Housekeeping Supervisor',
  salary:"$37,951",
  description:"First-Line Supervisors of Housekeeping and Janitorial Workers directly supervise and coordinate work activities of cleaning personnel in hotels, hospitals, offices, and other establishments.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'3pZIBcPzE8M',srcOrHref:'https://youtu.be/3pZIBcPzE8M'},
  education:"",
  id_gamification:"hosp-3",
  communicationbuttons:[
         {
          title:"Supervise",
          innerText:"Supervise in-house services, such as laundries, maintenance and repair, dry cleaning, or valet services."
         },
         {
          title:"Cleaning",
          innerText:"Select the most suitable cleaning materials for different types of linens, furniture, flooring, and surfaces."
         },
        {
          title:"Communicate",
          innerText:"Advise managers, desk clerks, or admitting personnel of rooms ready for occupancy."
         },
         {
          title:"Quality Control",
          innerText:"Inspect work performed to ensure that it meets specifications and established standards."
         },
         {
          title:"Physical Labor",
          innerText:"Perform or assist with cleaning duties as necessary."
         },
         {
          title:"Scheduling",
          innerText:"Plan and prepare employee work schedules."
         },
  ]
},
{
  jobtitle:'Receptionist',
  salary:"$25,755",
  description:"Information clerks perform routine clerical duties, maintain records, collect data, and provide information to customers. Although information clerks are employed in nearly every industry, many work in government agencies, hotels, and healthcare facilities. Most information clerks work full time.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'pvH8lSAU6Sw',srcOrHref:'https://youtu.be/pvH8lSAU6Sw'},
  education:"",
  id_gamification:"hosp-4",
  communicationbuttons:[
         {
          title:"Organized",
          innerText:"Operate telephone switchboard to answer, screen, or forward calls, providing information, taking messages, or scheduling appointments."
         },
         {
          title:"Social Skills",
          innerText:"Greet persons entering establishment, determine nature and purpose of visit, and direct or escort them to specific destinations."
         },
        {
          title:"Problem Solving",
          innerText:"Hear and resolve complaints from customers or the public."
         },
         {
          title:"Analysis",
          innerText:"Analyze data to determine answers to questions from customers or members of the public."
         },
         {
          title:"Data Entry",
          innerText:"Take orders for merchandise or materials and send them to the proper departments to be filled."
         },
         {
          title:"Mathematics",
          innerText:"Calculate and quote rates for tours, stocks, insurance policies, or other products or services."
         },
  ]
},
{
  jobtitle:'General & Operations Manager',
  salary:"$109,405",
  description:"Plan, direct, or coordinate the operations of public or private sector organizations, overseeing multiple departments or locations. Duties and responsibilities include formulating policies, managing daily operations, and planning the use of materials and human resources, but are too diverse and general in nature to be classified in any one functional area of management or administration, such as personnel, purchasing, or administrative services. Usually manage through subordinate supervisors. Excludes First-Line Supervisors.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'2rSN2E1_aPQ',srcOrHref:'https://youtu.be/2rSN2E1_aPQ'},
  education:"",
  id_gamification:"hosp-5",
  communicationbuttons:[
         {
          title:"Leadership",
          innerText:"Direct and coordinate activities of businesses or departments concerned with the production, pricing, sales, or distribution of products."
         },
         {
          title:"Coordinate",
          innerText:"Prepare staff work schedules and assign specific duties."
         },
        {
          title:"Analysis",
          innerText:"Direct or coordinate financial or budget activities to fund operations, maximize investments, or increase efficiency."
         },
         {
          title:"Sales",
          innerText:"Set prices or credit terms for goods or services, based on forecasts of customer demand."
         },
         {
          title:"Marketing",
          innerText:"Develop or implement product-marketing strategies, including advertising campaigns or sales promotions."
         },
         {
          title:"Communicate",
          innerText:"Recommend locations for new facilities, or oversee the remodeling or renovating of current facilities."
         },
  ]
},
      ],
  },
    modern_manufacturing_auto: {
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/kJ0MTvwCw_4",videoId:'kJ0MTvwCw_4',imageOrVideo:'video',srcOrHref:'https://youtu.be/kJ0MTvwCw_4'
      },
      jobs:[ 
          {
            jobtitle:'Team Assemblers',
            salary:"$20,840 - $56,950",
            description:"Work as part of a team having responsibility for assembling an entire product or component of a product. Team assemblers can perform all tasks conducted by the team in the assembly process and rotate through all or most of them, rather than being assigned to a specific task on a permanent basis. May participate in making management decisions affecting the work. Includes team leaders who work as part of the team.", 
            jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'TSn6ghApPKM',srcOrHref:'https://youtu.be/TSn6ghApPKM'},
            education:"",
            id_gamification:"mode-1",
            communicationbuttons:[
                   {
                    title:"Quality Control",
                    innerText:"Perform quality checks on products and parts."
                   },
                   {
                    title:"Detail Oriented",
                    innerText:"Review work orders and blueprints to ensure work is performed according to specifications."
                   },
                  {
                    title:"Instructing",
                    innerText:"Supervise assemblers and train employees on job procedures."
                   },
                   {
                    title:"Cleanliness",
                    innerText:"Shovel, sweep, or otherwise clean work areas."
                   },
                   {
                    title:"Operate Machinery",
                    innerText:"Operate machinery and heavy equipment, such as forklifts."
                   },
                   {
                    title:"Time Management",
                    innerText:"Rotate through all the tasks required in a particular production process."
                   },
            ]
        },
        {
          jobtitle:'Maintenance and Repair Workers',
          salary:"$19,870 - $53,630",
          description:"Perform work involving the skills of two or more maintenance or craft occupations to keep machines, mechanical equipment, or the structure of a building in repair. Duties may involve pipe fitting; HVAC maintenance; insulating; welding; machining; carpentry; repairing electrical or mechanical equipment; installing, aligning, and balancing new equipment; and repairing buildings, floors, or stairs.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'I_mhZjRcH5s',srcOrHref:'https://youtu.be/I_mhZjRcH5s'},
          education:"",
          id_gamification:"mode-2",
          communicationbuttons:[
                 {
                  title:"Machinery Maintenance",
                  innerText:"Dismantle machines, equipment, or devices to access and remove defective parts, using hoists, cranes, hand tools, or power tools."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Inspect, operate, or test machinery or equipment to diagnose machine malfunctions."
                 },
                {
                  title:"Detail Oriented",
                  innerText:"Plan and lay out repair work, using diagrams, drawings, blueprints, maintenance manuals, or schematic diagrams."
                 },
                 {
                  title:"Welding",
                  innerText:"Operate cutting torches or welding equipment to cut or join metal parts."
                 },
                 {
                  title:"Fabricate",
                  innerText:"Fabricate or repair counters, benches, partitions, or other wooden structures, such as sheds or outbuildings."
                 },
                 {
                  title:"Critical Thinking",
                  innerText:"Estimate costs to repair machinery, equipment, or building structures."
                 },
          ]
      },
      {
        jobtitle:'Supervisors of Production',
        salary:"$34,860 - $96,330",
        description:"Directly supervise and coordinate the activities of production and operating workers, such as inspectors, precision workers, machine setters and operators, assemblers, fabricators, and plant and system operators. Excludes team or work leaders.", 
        jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'dflNKhApmBE',srcOrHref:'https://youtu.be/dflNKhApmBE'},
        education:"",
        id_gamification:"mode-3",
        communicationbuttons:[
               {
                title:"Instructing",
                innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
               },
               {
                title:"Persuasion",
                innerText:"Recommend or implement measures to motivate employees and to improve production methods, equipment performance, product quality, or efficiency."
               },
              {
                title:"Critical Thinking",
                innerText:"Interpret specifications, blueprints, job orders, and company policies and procedures for workers."
               },
               {
                title:"Teamwork",
                innerText:"Confer with other supervisors to coordinate operations and activities within or between departments."
               },
               {
                title:"Organization",
                innerText:"Plan and establish work schedules, assignments, and production sequences to meet production goals."
               },
               {
                title:"Negotiation",
                innerText:"Confer with management or subordinates to resolve worker problems, complaints, or grievances."
               },
        ]
    }

       ],
  },
    modern_manufacturing:{
      mediaInfo:{
        videoType:'',link:"automotive-explore",videoId:'', imageOrVideo:'img',srcOrHref:'automotive-explore'
      },
      jobs:[ 
        {
          jobtitle:'Team Assemblers',
          salary:"$30,413",
          description:"Assemblers and fabricators build finished products and the parts that go into them. Most assemblers and fabricators work in manufacturing plants. Their duties may involve long periods of standing or sitting. Most work full time, including some evenings and weekends.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'TSn6ghApPKM',srcOrHref:'https://youtu.be/TSn6ghApPKM'},
          education:"",
          id_gamification:"moda-1",
          communicationbuttons:[
                 {
                  title:"Welding",
                  innerText:"Weld components in flat, vertical, or overhead positions."
                 },
                 {
                  title:"Safety",
                  innerText:"Operate safety equipment and use safe work habits."
                 },
                {
                  title:"Quality Control",
                  innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
                 },
                 {
                  title:"Hand Tools",
                  innerText:"Position and secure workpieces, using hoists, cranes, wire, and banding machines or hand tools."
                 },
                 {
                  title:"Analysis",
                  innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
                 },
                 {
                  title:"Design",
                  innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
                 },
          ]
      },
      {
        jobtitle:'Production Manager',
        salary:"$57,537",
        description:"First-Line Supervisors of Production and Operating Workers directly supervise and coordinate the activities of production and operating workers, such as inspectors, precision workers, machine setters and operators, assemblers, fabricators, and plant and system operators.", 
        jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'dflNKhApmBE',srcOrHref:'https://youtu.be/dflNKhApmBE'},
        education:"",
        id_gamification:"moda-2",
        communicationbuttons:[
               {
                title:"Organized",
                innerText:"Keep records of employees' attendance and hours worked."
               },
               {
                title:"Detailed",
                innerText:"Inspect materials, products, or equipment to detect defects or malfunctions."
               },
              {
                title:"Communicate",
                innerText:"Confer with other supervisors to coordinate operations and activities within or between departments."
               },
               {
                title:"Training",
                innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
               },
               {
                title:"Leadership",
                innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
               },
               {
                title:"Analysis",
                innerText:"Determine standards, budgets, production goals, and rates, based on company policies, equipment and labor availability, and workloads."
               },
        ]
    },
    {
      jobtitle:'Industrial Engineering Technician',
      salary:"$48,453",
      description:"Industrial engineering technologists and technicians help engineers solve problems affecting manufacturing layout or production. Industrial engineering technologists and technicians typically need an associate’s degree or a postsecondary certificate to enter the occupation. Community colleges or technical institutes typically offer associate’s degree programs, and vocational–technical schools offer certificate programs.", 
      jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'GhVZTN1pZys',srcOrHref:'https://youtu.be/GhVZTN1pZys'},
      education:"",
      id_gamification:"moda-3",
      communicationbuttons:[
             {
              title:"Quality Control",
              innerText:"Test selected products at specified stages in the production process for performance characteristics or adherence to specifications."
             },
             {
              title:"Detailed",
              innerText:"Compile and evaluate statistical data to determine and maintain quality and reliability of products."
             },
            {
              title:"Supervise",
              innerText:"Read worker logs, product processing sheets, or specification sheets to verify that records adhere to quality assurance specifications."
             },
             {
              title:"Safety",
              innerText:"Adhere to all applicable regulations, policies, and procedures for health, safety, and environmental compliance."
             },
             {
              title:"Engineering",
              innerText:"Assist engineers in developing, building, or testing prototypes or new products, processes, or procedures."
             },
             {
              title:"Analysis",
              innerText:"Conduct statistical studies to analyze or compare production costs for sustainable and nonsustainable designs."
             },
      ]
  },
  {
    jobtitle:'Machine Operator',
    salary:"$35,234",
    description:"Cutting, Punching, and Press Machine Setters, Operators, and Tenders, Metal and Plastic set up, operate, or tend machines to saw, cut, shear, slit, punch, crimp, notch, bend, or straighten metal or plastic material.", 
    jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'176-HGCTLqE',srcOrHref:'https://youtu.be/176-HGCTLqE'},
    education:"",
    id_gamification:"moda-4",
    communicationbuttons:[
           {
            title:"Measuring",
            innerText:"Measure completed workpieces to verify conformance to specifications, using micrometers, gauges, calipers, templates, or rulers."
           },
           {
            title:"Machine Usage",
            innerText:"Set up, operate, or tend machines to saw, cut, shear, slit, punch, crimp, notch, bend, or straighten metal or plastic material."
           },
          {
            title:"Quality Control",
            innerText:"Examine completed workpieces for defects, such as chipped edges or marred surfaces and sort defective pieces according to types of flaws."
           },
           {
            title:"Hand Tools",
            innerText:"Test and adjust machine speeds or actions, according to product specifications, using gauges and hand tools."
           },
           {
            title:"Driving",
            innerText:"Operate forklifts to deliver materials."
           },
           {
            title:"Organized",
            innerText:"Plan sequences of operations, applying knowledge of physical properties of workpiece materials."
           },
    ]
},
{
  jobtitle:'Engineering Technician',
  salary:"$55,950",
  description:"Electro-mechanical and mechatronics technologists and technicians operate, test, and maintain electromechanical or robotic equipment. Electrical and electronic engineering technologists and technicians work on teams with electrical engineers. They may work in offices, laboratories, or factories.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'GhVZTN1pZys',srcOrHref:'https://youtu.be/GhVZTN1pZys'},
  education:"",
  id_gamification:"moda-5",
  communicationbuttons:[
         {
          title:"Quality Control",
          innerText:"Test selected products at specified stages in the production process for performance characteristics or adherence to specifications."
         },
         {
          title:"Detailed",
          innerText:"Compile and evaluate statistical data to determine and maintain quality and reliability of products."
         },
        {
          title:"Supervise",
          innerText:"Read worker logs, product processing sheets, or specification sheets to verify that records adhere to quality assurance specifications."
         },
         {
          title:"Safety",
          innerText:"Adhere to all applicable regulations, policies, and procedures for health, safety, and environmental compliance."
         },
         {
          title:"Engineering",
          innerText:"Assist engineers in developing, building, or testing prototypes or new products, processes, or procedures."
         },
         {
          title:"Analysis",
          innerText:"Conduct statistical studies to analyze or compare production costs for sustainable and nonsustainable designs."
         },
  ]
},
{
  jobtitle:'Precision Machinist',
  salary:"$44,332",
  description:"Machinists and tool and die makers set up and operate equipment to produce precision metal parts, instruments, and tools. Machinists and tool and die makers work in machine shops and factories. Many work full time during regular business hours. However, working overtime, as well as nights and weekends, may be common.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'OWNXI09WI14',srcOrHref:'https://youtu.be/OWNXI09WI14'},
  education:"",
  id_gamification:"moda-6",
  communicationbuttons:[
         {
          title:"Accuracy",
          innerText:"Calculate dimensions or tolerances, using instruments, such as micrometers or vernier calipers."
         },
         {
          title:"Monitor",
          innerText:"Monitor the feed and speed of machines during the machining process."
         },
        {
          title:"Analysis",
          innerText:"Study sample parts, blueprints, drawings, or engineering information to determine methods or sequences of operations needed to fabricate products."
         },
         {
          title:"Organize",
          innerText:"Separate scrap waste and related materials for reuse, recycling, or disposal."
         },
         {
          title:"Design",
          innerText:"Design fixtures, tooling, or experimental parts to meet special engineering needs."
         },
         {
          title:"Quality Control",
          innerText:"Maintain machine tools in proper operational condition."
         },
  ]
},
{
  jobtitle:'Production Manager',
  salary:"$64,699",
  description:"Directly supervise and coordinate the activities of production and operating workers, such as inspectors, precision workers, machine setters and operators, assemblers, fabricators, and plant and system operators. Excludes team or work leaders.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'dflNKhApmBE',srcOrHref:'https://youtu.be/dflNKhApmBE'},
  education:"",
  id_gamification:"moda-7",
  communicationbuttons:[
         {
          title:"Organized",
          innerText:"Keep records of employees' attendance and hours worked."
         },
         {
          title:"Detailed",
          innerText:"Inspect materials, products, or equipment to detect defects or malfunctions."
         },
        {
          title:"Communicate",
          innerText:"Confer with other supervisors to coordinate operations and activities within or between departments."
         },
         {
          title:"Training",
          innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
         },
         {
          title:"Leadership",
          innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
         },
         {
          title:"Analysis",
          innerText:"Determine standards, budgets, production goals, and rates, based on company policies, equipment and labor availability, and workloads."
         },
  ]
},
{
  jobtitle:'Welder',
  salary:"$37,332",
  description:"Welders, cutters, solderers, and brazers may work outdoors, often in inclement weather, or indoors, sometimes in a confined area. They may work on a scaffold, high off the ground, and they occasionally must lift heavy objects and work in awkward positions. Most work full time and overtime is common.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'cETNESaLhcA',srcOrHref:'https://youtu.be/cETNESaLhcA'},
  education:"",
  id_gamification:"moda-8",
  communicationbuttons:[
         {
          title:"Welding",
          innerText:"Weld components in flat, vertical, or overhead positions."
         },
         {
          title:"Safety",
          innerText:"Operate safety equipment and use safe work habits."
         },
        {
          title:"Quality Control",
          innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
         },
         {
          title:"Hand Tools",
          innerText:"Position and secure workpieces, using hoists, cranes, wire, and banding machines or hand tools."
         },
         {
          title:"Analysis",
          innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
         },
         {
          title:"Design",
          innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
         },
  ]
},
{
  jobtitle:'Shipping Clerk',
  salary:"$30,053",
  description:"Verify and maintain records on incoming and outgoing shipments involving inventory. Duties include verifying and recording incoming merchandise or material and arranging for the transportation of products. May prepare items for shipment.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'EvqriUvNeiY',srcOrHref:'https://youtu.be/EvqriUvNeiY'},
  education:"",
  id_gamification:"moda-9",
  communicationbuttons:[
         {
          title:"Detailed",
          innerText:"Examine shipment contents and compare with records, such as manifests, invoices, or orders, to verify accuracy."
         },
         {
          title:"Organized",
          innerText:"Requisition and store shipping materials and supplies to maintain inventory of stock."
         },
        {
          title:"Data Entry",
          innerText:"Prepare documents, such as work orders, bills of lading, or shipping orders, to route materials."
         },
         {
          title:"Tool Usage",
          innerText:"Pack, seal, label, or affix postage to prepare materials for shipping, using hand tools, power tools, or postage meter."
         },
         {
          title:"Physical Labor",
          innerText:"Deliver or route materials to departments using handtruck, conveyor, or sorting bins."
         },
         {
          title:"Analysis",
          innerText:"Determine shipping methods, routes, or rates for materials to be shipped."
         },
  ]
},
{
  jobtitle:'Industrial Engineers',
  salary:"$91,218",
  description:"Depending on their tasks, industrial engineers work either in offices or in the settings they are trying to improve. For example, when observing problems, they may watch workers assembling parts in a factory. When solving problems, they may be in an office at a computer, looking at data that they or others have collected.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'kNXMJBQ6oL4',srcOrHref:'https://youtu.be/kNXMJBQ6oL4'},
  education:"",
  id_gamification:"moda-10",
  communicationbuttons:[
         {
          title:"Teamwork",
          innerText:"Communicate with management and user personnel to develop production and design standards."
         },
         {
          title:"Critical Analysis",
          innerText:"Evaluate precision and accuracy of production and testing equipment and engineering drawings to formulate corrective action plan."
         },
        {
          title:"Management",
          innerText:"Direct workers engaged in product measurement, inspection, and testing activities to ensure quality control and reliability."
         },
         {
          title:"Systems Design",
          innerText:"Plan and establish sequence of operations to fabricate and assemble parts or products and to promote efficient utilization."
         },
         {
          title:"Problem Solving",
          innerText:"Recommend methods for improving utilization of personnel, material, and utilities."
         },
         {
          title:"Organization",
          innerText:"Complete production reports, purchase orders, and material, tool, and equipment lists."
         },
  ]
}
       ],
  },
  law_public_safety_and_corrections:{
      mediaInfo:{
        videoType:'',link:"law-careers",videoId:'', imageOrVideo:'img',srcOrHref:'law-careers'
      },
      jobs:[
        {
          jobtitle:'Firefighter',
          salary:"$39,224",
          description:"Firefighters control and put out fires and respond to emergencies involving life, property, or the environment. On the scene of a fire or other emergency, firefighters’ work may be dangerous. On call at fire stations, firefighters sleep, eat, and perform other duties during shifts that often last 24 hours. Most paid firefighters work full time.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'DNK5dYGn3Fg',srcOrHref:'https://youtu.be/DNK5dYGn3Fg'},
          education:"",
          id_gamification:"lawp-1",
          communicationbuttons:[
                 {
                  title:"Critical Thinking",
                  innerText:"Assess fires and situations and report conditions to superiors to receive instructions, using two-way radios."
                 },
                 {
                  title:"Hand Tools",
                  innerText:"Create openings in buildings for ventilation or entrance, using axes, chisels, crowbars, electric saws, or core cutters."
                 },
                {
                  title:"Quality Control",
                  innerText:"Inspect fire sites after flames have been extinguished to ensure that there is no further danger."
                 },
                 {
                  title:"Judgement",
                  innerText:"Move toward the source of a fire, using knowledge of types of fires, construction design, building materials, and physical layout of properties."
                 },
                 {
                  title:"Collaborate",
                  innerText:"Collaborate with police to respond to accidents, disasters, and arson investigation calls."
                 },
                 {
                  title:"Physical Fitness",
                  innerText:"Participate in physical training activities to maintain a high level of physical fitness."
                 },
          ]
},
{
  jobtitle:'Police Officer',
  salary:"$42,422",
  description:"Police officers protect lives and property. Detectives and criminal investigators gather facts and collect evidence of possible crimes. Police and detective work can be physically demanding, stressful, and dangerous. Police and sheriff's patrol officers and transit and railroad police have some of the highest rates of injuries and illnesses of all occupations. Working around the clock in shifts is common.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'14D91NPvMz8',srcOrHref:'https://youtu.be/14D91NPvMz8'},
  education:"",
  id_gamification:"lawp-2",
  communicationbuttons:[
         {
          title:"Apprehend Suspects",
          innerText:"Identify, pursue, and arrest suspects and perpetrators of criminal acts."
         },
         {
          title:"Public Safety",
          innerText:"Provide for public safety by maintaining order, responding to emergencies, protecting people and property, enforcing motor vehicle and criminal laws, and promoting good community relations."
         },
        {
          title:"Active Listening",
          innerText:"Record facts to prepare reports that document incidents and activities."
         },
         {
          title:"First Aid",
          innerText:"Render aid to accident victims and other persons requiring first aid for physical injuries."
         },
         {
          title:"Investigate",
          innerText:"Investigate illegal or suspicious activities."
         },
         {
          title:"Weapons & Tools",
          innerText:"Use handguns, handcuffs, police vehicles, surveillance video and audio recorders, and two-way radios."
         },
  ]
},
{
  jobtitle:'Lawyer',
  salary:"$98,108",
  description:"Lawyers advise and represent individuals, businesses, and government agencies on legal issues and disputes. The majority of lawyers work in private and corporate legal offices. Some work for federal, local, and state governments. Most work full time and many work more than 40 hours a week.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'aK2PVtgWLp8',srcOrHref:'https://youtu.be/aK2PVtgWLp8'},
  education:"",
  id_gamification:"lawp-3",
  communicationbuttons:[
         {
          title:"Analysis",
          innerText:"Analyze the probable outcomes of cases, using knowledge of legal precedents."
         },
         {
          title:"Advising",
          innerText:"Advise clients concerning business transactions, claim liability, advisability of prosecuting or defending lawsuits, or legal rights and obligations."
         },
        {
          title:"Strategic",
          innerText:"Select jurors, argue motions, meet with judges, and question witnesses during the course of a trial."
         },
         {
          title:"Law",
          innerText:"Interpret laws, rulings and regulations for individuals and businesses."
         },
         {
          title:"Convincing",
          innerText:"Present evidence to defend clients or prosecute defendants in criminal or civil litigation."
         },
         {
          title:"Professional",
          innerText:"Represent clients in court or before government agencies."
         },
  ]
},
{
  jobtitle:'Security Guard',
  salary:"$25,863",
  description:"Security guards and gambling surveillance officers protect property from illegal activity. Security guards work in a variety of places, including industrial settings, retail stores, and office buildings. Gambling surveillance officers work mostly in casinos. Because many buildings and casinos are open 24 hours a day, security guards and officers often must work around the clock.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'SfdJ2Eh4bHw',srcOrHref:'https://youtu.be/SfdJ2Eh4bHw'},
  education:"",
  id_gamification:"lawp-4",
  communicationbuttons:[
         {
          title:"Safety",
          innerText:"Lock doors and gates of entrances and exits to secure buildings."
         },
         {
          title:"Responsive",
          innerText:"Answer alarms and investigate disturbances."
         },
        {
          title:"Observant",
          innerText:"Monitor and authorize entrance and departure of employees, visitors, and other persons to guard against theft and maintain security of premises."
         },
         {
          title:"Writing",
          innerText:"Write reports of daily activities and irregularities, such as equipment or property damage, theft, presence of unauthorized persons, or unusual occurrences."
         },
         {
          title:"Patrol",
          innerText:"Patrol industrial or commercial premises to prevent and detect signs of intrusion and ensure security of doors, windows, and gates."
         },
         {
          title:"Communicate",
          innerText:"Call police or fire departments in cases of emergency, such as fire or presence of unauthorized persons."
         },
  ]
},
{
  jobtitle:'Customer Service Representative',
  salary:"$33,280",
  description:"Customer service representatives interact with customers to handle complaints, process orders, and answer questions. Customer service representatives are employed in nearly every industry. Most work full time.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'0-ERXRCB6Nc',srcOrHref:'https://youtu.be/0-ERXRCB6Nc'},
  education:"",
  id_gamification:"lawp-5",
  communicationbuttons:[
         {
          title:"Communicate",
          innerText:"Confer with customers by telephone or in person to provide information about products or services, take or enter orders, cancel accounts, or obtain details of complaints."
         },
         {
          title:"People Skills",
          innerText:"Resolve customers' service or billing complaints by performing activities such as exchanging merchandise, refunding money, or adjusting bills."
         },
        {
          title:"Data Entry",
          innerText:"Complete contract forms, prepare change of address records, or issue service discontinuance orders, using computers."
         },
         {
          title:"Sales",
          innerText:"Solicit sales of new or additional services or products."
         },
         {
          title:"Organized",
          innerText:"Keep records of customer interactions or transactions, recording details of inquiries, complaints, or comments, as well as actions taken."
         },
         {
          title:"Analysis",
          innerText:"Review insurance policy terms to determine whether a particular loss is covered by insurance."
         },
  ]
},

      ],
  },
    transportation_distribution_and_logistics:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/w0wDnQP1dn8",videoId:'w0wDnQP1dn8',imageOrVideo:'video',srcOrHref:'https://youtu.be/w0wDnQP1dn8'
      },
      jobs:[
        {
          jobtitle:'Semi Truck Drivers',
          salary:"$40,576",
          description:"Heavy and tractor-trailer truck drivers transport goods from one location to another. Working as a long-haul truck driver is a lifestyle choice because these drivers can be away from home for days or weeks at a time.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'yz8Oku9fQfQ',srcOrHref:'https://youtu.be/yz8Oku9fQfQ'},
          education:"",
          id_gamification:"trans-1",
          communicationbuttons:[
                 {
                  title:"Quality Control",
                  innerText:"Check vehicles to ensure that mechanical, safety, and emergency equipment is in good working order."
                 },
                 {
                  title:"Organization",
                  innerText:"Maintain logs of working hours or of vehicle service or repair status, following applicable state and federal regulations."
                 },
                {
                  title:"Detail Oriented",
                  innerText:"Check all load-related documentation for completeness and accuracy."
                 },
                 {
                  title:"Vehicle Operation",
                  innerText:"Maneuver trucks into loading or unloading positions, following signals from loading crew and checking that vehicle and loading equipment are properly positioned."
                 },
                 {
                  title:"Vehicle Maintenance",
                  innerText:"Perform basic vehicle maintenance tasks, such as adding oil, fuel, or radiator fluid or performing minor repairs."
                 },
                 {
                  title:"Critical Thinking",
                  innerText:"Plan or adjust routes based on changing conditions, using computer equipment, global positioning systems (GPS) equipment, or other navigation devices, to minimize fuel consumption and carbon emissions."
                 },
          ]
},
{
  jobtitle:'Delivery Driver',
  salary:"$28,215",
  description:"Light Truck or Delivery Services Drivers pick up, transport, and drop off packages and small shipments within a local region or urban area. Light Truck or Delivery Services Drivers have a physically demanding job. Driving a truck for long periods can be tiring. When loading and unloading cargo, drivers do a lot of lifting, carrying, and walking.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'nhDMoNCrniA',srcOrHref:'https://youtu.be/nhDMoNCrniA'},
  education:"",
  id_gamification:"trans-2",
  communicationbuttons:[
         {
          title:"Safety",
          innerText:"Obey traffic laws and follow established traffic and transportation procedures."
         },
         {
          title:"Organized",
          innerText:"Turn in receipts and money received from deliveries."
         },
        {
          title:"Navigation",
          innerText:"Read maps and follow written or verbal geographic directions."
         },
         {
          title:"Detailed",
          innerText:"Verify the contents of inventory loads against shipping papers."
         },
         {
          title:"Driving",
          innerText:"Drive vehicles with capacities under three tons to transport materials to and from specified destinations."
         },
         {
          title:"Data Entry",
          innerText:"Maintain records, such as vehicle logs, records of cargo, or billing statements, in accordance with regulations."
         },
  ]
},
{
  jobtitle:'Truck Driver',
  salary:"$31,957",
  description:"Industrial Truck and Tractor Operators use equipment to transport objects. Most Industrial Truck and Tractor Operators work full time, and some work more than 40 hours per week. Because materials are shipped around the clock, some operators work overnight shifts.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'0RYX43tWmw0',srcOrHref:'https://youtu.be/0RYX43tWmw0'},
  education:"",
  id_gamification:"trans-3",
  communicationbuttons:[
         {
          title:"Driving",
          innerText:"Move controls to drive gasoline- or electric-powered trucks, cars, or tractors and transport materials between loading, processing, and storage areas."
         },
         {
          title:"Physical Labor",
          innerText:"Manually or mechanically load or unload materials from pallets, skids, platforms, cars, lifting devices, or other transport vehicles."
         },
        {
          title:"Safety",
          innerText:"Position lifting devices under, over, or around loaded pallets, skids, or boxes and secure material or products for transport to designated areas."
         },
         {
          title:"Analysis",
          innerText:"Inspect product load for accuracy and safely move it around the warehouse or facility to ensure timely and complete delivery."
         },
         {
          title:"Detailed",
          innerText:"Weigh materials or products and record weight or other production data on tags or labels."
         },
         {
          title:"Communicate",
          innerText:"Signal workers to discharge, dump, or level materials."
         },
  ]
},
{
  jobtitle:'Shipping Clerk',
  salary:"$30,053",
  description:"Verify and maintain records on incoming and outgoing shipments involving inventory. Duties include verifying and recording incoming merchandise or material and arranging for the transportation of products. May prepare items for shipment.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'EvqriUvNeiY',srcOrHref:'https://youtu.be/EvqriUvNeiY'},
  education:"",
  id_gamification:"trans-4",
  communicationbuttons:[
         {
          title:"Detailed",
          innerText:"Examine shipment contents and compare with records, such as manifests, invoices, or orders, to verify accuracy."
         },
         {
          title:"Organized",
          innerText:"Requisition and store shipping materials and supplies to maintain inventory of stock."
         },
        {
          title:"Data Entry",
          innerText:"Prepare documents, such as work orders, bills of lading, or shipping orders, to route materials."
         },
         {
          title:"Tool Usage",
          innerText:"Pack, seal, label, or affix postage to prepare materials for shipping, using hand tools, power tools, or postage meter."
         },
         {
          title:"Physical Labor",
          innerText:"Deliver or route materials to departments using handtruck, conveyor, or sorting bins."
         },
         {
          title:"Analysis",
          innerText:"Determine shipping methods, routes, or rates for materials to be shipped."
         },
  ]
},
{
  jobtitle:'Logistician',
  salary:"$95,581",
  description:"Logisticians analyze and coordinate an organization’s supply chain. Logisticians work in nearly every industry. The job can be stressful because logistical work is fast-paced. Most logisticians work full time during regular business hours.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'42uJcWOD1ts',srcOrHref:'https://youtu.be/42uJcWOD1ts'},
  education:"",
  id_gamification:"trans-5",
  communicationbuttons:[
         {
          title:"Customer Service",
          innerText:"Develop an understanding of customers' needs and take actions to ensure that such needs are met."
         },
         {
          title:"Writing",
          innerText:"Develop proposals that include documentation for estimates."
         },
        {
          title:"Analysis",
          innerText:"Review logistics performance with customers against targets, benchmarks, and service agreements."
         },
         {
          title:"Logistics",
          innerText:"Direct availability and allocation of materials, supplies, and finished products."
         },
         {
          title:"Critical Thinking",
          innerText:"Redesign the movement of goods to maximize value and minimize costs."
         },
         {
          title:"Communicate",
          innerText:"Explain proposed solutions to customers, management, or other interested parties through written proposals and oral presentations."
         },
  ]
},
      ],
  },
    aerospace:{
      mediaInfo:{
        videoType:'',link:"aerospace-careers",videoId:'', imageOrVideo:'img',srcOrHref:'aerospace-careers'
      },
      jobs:[ 
          {
            jobtitle:'Aerospace Engineer',
            salary:"$116,491",
            description:"Aerospace engineers are employed in industries whose workers design or build aircraft, missiles, systems for national defense, or spacecraft. Aerospace engineers are employed primarily in manufacturing, analysis and design, research and development, and the federal government.", 
            jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'bnxcib-8S4s',srcOrHref:'https://youtu.be/bnxcib-8S4s'},
            education:"",
            id_gamification:"aero-1",
            communicationbuttons:[
                   {
                    title:"Mathematics ",
                    innerText:"Formulate mathematical models or other methods of computer analysis to develop, evaluate, or modify designs."
                   },
                   {
                    title:"System Design",
                    innerText:"Design or engineer filtration systems that reduce harmful emissions."
                   },
                  {
                    title:"Fabricate",
                    innerText:"Fabricate defective sections or parts, using metal fabricating machines, saws, brakes, shears, and grinders."
                   },
                   {
                    title:"Organized",
                    innerText:"Maintain records of performance reports for future reference."
                   },
                   {
                    title:"Leadership",
                    innerText:"Direct aerospace research and development programs."
                   },
                   {
                    title:"Research",
                    innerText:"Research new materials to determine quality or conformance to environmental standards."
                   },
            ]
        },
        {
          jobtitle:'Electrical Engineers',
          salary:"$103,056",
          description:"Electrical and electronics engineers work in industries including research and development, engineering services, manufacturing, telecommunications, and the federal government. Electrical and electronics engineers generally work indoors in offices. However, they may have to visit sites to observe a problem or a piece of complex equipment.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'fhAZ2tZj84A',srcOrHref:'https://youtu.be/fhAZ2tZj84A'},
          education:"",
          id_gamification:"aero-2",
          communicationbuttons:[
                 {
                  title:"Computer Literacy",
                  innerText:"Operate computer-assisted engineering or design software or equipment to perform engineering tasks."
                 },
                 {
                  title:"Communicate",
                  innerText:"Confer with engineers, customers, or others to discuss existing or potential engineering projects or products."
                 },
                {
                  title:"Detail Oriented",
                  innerText:"Perform detailed calculations to compute and establish manufacturing, construction, or installation standards or specifications."
                 },
                 {
                  title:"Management",
                  innerText:"Supervise or train project team members, as necessary."
                 },
                 {
                  title:"Analysis",
                  innerText:"Conduct field surveys or study maps, graphs, diagrams, or other data to identify and correct power system problems."
                 },
                 {
                  title:"Design",
                  innerText:"Design electrical systems or components that minimize electric energy requirements, such as lighting systems designed to account for natural lighting."
                 },
          ]
      },
      {
        jobtitle:'Mechanical Engineers',
        salary:"$96,971",
        description:"Mechanical engineers generally work in offices. They may occasionally visit worksites where a problem or piece of equipment needs their personal attention. Mechanical engineers work mostly in engineering services, research and development, and manufacturing.", 
        jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'bDg2UcaZzcg',srcOrHref:'https://youtu.be/bDg2UcaZzcg'},
        education:"",
        id_gamification:"aero-3",
        communicationbuttons:[
               {
                title:"Analysis",
                innerText:"Read and interpret blueprints, technical drawings, schematics, or computer-generated reports."
               },
               {
                title:"Teamwork",
                innerText:"Confer with engineers or other personnel to implement operating procedures, resolve system malfunctions, or provide technical information."
               },
              {
                title:"Project Management",
                innerText:"Develop, coordinate, or monitor all aspects of production, including selection of manufacturing methods, fabrication, or operation of product designs."
               },
               {
                title:"Quality Control",
                innerText:"Recommend design modifications to eliminate machine or system malfunctions."
               },
               {
                title:"Design",
                innerText:"Design integrated mechanical or alternative systems, such as mechanical cooling systems with natural ventilation systems, to improve energy efficiency."
               },
               {
                title:"Critical Analysis",
                innerText:"Conduct research that tests or analyzes the feasibility, design, operation, or performance of equipment, components, or systems."
               },
        ]
    },
    {
      jobtitle:'Industrial Engineers',
      salary:"$91,218",
      description:"Depending on their tasks, industrial engineers work either in offices or in the settings they are trying to improve. For example, when observing problems, they may watch workers assembling parts in a factory. When solving problems, they may be in an office at a computer, looking at data that they or others have collected.", 
      jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'kNXMJBQ6oL4',srcOrHref:'https://youtu.be/kNXMJBQ6oL4'},
      education:"",
      id_gamification:"aero-4",
      communicationbuttons:[
             {
              title:"Teamwork",
              innerText:"Communicate with management and user personnel to develop production and design standards."
             },
             {
              title:"Critical Analysis",
              innerText:"Evaluate precision and accuracy of production and testing equipment and engineering drawings to formulate corrective action plan."
             },
            {
              title:"Management",
              innerText:"Direct workers engaged in product measurement, inspection, and testing activities to ensure quality control and reliability."
             },
             {
              title:"Systems Design",
              innerText:"Plan and establish sequence of operations to fabricate and assemble parts or products and to promote efficient utilization."
             },
             {
              title:"Problem Solving",
              innerText:"Recommend methods for improving utilization of personnel, material, and utilities."
             },
             {
              title:"Organization",
              innerText:"Complete production reports, purchase orders, and material, tool, and equipment lists."
             },
      ]
  },
  {
    jobtitle:'Electrical Technician',
    salary:"$55,950",
    description:"Electro-mechanical and mechatronics technologists and technicians operate, test, and maintain electromechanical or robotic equipment. Electrical and electronic engineering technologists and technicians work on teams with electrical engineers. They may work in offices, laboratories, or factories.", 
    jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'E_Iy34hFITE',srcOrHref:'https://youtu.be/E_Iy34hFITE'},
    education:"",
    id_gamification:"aero-5",
    communicationbuttons:[
           {
            title:"Tool Usage",
            innerText:"Assemble electrical systems or prototypes, using hand tools or measuring instruments."
           },
           {
            title:"Detailed",
            innerText:"Review electrical engineering plans to ensure adherence to design specifications and compliance with applicable electrical codes and standards."
           },
          {
            title:"Critical Analysis",
            innerText:"Review existing electrical engineering criteria to identify necessary revisions, deletions, or amendments to outdated material."
           },
           {
            title:"Data Entry",
            innerText:"Maintain system logs or manuals to document testing or operation of equipment."
           },
           {
            title:"Project Planning",
            innerText:"Calculate design specifications or cost, material, and resource estimates, and prepare project schedules and budgets."
           },
           {
            title:"Computers",
            innerText:"Integrate software or hardware components, using computer, microprocessor, or control architecture."
           },
    ]
},
{
  jobtitle:'Team Assemblers',
  salary:"$30,413",
  description:"Assemblers and fabricators build finished products and the parts that go into them. Most assemblers and fabricators work in manufacturing plants. Their duties may involve long periods of standing or sitting. Most work full time, including some evenings and weekends.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'TSn6ghApPKM',srcOrHref:'https://youtu.be/TSn6ghApPKM'},
  education:"",
  id_gamification:"aero-6",
  communicationbuttons:[
         {
          title:"Welding",
          innerText:"Weld components in flat, vertical, or overhead positions."
         },
         {
          title:"Safety",
          innerText:"Operate safety equipment and use safe work habits."
         },
        {
          title:"Quality Control",
          innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
         },
         {
          title:"Hand Tools",
          innerText:"Position and secure workpieces, using hoists, cranes, wire, and banding machines or hand tools."
         },
         {
          title:"Analysis",
          innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
         },
         {
          title:"Design",
          innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
         },
  ]
},
{
  jobtitle:'Production Manager',
  salary:"$57,537",
  description:"First-Line Supervisors of Production and Operating Workers directly supervise and coordinate the activities of production and operating workers, such as inspectors, precision workers, machine setters and operators, assemblers, fabricators, and plant and system operators.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'dflNKhApmBE',srcOrHref:'https://youtu.be/dflNKhApmBE'},
  education:"",
  id_gamification:"aero-7",
  communicationbuttons:[
         {
          title:"Organized",
          innerText:"Keep records of employees' attendance and hours worked."
         },
         {
          title:"Detailed",
          innerText:"Inspect materials, products, or equipment to detect defects or malfunctions."
         },
        {
          title:"Communicate",
          innerText:"Confer with other supervisors to coordinate operations and activities within or between departments."
         },
         {
          title:"Training",
          innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
         },
         {
          title:"Leadership",
          innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
         },
         {
          title:"Analysis",
          innerText:"Determine standards, budgets, production goals, and rates, based on company policies, equipment and labor availability, and workloads."
         },
  ]
},
{
  jobtitle:'Welders',
  salary:"$37,332",
  description:"Welders, cutters, solderers, and brazers may work outdoors, often in inclement weather, or indoors, sometimes in a confined area. They may work on a scaffold, high off the ground, and they occasionally must lift heavy objects and work in awkward positions. Most work full time and overtime is common.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'cETNESaLhcA',srcOrHref:'https://youtu.be/cETNESaLhcA'},
  education:"",
  id_gamification:"aero-8",
  communicationbuttons:[
         {
          title:"Welding",
          innerText:"Weld components in flat, vertical, or overhead positions."
         },
         {
          title:"Safety",
          innerText:"Operate safety equipment and use safe work habits."
         },
        {
          title:"Quality Control",
          innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
         },
         {
          title:"Hand Tools",
          innerText:"Position and secure workpieces, using hoists, cranes, wire, and banding machines or hand tools."
         },
         {
          title:"Analysis",
          innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
         },
         {
          title:"Design",
          innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
         },
  ]
},
{
  jobtitle:'Machine Operator',
  salary:"$35,234",
  description:"Cutting, Punching, and Press Machine Setters, Operators, and Tenders, Metal and Plastic set up, operate, or tend machines to saw, cut, shear, slit, punch, crimp, notch, bend, or straighten metal or plastic material.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'176-HGCTLqE',srcOrHref:'https://youtu.be/176-HGCTLqE'},
  education:"",
  id_gamification:"aero-9",
  communicationbuttons:[
         {
          title:"Measuring",
          innerText:"Measure completed workpieces to verify conformance to specifications, using micrometers, gauges, calipers, templates, or rulers."
         },
         {
          title:"Machine Usage",
          innerText:"Set up, operate, or tend machines to saw, cut, shear, slit, punch, crimp, notch, bend, or straighten metal or plastic material."
         },
        {
          title:"Quality Control",
          innerText:"Examine completed workpieces for defects, such as chipped edges or marred surfaces and sort defective pieces according to types of flaws."
         },
         {
          title:"Hand Tools",
          innerText:"Test and adjust machine speeds or actions, according to product specifications, using gauges and hand tools."
         },
         {
          title:"Driving",
          innerText:"Operate forklifts to deliver materials."
         },
         {
          title:"Organized",
          innerText:"Plan sequences of operations, applying knowledge of physical properties of workpiece materials."
         },
  ]
},
{
  jobtitle:'Avionics Technician',
  salary:"$61,267",
  description:"Aerospace engineering and operations technologists and technicians run and maintain equipment used to develop, test, produce, and sustain aircraft and spacecraft. Aerospace engineering and operations technologists and technicians usually work in manufacturing plants, laboratories, and offices.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'1eOnm-61cDE',srcOrHref:'https://youtu.be/1eOnm-61cDE'},
  education:"",
  id_gamification:"aero-10",
  communicationbuttons:[
         {
          title:"Quality Control",
          innerText:"Test aircraft systems under simulated operational conditions, performing systems readiness tests."
         },
         {
          title:"Analysis",
          innerText:"Identify required data, data acquisition plans, and test parameters, setting up equipment to conform to these specifications."
         },
        {
          title:"Teamwork",
          innerText:"Confer with engineering personnel regarding details and implications of test procedures and results."
         },
         {
          title:"Maintenance",
          innerText:"Adjust, repair, or replace faulty components of test setups and equipment."
         },
         {
          title:"Tool Usage",
          innerText:"Fabricate and install parts and systems to be tested in test equipment, using hand tools, power tools, and test instruments."
         },
         {
          title:"Systems Design",
          innerText:"Design electrical and mechanical systems for avionic instrumentation applications."
         },
  ]
},

       ],
  },
    business_and_finance:{
      mediaInfo:{
        videoType:'',link:"business-careers",videoId:'', imageOrVideo:'img',srcOrHref:'business-careers'
      },
      jobs:[
        {
          jobtitle:'Customer Service Representative',
          salary:"$33,280",
          description:"Customer service representatives interact with customers to handle complaints, process orders, and answer questions. Customer service representatives are employed in nearly every industry. Most work full time.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'0-ERXRCB6Nc',srcOrHref:'https://youtu.be/0-ERXRCB6Nc'},
          education:"",
          id_gamification:"busi-1",
          communicationbuttons:[
                 {
                  title:"Communicate",
                  innerText:"Confer with customers by telephone or in person to provide information about products or services, take or enter orders, cancel accounts, or obtain details of complaints."
                 },
                 {
                  title:"People Skills",
                  innerText:"Resolve customers' service or billing complaints by performing activities such as exchanging merchandise, refunding money, or adjusting bills."
                 },
                {
                  title:"Data Entry",
                  innerText:"Complete contract forms, prepare change of address records, or issue service discontinuance orders, using computers."
                 },
                 {
                  title:"Sales",
                  innerText:"Solicit sales of new or additional services or products."
                 },
                 {
                  title:"Organized",
                  innerText:"Keep records of customer interactions or transactions, recording details of inquiries, complaints, or comments, as well as actions taken."
                 },
                 {
                  title:"Analysis",
                  innerText:"Review insurance policy terms to determine whether a particular loss is covered by insurance."
                 },
          ]
},
{
  jobtitle:'General & Operations Manager',
  salary:"$109,405",
  description:"Plan, direct, or coordinate the operations of public or private sector organizations, overseeing multiple departments or locations. Duties and responsibilities include formulating policies, managing daily operations, and planning the use of materials and human resources, but are too diverse and general in nature to be classified in any one functional area of management or administration, such as personnel, purchasing, or administrative services. Usually manage through subordinate supervisors. Excludes First-Line Supervisors.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'2rSN2E1_aPQ',srcOrHref:'https://youtu.be/2rSN2E1_aPQ'},
  education:"",
  id_gamification:"busi-2",
  communicationbuttons:[
         {
          title:"Leadership",
          innerText:"Direct and coordinate activities of businesses or departments concerned with the production, pricing, sales, or distribution of products."
         },
         {
          title:"Coordinate",
          innerText:"Prepare staff work schedules and assign specific duties."
         },
        {
          title:"Analysis",
          innerText:"Direct or coordinate financial or budget activities to fund operations, maximize investments, or increase efficiency."
         },
         {
          title:"Sales",
          innerText:"Set prices or credit terms for goods or services, based on forecasts of customer demand."
         },
         {
          title:"Marketing",
          innerText:"Develop or implement product-marketing strategies, including advertising campaigns or sales promotions."
         },
         {
          title:"Communicate",
          innerText:"Recommend locations for new facilities, or oversee the remodeling or renovating of current facilities."
         },
  ]
},
{
  jobtitle:'Office Manager',
  salary:"$49,887",
  description:"First-Line Supervisors of Office and Administrative Support Workers directly supervise and coordinate the activities of clerical and administrative support workers.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'2rSN2E1_aPQ',srcOrHref:'https://youtu.be/2rSN2E1_aPQ'},
  education:"",
  id_gamification:"busi-3",
  communicationbuttons:[
         {
          title:"Customer Service",
          innerText:"Resolve customer complaints or answer customers' questions regarding policies and procedures."
         },
         {
          title:"Leadership",
          innerText:"Provide employees with guidance in handling difficult or complex problems or in resolving escalated complaints or disputes."
         },
        {
          title:"Communicate",
          innerText:"Discuss job performance problems with employees to identify causes and issues and to work on resolving problems."
         },
         {
          title:"Inventory",
          innerText:"Monitor inventory levels and requisition or purchase supplies as needed."
         },
         {
          title:"Training",
          innerText:"Train or instruct employees in job duties or company policies or arrange for training to be provided."
         },
         {
          title:"Math",
          innerText:"Compute figures such as balances, totals, or commissions."
         },
  ]
},
{
  jobtitle:'Accountants & Auditors',
  salary:"$63,562",
  description:"Accountants and auditors prepare and examine financial records. Most accountants and auditors work full time. Overtime hours are typical at certain periods of the year, such as for quarterly audits or during tax season.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'u9pgua9hT6k',srcOrHref:'https://youtu.be/u9pgua9hT6k'},
  education:"",
  id_gamification:"busi-4",
  communicationbuttons:[
         {
          title:"Reporting",
          innerText:"Prepare detailed reports on audit findings."
         },
         {
          title:"Communicate",
          innerText:"Report to management about asset utilization and audit results, and recommend changes in operations and financial activities."
         },
        {
          title:"Supervise",
          innerText:"Supervise auditing of establishments, and determine scope of investigation required."
         },
         {
          title:"Consulting",
          innerText:"Confer with company officials about financial and regulatory matters."
         },
         {
          title:"Quality Assurance",
          innerText:"Examine and evaluate financial and information systems, recommending controls to ensure system reliability and data integrity."
         },
         {
          title:"Analysis",
          innerText:"Examine records and interview workers to ensure recording of transactions and compliance with laws and regulations."
         },
  ]
},
{
  jobtitle:'Shipping Clerk',
  salary:"$30,053",
  description:"Verify and maintain records on incoming and outgoing shipments involving inventory. Duties include verifying and recording incoming merchandise or material and arranging for the transportation of products. May prepare items for shipment.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'EvqriUvNeiY',srcOrHref:'https://youtu.be/EvqriUvNeiY'},
  education:"",
  id_gamification:"busi-5",
  communicationbuttons:[
         {
          title:"Detailed",
          innerText:"Examine shipment contents and compare with records, such as manifests, invoices, or orders, to verify accuracy."
         },
         {
          title:"Organized",
          innerText:"Requisition and store shipping materials and supplies to maintain inventory of stock."
         },
        {
          title:"Data Entry",
          innerText:"Prepare documents, such as work orders, bills of lading, or shipping orders, to route materials."
         },
         {
          title:"Tool Usage",
          innerText:"Pack, seal, label, or affix postage to prepare materials for shipping, using hand tools, power tools, or postage meter."
         },
         {
          title:"Physical Labor",
          innerText:"Deliver or route materials to departments using handtruck, conveyor, or sorting bins."
         },
         {
          title:"Analysis",
          innerText:"Determine shipping methods, routes, or rates for materials to be shipped."
         },
  ]
},
      ],
  },
  communications_media_and_marketing:{
    mediaInfo:{
      videoType:'',link:"marketing-careers",videoId:'', imageOrVideo:'img',srcOrHref:'marketing-careers'
    },
    jobs:[ 
      {
        jobtitle:'Department & Store Manager',
        salary:"$36,065",
        description:"First-Line Supervisors of Retail Sales Workers directly supervise and coordinate activities of retail sales workers in an establishment or department.", 
        jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'7yuWpixj8jo',srcOrHref:'https://youtu.be/7yuWpixj8jo'},
        education:"",
        id_gamification:"comm-1",
        communicationbuttons:[
               {
                title:"Customer Service",
                innerText:"Provide customer service by greeting and assisting customers and responding to customer inquiries and complaints."
               },
               {
                title:"Detailed",
                innerText:"Examine merchandise to ensure that it is correctly priced and displayed and that it functions as advertised."
               },
              {
                title:"Data Tracking",
                innerText:"Monitor sales activities to ensure that customers receive satisfactory service and quality goods."
               },
               {
                title:"Training",
                innerText:"Instruct staff on how to handle difficult and complicated sales."
               },
               {
                title:"Organized",
                innerText:"Keep records of purchases, sales, and requisitions."
               },
               {
                title:"Physical Labor",
                innerText:"Perform work activities of subordinates, such as cleaning and organizing shelves and displays and selling merchandise."
               },
        ]
},
{
  jobtitle:'Sales Representative',
  salary:"$52,917",
  description:"Sales Representatives, Wholesale and Manufacturing, Except Technical and Scientific Products sell goods for wholesalers or manufacturers to businesses or groups of individuals. ", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'7yuWpixj8jo',srcOrHref:'https://youtu.be/7yuWpixj8jo'},
  education:"",
  id_gamification:"comm-2",
  communicationbuttons:[
         {
          title:"Customer Service",
          innerText:"Answer customers' questions about products, prices, availability, product uses, and credit terms."
         },
         {
          title:"Sales",
          innerText:"Recommend products to customers, based on customers' needs and interests."
         },
        {
          title:"Paperwork",
          innerText:"Estimate or quote prices, credit or contract terms, warranties, and delivery dates."
         },
         {
          title:"Networking",
          innerText:"Consult with clients after sales or contract signings to resolve problems and to provide ongoing support."
         },
         {
          title:"Reporting",
          innerText:"Perform administrative duties, such as preparing sales budgets and reports, keeping sales records, and filing expense account reports."
         },
         {
          title:"Forecasting",
          innerText:"Monitor market conditions, product innovations, and competitors' products, prices, and sales."
         },
  ]
},
{
  jobtitle:'Market Research Analyst',
  salary:"$55,570",
  description:"Market research analysts study market conditions to examine potential sales of a product or service. Because most industries use market research, these analysts are employed throughout the economy. Most analysts work full time during regular business hours. Some work under pressure of deadlines and tight schedules.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'7yuWpixj8jo',srcOrHref:'https://youtu.be/7yuWpixj8jo'},
  education:"",
  id_gamification:"comm-3",
  communicationbuttons:[
         {
          title:"Technical Writing",
          innerText:"Prepare reports of findings, illustrating data graphically and translating complex findings into written text."
         },
         {
          title:"Focus Testing",
          innerText:"Direct trained survey interviewers."
         },
        {
          title:"Data Gathering",
          innerText:"Devise and evaluate methods and procedures for collecting data, such as surveys, opinion polls, or questionnaires, or arrange to obtain existing data."
         },
         {
          title:"Strategy",
          innerText:"Develop and implement procedures for identifying advertising needs."
         },
         {
          title:"Consulting",
          innerText:"Seek and provide information to help companies determine their position in the marketplace."
         },
         {
          title:"Forecasting",
          innerText:"Forecast and track marketing and sales trends, analyzing collected data."
         },
  ]
},
{
  jobtitle:'Technical Writer',
  salary:"$70,767",
  description:"Technical writers prepare instruction manuals, how-to guides, journal articles, and other supporting documents to communicate complex and technical information more easily. Most technical writers work full time. Although technical writers work in a variety of industries, they are concentrated in the computer and management, scientific, and technical industries.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'D6B1IyVLc7E',srcOrHref:'https://youtu.be/D6B1IyVLc7E'},
  education:"",
  id_gamification:"comm-4",
  communicationbuttons:[
         {
          title:"Technical Writing",
          innerText:"Organize material and complete writing assignment according to set standards regarding order, clarity, conciseness, style, and terminology."
         },
         {
          title:"Organized",
          innerText:"Maintain records and files of work and revisions."
         },
        {
          title:"Editing",
          innerText:"Edit, standardize, or make changes to material prepared by other writers or establishment personnel."
         },
         {
          title:"Visual Eye",
          innerText:"Select photographs, drawings, sketches, diagrams, and charts to illustrate material."
         },
         {
          title:"Detailed",
          innerText:"Develop or maintain online help documentation."
         },
         {
          title:"Print Layout",
          innerText:"Assist in laying out material for publication."
         },
  ]
},
{
  jobtitle:'Graphic Designer',
  salary:"$44,461",
  description:"Design or create graphics to meet specific commercial or promotional needs, such as packaging, displays, or logos. May use a variety of mediums to achieve artistic or decorative effects.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'dt6td67yF9E',srcOrHref:'https://youtu.be/dt6td67yF9E'},
  education:"",
  id_gamification:"comm-5",
  communicationbuttons:[
         {
          title:"Critical Thinking",
          innerText:"Determine size and arrangement of illustrative material and copy, and select style and size of type."
         },
         {
          title:"Communicate",
          innerText:"Confer with clients to discuss and determine layout design."
         },
        {
          title:"Design",
          innerText:"Create designs, concepts, and sample layouts, based on knowledge of layout principles and esthetic design concepts."
         },
         {
          title:"Quality Control",
          innerText:"Review final layouts and suggest improvements as needed."
         },
         {
          title:"Active Learning",
          innerText:"Research new software or design concepts."
         },
         {
          title:"Detail Oriented",
          innerText:"Mark up, paste, and assemble final layouts to prepare layouts for printer."
         },
  ]
},
     ],
},
    cybersecurity_and_information_technology:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/cbffW0MeWoY",videoId:'cbffW0MeWoY',imageOrVideo:'video',srcOrHref:'https://youtu.be/cbffW0MeWoY'
      },
      jobs:[ 
        {
          jobtitle:'Software Developer',
          salary:"$107,040",
          description:"Software Developers, Applications and Systems Software research, design, and develop computer and network software or specialized utility programs. Many software developers, quality assurance analysts, and testers work in computer systems design and related services, in manufacturing, or for software publishers. They often work in offices and on teams with other software developers or quality assurance analysts and testers.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'_WQ_VV4pXPc',srcOrHref:'https://youtu.be/_WQ_VV4pXPc'},
          education:"",
          id_gamification:"cybe-1",
          communicationbuttons:[
                 {
                  title:"Communicate",
                  innerText:"Consult with customers or other departments on project status, proposals, or technical issues, such as software system design or maintenance."
                 },
                 {
                  title:"Systems Analysis",
                  innerText:"Analyze information to determine, recommend, and plan installation of a new system or modification of an existing system."
                 },
                {
                  title:"Supervise",
                  innerText:"Supervise the work of programmers, technologists and technicians and other engineering and scientific personnel."
                 },
                 {
                  title:"Instructing",
                  innerText:"Train users to use new or modified equipment."
                 },
                 {
                  title:"Monitoring",
                  innerText:"Monitor functioning of equipment to ensure system operates in conformance with specifications."
                 },
                 {
                  title:"Mathematics",
                  innerText:"Design, develop and modify software systems, using scientific analysis and mathematical models to predict and measure outcomes and consequences of design."
                 },
          ]
      },
      {
        jobtitle:'Computer Systems Analysts',
        salary:"$92,004",
        description:"Analyze science, engineering, business, and other data processing problems to develop and implement solutions to complex applications problems, system administration issues, or network concerns. Perform systems management and integration functions, improve existing computer systems, and review computer system capabilities, workflow, and schedule limitations. May analyze or recommend commercially available software.", 
        jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'T0JdNufvn-o',srcOrHref:'https://youtu.be/T0JdNufvn-o'},
        education:"",
        id_gamification:"cybe-2",
        communicationbuttons:[
               {
                title:"Detailed",
                innerText:"Test, maintain, and monitor computer programs and systems, including coordinating the installation of computer programs and systems."
               },
               {
                title:"Teaching",
                innerText:"Train staff and users to work with computer systems and programs."
               },
              {
                title:"Analysis",
                innerText:"Assess the usefulness of pre-developed application packages and adapt them to a user environment."
               },
               {
                title:"Planning",
                innerText:"Define the goals of the system and devise flow charts and diagrams describing logical operational steps of programs."
               },
               {
                title:"Support",
                innerText:"Provide staff and users with assistance solving computer-related problems, such as malfunctions and program problems."
               },
               {
                title:"Programming",
                innerText:"Use object-oriented programming languages, as well as client and server applications development processes and multimedia and Internet technology."
               },
        ]
    },
    {
      jobtitle:'Information Security Analyst',
      salary:"$87,990",
      description:"Plan, implement, upgrade, or monitor security measures for the protection of computer networks and information. Assess system vulnerabilities for security risks and propose and implement risk mitigation strategies. May ensure appropriate security controls are in place that will safeguard digital files and vital electronic infrastructure. May respond to computer security breaches and viruses.", 
      jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'cVZktAEtWE4',srcOrHref:'https://youtu.be/cVZktAEtWE4'},
      education:"",
      id_gamification:"cybe-3",
      communicationbuttons:[
             {
              title:"Organize",
              innerText:"Document computer security and emergency measures policies, procedures, and tests."
             },
             {
              title:"Analysis",
              innerText:"Monitor current reports of computer viruses to determine when to update virus protection systems."
             },
            {
              title:"Communicate",
              innerText:"Coordinate implementation of computer system plan with establishment personnel and outside vendors."
             },
             {
              title:"Quality Control",
              innerText:"Perform risk assessments and execute tests of data processing system to ensure functioning of data processing activities and security measures."
             },
             {
              title:"Troubleshoot",
              innerText:"Modify computer security files to incorporate new software, correct errors, or change individual access status."
             },
             {
              title:"Instructing",
              innerText:"Confer with users to discuss issues such as computer data access needs, security violations, and programming changes."
             },
      ]
  },
  {
    jobtitle:'Network Administrator',
    salary:"$75,212",
    description:"Install, configure, and maintain an organization's local area network (LAN), wide area network (WAN), data communications network, operating systems, and physical and virtual servers. Perform system monitoring and verify the integrity and availability of hardware, network, and server resources and systems. Review system and application logs and verify completion of scheduled jobs, including system backups. Analyze network and server resource consumption and control user access. Install and upgrade software and maintain software licenses. May assist in network modeling, analysis, planning, and coordination between network and data communications hardware and software.", 
    jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'H3kIfqDCY14',srcOrHref:'https://youtu.be/H3kIfqDCY14'},
    education:"",
    id_gamification:"cybe-4",
    communicationbuttons:[
           {
            title:"Planning",
            innerText:"Perform data backups and disaster recovery operations."
           },
           {
            title:"Computer Usage",
            innerText:"Configure, monitor, and maintain email applications or virus protection software."
           },
          {
            title:"Safety",
            innerText:"Plan, coordinate, and implement network security measures to protect data, software, and hardware."
           },
           {
            title:"Hands-On",
            innerText:"Design, configure, and test computer hardware, networking software and operating system software."
           },
           {
            title:"Teaching",
            innerText:"Train people in computer system use."
           },
           {
            title:"Data Entry",
            innerText:"Maintain logs related to network functions, as well as maintenance and repair records."
           },
    ]
},
{
  jobtitle:'Database Administrator',
  salary:"$79,224",
  description:"Database administrators and architects create or organize systems to store and secure data. Many database administrators and architects work in firms that provide computer design services or in industries that have large databases, such educational institutions and insurance companies. Most database administrators and architects work full time.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'GKAnWFOyk8E',srcOrHref:'https://youtu.be/GKAnWFOyk8E'},
  education:"",
  id_gamification:"cybe-5",
  communicationbuttons:[
         {
          title:"Troubleshoot",
          innerText:"Test programs or databases, correct errors, and make necessary modifications."
         },
         {
          title:"Safety",
          innerText:"Develop standards and guidelines to guide the use and acquisition of software and to protect vulnerable information."
         },
        {
          title:"Instructing",
          innerText:"Train users and answer questions."
         },
         {
          title:"Coding",
          innerText:"Write and code logical and physical database descriptions and specify identifiers of database to management system or direct others in coding descriptions."
         },
         {
          title:"Critical Analysis",
          innerText:"Develop data model describing data elements and how they are used, following procedures and using pen, template or computer software."
         },
         {
          title:"Quality Control",
          innerText:"Test changes to database applications or systems."
         },
  ]
},
{
  jobtitle:'Web Developer',
  salary:"$68,378",
  description:"Web developers create and maintain websites. Digital designers develop, create, and test website or interface layout, functions, and navigation for usability. Some web developers and digital designers work in the computer systems design and related services industry. Others are self-employed. Still others work in industries including publishing, management consulting, and advertising.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'FfxTkIsaTYk',srcOrHref:'https://youtu.be/FfxTkIsaTYk'},
  education:"",
  id_gamification:"cybe-6",
  communicationbuttons:[
         {
          title:"Programming",
          innerText:"Design, build, or maintain Web sites, using authoring or scripting languages, content creation tools, management tools, and digital media."
         },
         {
          title:"Analysis",
          innerText:"Analyze user needs to determine technical requirements."
         },
        {
          title:"Communicate",
          innerText:"Respond to user email inquiries, or set up automated systems to send responses."
         },
         {
          title:"Quality Control",
          innerText:"Perform Web site tests according to planned schedules, or after any Web site or product revision."
         },
         {
          title:"Teamwork",
          innerText:"Confer with management or development teams to prioritize needs, resolve conflicts, develop content criteria, or choose solutions."
         },
         {
          title:"Prototyping",
          innerText:"Create Web models or prototypes that include physical, interface, logical, or data models."
         },
  ]
},
{
  jobtitle:'Computer User Support Specialist',
  salary:"$45,006",
  description:"Provide technical assistance to computer users. Answer questions or resolve computer problems for clients in person, via telephone, or electronically. May provide assistance concerning the use of computer hardware and software, including printing, installation, word processing, electronic mail, and operating systems.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'xe6GS8kSN1g',srcOrHref:'https://youtu.be/xe6GS8kSN1g'},
  education:"",
  id_gamification:"cybe-7",
  communicationbuttons:[
         {
          title:"Customer Support",
          innerText:"Answer user inquiries regarding computer software or hardware operation to resolve problems."
         },
         {
          title:"Troubleshoot",
          innerText:"Read technical manuals, confer with users, or conduct computer diagnostics to investigate and resolve problems or to provide technical assistance and support."
         },
        {
          title:"Instructing",
          innerText:"Develop training materials and procedures, or train users in the proper use of hardware or software."
         },
         {
          title:"Organization",
          innerText:"Maintain records of daily data communication transactions, problems and remedial actions taken, or installation activities."
         },
         {
          title:"Communicate",
          innerText:"Confer with staff, users, and management to establish requirements for new systems or modifications."
         },
         {
          title:"Systems Analysis",
          innerText:"Prepare evaluations of software or hardware, and recommend improvements or upgrades."
         },
  ]
},
{
  jobtitle:'Computer Network Architect',
  salary:"$110,659",
  description:"Provide technical assistance to computer users. Answer questions or resolve computer problems for clients in person, via telephone, or electronically. May provide assistance concerning the use of computer hardware and software, including printing, installation, word processing, electronic mail, and operating systems.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'ilhLnDRtwZo',srcOrHref:'https://youtu.be/ilhLnDRtwZo'},
  education:"",
  id_gamification:"cybe-8",
  communicationbuttons:[
         {
          title:"Consult",
          innerText:"Develop or recommend network security measures, such as firewalls, network security audits, or automated security probes."
         },
         {
          title:"Systems Design",
          innerText:"Develop conceptual, logical, or physical network designs."
         },
        {
          title:"Analysis",
          innerText:"Determine specific network hardware or software requirements, such as platforms, interfaces, bandwidths, or routine schemas."
         },
         {
          title:"Customer Support",
          innerText:"Communicate with system users to ensure accounts are set up properly or to diagnose and solve operational problems."
         },
         {
          title:"Systems Analysis",
          innerText:"Evaluate network designs to determine whether customer requirements are met efficiently and effectively."
         },
         {
          title:"Technical Writing",
          innerText:"Prepare detailed network specifications, including diagrams, charts, equipment configurations, or recommended technologies."
         },
  ]
},
{
  jobtitle:'Network Technician',
  salary:"$51,540",
  description:"Analyze, test, troubleshoot, and evaluate existing network systems, such as local area networks (LAN), wide area networks (WAN), cloud networks, servers, and other data communications networks. Perform network maintenance to ensure networks operate correctly with minimal interruption.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'xe6GS8kSN1g',srcOrHref:'https://youtu.be/xe6GS8kSN1g'},
  education:"",
  id_gamification:"cybe-9",
  communicationbuttons:[
         {
          title:"Physical Labor",
          innerText:"Configure wide area network (WAN) or local area network (LAN) routers or related equipment."
         },
         {
          title:"Troubleshoot",
          innerText:"Identify the causes of networking problems, using diagnostic testing software and equipment."
         },
        {
          title:"Critical Analysis",
          innerText:"Analyze and report computer network security breaches or attempted breaches."
         },
         {
          title:"Customer Support",
          innerText:"Provide telephone support related to networking or connectivity issues."
         },
         {
          title:"Teaching",
          innerText:"Train users in procedures related to network applications software or related systems."
         },
         {
          title:"Research",
          innerText:"Research hardware or software products to meet technical networking or security needs."
         },
  ]
},
{
  jobtitle:'Computer Hardware Engineer',
  salary:"$115,924",
  description:"Computer hardware engineers research, design, develop, and test computer systems and components. Computer hardware engineers usually work in research laboratories that build and test various types of computer models. Most work in computer systems design services and in manufacturing.", 
  jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'l09D6OycD4o',srcOrHref:'https://youtu.be/l09D6OycD4o'},
  education:"",
  id_gamification:"cybe-10",
  communicationbuttons:[
         {
          title:"Research",
          innerText:"Update knowledge and skills to keep up with rapid advancements in computer technology."
         },
         {
          title:"Rapid Prototypes",
          innerText:"Build, test, and modify product prototypes, using working models or theoretical models constructed with computer simulation."
         },
        {
          title:"Technical Writing",
          innerText:"Write detailed functional specifications that document the hardware development process and support hardware introduction."
         },
         {
          title:"Critical Analysis",
          innerText:"Test and verify hardware and support peripherals to ensure that they meet specifications and requirements, by recording and analyzing test data."
         },
         {
          title:"Supervise",
          innerText:"Direct technicians, engineering designers or other technical support personnel as needed."
         },
         {
          title:"Detailed",
          innerText:"Select hardware and material, assuring compliance with specifications and product requirements."
         },
  ]
},

       ],
  },
   
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var EAW_Quiz_Object={ 
    agriculture_and_natural_resources:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/bEIYIGx61Pk",videoId:'bEIYIGx61Pk',imageOrVideo:'video',srcOrHref:'https://youtu.be/bEIYIGx61Pk'
      },
      jobs:[
        {
          jobtitle:'Agronomist',
          salary:"$38,000 - $101,310",
          description:"Conduct research in breeding, physiology, production, yield, and management of crops and agricultural plants or trees, shrubs, and nursery stock, their growth in soils, and control of pests; or study the chemical, physical, biological, and mineralogical composition of soils as they relate to plant or crop growth. May classify and map soils and investigate effects of alternative practices on soil and crop productivity.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'cWbaZEIe7GQ',srcOrHref:'https://youtu.be/cWbaZEIe7GQ'},
          id_gamification:"aanr-1",
          communicationbuttons:
              [ 
                {
                  title:"Communicate",
                  innerText:"Communicate research or project results to other professionals or the public or teach related courses, seminars, or workshops."
                },
                {
                  title:"Critical Thinking",
                  innerText:"Investigate soil problems or poor water quality to determine sources and effects."
                },
                {
                  title:"Experiment",
                  innerText:"Conduct experiments investigating how soil forms, changes, or interacts with land-based ecosystems or living organisms."
                },
                {
                  title:"Teamwork",
                  innerText:"Consult with engineers or other technical personnel working on construction projects about the effects of soil problems and possible solutions to these problems."
                },
                {
                  title:"Analyze",
                  innerText:"Perform chemical analyses of the microorganism content of soils to determine microbial reactions or chemical mineralogical relationships to plant growth."
                },
                {
                  title:"Active Learning",
                  innerText:"Study ways to improve agricultural sustainability, such as the use of new methods of composting."
                },]
        },
        {
          jobtitle:'Zoologists and Wildlife Biologists',
          salary:"$39,800 - $84,500",
          description:"Study the origins, behavior, diseases, genetics, and life processes of animals and wildlife. May specialize in wildlife research and management. May collect and analyze biological data to determine the environmental effects of present and potential use of land and water habitats.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'cu6BuYyI6SQ',srcOrHref:'https://youtu.be/cu6BuYyI6SQ'},
          id_gamification:"aanr-2",
          communicationbuttons:
              [ 
                {
                  title:"Organization",
                  innerText:"Inventory or estimate plant and wildlife populations."
                },
                {
                  title:"Safety",
                  innerText:"Check for, and ensure compliance with, environmental laws, and notify law enforcement when violations are identified."
                },
                {
                  title:"Critical Analysis",
                  innerText:"Analyze characteristics of animals to identify and classify them."
                },
                {
                  title:"Management",
                  innerText:"Coordinate preventive programs to control the outbreak of wildlife diseases."
                },
                {
                  title:"Experiment",
                  innerText:"Organize and conduct experimental studies with live animals in controlled or natural surroundings."
                },
                {
                  title:"Observation",
                  innerText:"Study animals in their natural habitats, assessing effects of environment and industry on animals, interpreting findings and recommending alternative operating conditions for industry."
                },]
        },
        {
          jobtitle:'Forester',
          salary:"$40,210 - $92,210",
          description:"Manage public and private forested lands for economic, recreational, and conservation purposes. May inventory the type, amount, and location of standing timber, appraise the timber's worth, negotiate the purchase, and draw up contracts for procurement. May determine how to conserve wildlife habitats, creek beds, water quality, and soil stability, and how best to comply with environmental regulations. May devise plans for planting and growing new trees, monitor trees for healthy growth, and determine optimal harvesting schedules.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'KonPtcUR-ps',srcOrHref:'https://youtu.be/KonPtcUR-ps'},
          id_gamification:"aanr-3",
          communicationbuttons:
              [ 
                {
                  title:"Supervise",
                  innerText:"Supervise activities of other forestry workers."
                },
                {
                  title:"Quality Control",
                  innerText:"Perform inspections of forests or forest nurseries."
                },
                {
                  title:"Critical Analysis",
                  innerText:"Determine methods of cutting and removing timber with minimum waste and environmental damage."
                },
                {
                  title:"Decision Making",
                  innerText:"Choose and prepare sites for new trees, using controlled burning, bulldozers, or herbicides to clear weeds, brush, and logging debris."
                },
                {
                  title:"Persuasion",
                  innerText:"Negotiate terms and conditions of agreements and contracts for forest harvesting, forest management and leasing of forest lands."
                },
                {
                  title:"Instructing",
                  innerText:"Conduct public educational programs on forest care and conservation."
                },]
        },
        {
          jobtitle:'Logging Equipment Operator',
          salary:"$26,020 - $50,830",
          description:"Drive logging tractor or wheeled vehicle equipped with one or more accessories, such as bulldozer blade, frontal shear, grapple, logging arch, cable winches, hoisting rack, or crane boom, to fell tree; to skid, load, unload, or stack logs; or to pull stumps or clear brush. Includes operating stand-alone logging machines, such as log chippers.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'d_dMEEleMco',srcOrHref:'https://youtu.be/d_dMEEleMco'},
          id_gamification:"aanr-4",
          communicationbuttons:
              [ 
                {
                  title:"Safety",
                  innerText:"Inspect equipment for safety prior to use, and perform necessary basic maintenance tasks."
                },
                {
                  title:"Operate Machinery",
                  innerText:"Control hydraulic tractors equipped with tree clamps and booms to lift, swing, and bunch sheared trees."
                },
                {
                  title:"Quality Control",
                  innerText:"Grade logs according to characteristics such as knot size and straightness, and according to established industry or company standards."
                },
                {
                  title:"Measuring",
                  innerText:"Calculate total board feet, cordage, or other wood measurement units, using conversion tables."
                },
                {
                  title:"Writing",
                  innerText:"Fill out required job or shift report forms."
                },
                {
                  title:"Driving",
                  innerText:"Drive tractors for the purpose of building or repairing logging and skid roads."
                },]
        },
        {
          jobtitle:'Poultry Service Technician',
          salary:"$19,940 - $60,550",
          description:"Work with agricultural scientists in plant, fiber, and animal research, or assist with animal breeding and nutrition. Set up or maintain laboratory equipment and collect samples from crops or animals. Prepare specimens or record data to assist scientists in biology or related life science experiments. Conduct tests and experiments to improve yield and quality of crops or to increase the resistance of plants and animals to disease or insects.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'FQ2WMg6rs2s',srcOrHref:'https://youtu.be/FQ2WMg6rs2s'},
          id_gamification:"aanr-5",
          communicationbuttons:
              [ 
                {
                  title:"Writing",
                  innerText:"Prepare data summaries, reports, or analyses that include results, charts, or graphs to document research findings and results."
                },
                {
                  title:"Critical Analysis",
                  innerText:"Examine animals or crop specimens to determine the presence of diseases or other problems."
                },
                {
                  title:"Supervise",
                  innerText:"Supervise or train agricultural technicians or farm laborers."
                },
                {
                  title:"Operate Machines",
                  innerText:"Operate farm machinery, including tractors, plows, mowers, combines, balers, sprayers, earthmoving equipment, or trucks."
                },
                {
                  title:"Safety",
                  innerText:"Maintain or repair agricultural facilities, equipment, or tools to ensure operational readiness, safety, and cleanliness."
                },
                {
                  title:"Hand Tools",
                  innerText:"Perform crop production duties, such as tilling, hoeing, pruning, weeding, or harvesting crops."
                },]
        },
        {
          jobtitle:'Environmental Engineers',
          salary:"$49,730 - $122,060",
          description:"Research, design, plan, or perform engineering duties in the prevention, control, and remediation of environmental hazards using various engineering disciplines. Work may include waste treatment, site remediation, or pollution control technology.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'JSxcetMCZZ0',srcOrHref:'https://youtu.be/JSxcetMCZZ0'},
          id_gamification:"aanr-6",
          communicationbuttons:
              [ 
                {
                  title:"Systems Evaluation",
                  innerText:"Inspect industrial or municipal facilities or programs to evaluate operational effectiveness or ensure compliance with environmental regulations."
                },
                {
                  title:"Design",
                  innerText:"Design, or supervise the design of, systems, processes, or equipment for control, management, or remediation of water, air, or soil quality."
                },
                {
                  title:"Instructing",
                  innerText:"Develop or present environmental compliance training or orientation sessions."
                },
                {
                  title:"Monitoring",
                  innerText:"Monitor progress of environmental improvement programs."
                },
                {
                  title:"Critical Thinking",
                  innerText:"Develop site-specific health and safety protocols, such as spill contingency plans or methods for loading or transporting waste."
                },
                {
                  title:"Teamwork",
                  innerText:"Collaborate with environmental scientists, planners, hazardous waste technicians, engineers, experts in law or business, or other specialists to address environmental problems."
                },]
        },
      ]   
    },
    automotive_and_manufacturing:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/kJ0MTvwCw_4",videoId:'kJ0MTvwCw_4',imageOrVideo:'video',srcOrHref:'https://youtu.be/kJ0MTvwCw_4'
      },
      jobs:[
        {
          jobtitle:'Cabinetmakers',
          salary:"$20,450 - $39,330",
          description:"Cut, shape, and assemble wooden articles or set up and operate a variety of woodworking machines, such as power saws, jointers, and mortisers to surface, cut, or shape lumber or to fabricate parts for wood products.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'Avn11IVTieY',srcOrHref:'https://youtu.be/Avn11IVTieY'},
          id_gamification:"aam-1",
          communicationbuttons:
              [ 
                {
                  title:"Quality Control",
                  innerText:"Verify dimensions or check the quality or fit of pieces to ensure adherence to specifications."
                },
                {
                  title:"Accuracy",
                  innerText:"Measure and mark dimensions of parts on paper or lumber stock prior to cutting, following blueprints, to ensure a tight fit and quality product."
                },
                {
                  title:"Operate Machinery",
                  innerText:"Set up or operate machines, including power saws, jointers, mortisers, tenoners, molders, or shapers, to cut, mold, or shape woodstock or wood substitutes."
                },
                {
                  title:"Hand Tools",
                  innerText:"Attach parts or subassemblies together to form completed units, using glue, dowels, nails, screws, or clamps."
                },
                {
                  title:"Drafting",
                  innerText:"Draw up detailed specifications and discuss projects with customers."
                },
                {
                  title:"Computers",
                  innerText:"Design furniture, using computer-aided drawing programs."
                },]
        },
        {
          jobtitle:'CNC Operator',
          salary:"$28,440 - $53,050",
          description:"Operate computer-controlled tools, machines, or robots to machine or process parts, tools, or other work pieces made of metal, plastic, wood, stone, or other materials. May also set up and maintain equipment.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'176-HGCTLqE',srcOrHref:'https://youtu.be/176-HGCTLqE'},
          id_gamification:"aam-2",
          communicationbuttons:
              [ 
                {
                  title:"Quality Control",
                  innerText:"Measure dimensions of finished workpieces to ensure conformance to specifications, using precision measuring instruments, templates, and fixtures."
                },
                {
                  title:"Computers",
                  innerText:"Transfer commands from servers to computer numerical control (CNC) modules, using computer network links."
                },
                {
                  title:"Maintenance",
                  innerText:"Remove and replace dull cutting tools."
                },
                {
                  title:"Problem Solving",
                  innerText:"Modify cutting programs to account for problems encountered during operation, and save modified programs."
                },
                {
                  title:"Cleanliness",
                  innerText:"Clean machines, tooling, or parts, using solvents or solutions and rags."
                },
                {
                  title:"Communicate",
                  innerText:"Confer with supervisors or programmers to resolve machine malfunctions or production errors or to obtain approval to continue production."
                },]
        },
        {
          jobtitle:'Industrial Machinery Mechanic',
          salary:"$32,630 - $74,850",
          description:"Repair, install, adjust, or maintain industrial production and processing machinery or refinery and pipeline distribution systems. May also install, dismantle, or move machinery and heavy equipment according to plans.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'ROJQXBuEnEY',srcOrHref:'https://youtu.be/ROJQXBuEnEY'},
          id_gamification:"aam-3",
          communicationbuttons:
              [ 
                {
                  title:"Maintenance",
                  innerText:"Repair or maintain the operating condition of industrial production or processing machinery or equipment."
                },
                {
                  title:"Quality Control",
                  innerText:"Examine parts for defects, such as breakage or excessive wear."
                },
                {
                  title:"Analysis",
                  innerText:"Study blueprints or manufacturers' manuals to determine correct installation or operation of machinery."
                },
                {
                  title:"Instructing",
                  innerText:"Demonstrate equipment functions and features to machine operators."
                },
                {
                  title:"Welding",
                  innerText:"Cut and weld metal to repair broken metal parts, fabricate new parts, or assemble new equipment."
                },
                {
                  title:"Inventory",
                  innerText:"Record parts or materials used and order or requisition new parts or materials, as necessary."
                },]
        },
        {
          jobtitle:'Precision Machinist',
          salary:"$26,840 - $63,600",
          description:"", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'OWNXI09WI14',srcOrHref:'https://youtu.be/OWNXI09WI14'},
          id_gamification:"aam-4",
          communicationbuttons:
              [ 
                {
                  title:"Accuracy",
                  innerText:"Calculate dimensions or tolerances, using instruments, such as micrometers or vernier calipers."
                },
                {
                  title:"Monitor",
                  innerText:"Monitor the feed and speed of machines during the machining process."
                },
                {
                  title:"Analysis",
                  innerText:"Study sample parts, blueprints, drawings, or engineering information to determine methods or sequences of operations needed to fabricate products."
                },
                {
                  title:"Organize",
                  innerText:"Separate scrap waste and related materials for reuse, recycling, or disposal."
                },
                {
                  title:"Design",
                  innerText:"Design fixtures, tooling, or experimental parts to meet special engineering needs."
                },
                {
                  title:"Quality Control",
                  innerText:"Maintain machine tools in proper operational condition."
                },]
        },
        {
          jobtitle:'Quality Control Analysts',
          salary:"$29,140 - $73,010",
          description:"Conduct tests to determine quality of raw materials, bulk intermediate and finished products. May conduct stability sample tests.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'RCTxteXoiAA',srcOrHref:'https://youtu.be/RCTxteXoiAA'},
          id_gamification:"aam-5",
          communicationbuttons:
              [ 
                {
                  title:"Quality Control",
                  innerText:"Perform visual inspections of finished products."
                },
                {
                  title:"Writing",
                  innerText:"Write technical reports or documentation, such as deviation reports, testing protocols, and trend analyses."
                },
                {
                  title:"Analysis",
                  innerText:"Compile laboratory test data and perform appropriate analyses."
                },
                {
                  title:"Troubleshoot",
                  innerText:"Identify and troubleshoot equipment problems."
                },
                {
                  title:"Problem Solving",
                  innerText:"Identify quality problems and recommend solutions."
                },
                {
                  title:"Instructing",
                  innerText:"Train other analysts to perform laboratory procedures and assays."
                },]
        },
        {
          jobtitle:'Welders',
          salary:"$27,290 - $57,660",
          description:"Use hand-welding, flame-cutting, hand-soldering, or brazing equipment to weld or join metal components or to fill holes, indentations, or seams of fabricated metal products.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'cETNESaLhcA',srcOrHref:'https://youtu.be/cETNESaLhcA'},
          id_gamification:"aam-6",
          communicationbuttons:
              [ 
                {
                  title:"Welding",
                  innerText:"Weld components in flat, vertical, or overhead positions."
                },
                {
                  title:"Safety",
                  innerText:"Operate safety equipment and use safe work habits."
                },
                {
                  title:"Quality Control",
                  innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
                },
                {
                  title:"Hand Tools",
                  innerText:"Position and secure workpieces, using hoists, cranes, wire, and banding machines or hand tools."
                },
                {
                  title:"Analysis",
                  innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
                },
                {
                  title:"Design",
                  innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
                },]
        },
      ]
         
    },
    communications_media_and_marketing:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/ns5gCy0wxhA",videoId:'ns5gCy0wxhA',imageOrVideo:'video',srcOrHref:'https://youtu.be/ns5gCy0wxhA'
      },
      jobs:[
        {
          jobtitle:'Advertising Director',
          salary:"$39,160 - $163,140",
          description:"Plan, direct, or coordinate advertising policies and programs or produce collateral materials, such as posters, contests, coupons, or giveaways, to create extra interest in the purchase of a product or service for a department, an entire organization, or on an account basis.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'eHpCwZMQjxk',srcOrHref:'https://youtu.be/eHpCwZMQjxk'},
          id_gamification:"cmm-1",
          communicationbuttons:
              [ 
                {
                  title:"Quality Control",
                  innerText:"Inspect layouts and advertising copy and edit scripts, audio and video tapes, and other promotional material for adherence to specifications."
                },
                {
                  title:"Teamwork",
                  innerText:"Confer with department heads or staff to discuss topics such as contracts, selection of advertising media, or product to be advertised."
                },
                {
                  title:"Task Management",
                  innerText:"Coordinate activities of departments, such as sales, graphic arts, media, finance, and research."
                },
                {
                  title:"Budgeting",
                  innerText:"Prepare budgets and submit estimates for program costs as part of campaign plan development."
                },
                {
                  title:"Analysis",
                  innerText:"Analyze the effectiveness of marketing tactics or channels."
                },
                {
                  title:"Public Relations",
                  innerText:"Represent company at trade association meetings to promote products."
                },]
        },
        {
          jobtitle:'Graphic Designer',
          salary:"$26,650 - $74,410",
          description:"Design or create graphics to meet specific commercial or promotional needs, such as packaging, displays, or logos. May use a variety of mediums to achieve artistic or decorative effects.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'dt6td67yF9E',srcOrHref:'https://youtu.be/dt6td67yF9E'},
          id_gamification:"cmm-2",
          communicationbuttons:
              [ 
                {
                  title:"Critical Thinking",
                  innerText:"Determine size and arrangement of illustrative material and copy, and select style and size of type."
                },
                {
                  title:"Communicate",
                  innerText:"Confer with clients to discuss and determine layout design."
                },
                {
                  title:"Design",
                  innerText:"Create designs, concepts, and sample layouts, based on knowledge of layout principles and esthetic design concepts."
                },
                {
                  title:"Quality Control",
                  innerText:"Review final layouts and suggest improvements as needed."
                },
                {
                  title:"Active Learning",
                  innerText:"Research new software or design concepts."
                },
                {
                  title:"Detail Oriented",
                  innerText:"Mark up, paste, and assemble final layouts to prepare layouts for printer."
                },]
        },
        {
          jobtitle:'Market Research Analyst',
          salary:"$31,890 - $99,200",
          description:"Research conditions in local, regional, national, or online markets. Gather information to determine potential sales of a product or service, or plan a marketing or advertising campaign. May gather information on competitors, prices, sales, and methods of marketing and distribution. May employ search marketing tactics, analyze web metrics, and develop recommendations to increase search engine ranking and visibility to target markets.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'ijEovAUjj-8',srcOrHref:'https://youtu.be/ijEovAUjj-8'},
          id_gamification:"cmm-3",
          communicationbuttons:
              [ 
                {
                  title:"Measure",
                  innerText:"Measure and assess customer and employee satisfaction."
                },
                {
                  title:"Data Gathering",
                  innerText:"Gather data on competitors and analyze their prices, sales, and method of marketing and distribution."
                },
                {
                  title:"Forecasting",
                  innerText:"Forecast and track marketing and sales trends, analyzing collected data."
                },
                {
                  title:"Problem Solving",
                  innerText:"Seek and provide information to help companies determine their position in the marketplace."
                },
                {
                  title:"Systems Analysis",
                  innerText:"Devise and evaluate methods and procedures for collecting data, such as surveys, opinion polls, or questionnaires, or arrange to obtain existing data."
                },
                {
                  title:"Critical Thinking",
                  innerText:"Prepare reports of findings, illustrating data graphically and translating complex findings into written text."
                },]
        },
        {
          jobtitle:'Public Relations Specialist',
          salary:"$32,780 - $93,440",
          description:"Promote or create an intended public image for individuals, groups, or organizations. May write or select material for release to various communications media. May specialize in using social media.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'_C_YAnEwLFI',srcOrHref:'https://youtu.be/_C_YAnEwLFI'},
          id_gamification:"cmm-4",
          communicationbuttons:
              [ 
                {
                  title:"Writing",
                  innerText:"Write press releases or other media communications to promote clients."
                },
                {
                  title:"Coaching",
                  innerText:"Coach client representatives in effective communication with the public or with employees."
                },
                {
                  title:"Content Entry",
                  innerText:"Update and maintain content posted on the Web."
                },
                {
                  title:"Public Speaking",
                  innerText:"Prepare or deliver speeches to further public relations objectives."
                },
                {
                  title:"Teamwork",
                  innerText:"Confer with production or support personnel to produce or coordinate production of advertisements or promotions."
                },
                {
                  title:"Coordination",
                  innerText:"Arrange public appearances, lectures, contests, or exhibits for clients to increase product or service awareness or to promote goodwill."
                },]
        },
        {
          jobtitle:'Marketing Manager',
          salary:"",
          description:"Plan, direct, or coordinate marketing policies and programs, such as determining the demand for products and services offered by a firm and its competitors, and identify potential customers. Develop pricing strategies with the goal of maximizing the firm's profits or share of the market while ensuring the firm's customers are satisfied. Oversee product development or monitor trends that indicate the need for new products and services.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'8r-nFPlKELc',srcOrHref:'https://youtu.be/8r-nFPlKELc'},
          id_gamification:"cmm-5",
          communicationbuttons:
              [ 
                {
                  title:"Strategize",
                  innerText:"Develop pricing strategies, balancing firm objectives and customer satisfaction."
                },
                {
                  title:"Instructing",
                  innerText:"Advise business or other groups on local, national, or international factors affecting the buying or selling of products or services."
                },
                {
                  title:"Persuasion",
                  innerText:"Negotiate contracts with vendors or distributors to manage product distribution, establishing distribution networks or developing distribution strategies."
                },
                {
                  title:"Design",
                  innerText:"Consult with product development personnel on product specifications, such as design, color, or packaging."
                },
                {
                  title:"Legal",
                  innerText:"Confer with legal staff to resolve problems, such as copyright infringement or royalty sharing with outside producers or distributors."
                },
                {
                  title:"Management",
                  innerText:"Direct the hiring, training, or performance evaluations of marketing or sales staff and oversee their daily activities."
                },]
        },
        {
          jobtitle:'Art Director',
          salary:"$39,600 - $114,220",
          description:"Formulate design concepts and presentation approaches for visual productions and media, such as print, broadcasting, video, and film. Direct workers engaged in artwork or layout design.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'0xZz2t-Ex1I',srcOrHref:'https://youtu.be/0xZz2t-Ex1I'},
          id_gamification:"cmm-6",
          communicationbuttons:
              [ 
                {
                  title:"Management",
                  innerText:"Manage own accounts and projects, working within budget and scheduling requirements."
                },
                {
                  title:"Quality Control",
                  innerText:"Review and approve art materials, copy materials, and proofs of printed copy developed by staff members."
                },
                {
                  title:"Collaborate",
                  innerText:"Work with creative directors to develop design solutions."
                },
                {
                  title:"Design",
                  innerText:"Create custom illustrations or other graphic elements."
                },
                {
                  title:"Analysis",
                  innerText:"Review illustrative material to determine if it conforms to standards and specifications."
                },
                {
                  title:"Creativity",
                  innerText:"Conceptualize and help design interfaces for multimedia games, products, and devices."
                },]
        },
      ]
         
    },
    construction:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/TlMAlcdd4ho",videoId:'TlMAlcdd4ho',imageOrVideo:'video',srcOrHref:'https://youtu.be/TlMAlcdd4ho'
      },
      jobs:[
        {
          jobtitle:'Carpenters',
          salary:"$26,830 - $60,350",
          description:"Construct, erect, install, or repair structures and fixtures made of wood and comparable materials, such as concrete forms; building frameworks, including partitions, joists, studding, and rafters; and wood stairways, window and door frames, and hardwood floors. May also install cabinets, siding, drywall, and batt or roll insulation. Includes brattice builders who build doors or brattices (ventilation walls or partitions) in underground passageways.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'i-tiC2Y-038',srcOrHref:'https://youtu.be/i-tiC2Y-038'},
          id_gamification:"construc-1",
          communicationbuttons:
              [ 
                {
                  title:"Safety",
                  innerText:"Follow established safety rules and regulations and maintain a safe and clean environment."
                },
                {
                  title:"Analysis",
                  innerText:"Study specifications in blueprints, sketches, or building plans to prepare project layout and determine dimensions and materials required."
                },
                {
                  title:"Measuring",
                  innerText:"Measure and mark cutting lines on materials, using a ruler, pencil, chalk, and marking gauge."
                },
                {
                  title:"Hand Tools",
                  innerText:"Shape or cut materials to specified measurements, using hand tools, machines, or power saws."
                },
                {
                  title:"Quality Control",
                  innerText:"Verify trueness of structure, using plumb bob and level."
                },
                {
                  title:"Organization",
                  innerText:"Maintain records, document actions, and present written progress reports."
                },]
        },
        {
          jobtitle:'Construction and Building Inspectors',
          salary:"$34,380 - $80,320",
          description:"Inspect structures using engineering skills to determine structural soundness and compliance with specifications, building codes, and other regulations. Inspections may be general in nature or may be limited to a specific area, such as electrical systems or plumbing.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'PgnWqyol0Pk',srcOrHref:'https://youtu.be/PgnWqyol0Pk'},
          id_gamification:"construc-2",
          communicationbuttons:
              [ 
                {
                  title:"Quality Control",
                  innerText:"Inspect and monitor construction sites to ensure adherence to safety standards, building codes, or specifications."
                },
                {
                  title:"Organization",
                  innerText:"Maintain daily logs and supplement inspection records with photographs."
                },
                {
                  title:"Measuring",
                  innerText:"Measure dimensions and verify level, alignment, or elevation of structures or fixtures to ensure compliance to building plans and codes."
                },
                {
                  title:"Communicate",
                  innerText:"Confer with owners, violators, or authorities to explain regulations or recommend remedial actions."
                },
                {
                  title:"Instructing",
                  innerText:"Train, direct, or supervise other construction inspectors."
                },
                {
                  title:"Observation",
                  innerText:"Monitor installation of plumbing, wiring, equipment, or appliances to ensure that installation is performed properly and is in compliance with applicable regulations."
                },]
        },
        {
          jobtitle:'Construction Managers',
          salary:"$55,730 - $155,820",
          description:"Plan, direct, or coordinate, usually through subordinate supervisory personnel, activities concerned with the construction and maintenance of structures, facilities, and systems. Participate in the conceptual development of a construction project and oversee its organization, scheduling, budgeting, and implementation. Includes managers in specialized construction fields, such as carpentry or plumbing.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'SiamJadljrw',srcOrHref:'https://youtu.be/SiamJadljrw'},
          id_gamification:"construc-3",
          communicationbuttons:
              [ 
                {
                  title:"Time Management",
                  innerText:"Plan, schedule, or coordinate construction project activities to meet deadlines."
                },
                {
                  title:"Budgeting",
                  innerText:"Prepare and submit budget estimates, progress reports, or cost tracking reports."
                },
                {
                  title:"Communicate",
                  innerText:"Interpret and explain plans and contract terms to representatives of the owner or developer, including administrative staff, workers, or clients."
                },
                {
                  title:"Analysis",
                  innerText:"Study job specifications to determine appropriate construction methods."
                },
                {
                  title:"Quality Control",
                  innerText:"Inspect or review projects to monitor compliance with building and safety codes or other regulations."
                },
                {
                  title:"Critical Thinking",
                  innerText:"Evaluate construction methods and determine cost-effectiveness of plans, using computer models."
                },]
        },
        {
          jobtitle:'Electrician',
          salary:"$31,640 - $64,740",
          description:"Install, maintain, and repair electrical wiring, equipment, and fixtures. Ensure that work is in accordance with relevant codes. May install or service street lights, intercom systems, or electrical control systems.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'mCGHJjpiOc4',srcOrHref:'https://youtu.be/mCGHJjpiOc4'},
          id_gamification:"construc-4",
          communicationbuttons:
              [ 
                {
                  title:"Critical Analysis",
                  innerText:"Plan layout and installation of electrical wiring, equipment, or fixtures, based on job specifications and local codes."
                },
                {
                  title:"Design",
                  innerText:"Prepare sketches or follow blueprints to determine the location of wiring or equipment and to ensure conformance to building and safety codes."
                },
                {
                  title:"Troubleshoot",
                  innerText:"Diagnose malfunctioning systems, apparatus, or components, using test equipment and hand tools to locate the cause of a breakdown and correct the problem."
                },
                {
                  title:"Instructing",
                  innerText:"Advise management on whether continued operation of equipment could be hazardous."
                },
                {
                  title:"Quality Control",
                  innerText:"Inspect electrical systems, equipment, or components to identify hazards, defects, or the need for adjustment or repair, and to ensure compliance with codes."
                },
                {
                  title:"Hand Tools",
                  innerText:"Repair or replace wiring, equipment, or fixtures, using hand tools or power tools."
                },]
        },
        {
          jobtitle:'Heating/Air/ Refrigeration Mechanics',
          salary:"$26,490 - $62,640",
          description:"Install or repair heating, central air conditioning, HVAC, or refrigeration systems, including oil burners, hot-air furnaces, and heating stoves.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'PNcQwekI2KQ',srcOrHref:'https://youtu.be/PNcQwekI2KQ'},
          id_gamification:"construc-5",
          communicationbuttons:
              [ 
                {
                  title:"Electrical",
                  innerText:"Test electrical circuits or components for continuity, using electrical test equipment."
                },
                {
                  title:"Repair",
                  innerText:"Repair or replace defective equipment, components, or wiring."
                },
                {
                  title:"Customer Service",
                  innerText:"Discuss heating or cooling system malfunctions with users to isolate problems or to verify that repairs corrected malfunctions."
                },
                {
                  title:"Safety",
                  innerText:"Comply with all applicable standards, policies, or procedures, such as safety procedures or the maintenance of a clean work area."
                },
                {
                  title:"Organization",
                  innerText:"Keep records of repairs and replacements made and causes of malfunctions."
                },
                {
                  title:"Quality Control",
                  innerText:"Inspect and test systems to verify system compliance with plans and specifications or to detect and locate malfunctions."
                },]
        },
        {
          jobtitle:'Ironworker',
          salary:"$32,240 - $63,530",
          description:"Raise, place, and unite iron or steel girders, columns, and other structural members to form completed structures or structural frameworks. May erect metal storage tanks and assemble prefabricated metal buildings.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'hHKuhixIsnc',srcOrHref:'https://youtu.be/hHKuhixIsnc'},
          id_gamification:"construc-6",
          communicationbuttons:
              [ 
                {
                  title:"Analysis",
                  innerText:"Read specifications or blueprints to determine the locations, quantities, or sizes of materials required."
                },
                {
                  title:"Reading",
                  innerText:"Connect columns, beams, and girders with bolts, following blueprints and instructions from supervisors."
                },
                {
                  title:"Hand Tools",
                  innerText:"Force structural steel members into final positions, using turnbuckles, crowbars, jacks, or hand tools."
                },
                {
                  title:"Quality Control",
                  innerText:"Verify vertical and horizontal alignment of structural steel members, using plumb bobs, laser equipment, transits, or levels."
                },
                {
                  title:"Precision",
                  innerText:"Fabricate metal parts, such as steel frames, columns, beams, or girders, according to blueprints or instructions from supervisors."
                },
                {
                  title:"Welding",
                  innerText:"Cut, bend, or weld steel pieces, using metal shears, torches, or welding equipment."
                },]
        },
      ]
         
    },
    energy_and_utilities:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/En9Bjtvq-48",videoId:'En9Bjtvq-48',imageOrVideo:'video',srcOrHref:'https://youtu.be/En9Bjtvq-48'
      },
      jobs:[
        {
          jobtitle:'Earth Driller',
          salary:"$28,530 - $59,090",
          description:"Operate a variety of drills such as rotary, churn, and pneumatic to tap subsurface water and salt deposits, to remove core samples during mineral exploration or soil testing, and to facilitate the use of explosives in mining or construction. Includes horizontal and earth boring machine operators.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'MTP0fYxf8BA',srcOrHref:'https://youtu.be/MTP0fYxf8BA'},
          id_gamification:"eau-1",
          communicationbuttons:
              [ 
                {
                  title:"Operate Machines",
                  innerText:"Operate controls to stabilize machines and to position and align drills."    
                },
                {
                  title:"Recording",
                  innerText:"Record drilling progress and geological data."
                },
                {
                  title:"Quality Control",
                  innerText:"Perform pumping tests to assess well performance."
                },
                {
                  title:"Design",
                  innerText:"Design well pumping systems."
                },
                {
                  title:"Coordination",
                  innerText:"Signal crane operators to move equipment."
                },
                {
                  title:"Analysis",
                  innerText:"Inspect core samples to determine the nature of strata, or take samples to laboratories for analysis"
                },]
        },
     
        {
          jobtitle:'Electrical Power Line Installers',
          salary:"$38,490 - $96,470",
          description:"Install or repair cables or wires used in electrical power or distribution systems. May erect poles and light or heavy duty transmission towers.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'qJbC9pYtLK4',srcOrHref:'https://youtu.be/qJbC9pYtLK4'},
          id_gamification:"eau-2",
          communicationbuttons:
              [ 
                {
                  title:"Safety",
                  innerText:"Adhere to safety practices and procedures, such as checking equipment regularly and erecting barriers around work areas."
                },
                {
                  title:"Quality Control",
                  innerText:"Test conductors, according to electrical diagrams and specifications, to identify corresponding conductors and to prevent incorrect connections."
                },
                {
                  title:"Repair",
                  innerText:"Open switches or attach grounding devices to remove electrical hazards from disturbed or fallen lines or to facilitate repairs."
                },
                {
                  title:"Vehicle Operation",
                  innerText:"Drive vehicles equipped with tools and materials to job sites."
                },
                {
                  title:"Operate Machinery",
                  innerText:"Dig holes, using augers, and set poles, using cranes and power equipment."
                },
                {
                  title:"Troubleshoot",
                  innerText:"Inspect and test power lines and auxiliary equipment to locate and identify problems, using reading and testing instruments."
                },]
        },
        {
          jobtitle:"Nuclear Engineers",
          salary:" $71,860 - $179,430",
          description:"Conduct research on nuclear engineering projects or apply principles and theory of nuclear science to problems concerned with release, control, and use of nuclear energy and nuclear waste disposal.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'RX_V2Tt0c4w',srcOrHref:'https://youtu.be/RX_V2Tt0c4w'},
          id_gamification:"eau-3",
          communicationbuttons:
              [ 
                {
                  title:"Design",
                  innerText:"Design or develop nuclear equipment, such as reactor cores, radiation shielding, or associated instrumentation or control mechanisms."
                },
                {
                  title:"Critical Analysis",
                  innerText:"Examine accidents to obtain data that can be used to design preventive measures."
                },
                {
                  title:"Instructing",
                  innerText:"Write operational instructions to be used in nuclear plant operation or nuclear fuel or waste handling and disposal."
                },
                {
                  title:"Research",
                  innerText:"Conduct environmental studies related to topics such as nuclear power generation, nuclear waste disposal, or nuclear weapon deployment."
                },
                {
                  title:"Safety",
                  innerText:"Initiate corrective actions or order plant shutdowns in emergency situations."
                },
                {
                  title:"Active Learning",
                  innerText:"Keep abreast of developments and changes in the nuclear field by reading technical journals or by independent study and research."
                },]
        },
        {
          jobtitle:'Environmental Engineering Technician',
          description:"Apply theory and principles of environmental engineering to modify, test, and operate equipment and devices used in the prevention, control, and remediation of environmental problems, including waste treatment and site remediation, under the direction of engineering staff or scientists. May assist in the development of environmental remediation devices.",
          salary:"$29,500 - $77,130", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'gBIUUlrJKjU',srcOrHref:'https://youtu.be/gBIUUlrJKjU'},
          id_gamification:"eau-4",
          communicationbuttons:
              [ 
                {
                  title:"Organization",
                  innerText:"Maintain project logbook records or computer program files."
                },
                {
                  title:"Observation",
                  innerText:"Record laboratory or field data, including numerical data, test results, photographs, or summaries of visual observations."
                },
                {
                  title:"Analysis",
                  innerText:"Collect and analyze pollution samples, such as air or ground water."
                },
                {
                  title:"Cleanliness",
                  innerText:"Decontaminate or test field equipment used to clean or test pollutants from soil, air, or water."
                },
                {
                  title:"Critical Thinking",
                  innerText:"Create models to demonstrate or predict the process by which pollutants move through or impact an environment."
                },
                {
                  title:"Safety",
                  innerText:"Inspect facilities to monitor compliance with regulations governing substances, such as asbestos, lead, or wastewater."
                },]
        },
        {
          jobtitle:'Water Plant Operator',
          salary:"$23,540 - $64,890",
          description:"Operate or control an entire process or system of machines, often through the use of control boards, to transfer or treat water or wastewater.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'pABK8Di1Y5Q',srcOrHref:'https://youtu.be/pABK8Di1Y5Q'},
          id_gamification:"eau-5",
          communicationbuttons:
              [ 
                {
                  title:"Maintenance",
                  innerText:"Add chemicals, such as ammonia, chlorine, or lime, to disinfect and deodorize water and other liquids."
                },
                {
                  title:"Analysis",
                  innerText:"Collect and test water and sewage samples, using test equipment and color analysis standards." 
                },
                {
                  title:"Observation",
                  innerText:"Record operational data, personnel attendance, or meter and gauge readings on specified forms."
                },
                {
                  title:"Machine Operation",
                  innerText:"Operate and adjust controls on equipment to purify and clarify water, process or dispose of sewage, and generate power."
                },
                {
                  title:"Quality Control",
                  innerText:"Inspect equipment or monitor operating conditions, meters, and gauges to determine load requirements and detect malfunctions."
                },
                {
                  title:"Supervise",
                  innerText:"Direct and coordinate plant workers engaged in routine operations and maintenance activities."
                },]
        },
        {
            jobtitle:'Geoscientists',
            salary:"$40,760 - $98,900",
            description:"Study the composition, structure, and other physical aspects of the Earth. May use geological, physics, and mathematics knowledge in exploration for oil, gas, minerals, or underground water; or in waste disposal, land reclamation, or other environmental problems. May study the Earth's internal composition, atmospheres, and oceans, and its magnetic, electrical, and gravitational forces. Includes mineralogists, paleontologists, stratigraphers, geodesists, and seismologists.", 
            jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'tNCUbLoD5Io',srcOrHref:'https://youtu.be/tNCUbLoD5Io'},
            id_gamification:"eau-6",
            communicationbuttons:
                [ 
                  {
                    title:"Computer Literacy",
                    innerText:"Analyze and interpret geological data, using computer software."
                  },
                  {
                    title:"Research",
                    innerText:"Locate and review research articles or environmental, historical, or technical reports." 
                  },
                  {
                    title:"Writing",
                    innerText:"Communicate geological findings by writing research papers, participating in conferences, or teaching geological science at universities."
                  },
                  {
                    title:"Critical Analysis",
                    innerText:"Inspect construction projects to analyze engineering problems, using test equpment or drilling machinery"
                  },
                  {
                    title:"Design",
                    innerText:"Design geological mine maps, monitor mine structural integrity, or advise and monitor mining crews."
                  },
                  {
                    title:"Science",
                    innerText:"Test industrial diamonds or abrasives, soil, or rocks to determine their geological characteristics, using optical, x-ray, heat, acid, or precision instruments."
                  },]
          },
      ]
    },
    engineering:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/veg8CcB-xnI",videoId:'veg8CcB-xnI',imageOrVideo:'video',srcOrHref:'https://youtu.be/veg8CcB-xnI'
      },
      jobs:[
        {
          jobtitle:'Biomedical Engineers',
          salary:"$55,280 - $148,210",
          description:"Apply knowledge of engineering, biology, chemistry, computer science, and biomechanical principles to the design, development, and evaluation of biological, agricultural, and health systems and products, such as artificial organs, prostheses, instrumentation, medical information systems, and health management and care delivery systems.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'hBKj9Uh1PgI',srcOrHref:'https://youtu.be/hBKj9Uh1PgI'},
          id_gamification:"engi-1",
          communicationbuttons:
              [ 
                {
                  title:"Computer Literacy",
                  innerText:"Adapt or design computer hardware or software for medical science uses."
                },
                {
                  title:"Communicate",
                  innerText:"Confer with engineers, customers, or others to discuss existing or potential engineering projects or products."
                },
                {
                  title:"Detail Oriented",
                  innerText:"Perform detailed calculations to compute and establish manufacturing, construction, or installation standards or specifications."
                },
                {
                  title:"Management",
                  innerText:"Supervise or train project team members, as necessary."
                },
                {
                  title:"Analysis",
                  innerText:"Conduct field surveys or study maps, graphs, diagrams, or other data to identify and correct power system problems."
                },
                {
                  title:"Design",
                  innerText:"Design electrical systems or components that minimize electric energy requirements, such as lighting systems designed to account for natural lighting."
                },]
        },
        {
          jobtitle:'Industrial Engineers',
          salary:"$55,880 - $128,660",
          description:"Design, develop, test, and evaluate integrated systems for managing industrial production processes, including human work factors, quality control, inventory control, logistics and material flow, cost analysis, and production coordina", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'kNXMJBQ6oL4',srcOrHref:'https://youtu.be/kNXMJBQ6oL4'},
          id_gamification:"engi-2",
          communicationbuttons:
              [ 
                {
                  title:"Teamwork",
                  innerText:"Communicate with management and user personnel to develop production and design standards."
                },
                {
                  title:"Critical Analysis",
                  innerText:"Evaluate precision and accuracy of production and testing equipment and engineering drawings to formulate corrective action plan."
                },
                {
                  title:"Management",
                  innerText:"Direct workers engaged in product measurement, inspection, and testing activities to ensure quality control and reliability."
                },
                {
                  title:"Systems Design",
                  innerText:"Plan and establish sequence of operations to fabricate and assemble parts or products and to promote efficient utilization."
                },
                {
                  title:"Problem Solving",
                  innerText:"Recommend methods for improving utilization of personnel, material, and utilities."
                },
                {
                  title:"Organization",
                  innerText:"Complete production reports, purchase orders, and material, tool, and equipment lists."
                },]
        },
        {
          jobtitle:'Mechanical Engineers',
          salary:" $58,490 - $137,590",
          description:"Perform engineering duties in planning and designing tools, engines, machines, and other mechanically functioning equipment. Oversee installation, operation, maintenance, and repair of equipment such as centralized heat, gas, water, and steam systems.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'bDg2UcaZzcg',srcOrHref:'https://youtu.be/bDg2UcaZzcg'},
          id_gamification:"engi-3",
          communicationbuttons:
              [ 
                {
                  title:"Analysis",
                  innerText:"Read and interpret blueprints, technical drawings, schematics, or computer-generated reports."
                },
                {
                  title:"Teamwork",
                  innerText:"Confer with engineers or other personnel to implement operating procedures, resolve system malfunctions, or provide technical information."
                },
                {
                  title:"Project Management",
                  innerText:"Develop, coordinate, or monitor all aspects of production, including selection of manufacturing methods, fabrication, or operation of product designs."
                },
                {
                  title:"Quality Control",
                  innerText:"Recommend design modifications to eliminate machine or system malfunctions."
                },
                {
                  title:"Design",
                  innerText:"Design integrated mechanical or alternative systems, such as mechanical cooling systems with natural ventilation systems, to improve energy efficiency."
                },
                {
                  title:"Critical Analysis",
                  innerText:"Conduct research that tests or analyzes the feasibility, design, operation, or performance of equipment, components, or systems."
                },]
        },
        {
          jobtitle:"Chemical Engineers",
          salary:"$69,270 - $147,940",
          description:"Design chemical plant equipment and devise processes for manufacturing chemicals and products, such as gasoline, synthetic rubber, plastics, detergents, cement, paper, and pulp, by applying principles and technology of chemistry, physics, and engineering.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'cFQ4BjDeTME',srcOrHref:'https://youtu.be/cFQ4BjDeTME'},
          id_gamification:"engi-4",
          communicationbuttons:
              [ 
                {
                  title:"Analysis",
                  innerText:"Monitor and analyze data from processes and experiments."
                },
                {
                  title:"Safety",
                  innerText:"Develop safety procedures to be employed by workers operating equipment or working in close proximity to ongoing chemical reactions."
                },
                {
                  title:"Chemistry",
                  innerText:"Develop processes to separate components of liquids or gases or generate electrical currents, using controlled chemical processes."
                },
                {
                  title:"Troubleshoot",
                  innerText:"Troubleshoot problems with chemical manufacturing processes."
                },
                {
                  title:"Research",
                  innerText:"Conduct research to develop new and improved chemical manufacturing processes."
                },
                {
                  title:"Systems Design",
                  innerText:"Design measurement and control systems for chemical plants based on data collected in laboratory experiments and in pilot plant operations."
                },]
        },
        {
          jobtitle:'Civil Engineers',
          salary:"$53,220 - $141,990",
          description:"Perform engineering duties in planning, designing, and overseeing construction and maintenance of building structures and facilities, such as roads, railroads, airports, bridges, harbors, channels, dams, irrigation projects, pipelines, power plants, and water and sewage systems.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'-XIQSrYi0O0',srcOrHref:'https://youtu.be/-XIQSrYi0O0'},
          id_gamification:"engi-5",
          communicationbuttons:
              [ 
                {
                  title:"Monitoring",
                  innerText:"Inspect project sites to monitor progress and ensure conformance to design specifications and safety or sanitation standards."
                },
                {
                  title:"Instructing",
                  innerText:"Provide technical advice to industrial or managerial personnel regarding design, construction, program modifications, or structural repairs."
                },
                {
                  title:"Critical Analysis",
                  innerText:"Test soils or materials to determine the adequacy and strength of foundations, concrete, asphalt, or steel."
                },
                {
                  title:"Management",
                  innerText:"Manage and direct the construction, operations, or maintenance activities at project site."
                },
                {
                  title:"Design",
                  innerText:"Design energy-efficient or environmentally sound civil structures."
                },
                {
                  title:"Computer Literacy",
                  innerText:"Plan and design transportation or hydraulic systems or structures, using computer-assisted design or drawing tools."
                },]
        },
        {
          jobtitle:'Electrical Engineers',
          salary:"$64,620 - $153,180",
          description:"Research, design, develop, test, or supervise the manufacturing and installation of electrical equipment, components, or systems for commercial, industrial, military, or scientific use.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'fhAZ2tZj84A',srcOrHref:'https://youtu.be/fhAZ2tZj84A'},
          id_gamification:"engi-6",
          communicationbuttons:
              [ 
                {
                  title:"Computer Literacy",
                  innerText:"Operate computer-assisted engineering or design software or equipment to perform engineering tasks."
                },
                {
                  title:"Communicate",
                  innerText:"Confer with engineers, customers, or others to discuss existing or potential engineering projects or products."
                },
                {
                  title:"Detail Oriented",
                  innerText:"Perform detailed calculations to compute and establish manufacturing, construction, or installation standards or specifications."
                },
                {
                  title:"Management",
                  innerText:"Supervise or train project team members, as necessary."
                },
                {
                  title:"Analysis",
                  innerText:"Conduct field surveys or study maps, graphs, diagrams, or other data to identify and correct power system problems."
                },
                {
                  title:"Design",
                  innerText:"Design electrical systems or components that minimize electric energy requirements, such as lighting systems designed to account for natural lighting."
                },]
        },
      ]
    },
    healthcare:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/qm6c7KDIQq0",videoId:'qm6c7KDIQq0',imageOrVideo:'video',srcOrHref:'https://youtu.be/qm6c7KDIQq0'
      },
         
jobs:[
  {
    jobtitle:'Dental Hygienist',
    salary:" $34,710 - $61,160",
    description:"Administer oral hygiene care to patients. Assess patient oral hygiene problems or needs and maintain health records. Advise patients on oral health maintenance and disease prevention. May provide advanced care such as providing fluoride treatment or administering topical anesthesia.", 
    jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'TeAtDfCQ0J4',srcOrHref:'https://youtu.be/TeAtDfCQ0J4'},
    id_gamification:"healthcare-1",
    communicationbuttons:
        [ 
          {
            title:"Cleanliness",
            innerText:"Clean calcareous deposits, accretions, and stains from teeth and beneath margins of gums, using dental instruments."
          },
          {
            title:"Data Entry",
            innerText:"Record and review patient medical histories."
          },
          {
            title:"Analysis",
            innerText:"Examine gums, using probes, to locate periodontal recessed gums and signs of gum disease."
          },
          {
            title:"Instructing",
            innerText:"Conduct dental health clinics for community groups to augment services of dentist."
          },
          {
            title:"Customer Service",
            innerText:"Provide clinical services or health education to improve and maintain the oral health of patients or the general public"
          },
          {
            title:"Maintenance",
            innerText:"Maintain dental equipment and sharpen and sterilize dental instruments."
          },]
  },
  {
    jobtitle:'Diagnostic Sonography',
    salary:" $39,850 - $76,350",
    description:"Produce ultrasonic recordings of internal organs for use by physicians. Includes vascular technologists.", 
    jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'GYLfPP0UQWw',srcOrHref:'https://youtu.be/GYLfPP0UQWw'},
    id_gamification:"healthcare-2",
    communicationbuttons:
        [ 
          {
            title:"Monitoring",
            innerText:"Observe screen during scan to ensure that image produced is satisfactory for diagnostic purposes, making adjustments to equipment as required."
          },
          {
            title:"Safety",
            innerText:"Observe and care for patients throughout examinations to ensure their safety and comfort."
          },
          {
            title:"Instructing",
            innerText:"Prepare patient for exam by explaining procedure, transferring patient to ultrasound table, scrubbing skin and applying gel, and positioning patient properly."
          },
          {
            title:"Critical Thinking",
            innerText:"Determine whether scope of exam should be extended, based on findings."
          },
          {
            title:"Teamwork",
            innerText:"Coordinate work with physicians or other healthcare team members, including providing assistance during invasive procedures."
          },
          {
            title:"Organization",
            innerText:"Clean, check, and maintain sonographic equipment, submitting maintenance requests or performing minor repairs as necessary."
          },]
  },
  {
    jobtitle:'Nursing Assistant',
    salary:" $18,750 - $31,640",
    description:"Provide or assist with basic care or support under the direction of onsite licensed nursing staff. Perform duties such as monitoring of health status, feeding, bathing, dressing, grooming, toileting, or ambulation of patients in a health or nursing facility. May include medication administration and other health-related tasks. Includes nursing care attendants, nursing aides, and nursing attendants.", 
    jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'wlSsdECNaiU',srcOrHref:'https://youtu.be/wlSsdECNaiU'},
    id_gamification:"healthcare-3",
    communicationbuttons:
        [ 
          {
            title:"Observe",
            innerText:"Document or otherwise report observations of patient behavior, complaints, or physical symptoms to nurses."
          },
          {
            title:"Safety",
            innerText:"Review patients' dietary restrictions, food allergies, and preferences to ensure patient receives appropriate diet."
          },
          {
            title:"Communicate",
            innerText:"Communicate with patients to ascertain feelings or need for assistance or social and emotional support."
          },
          {
            title:"Instructing",
            innerText:"Explain medical instructions to patients or family members."
          },
          {
            title:"Teamwork",
            innerText:"Assist nurses or physicians in the operation of medical equipment or provision of patient care."
          },
          {
            title:"Physical Strength",
            innerText:"Lift or assist others to lift patients to move them on or off beds, examination tables, surgical tables, or stretchers."
          },]
  },
  {
    jobtitle:"Phlebotomists",
    salary:" $22,350 - $40,280",
    description:"Draw blood for tests, transfusions, donations, or research. May explain the procedure to patients and assist in the recovery of patients with adverse reactions.", 
    jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'A3c4BlLri_U',srcOrHref:'https://youtu.be/A3c4BlLri_U'},
    id_gamification:"healthcare-4",
    communicationbuttons:
        [ 
          {
            title:"Safety",
            innerText:"Dispose of contaminated sharps, in accordance with applicable laws, standards, and policies."
          },
          {
            title:"Organize",
            innerText:"Organize or clean blood-drawing trays, ensuring that all instruments are sterile and all needles, syringes, or related items are of first-time use."
          },
          {
            title:"Detail Oriented",
            innerText:"Collect specimens at specific time intervals for tests, such as those assessing therapeutic drug levels."
          },
          {
            title:"Computer Literacy",
            innerText:"Enter patient, specimen, insurance, or billing information into computer."
          },
          {
            title:"Instructing",
            innerText:"Explain fluid or tissue collection procedures to patients."
          },
          {
            title:"Testing",
            innerText:"Conduct hemoglobin tests to ensure donor iron levels are normal."
          },]
  },
  {
    jobtitle:'Physical Therapy Assistant',
    salary:"$39,450 - $78,300",
    description:"Assist physical therapists in providing physical therapy treatments and procedures. May, in accordance with state laws, assist in the development of treatment plans, carry out routine functions, document the progress of treatment, and modify specific treatments in accordance with patient status and within the scope of treatment plans established by a physical therapist. Generally requires formal training.", 
    jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'fbpS0uBh7b0',srcOrHref:''},
    id_gamification:"healthcare-5",
    communicationbuttons:
        [ 
          {
            title:"Data Entry",
            innerText:"Document patient information, such as notes on their progress."
          },
          {
            title:"Instructing",
            innerText:"Instruct patients in proper body mechanics and in ways to improve functional mobility, such as aquatic exercise."
          },
          {
            title:"Physical Strength",
            innerText:"Secure patients into or onto therapy equipment."
          },
          {
            title:"Cleanliness",
            innerText:"Clean work area and check and store equipment after treatment."
          },
          {
            title:"Communicate",
            innerText:"Communicate with or instruct caregivers or family members on patient therapeutic activities or treatment plans."
          },
          {
            title:"Active Learning",
            innerText:"Attend or conduct continuing education courses, seminars, or in-service activities."
          },]
  },
  {
    jobtitle:'Registered Nurse',
    salary:"$43,350 - $79,590",
    description:"Assess patient health problems and needs, develop and implement nursing care plans, and maintain medical records. Administer nursing care to ill, injured, convalescent, or disabled patients. May advise patients on health maintenance and disease prevention or provide case management. Licensing or registration required.", 
    jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'x4Y_2fpET0U',srcOrHref:'https://youtu.be/x4Y_2fpET0U'},
    id_gamification:"healthcare-6",
    communicationbuttons:
        [ 
          {
            title:"Communicate",
            innerText:"Consult and coordinate with healthcare team members to assess, plan, implement, or evaluate patient care plans."
          },
          {
            title:"Social Perceptiveness",
            innerText:"Assess the needs of individuals, families, or communities, including assessment of individuals' home or work environments, to identify potential health or safety problems."
          },
          {
            title:"Monitoring",
            innerText:"Monitor, record, and report symptoms or changes in patients' conditions."
          },
          {
            title:"Service Oriented",
            innerText:"Assess the needs of individuals, families, or communities, including assessment of individuals' home or work environments, to identify potential health or safety problems."
          },
          {
            title:"Instructing",
            innerText:"Instruct individuals, families, or other groups on topics such as health education, disease prevention, or childbirth and develop health improvement programs."
          },
          {
            title:"Detail Oriented",
            innerText:"Administer medications to patients and monitor patients for reactions or side effects."
          },]
  },
]
    },
    information_technology:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/cbffW0MeWoY",videoId:'cbffW0MeWoY',imageOrVideo:'video',srcOrHref:'https://youtu.be/cbffW0MeWoY'
      },
      jobs:[
        {
          jobtitle:'Computer User Support Specialist',
          salary:"$31,540 - $78,630",
          description:"Provide technical assistance to computer users. Answer questions or resolve computer problems for clients in person, via telephone, or electronically. May provide assistance concerning the use of computer hardware and software, including printing, installation, word processing, electronic mail, and operating systems.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'xe6GS8kSN1g',srcOrHref:'https://youtu.be/xe6GS8kSN1g'},
          id_gamification:"it-1",
          communicationbuttons:
              [ 
                {
                  title:"Customer Support",
                  innerText:"Answer user inquiries regarding computer software or hardware operation to resolve problems."
                },
                {
                  title:"Troubleshoot",
                  innerText:"Read technical manuals, confer with users, or conduct computer diagnostics to investigate and resolve problems or to provide technical assistance and support."
                },
                {
                  title:"Instructing",
                  innerText:"Develop training materials and procedures, or train users in the proper use of hardware or software."
                },
                {
                  title:"Organization",
                  innerText:"Maintain records of daily data communication transactions, problems and remedial actions taken, or installation activities."
                },
                {
                  title:"Communicate",
                  innerText:"Confer with staff, users, and management to establish requirements for new systems or modifications."
                },
                {
                  title:"Systems Analysis",
                  innerText:"Prepare evaluations of software or hardware, and recommend improvements or upgrades."
                },]
        },
        {
          jobtitle:'Database Administrator',
          salary:"$50,490 - $127,220",
          description:"Administer, test, and implement computer databases, applying knowledge of database management systems. Coordinate changes to computer databases. Identify, investigate, and resolve database performance issues, database capacity, and database scalability. May plan, coordinate, and implement security measures to safeguard computer databases.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'GKAnWFOyk8E',srcOrHref:' https://youtu.be/GKAnWFOyk8E'},
          id_gamification:"it-2",
          communicationbuttons:
              [ 
                {
                  title:"Troubleshoot",
                  innerText:"Test programs or databases, correct errors, and make necessary modifications."
                },
                {
                  title:"Safety",
                  innerText:"Develop standards and guidelines to guide the use and acquisition of software and to protect vulnerable information."
                },
                {
                  title:"Instructing",
                  innerText:"Train users and answer questions."
                },
                {
                  title:"Coding",
                  innerText:"Write and code logical and physical database descriptions and specify identifiers of database to management system or direct others in coding descriptions."
                },
                {
                  title:"Critical Analysis",
                  innerText:"Develop data model describing data elements and how they are used, following procedures and using pen, template or computer software."
                },
                {
                  title:"Quality Control",
                  innerText:"Test changes to database applications or systems."
                },]
        },
        {
          jobtitle:'Information Security Analyst',
          salary:" $49,760 - $136,900",
          description:"Plan, implement, upgrade, or monitor security measures for the protection of computer networks and information. Assess system vulnerabilities for security risks and propose and implement risk mitigation strategies. May ensure appropriate security controls are in place that will safeguard digital files and vital electronic infrastructure. May respond to computer security breaches and viruses.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'cVZktAEtWE4',srcOrHref:'https://youtu.be/cVZktAEtWE4'},
          id_gamification:"it-3",
          communicationbuttons:
              [ 
                {
                  title:"Organize",
                  innerText:"Document computer security and emergency measures policies, procedures, and tests."
                },
                {
                  title:"Analysis",
                  innerText:"Monitor current reports of computer viruses to determine when to update virus protection systems."
                },
                {
                  title:"Communicate",
                  innerText:"Coordinate implementation of computer system plan with establishment personnel and outside vendors."
                },
                {
                  title:"Quality Control",
                  innerText:"Perform risk assessments and execute tests of data processing system to ensure functioning of data processing activities and security measures."
                },
                {
                  title:"Troubleshoot",
                  innerText:"Modify computer security files to incorporate new software, correct errors, or change individual access status."
                },
                {
                  title:"Instructing",
                  innerText:"Confer with users to discuss issues such as computer data access needs, security violations, and programming changes."
                },]
        },
        {
          jobtitle:"IT Project Manager",
          salary:"$42,240  - $116,490",
          description:"Plan, initiate, and manage information technology (IT) projects. Lead and guide the work of technical staff. Serve as liaison between business and technical aspects of projects. Plan project stages and assess business implications for each stage. Monitor progress to assure deadlines, standards, and cost targets are met.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'LVkXobqdvbk',srcOrHref:'https://youtu.be/LVkXobqdvbk'},
          id_gamification:"it-4",
          communicationbuttons:
              [ 
                {
                  title:"Organization",
                  innerText:"Monitor or track project milestones and deliverables."
                },
                {
                  title:"Quality Control",
                  innerText:"Submit project deliverables, ensuring adherence to quality standards."
                },
                {
                  title:"Customer Service",
                  innerText:"Assess current or future customer needs and priorities by communicating directly with customers, conducting surveys, or other methods."
                },
                {
                  title:"Planning",
                  innerText:"Initiate, review, or approve modifications to project plans."
                },
                {
                  title:"Time Management",
                  innerText:"Schedule and facilitate meetings related to information technology projects."
                },
                {
                  title:"Critical Analysis",
                  innerText:"Develop implementation plans that include analyses such as cost-benefit or return on investment (ROI)."
                },]
        },
        {
          jobtitle:'Software Developer',
          salary:"$55,630 - $156,570",
          description:"Research, design, and develop computer and network software or specialized utility programs. Analyze user needs and develop software solutions, applying principles and techniques of computer science, engineering, and mathematical analysis. Update software or enhance existing software capabilities. May work with computer hardware engineers to integrate hardware and software systems, and develop specifications and performance requirements. May maintain databases within an application area, working individually or coordinating database development as part of a team.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'',srcOrHref:''},
          id_gamification:"it-5",
          communicationbuttons:
              [ 
                {
                  title:"Communicate",
                  innerText:"Consult with customers or other departments on project status, proposals, or technical issues, such as software system design or maintenance."
                },
                {
                  title:"Systems Analysis",
                  innerText:"Analyze information to determine, recommend, and plan installation of a new system or modification of an existing system."
                },
                {
                  title:"Supervise",
                  innerText:"Supervise the work of programmers, technologists and technicians and other engineering and scientific personnel."
                },
                {
                  title:"Instructing",
                  innerText:"Train users to use new or modified equipment."
                },
                {
                  title:"Monitoring",
                  innerText:"Monitor functioning of equipment to ensure system operates in conformance with specifications."
                },
                {
                  title:"Mathematics",
                  innerText:"Design, develop and modify software systems, using scientific analysis and mathematical models to predict and measure outcomes and consequences of design."
                },]
        },
        {
          jobtitle:'Web Developer',
          salary:"$38,910 - $110,970",
          description:"Develop and implement websites, web applications, application databases, and interactive web interfaces. Evaluate code to ensure that it is properly structured, meets industry standards, and is compatible with browsers and devices. Optimize website performance, scalability, and server-side code and processes. May develop website infrastructure and integrate websites with other computer applications.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'FfxTkIsaTYk',srcOrHref:'https://youtu.be/FfxTkIsaTYk'},
          id_gamification:"it-6",
          communicationbuttons:
              [ 
                {
                  title:"Programming",
                  innerText:"Design, build, or maintain Web sites, using authoring or scripting languages, content creation tools, management tools, and digital media."
                },
                {
                  title:"Analysis",
                  innerText:"Analyze user needs to determine technical requirements."
                },
                {
                  title:"Communicate",
                  innerText:"Respond to user email inquiries, or set up automated systems to send responses."
                },
                {
                  title:"Quality Control",
                  innerText:"Perform Web site tests according to planned schedules, or after any Web site or product revision."
                },
                {
                  title:"Teamwork",
                  innerText:"Confer with management or development teams to prioritize needs, resolve conflicts, develop content criteria, or choose solutions."
                },
                {
                  title:"Prototyping",
                  innerText:"Create Web models or prototypes that include physical, interface, logical, or data models."
                },]
        },
      ]
    },
    public_safety:{
      mediaInfo:{
        videoType:'',link:"public-safety-careers",videoId:'', imageOrVideo:'img',srcOrHref:'public-safety-careers'
      },
      jobs:[
        {
          jobtitle:'Patrol Officers',
          salary:"$29,610 - $65,520",
          description:"Maintain order and protect life and property by enforcing local, tribal, state, or federal laws and ordinances. Perform a combination of the following duties: patrol a specific area; direct traffic; issue traffic summonses; investigate accidents; apprehend and arrest suspects, or serve legal processes of courts. Includes police officers working at educational institutions.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'14D91NPvMz8',srcOrHref:'https://youtu.be/14D91NPvMz8'},
          id_gamification:"psafety-1",
          communicationbuttons:
              [ 
                {
                  title:"Apprehend Suspects",
                  innerText:"Identify, pursue, and arrest suspects and perpetrators of criminal acts."
                },
                {
                  title:"Public Safety",
                  innerText:"Provide for public safety by maintaining order, responding to emergencies, protecting people and property, enforcing motor vehicle and criminal laws, and promoting good community relations."
                },
                {
                  title:"Active Listening",
                  innerText:"Record facts to prepare reports that document incidents and activities."
                },
                {
                  title:"First Aid",
                  innerText:"Render aid to accident victims and other persons requiring first aid for physical injuries."
                },
                {
                  title:"Investigate",
                  innerText:"Investigate illegal or suspicious activities"
                },
                {
                  title:"Weapons & Tools",
                  innerText:"Use handguns, handcuffs, police vehicles, surveillance video and audio recorders, and two-way radios."
                },]
        },
        {
          jobtitle:'Firefighters',
          salary:"$24,080 - $62,680",
          description:"Control and extinguish fires or respond to emergency situations where life, property, or the environment is at risk. Duties may include fire prevention, emergency medical service, hazardous material response, search and rescue, and disaster assistance.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'DNK5dYGn3Fg',srcOrHref:'https://youtu.be/DNK5dYGn3Fg'},
          id_gamification:"psafety-2",
          communicationbuttons:
              [ 
                {
                  title:"Critical Thinking",
                  innerText:"Assess fires and situations and report conditions to superiors to receive instructions, using two-way radios."
                },
                {
                  title:"Hand Tools",
                  innerText:"Create openings in buildings for ventilation or entrance, using axes, chisels, crowbars, electric saws, or core cutters."
                },
                {
                  title:"Quality Control",
                  innerText:"Inspect fire sites after flames have been extinguished to ensure that there is no further danger."
                },
                {
                  title:"Judgement",
                  innerText:"Move toward the source of a fire, using knowledge of types of fires, construction design, building materials, and physical layout of propertie"
                },
                {
                  title:"Collaborate",
                  innerText:"Collaborate with police to respond to accidents, disasters, and arson investigation calls."
                },
                {
                  title:"Physical Fitness",
                  innerText:"Participate in physical training activities to maintain a high level of physical fitness."
                },]
        },
        {
          jobtitle:'Criminal Investigator',
          salary:" $36,250 - $123,610",
          description:"Conduct investigations related to suspected violations of federal, state, or local laws to prevent or solve crimes.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'PNOImo84oHI',srcOrHref:'https://youtu.be/PNOImo84oHI'},
          id_gamification:"psafety-3",
          communicationbuttons:
              [ 
                {
                  title:"Active Listening",
                  innerText:"Obtain facts or statements from complainants, witnesses, and accused persons and record interviews, using recording device."
                },
                {
                  title:"Organization",
                  innerText:"Note, mark, and photograph location of objects found, such as footprints, tire tracks, bullets and bloodstains, and take measurements of the scene."
                },
                {
                  title:"Analysis",
                  innerText:"Analyze completed police reports to determine what additional information and investigative work is needed."
                },
                {
                  title:"Critical Analysis",
                  innerText:"Determine scope, timing, and direction of investigations."
                },
                {
                  title:"Teamwork",
                  innerText:"Collaborate with other offices and agencies to exchange information and coordinate activities."
                },
                {
                  title:"Detail Oriented",
                  innerText:"Organize scene search, assigning specific tasks and areas of search to individual officers and obtaining adequate lighting as necessary."
                },]
        },
        {
          jobtitle:"Dispatchers",
          salary:"$20,870 - $47,780",
          description:"Operate telephone, radio, or other communication systems to receive and communicate requests for emergency assistance at 9-1-1 public safety answering points and emergency operations centers. Take information from the public and other sources regarding crimes, threats, disturbances, acts of terrorism, fires, medical emergencies, and other public safety matters. May coordinate and provide information to law enforcement and emergency response personnel.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'mFB5YEzQUfI',srcOrHref:'https://youtu.be/mFB5YEzQUfI'},
          id_gamification:"psafety-4",
          communicationbuttons:
              [ 
                {
                  title:"Communicate",
                  innerText:"Question callers to determine their locations, and the nature of their problems to determine type of response needed."
                },
                {
                  title:"Analysis",
                  innerText:"Determine response requirements and relative priorities of situations, and dispatch units in accordance with established procedures."
                },
                {
                  title:"Data Entry",
                  innerText:"Record details of calls, dispatches, and messages."
                },
                {
                  title:"Quality Control",
                  innerText:"Test and adjust communication and alarm systems, and report malfunctions to maintenance units."
                },
                {
                  title:"Safety",
                  innerText:"Maintain access to, and security of, highly sensitive materials."
                },
                {
                  title:"Monitoring",
                  innerText:"Monitor alarm systems to detect emergencies, such as fires and illegal entry into establishments."
                },]
        },
        {
          jobtitle:'Emergency Management Directors',
          salary:"$35,510 - $134,370",
          description:"Plan and direct disaster response or crisis management activities, provide disaster preparedness training, and prepare emergency plans and procedures for natural (e.g., hurricanes, floods, earthquakes), wartime, or technological (e.g., nuclear power plant emergencies or hazardous materials spills) disasters or hostage situations.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'uRmE4z1sGbA',srcOrHref:'https://youtu.be/uRmE4z1sGbA'},
          id_gamification:"psafety-6",
          communicationbuttons:
              [ 
                {
                  title:"Organization",
                  innerText:"Prepare emergency situation status reports that describe response and recovery efforts, needs, and preliminary damage assessments."
                },
                {
                  title:"Writing",
                  innerText:"Apply for federal funding for emergency-management-related needs and administer and report on the progress of such grants."
                },
                {
                  title:"Instructing",
                  innerText:"Design and administer emergency or disaster preparedness training courses that teach people how to effectively respond to major emergencies and disasters."
                },
                {
                  title:"Teamwork",
                  innerText:"Collaborate with other officials to prepare and analyze damage assessments following disasters or emergencies."
                },
                {
                  title:"Quality Control",
                  innerText:"Develop and perform tests and evaluations of emergency management plans in accordance with state and federal regulations."
                },
                {
                  title:"Analysis",
                  innerText:"Review emergency plans of individual organizations, such as medical facilities, to ensure their adequacy."
                },]
        },
        {
          jobtitle:'Emergency Medical Technician',
          description:"Assess injuries and illnesses and administer basic emergency medical care. May transport injured or sick persons to medical facilities.",
          salary:" $19,310 - $40,370", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'C8zIHk9KKqI',srcOrHref:'https://youtu.be/C8zIHk9KKqI'},
          id_gamification:"psafety-7",
          communicationbuttons:
              [ 
                {
                  title:"Analysis",
                  innerText:"Assess nature and extent of illness or injury to establish and prioritize medical procedures."
                },
                {
                  title:"Active Learning",
                  innerText:"Attend training classes to maintain certification licensure, keep abreast of new developments in the field, or maintain existing knowledge."
                },
                {
                  title:"Persuasion",
                  innerText:"Comfort and reassure patients."
                },
                {
                  title:"Teamwork",
                  innerText:"Coordinate work with other emergency medical team members or police or fire department personnel."
                },
                {
                  title:"Cleanliness",
                  innerText:"Decontaminate ambulance interior following treatment of patient with infectious disease and report case to proper authorities."
                },
                {
                  title:"Medical Procedure",
                  innerText:"Perform emergency diagnostic and treatment procedures, such as stomach suction, airway management, or heart monitoring, during ambulance ride."
                },]
        },
      ]
    },  
    transportation_distribution_and_logistics:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/w0wDnQP1dn8",videoId:'w0wDnQP1dn8',imageOrVideo:'video',srcOrHref:'https://youtu.be/w0wDnQP1dn8'
      },
      jobs:[
        {
          jobtitle:'Automotive Service Technicians',
          salary:"$22,790 - $63,540",
          description:"Diagnose, adjust, repair, or overhaul automotive vehicles.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'ARAUXc6Cbxw',srcOrHref:'https://youtu.be/ARAUXc6Cbxw'},
          id_gamification:"tdl-1",
          communicationbuttons:
              [ 
                {
                  title:"Analysis",
                  innerText:"Inspect vehicles for damage and record findings so that necessary repairs can be made."
                },
                {
                  title:"Troubleshoot",
                  innerText:"Troubleshoot fuel, ignition, and emissions control systems, using electronic testing equipment."
                },
                {
                  title:"Teamwork",
                  innerText:"Review work orders and discuss work with supervisors."
                },
                {
                  title:"Communicate",
                  innerText:"Confer with customers to obtain descriptions of vehicle problems and to discuss work to be performed and future repair requirements."
                },
                {
                  title:"Planning",
                  innerText:"Plan work procedures, using charts, technical manuals, and experience."
                },
                {
                  title:"Mechanical",
                  innerText:"Rebuild parts, such as crankshafts and cylinder blocks."
                },]
        },
        {
          jobtitle:'CDL Drivers',
          salary:"$25,690 - $64,210",
          description:"Drive a tractor-trailer combination or a truck with a capacity of at least 26,001 pounds Gross Vehicle Weight (GVW). May be required to unload truck. Requires commercial drivers' license. Includes tow truck drivers.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'yz8Oku9fQfQ',srcOrHref:'https://youtu.be/yz8Oku9fQfQ'},
          id_gamification:"tdl-2",
          communicationbuttons:
              [ 
                {
                  title:"Quality Control",
                  innerText:"Check vehicles to ensure that mechanical, safety, and emergency equipment is in good working order."
                },
                {
                  title:"Organization",
                  innerText:"Maintain logs of working hours or of vehicle service or repair status, following applicable state and federal regulations."
                },
                {
                  title:"Detail Oriented",
                  innerText:"Check all load-related documentation for completeness and accuracy."
                },
                {
                  title:"Vehicle Operation",
                  innerText:"Maneuver trucks into loading or unloading positions, following signals from loading crew and checking that vehicle and loading equipment are properly positioned."
                },
                {
                  title:"Vehicle Maintenance",
                  innerText:"Perform basic vehicle maintenance tasks, such as adding oil, fuel, or radiator fluid or performing minor repairs."
                },
                {
                  title:"Critical Thinking",
                  innerText:"Plan or adjust routes based on changing conditions, using computer equipment, global positioning systems (GPS) equipment, or other navigation devices, to minimize fuel consumption and carbon emissions."
                },]
        },
        {
          jobtitle:'Diesel Mechanic',
          salary:"$29,650 - $63,150",
          description:"Diagnose, adjust, repair, or overhaul buses and trucks, or maintain and repair any type of diesel engines. Includes mechanics working primarily with automobile or marine diesel engines.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'-PcpkHT8xaU',srcOrHref:'https://youtu.be/-PcpkHT8xaU'},
          id_gamification:"tdl-3",
          communicationbuttons:
              [ 
                {
                  title:"Vehicle Operation",
                  innerText:"Test drive trucks and buses to diagnose malfunctions or to ensure that they are working properly."
                },
                {
                  title:"Engine Repair",
                  innerText:"Recondition and replace parts, pistons, bearings, gears, and valves."
                },
                {
                  title:"Vehicle Maintenance",
                  innerText:"Perform routine maintenance such as changing oil, checking batteries, and lubricating equipment and machinery."
                },
                {
                  title:"Critical Thinking",
                  innerText:"Inspect, test, and listen to defective equipment to diagnose malfunctions, using test instruments such as handheld computers, motor analyzers, chassis charts, or pressure gauges."
                },
                {
                  title:"Measure",
                  innerText:"Measure vehicle emissions to determine whether they are within acceptable limits."
                },
                {
                  title:"Electrical",
                  innerText:"Rewire ignition systems, lights, and instrument panels."
                },]
        },
        {
          jobtitle:"Dispatchers",
          salary:"$21,960 - $57,990",
          description:"Schedule and dispatch workers, work crews, equipment, or service vehicles for conveyance of materials, freight, or passengers, or for normal installation, service, or emergency repairs rendered outside the place of business. Duties may include using radio, telephone, or computer to transmit assignments and compiling statistics and reports on work progress.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'oq_BL4rJIDs',srcOrHref:'https://youtu.be/oq_BL4rJIDs'},
          id_gamification:"tdl-4",
          communicationbuttons:
              [ 
                {
                  title:"Monitoring",
                  innerText:"Monitor personnel or equipment locations and utilization to coordinate service and schedules."
                },
                {
                  title:"Organization",
                  innerText:"Record and maintain files or records of customer requests, work or services performed, charges, expenses, inventory, or other dispatch information."
                },
                {
                  title:"Inventory",
                  innerText:"Order supplies or equipment and issue them to personnel."
                },
                {
                  title:"Instructing",
                  innerText:"Advise personnel about traffic problems, such as construction areas, accidents, congestion, weather conditions, or other hazards."
                },
                {
                  title:"Communicate",
                  innerText:"Relay work orders, messages, or information to or from work crews, supervisors, or field inspectors, using telephones or two-way radios."
                },
                {
                  title:"Quality Control",
                  innerText:"Ensure timely and efficient movement of trains, according to train orders and schedules."
                },]
        },
        {
          jobtitle:'Transportation Inspectors',
          salary:"$52,070 - $113,410",
          description:"Inspect equipment or goods in connection with the safe transport of cargo or people. Includes rail transportation inspectors, such as freight inspectors, rail inspectors, and other inspectors of transportation vehicles not elsewhere classified.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'-RK1MvfADJU',srcOrHref:'https://youtu.be/-RK1MvfADJU'},
          id_gamification:"tdl-5",
          communicationbuttons:
              [ 
                {
                  title:"Data Entry",
                  innerText:"Record details about freight conditions, handling of freight, and any problems encountered."
                },
                {
                  title:"Instructing",
                  innerText:"Advise crews in techniques of stowing dangerous and heavy cargo"
                },
                {
                  title:"Quality Control",
                  innerText:"Visually inspect cargo for damage upon arrival or discharge."
                },
                {
                  title:"Analysis",
                  innerText:"Read draft markings to determine depths of vessels in water."
                },
                {
                  title:"Writing",
                  innerText:"Write certificates of admeasurement that list details such as designs, lengths, depths, and breadths of vessels, and methods of propulsion."
                },
                {
                  title:"Communicate",
                  innerText:"Notify workers of any special treatment required for shipments"
                },]
        },
        {
          jobtitle:'Stock & Material Movers',
          salary:" $18,840 - $40,230",
          description:"Manually move freight, stock, luggage, or other materials, or perform other general labor. Includes all manual laborers not elsewhere classified", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'wQr_uQMpmEM',srcOrHref:'https://youtu.be/wQr_uQMpmEM'},
          id_gamification:"tdl-6",
          communicationbuttons:
              [ 
                {
                  title:"Physical Fitness",
                  innerText:"Shovel material, such as gravel, ice, or spilled concrete, into containers or bins or onto conveyors."
                },
                {
                  title:"Operate Machinery",
                  innerText:"Adjust controls to guide, position, or move equipment, such as cranes, booms, or cameras."
                },
                {
                  title:"Electrical",
                  innerText:"Connect electrical equipment to power sources so that it can be tested before use."
                },
                {
                  title:"Detail Oriented",
                  innerText:"Attach identifying tags to containers or mark them with identifying information."
                },
                {
                  title:"Writing",
                  innerText:"Record numbers of units handled or moved, using daily production sheets or work tickets."
                },
                {
                  title:"Hand Tools",
                  innerText:"Rig or dismantle props or equipment, such as frames, scaffolding, platforms, or backdrops, using hand tools."
                },]
        },
      ]

    },
   
   
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var WAW_Quiz_Object={ 
    agriculture:{
        mediaInfo:{
          videoType:'youtube',link:"https://youtu.be/MBYHvraw-Mg",videoId:'MBYHvraw-Mg',imageOrVideo:'image',srcOrHref:'agriculture-careers'
        },
        jobs:[
          {
            jobtitle:'Agronomist',
            salary:"$32,000 - $84,000",
            description:"Conduct research in breeding, physiology, production, yield, and management of crops and agricultural plants or trees, shrubs, and nursery stock, their growth in soils, and control of pests; or study the chemical, physical, biological, and mineralogical composition of soils as they relate to plant or crop growth. May classify and map soils and investigate effects of alternative practices on soil and crop productivity.", 
            jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'cWbaZEIe7GQ',srcOrHref:'https://youtu.be/cWbaZEIe7GQ'},
            id_gamification:"ag-1",
            communicationbuttons:
                [ 
                  {
                    title:"Communicate",
                    innerText:"Communicate research or project results to other professionals or the public or teach related courses, seminars, or workshops."
                  },
                  {
                    title:"Critical Thinking",
                    innerText:"Investigate soil problems or poor water quality to determine sources and effects."
                  },
                  {
                    title:"Experiment",
                    innerText:"Conduct experiments investigating how soil forms, changes, or interacts with land-based ecosystems or living organisms."
                  },
                  {
                    title:"Teamwork",
                    innerText:"Consult with engineers or other technical personnel working on construction projects about the effects of soil problems and possible solutions to these problems."
                  },
                  {
                    title:"Analyze",
                    innerText:"Perform chemical analyses of the microorganism content of soils to determine microbial reactions or chemical mineralogical relationships to plant growth."
                  },
                  {
                    title:"Active Learning",
                    innerText:"Study ways to improve agricultural sustainability, such as the use of new methods of composting."
                  },
          ]
          },
          {
            jobtitle:'Zoologists and Wildlife Biologists',
            salary:"$39,800 - $84,500",
            description:"Study the origins, behavior, diseases, genetics, and life processes of animals and wildlife. May specialize in wildlife research and management. May collect and analyze biological data to determine the environmental effects of present and potential use of land and water habitats.", 
            jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'cu6BuYyI6SQ',srcOrHref:'https://youtu.be/cu6BuYyI6SQ'},
            id_gamification:"ag-2",
            communicationbuttons:
                [ 
                  {
                    title:"Organization",
                    innerText:"Inventory or estimate plant and wildlife populations."
                  },
                  {
                    title:"Safety",
                    innerText:"Check for, and ensure compliance with, environmental laws, and notify law enforcement when violations are identified."
                  },
                  {
                    title:"Critical Analysis",
                    innerText:"Analyze characteristics of animals to identify and classify them."
                  },
                  {
                    title:"Management",
                    innerText:"Coordinate preventive programs to control the outbreak of wildlife diseases."
                  },
                  {
                    title:"Experiment",
                    innerText:"Organize and conduct experimental studies with live animals in controlled or natural surroundings."
                  },
                  {
                    title:"Observation",
                    innerText:"Study animals in their natural habitats, assessing effects of environment and industry on animals, interpreting findings and recommending alternative operating conditions for industry."
                  },]
          },
          {
            jobtitle:'Agricultural Engineer',
            salary:"$49,000 - $106,000",
            description:"Apply knowledge of engineering technology and biological science to agricultural problems concerned with power and machinery, electrification, structures, soil and water conservation, and processing of agricultural products.", 
            jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'ozIUJsnBDLY',srcOrHref:'https://youtu.be/ozIUJsnBDLY'},
            id_gamification:"ag-3",
            communicationbuttons:
                [ 
                  {
                    title:"Active Listening",
                    innerText:"Meet with clients, such as district or regional councils, farmers, and developers, to discuss their needs."
                  },
                  {
                    title:"Communicate",
                    innerText:"Discuss plans with clients, contractors, consultants, and other engineers so that they can be evaluated and necessary changes made."
                  },
                  {
                    title:"Instructing",
                    innerText:"Provide advice on water quality and issues related to pollution management, river control, and ground and surface water resources."
                  },
                  {
                    title:"Computer Literacy",
                    innerText:"Design agricultural machinery components and equipment, using computer-aided design (CAD) technology."
                  },
                  {
                    title:"Quality Control",
                    innerText:"Test agricultural machinery and equipment to ensure adequate performance."
                  },
                  {
                    title:"Systems Design",
                    innerText:"Design food processing plants and related mechanical systems."
                  },]
          },

        ]            
    },
    cosmetology:{
      mediaInfo:{
        videoType:'none',link:"cosmetology-careers",videoId:'',imageOrVideo:'image',srcOrHref:'cosmetology-careers'
      },
      jobs:[
        {
          jobtitle:'Hairstylist',
          salary:"$20,000 - $45,000",
          description:"Provide beauty services, such as cutting, coloring, and styling hair, and massaging and treating scalp. May shampoo hair, apply makeup, dress wigs, remove hair, and provide nail and skincare services.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'wqgol3sEFvI',srcOrHref:'https://youtu.be/wqgol3sEFvI'},
          id_gamification:"cosme-1",
          communicationbuttons:
              [ 
                {
                  title:"Cleanliness",
                  innerText:"Keep work stations clean and sanitize tools, such as scissors and combs."
                },
                {
                  title:"Sharp Tools",
                  innerText:"Cut, trim and shape hair or hairpieces, based on customers' instructions, hair type, and facial features, using clippers, scissors, trimmers and razors."
                },
                {
                  title:"Organized",
                  innerText:"Update and maintain customer information records, such as beauty services provided."
                },
                {
                  title:"Creativity",
                  innerText:"Develop new styles and techniques."
                },
                {
                  title:"Instructing",
                  innerText:"Train or supervise other hairstylists, hairdressers, and assistants."
                },
                {
                  title:"Technology",
                  innerText:"Operate cash registers to receive payments from patrons."
                },
        ]
        },
        {
          jobtitle:'Massage Therapist',
          salary:"$20,000 - $58,000",
          description:"Perform therapeutic massages of soft tissues and joints. May assist in the assessment of range of motion and muscle strength, or propose client therapy plans.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'kgg5Mw0TJJk',srcOrHref:'https://youtu.be/kgg5Mw0TJJk'},
          id_gamification:"cosme-2",
          communicationbuttons:
              [ 
                {
                  title:"Physical Strength",
                  innerText:"Massage and knead muscles and soft tissues of the body to provide treatment for medical conditions, injuries, or wellness maintenance."
                },
                {
                  title:"Communicate",
                  innerText:"Confer with clients about their medical histories and problems with stress or pain to determine how massage will be most helpful."
                },
                {
                  title:"Anatomy",
                  innerText:"Apply finger and hand pressure to specific points of the body."
                },
                {
                  title:"Organized",
                  innerText:"Maintain treatment records."
                },
                {
                  title:"Analysis",
                  innerText:"Assess clients' soft tissue condition, joint quality and function, muscle strength, and range of motion."
                },
                {
                  title:"Decision Making",
                  innerText:"Develop and propose client treatment plans that specify which types of massage are to be used."
                },]
        },
        {
          jobtitle:'Esthetician',
          salary:"$20,000 - $43,000",
          description:"Provide skincare treatments to face and body to enhance an individual's appearance. Includes electrologists and laser hair removal specialists.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'mFQ5Q7slO1M',srcOrHref:'https://youtu.be/mFQ5Q7slO1M'},
          id_gamification:"cosme-3",
          communicationbuttons:
              [ 
                {
                  title:"Cleanliness",
                  innerText:"Sterilize equipment and clean work areas."
                },
                {
                  title:"Analysis",
                  innerText:"Examine clients' skin, using magnifying lamps or visors when necessary, to evaluate skin condition and appearance."
                },
                {
                  title:"Instructing",
                  innerText:"Demonstrate how to clean and care for skin properly and recommend skin-care regimens."
                },
                {
                  title:"Active Learning",
                  innerText:"Stay abreast of latest industry trends, products, research, and treatments."
                },
                {
                  title:"Organized",
                  innerText:"Keep records of client needs and preferences and the services provided."
                },
                {
                  title:"Communicate",
                  innerText:"Collaborate with plastic surgeons and dermatologists to provide patients with preoperative and postoperative skin care."
                },]
        },

      ]  
         
    },
    construction:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/FknPHp4mMNQ",videoId:'FknPHp4mMNQ',imageOrVideo:'video',srcOrHref:'https://youtu.be/FknPHp4mMNQ'
      },
      jobs:[
        {
          jobtitle:'Carpenters',
          salary:"$28,000 - $80,000",
          description:"Construct, erect, install, or repair structures and fixtures made of wood and comparable materials, such as concrete forms; building frameworks, including partitions, joists, studding, and rafters; and wood stairways, window and door frames, and hardwood floors. May also install cabinets, siding, drywall, and batt or roll insulation. Includes brattice builders who build doors or brattices (ventilation walls or partitions) in underground passageways.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'i-tiC2Y-038',srcOrHref:'https://youtu.be/i-tiC2Y-038'},
          education:"",
          id_gamification:"construc-1",
          communicationbuttons:
              [ 
                {
                  title:"Safety",
                  innerText:"Follow established safety rules and regulations and maintain a safe and clean environment."
                },
                {
                  title:"Analysis",
                  innerText:"Study specifications in blueprints, sketches, or building plans to prepare project layout and determine dimensions and materials required."
                },
                {
                  title:"Measuring",
                  innerText:"Measure and mark cutting lines on materials, using a ruler, pencil, chalk, and marking gauge."
                },
                {
                  title:"Hand Tools",
                  innerText:"Shape or cut materials to specified measurements, using hand tools, machines, or power saws."
                },
                {
                  title:"Quality Control",
                  innerText:"Verify trueness of structure, using plumb bob and level."
                },
                {
                  title:"Organization",
                  innerText:"Maintain records, document actions, and present written progress reports."
                },]
        },
        {
          jobtitle:'Electrician',
          salary:"$32,000 - $93,000",
          description:"Install, maintain, and repair electrical wiring, equipment, and fixtures. Ensure that work is in accordance with relevant codes. May install or service street lights, intercom systems, or electrical control systems.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'Rw8gAGYZ6qs',srcOrHref:'https://youtu.be/mCGHJjpiOc4'},
          education:"",
          id_gamification:"construc-2",
          communicationbuttons:
              [ 
                {
                  title:"Critical Analysis",
                  innerText:"Plan layout and installation of electrical wiring, equipment, or fixtures, based on job specifications and local codes."
                },
                {
                  title:"Design",
                  innerText:"Prepare sketches or follow blueprints to determine the location of wiring or equipment and to ensure conformance to building and safety codes."
                },
                {
                  title:"Troubleshoot",
                  innerText:"Diagnose malfunctioning systems, apparatus, or components, using test equipment and hand tools to locate the cause of a breakdown and correct the problem."
                },
                {
                  title:"Instructing",
                  innerText:"Advise management on whether continued operation of equipment could be hazardous."
                },
                {
                  title:"Quality Control",
                  innerText:"Inspect electrical systems, equipment, or components to identify hazards, defects, or the need for adjustment or repair, and to ensure compliance with codes."
                },
                {
                  title:"Hand Tools",
                  innerText:"Repair or replace wiring, equipment, or fixtures, using hand tools or power tools."
                },]
        },
        {
          jobtitle:'HVAC Mechanics',
          salary:"$29,000 - $75,000",
          description:"Install or repair heating, central air conditioning, HVAC, or refrigeration systems, including oil burners, hot-air furnaces, and heating stoves.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'PNcQwekI2KQ',srcOrHref:'https://youtu.be/PNcQwekI2KQ'},
          education:"",
          id_gamification:"construc-3",
          communicationbuttons:
              [ 
                {
                  title:"Electrical",
                  innerText:"Test electrical circuits or components for continuity, using electrical test equipment."
                },
                {
                  title:"Repair",
                  innerText:"Repair or replace defective equipment, components, or wiring."
                },
                {
                  title:"Customer Service",
                  innerText:"Discuss heating or cooling system malfunctions with users to isolate problems or to verify that repairs corrected malfunctions."
                },
                {
                  title:"Safety",
                  innerText:"Comply with all applicable standards, policies, or procedures, such as safety procedures or the maintenance of a clean work area."
                },
                {
                  title:"Organization",
                  innerText:"Keep records of repairs and replacements made and causes of malfunctions."
                },
                {
                  title:"Quality Control",
                  innerText:"Inspect and test systems to verify system compliance with plans and specifications or to detect and locate malfunctions."
                },]
        },
      ]
         
    },
    education:{
  
      mediaInfo:{
        videoType:'none',link:"education-careers",videoId:'',imageOrVideo:'image',srcOrHref:'education-careers'
      },
      jobs:[
        {
          jobtitle:'Elementary School Teachers',
          salary:"$39,488 - $58,000",
          description:"Teach academic and social skills to students at the elementary school level.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'VRB818Z8Xhs',srcOrHref:'https://youtu.be/VRB818Z8Xhs'},
          education:"",
          id_gamification:"edu-1",
          communicationbuttons:
              [ 
                {
                  title:"Enforce Rules",
                  innerText:"Establish and enforce rules for behavior and procedures for maintaining order among the students for whom they are responsible."
                },
                {
                  title:"Learning Strategy",
                  innerText:"Adapt teaching methods and instructional materials to meet students' varying needs and interests."
                },
                {
                  title:"Communicate",
                  innerText:"Confer with parents or guardians, teachers, counselors, and administrators to resolve students' behavioral and academic problems."
                },
                {
                  title:"Critical Thinking",
                  innerText:"Establish clear objectives for all lessons, units, and projects and communicate those objectives to students."
                },
                {
                  title:"Monitoring",
                  innerText:"Observe and evaluate students' performance, behavior, social development, and physical health."
                },
                {
                  title:"Organization",
                  innerText:"Maintain accurate and complete student records as required by laws, district policies, and administrative regulations."
                },]
        },
        {
          jobtitle:'Psychologist',
          salary:"$39,488 - $85,000",
          description:"Diagnose and implement individual or schoolwide interventions or strategies to address educational, behavioral, or developmental issues that adversely impact educational functioning in a school. May address student learning and behavioral problems and counsel students or families. May design and implement performance plans, and evaluate performance. May consult with other school-based personnel.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'AarAP2ur7gQ',srcOrHref:'https://youtu.be/AarAP2ur7gQ'},
          education:"",
          id_gamification:"edu-2",
          communicationbuttons:
              [ 
                {
                  title:"Organized",
                  innerText:"Maintain student records, including special education reports, confidential records, records of services provided, and behavioral data."
                },
                {
                  title:"Responsible",
                  innerText:"Report any pertinent information to the proper authorities in cases of child endangerment, neglect, or abuse."
                },
                {
                  title:"Analysis",
                  innerText:"Interpret test results and prepare psychological reports for teachers, administrators, and parents."
                },
                {
                  title:"Active Listening",
                  innerText:"Counsel children and families to help solve conflicts and problems in learning and adjustment."
                },
                {
                  title:"Collaborate",
                  innerText:"Collaborate with other educational professionals to develop teaching strategies and school programs."
                },
                {
                  title:"Active Learning",
                  innerText:"Attend workshops, seminars, or professional meetings to remain informed of new developments in school psychology."
                },]
        },
        {
          jobtitle:'Guidance Counselor',
          salary:"$34,370 - $73,210",
          description:"Advise and assist students and provide educational and vocational guidance services.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'aPtHL33Velc',srcOrHref:'https://youtu.be/aPtHL33Velc'},
          education:"",
          id_gamification:"edu-3",
          communicationbuttons:
              [ 
                {
                  title:"Socially Perceptive",
                  innerText:"Identify cases of domestic abuse or other family problems and encourage students or parents to seek additional assistance from mental health professionals."
                },
                {
                  title:"Organization",
                  innerText:"Maintain accurate and complete student records as required by laws, district policies, and administrative regulations."
                },
                {
                  title:"Instruction",
                  innerText:"Teach classes and present self-help or information sessions on subjects related to education and career planning."
                },
                {
                  title:"Monitoring",
                  innerText:"Observe students during classroom and play activities to evaluate students' performance, behavior, social development, and physical health."
                },
                {
                  title:"Teamwork",
                  innerText:"Collaborate with teachers and administrators in the development, evaluation, and revision of school programs and in the preparation of master schedules for curriculum offerings."
                },
                {
                  title:"Program Planning",
                  innerText:"Plan and promote career and employment-related programs and events, such as career planning presentations, work experience programs, job fairs, and career workshops."
                },]
        },
      ]
      
         
    },
    energy_and_utilities:{
  
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/En9Bjtvq-48",videoId:'En9Bjtvq-48',imageOrVideo:'video',srcOrHref:'https://youtu.be/En9Bjtvq-48'
      },
      jobs:[
        {
          jobtitle:'Water Treatment Plant Operator',
          salary:"$45,000 - $91,000",
          description:"Operate or control an entire process or system of machines, often through the use of control boards, to transfer or treat water or wastewater.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'pABK8Di1Y5Q',srcOrHref:'https://youtu.be/pABK8Di1Y5Q'},
          education:"",
          id_gamification:"eau-1",
          communicationbuttons:
              [ 
                {
                  title:"Maintenance",
                  innerText:"Add chemicals, such as ammonia, chlorine, or lime, to disinfect and deodorize water and other liquids."
                },
                {
                  title:"Analysis",
                  innerText:"Collect and test water and sewage samples, using test equipment and color analysis standards."
                },
                {
                  title:"Organized",
                  innerText:"Record operational data, personnel attendance, or meter and gauge readings on specified forms."
                },
                {
                  title:"Equipment Operation",
                  innerText:"Operate and adjust controls on equipment to purify and clarify water, process or dispose of sewage, and generate power."
                },
                {
                  title:"Quality Control",
                  innerText:"Inspect equipment or monitor operating conditions, meters, and gauges to determine load requirements and detect malfunctions."
                },
                {
                  title:"Cleanliness",
                  innerText:"Clean and maintain tanks, filter beds, and other work areas, using hand tools and power tools."
                },]
        },
        {
          jobtitle:'Line Installation & Maintenance',
          salary:"$52,000 - $105,000",
          description:"Install or repair cables or wires used in electrical power or distribution systems. May erect poles and light or heavy duty transmission towers.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'qJbC9pYtLK4',srcOrHref:'https://youtu.be/qJbC9pYtLK4'},
          education:"",
          id_gamification:"eau-2",
          communicationbuttons:
              [ 
                {
                  title:"Safety",
                  innerText:"Adhere to safety practices and procedures, such as checking equipment regularly and erecting barriers around work areas."
                },
                {
                  title:"Quality Control",
                  innerText:"Test conductors, according to electrical diagrams and specifications, to identify corresponding conductors and to prevent incorrect connections."
                },
                {
                  title:"Repair",
                  innerText:"Open switches or attach grounding devices to remove electrical hazards from disturbed or fallen lines or to facilitate repairs."
                },
                {
                  title:"Vehicle Operation",
                  innerText:"Drive vehicles equipped with tools and materials to job sites."
                },
                {
                  title:"Operate Machinery",
                  innerText:"Dig holes, using augers, and set poles, using cranes and power equipment."
                },
                {
                  title:"Troubleshoot",
                  innerText:"Inspect and test power lines and auxiliary equipment to locate and identify problems, using reading and testing instruments."
                },]
        },
        {
          jobtitle:'Refinery Operator',
          salary:"$50,000 - $75,000",
          description:"Operate or control petroleum refining or processing units. May specialize in controlling manifold and pumping systems, gauging or testing oil in storage tanks, or regulating the flow of oil into pipelines.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'wKCFFwuItmE',srcOrHref:'https://youtu.be/uSZVN7JaJmQ'},
          education:"",
          id_gamification:"eau-3",
          communicationbuttons:
              [ 
                {
                  title:"Monitoring",
                  innerText:"Monitor process indicators, instruments, gauges, and meters to detect and report any possible problems."
                },
                {
                  title:"Equipment Operation",
                  innerText:"Control or operate manifold and pumping systems to circulate liquids through a petroleum refinery."
                },
                {
                  title:"Communicate",
                  innerText:"Signal other workers by telephone or radio to operate pumps, open and close valves, and check temperatures."
                },
                {
                  title:"Quality Control",
                  innerText:"Verify that incoming and outgoing products are moving through the correct meters, and that meters are working properly."
                },
                {
                  title:"Troubleshoot",
                  innerText:"Maintain and repair equipment, or report malfunctioning equipment to supervisors so that repairs can be scheduled."
                },
                {
                  title:"Analysis",
                  innerText:"Record and compile operating data, instrument readings, documentation, and results of laboratory analyses."
                },]
        },
      ]
      
         
    },
  
    engineering_and_environment:{
  
         
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/veg8CcB-xnI",videoId:'veg8CcB-xnI',imageOrVideo:'video',srcOrHref:'https://youtu.be/veg8CcB-xnI'
      },
      jobs:[
        {
          jobtitle:'Civil Engineers',
          salary:"$48,000 - $87,000",
          description:"Perform engineering duties in planning, designing, and overseeing construction and maintenance of building structures and facilities, such as roads, railroads, airports, bridges, harbors, channels, dams, irrigation projects, pipelines, power plants, and water and sewage systems.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'-XIQSrYi0O0',srcOrHref:'https://youtu.be/-XIQSrYi0O0'},
          education:"",
          id_gamification:"eae-1",
          communicationbuttons:
              [ 
                {
                  title:"Monitoring",
                  innerText:"Inspect project sites to monitor progress and ensure conformance to design specifications and safety or sanitation standards."
                },
                {
                  title:"Instructing",
                  innerText:"Provide technical advice to industrial or managerial personnel regarding design, construction, program modifications, or structural repairs."
                },
                {
                  title:"Critical Analysis",
                  innerText:"Test soils or materials to determine the adequacy and strength of foundations, concrete, asphalt, or steel."
                },
                {
                  title:"Management",
                  innerText:"Manage and direct the construction, operations, or maintenance activities at project site."
                },
                {
                  title:"Design",
                  innerText:"Design energy-efficient or environmentally sound civil structures."
                },
                {
                  title:"Computer Literacy",
                  innerText:"Plan and design transportation or hydraulic systems or structures, using computer-assisted design or drawing tools."
                },]
        },
        {
          jobtitle:'Mechanical Engineers',
          salary:"$65,000 - $100,000",
          description:"Perform engineering duties in planning and designing tools, engines, machines, and other mechanically functioning equipment. Oversee installation, operation, maintenance, and repair of equipment such as centralized heat, gas, water, and steam systems.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'bDg2UcaZzcg',srcOrHref:'https://youtu.be/bDg2UcaZzcg'},
          education:"",
          id_gamification:"eae-2",
          communicationbuttons:
              [ 
                {
                  title:"Analysis",
                  innerText:"Read and interpret blueprints, technical drawings, schematics, or computer-generated reports."
                },
                {
                  title:"Teamwork",
                  innerText:"Confer with engineers or other personnel to implement operating procedures, resolve system malfunctions, or provide technical information."
                },
                {
                  title:"Project Management",
                  innerText:"Develop, coordinate, or monitor all aspects of production, including selection of manufacturing methods, fabrication, or operation of product designs."
                },
                {
                  title:"Quality Control",
                  innerText:"Recommend design modifications to eliminate machine or system malfunctions."
                },
                {
                  title:"Systems Design",
                  innerText:"Design integrated mechanical or alternative systems, such as mechanical cooling systems with natural ventilation systems, to improve energy efficiency."
                },
                {
                  title:"Critical Analysis",
                  innerText:"Conduct research that tests or analyzes the feasibility, design, operation, or performance of equipment, components, or systems."
                },]
        },
        {
          jobtitle:'Aerospace Engineers',
          salary:"$47,000 – $91,000",
          description:"Operate, install, adjust, and maintain integrated computer/communications systems, consoles, simulators, and other data acquisition, test, and measurement instruments and equipment, which are used to launch, track, position, and evaluate air and space vehicles. May record and interpret test data.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'1eOnm-61cDE',srcOrHref:'https://youtu.be/1eOnm-61cDE'},
          education:"",
          id_gamification:"eae-3",
          communicationbuttons:
              [ 
                {
                  title:"Quality Control",
                  innerText:"Test aircraft systems under simulated operational conditions, performing systems readiness tests."
                },
                {
                  title:"Analysis",
                  innerText:"Identify required data, data acquisition plans, and test parameters, setting up equipment to conform to these specifications."
                },
                {
                  title:"Teamwork",
                  innerText:"Confer with engineering personnel regarding details and implications of test procedures and results."
                },
                {
                  title:"Maintenance",
                  innerText:"Adjust, repair, or replace faulty components of test setups and equipment."
                },
                {
                  title:"Fabricate",
                  innerText:"Fabricate and install parts and systems to be tested in test equipment, using hand tools, power tools, and test instruments."
                },
                {
                  title:"Systems Design",
                  innerText:"Design electrical and mechanical systems for avionic instrumentation applications."
                },]
        },
      ]
      
    },
    healthcare:{
  
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/qm6c7KDIQq0",videoId:'qm6c7KDIQq0',imageOrVideo:'video',srcOrHref:'https://youtu.be/qm6c7KDIQq0'
      },
      jobs:[
        {
          jobtitle:'Dental Hygienist',
          salary:"$42,000 - $90,000",
          description:"Administer oral hygiene care to patients. Assess patient oral hygiene problems or needs and maintain health records. Advise patients on oral health maintenance and disease prevention. May provide advanced care such as providing fluoride treatment or administering topical anesthesia.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'TeAtDfCQ0J4',srcOrHref:'https://youtu.be/uSZVN7JaJmQ'},
          education:"",
          id_gamification:"hc-4",
          communicationbuttons:
              [ 
                {
                  title:"Cleanliness",
                  innerText:"Clean calcareous deposits, accretions, and stains from teeth and beneath margins of gums, using dental instruments."
                },
                {
                  title:"Data Entry",
                  innerText:"Record and review patient medical histories."
                },
                {
                  title:"Analysis",
                  innerText:"Examine gums, using probes, to locate periodontal recessed gums and signs of gum disease."
                },
                {
                  title:"Instructing",
                  innerText:"Conduct dental health clinics for community groups to augment services of dentist."
                },
                {
                  title:"Customer Service",
                  innerText:"Provide clinical services or health education to improve and maintain the oral health of patients or the general public."
                },
                {
                  title:"Maintenance",
                  innerText:"Maintain dental equipment and sharpen and sterilize dental instruments."
                },]
        },
        {
          jobtitle:'Registered Nurse',
          salary:"$45,000 - $74,000",
          description:"Assess patient health problems and needs, develop and implement nursing care plans, and maintain medical records. Administer nursing care to ill, injured, convalescent, or disabled patients. May advise patients on health maintenance and disease prevention or provide case management. Licensing or registration required.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'x4Y_2fpET0U',srcOrHref:'https://youtu.be/x4Y_2fpET0U'},
          education:"",
          id_gamification:"hc-5",
          communicationbuttons:
              [ 
                {
                  title:"Communicate",
                  innerText:"Consult and coordinate with healthcare team members to assess, plan, implement, or evaluate patient care plans."
                },
                {
                  title:"Social Perceptiveness",
                  innerText:"Assess the needs of individuals, families, or communities, including assessment of individuals' home or work environments, to identify potential health or safety problems."
                },
                {
                  title:"Monitoring",
                  innerText:"Monitor, record, and report symptoms or changes in patients' conditions."
                },
                {
                  title:"Service-Oriented",
                  innerText:"Assess the needs of individuals, families, or communities, including assessment of individuals' home or work environments, to identify potential health or safety problems."
                },
                {
                  title:"Instructing",
                  innerText:"Instruct individuals, families, or other groups on topics such as health education, disease prevention, or childbirth and develop health improvement programs."
                },
                {
                  title:"Detail Oriented",
                  innerText:"Administer medications to patients and monitor patients for reactions or side effects."
                },]
        },
        {
          jobtitle:'Paramedics',
          salary:"$30,000 - $52,000",
          description:"Administer basic or advanced emergency medical care and assess injuries and illnesses. May administer medication intravenously, use equipment such as EKGs, or administer advanced life support to sick or injured individuals.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'C8zIHk9KKqI',srcOrHref:'https://youtu.be/C8zIHk9KKqI'},
          education:"",
          id_gamification:"hc-6",
          communicationbuttons:
              [ 
                {
                  title:"Analysis",
                  innerText:"Assess nature and extent of illness or injury to establish and prioritize medical procedures."
                },
                {
                  title:"Active Learning",
                  innerText:"Attend training classes to maintain certification licensure, keep abreast of new developments in the field, or maintain existing knowledge."
                },
                {
                  title:"Persuasion",
                  innerText:"Comfort and reassure patients."
                },
                {
                  title:"Teamwork",
                  innerText:"Coordinate work with other emergency medical team members or police or fire department personnel."
                },
                {
                  title:"Cleanliness",
                  innerText:"Decontaminate ambulance interior following treatment of patient with infectious disease and report case to proper authorities."
                },
                {
                  title:"Medical Procedure",
                  innerText:"Perform emergency diagnostic and treatment procedures, such as stomach suction, airway management, or heart monitoring, during ambulance ride."
                },]
        },
      ]
      
         
    },
    hospitality_and_tourism:{
  
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/j5IJHSpu31w",videoId:'j5IJHSpu31w',imageOrVideo:'video',srcOrHref:'https://youtu.be/j5IJHSpu31w'
      },
      jobs:[
        {
          jobtitle:'Food Service Managers',
          salary:"$42,240 - $95,400",
          description:"Plan, direct, or coordinate activities of an organization or department that serves food and beverages.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'OzTbTaVPM2w',srcOrHref:'https://youtu.be/OzTbTaVPM2w'},
          education:"",
          id_gamification:"hat-1",
          communicationbuttons:
              [ 
                {
                  title:"Scheduling",
                  innerText:"Schedule and receive food and beverage deliveries, checking delivery contents to verify product quality and quantity."
                },
                {
                  title:"Quality Control",
                  innerText:"Test cooked food by tasting and smelling it to ensure palatability and flavor conformity."
                },
                {
                  title:"Organization",
                  innerText:"Plan menus and food utilization, based on anticipated number of guests, nutritional value, palatability, popularity, and costs."
                },
                {
                  title:"Critical Thinking",
                  innerText:"Review work procedures and operational problems to determine ways to improve service, performance, or safety."
                },
                {
                  title:"Customer Service",
                  innerText:"Greet guests, escort them to their seats, and present them with menus and wine lists."
                },
                {
                  title:"Management",
                  innerText:"Schedule staff hours and assign duties."
                },]
        },
        {
          jobtitle:'Chefs/Head Cooks',
          salary:"$42,240 - $95,400",
          description:"Direct and may participate in the preparation, seasoning, and cooking of salads, soups, fish, meats, vegetables, desserts, or other foods. May plan and price menu items, order supplies, and keep records and accounts.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'BtAevVMysC0',srcOrHref:'https://youtu.be/BtAevVMysC0'},
          education:"",
          id_gamification:"hat-2",
          communicationbuttons:
              [ 
                {
                  title:"Quality Control",
                  innerText:"Check the quality of raw or cooked food products to ensure that standards are met."
                },
                {
                  title:"Management",
                  innerText:"Supervise or coordinate activities of cooks or workers engaged in food preparation."
                },
                {
                  title:"Creativity",
                  innerText:"Determine how food should be presented and create decorative food displays."
                },
                {
                  title:"Critical Analysis",
                  innerText:"Analyze recipes to assign prices to menu items, based on food, labor, and overhead costs."
                },
                {
                  title:"Communicate",
                  innerText:"Meet with customers to discuss menus for special occasions, such as weddings, parties, or banquets."
                },
                {
                  title:"Instruction",
                  innerText:"Demonstrate new cooking techniques or equipment to staff."
                },]
        },
        {
          jobtitle:'Park Ranger',
          salary:"$38,000 - $95,000",
          description:"Plan, develop, and conduct programs to inform public of historical, natural, and scientific features of national, state, or local park.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'zBGnuQurqOU',srcOrHref:'https://youtu.be/zBGnuQurqOU'},
          education:"",
          id_gamification:"hat-3",
          communicationbuttons:
              [ 
                {
                  title:"Guest Relations",
                  innerText:"Conduct field trips to point out scientific, historic, and natural features of parks, forests, historic sites, or other attractions."
                },
                {
                  title:"Instructing",
                  innerText:"Prepare and present illustrated lectures and interpretive talks about park features."
                },
                {
                  title:"Organize",
                  innerText:"Plan and organize public events at the park."
                },
                {
                  title:"Program",
                  innerText:"Develop environmental educational programs and curricula for schools."
                },
                {
                  title:"",
                  innerText:""
                },
                {
                  title:"",
                  innerText:""
                },]
        },
      ]
      
         
    },
    manufacturing_auto_logistics:{
  
      mediaInfo:{
        videoType:'youtube',videoUrl:"https://vimeo.com/465527258",videoId:'nn4P4_VLWoc',imageOrVideo:'video',srcOrHref:'https://vimeo.com/465527258'
      },
      jobs:[
        {
          jobtitle:'CNC Operator',
          salary:"$28,440 - $53,050",
          description:"Operate computer-controlled tools, machines, or robots to machine or process parts, tools, or other work pieces made of metal, plastic, wood, stone, or other materials. May also set up and maintain equipment.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'176-HGCTLqE',srcOrHref:'https://youtu.be/176-HGCTLqE'},
          education:"",
          id_gamification:"mal-1",
          communicationbuttons:
              [ 
                {
                  title:"Quality Control",
                  innerText:"Measure dimensions of finished workpieces to ensure conformance to specifications, using precision measuring instruments, templates, and fixtures."
                },
                {
                  title:"Computers",
                  innerText:"Transfer commands from servers to computer numerical control (CNC) modules, using computer network links."
                },
                {
                  title:"Maintenance",
                  innerText:"Remove and replace dull cutting tools."
                },
                {
                  title:"Problem Solving",
                  innerText:"Modify cutting programs to account for problems encountered during operation, and save modified programs."
                },
                {
                  title:"Cleanliness",
                  innerText:"Clean machines, tooling, or parts, using solvents or solutions and rags."
                },
                {
                  title:"Communicate",
                  innerText:"Confer with supervisors or programmers to resolve machine malfunctions or production errors or to obtain approval to continue production."
                },]
        },
        {
          jobtitle:'Industrial Machinery Mechanic',
          salary:"$32,630 - $74,850",
          description:"Repair, install, adjust, or maintain industrial production and processing machinery or refinery and pipeline distribution systems. May also install, dismantle, or move machinery and heavy equipment according to plans.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'ROJQXBuEnEY',srcOrHref:'https://youtu.be/ROJQXBuEnEY'},
          education:"",
          id_gamification:"mal-2",
          communicationbuttons:
              [ 
                {
                  title:"Maintenance",
                  innerText:"Repair or maintain the operating condition of industrial production or processing machinery or equipment."
                },
                {
                  title:"Quality Control",
                  innerText:"Examine parts for defects, such as breakage or excessive wear."
                },
                {
                  title:"Analysis",
                  innerText:"Study blueprints or manufacturers' manuals to determine correct installation or operation of machinery."
                },
                {
                  title:"Instructing",
                  innerText:"Demonstrate equipment functions and features to machine operators."
                },
                {
                  title:"Welding",
                  innerText:"Cut and weld metal to repair broken metal parts, fabricate new parts, or assemble new equipment."
                },
                {
                  title:"Inventory",
                  innerText:"Record parts or materials used and order or requisition new parts or materials, as necessary."
                },]
        },
        {
          jobtitle:'Welders',
          salary:"$27,290 - $57,660",
          description:"Use hand-welding, flame-cutting, hand-soldering, or brazing equipment to weld or join metal components or to fill holes, indentations, or seams of fabricated metal products.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'rzTzmkoSLg4',srcOrHref:''},
          education:"",
          id_gamification:"mal-3",
          communicationbuttons:
              [ 
                {
                  title:"Welding",
                  innerText:"Weld components in flat, vertical, or overhead positions."
                },
                {
                  title:"Safety",
                  innerText:"Operate safety equipment and use safe work habits."
                },
                {
                  title:"Quality Control",
                  innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
                },
                {
                  title:"Hand Tools",
                  innerText:"Position and secure workpieces, using hoists, cranes, wire, and banding machines or hand tools."
                },
                {
                  title:"Analysis",
                  innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
                },
                {
                  title:"Design",
                  innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
                },]
        },
      ]
      
         
    },
    public_safety:{
  
      mediaInfo:{
        videoType:'none',link:"public-safety-careers",videoId:'',imageOrVideo:'image',srcOrHref:'public-safety-careers'
      },
      jobs:[
        {
          jobtitle:'Police Officers',
          salary:"$42,500 - $60,500",
          description:"Maintain order and protect life and property by enforcing local, tribal, state, or federal laws and ordinances. Perform a combination of the following duties: patrol a specific area; direct traffic; issue traffic summonses; investigate accidents; apprehend and arrest suspects, or serve legal processes of courts. Includes police officers working at educational institutions.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'14D91NPvMz8',srcOrHref:'https://youtu.be/14D91NPvMz8'},
          education:"",
          id_gamification:"pub_saf-1",
          communicationbuttons:
              [ 
                {
                  title:"Apprehend Suspects",
                  innerText:"Identify, pursue, and arrest suspects and perpetrators of criminal acts."
                },
                {
                  title:"Public Safety",
                  innerText:"Provide for public safety by maintaining order, responding to emergencies, protecting people and property, enforcing motor vehicle and criminal laws, and promoting good community relations."
                },
                {
                  title:"Active Listening",
                  innerText:"Record facts to prepare reports that document incidents and activities."
                },
                {
                  title:"First Aid",
                  innerText:"Render aid to accident victims and other persons requiring first aid for physical injuries."
                },
                {
                  title:"Investigate",
                  innerText:"Investigate illegal or suspicious activities."
                },
                {
                  title:"Weapons & Tools",
                  innerText:"Use handguns, handcuffs, police vehicles, surveillance video and audio recorders, and two-way radios."
                },]
        },
        {
          jobtitle:'Firefighters',
          salary:"$42,500 - $60,500",
          description:"Control and extinguish fires or respond to emergency situations where life, property, or the environment is at risk. Duties may include fire prevention, emergency medical service, hazardous material response, search and rescue, and disaster assistance.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'DNK5dYGn3Fg',srcOrHref:'https://youtu.be/DNK5dYGn3Fg'},
          education:"",
          id_gamification:"pub_saf-2",
          communicationbuttons:
              [ 
                {
                  title:"Critical Thinking",
                  innerText:"Assess fires and situations and report conditions to superiors to receive instructions, using two-way radios."
                },
                {
                  title:"Hand Tools",
                  innerText:"Create openings in buildings for ventilation or entrance, using axes, chisels, crowbars, electric saws, or core cutters."
                },
                {
                  title:"Quality Control",
                  innerText:"Inspect fire sites after flames have been extinguished to ensure that there is no further danger."
                },
                {
                  title:"Judgment",
                  innerText:"Move toward the source of a fire, using knowledge of types of fires, construction design, building materials, and physical layout of properties."
                },
                {
                  title:"Collaborate",
                  innerText:"Collaborate with police to respond to accidents, disasters, and arson investigation calls."
                },
                {
                  title:"Physical Fitness",
                  innerText:"Participate in physical training activities to maintain a high level of physical fitness."
                },]
        },
        {
          jobtitle:'Emergency Medical Technician',
          salary:"$18,000 - $32,000",
          description:"Assess injuries and illnesses and administer basic emergency medical care. May transport injured or sick persons to medical facilities.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'C8zIHk9KKqI',srcOrHref:'https://youtu.be/C8zIHk9KKqI'},
          education:"",
          id_gamification:"pub_saf-3",
          communicationbuttons:
              [ 
                {
                  title:"Analysis",
                  innerText:"Assess nature and extent of illness or injury to establish and prioritize medical procedures."
                },
                {
                  title:"Active Learning",
                  innerText:"Attend training classes to maintain certification licensure, keep abreast of new developments in the field, or maintain existing knowledge."
                },
                {
                  title:"Persuasion",
                  innerText:"Comfort and reassure patients."
                },
                {
                  title:"Teamwork",
                  innerText:"Coordinate work with other emergency medical team members or police or fire department personnel."
                },
                {
                  title:"Cleanliness",
                  innerText:"Decontaminate ambulance interior following treatment of patient with infectious disease and report case to proper authorities."
                },
                {
                  title:"Medical Procedure",
                  innerText:"Perform emergency diagnostic and treatment procedures, such as stomach suction, airway management, or heart monitoring, during ambulance rides."
                },]
        },
      ]
      
         
    },
    transportation:{
  
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/w0wDnQP1dn8",videoId:'w0wDnQP1dn8',imageOrVideo:'video',srcOrHref:'https://youtu.be/w0wDnQP1dn8'
      },
      jobs:[
        {
          jobtitle:'Diesel Mechanic',
          salary:"$30,000 - $65,000",
          description:"Diagnose, adjust, repair, or overhaul buses and trucks, or maintain and repair any type of diesel engines. Includes mechanics working primarily with automobile or marine diesel engines.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'-PcpkHT8xaU',srcOrHref:'https://youtu.be/-PcpkHT8xaU'},
          education:"",
          id_gamification:"transpo-1",
          communicationbuttons:
              [ 
                {
                  title:"Vehicle Operation",
                  innerText:"Test drive trucks and buses to diagnose malfunctions or to ensure that they are working properly."
                },
                {
                  title:"Engine Repair",
                  innerText:"Recondition and replace parts, pistons, bearings, gears, and valves."
                },
                {
                  title:"Vehicle Maintenance",
                  innerText:"Perform routine maintenance such as changing oil, checking batteries, and lubricating equipment and machinery."
                },
                {
                  title:"Critical Thinking",
                  innerText:"Inspect, test, and listen to defective equipment to diagnose malfunctions, using test instruments such as handheld computers, motor analyzers, chassis charts, or pressure gauges."
                },
                {
                  title:"Measure",
                  innerText:"Measure vehicle emissions to determine whether they are within acceptable limits."
                },
                {
                  title:"Electrical",
                  innerText:"Rewire ignition systems, lights, and instrument panels."
                },]
        },
        {
          jobtitle:'Automotive Mechanics',
          salary:"$30,000 - $87,000",
          description:"Diagnose, adjust, repair, or overhaul automotive vehicles.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'ARAUXc6Cbxw',srcOrHref:'https://youtu.be/ARAUXc6Cbxw'},
          education:"",
          id_gamification:"transpo-2",
          communicationbuttons:
              [ 
                {
                  title:"Analysis",
                  innerText:"Inspect vehicles for damage and record findings so that necessary repairs can be made."
                },
                {
                  title:"Troubleshoot",
                  innerText:"Troubleshoot fuel, ignition, and emissions control systems, using electronic testing equipment."
                },
                {
                  title:"Teamwork",
                  innerText:"Review work orders and discuss work with supervisors."
                },
                {
                  title:"Communicate",
                  innerText:"Confer with customers to obtain descriptions of vehicle problems and to discuss work to be performed and future repair requirements."
                },
                {
                  title:"Planning",
                  innerText:"Plan work procedures, using charts, technical manuals, and experience."
                },
                {
                  title:"Mechanical",
                  innerText:"Rebuild parts, such as crankshafts and cylinder blocks."
                },]
        },
        {
          jobtitle:'Truck Drivers',
          salary:"$50,000 - $100,000",
          description:"Drive a tractor-trailer combination or a truck with a capacity of at least 26,001 pounds Gross Vehicle Weight (GVW). May be required to unload truck. Requires commercial drivers' license. Includes tow truck drivers.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'yz8Oku9fQfQ',srcOrHref:'https://youtu.be/yz8Oku9fQfQ'},
          education:"",
          id_gamification:"transpo-3",
          communicationbuttons:
              [ 
                {
                  title:"Quality Control",
                  innerText:"Check vehicles to ensure that mechanical, safety, and emergency equipment is in good working order."
                },
                {
                  title:"Organization",
                  innerText:"Maintain logs of working hours or of vehicle service or repair status, following applicable state and federal regulations."
                },
                {
                  title:"Detail Oriented",
                  innerText:"Check all load-related documentation for completeness and accuracy."
                },
                {
                  title:"Vehicle Operation",
                  innerText:"Maneuver trucks into loading or unloading positions, following signals from loading crew and checking that vehicle and loading equipment are properly positioned."
                },
                {
                  title:"Vehicle Maintenance",
                  innerText:"Perform basic vehicle maintenance tasks, such as adding oil, fuel, or radiator fluid or performing minor repairs."
                },
                {
                  title:"Critical Thinking",
                  innerText:"Plan or adjust routes based on changing conditions, using computer equipment, global positioning systems (GPS) equipment, or other navigation devices, to minimize fuel consumption and carbon emissions."
                },]
        },
      ]
      
         
    },
  
   
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var CAW_Quiz_Object={ 
    agriculture:{
      mediaInfo:{
        videoType:'youtube',link:"https://www.youtube.com/watch?v=cUIAZcjjB_g&list=TLPQMjQwMzIwMjHqCZpyRvoORg",videoId:'MBYHvraw-Mg',imageOrVideo:'video',srcOrHref:'https://youtu.be/JSxcetMCZZ0'
      },
      jobs:[
        {
          jobtitle:'Agricultural Equipment Operators',
          salary:"$18,590 – $36,240",
          description:"Drive and control equipment to support agricultural activities such as tilling soil; planting, cultivating, and harvesting crops; feeding and herding livestock; or removing animal waste. May perform tasks such as crop baling or hay bucking. May operate stationary equipment to perform post-harvest tasks such as husking, shelling, threshing, and ginning.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'uSZVN7JaJmQ',srcOrHref:'https://youtu.be/uSZVN7JaJmQ'},
          id_gamification:"agri-1",
          communicationbuttons:
              [ 
                {
                  title:"Machinery Repair",
                  innerText:"Adjust, repair, and service farm machinery and notify supervisors when machinery malfunctions."
                },
                {
                  title:"Manual Labor",
                  innerText:"Load and unload crops or containers of materials, manually or using conveyors, handtrucks, forklifts, or transfer augers."
                },
                {
                  title:"Quality Control",
                  innerText:"Observe and listen to machinery operation to detect equipment malfunctions."
                },
                {
                  title:"Teamwork",
                  innerText:"Direct and monitor the activities of work crews engaged in planting, weeding, or harvesting activities."
                },
                {
                  title:"Operate Machinery",
                  innerText:"Operate or tend equipment used in agricultural production, such as tractors, combines, and irrigation equipment."
                },
                {
                  title:"Operate Hand Tools",
                  innerText:"Attach farm implements such as plows, discs, sprayers, or harvesters to tractors, using bolts and hand tools."
                },]
        },
        {
          jobtitle:'Environmental Engineers',
          salary:"$49,730 - $122,060",
          description:"Research, design, plan, or perform engineering duties in the prevention, control, and remediation of environmental hazards using various engineering disciplines. Work may include waste treatment, site remediation, or pollution control technology.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'JSxcetMCZZ0',srcOrHref:'https://youtu.be/JSxcetMCZZ0'},
          id_gamification:"agri-2",
          communicationbuttons:
              [ 
                {
                  title:"Systems Evaluation",
                  innerText:"Inspect industrial or municipal facilities or programs to evaluate operational effectiveness or ensure compliance with environmental regulations."
                },
                {
                  title:"Design",
                  innerText:"Design, or supervise the design of, systems, processes, or equipment for control, management, or remediation of water, air, or soil quality."
                },
                {
                  title:"Instructing",
                  innerText:"Develop or present environmental compliance training or orientation sessions."
                },
                {
                  title:"Monitoring",
                  innerText:"Monitor progress of environmental improvement programs."
                },
                {
                  title:"Critical Thinking",
                  innerText:"Develop site-specific health and safety protocols, such as spill contingency plans or methods for loading or transporting waste."
                },
                {
                  title:"Teamwork",
                  innerText:"Collaborate with environmental scientists, planners, hazardous waste technicians, engineers, experts in law or business, or other specialists to address environmental problems."
                },]
        },
        {
          jobtitle:'Biological Technicians',
          salary:"$26,260 - $52,340",
          description:"Assist biological and medical scientists. Set up, operate, and maintain laboratory instruments and equipment, monitor experiments, collect data and samples, make observations, and calculate and record results. May analyze organic substances, such as blood, food, and drugs.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'QEr_X2rDM-0',srcOrHref:'https://youtu.be/QEr_X2rDM-0'},
          id_gamification:"agri-3",
          communicationbuttons:
              [ 
                {
                  title:"Research",
                  innerText:"Conduct research or assist in the conduct of research, including the collection of information and samples, such as blood, water, soil, plants and animals."
                },
                {
                  title:"Observation",
                  innerText:"Monitor and observe experiments, recording production and test data for evaluation by research personnel."
                },
                {
                  title:"Analysis",
                  innerText:"Analyze experimental data and interpret results to write reports and summaries of findings."
                },
                {
                  title:"Examine",
                  innerText:"Examine animals and specimens to detect the presence of disease or other problems."
                },
                {
                  title:"Computer Fluency",
                  innerText:"Use computers, computer-interfaced equipment, robotics or high-technology industrial applications to perform work duties."
                },
                {
                  title:"Laboratory Equipment",
                  innerText:"Set up, adjust, calibrate, clean, maintain, and troubleshoot laboratory and field equipment."
                },]
        },
      ]
         
    },
    healthcare:{
    mediaInfo:{
      videoType:'youtube',link:"",videoId:'qm6c7KDIQq0',imageOrVideo:'video',srcOrHref:'https://youtu.be/JSxcetMCZZ0'
    },
      jobs:[
        {
          jobtitle:'Registered Nurse',
          salary:"$44,223 – $65,290",
          description:"Assess patient health problems and needs, develop and implement nursing care plans, and maintain medical records. Administer nursing care to ill, injured, convalescent, or disabled patients. May advise patients on health maintenance and disease prevention or provide case management. Licensing or registration required.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'x4Y_2fpET0U',srcOrHref:'https://youtu.be/x4Y_2fpET0U'},
          id_gamification:"hs-1",
          communicationbuttons:
              [ 
                 {
                  title:"Communicate",
                  innerText:"Consult and coordinate with healthcare team members to assess, plan, implement, or evaluate patient care plans."
                 },
                 {
                  title:"Social Perceptiveness",
                  innerText:"Assess the needs of individuals, families, or communities, including assessment of individuals' home or work environments, to identify potential health or safety problems."
                 },
                 {
                  title:"Monitoring",
                  innerText:"Monitor, record, and report symptoms or changes in patients' conditions."
                 },
                 {
                  title:"Service Oriented",
                  innerText:"Assess the needs of individuals, families, or communities, including assessment of individuals' home or work environments, to identify potential health or safety problems."
                 },
                 {
                  title:"Instructing",
                  innerText:"Instruct individuals, families, or other groups on topics such as health education, disease prevention, or childbirth and develop health improvement programs."
                 },
                 {
                  title:"Detail Oriented",
                  innerText:"Administer medications to patients and monitor patients for reactions or side effects."
                 },]
        },
        {
          jobtitle:'Pharmacy Technician',
          salary:"$21,820 - $40,310",
          description:"Prepare medications under the direction of a pharmacist. May measure, mix, count out, label, and record amounts and dosages of medications according to prescription orders.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'xmQM3R-Ijj4',srcOrHref:' https://youtu.be/xmQM3R-Ijj4'},
          id_gamification:"hs-2",
          communicationbuttons:
              [ 
                 {
                  title:"Customer Service",
                  innerText:"Assist customers by answering simple questions, locating items, or referring them to the pharmacist for medication information."
                 },
                 {
                  title:"Detail Oriented",
                  innerText:"Receive written prescription or refill requests and verify that information is complete and accurate."
                 },
                 {
                  title:"Cleanliness",
                  innerText:"Clean and help maintain equipment or work areas and sterilize glassware, according to prescribed methods."
                 },
                 {
                  title:"Organization",
                  innerText:"Establish or maintain patient profiles, including lists of medications taken by individual patients."
                 },
                 {
                  title:"Inventory",
                  innerText:"Receive and store incoming supplies, verify quantities against invoices, check for outdated medications in current inventory, and inform supervisors of stock needs and shortages."
                 },
                 {
                  title:"Computer Fluency",
                  innerText:"Operate cash registers to accept payment from customers."
                 },]
        },
        {
          jobtitle:'Respiratory Therapist',
          salary:"$39,280 - $68,990",
          description:"Assess, treat, and care for patients with breathing disorders. Assume primary responsibility for all respiratory care modalities, including the supervision of respiratory therapy technicians. Initiate and conduct therapeutic procedures; maintain patient records; and select, assemble, check, and operate equipment.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'1uC9I02SslA',srcOrHref:'https://youtu.be/1uC9I02SslA'},
          id_gamification:"hs-3",
          communicationbuttons:
              [ 
                 {
                  title:"Critical Care",
                  innerText:"Provide emergency care, such as artificial respiration, external cardiac massage, or assistance with cardiopulmonary resuscitation."
                 },
                 {
                  title:"Monitoring",
                  innerText:"Monitor patient's physiological responses to therapy, such as vital signs, arterial blood gases, or blood chemistry changes, and consult with physician if adverse reactions occur."
                 },
                 {
                  title:"Teamwork",
                  innerText:"Work as part of a team of physicians, nurses, or other healthcare professionals to manage patient care by assisting with medical procedures or related duties."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Inspect, clean, test, and maintain respiratory therapy equipment to ensure equipment is functioning safely and efficiently, ordering repairs when necessary."
                 },
                 {
                  title:"Critical Thinking",
                  innerText:"Read prescription, measure arterial blood gases, and review patient information to assess patient condition."
                 },
                 {
                  title:"Instructing",
                  innerText:"Educate patients and their families about their conditions and teach appropriate disease management techniques, such as breathing exercises or the use of medications or respiratory equipment."
                 },]
        },
      ]
         
    },
    manufacturing:{
      mediaInfo:{
        videoType:'youtube',link:"",videoId:'kJ0MTvwCw_4',imageOrVideo:'video',srcOrHref:'https://youtu.be/JSxcetMCZZ0'
      },
      jobs:[
        {
          jobtitle:'Team Assemblers',
          salary:"$20,840 - $56,950",
          description:"Work as part of a team having responsibility for assembling an entire product or component of a product. Team assemblers can perform all tasks conducted by the team in the assembly process and rotate through all or most of them, rather than being assigned to a specific task on a permanent basis. May participate in making management decisions affecting the work. Includes team leaders who work as part of the team.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'TSn6ghApPKM',srcOrHref:'https://youtu.be/TSn6ghApPKM'},
          id_gamification:"manufac-1",
          communicationbuttons:
              [ 
                 {
                  title:"Quality Control",
                  innerText:"Perform quality checks on products and parts."
                 },
                 {
                  title:"Detail Oriented",
                  innerText:"Review work orders and blueprints to ensure work is performed according to specifications."
                 },
                 {
                  title:"Instructing",
                  innerText:"Supervise assemblers and train employees on job procedures."
                 },
                 {
                  title:"Cleanliness",
                  innerText:"Shovel, sweep, or otherwise clean work areas."
                 },
                 {
                  title:"Operate Machinery",
                  innerText:"Operate machinery and heavy equipment, such as forklifts."
                 },
                 {
                  title:"Time Management",
                  innerText:"Rotate through all the tasks required in a particular production process."
                 },]
        },
        {
          jobtitle:'Maintenance and Repair Workers',
          salary:"$19,870 - $53,630",
          description:"Perform work involving the skills of two or more maintenance or craft occupations to keep machines, mechanical equipment, or the structure of a building in repair. Duties may involve pipe fitting; HVAC maintenance; insulating; welding; machining; carpentry; repairing electrical or mechanical equipment; installing, aligning, and balancing new equipment; and repairing buildings, floors, or stairs.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'I_mhZjRcH5s',srcOrHref:'https://youtu.be/I_mhZjRcH5s'},
          id_gamification:"manufac-2",

          communicationbuttons:
              [ 
                 {
                  title:"Machinery Maintenance",
                  innerText:"Dismantle machines, equipment, or devices to access and remove defective parts, using hoists, cranes, hand tools, or power tools."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Inspect, operate, or test machinery or equipment to diagnose machine malfunctions."
                 },
                 {
                  title:"Detail Oriented",
                  innerText:"Plan and lay out repair work, using diagrams, drawings, blueprints, maintenance manuals, or schematic diagrams."
                 },
                 {
                  title:"Welding",
                  innerText:"Operate cutting torches or welding equipment to cut or join metal parts."
                 },
                 {
                  title:"Fabricate",
                  innerText:"Fabricate or repair counters, benches, partitions, or other wooden structures, such as sheds or outbuildings."
                 },
                 {
                  title:"Critical Thinking",
                  innerText:"Estimate costs to repair machinery, equipment, or building structures."
                 },]
        },
        {
          jobtitle:'Supervisors of Production',
          salary:"$34,860 - $96,330",
          description:"Directly supervise and coordinate the activities of production and operating workers, such as inspectors, precision workers, machine setters and operators, assemblers, fabricators, and plant and system operators. Excludes team or work leaders.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'dflNKhApmBE',srcOrHref:'https://youtu.be/dflNKhApmBE'},
          id_gamification:"manufac-3",
          communicationbuttons:
              [ 
                 {
                  title:"Instructing",
                  innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
                 },
                 {
                  title:"Persuasion",
                  innerText:"Recommend or implement measures to motivate employees and to improve production methods, equipment performance, product quality, or efficiency."
                 },
                 {
                  title:"Critical Thinking",
                  innerText:"Interpret specifications, blueprints, job orders, and company policies and procedures for workers."
                 },
                 {
                  title:"Teamwork",
                  innerText:"Confer with other supervisors to coordinate operations and activities within or between departments."
                 },
                 {
                  title:"Organization",
                  innerText:"Plan and establish work schedules, assignments, and production sequences to meet production goals."
                 },
                 {
                  title:"Negotiation",
                  innerText:"Confer with management or subordinates to resolve worker problems, complaints, or grievances."
                 },]
        },
      ]
         
    },
    public_service:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/Ec1qLxQ3TRw",videoId:'a8LRVa59dl0',imageOrVideo:'video',srcOrHref:'https://youtu.be/a8LRVa59dl0'
      },
      jobs:[
        {
          jobtitle:'Patrol Officers',
          salary:"$29,610 - $65,520",
          description:"Maintain order and protect life and property by enforcing local, tribal, state, or federal laws and ordinances. Perform a combination of the following duties: patrol a specific area; direct traffic; issue traffic summonses; investigate accidents; apprehend and arrest suspects, or serve legal processes of courts. Includes police officers working at educational institutions.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'14D91NPvMz8',srcOrHref:'https://youtu.be/14D91NPvMz8'},
          id_gamification:"ps-1",
          communicationbuttons:
              [ 
                 {
                  title:"Apprehend Suspects",
                  innerText:"Identify, pursue, and arrest suspects and perpetrators of criminal acts."
                 },
                 {
                  title:"Public Safety",
                  innerText:"Provide for public safety by maintaining order, responding to emergencies, protecting people and property, enforcing motor vehicle and criminal laws, and promoting good community relations."
                 },
                 {
                  title:"Active Listening",
                  innerText:"Record facts to prepare reports that document incidents and activities."
                 },
                 {
                  title:"First Aid",
                  innerText:"Render aid to accident victims and other persons requiring first aid for physical injuries."
                 },
                 {
                  title:"Investigate",
                  innerText:"Investigate illegal or suspicious activities."
                 },
                 {
                  title:"Weapons & Tools",
                  innerText:"Use handguns, handcuffs, police vehicles, surveillance video and audio recorders, and two-way radios."
                 },]
        },
        {
          jobtitle:'Firefighters',
          salary:"$24,080 - $62,680",
          description:"Control and extinguish fires or respond to emergency situations where life, property, or the environment is at risk. Duties may include fire prevention, emergency medical service, hazardous material response, search and rescue, and disaster assistance.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'DNK5dYGn3Fg',srcOrHref:'https://youtu.be/DNK5dYGn3Fg'},
          id_gamification:"ps-2",
          communicationbuttons:
              [ 
                 {
                  title:"Critical Thinking",
                  innerText:"Assess fires and situations and report conditions to superiors to receive instructions, using two-way radios."
                 },
                 {
                  title:"Hand Tools",
                  innerText:"Create openings in buildings for ventilation or entrance, using axes, chisels, crowbars, electric saws, or core cutters."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Inspect fire sites after flames have been extinguished to ensure that there is no further danger."
                 },
                 {
                  title:"Judgement",
                  innerText:"Move toward the source of a fire, using knowledge of types of fires, construction design, building materials, and physical layout of properties."
                 },
                 {
                  title:"Collaborate",
                  innerText:"Collaborate with police to respond to accidents, disasters, and arson investigation calls."
                 },
                 {
                  title:"Physical Fitness",
                  innerText:"Participate in physical training activities to maintain a high level of physical fitness."
                 },]
        },
        {
          jobtitle:'Paramedics',
          salary:"$19,310 - $40,370",
          description:"Administer basic or advanced emergency medical care and assess injuries and illnesses. May administer medication intravenously, use equipment such as EKGs, or administer advanced life support to sick or injured individuals.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'C8zIHk9KKqI',srcOrHref:'https://youtu.be/C8zIHk9KKqI'},
          id_gamification:"ps-3",
          communicationbuttons:
              [ 
                 {
                  title:"First Aid",
                  innerText:"Administer first aid treatment or life support care to sick or injured persons in prehospital settings."
                 },
                 {
                  title:"Critical Thinking",
                  innerText:"Assess nature and extent of illness or injury to establish and prioritize medical procedures."
                 },
                 {
                  title:"Continued Training",
                  innerText:"Attend training classes to maintain certification licensure, keep abreast of new developments in the field, or maintain existing knowledge."
                 },
                 {
                  title:"Collaborate",
                  innerText:"Coordinate work with other emergency medical team members or police or fire department personnel."
                 },
                 {
                  title:"Instructing",
                  innerText:"Instruct emergency medical response team about emergency interventions, to ensure correct application of procedures."
                 },
                 {
                  title:"Observation",
                  innerText:"Observe, record, and report to physician the patient's condition or injury, the treatment provided, and reactions to drugs or treatment."
                 },]
        },
      ]
         
    },
    transportation_distribution_and_logistics:{
      mediaInfo:{
        videoType:'youtube',link:"https://youtu.be/w0wDnQP1dn8",videoId:'w0wDnQP1dn8',imageOrVideo:'video',srcOrHref:'https://youtu.be/w0wDnQP1dn8'
      },
      jobs:[
        {
          jobtitle:'Stock & Material Movers',
          salary:"$18,840 - $40,230",
          description:"Manually move freight, stock, luggage, or other materials, or perform other general labor. Includes all manual laborers not elsewhere classified.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'wQr_uQMpmEM',srcOrHref:'https://youtu.be/wQr_uQMpmEM'},
          id_gamification:"tdal-1",
          communicationbuttons:
              [ 
                 {
                  title:"Physical Fitness",
                  innerText:"Shovel material, such as gravel, ice, or spilled concrete, into containers or bins or onto conveyors."
                 },
                 {
                  title:"Operate Machinery",
                  innerText:"Adjust controls to guide, position, or move equipment, such as cranes, booms, or cameras."
                 },
                 {
                  title:"Electrical",
                  innerText:"Connect electrical equipment to power sources so that it can be tested before use."
                 },
                 {
                  title:"Detail Oriented",
                  innerText:"Attach identifying tags to containers or mark them with identifying information."
                 },
                 {
                  title:"Writing",
                  innerText:"Record numbers of units handled or moved, using daily production sheets or work tickets."
                 },
                 {
                  title:"Hand Tools",
                  innerText:"Rig or dismantle props or equipment, such as frames, scaffolding, platforms, or backdrops, using hand tools."
                 },]
        },
        {
          jobtitle:'Truck Drivers',
          salary:"$25,690 - $64,210",
          description:"Drive a tractor-trailer combination or a truck with a capacity of at least 26,001 pounds Gross Vehicle Weight (GVW). May be required to unload truck. Requires commercial drivers' license. Includes tow truck drivers.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'yz8Oku9fQfQ',srcOrHref:'https://youtu.be/yz8Oku9fQfQ'},
          id_gamification:"tdal-2",
          communicationbuttons:
              [ 
                 {
                  title:"Quality Control",
                  innerText:"Check vehicles to ensure that mechanical, safety, and emergency equipment is in good working order."
                 },
                 {
                  title:"Organization",
                  innerText:"Maintain logs of working hours or of vehicle service or repair status, following applicable state and federal regulations."
                 },
                 {
                  title:"Detail Oriented",
                  innerText:"Check all load-related documentation for completeness and accuracy."
                 },
                 {
                  title:"Vehicle Operation",
                  innerText:"Maneuver trucks into loading or unloading positions, following signals from loading crew and checking that vehicle and loading equipment are properly positioned."
                 },
                 {
                  title:"Vehicle Maintenance",
                  innerText:"Perform basic vehicle maintenance tasks, such as adding oil, fuel, or radiator fluid or performing minor repairs."
                 },
                 {
                  title:"Critical Thinking",
                  innerText:"Plan or adjust routes based on changing conditions, using computer equipment, global positioning systems (GPS) equipment, or other navigation devices, to minimize fuel consumption and carbon emissions."
                 },]
        },
        {
          jobtitle:'Mechanics/Diesel Engine Specialists',
          salary:"$29,650 - $63,150",
          description:"Diagnose, adjust, repair, or overhaul buses and trucks, or maintain and repair any type of diesel engines. Includes mechanics working primarily with automobile or marine diesel engines.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'-PcpkHT8xaU',srcOrHref:'https://youtu.be/-PcpkHT8xaU'},
          id_gamification:"tdal-3",
          communicationbuttons:
              [ 
                 {
                  title:"Vehicle Operation",
                  innerText:"Test drive trucks and buses to diagnose malfunctions or to ensure that they are working properly."
                 },
                 {
                  title:"Engine Repair",
                  innerText:"Recondition and replace parts, pistons, bearings, gears, and valves."
                 },
                 {
                  title:"Vehicle Maintenance",
                  innerText:"Perform routine maintenance such as changing oil, checking batteries, and lubricating equipment and machinery."
                 },
                 {
                  title:"Critical Thinking",
                  innerText:"Inspect, test, and listen to defective equipment to diagnose malfunctions, using test instruments such as handheld computers, motor analyzers, chassis charts, or pressure gauges."
                 },
                 {
                  title:"Measure",
                  innerText:"Measure vehicle emissions to determine whether they are within acceptable limits."
                 },
                 {
                  title:"Electrical",
                  innerText:"Rewire ignition systems, lights, and instrument panels."
                 },]
        },
      ]
         
    },
    communication_and_information_technology:{
      mediaInfo:{
        videoType:'youtube',link:"",videoId:'cbffW0MeWoY',imageOrVideo:'video',srcOrHref:'https://youtu.be/JSxcetMCZZ0'
      },
      jobs:[
        {
          jobtitle:'Computer User Support Specialists',
          salary:"$31,540 - $78,630",
          description:"Provide technical assistance to computer users. Answer questions or resolve computer problems for clients in person, via telephone, or electronically. May provide assistance concerning the use of computer hardware and software, including printing, installation, word processing, electronic mail, and operating systems.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'xe6GS8kSN1g',srcOrHref:'https://youtu.be/xe6GS8kSN1g'},
          id_gamification:"cait-1",
          communicationbuttons:
              [ 
                 {
                  title:"Customer Support",
                  innerText:"Answer user inquiries regarding computer software or hardware operation to resolve problems."
                 },
                 {
                  title:"Troubleshoot",
                  innerText:"Read technical manuals, confer with users, or conduct computer diagnostics to investigate and resolve problems or to provide technical assistance and support."
                 },
                 {
                  title:"Instructing",
                  innerText:"Develop training materials and procedures, or train users in the proper use of hardware or software."
                 },
                 {
                  title:"Organization",
                  innerText:"Maintain records of daily data communication transactions, problems and remedial actions taken, or installation activities."
                 },
                 {
                  title:"Communicate",
                  innerText:"Confer with staff, users, and management to establish requirements for new systems or modifications."
                 },
                 {
                  title:"Systems Analysis",
                  innerText:"Prepare evaluations of software or hardware, and recommend improvements or upgrades."
                 },]
        },
        {
          jobtitle:'Network and Computer Systems Administrator',
          salary:"$45,110 - $117,730",
          description:"Install, configure, and maintain an organization's local area network (LAN), wide area network (WAN), data communications network, operating systems, and physical and virtual servers. Perform system monitoring and verify the integrity and availability of hardware, network, and server resources and systems. Review system and application logs and verify completion of scheduled jobs, including system backups. Analyze network and server resource consumption and control user access. Install and upgrade software and maintain software licenses. May assist in network modeling, analysis, planning, and coordination between network and data communications hardware and software.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'H3kIfqDCY14',srcOrHref:'https://youtu.be/H3kIfqDCY14'},
          id_gamification:"cait-2",
          communicationbuttons:
              [ 
                 {
                  title:"Troubleshoot",
                  innerText:"Diagnose, troubleshoot, and resolve hardware, software, or other network and system problems, and replace defective components when necessary."
                 },
                 {
                  title:"Communicate",
                  innerText:"Confer with network users about how to solve existing system problems."
                 },
                 {
                  title:"Systems Analysis",
                  innerText:"Recommend changes to improve systems and network configurations, and determine hardware or software requirements related to such changes."
                 },
                 {
                  title:"Instructing",
                  innerText:"Train people in computer system use."
                 },
                 {
                  title:"Organization",
                  innerText:"Maintain logs related to network functions, as well as maintenance and repair records."
                 },
                 {
                  title:"Operations Analysis",
                  innerText:"Gather data pertaining to customer needs, and use the information to identify, predict, interpret, and evaluate system and network requirements."
                 },]
        },
        {
          jobtitle:'Software Developers',
          salary:"$55,630 - $156,570",
          description:"Research, design, and develop computer and network software or specialized utility programs. Analyze user needs and develop software solutions, applying principles and techniques of computer science, engineering, and mathematical analysis. Update software or enhance existing software capabilities. May work with computer hardware engineers to integrate hardware and software systems, and develop specifications and performance requirements. May maintain databases within an application area, working individually or coordinating database development as part of a team.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'_WQ_VV4pXPc',srcOrHref:' https://youtu.be/_WQ_VV4pXPc'},
          id_gamification:"cait-3",
          communicationbuttons:
              [ 
                 {
                  title:"Communicate",
                  innerText:"Consult with customers or other departments on project status, proposals, or technical issues, such as software system design or maintenance."
                 },
                 {
                  title:"Systems Analysis",
                  innerText:"Analyze information to determine, recommend, and plan installation of a new system or modification of an existing system."
                 },
                 {
                  title:"Supervise",
                  innerText:"Supervise the work of programmers, technologists and technicians and other engineering and scientific personnel."
                 },
                 {
                  title:"Instructing",
                  innerText:"Train users to use new or modified equipment."
                 },
                 {
                  title:"Monitoring",
                  innerText:"Monitor functioning of equipment to ensure system operates in conformance with specifications."
                 },
                 {
                  title:"Mathematics",
                  innerText:"Design, develop and modify software systems, using scientific analysis and mathematical models to predict and measure outcomes and consequences of design."
                 },]
        },
      ]
    },
    hospitality_and_tourism:{
      mediaInfo:{
        videoType:'youtube',link:"",videoId:'j5IJHSpu31w',imageOrVideo:'video',srcOrHref:'https://youtu.be/JSxcetMCZZ0'
      },
      jobs:[
        {
          jobtitle:'Food Service Managers',
          salary:"$35,220 - $92,850",
          description:"Plan, direct, or coordinate activities of an organization or department that serves food and beverages.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'OzTbTaVPM2w',srcOrHref:'https://youtu.be/OzTbTaVPM2w'},
          id_gamification:"hat-1",
          communicationbuttons:
              [ 
                 {
                  title:"Scheduling",
                  innerText:"Schedule and receive food and beverage deliveries, checking delivery contents to verify product quality and quantity."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Test cooked food by tasting and smelling it to ensure palatability and flavor conformity."
                 },
                 {
                  title:"Organization",
                  innerText:"Plan menus and food utilization, based on anticipated number of guests, nutritional value, palatability, popularity, and costs."
                 },
                 {
                  title:"Critical Thinking",
                  innerText:"Review work procedures and operational problems to determine ways to improve service, performance, or safety."
                 },
                 {
                  title:"Customer Service",
                  innerText:"Greet guests, escort them to their seats, and present them with menus and wine lists."
                 },
                 {
                  title:"Management",
                  innerText:"Schedule staff hours and assign duties."
                 },]
        },
        {
          jobtitle:'Chefs/Head Cooks',
          salary:"$26,380 - $75,980",
          description:"Direct and may participate in the preparation, seasoning, and cooking of salads, soups, fish, meats, vegetables, desserts, or other foods. May plan and price menu items, order supplies, and keep records and accounts.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'BtAevVMysC0',srcOrHref:'https://youtu.be/BtAevVMysC0'},
          id_gamification:"hat-2",
          communicationbuttons:
              [ 
                 {
                  title:"Quality Control",
                  innerText:"Check the quality of raw or cooked food products to ensure that standards are met."
                 },
                 {
                  title:"Management",
                  innerText:"Supervise or coordinate activities of cooks or workers engaged in food preparation."
                 },
                 {
                  title:"Creativity",
                  innerText:"Determine how food should be presented and create decorative food displays."
                 },
                 {
                  title:"Critical Analysis",
                  innerText:"Analyze recipes to assign prices to menu items, based on food, labor, and overhead costs."
                 },
                 {
                  title:"Communicate",
                  innerText:"Meet with customers to discuss menus for special occasions, such as weddings, parties, or banquets."
                 },
                 {
                  title:"Instruction",
                  innerText:"Demonstrate new cooking techniques or equipment to staff."
                 },
                 ]
        },
        {
          jobtitle:'Lodging Managers',
          salary:"$29,520 - $78,140",
          description:"Plan, direct, or coordinate activities of an organization or department that provides lodging and other accommodations.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'N6wsqmVAvnQ',srcOrHref:'https://youtu.be/N6wsqmVAvnQ'},
          id_gamification:"hat-3",
          communicationbuttons:
              [ 
                 {
                  title:"Customer Service",
                  innerText:"Answer inquiries pertaining to hotel policies and services, and resolve occupants' complaints."
                 },
                 {
                  title:"Teamwork",
                  innerText:"Confer and cooperate with other managers to ensure coordination of hotel activities."
                 },
                 {
                  title:"Instruction",
                  innerText:"Train staff members."
                 },
                 {
                  title:"Management",
                  innerText:"Assign duties to workers, and schedule shifts."
                 },
                 {
                  title:"Coordinate",
                  innerText:"Organize and coordinate the work of staff and convention personnel for meetings to be held at a particular facility."
                 },
                 {
                  title:"Problem Solving",
                  innerText:"Develop and implement policies and procedures for the operation of a department or establishment."
                 },]
        },
      ]

    },

    education_and_training:{
      mediaInfo:{
        videoType:'youtube',link:"",videoId:'4r-QVk5-umI',imageOrVideo:'video',srcOrHref:'https://youtu.be/4r-QVk5-umI'
      },
      jobs:[
        {
          jobtitle:'Elementary School Teachers',
          salary:"$38,520 - $62,440",
          description:"Teach academic and social skills to students at the elementary school level.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'VRB818Z8Xhs',srcOrHref:'https://youtu.be/VRB818Z8Xhs'},
          id_gamification:"eat-1",
          communicationbuttons:
              [ 
                 {
                  title:"Enforce Rules",
                  innerText:"Establish and enforce rules for behavior and procedures for maintaining order among the students for whom they are responsible."
                 },
                 {
                  title:"Learning Strategy",
                  innerText:"Adapt teaching methods and instructional materials to meet students' varying needs and interests."
                 },
                 {
                  title:"Communicate",
                  innerText:"Confer with parents or guardians, teachers, counselors, and administrators to resolve students' behavioral and academic problems."
                 },
                 {
                  title:"Critical Thinking",
                  innerText:"Establish clear objectives for all lessons, units, and projects and communicate those objectives to students."
                 },
                 {
                  title:"Monitoring",
                  innerText:"Observe and evaluate students' performance, behavior, social development, and physical health."
                 },
                 {
                  title:"Organization",
                  innerText:"Maintain accurate and complete student records as required by laws, district policies, and administrative regulations."
                 },]
        },
          {
            jobtitle:'Secondary School Teachers',
            salary:"$39,960 - $63,490",
            description:"Teach one or more subjects to students at the secondary school level.", 
            jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'tSeStFsCZrQ',srcOrHref:'https://youtu.be/tSeStFsCZrQ'},
            id_gamification:"eat-2",
            communicationbuttons:
                [ 
                   {
                    title:"Enforce Rules",
                    innerText:"Establish and enforce rules for behavior and procedures for maintaining order among the students for whom they are responsible."
                   },
                   {
                    title:"Learning Strategy",
                    innerText:"Adapt teaching methods and instructional materials to meet students' varying needs and interests."
                   },
                   {
                    title:"Communicate",
                    innerText:"Confer with parents or guardians, teachers, counselors, and administrators to resolve students' behavioral and academic problems."
                   },
                   {
                    title:"Critical Thinking",
                    innerText:"Establish clear objectives for all lessons, units, and projects and communicate those objectives to students."
                   },
                   {
                    title:"Monitoring",
                    innerText:"Observe and evaluate students' performance, behavior, social development, and physical health."
                   },
                   {
                    title:"Organization",
                    innerText:"Maintain accurate and complete student records as required by laws, district policies, and administrative regulations."
                   },]
          },
          {
            jobtitle:'Guidance Counselor',
            salary:"$34,370 - $73,210",
            description:"Advise and assist students and provide educational and vocational guidance services.", 
            jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'aPtHL33Velc',srcOrHref:'https://youtu.be/aPtHL33Velc'},
            id_gamification:"eat-3",
            communicationbuttons:
                [ 
                   {
                    title:"Socially Perceptive",
                    innerText:"Identify cases of domestic abuse or other family problems and encourage students or parents to seek additional assistance from mental health professionals."
                   },
                   {
                    title:"Organization",
                    innerText:"Maintain accurate and complete student records as required by laws, district policies, and administrative regulations."
                   },
                   {
                    title:"Instruction",
                    innerText:"Teach classes and present self-help or information sessions on subjects related to education and career planning."
                   },
                   {
                    title:"Monitoring",
                    innerText:"Observe students during classroom and play activities to evaluate students' performance, behavior, social development, and physical health."
                   },
                   {
                    title:"Teamwork",
                    innerText:"Collaborate with teachers and administrators in the development, evaluation, and revision of school programs and in the preparation of master schedules for curriculum offerings."
                   },
                   {
                    title:"Program Planning",
                    innerText:"Plan and promote career and employment-related programs and events, such as career planning presentations, work experience programs, job fairs, and career workshops."
                   },]
          },
        ]
    },
    architecture_construction_and_utilities:{
      mediaInfo:{
        videoType:'youtube',link:"",videoId:'pH5PpKnaRk4',imageOrVideo:'video',srcOrHref:'https://youtu.be/JSxcetMCZZ0'
      },
      jobs:[
        {
          jobtitle:'Welders',
          salary:"",
          description:"Use hand-welding, flame-cutting, hand-soldering, or brazing equipment to weld or join metal components or to fill holes, indentations, or seams of fabricated metal products.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'Ydr62_uFPrA',srcOrHref:'https://youtu.be/Ydr62_uFPrA'},
          id_gamification:"aac-1",

          communicationbuttons:
              [ 
                 {
                  title:"Safety",
                  innerText:"Operate safety equipment and use safe work habits."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
                 },
                 {
                  title:"Analysis",
                  innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
                 },
                 {
                  title:"Organization",
                  innerText:"Mark or tag material with proper job number, piece marks, and other identifying marks as required."
                 },
                 {
                  title:"Mathematics",
                  innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
                 },
                 {
                  title:"Detail Oriented",
                  innerText:"Monitor the fitting, burning, and welding processes to avoid overheating of parts or warping, shrinking, distortion, or expansion of material."
                 },]
        },
        {
          jobtitle:'Heating/Air/ Refrigeration Mechanics',
          salary:"$26,490 - $62,640",
          description:"Install or repair heating, central air conditioning, HVAC, or refrigeration systems, including oil burners, hot-air furnaces, and heating stoves.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'PNcQwekI2KQ',srcOrHref:'https://youtu.be/PNcQwekI2KQ'},
          id_gamification:"aac-2",
          communicationbuttons:
              [ 
                 {
                  title:"Electrical",
                  innerText:"Test electrical circuits or components for continuity, using electrical test equipment."
                 },
                 {
                  title:"Repair",
                  innerText:"Repair or replace defective equipment, components, or wiring."
                 },
                 {
                  title:"Customer Service",
                  innerText:"Discuss heating or cooling system malfunctions with users to isolate problems or to verify that repairs corrected malfunctions."
                 },
                 {
                  title:"Safety",
                  innerText:"Comply with all applicable standards, policies, or procedures, such as safety procedures or the maintenance of a clean work area."
                 },
                 {
                  title:"Organization",
                  innerText:"Keep records of repairs and replacements made and causes of malfunctions."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Inspect and test systems to verify system compliance with plans and specifications or to detect and locate malfunctions."
                 },]
        },
        {
          jobtitle:'Plumbers/Pipefitters/Steamfitters',
          salary:"$28,570 - $64,290",
          description:"Assemble, install, alter, and repair pipelines or pipe systems that carry water, steam, air, or other liquids or gases. May install heating and cooling equipment and mechanical control systems. Includes sprinkler fitters.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'NgPQZeIZnsE',srcOrHref:'https://youtu.be/NgPQZeIZnsE'},
          id_gamification:"aac-3",
          communicationbuttons:
              [ 
                 {
                  title:"Quality Control",
                  innerText:"Inspect, examine, or test installed systems or pipe lines, using pressure gauge, hydrostatic testing, observation, or other methods."
                 },
                 {
                  title:"Detail Oriented",
                  innerText:"Plan pipe system layout, installation, or repair, according to specifications."
                 },
                 {
                  title:"Teamwork",
                  innerText:"Direct helpers engaged in pipe cutting, preassembly, or installation of plumbing systems or components."
                 },
                 {
                  title:"Analysis",
                  innerText:"Review blueprints, building codes, or specifications to determine work details or procedures."
                 },
                 {
                  title:"Hand Tools",
                  innerText:"Cut, thread, or hammer pipes to specifications, using tools such as saws, cutting torches, pipe threaders, or pipe benders."
                 },
                 {
                  title:"Organization",
                  innerText:"Keep records of work assignments."
                 },]
        },
        {
          jobtitle:'Line Workers',
          salary:"$38,490 - $96,470",
          description:"Install or repair cables or wires used in electrical power or distribution systems. May erect poles and light or heavy duty transmission towers.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'_IZLJj25qDE',srcOrHref:'https://www.youtube.com/watch?v=_IZLJj25qDE&ab_channel=GoBuildAlabama'},
          id_gamification:"eau-1",
          communicationbuttons:
              [ 
                 {
                  title:"Safety",
                  innerText:"Adhere to safety practices and procedures, such as checking equipment regularly and erecting barriers around work areas."
                 },
                 {
                  title:"Vehicle Operation",
                  innerText:"Drive vehicles equipped with tools and materials to job sites."
                 },
                 {
                  title:"Manual Labor",
                  innerText:"Dig holes, using augers, and set poles, using cranes and power equipment."
                 },
                 {
                  title:"Troubleshoot",
                  innerText:"Inspect and test power lines and auxiliary equipment to locate and identify problems, using reading and testing instruments."
                 },
                 {
                  title:"Repair",
                  innerText:"Replace or straighten damaged poles."
                 },
                 {
                  title:"Installation",
                  innerText:"Install watt-hour meters and connect service drops between power lines and consumers' facilities."
                 },]
        },
        {
          jobtitle:'Electrical Engineer',
          salary:"$64,620 - $153,180",
          description:"Research, design, develop, test, or supervise the manufacturing and installation of electrical equipment, components, or systems for commercial, industrial, military, or scientific use.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'E_Iy34hFITE',srcOrHref:'https://youtu.be/E_Iy34hFITE'},
          id_gamification:"eau-2",
          communicationbuttons:
              [ 
                 {
                  title:"Collaboration",
                  innerText:"Confer with engineers, customers, or others to discuss existing or potential engineering projects or products."
                 },
                 {
                  title:"Troubleshoot",
                  innerText:"Investigate customer or public complaints to determine the nature and extent of problems."
                 },
                 {
                  title:"Instructing",
                  innerText:"Supervise or train project team members, as necessary."
                 },
                 {
                  title:"Project Planning",
                  innerText:"Oversee project production efforts to assure projects are completed on time and within budget."
                 },
                 {
                  title:"Writing",
                  innerText:"Compile data and write reports regarding existing or potential electrical engineering studies or projects."
                 },
                 {
                  title:"Systems Analysis",
                  innerText:"Plan or implement research methodology or procedures to apply principles of electrical theory to engineering projects."
                 },]
        },
        {
          jobtitle:'Water Resource Specialist',
          salary:"$73,170 - $166,860",
          description:"Design or implement programs and strategies related to water resource issues such as supply, quality, and regulatory compliance issues.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'NIKz-bbli28',srcOrHref:'https://youtu.be/NIKz-bbli28'},
          id_gamification:"eau-3",
          communicationbuttons:
              [ 
                 {
                  title:"Systems Analysis",
                  innerText:"Analyze storm water systems to identify opportunities for water resource improvements."
                 },
                 {
                  title:"Organization",
                  innerText:"Compile and maintain documentation on the health of a body of water."
                 },
                 {
                  title:"Persuasion",
                  innerText:"Negotiate for water rights with communities or water facilities to meet water supply demands."
                 },
                 {
                  title:"Supervise",
                  innerText:"Supervise teams of workers who capture water from wells and rivers."
                 },
                 {
                  title:"Monitoring",
                  innerText:"Monitor water use, demand, or quality in a particular geographic area."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Conduct, or oversee the conduct of, chemical, physical, and biological water quality monitoring or sampling to ensure compliance with water quality standards."
                 },]
        },
      ]
        
  }
}


 /*********************** */
 var NCTEJW_Quiz_Object={ 
  aerospace_and_aviation:{
    mediaInfo:{
      videoType:'',link:"aerospace-careers",videoId:'', imageOrVideo:'img',srcOrHref:'aerospace-careers'
    },
    jobs:[ 
        {
          jobtitle:'Aerospace Engineering & Operations Technicians',
          salary:"$36,760 – $96,020",
          description:"Operate, install, adjust, and maintain integrated computer/communications systems, consoles, simulators, and other data acquisition, test, and measurement instruments and equipment, which are used to launch, track, position, and evaluate air and space vehicles. May record and interpret test data.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'1eOnm-61cDE',srcOrHref:'https://youtu.be/1eOnm-61cDE'},
          education:"Post-secondary certificate",
          id_gamification:"aaa-1",
          communicationbuttons:[
                 {
                  title:"Quality Control",
                  innerText:"Test aircraft systems under simulated operational conditions, performing systems readiness tests"
                 },
                 {
                  title:"Analysis",
                  innerText:"Identify required data, data acquisition plans, and test parameters, setting up equipment to conform to these specifications."
                 },
                {
                  title:"Teamwork",
                  innerText:"Confer with engineering personnel regarding details and implications of test procedures and results."
                 },
                 {
                  title:"Maintenance",
                  innerText:"Adjust, repair, or replace faulty components of test setups and equipment."
                 },
                 {
                  title:"Tool Usage",
                  innerText:"Fabricate and install parts and systems to be tested in test equipment, using hand tools, power tools, and test instruments."
                 },
                 {
                  title:"Systems Design",
                  innerText:"Design electrical and mechanical systems for avionic instrumentation applications."
                 },
          ]
      },
                {



          jobtitle:'Avionics Mechanic',
          salary:"$55,200",
          description:"Install, inspect, test, adjust, or repair avionics equipment, such as radar, radio, navigation, and missile control systems in aircraft or space vehicles.", 
          jobMedia:{imageOrVideo:'video',videoType:'youtube',videoId:'oByvKU2CwHs',srcOrHref:'https://youtu.be/oByvKU2CwHs'},
          education:"On-The Job Training/Technical Training",
          id_gamification:"aaa-2",
          communicationbuttons:[
                 {
                  title:"Quality Control",
                  innerText:"Test and troubleshoot instruments, components, and assemblies, using circuit testers, oscilloscopes, or voltmeters."
                 },
                 {
                  title:"Maintenance",
                  innerText:"Adjust, repair, or replace malfunctioning components or assemblies, using hand tools or soldering irons."
                 },
                 {
                  title:"Tool Usage",
                  innerText:"Install electrical and electronic components, assemblies, and systems in aircraft, using hand tools, power tools, or soldering irons."
                 },
                 {
                  title:"Analysis",
                  innerText:"Interpret flight test data to diagnose malfunctions and systemic performance problems."
                 },
                 {
                  title:"Teamwork",
                  innerText:"Coordinate work with that of engineers, technicians, and other aircraft maintenance personnel"                     },
                 {
                  title:"Prototyping",
                  innerText:"Assemble prototypes or models of circuits, instruments, and systems for use in testing."
                 },
          ]
      },
      {


          jobtitle:"A&P Mechanic",
          description:"Airframe and Powerplant Mechanic, Diagnose, adjust, repair, or overhaul aircraft engines and assemblies, such as hydraulic and pneumatic systems.",
          salary:"$55,900",
          education: "On-The Job Training/Technical Training",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/ncXP05WGGCM",videoId:"ncXP05WGGCM"},
          id_gamification:"aaa-3",
          communicationbuttons:[
                 {
                  title:"Quality Control",
                  innerText:"Examine and inspect aircraft components, including landing gear, hydraulic systems, and deicers to locate cracks, breaks, leaks, or other problems."                     
                  },
                 {
                  title:"Analysis",
                  innerText:"Read and interpret maintenance manuals, service bulletins, and other specifications to determine the feasibility and method of repairing or replacing malfunctioning or damaged components."
                 },
                 {
                  title:"Tool Usage",
                  innerText:"Assemble and install electrical, plumbing, mechanical, hydraulic, and structural components and accessories, using hand or power tools."
                 },
                 {
                  title:"Teamwork ",
                  innerText:"Communicate with other workers to coordinate fitting and alignment of heavy parts, or to facilitate processing of repair parts."
                 },
                 {
                  title:"Multitask",
                  innerText:" Examine engines through specially designed openings while working from ladders or scaffolds, or use hoists or lifts to remove the entire engine from an aircraft."                    },
                 {
                  title:"Troubleshoot",
                  innerText:"Listen to operating engines to detect and diagnose malfunctions, such as sticking or burned valves."
                 },
          ]
      },
      {


        jobtitle:"Aerospace Engineer",
        description:"Perform engineering duties in designing, constructing, and testing aircraft, missiles, and spacecraft. May conduct basic and applied research to evaluate adaptability of materials and equipment to aircraft design and manufacture. May recommend improvements in testing equipment and techniques.",
        salary:"$117,200",
        education: "",
        jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/bnxcib-8S4s",videoId:"bnxcib-8S4s"},
        id_gamification:"aaa-4",
        communicationbuttons:[
               {
                title:"Mathematics",
                innerText:"Formulate mathematical models or other methods of computer analysis to develop, evaluate, or modify designs."                     
                },
               {
                title:"System Design",
                innerText:"Design or engineer filtration systems that reduce harmful emissions."
               },
               {
                title:"Fabricate",
                innerText:"Fabricate defective sections or parts, using metal fabricating machines, saws, brakes, shears, and grinders."
               },
               {
                title:"Organized",
                innerText:"Maintain records of performance reports for future reference."
               },
               {
                title:"Leadership",
                innerText:"Direct aerospace research and development programs."                    },
               {
                title:"Research",
                innerText:"Research new materials to determine quality or conformance to environmental standards."
               },
        ]
    },
    {


      jobtitle:"A&P Technician",
      description:"Assemble, fit, fasten, and install parts of airplanes, space vehicles, or missiles, such as tails, wings, fuselage, bulkheads, stabilizers, landing gear, rigging and control equipment, or heating and ventilating systems.",
      salary:"$55,100",
      education: "",
      jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/TSn6ghApPKM",videoId:"TSn6ghApPKM"},
      id_gamification:"aaa-5",
      communicationbuttons:[
             {
              title:"Analysis",
              innerText:"Read blueprints, illustrations, or specifications to determine layouts, sequences of operations, or identities or relationships of parts."                     
              },
             {
              title:"Maintain",
              innerText:"Adjust, repair, rework, or replace parts or assemblies to ensure proper operation."
             },
             {
              title:"Fabricate",
              innerText:"Fabricate parts needed for assembly or installation, using shop machinery or equipment."
             },
             {
              title:"Tool Usage ",
              innerText:"Attach brackets, hinges, or clips to secure or support components or subassemblies, using bolts, screws, rivets, chemical bonding, or welding."
             },
             {
              title:"Welding",
              innerText:"Weld tubing and fittings or solder cable ends, using tack welders, induction brazing chambers, or other equipment."                    },
             {
              title:"Operate Machines",
              innerText:"Set up or operate machines or systems to crimp, cut, bend, form, swage, flare, bead, burr, or straighten tubing, according to specifications."
             },
      ]
  },
  {


    jobtitle:"Shipping and Receiving Handler",
    description:"Manually move freight, stock, luggage, or other materials, or perform other general labor. Includes all manual laborers not elsewhere classified.",
    salary:"$31,000",
    education: "",
    jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/wQr_uQMpmEM",videoId:" wQr_uQMpmEM"},
    id_gamification:"aaa-6",
    communicationbuttons:[
           {
            title:"Detailed",
            innerText:"Attach identifying tags to containers or mark them with identifying information."                     
            },
            {
              title:"Task Oriented",
              innerText:"Read work orders or receive oral instructions to determine work assignments or material or equipment needs."                     
              },{
                title:"Physical Labor",
                innerText:"Stack cargo in locations, such as transit sheds or in holds of ships as directed, using pallets or cargo boards."                     
                },{
                  title:"Safety",
                  innerText:"Guide loads being lifted to prevent swinging."                     
                  },{
                    title:"Tool Usage",
                    innerText:"Rig or dismantle props or equipment, such as frames, scaffolding, platforms, or backdrops, using hand tools."                     
                    },{
                      title:"Organized",
                      innerText:"Maintain equipment storage areas to ensure that inventory is protected."                     
                      },
    ]
},
{


  jobtitle:"Quality Control Inspector",
  description:"Inspect, test, sort, sample, or weigh nonagricultural raw materials or processed, machined, fabricated, or assembled parts or products for defects, wear, and deviations from specifications. May use precision measuring instruments and complex test equipment.",
  salary:"$62,200",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/RCTxteXoiAA",videoId:"RCTxteXoiAA"},
  id_gamification:"aaa-7",
  communicationbuttons:[
         {
          title:"Analysis",
          innerText:"Mark items with details, such as grade or acceptance-rejection status."                     
          },
         {
          title:"Communicate",
          innerText:"Notify supervisors or other personnel of production problems."
         },
         {
          title:"Physical Labor",
          innerText:"Make minor adjustments to equipment, such as turning setscrews to calibrate instruments to required tolerances."
         },
         {
          title:"Solution Focused",
          innerText:"Recommend necessary corrective actions, based on inspection results."
         },
         {
          title:"Organized",
          innerText:"Write test or inspection reports describing results, recommendations, or needed repairs."                    },
         {
          title:"Detailed",
          innerText:"Analyze test data, making computations as necessary, to determine test results."
         },
  ]
},
{


jobtitle:"Industrial Engineers",
description:"Design, develop, test, and evaluate integrated systems for managing industrial production processes, including human work factors, quality control, inventory control, logistics and material flow, cost analysis, and production coordination.",
salary:"$95,400",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/kNXMJBQ6oL4",videoId:"kNXMJBQ6oL4"},
id_gamification:"aaa-8",
communicationbuttons:[
       {
        title:"Analysis",
        innerText:"Analyze statistical data and product specifications to determine standards and establish quality and reliability objectives of finished product."                     
        },
       {
        title:"Communicate",
        innerText:"Communicate with management and user personnel to develop production and design standards."
       },
       {
        title:"Solution Focused",
        innerText:"Evaluate precision and accuracy of production and testing equipment and engineering drawings to formulate corrective action plan."
       },
       {
        title:"Organized",
        innerText:"Complete production reports, purchase orders, and material, tool, and equipment lists."
       },
       {
        title:"Mathematics",
        innerText:"Apply statistical methods and perform mathematical calculations to determine manufacturing processes, staff requirements, and production standards."                    },
       {
        title:"System Design",
        innerText:"Formulate sampling procedures and designs and develop forms and instructions for recording, evaluating, and reporting quality and reliability data."
       },
]
},
{


jobtitle:"Software QA Analysts and Testers",
description:"Develop and execute software tests to identify software problems and their causes. Test system modifications to prepare for implementation. Document software and application defects using a bug tracking system and report defects to software or web developers. Create and maintain databases of known defects. May participate in software design reviews to provide input on functional requirements, operational characteristics, product designs, and schedules.",
salary:"$96,400",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/Xr-EoC9DHyc",videoId:"Xr-EoC9DHyc"},
id_gamification:"aaa-9",
communicationbuttons:[
     {
      title:"Analysis",
      innerText:"Identify, analyze, and document problems with program function, output, online screen, or content."                     
      },
     {
      title:"Communicate",
      innerText:"Participate in product design reviews to provide input on functional requirements, product designs, schedules, or potential problems."
     },
     {
      title:"Solution Focused",
      innerText:"Provide feedback and recommendations to developers on software usability and functionality."
     },
     {
      title:"Organized",
      innerText:"Document software defects, using a bug tracking system, and report defects to software developers."
     },
     {
      title:"System Design",
      innerText:"Design test plans, scenarios, scripts, or procedures."                    },
     {
      title:"User Testing",
      innerText:"Coordinate user or third-party testing."
     },
]
},
{


jobtitle:"Commercial Pilots",
description:"Pilot and navigate the flight of fixed-wing aircraft on nonscheduled air carrier routes, or helicopters. Requires Commercial Pilot certificate. Includes charter pilots with similar certification, and air ambulance and air tour pilots. Excludes regional, national, and international airline pilots.",
salary:"$80,500",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/oey4mi_QV48",videoId:"oey4mi_QV48"},
id_gamification:"aaa-10",
communicationbuttons:[
     {
      title:"Communicate",
      innerText:"Contact control towers for takeoff clearances, arrival instructions, and other information, using radio equipment."                     
      },
     {
      title:"Analysis",
      innerText:"Choose routes, altitudes, and speeds that will provide the fastest, safest, and smoothest flights."
     },
     {
      title:"Safety",
      innerText:"Order changes in fuel supplies, loads, routes, or schedules to ensure safety of flights."
     },
     {
      title:"Supervise",
      innerText:"Supervise other crew members."
     },
     {
      title:"Teaching",
      innerText:"Instruct other pilots and student pilots in aircraft operations."                    },
     {
      title:"Monitoring",
      innerText:"Monitor engine operation, fuel consumption, and functioning of aircraft systems during flights."
     },
]
},
{


jobtitle:"Machinists",
description:"Set up and operate a variety of machine tools to produce precision parts and instruments out of metal. Includes precision instrument makers who fabricate, modify, or repair mechanical instruments. May also fabricate and modify parts to make or repair machine tools or maintain industrial machines, applying knowledge of mechanics, mathematics, metal properties, layout, and machining procedures.",
salary:"$54,500",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/OWNXI09WI14",videoId:"OWNXI09WI14"},
id_gamification:"aaa-11",
communicationbuttons:[
     {
      title:"Detailed",
      innerText:"Calculate dimensions or tolerances, using instruments, such as micrometers or vernier calipers."                     
      },
     {
      title:"Machine Usage",
      innerText:"Machine parts to specifications, using machine tools, such as lathes, milling machines, shapers, or grinders."
     },
     {
      title:"Communicate",
      innerText:"Confer with engineering, supervisory, or manufacturing personnel to exchange technical information."
     },
     {
      title:"Analysis",
      innerText:"Evaluate machining procedures and recommend changes or modifications for improved efficiency or adaptability."
     },
     {
      title:"Artistic",
      innerText:"Prepare working sketches for the illustration of product appearance."                    },
     {
      title:"Monitoring",
      innerText:"Monitor the feed and speed of machines during the machining process."
     },
]
}

     ],
 
    description:{
      aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
    }
},
construction:{
  mediaInfo:{
    videoType:'youtube',link:"https://www.youtube.com/watch?v=cUIAZcjjB_g&list=TLPQMjQwMzIwMjHqCZpyRvoORg",videoId:'pH5PpKnaRk4',imageOrVideo:'video',srcOrHref:'aerospace-careers'
  },
    jobs:[ 
        {
          jobtitle:'Electrician',
          salary:"$47,500",
          description:"Install, maintain, and repair electrical wiring, equipment, and fixtures. Ensure that work is in accordance with relevant codes. May install or service street lights, intercom systems, or electrical control systems.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'l6IWPbx4ZXw',
          srcOrHref:''},
          education:"",
          id_gamification:"construc-1",
          communicationbuttons:[
                 {
                  title:"Critical Analysis",
                  innerText:"Plan layout and installation of electrical wiring, equipment, or fixtures, based on job specifications and local codes."
                 },
                 {
                  title:"Design",
                  innerText:"Prepare sketches or follow blueprints to determine the location of wiring or equipment and to ensure conformance to building and safety codes."
                 },
                {
                  title:"Troubleshoot",
                  innerText:"Diagnose malfunctioning systems, apparatus, or components, using test equipment and hand tools to locate the cause of a breakdown and correct the problem."
                 },
                 {
                  title:"Instructing",
                  innerText:"Advise management on whether continued operation of equipment could be hazardous."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Inspect electrical systems, equipment, or components to identify hazards, defects, or the need for adjustment or repair, and to ensure compliance with codes."
                 },
                 {
                  title:"Hand Tools",
                  innerText:"Repair or replace wiring, equipment, or fixtures, using hand tools or power tools"

                 },
          ]
      },
                {



          jobtitle:'Heating/Air/Refrigeration Mechanics',
          salary:"$40,900",
          description:"Install or repair heating, central air conditioning, HVAC, or refrigeration systems, including oil burners, hot-air furnaces, and heating stoves.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'PNcQwekI2KQ',
          srcOrHref:''},
          education:"",
          id_gamification:"construc-2",
          communicationbuttons:[
                 {
                  title:"Electrical",
                  innerText:"Test electrical circuits or components for continuity, using electrical test equipment."
                 },
                 {
                  title:"Repair",
                  innerText:"Repair or replace defective equipment, components, or wiring."
                 },
                 {
                  title:"Customer Service",
                  innerText:"Discuss heating or cooling system malfunctions with users to isolate problems or to verify that repairs corrected malfunctions."
                 },
                 {
                  title:"Safety",
                  innerText:"Comply with all applicable standards, policies, or procedures, such as safety procedures or the maintenance of a clean work area."
                 },
                 {
                  title:"Organization",
                  innerText:"Keep records of repairs and replacements made and causes of malfunctions."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Inspect and test systems to verify system compliance with plans and specifications or to detect and locate malfunctions."
                 },
          ]
      },
      {


          jobtitle:"Ironworker",
          description:"Raise, place, and unite iron or steel girders, columns, and other structural members to form completed structures or structural frameworks. May erect metal storage tanks and assemble prefabricated metal buildings.",
          salary:"$32,000 - $63,000",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"UCtNJp6sd8I"},
          id_gamification:"construc-3",
          communicationbuttons:[
                 {
                  title:"Analysis",
                  innerText:"Read specifications or blueprints to determine the locations, quantities, or sizes of materials required."
                  },
                 {
                  title:"Reading",
                  innerText:"Connect columns, beams, and girders with bolts, following blueprints and instructions from supervisors"
                 },
                 {
                  title:"Hand Tools",
                  innerText:"Force structural steel members into final positions, using turnbuckles, crowbars, jacks, or hand tools."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Verify vertical and horizontal alignment of structural steel members, using plumb bobs, laser equipment, transits, or levels."
                 },
                 {
                  title:"Precision",
                  innerText:"Fabricate metal parts, such as steel frames, columns, beams, or girders, according to blueprints or instructions from supervisors."
                 },
                 {
                  title:"Welding",
                  innerText:"Cut, bend, or weld steel pieces, using metal shears, torches, or welding equipment."
                 },
          ]
      },
      {
  
  
        jobtitle:"Plumbers, Pipefitters, and Steamfitters",
        description:"Assemble, install, alter, and repair pipelines or pipe systems that carry water, steam, air, or other liquids or gases. May install heating and cooling equipment and mechanical control systems. Includes sprinkler fitters.",
        salary:"$47,200",
        education: "",
        jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/uF3-2wFnf0E",videoId:"uF3-2wFnf0E"},
        id_gamification:"construc-4",
        communicationbuttons:[
               {
                title:"Hand Tools",
                innerText:"Cut, thread, or hammer pipes to specifications, using tools such as saws, cutting torches, pipe threaders, or pipe benders."                     
                },
               {
                title:"Organized",
                innerText:"Lay out full scale drawings of pipe systems, supports, or related equipment, according to blueprints."
               },
               {
                title:"Analysis",
                innerText:"Inspect, examine, or test installed systems or pipe lines, using pressure gauge, hydrostatic testing, observation, or other methods."
               },
               {
                title:"Supervise",
                innerText:"Direct helpers engaged in pipe cutting, preassembly, or installation of plumbing systems or components."
               },
               {
                title:"Welding",
                innerText:"Weld small pipes or special piping, using specialized techniques, equipment, or materials, such as computer-assisted welding or microchip fabrication."                    },
               {
                title:"Organized",
                innerText:"Estimate time, material, or labor costs for use in project plans."
               },
        ]
    },
    {


      jobtitle:"Construction Laborers",
      description:"Perform tasks involving physical labor at construction sites. May operate hand and power tools of all types: air hammers, earth tampers, cement mixers, small mechanical hoists, surveying and measuring equipment, and a variety of other equipment and instruments. May clean and prepare sites, dig trenches, set braces to support the sides of excavations, erect scaffolding, and clean up rubble, debris, and other waste materials. May assist other craft workers.",
      salary:"$32,900",
      education: "",
      jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/s1n6nBhVzdo",videoId:"s1n6nBhVzdo"},
      id_gamification:"construc-5",
      communicationbuttons:[
             {
              title:"Physical Labor",
              innerText:"Dig ditches or trenches, backfill excavations, or compact and level earth to grade specifications, using picks, shovels, pneumatic tampers, or rakes."                     
              },
             {
              title:"Safety",
              innerText:"Clean or prepare construction sites to eliminate possible hazards."
             },
             {
              title:"Maintenance",
              innerText:"Lubricate, clean, or repair machinery, equipment, or tools."
             },
             {
              title:"Cleanliness",
              innerText:"Clean or prepare construction sites to eliminate possible hazards."
             },
             {
              title:"Concrete Work ",
              innerText:"Smooth or finish freshly poured cement or concrete, using floats, trowels, screeds, or powered cement finishing tools."                    },
             {
              title:"Teamwork",
              innerText:"Provide assistance to craft workers, such as carpenters, plasterers, or masons."
             },
      ]
  },
  {


    jobtitle:"Carpenters",
    description:"Construct, erect, install, or repair structures and fixtures made of wood and comparable materials, such as concrete forms; building frameworks, including partitions, joists, studding, and rafters; and wood stairways, window and door frames, and hardwood floors. May also install cabinets, siding, drywall, and batt or roll insulation. Includes brattice builders who build doors or brattices (ventilation walls or partitions) in underground passageways.",
    salary:"$41,000",
    education: "",
    jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/i-tiC2Y-038",videoId:"i-tiC2Y-038"},
    id_gamification:"construc-6",
    communicationbuttons:[
           {
            title:"Safety",
            innerText:"Follow established safety rules and regulations and maintain a safe and clean environment."                     
            },
           {
            title:"Tool Usage",
            innerText:"Shape or cut materials to specified measurements, using hand tools, machines, or power saws."
           },
           {
            title:"Detailed",
            innerText:"Measure and mark cutting lines on materials, using a ruler, pencil, chalk, and marking gauge."
           },
           {
            title:"Communicate",
            innerText:"Arrange for subcontractors to deal with special areas, such as heating or electrical wiring work."
           },
           {
            title:"Data Entry",
            innerText:"Maintain records, document actions, and present written progress reports."                    },
           {
            title:"Finishing",
            innerText:"Finish surfaces of woodwork or wallboard in houses or buildings, using paint, hand tools, or paneling."
           },
    ]
},
{


  jobtitle:"Construction Supervisor",
  description:"Directly supervise and coordinate activities of construction or extraction workers.",
  salary:"$59,700",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/B7YiI3UkxFw",videoId:"B7YiI3UkxFw"},
  id_gamification:"construc-7",
  communicationbuttons:[
         {
          title:"Analysis",
          innerText:"Inspect work progress, equipment, or construction sites to verify safety or to ensure that specifications are met."                     
          },
         {
          title:"Supervise",
          innerText:"Supervise, coordinate, or schedule the activities of construction or extractive workers."
         },
         {
          title:"Planning",
          innerText:"Estimate material or worker requirements to complete jobs."
         },
         {
          title:"Communicate",
          innerText:"Confer with managerial or technical personnel, other departments, or contractors to resolve problems or to coordinate activities."
         },
         {
          title:"Teaching",
          innerText:"Train workers in construction methods, operation of equipment, safety procedures, or company policies."                    },
         {
          title:"Data Entry",
          innerText:"Record information, such as personnel, production, or operational data on specified forms or reports."
         },
  ]
},
{


jobtitle:"Construction Managers",
description:"Plan, direct, or coordinate, usually through subordinate supervisory personnel, activities concerned with the construction and maintenance of structures, facilities, and systems. Participate in the conceptual development of a construction project and oversee its organization, scheduling, budgeting, and implementation. Includes managers in specialized construction fields, such as carpentry or plumbing.",
salary:"$94,500",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/SiamJadljrw",videoId:"SiamJadljrw"},
id_gamification:"construc-8",
communicationbuttons:[
       {
        title:"Project Management",
        innerText:"Plan, schedule, or coordinate construction project activities to meet deadlines."                     
        },
       {
        title:"Communicate",
        innerText:"Interpret and explain plans and contract terms to representatives of the owner or developer, including administrative staff, workers, or clients."
       },
       {
        title:"Supervise",
        innerText:"Direct and supervise construction or related workers."
       },
       {
        title:"Business",
        innerText:"Prepare contracts or negotiate revisions to contractual agreements with architects, consultants, clients, suppliers, or subcontractors."
       },
       {
        title:"Safety",
        innerText:"Investigate damage, accidents, or delays at construction sites to ensure that proper construction procedures are being followed."                    },
       {
        title:"Analysis",
        innerText:"Study job specifications to determine appropriate construction methods."
       },
]
},
{


jobtitle:"Painter",
description:"Paint walls, equipment, buildings, bridges, and other structural surfaces, using brushes, rollers, and spray guns. May remove old paint to prepare surface prior to painting. May mix colors or oils to obtain desired color or consistency.",
salary:"$35,700",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/MhpyAMBkwIQ",videoId:"MhpyAMBkwIQ"},
id_gamification:"construc-9",
communicationbuttons:[
     {
      title:"Detailed",
      innerText:"Fill cracks, holes, or joints with caulk, putty, plaster, or other fillers, using caulking guns or putty knives."                     
      },
     {
      title:"Cautious",
      innerText:"Cover surfaces with dropcloths or masking tape and paper to protect surfaces during painting."
     },
     {
      title:"Finishing",
      innerText:"Smooth surfaces, using sandpaper, scrapers, brushes, steel wool, or sanding machines."
     },
     {
      title:"Painting",
      innerText:"Apply primers or sealers to prepare new surfaces, such as bare wood or metal, for finish coats."
     },
     {
      title:"Physical Labor",
      innerText:"Erect scaffolding or swing gates, or set up ladders, to work above ground level."                    },
     {
      title:"Artistic",
      innerText:"Mix and match colors of paint, stain, or varnish with oil or thinning and drying additives to obtain desired colors and consistencies."
     },
]
},
{


jobtitle:"Equipment Operator",
description:"Operate one or several types of power construction equipment, such as motor graders, bulldozers, scrapers, compressors, pumps, derricks, shovels, tractors, or front-end loaders to excavate, move, and grade earth, erect structures, or pour concrete or other hard surface pavement. May repair and maintain equipment in addition to other duties.",
salary:"$40,900",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/_zm6Wud8hp4",videoId:"_zm6Wud8hp4"},
id_gamification:"construc-10",
communicationbuttons:[
     {
      title:"Communicate",
      innerText:"Coordinate machine actions with other activities, positioning or moving loads in response to hand or audio signals from crew members."                     
      },
     {
      title:"Cautious",
      innerText:"Take actions to avoid potential hazards or obstructions, such as utility lines, other equipment, other workers, or falling objects."
     },
     {
      title:"Safety",
      innerText:"Locate underground services, such as pipes or wires, prior to beginning work."
     },
     {
      title:"Physical Labor",
      innerText:"Repair and maintain equipment, making emergency adjustments or assisting with major repairs as necessary."
     },
     {
      title:"Organized",
      innerText:"Keep records of material or equipment usage or problems encountered."                    },
     {
      title:"Operate Machinery",
      innerText:"Load and move dirt, rocks, equipment, or other materials, using trucks, crawler tractors, power cranes, shovels, graders, or related equipment."
     },
]
},
{


jobtitle:"Administrative Assistant",
description:"Perform routine administrative functions such as drafting correspondence, scheduling appointments, organizing and maintaining paper and electronic files, or providing information to callers.",
salary:"$35,900",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/zDqK9DaCDg4",videoId:"zDqK9DaCDg4"},
id_gamification:"construc-11",
communicationbuttons:[
     {
      title:"Communicate",
      innerText:"Answer telephones and give information to callers, take messages, or transfer calls to appropriate individuals."                     
      },
     {
      title:"People Skills",
      innerText:"Greet visitors or callers and handle their inquiries or direct them to the appropriate persons according to their needs."
     },
     {
      title:"Data Entry",
      innerText:"Create, maintain, and enter information into databases."
     },
     {
      title:"Computer Usage",
      innerText:"Use computers for various applications, such as database management or word processing."
     },
     {
      title:"Organized",
      innerText:"Establish work procedures or schedules and keep track of the daily work of clerical staff."                    },
     {
      title:"Supervise",
      innerText:"Supervise other clerical staff and provide training and orientation to new staff."
     },
]
}

     ],
 
    description:{
      aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
    }
 
},
energy:{
  mediaInfo:{
    videoType:'youtube',link:"",videoId:'En9Bjtvq-48',imageOrVideo:'image',srcOrHref:'aerospace-careers'
  },
    jobs:[ 
        {
          jobtitle:'Electrical Power Line Installers',
          salary:"$76,000",
          description:'Install or repair cables or wires used in electrical power or distribution systems. May erect poles and light or heavy duty transmission towers',
        
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'qJbC9pYtLK4',
          srcOrHref:''},
          education:"",
          id_gamification:"energy-1",
          communicationbuttons:[
                 {
                  title:"safety",
                  innerText:"Adhere to safety practices and procedures, such as checking equipment regularly and erecting barriers around work areas."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Test conductors, according to electrical diagrams and specifications, to identify corresponding conductors and to prevent incorrect connections."
                 },
                {
                  title:"Repair",
                  innerText:"Open switches or attach grounding devices to remove electrical hazards from disturbed or fallen lines or to facilitate repairs."
                 },
                 {
                  title:"Vehicle Operation",
                  innerText:"Drive vehicles equipped with tools and materials to job sites."
                 },
                 {
                  title:"Operate Machinery",
                  innerText:"Dig holes, using augers, and set poles, using cranes and power equipment."
                 },
                 {
                  title:"Troubleshoot",
                  innerText:"Inspect and test power lines and auxiliary equipment to locate and identify problems, using reading and testing instruments"
                 },
          ]
      },
                {



          jobtitle:'Nuclear Engineers',
          salary:"$66,000 - $151,000",
          description:"Conduct research on nuclear engineering projects or apply principles and theory of nuclear science to problems concerned with release, control, and use of nuclear energy and nuclear waste disposal.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'RX_V2Tt0c4w',
          srcOrHref:''},
          education:'',
          id_gamification:"energy-2",
          communicationbuttons:[
                 {
                  title:"Design",
                  innerText:"Design or develop nuclear equipment, such as reactor cores, radiation shielding, or associated instrumentation or control mechanisms."
                 },
                 {
                  title:"Critical Analysis",
                  innerText:"Examine accidents to obtain data that can be used to design preventive measures."
                 },
                 {
                  title:"Instructing",
                  innerText:"Write operational instructions to be used in nuclear plant operation or nuclear fuel or waste handling and disposal"
                 },
                 {
                  title:"Research",
                  innerText:"Conduct environmental studies related to topics such as nuclear power generation, nuclear waste disposal, or nuclear weapon deployment."
                 },
                 {
                  title:"Safety",
                  innerText:"Initiate corrective actions or order plant shutdowns in emergency situations."
                 },
                 {
                  title:"Active Learning",
                  innerText:"Keep abreast of developments and changes in the nuclear field by reading technical journals or by independent study and research."
                 },
          ]
      },
      {


          jobtitle:"Power Plant Operator",
          description:"Control, operate, or maintain machinery to generate electric power. Includes auxiliary equipment operators.",
          salary:"$76,400",
          education: "",
          id_gamification:"energy-3",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"Ug9QtgaRplo"},
                      
          communicationbuttons:[
                 {
                  title:"Maintenance",
                  innerText:"Clean, lubricate, or maintain equipment, such as generators, turbines, pumps, or compressors, to prevent failure or deterioratio"
                  },
                 {
                  title:"Analysis",
                  innerText:"Analyze the layout, instrumentation, or function of electrical generation or transmission facilities."
                 },
                 {
                  title:"Observation",
                  innerText:"Monitor power plant equipment and indicators to detect evidence of operating problems."
                 },
                 {
                  title:"Machine Operation",
                  innerText:"Adjust controls to generate specified electrical power or to regulate the flow of power between generating stations and substations."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Examine and test electrical power distribution machinery and equipment, using testing devices."
                 },
                 {
                  title:"Communicate",
                  innerText:"Communicate with systems operators to regulate and coordinate line voltages and transmission loads and frequencies."
                 },
          ]
      },
      {
  
  
        jobtitle:"Water Plant Operator",
        description:"Operate or control an entire process or system of machines, often through the use of control boards, to transfer or treat water or wastewater.",
        salary:"$40,800",
        education: "",
        jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/pABK8Di1Y5Q",videoId:"pABK8Di1Y5Q"},
        id_gamification:"energy-4",
        communicationbuttons:[
               {
                title:"Maintenance",
                innerText:"Add chemicals, such as ammonia, chlorine, or lime, to disinfect and deodorize water and other liquids."                     
                },
               {
                title:"Analysis",
                innerText:"Collect and test water and sewage samples, using test equipment and color analysis standards."
               },
               {
                title:"Observation",
                innerText:"Record operational data, personnel attendance, or meter and gauge readings on specified forms."
               },
               {
                title:"Machine Operation",
                innerText:"Operate and adjust controls on equipment to purify and clarify water, process or dispose of sewage, and generate power."
               },
               {
                title:"Quality Control",
                innerText:"Inspect equipment or monitor operating conditions, meters, and gauges to determine load requirements and detect malfunctions."                    },
               {
                title:"Supervise",
                innerText:"Perform routine administrative functions such as drafting correspondence, scheduling appointments, organizing and maintaining paper and electronic files, or providing information to callers."
               },
        ]
    },
    {


      jobtitle:"Administrative Assistant",
      description:"Perform routine administrative functions such as drafting correspondence, scheduling appointments, organizing and maintaining paper and electronic files, or providing information to callers.",
      salary:"$37,200",
      education: "",
      jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/zDqK9DaCDg4",videoId:"zDqK9DaCDg4"},
      id_gamification:"energy-5",
      communicationbuttons:[
             {
              title:"Communicate",
              innerText:"Answer telephones and give information to callers, take messages, or transfer calls to appropriate individuals."                     
              },
             {
              title:"People Skills",
              innerText:"Greet visitors or callers and handle their inquiries or direct them to the appropriate persons according to their needs."
             },
             {
              title:"Data Entry",
              innerText:"Create, maintain, and enter information into databases."
             },
             {
              title:"Computer Usage",
              innerText:"Use computers for various applications, such as database management or word processing."
             },
             {
              title:"Organized",
              innerText:"Establish work procedures or schedules and keep track of the daily work of clerical staff."                    },
             {
              title:"Supervise",
              innerText:"Supervise other clerical staff and provide training and orientation to new staff."
             },
      ]
  },
  {


    jobtitle:"Customer Service Representatives",
    description:"Interact with customers to provide basic or scripted information in response to routine inquiries about products and services. May handle and resolve general complaints. Excludes individuals whose duties are primarily installation, sales, repair, and technical support.",
    salary:"$45,700",
    education: "",
    jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/0-ERXRCB6Nc",videoId:"0-ERXRCB6Nc"},
    id_gamification:"energy-6",
    communicationbuttons:[
           {
            title:"Communicate",
            innerText:"Confer with customers by telephone or in person to provide information about products or services, take or enter orders, cancel accounts, or obtain details of complaints."                     
            },
           {
            title:"People Skills",
            innerText:"Resolve customers' service or billing complaints by performing activities such as exchanging merchandise, refunding money, or adjusting bills."
           },
           {
            title:"Data Entry",
            innerText:"Complete contract forms, prepare change of address records, or issue service discontinuance orders, using computers."
           },
           {
            title:"Sales",
            innerText:"Solicit sales of new or additional services or products."
           },
           {
            title:"Organized",
            innerText:"Keep records of customer interactions or transactions, recording details of inquiries, complaints, or comments, as well as actions taken."                    },
           {
            title:"Analysis",
            innerText:"Review insurance policy terms to determine whether a particular loss is covered by insurance."
           },
    ]
},
{


  jobtitle:"Production Manager",
  description:"Directly supervise and coordinate the activities of production and operating workers, such as inspectors, precision workers, machine setters and operators, assemblers, fabricators, and plant and system operators. Excludes team or work leaders.",
  salary:"$81,500",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/dflNKhApmBE",videoId:"dflNKhApmBE"},
  id_gamification:"energy-7",
  communicationbuttons:[
         {
          title:"Organized",
          innerText:"Keep records of employees' attendance and hours worked."                     
          },
         {
          title:"Detailed",
          innerText:"Inspect materials, products, or equipment to detect defects or malfunctions."
         },
         {
          title:"Communicate",
          innerText:"Confer with other supervisors to coordinate operations and activities within or between departments."
         },
         {
          title:"Training",
          innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
         },
         {
          title:"Leadership",
          innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."                    },
         {
          title:"Analysis",
          innerText:"Determine standards, budgets, production goals, and rates, based on company policies, equipment and labor availability, and workloads."
         },
  ]
},
{


jobtitle:"Maintenance Manager",
description:"Directly supervise and coordinate the activities of mechanics, installers, and repairers. May also advise customers on recommended services. Excludes team or work leaders.",
salary:"$78,800",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/8D6tWZDT2m8",videoId:"8D6tWZDT2m8"},
id_gamification:"energy-8",
communicationbuttons:[
       {
        title:"Leadership",
        innerText:"Recommend or initiate personnel actions, such as hires, promotions, transfers, discharges, or disciplinary measures."                     
        },
       {
        title:"Planning",
        innerText:"Compute estimates and actual costs of factors such as materials, labor, or outside contractors."
       },
       {
        title:"Training",
        innerText:"Conduct or arrange for worker training in safety, repair, or maintenance techniques, operational procedures, or equipment use."
       },
       {
        title:"Tool Usage",
        innerText:"Perform skilled repair or maintenance operations, using equipment such as hand or power tools, hydraulic presses or shears, or welding equipment."
       },
       {
        title:"Organized",
        innerText:"Monitor tool and part inventories and the condition and maintenance of shops to ensure adequate working conditions."                    },
       {
        title:"Communicate",
        innerText:"Meet with vendors or suppliers to discuss products used in repair work."
       },
]
},
{


jobtitle:"Control Valve Mechanic",
description:"Install, repair, and maintain mechanical regulating and controlling devices, such as electric meters, gas regulators, thermostats, safety and flow valves, and other mechanical governors.",
salary:"$46,200",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/vCUW9kkUYFQ",videoId:"vCUW9kkUYFQ"},
id_gamification:"energy-9",
communicationbuttons:[
     {
      title:"Data Entry",
      innerText:"Record maintenance information, including test results, material usage, and repairs made."                     
      },
     {
      title:"Finance",
      innerText:"Collect money due on delinquent accounts."
     },
     {
      title:"Tool Usage",
      innerText:"Disconnect or remove defective or unauthorized meters, using hand tools."
     },
     {
      title:"Fabricate",
      innerText:"Recondition displacement type gas meters and governors, fabricating, machining, or modifying parts needed for repairs."
     },
     {
      title:"Troubleshoot",
      innerText:"Investigate instances of illegal tapping into service lines."                    },
     {
      title:"Writing Skills",
      innerText:"Recommend and write up specifications for changes in hardware, such as house wiring."
     },
]
},
{


jobtitle:"Industrial Machinery Mechanic",
description:"Repair, install, adjust, or maintain industrial production and processing machinery or refinery and pipeline distribution systems. May also install, dismantle, or move machinery and heavy equipment according to plans.",
salary:"$70,100",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/ROJQXBuEnEY",videoId:"ROJQXBuEnEY"},
id_gamification:"energy-10",
communicationbuttons:[
     {
      title:"Troubleshoot",
      innerText:"Repair or maintain the operating condition of industrial production or processing machinery or equipment."                     
      },
     {
      title:"Data Entry",
      innerText:"Record repairs and maintenance performed."
     },
     {
      title:"Detailed",
      innerText:"Examine parts for defects, such as breakage or excessive wear."
     },
     {
      title:"Analysis",
      innerText:"Analyze test results, machine error messages, or information obtained from operators to diagnose equipment problems."
     },
     {
      title:"Leadership",
      innerText:"Assign schedules to work crews."                    },
     {
      title:"Training",
      innerText:"Demonstrate equipment functions and features to machine operators."
     },
]
},
{


jobtitle:"General & Operations Managers",
description:"Plan, direct, or coordinate the operations of public or private sector organizations, overseeing multiple departments or locations. Duties and responsibilities include formulating policies, managing daily operations, and planning the use of materials and human resources, but are too diverse and general in nature to be classified in any one functional area of management or administration, such as personnel, purchasing, or administrative services. Usually manage through subordinate supervisors. Excludes First-Line Supervisors.",
salary:"$118,600",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/2rSN2E1_aPQ",videoId:"2rSN2E1_aPQ"},
id_gamification:"energy-11",
communicationbuttons:[
     {
      title:"Leadership",
      innerText:"Direct and coordinate activities of businesses or departments concerned with the production, pricing, sales, or distribution of products."                     
      },
     {
      title:"Coordinate",
      innerText:"Prepare staff work schedules and assign specific duties."
     },
     {
      title:"Analysis",
      innerText:"Direct or coordinate financial or budget activities to fund operations, maximize investments, or increase efficiency."
     },
     {
      title:"Sales",
      innerText:"Set prices or credit terms for goods or services, based on forecasts of customer demand."
     },
     {
      title:"Marketing",
      innerText:"Develop or implement product-marketing strategies, including advertising campaigns or sales promotions."                    },
     {
      title:"Communicate",
      innerText:"Recommend locations for new facilities, or oversee the remodeling or renovating of current facilities."
     },
]
}


     ],
 
    description:{
      aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
    }
     
},
engineering_and_design:{
  mediaInfo:{
    videoType:'youtube',link:"https://www.youtube.com/watch?v=cUIAZcjjB_g&list=TLPQMjQwMzIwMjHqCZpyRvoORg",videoId:'veg8CcB-xnI',imageOrVideo:'video',srcOrHref:'aerospace-careers'
  },
    jobs:[ 
        {
          jobtitle:'Civil Engineers',
          salary:"$89,800",
          description:"Perform engineering duties in planning, designing, and overseeing construction and maintenance of building structures and facilities, such as roads, railroads, airports, bridges, harbors, channels, dams, irrigation projects, pipelines, power plants, and water and sewage systems.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'-XIQSrYi0O0',
          srcOrHref:'https://youtu.be/-XIQSrYi0O0'},
          education:"",
          id_gamification:"ead-1",
          communicationbuttons:[
                 {
                  title:"Monitoring",
                  innerText:"Inspect project sites to monitor progress and ensure conformance to design specifications and safety or sanitation standards."
                 },
                 {
                  title:"Instructing",
                  innerText:"Provide technical advice to industrial or managerial personnel regarding design, construction, program modifications, or structural repairs"
                 },
                {
                  title:"Critical Analysis",
                  innerText:"Test soils or materials to determine the adequacy and strength of foundations, concrete, asphalt, or steel."
                 },
                 {
                  title:"Management",
                  innerText:"Manage and direct the construction, operations, or maintenance activities at project site."
                 },
                 {
                  title:"Design",
                  innerText:"Design energy-efficient or environmentally sound civil structures."
                 },
                 {
                  title:"Computer Literacy",
                  innerText:"Plan and design transportation or hydraulic systems or structures, using computer-assisted design or drawing tools."
                 },
          ]
      },
      {


          jobtitle:"Art Director",
          description:"Formulate design concepts and presentation approaches for visual productions and media, such as print, broadcasting, video, and film. Direct workers engaged in artwork or layout design.",
          salary:"$33,000 - $106,000",
          education: "",
          id_gamification:"ead-3",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"0xZz2t-Ex1I"},
                      
          communicationbuttons:[
                 {
                  title:"Management",
                  innerText:"Manage own accounts and projects, working within budget and scheduling requirements."
                  },
                 {
                  title:"Quality Control",
                  innerText:"Review and approve art materials, copy materials, and proofs of printed copy developed by staff members."
                 },
                 {
                  title:"Collaborate",
                  innerText:"Work with creative directors to develop design solutions."
                 },
                 {
                  title:"Design",
                  innerText:"Create custom illustrations or other graphic elements."
                 },
                 {
                  title:"Analysis",
                  innerText:"Review illustrative material to determine if it conforms to standards and specifications."
                 },
                 {
                  title:"Creativity",
                  innerText:"Conceptualize and help design interfaces for multimedia games, products, and devices."
                 },
          ]
      },
      {
  
  
        jobtitle:"Electrical Engineers",
        description:"Research, design, develop, test, or supervise the manufacturing and installation of electrical equipment, components, or systems for commercial, industrial, military, or scientific use.",
        salary:"$103,300",
        education: "",
        jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/fhAZ2tZj84A",videoId:"fhAZ2tZj84A"},
        id_gamification:"ead-4",
        communicationbuttons:[
               {
                title:"Computer Literacy",
                innerText:"Operate computer-assisted engineering or design software or equipment to perform engineering tasks."                     
                },
               {
                title:"Communicate",
                innerText:"Confer with engineers, customers, or others to discuss existing or potential engineering projects or products."
               },
               {
                title:"Detail Oriented",
                innerText:"Perform detailed calculations to compute and establish manufacturing, construction, or installation standards or specifications."
               },
               {
                title:"Management",
                innerText:"Supervise or train project team members, as necessary."
               },
               {
                title:"Analysis",
                innerText:"Conduct field surveys or study maps, graphs, diagrams, or other data to identify and correct power system problems."                    },
               {
                title:"Design",
                innerText:"Design electrical systems or components that minimize electric energy requirements, such as lighting systems designed to account for natural lighting."
               },
        ]
    },
    {


      jobtitle:"Industrial Engineers",
      description:"Design, develop, test, and evaluate integrated systems for managing industrial production processes, including human work factors, quality control, inventory control, logistics and material flow, cost analysis, and production coordination.",
      salary:"$101,900",
      education: "",
      jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/kNXMJBQ6oL4",videoId:"kNXMJBQ6oL4"},
      id_gamification:"ead-5",
      communicationbuttons:[
             {
              title:"Teamwork",
              innerText:"Communicate with management and user personnel to develop production and design standards."                     
              },
             {
              title:"Critical Analysis",
              innerText:"Evaluate precision and accuracy of production and testing equipment and engineering drawings to formulate corrective action plan."
             },
             {
              title:"Management",
              innerText:"Direct workers engaged in product measurement, inspection, and testing activities to ensure quality control and reliability."
             },
             {
              title:"Systems Design",
              innerText:"Plan and establish sequence of operations to fabricate and assemble parts or products and to promote efficient utilization."
             },
             {
              title:"Problem Solving",
              innerText:"Recommend methods for improving utilization of personnel, material, and utilities."                    },
             {
              title:"Organization",
              innerText:"Complete production reports, purchase orders, and material, tool, and equipment lists."
             },
      ]
  },
  {



jobtitle:'Mechanical Engineers',
salary:"$99,300",
description:"Perform engineering duties in planning and designing tools, engines, machines, and other mechanically functioning equipment. Oversee installation, operation, maintenance, and repair of equipment such as centralized heat, gas, water, and steam systems.",
jobMedia:{imageOrVideo:'video',
videoType:'youtube',
videoId:'bDg2UcaZzcg',
srcOrHref:''},
education:"",
id_gamification:"ead-2",
communicationbuttons:[
   {
    title:"Analysis",
    innerText:"Read and interpret blueprints, technical drawings, schematics, or computer-generated reports."
   },
   {
    title:"Teamwork",
    innerText:"Confer with engineers or other personnel to implement operating procedures, resolve system malfunctions, or provide technical information."
   },
   {
    title:"Project Management",
    innerText:"Develop, coordinate, or monitor all aspects of production, including selection of manufacturing methods, fabrication, or operation of product designs."
   },
   {
    title:"Quality Control",
    innerText:"Recommend design modifications to eliminate machine or system malfunctions."
   },
   {
    title:"Design",
    innerText:"Design integrated mechanical or alternative systems, such as mechanical cooling systems with natural ventilation systems, to improve energy efficiency."
   },
   {
    title:"Critical Analysis",
    innerText:"Conduct research that tests or analyzes the feasibility, design, operation, or performance of equipment, components, or system"
   },
]
},
{


jobtitle:"Aerospace Engineer",
description:"Perform engineering duties in designing, constructing, and testing aircraft, missiles, and spacecraft. May conduct basic and applied research to evaluate adaptability of materials and equipment to aircraft design and manufacture. May recommend improvements in testing equipment and techniques.",
salary:"$109,900",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/bnxcib-8S4s",videoId:"bnxcib-8S4s"},
id_gamification:"ead-6",
communicationbuttons:[
     {
      title:"Mathematics",
      innerText:"Formulate mathematical models or other methods of computer analysis to develop, evaluate, or modify designs."                     
      },
     {
      title:"System Design",
      innerText:"Design or engineer filtration systems that reduce harmful emissions."
     },
     {
      title:"Fabricate",
      innerText:"Fabricate defective sections or parts, using metal fabricating machines, saws, brakes, shears, and grinders."
     },
     {
      title:"Organized",
      innerText:"Maintain records of performance reports for future reference."
     },
     {
      title:"Leadership",
      innerText:"Direct aerospace research and development programs."                    },
     {
      title:"Research",
      innerText:"Research new materials to determine quality or conformance to environmental standards."
     },
]
},
{


jobtitle:"Visual Merchandiser",
description:"Plan and erect commercial displays, such as those in windows and interiors of retail stores and at trade exhibitions.",
salary:"$33,600",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/w961haPAZR8",videoId:"w961haPAZR8"},
id_gamification:"ead-7",
communicationbuttons:[
     {
      title:"Planning",
      innerText:"Plan commercial displays to entice and appeal to customers."                     
      },
     {
      title:"Detailed",
      innerText:"Arrange properties, furniture, merchandise, backdrops, or other accessories, as shown in prepared sketches."
     },
     {
      title:"Creativity",
      innerText:"Develop ideas or plans for merchandise displays or window decorations."
     },
     {
      title:"Visual Design",
      innerText:"Select themes, lighting, colors, or props to be used."
     },
     {
      title:"Computer Usage",
      innerText:"Use computers to produce signage."                    },
     {
      title:"Communicate",
      innerText:"Instruct sales staff in color coordination of clothing racks or counter displays."
     },
]
},
{


jobtitle:"Graphic Designer",
description:"Design or create graphics to meet specific commercial or promotional needs, such as packaging, displays, or logos. May use a variety of mediums to achieve artistic or decorative effects.",
salary:"$43,400",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/dt6td67yF9E",videoId:"dt6td67yF9E"},
id_gamification:"ead-8",
communicationbuttons:[
     {
      title:"Critical Thinking",
      innerText:"Determine size and arrangement of illustrative material and copy, and select style and size of type."                     
      },
     {
      title:"Communicate",
      innerText:"Confer with clients to discuss and determine layout design."
     },
     {
      title:"Design",
      innerText:"Create designs, concepts, and sample layouts, based on knowledge of layout principles and esthetic design concepts."
     },
     {
      title:"Quality Control",
      innerText:"Review final layouts and suggest improvements as needed."
     },
     {
      title:"Active Learning",
      innerText:"Research new software or design concepts."                    },
     {
      title:"Detail Oriented",
      innerText:"Mark up, paste, and assemble final layouts to prepare layouts for printer."
     },
]
},{
jobtitle:'Marine Engineer',
salary:"$103,400",
description:"Design, develop, and evaluate the operation of marine vessels, ship machinery, and related equipment, such as power supply and propulsion systems.",
jobMedia:{imageOrVideo:'video',
videoType:'youtube',
videoId:'445nfaA6gdI',
srcOrHref:''},
education:"",
id_gamification:"ead-9",
communicationbuttons:[
     {
      title:"Design",
      innerText:"Design complete hull and superstructure according to specifications and test data, in conformity with standards of safety, efficiency, and economy."
     },
     {
      title:"Supervise",
      innerText:"Supervise other engineers and crew members and train them for routine and emergency duties."
     },
    {
      title:"Analysis",
      innerText:"Study design proposals and specifications to establish basic characteristics of craft, such as size, weight, speed, propulsion, displacement, and draft."
     },
     {
      title:"Problem Solving",
      innerText:"Evaluate performance of craft during dock and sea trials to determine design changes and conformance with national and international standards."
     },
     {
      title:"Organized",
      innerText:"Prepare plans, estimates, design and construction schedules, and contract specifications, including any special provisions."
     },
     {
      title:"Quality Control",
      innerText:"Conduct environmental, operational, or performance tests on marine machinery and equipment."
     },
]
},
{
jobtitle:'Floral Designers',
salary:"$25,200",
description:"Design, cut, and arrange live, dried, or artificial flowers and foliage.",
jobMedia:{imageOrVideo:'video',
videoType:'youtube',
videoId:'OF4-CWR2XE8',
srcOrHref:''},
education:"",
id_gamification:"ead-10",
communicationbuttons:[
     {
      title:"Communicate",
      innerText:"Confer with clients regarding price and type of arrangement desired and the date, time, and place of delivery."
     },
     {
      title:"Design",
      innerText:"Select flora and foliage for arrangements, working with numerous combinations to synthesize and develop new creations."
     },
    {
      title:"Logistics",
      innerText:"Order and purchase flowers and supplies from wholesalers and growers."
     },
     {
      title:"Training",
      innerText:"Conduct classes or demonstrations, or train other workers."
     },
     {
      title:"Creativity",
      innerText:"Create and change in-store and window displays, designs, and looks to enhance a shop's image."
     },
     {
      title:"Sales",
      innerText:"Wrap and price completed arrangements."
     },
]
},

     ],
 
    description:{
      aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
    }
     
},
healthcare:{
  mediaInfo:{
    videoType:'youtube',link:"https://youtu.be/qm6c7KDIQq0",videoId:'qm6c7KDIQq0',imageOrVideo:'video',srcOrHref:'https://youtu.be/qm6c7KDIQq0'
  },
    jobs:[
        {
          jobtitle:'Phlebotomists',
          salary:"$23,000 - $43,000",
          description:"Draw blood for tests, transfusions, donations, or research. May explain the procedure to patients and assist in the recovery of patients with adverse reactions.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'A3c4BlLri_U',
          srcOrHref:'https://youtu.be/A3c4BlLri_U'},
          education:"",
          id_gamification:"hc-1",
          communicationbuttons:[
                 {
                  title:"Safety",
                  innerText:"Dispose of contaminated sharps, in accordance with applicable laws, standards, and policies."
                 },
                 {
                  title:"Organize",
                  innerText:"Organize or clean blood-drawing trays, ensuring that all instruments are sterile and all needles, syringes, or related items are of first-time use."
                 },
                {
                  title:"Detail Oriented",
                  innerText:"Collect specimens at specific time intervals for tests, such as those assessing therapeutic drug levels."
                 },
                 {
                  title:"Computer Literacy",
                  innerText:"Enter patient, specimen, insurance, or billing information into computer."
                 },
                 {
                  title:"Instructing",
                  innerText:"Explain fluid or tissue collection procedures to patients."
                 },
                 {
                  title:"Testing",
                  innerText:"Conduct hemoglobin tests to ensure donor iron levels are normal."
                 },
          ]
      },
                {



          jobtitle:'Registered Nurse',
          salary:"$58,300",
          description:"Assess patient health problems and needs, develop and implement nursing care plans, and maintain medical records. Administer nursing care to ill, injured, convalescent, or disabled patients. May advise patients on health maintenance and disease prevention or provide case management. Licensing or registration required.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'x4Y_2fpET0U',
          srcOrHref:'https://youtu.be/x4Y_2fpET0U'},
          education:"",
          id_gamification:"hc-2",
          communicationbuttons:[
                 {
                  title:"Communicate",
                  innerText:"Consult and coordinate with healthcare team members to assess, plan, implement, or evaluate patient care plans."
                 },
                 {
                  title:"Social Perceptiveness",
                  innerText:"Assess the needs of individuals, families, or communities, including assessment of individuals' home or work environments, to identify potential health or safety problems."
                 },
                 {
                  title:"Monitoring",
                  innerText:"Monitor, record, and report symptoms or changes in patients' conditions."
                 },
                 {
                  title:"Service Oriented",
                  innerText:"Assess the needs of individuals, families, or communities, including assessment of individuals' home or work environments, to identify potential health or safety problems."
                 },
                 {
                  title:"Instructing",
                  innerText:"Instruct individuals, families, or other groups on topics such as health education, disease prevention, or childbirth and develop health improvement programs."
                 },
                 {
                  title:"Detail Oriented",
                  innerText:"Administer medications to patients and monitor patients for reactions or side effects."
                 },
          ]
      },
      {


          jobtitle:"Pharmacist",
          description:"Dispense drugs prescribed by physicians and other health practitioners and provide information to patients about medications and their use. May advise physicians and other health practitioners on the selection, dosage, interactions, and side effects of medications.",
          salary:"$108,000 - $160,000",
          education: "",
          id_gamification:"hc-3",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"ploFnvE1770"},
                      
          communicationbuttons:[
                 {
                  title:"Detail Oriented",
                  innerText:"Review prescriptions to assure accuracy, to ascertain the needed ingredients, and to evaluate their suitability."
                  },
                 {
                  title:"Instructing",
                  innerText:"Provide information and advice regarding drug interactions, side effects, dosage, and proper medication storage."
                 },
                 {
                  title:"Organized",
                  innerText:"Order and purchase pharmaceutical supplies, medical supplies, or drugs, maintaining stock and storing and handling it properly."
                 },
                 {
                  title:"Active Learning",
                  innerText:"Analyze prescribing trends to monitor patient compliance and to prevent excessive usage or harmful interactions."
                 },
                 {
                  title:"Customer Service",
                  innerText:"Advise customers on the selection of medication brands, medical equipment, or healthcare supplies"
                 },
                 {
                  title:"Management",
                  innerText:"Manage pharmacy operations, hiring or supervising staff, performing administrative duties, or buying or selling non-pharmaceutical merchandise."
                 },
          ]
      },
      {
  
  
        jobtitle:"Personal Care Aides",
        description:"Provide personalized assistance to individuals with disabilities or illness who require help with personal care and activities of daily living support (e.g., feeding, bathing, dressing, grooming, toileting, and ambulation). May also provide help with tasks such as preparing meals, doing light housekeeping, and doing laundry. Work is performed in various settings depending on the needs of the care recipient and may include locations such as their home, place of work, out in the community, or at a daytime nonresidential facility.",
        salary:"$20,600",
        education: "",
        jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/7llPqIH-DdQ",videoId:"7llPqIH-DdQ"},
        id_gamification:"hc-4",
        communicationbuttons:[
               {
                title:"Organized",
                innerText:"Prepare and maintain records of client progress and services performed, reporting changes in client condition to manager or supervisor. "                     
                },
               {
                title:"Teamwork",
                innerText:"Participate in case reviews, consulting with the team caring for the client, to evaluate the client's needs and plan for continuing services."
               },
               {
                title:"Physical Labor",
                innerText:"Perform housekeeping duties, such as cooking, cleaning, washing clothes or dishes, or running errands."
               },
               {
                title:"Instructing",
                innerText:"Instruct or advise clients on issues, such as household cleanliness, utilities, hygiene, nutrition, or infant care."
               },
               {
                title:"Cooking",
                innerText:"Plan, shop for, or prepare nutritious meals or assist families in planning, shopping for, or preparing nutritious meals."                    },
               {
                title:"Driving",
                innerText:"Transport clients to locations outside the home, such as to physicians' offices or on outings, using a motor vehicle."
               },
        ]
    },
    {


      jobtitle:"Nursing Assistant",
      description:"Provide or assist with basic care or support under the direction of onsite licensed nursing staff. Perform duties such as monitoring of health status, feeding, bathing, dressing, grooming, toileting, or ambulation of patients in a health or nursing facility. May include medication administration and other health-related tasks. Includes nursing care attendants, nursing aides, and nursing attendants.",
      salary:"$23,900",
      education: "",
      jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/wlSsdECNaiU",videoId:"wlSsdECNaiU"},
      id_gamification:"hc-5",
      communicationbuttons:[
             {
              title:"Observe",
              innerText:"Document or otherwise report observations of patient behavior, complaints, or physical symptoms to nurses."                     
              },
             {
              title:"Safety",
              innerText:"Review patients' dietary restrictions, food allergies, and preferences to ensure patient receives appropriate diet."
             },
             {
              title:"Communicate",
              innerText:"Communicate with patients to ascertain feelings or need for assistance or social and emotional support."
             },
             {
              title:"Instructing",
              innerText:"Explain medical instructions to patients or family members."
             },
             {
              title:"Teamwork",
              innerText:"Assist nurses or physicians in the operation of medical equipment or provision of patient care."                    },
             {
              title:"Physical Strength",
              innerText:"Lift or assist others to lift patients to move them on or off beds, examination tables, surgical tables, or stretchers."
             },
      ]
  },
  {


    jobtitle:"Medical Assistants",
    description:"Perform administrative and certain clinical duties under the direction of a physician. Administrative duties may include scheduling appointments, maintaining medical records, billing, and coding information for insurance purposes. Clinical duties may include taking and recording vital signs and medical histories, preparing patients for examination, drawing blood, and administering medications as directed by physician.",
    salary:"$28,200",
    education: "",
    jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/ZsgeduDiK_w",videoId:"ZsgeduDiK_w"},
    id_gamification:"hc-6",
    communicationbuttons:[
           {
            title:"Data Entry",
            innerText:"Record patients' medical history, vital statistics, or information such as test results in medical records."                     
            },
           {
            title:"Cleanliness",
            innerText:"Clean and sterilize instruments and dispose of contaminated supplies."
           },
           {
            title:"Communicate",
            innerText:"Explain treatment procedures, medications, diets, or physicians' instructions to patients."
           },
           {
            title:"Organized",
            innerText:"Collect blood, tissue, or other laboratory specimens, log the specimens, and prepare them for testing."
           },
           {
            title:"Social Skills",
            innerText:"Greet and log in patients arriving at office or clinic."                    },
           {
            title:"Operate Machines",
            innerText:"Operate x-ray, electrocardiogram (EKG), or other equipment to administer routine diagnostic tests."
           },
    ]
},
{


  jobtitle:"Charge Nurse",
  description:"Care for ill, injured, or convalescing patients or persons with disabilities in hospitals, nursing homes, clinics, private homes, group homes, and similar institutions. May work under the supervision of a registered nurse. Licensing required.",
  salary:"$38,000",
  education: "",
  jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/yTBPYOnNjVU",videoId:"yTBPYOnNjVU"},
  id_gamification:"hc-7",
  communicationbuttons:[
         {
          title:"Data Entry",
          innerText:"Measure and record patients' vital signs, such as height, weight, temperature, blood pressure, pulse, or respiration."                     
          },
         {
          title:"Supervise",
          innerText:"Supervise nurses' aides or assistants."
         },
         {
          title:"Analysis",
          innerText:"Evaluate nursing intervention outcomes, conferring with other healthcare team members as necessary."
         },
         {
          title:"Teamwork",
          innerText:"Work as part of a healthcare team to assess patient needs, plan and modify care, and implement interventions."
         },
         {
          title:"Organized",
          innerText:"Collect samples, such as blood, urine, or sputum from patients, and perform routine laboratory tests on samples."                    },
         {
          title:"Detailed",
          innerText:"Administer prescribed medications or start intravenous fluids, noting times and amounts on patients' charts."
         },
  ]
},
{


jobtitle:"Office Assistant",
description:"Answer inquiries and provide information to the general public, customers, visitors, and other interested parties regarding activities conducted at establishment and location of departments, offices, and employees within the organization.",
salary:"$27,100",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/pvH8lSAU6Sw",videoId:"pvH8lSAU6Sw"},
id_gamification:"hc-8",
communicationbuttons:[
       {
        title:"Organized",
        innerText:"Operate telephone switchboard to answer, screen, or forward calls, providing information, taking messages, or scheduling appointments."                     
        },
       {
        title:"Social Skills",
        innerText:"Greet persons entering establishment, determine nature and purpose of visit, and direct or escort them to specific destinations."
       },
       {
        title:"Problem Solving",
        innerText:"Hear and resolve complaints from customers or the public."
       },
       {
        title:"Analysis",
        innerText:"Analyze data to determine answers to questions from customers or members of the public."
       },
       {
        title:"Data Entry",
        innerText:"Take orders for merchandise or materials and send them to the proper departments to be filled."                    },
       {
        title:"Mathematics",
        innerText:"Calculate and quote rates for tours, stocks, insurance policies, or other products or services."
       },
]
},
{


jobtitle:"Medical Secretary",
description:"Perform secretarial duties using specific knowledge of medical terminology and hospital, clinic, or laboratory procedures. Duties may include scheduling appointments, billing patients, and compiling and recording medical charts, reports, and correspondence.",
salary:"$32,500",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/jpvPQ5oFmHE",videoId:"jpvPQ5oFmHE"},
id_gamification:"hc-9",
communicationbuttons:[
     {
      title:"Organized",
      innerText:"Answer telephones and direct calls to appropriate staff."                     
      },
     {
      title:"Data Entry",
      innerText:"Transcribe recorded messages or practitioners' diagnoses or recommendations into patients' medical records."
     },
     {
      title:"Social Skills",
      innerText:"Greet visitors, ascertain purpose of visit, and direct them to appropriate staff."
     },
     {
      title:"Computer Usage",
      innerText:"Schedule and confirm patient diagnostic appointments, surgeries, or medical consultations."
     },
     {
      title:"Detailed",
      innerText:"Interview patients to complete documents, case histories, or forms, such as intake or insurance forms."                    },
     {
      title:"Finance",
      innerText:"Perform bookkeeping duties, such as credits or collections, preparing and sending financial statements or bills, and keeping financial records."
     },
]
},
{


jobtitle:"Home Health Aide",
description:"Monitor the health status of an individual with disabilities or illness, and address their health-related needs, such as changing bandages, dressing wounds, or administering medication. Work is performed under the direction of offsite or intermittent onsite licensed nursing staff. Provide assistance with routine healthcare tasks or activities of daily living, such as feeding, bathing, toileting, or ambulation. May also help with tasks such as preparing meals, doing light housekeeping, and doing laundry depending on the patient's abilities.",
salary:"$20,600",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/_AyWpdXW5o",videoId:"_AyWpdXW5o"},
id_gamification:"hc-10",
communicationbuttons:[
     {
      title:"Detailed",
      innerText:"Maintain records of patient care, condition, progress, or problems to report and discuss observations with supervisor or case manager."                     
      },
     {
      title:"Physical Labor",
      innerText:"Provide patients with help moving in and out of beds, baths, wheelchairs, or automobiles and with dressing and grooming."
     },
     {
      title:"Social Skills",
      innerText:"Entertain, converse with, or read aloud to patients to keep them mentally healthy and alert."
     },
     {
      title:"Childcare",
      innerText:"Care for children who are disabled or who have sick or disabled parents."
     },
     {
      title:"Therapy",
      innerText:"Massage patients or apply preparations or treatments, such as liniment, alcohol rubs, or heat-lamp stimulation."                    },
     {
      title:"Teaching",
      innerText:"Direct patients in simple prescribed exercises or in the use of braces or artificial limbs."
     },
]
},
{


jobtitle:"Childcare Worker",
description:"Attend to children at schools, businesses, private households, and childcare institutions. Perform a variety of tasks, such as dressing, feeding, bathing, and overseeing play.",
salary:"$18,600",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/xjYk0w_MMmw",videoId:"xjYk0w_MMmw"},
id_gamification:"hc-11",
communicationbuttons:[
     {
      title:"Communicate",
      innerText:"Communicate with children's parents or guardians about daily activities, behaviors, and related issues."                     
      },
     {
      title:"Empathy",
      innerText:"Support children's emotional and social development, encouraging understanding of others and positive self-concepts."
     },
     {
      title:"Perception",
      innerText:"Identify signs of emotional or developmental problems in children and bring them to parents' or guardians' attention."
     },
     {
      title:"Teaching",
      innerText:"Instruct children in health and personal habits, such as eating, resting, and toilet habits."
     },
     {
      title:"Organized",
      innerText:"Perform general administrative tasks, such as taking attendance, editing internal paperwork, and making phone calls."                    },
     {
      title:"Social Skills",
      innerText:"Read to children and teach them simple painting, drawing, handicrafts, and songs."
     },
]
},
{


jobtitle:"Administrative Assistant",
description:"Perform routine administrative functions such as drafting correspondence, scheduling appointments, organizing and maintaining paper and electronic files, or providing information to callers.",
salary:"$34,900",
education: "",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/zDqK9DaCDg4",videoId:"zDqK9DaCDg4"},
id_gamification:"hc-12",
communicationbuttons:[
     {
      title:"Communicate",
      innerText:"Answer telephones and give information to callers, take messages, or transfer calls to appropriate individuals."                     
      },
     {
      title:"People Skills",
      innerText:"Greet visitors or callers and handle their inquiries or direct them to the appropriate persons according to their needs."
     },
     {
      title:"Data Entry",
      innerText:"Create, maintain, and enter information into databases."
     },
     {
      title:"Computer Usage",
      innerText:"Use computers for various applications, such as database management or word processing."
     },
     {
      title:"Organized",
      innerText:"Establish work procedures or schedules and keep track of the daily work of clerical staff."                    },
     {
      title:"Supervise",
      innerText:"Supervise other clerical staff and provide training and orientation to new staff."
     },
]
}

     ],
 
    description:{
      aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
    }
     
},
information_technology:{
  mediaInfo:{
    videoType:'youtube',link:"https://www.youtube.com/watch?v=cUIAZcjjB_g&list=TLPQMjQwMzIwMjHqCZpyRvoORg",videoId:'cbffW0MeWoY',imageOrVideo:'video',srcOrHref:'aerospace-careers'
  },
    jobs:[ 
        {
          jobtitle:'Database Administrator',
          salary:"$44,000 - $99,000",
          description:"Administer, test, and implement computer databases, applying knowledge of database management systems. Coordinate changes to computer databases. Identify, investigate, and resolve database performance issues, database capacity, and database scalability. May plan, coordinate, and implement security measures to safeguard computer databases",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'GKAnWFOyk8E',
          srcOrHref:''},
          education:"",
          id_gamification:"hat-1",
          communicationbuttons:[
                 {
                  title:"Troubleshoot",
                  innerText:"Test programs or databases, correct errors, and make necessary modifications"
                 },
                 {
                  title:"Safety",
                  innerText:"Develop standards and guidelines to guide the use and acquisition of software and to protect vulnerable information."
                 },
                {
                  title:"Instructing",
                  innerText:"Train users and answer questions."
                 },
                 {
                  title:"Coding",
                  innerText:"Write and code logical and physical database descriptions and specify identifiers of database to management system or direct others in coding descriptions."
                 },
                 {
                  title:"Critical Analysis",
                  innerText:"Develop data model describing data elements and how they are used, following procedures and using pen, template or computer software."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Test changes to database applications or systems."
                 },
          ]
      },
                {



          jobtitle:'IT Project Manager',
          salary:"$30,000  - $110,000",
          description:"Plan, initiate, and manage information technology (IT) projects. Lead and guide the work of technical staff. Serve as liaison between business and technical aspects of projects. Plan project stages and assess business implications for each stage. Monitor progress to assure deadlines, standards, and cost targets are met.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'LVkXobqdvbk',
          srcOrHref:''},
          education:"",
          id_gamification:"hat-2",
          communicationbuttons:[
                 {
                  title:"Organization",
                  innerText:"Monitor or track project milestones and deliverables."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Submit project deliverables, ensuring adherence to quality standards."
                 },
                 {
                  title:"Customer Service",
                  innerText:"Assess current or future customer needs and priorities by communicating directly with customers, conducting surveys, or other methods."
                 },
                 {
                  title:"Planning",
                  innerText:"Initiate, review, or approve modifications to project plans."
                 },
                 {
                  title:"Time Management",
                  innerText:"Schedule and facilitate meetings related to information technology projects."
                 },
                 {
                  title:"Critical Analysis",

                  innerText:"Develop implementation plans that include analyses such as cost-benefit or return on investment (ROI)."
                 },
          ]
      },
      {


          jobtitle:"Web Developer",
          description:"Develop and implement websites, web applications, application databases, and interactive web interfaces. Evaluate code to ensure that it is properly structured, meets industry standards, and is compatible with browsers and devices. Optimize website performance, scalability, and server-side code and processes. May develop website infrastructure and integrate websites with other computer applications.",
          salary:"$30,000 - $70,000",
          education: "",
          id_gamification:"hat-3",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"FfxTkIsaTYk"},
                      
          communicationbuttons:[
                 {
                  title:"Programming",
                  innerText:"Design, build, or maintain Web sites, using authoring or scripting languages, content creation tools, management tools, and digital media."
                  },
                 {
                  title:"Analysis",
                  innerText:"Analyze user needs to determine technical requirements."
                 },
                 {
                  title:"Communicate",
                  innerText:"Respond to user email inquiries, or set up automated systems to send responses."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Perform Web site tests according to planned schedules, or after any Web site or product revision."
                 },
                 {
                  title:"Teamwork",
                  innerText:"Confer with management or development teams to prioritize needs, resolve conflicts, develop content criteria, or choose solutions."
                 },
                 {
                  title:"Prototyping",
                  innerText:"Create Web models or prototypes that include physical, interface, logical, or data models."
                 },
          ]
      },
      {


          jobtitle:"Computer User Support Specialist",
          description:"Provide technical assistance to computer users. Answer questions or resolve computer problems for clients in person, via telephone, or electronically. May provide assistance concerning the use of computer hardware and software, including printing, installation, word processing, electronic mail, and operating systems.",
          salary:"$50,500",
          education: "",
          id_gamification:"hat-4",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/xe6GS8kSN1g",videoId:"xe6GS8kSN1g"},
                      
          communicationbuttons:[
                 {
                  title:"Customer Support",
                  innerText:"Answer user inquiries regarding computer software or hardware operation to resolve problems."
                  },
                 {
                  title:"Troubleshoot",
                  innerText:"Read technical manuals, confer with users, or conduct computer diagnostics to investigate and resolve problems or to provide technical assistance and support."
                 },
                 {
                  title:"Instructing",
                  innerText:"Develop training materials and procedures, or train users in the proper use of hardware or software."
                 },
                 {
                  title:"Organization",
                  innerText:"Maintain records of daily data communication transactions, problems and remedial actions taken, or installation activities."
                 },
                 {
                  title:"Communicate",
                  innerText:"Confer with staff, users, and management to establish requirements for new systems or modifications."
                 },
                 {
                  title:"Systems Analysis",
                  innerText:"Prepare evaluations of software or hardware, and recommend improvements or upgrades."
                 },
          ]
      },
      {


          jobtitle:"Software Quality Engineer",
          description:"Develop and execute software tests to identify software problems and their causes. Test system modifications to prepare for implementation. Document software and application defects using a bug tracking system and report defects to software or web developers. Create and maintain databases of known defects. May participate in software design reviews to provide input on functional requirements, operational characteristics, product designs, and schedules.",
          salary:"$87,300",
          education: "",
          id_gamification:"hat-5",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/Xr-EoC9DHyc",videoId:"Xr-EoC9DHyc"},
                      
          communicationbuttons:[
                 {
                  title:"Detailed",
                  innerText:"Document software defects, using a bug tracking system, and report defects to software developers."
                  },
                 {
                  title:"Systems Analysis",
                  innerText:"Identify, analyze, and document problems with program function, output, online screen, or content."
                 },
                 {
                  title:"Teamwork",
                  innerText:"Participate in product design reviews to provide input on functional requirements, product designs, schedules, or potential problems."
                 },
                 {
                  title:"Organized",
                  innerText:"Plan test schedules or strategies in accordance with project scope or delivery dates."
                 },
                 {
                  title:"Troubleshoot",
                  innerText:"Provide feedback and recommendations to developers on software usability and functionality."
                 },
                 {
                  title:"Computer Literacy",
                  innerText:"Install, maintain, or use software testing programs."
                 },
          ]
      },
      {


          jobtitle:"Computer Systems Analysts",
          description:"Analyze science, engineering, business, and other data processing problems to develop and implement solutions to complex applications problems, system administration issues, or network concerns. Perform systems management and integration functions, improve existing computer systems, and review computer system capabilities, workflow, and schedule limitations. May analyze or recommend commercially available software.",
          salary:"$77,000",
          education: "",
          id_gamification:"hat-6",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/T0JdNufvn-o",videoId:"T0JdNufvn-o"},
                      
          communicationbuttons:[
                 {
                  title:"Detailed",
                  innerText:"Test, maintain, and monitor computer programs and systems, including coordinating the installation of computer programs and systems."
                  },
                 {
                  title:"Teaching",
                  innerText:"Train staff and users to work with computer systems and programs."
                 },
                 {
                  title:"Analysis",
                  innerText:"Assess the usefulness of pre-developed application packages and adapt them to a user environment."
                 },
                 {
                  title:"Planning",
                  innerText:"Define the goals of the system and devise flow charts and diagrams describing logical operational steps of programs."
                 },
                 {
                  title:"Support",
                  innerText:"Provide staff and users with assistance solving computer-related problems, such as malfunctions and program problems."
                 },
                 {
                  title:"Programming",
                  innerText:"Use object-oriented programming languages, as well as client and server applications development processes and multimedia and Internet technology."
                 },
          ]
      },
      {


          jobtitle:"Computer Programmer",
          description:"Create, modify, and test the code and scripts that allow computer applications to run. Work from specifications drawn up by software and web developers or other individuals. May develop and write computer programs to store, locate, and retrieve specific documents, data, and information.",
          salary:"$102,200",
          education: "",
          id_gamification:"hat-7",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/4FT15GxJQrE",videoId:"4FT15GxJQrE"},
                      
          communicationbuttons:[
                 {
                  title:"Detailed",
                  innerText:"Correct errors by making appropriate changes and rechecking the program to ensure that the desired results are produced."
                  },
                 {
                  title:"Communicate",
                  innerText:"Consult with managerial, engineering, and technical personnel to clarify program intent, identify problems, and suggest changes."
                 },
                 {
                  title:"Detailed",
                  innerText:"Conduct trial runs of programs and software applications to be sure they will produce the desired information and that the instructions are correct."
                 },
                 {
                  title:"Teamwork",
                  innerText:"Consult with and assist computer operators or system analysts to define and resolve problems in running computer programs."
                 },
                 {
                  title:"Analysis",
                  innerText:"Perform systems analysis and programming tasks to maintain and control the use of computer systems software as a systems programmer."
                 },
                 {
                  title:"Technical Writing",
                  innerText:"Write or contribute to instructions or manuals to guide end users."
                 },
          ]
      },
      {


          jobtitle:"IT Director",
          description:"Plan, direct, or coordinate activities in such fields as electronic data processing, information systems, systems analysis, and computer programming.",
          salary:"$118,100",
          education: "",
          id_gamification:"hat-8",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/WQmsKH7mIqo",videoId:"WQmsKH7mIqo"},
                      
          communicationbuttons:[
                 {
                  title:"Teamwork",
                  innerText:"Meet with department heads, managers, supervisors, vendors, and others, to solicit cooperation and resolve problems."
                  },
                 {
                  title:"Leadership",
                  innerText:"Direct daily operations of department, analyzing workflow, establishing priorities, developing standards and setting deadlines."
                 },
                 {
                  title:"Detailed",
                  innerText:"Assign and review the work of systems analysts, programmers, and other computer-related workers."
                 },
                 {
                  title:"Social Skills",
                  innerText:"Provide users with technical support for computer problems."
                 },
                 {
                  title:"Research",
                  innerText:"Stay abreast of advances in technology."
                 },
                 {
                  title:"Technical Writing",
                  innerText:"Evaluate data processing proposals to assess project feasibility and requirements."
                 },
          ]
      },
      {


          jobtitle:"General & Operations Managers",
          description:"Plan, direct, or coordinate the operations of public or private sector organizations, overseeing multiple departments or locations. Duties and responsibilities include formulating policies, managing daily operations, and planning the use of materials and human resources, but are too diverse and general in nature to be classified in any one functional area of management or administration, such as personnel, purchasing, or administrative services. Usually manage through subordinate supervisors. Excludes First-Line Supervisors.",
          salary:"$158,200",
          education: "",
          id_gamification:"hat-9",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/2rSN2E1_aPQ",videoId:"2rSN2E1_aPQ"},
                      
          communicationbuttons:[
                 {
                  title:"Leadership",
                  innerText:"Direct and coordinate activities of businesses or departments concerned with the production, pricing, sales, or distribution of products."
                  },
                 {
                  title:"Coordinate",
                  innerText:"Prepare staff work schedules and assign specific duties."
                 },
                 {
                  title:"Analysis",
                  innerText:"Direct or coordinate financial or budget activities to fund operations, maximize investments, or increase efficiency."
                 },
                 {
                  title:"Sales",
                  innerText:"Set prices or credit terms for goods or services, based on forecasts of customer demand."
                 },
                 {
                  title:"Marketing",
                  innerText:"Develop or implement product-marketing strategies, including advertising campaigns or sales promotions."
                 },
                 {
                  title:"Communicate",
                  innerText:"Recommend locations for new facilities, or oversee the remodeling or renovating of current facilities."
                 },
          ]
      },
      {


          jobtitle:"Network Administrator",
          description:"Install, configure, and maintain an organization's local area network (LAN), wide area network (WAN), data communications network, operating systems, and physical and virtual servers. Perform system monitoring and verify the integrity and availability of hardware, network, and server resources and systems. Review system and application logs and verify completion of scheduled jobs, including system backups. Analyze network and server resource consumption and control user access. Install and upgrade software and maintain software licenses. May assist in network modeling, analysis, planning, and coordination between network and data communications hardware and software.",
          salary:"$68,700",
          education: "",
          id_gamification:"hat-10",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/H3kIfqDCY14",videoId:"H3kIfqDCY14"},
                      
          communicationbuttons:[
                 {
                  title:"Planning",
                  innerText:"Perform data backups and disaster recovery operations."
                  },
                 {
                  title:"Computer Usage",
                  innerText:"Configure, monitor, and maintain email applications or virus protection software."
                 },
                 {
                  title:"Safety",
                  innerText:"Plan, coordinate, and implement network security measures to protect data, software, and hardware."
                 },
                 {
                  title:"Hands-On",
                  innerText:"Design, configure, and test computer hardware, networking software and operating system software."
                 },
                 {
                  title:"Teaching",
                  innerText:"Train people in computer system use."
                 },
                 {
                  title:"Data Entry",
                  innerText:"Maintain logs related to network functions, as well as maintenance and repair records."
                 },
          ]
      },
      {


          jobtitle:"Sales Representative",
          description:"Sell services to individuals or businesses. May describe options or resolve client problems.",
          salary:"$73,100",
          education: "",
          id_gamification:"hat-11",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/To04x9Um1VM",videoId:"To04x9Um1VM"},
                      
          communicationbuttons:[
                 {
                  title:"Social Skills",
                  innerText:"Answer customers' questions about services, prices, availability, or credit terms."
                  },
                 {
                  title:"Troubleshoot",
                  innerText:"Consult with clients after sales or contract signings to resolve problems and provide ongoing support."
                 },
                 {
                  title:"Active Listening",
                  innerText:"Contact prospective or existing customers to discuss how services can meet their needs."
                 },
                 {
                  title:"Data Entry",
                  innerText:"Create forms or agreements to complete sales."
                 },
                 {
                  title:"Sales",
                  innerText:"Develop sales presentations or proposals to explain service specifications."
                 },
                 {
                  title:"Marketing",
                  innerText:"Distribute promotional materials at meetings, conferences, or tradeshows."
                 },
          ]
      },
      {


          jobtitle:"Customer Service Representatives",
          description:"Interact with customers to provide basic or scripted information in response to routine inquiries about products and services. May handle and resolve general complaints. Excludes individuals whose duties are primarily installation, sales, repair, and technical support.",
          salary:"$45,700",
          education: "",
          id_gamification:"hat-12",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/0-ERXRCB6Nc",videoId:"0-ERXRCB6Nc"},
                      
          communicationbuttons:[
                 {
                  title:"Communicate",
                  innerText:"Confer with customers by telephone or in person to provide information about products or services, take or enter orders, cancel accounts, or obtain details of complaints."
                  },
                 {
                  title:"People Skills",
                  innerText:"Resolve customers' service or billing complaints by performing activities such as exchanging merchandise, refunding money, or adjusting bills."
                 },
                 {
                  title:"Data Entry",
                  innerText:"Complete contract forms, prepare change of address records, or issue service discontinuance orders, using computers."
                 },
                 {
                  title:"Sales",
                  innerText:"Solicit sales of new or additional services or products."
                 },
                 {
                  title:"Organized",
                  innerText:"Keep records of customer interactions or transactions, recording details of inquiries, complaints, or comments, as well as actions taken."
                 },
                 {
                  title:"Analysis",
                  innerText:"Review insurance policy terms to determine whether a particular loss is covered by insurance."
                 },
          ]
      }



     ],
 
    description:{
      aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
    }
     
},
hospitality_and_tourism:{
  mediaInfo:{
    videoType:'youtube',link:"https://www.youtube.com/watch?v=j5IJHSpu31w",videoId:'j5IJHSpu31w',imageOrVideo:'video',srcOrHref:'https://www.youtube.com/watch?v=j5IJHSpu31w'
  },
    jobs:[ 
        {
          jobtitle:'Restaurant Managers',
          salary:"$35,000 - $101,000",
          description:"Plan, direct, or coordinate activities of an organization or department that serves food and beverages.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'OzTbTaVPM2w',
          srcOrHref:''},
          education:"",
          id_gamification:"it-1",
          communicationbuttons:[
                 {
                  title:"Scheduling",
                  innerText:"Schedule and receive food and beverage deliveries, checking delivery contents to verify product quality and quantity."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Test cooked food by tasting and smelling it to ensure palatability and flavor conformity."
                 },
                {
                  title:"Organization",
                  innerText:"Plan menus and food utilization, based on anticipated number of guests, nutritional value, palatability, popularity, and costs."
                 },
                 {
                  title:"Critical Thinking",
                  innerText:"Review work procedures and operational problems to determine ways to improve service, performance, or safety."
                 },
                 {
                  title:"Customer Service",
                  innerText:"Greet guests, escort them to their seats, and present them with menus and wine lists."
                 },
                 {
                  title:"Management",
                  innerText:"Schedule staff hours and assign duties."
                 },
          ]
      },
                {



          jobtitle:'Chefs & Head Cooks',
          salary:"$30,000 - $77,000",
          description:"Direct and may participate in the preparation, seasoning, and cooking of salads, soups, fish, meats, vegetables, desserts, or other foods. May plan and price menu items, order supplies, and keep records and accounts.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'BtAevVMysC0',
          srcOrHref:''},
          education:"",
          id_gamification:"it-2",
          communicationbuttons:[
                 {
                  title:"Quality Control",
                  innerText:"Check the quality of raw or cooked food products to ensure that standards are met."
                 },
                 {
                  title:"Management",
                  innerText:"Supervise or coordinate activities of cooks or workers engaged in food preparation."
                 },
                 {
                  title:"Creativity",
                  innerText:"Determine how food should be presented and create decorative food displays."
                 },
                 {
                  title:"Critical Analysis",
                  innerText:"Analyze recipes to assign prices to menu items, based on food, labor, and overhead costs."
                 },
                 {
                  title:"Communicate",
                  innerText:"Meet with customers to discuss menus for special occasions, such as weddings, parties, or banquets."
                 },
                 {
                  title:"Instruction",
                  innerText:"Demonstrate new cooking techniques or equipment to staff."
                 },
          ]
      },
      {


          jobtitle:"Lodging Managers",
          description:"Plan, direct, or coordinate activities of an organization or department that provides lodging and other accommodations.",
          salary:"$30,000 - $76,000",
          education: "",
          id_gamification:"it-3",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"N6wsqmVAvnQ"},
                      
          communicationbuttons:[
                 {
                  title:"Customer Service",
                  innerText:"Answer inquiries pertaining to hotel policies and services, and resolve occupants' complaints."
                  },
                 {
                  title:"Teamwork",
                  innerText:"Confer and cooperate with other managers to ensure coordination of hotel activities."
                 },
                 {
                  title:"Instruction",
                  innerText:"Train staff members."
                 },
                 {
                  title:"Management",
                  innerText:"Assign duties to workers, and schedule shifts."
                 },
                 {
                  title:"Coordinate",
                  innerText:"Organize and coordinate the work of staff and convention personnel for meetings to be held at a particular facility."
                 },
                 {
                  title:"Problem Solving",
                  innerText:"Develop and implement policies and procedures for the operation of a department or establishment."
                 },
          ]
      }

     ],
 
    description:{
      aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
    }
     
},
manufacturing:{
  mediaInfo:{
    videoType:'youtube',link:"https://www.youtube.com/watch?v=L1kYKXLXf2A",videoId:'L1kYKXLXf2A',imageOrVideo:'video',srcOrHref:'https://www.youtube.com/watch?v=L1kYKXLXf2A'
  },
    jobs:[ 
        {
          jobtitle:'Electrical Engineers',
          salary:"$66,000 - $152,000",
          description:"Research, design, develop, test, or supervise the manufacturing and installation of electrical equipment, components, or systems for commercial, industrial, military, or scientific use.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'fhAZ2tZj84A',
          srcOrHref:''},
          education:"",
          id_gamification:"manufac-1",
          communicationbuttons:[
                 {
                  title:"Computer Literacy",
                  innerText:"Operate computer-assisted engineering or design software or equipment to perform engineering tasks."
                 },
                 {
                  title:"Communicate",
                  innerText:"Confer with engineers, customers, or others to discuss existing or potential engineering projects or products."
                 },
                {
                  title:"Detail Oriented",
                  innerText:"Perform detailed calculations to compute and establish manufacturing, construction, or installation standards or specifications."

                 },
                 {
                  title:"Management",
                  innerText:"Supervise or train project team members, as necessary."
                 },
                 {
                  title:"Analysis",
                  innerText:"Conduct field surveys or study maps, graphs, diagrams, or other data to identify and correct power system problems."

                 },
                 {
                  title:"Design",
                  innerText:"Design electrical systems or components that minimize electric energy requirements, such as lighting systems designed to account for natural lighting."
                 },
          ]
      },
                {



          jobtitle:'Chemical Engineers',
          salary:"$75,000 - $164,000",
          description:"Design chemical plant equipment and devise processes for manufacturing chemicals and products, such as gasoline, synthetic rubber, plastics, detergents, cement, paper, and pulp, by applying principles and technology of chemistry, physics, and engineering.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'cFQ4BjDeTME',
          srcOrHref:''},
          education:"",
          id_gamification:"manufac-2",
          communicationbuttons:[
                 {
                  title:"Quality Control",
                  innerText:"Monitor and analyze data from processes and experiments.",
                 },
                 {
                  title:"Safety",
                  innerText:"Develop safety procedures to be employed by workers operating equipment or working in close proximity to ongoing chemical reactions."

                 },
                 {
                  title:"Chemistry",
                  innerText:"Develop processes to separate components of liquids or gases or generate electrical currents, using controlled chemical processes."
                 },
                 {
                  title:"Troubleshoot",
                  innerText:"Troubleshoot problems with chemical manufacturing processes."
                 },
                 {
                  title:"Research",
                  innerText:"Conduct research to develop new and improved chemical manufacturing processes."
                 },
                 {
                  title:"Systems Design",
                  innerText:"Design measurement and control systems for chemical plants based on data collected in laboratory experiments and in pilot plant operations."
                 },
          ]
      },
      {


          jobtitle:"Maintenance Mechanic",
          description:"Perform work involving the skills of two or more maintenance or craft occupations to keep machines, mechanical equipment, or the structure of a building in repair. Duties may involve pipe fitting; HVAC maintenance; insulating; welding; machining; carpentry; repairing electrical or mechanical equipment; installing, aligning, and balancing new equipment; and repairing buildings, floors, or stairs.",
          salary:"$35,000 - $81,000",
          education: "",
          id_gamification:"manufac-3",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"I_mhZjRcH5s"},
                      
          communicationbuttons:[
                 {
                  title:"Machinery Maintenance",
                  innerText:"Dismantle machines, equipment, or devices to access and remove defective parts, using hoists, cranes, hand tools, or power tools."
                  },
                 {
                  title:"Quality Control",
                  innerText:"Inspect, operate, or test machinery or equipment to diagnose machine malfunctions."

                 },
                 {
                  title:"Detail Oriented",
                  innerText:"Plan and lay out repair work, using diagrams, drawings, blueprints, maintenance manuals, or schematic diagrams."
                 },
                 {
                  title:"Welding",
                  innerText:"Operate cutting torches or welding equipment to cut or join metal parts."

                 },
                 {
                  title:"Fabricate",
                  innerText:"Fabricate or repair counters, benches, partitions, or other wooden structures, such as sheds or outbuildings."
                 },
                 {
                  title:"Critical Thinking",
                  innerText:"Estimate costs to repair machinery, equipment, or building structures."
                 },
          ]
      },
      {


          jobtitle:"Precision Machinist",
          description:"Set up and operate a variety of machine tools to produce precision parts and instruments out of metal. Includes precision instrument makers who fabricate, modify, or repair mechanical instruments. May also fabricate and modify parts to make or repair machine tools or maintain industrial machines, applying knowledge of mechanics, mathematics, metal properties, layout, and machining procedures.",
          salary:"$49,900",
          education: "",
          id_gamification:"manufac-4",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/OWNXI09WI14",videoId:"OWNXI09WI14"},
                      
          communicationbuttons:[
                 {
                  title:"Accuracy",
                  innerText:"Calculate dimensions or tolerances, using instruments, such as micrometers or vernier calipers."
                  },
                 {
                  title:"Monitor",
                  innerText:"Monitor the feed and speed of machines during the machining process."
                 },
                 {
                  title:"Analysis",
                  innerText:"Study sample parts, blueprints, drawings, or engineering information to determine methods or sequences of operations needed to fabricate products."
                 },
                 {
                  title:"Organize",
                  innerText:"Separate scrap waste and related materials for reuse, recycling, or disposal."
                 },
                 {
                  title:"Design",
                  innerText:"Design fixtures, tooling, or experimental parts to meet special engineering needs."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Maintain machine tools in proper operational condition."
                 },
          ]
      },
      {


          jobtitle:"Welders",
          description:"Use hand-welding, flame-cutting, hand-soldering, or brazing equipment to weld or join metal components or to fill holes, indentations, or seams of fabricated metal products.",
          salary:"$48,600",
          education: "",
          id_gamification:"manufac-5",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/cETNESaLhcA",videoId:"cETNESaLhcA"},
                      
          communicationbuttons:[
                 {
                  title:"Welding",
                  innerText:"Weld components in flat, vertical, or overhead positions."
                  },
                 {
                  title:"Safety",
                  innerText:"Operate safety equipment and use safe work habits."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
                 },
                 {
                  title:"Hand Tools",
                  innerText:"Position and secure workpieces, using hoists, cranes, wire, and banding machines or hand tools."
                 },
                 {
                  title:"Analysis",
                  innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
                 },
                 {
                  title:"Design",
                  innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
                 },
          ]
      },
      {


          jobtitle:"Shipping and Receiving Materials Handler",
          description:"Manually move freight, stock, luggage, or other materials, or perform other general labor. Includes all manual laborers not elsewhere classified.",
          salary:"$28,400",
          education: "",
          id_gamification:"manufac-6",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/wQr_uQMpmEM",videoId:"wQr_uQMpmEM"},
                      
          communicationbuttons:[
                 {
                  title:"Detailed",
                  innerText:"Attach identifying tags to containers or mark them with identifying information."
                  },
                 {
                  title:"Task Oriented",
                  innerText:"Read work orders or receive oral instructions to determine work assignments or material or equipment needs."
                 },
                 {
                  title:"Physical Labor",
                  innerText:"Stack cargo in locations, such as transit sheds or in holds of ships as directed, using pallets or cargo boards."
                 },
                 {
                  title:"Safety",
                  innerText:"Guide loads being lifted to prevent swinging."
                 },
                 {
                  title:"Tool Usage",
                  innerText:"Rig or dismantle props or equipment, such as frames, scaffolding, platforms, or backdrops, using hand tools."
                 },
                 {
                  title:"Organized",
                  innerText:"Maintain equipment storage areas to ensure that inventory is protected."
                 },
          ]
      },
      {


          jobtitle:"Team Assemblers",
          description:"Work as part of a team having responsibility for assembling an entire product or component of a product. Team assemblers can perform all tasks conducted by the team in the assembly process and rotate through all or most of them, rather than being assigned to a specific task on a permanent basis. May participate in making management decisions affecting the work. Includes team leaders who work as part of the team.",
          salary:"$32,700",
          education: "",
          id_gamification:"manufac-7",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/TSn6ghApPKM",videoId:"TSn6ghApPKM"},
                      
          communicationbuttons:[
                 {
                  title:"Welding",
                  innerText:"Weld components in flat, vertical, or overhead positions."
                  },
                 {
                  title:"Safety",
                  innerText:"Operate safety equipment and use safe work habits."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
                 },
                 {
                  title:"Hand Tools",
                  innerText:"Position and secure workpieces, using hoists, cranes, wire, and banding machines or hand tools."
                 },
                 {
                  title:"Analysis",
                  innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
                 },
                 {
                  title:"Design",
                  innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
                 },
          ]
      },
      {


          jobtitle:"Production Workers",
          description:"Help production workers by performing duties requiring less skill. Duties include supplying or holding materials or tools, and cleaning work area and equipment.",
          salary:"$27,100",
          education: "",
          id_gamification:"manufac-8",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/cznEUK9d5uM",videoId:"cznEUK9d5uM"},
                      
          communicationbuttons:[
                 {
                  title:"Operate Machines",
                  innerText:"Operate machinery used in the production process, or assist machine operators."
                  },
                 {
                  title:"Analysis",
                  innerText:"Examine products to verify conformance to quality standards."
                 },
                 {
                  title:"Safety",
                  innerText:"Observe equipment operations so that malfunctions can be detected, and notify operators of any malfunctions."
                 },
                 {
                  title:"Detailed",
                  innerText:"Separate products according to weight, grade, size, or composition of materials used to produce them."
                 },
                 {
                  title:"Physical Labor",
                  innerText:"Perform minor repairs to machines, such as replacing damaged or worn parts."
                 },
                 {
                  title:"Cleanliness",
                  innerText:"Wash work areas, machines, equipment, vehicles, or products."
                 },
          ]
      },
      {


          jobtitle:"Production Manager",
          description:"Directly supervise and coordinate the activities of production and operating workers, such as inspectors, precision workers, machine setters and operators, assemblers, fabricators, and plant and system operators. Excludes team or work leaders.",
          salary:"$68,000",
          education: "",
          id_gamification:"manufac-9",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/dflNKhApmBE",videoId:"dflNKhApmBE"},
                      
          communicationbuttons:[
                 {
                  title:"Organized",
                  innerText:"Keep records of employees' attendance and hours worked."
                  },
                 {
                  title:"Detailed",
                  innerText:"Inspect materials, products, or equipment to detect defects or malfunctions."
                 },
                 {
                  title:"Communicate",
                  innerText:"Confer with other supervisors to coordinate operations and activities within or between departments."
                 },
                 {
                  title:"Training",
                  innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
                 },
                 {
                  title:"Leadership",
                  innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
                 },
                 {
                  title:"Analysis",
                  innerText:"Determine standards, budgets, production goals, and rates, based on company policies, equipment and labor availability, and workloads."
                 },
          ]
      },
      {


          jobtitle:"Industrial Machinery Mechanics",
          description:"Repair, install, adjust, or maintain industrial production and processing machinery or refinery and pipeline distribution systems. May also install, dismantle, or move machinery and heavy equipment according to plans.",
          salary:"$61,100",
          education: "",
          id_gamification:"manufac-10",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/ROJQXBuEnEY",videoId:"ROJQXBuEnEY"},
                      
          communicationbuttons:[
                 {
                  title:"Data Entry",
                  innerText:"Record parts or materials used and order or requisition new parts or materials, as necessary."
                  },
                 {
                  title:"Analyze",
                  innerText:"Observe and test the operation of machinery or equipment to diagnose malfunctions, using voltmeters or other testing devices."
                 },
                 {
                  title:"Detailed",
                  innerText:"Study blueprints or manufacturers' manuals to determine correct installation or operation of machinery."
                 },
                 {
                  title:"Welding",
                  innerText:"Cut and weld metal to repair broken metal parts, fabricate new parts, or assemble new equipment."
                 },
                 {
                  title:"Machine Usage",
                  innerText:"Repair or maintain the operating condition of industrial production or processing machinery or equipment."
                 },
                 {
                  title:"Organized",
                  innerText:"Assign schedules to work crews."
                 },
          ]
      },
      {


          jobtitle:"Paper Machine Operator",
          description:"Set up, operate, or tend paper goods machines that perform a variety of functions, such as converting, sawing, corrugating, banding, wrapping, boxing, stitching, forming, or sealing paper or paperboard sheets into products.",
          salary:"$45,000",
          education: "",
          id_gamification:"manufac-11",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/wGITtQuRcaE",videoId:"wGITtQuRcaE"},
                      
          communicationbuttons:[
                 {
                  title:"Troubleshoot",
                  innerText:"Examine completed work to detect defects and verify conformance to work orders, and adjust machinery as necessary to correct production problems."
                  },
                 {
                  title:"Analyze",
                  innerText:"Observe operation of various machines to detect and correct machine malfunctions such as improper forming, glue flow, or pasteboard tension."
                 },
                 {
                  title:"Tool Usage",
                  innerText:"Disassemble machines to maintain, repair, or replace broken or worn parts, using hand or power tools."
                 },
                 {
                  title:"Measuring",
                  innerText:"Measure, space, and set saw blades, cutters, and perforators, according to product specifications."
                 },
                 {
                  title:"Physical Labor",
                  innerText:"Lift tote boxes of finished cartons, and dump cartons into feed hoppers."
                 },
                 {
                  title:"Detailed",
                  innerText:"Fill glue and paraffin reservoirs, and position rollers to dispense glue onto paperboard."
                 },
          ]
      },
      {


          jobtitle:"Quality Control Inspector",
          description:"Inspect, test, sort, sample, or weigh nonagricultural raw materials or processed, machined, fabricated, or assembled parts or products for defects, wear, and deviations from specifications. May use precision measuring instruments and complex test equipment.",
          salary:"$46,500",
          education: "",
          id_gamification:"manufac-12",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/RCTxteXoiAA",videoId:"RCTxteXoiAA"},
                      
          communicationbuttons:[
                 {
                  title:"Analysis",
                  innerText:"Mark items with details, such as grade or acceptance-rejection status."
                  },
                 {
                  title:"Communicate",
                  innerText:"Notify supervisors or other personnel of production problems."
                 },
                 {
                  title:"Physical Labor",
                  innerText:"Make minor adjustments to equipment, such as turning setscrews to calibrate instruments to required tolerances."
                 },
                 {
                  title:"Solution Focused",
                  innerText:"Recommend necessary corrective actions, based on inspection results."
                 },
                 {
                  title:"Organized",
                  innerText:"Write test or inspection reports describing results, recommendations, or needed repairs."
                 },
                 {
                  title:"Detailed",
                  innerText:"Analyze test data, making computations as necessary, to determine test results."
                 },
          ]
      },
      {


          jobtitle:"Chemical Operator",
          description:"Operate or tend equipment to control chemical changes or reactions in the processing of industrial or consumer products. Equipment used includes devulcanizers, steam-jacketed kettles, and reactor vessels.",
          salary:"$60,000",
          education: "",
          id_gamification:"manufac-13",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/CN0N14XRJhM",videoId:"CN0N14XRJhM"},
                      
          communicationbuttons:[
                 {
                  title:"Data Entry",
                  innerText:"Record operational data, such as temperatures, pressures, ingredients used, processing times, or test results."
                  },
                 {
                  title:"Machine Usage",
                  innerText:"Control or operate equipment in which chemical changes or reactions take place during the processing of industrial or consumer products."
                 },
                 {
                  title:"Analysis",
                  innerText:"Draw samples of products at specified stages so that analyses can be performed."
                 },
                 {
                  title:"Measuring",
                  innerText:"Measure, weigh, and mix chemical ingredients, according to specifications."
                 },
                 {
                  title:"Detailed",
                  innerText:"Read plant specifications to determine products, ingredients, or prescribed modifications of plant procedures."
                 },
                 {
                  title:"Perceptive",
                  innerText:"Patrol work areas to detect leaks or equipment malfunctions or to monitor operating conditions."
                 },
          ]
      }


     ],
 
    description:{
      aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
    }
     
},
public_service:{
  mediaInfo:{
    videoType:'youtube',link:"https://www.youtube.com/watch?v=cUIAZcjjB_g&list=TLPQMjQwMzIwMjHqCZpyRvoORg",videoId:'cUIAZcjjB_g',imageOrVideo:'image',srcOrHref:'public-safety-careers'
  },
    jobs:[ 
        {
          jobtitle:'Patrol Officers',
          salary:"$45,000",
          description:"Maintain order and protect life and property by enforcing local, tribal, state, or federal laws and ordinances. Perform a combination of the following duties: patrol a specific area; direct traffic; issue traffic summonses; investigate accidents; apprehend and arrest suspects, or serve legal processes of courts. Includes police officers working at educational institutions.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'14D91NPvMz8',
          srcOrHref:''},
          education:"",
          id_gamification:"ps-1",
          communicationbuttons:[
                 {
                  title:"Apprehend Suspects",
                  innerText:"Identify, pursue, and arrest suspects and perpetrators of criminal acts."
                 },
                 {
                  title:"Public Safety",
                  innerText:"Provide for public safety by maintaining order, responding to emergencies, protecting people and property, enforcing motor vehicle and criminal laws, and promoting good community relations."
                 },
                {
                  title:"Active Listening",
                  innerText:"Record facts to prepare reports that document incidents and activities."
                 },
                 {
                  title:"First Aid",
                  innerText:"Render aid to accident victims and other persons requiring first aid for physical injuries."
                 },
                 {
                  title:"Investigate",
                  innerText:"Investigate illegal or suspicious activities."
                 },
                 {
                  title:"Weapons & Tools",
                  innerText:"Use handguns, handcuffs, police vehicles, surveillance video and audio recorders, and two-way radios."
                 },
          ]
      },
                {



          jobtitle:'Firefighters',
          salary:"$35,800",
          description:"Control and extinguish fires or respond to emergency situations where life, property, or the environment is at risk. Duties may include fire prevention, emergency medical service, hazardous material response, search and rescue, and disaster assistance.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'DNK5dYGn3Fg',
          srcOrHref:''},
          education:"",
          id_gamification:"ps-2",
          communicationbuttons:[
                 {
                  title:"Critical Thinking",
                  innerText:"Assess fires and situations and report conditions to superiors to receive instructions, using two-way radios."
                 },
                 {
                  title:"Hand Tools                   ",
                  innerText:"Create openings in buildings for ventilation or entrance, using axes, chisels, crowbars, electric saws, or core cutters."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Inspect fire sites after flames have been extinguished to ensure that there is no further danger."
                 },
                 {
                  title:"Judgement",
                  innerText:"Move toward the source of a fire, using knowledge of types of fires, construction design, building materials, and physical layout of properties."
                 },
                 {
                  title:"Collaborate",
                  innerText:"Collaborate with police to respond to accidents, disasters, and arson investigation calls."
                 },
                 {
                  title:"Physical Fitness",
                  innerText:"Participate in physical training activities to maintain a high level of physical fitness."
                 },
          ]
      },
      {


          jobtitle:"Corrections Officer",
          description:"Guard inmates in penal or rehabilitative institutions in accordance with established regulations and procedures. May guard prisoners in transit between jail, courtroom, prison, or other point. Includes deputy sheriffs and police who spend the majority of their time guarding prisoners in correctional institutions.",
          salary:"$35,800",
          education: "",
          id_gamification:"ps-3",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/Vcnk_bSJsAc",videoId:"Vcnk_bSJsAc"},
                      
          communicationbuttons:[
                 {
                  title:"Observation",
                  innerText:"Conduct head counts to ensure that each prisoner is present."
                  },
                 {
                  title:"Detail Oriented",
                  innerText:"Inspect conditions of locks, window bars, grills, doors, and gates at correctional facilities to ensure security and help prevent escapes."
                 },
                 {
                  title:"Organized",
                  innerText:"Maintain records of prisoners' identification and charges."
                 },
                 {
                  title:"Writing",
                  innerText:"Record information, such as prisoner identification, charges, and incidents of inmate disturbance, keeping daily logs of prisoner activities."
                 },
                 {
                  title:"Weapon Usage",
                  innerText:"Use weapons, handcuffs, and physical force to maintain discipline and order among prisoners."
                 },
                 {
                  title:"Physical Strength",
                  innerText:"Search for and recapture escapees."
                 },
          ]
      },
      {


          jobtitle:"Administrative Assistant",
          description:"Perform routine administrative functions such as drafting correspondence, scheduling appointments, organizing and maintaining paper and electronic files, or providing information to callers.",
          salary:"$35,500",
          education: "",
          id_gamification:"ps-4",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/zDqK9DaCDg4",videoId:"zDqK9DaCDg4"},
                      
          communicationbuttons:[
                 {
                  title:"Communicate",
                  innerText:"Answer telephones and give information to callers, take messages, or transfer calls to appropriate individuals."
                  },
                 {
                  title:"People Skills",
                  innerText:"Greet visitors or callers and handle their inquiries or direct them to the appropriate persons according to their needs."
                 },
                 {
                  title:"Data Entry",
                  innerText:"Create, maintain, and enter information into databases."
                 },
                 {
                  title:"Computer Usage",
                  innerText:"Use computers for various applications, such as database management or word processing."
                 },
                 {
                  title:"Organized",
                  innerText:"Establish work procedures or schedules and keep track of the daily work of clerical staff."
                 },
                 {
                  title:"Supervise",
                  innerText:"Supervise other clerical staff and provide training and orientation to new staff."
                 },
          ]
      },
      {


          jobtitle:"Highway Maintenance Worker",
          description:"Maintain highways, municipal and rural roads, airport runways, and rights-of-way. Duties include patching broken or eroded pavement and repairing guard rails, highway markers, and snow fences. May also mow or clear brush from along road, or plow snow from roadway.",
          salary:"$31,500",
          education: "",
          id_gamification:"ps-5",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/HJ2naWZeg-s",videoId:"HJ2naWZeg-s"},
                      
          communicationbuttons:[
                 {
                  title:"Driving",
                  innerText:"Drive trucks to transport crews and equipment to work sites."
                  },
                 {
                  title:"Tool Usage",
                  innerText:"Erect, install, or repair guardrails, road shoulders, berms, highway markers, warning signals, and highway lighting, using hand tools and power tools."
                 },
                 {
                  title:"Safety",
                  innerText:"Set out signs and cones around work areas to divert traffic."
                 },
                 {
                  title:"Physical Labor",
                  innerText:"Haul and spread sand, gravel, and clay to fill washouts and repair road shoulders."
                 },
                 {
                  title:"Landscaping",
                  innerText:"Perform roadside landscaping work, such as clearing weeds and brush, and planting and trimming trees."
                 },
                 {
                  title:"Painting",
                  innerText:"Paint traffic control lines and place pavement traffic messages, by hand or using machines."
                 },
          ]
      },
      {


          jobtitle:"Office Clerk",
          description:"Perform duties too varied and diverse to be classified in any specific office clerical occupation, requiring knowledge of office systems and procedures. Clerical duties may be assigned in accordance with the office procedures of individual establishments and may include a combination of answering telephones, bookkeeping, typing or word processing, office machine operation, and filing.",
          salary:"$25,500",
          education: "",
          id_gamification:"ps-6",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/918owLdYnts",videoId:"918owLdYnts"},
                      
          communicationbuttons:[
                 {
                  title:"Computer Usage",
                  innerText:"Operate office machines, such as photocopiers and scanners, facsimile machines, voice mail systems, and personal computers."
                  },
                 {
                  title:"Data Entry",
                  innerText:"Maintain and update filing, inventory, mailing, and database systems, either manually or using a computer."
                 },
                 {
                  title:"Organized",
                  innerText:"Compile, copy, sort, and file records of office activities, business transactions, and other activities."
                 },
                 {
                  title:"Communicate",
                  innerText:"Answer telephones, direct calls, and take messages."
                 },
                 {
                  title:"Finance",
                  innerText:"Collect, count, and disburse money, do basic bookkeeping, and complete banking transactions."
                 },
                 {
                  title:"Reporting",
                  innerText:"Process and prepare documents, such as business or government forms and expense reports."
                 },
          ]
      },
      {


          jobtitle:"City Clerk",
          description:"Perform clerical duties for courts of law, municipalities, or governmental licensing agencies and bureaus. May prepare docket of cases to be called; secure information for judges and court; prepare draft agendas or bylaws for town or city council; answer official correspondence; keep fiscal records and accounts; issue licenses or permits; and record data, administer tests, or collect fees.",
          salary:"$34,900",
          education: "",
          id_gamification:"ps-7",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/jrJPzRAEtHE",videoId:"jrJPzRAEtHE"},
                      
          communicationbuttons:[
                 {
                  title:"Detailed",
                  innerText:"Verify the authenticity of documents, such as foreign identification or immigration documents."
                  },
                 {
                  title:"Data Entry",
                  innerText:"Record and edit the minutes of meetings and distribute to appropriate officials or staff members."
                 },
                 {
                  title:"Communicate",
                  innerText:"Question applicants to obtain required information, such as name, address, or age, and record data on prescribed forms."
                 },
                 {
                  title:"Organized",
                  innerText:"Record and maintain all vital and fiscal records and accounts."
                 },
                 {
                  title:"Finance",
                  innerText:"Perform budgeting duties, such as assisting in budget preparation, expenditure review, or budget administration."
                 },
                 {
                  title:"Research",
                  innerText:"Research information in the municipal archives upon request of public officials or private citizens."
                 },
          ]
      },
      {


          jobtitle:"Dispatchers",
          description:"Operate telephone, radio, or other communication systems to receive and communicate requests for emergency assistance at 9-1-1 public safety answering points and emergency operations centers. Take information from the public and other sources regarding crimes, threats, disturbances, acts of terrorism, fires, medical emergencies, and other public safety matters. May coordinate and provide information to law enforcement and emergency response personnel.",
          salary:"$31,600",
          education: "",
          id_gamification:"ps-8",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/mFB5YEzQUfI",videoId:"mFB5YEzQUfI"},
                      
          communicationbuttons:[
                 {
                  title:"Communicate",
                  innerText:"Question callers to determine their locations, and the nature of their problems to determine type of response needed."
                  },
                 {
                  title:"Analysis",
                  innerText:"Determine response requirements and relative priorities of situations, and dispatch units in accordance with established procedures."
                 },
                 {
                  title:"Data Entry",
                  innerText:"Record details of calls, dispatches, and messages."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Test and adjust communication and alarm systems, and report malfunctions to maintenance units."
                 },
                 {
                  title:"Safety",
                  innerText:"Maintain access to, and security of, highly sensitive materials."
                 },
                 {
                  title:"Monitoring",
                  innerText:"Monitor alarm systems to detect emergencies, such as fires and illegal entry into establishments."
                 },
          ]
      },
      {


          jobtitle:"Case Manager",
          description:"Determine eligibility of persons applying to receive assistance from government programs and agency resources, such as welfare, unemployment benefits, social security, and public housing.",
          salary:"$39,800",
          education: "",
          id_gamification:"ps-9",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/WXVsjrQzx-E",videoId:"WXVsjrQzx-E"},
                      
          communicationbuttons:[
                 {
                  title:"Active Listening",
                  innerText:"Interview benefits recipients at specified intervals to certify their eligibility for continuing benefits."
                  },
                 {
                  title:"Organized",
                  innerText:"Keep records of assigned cases, and prepare required reports."
                 },
                 {
                  title:"Data Entry",
                  innerText:"Compile, record, and evaluate personal and financial data to verify completeness and accuracy, and to determine eligibility status."
                 },
                 {
                  title:"Communicate",
                  innerText:"Answer applicants' questions about benefits and claim procedures."
                 },
                 {
                  title:"Reporting",
                  innerText:"Conduct annual, interim, and special housing reviews and home visits to ensure conformance to regulations."
                 },
                 {
                  title:"Finance",
                  innerText:"Compute and authorize amounts of assistance for programs, such as grants, monetary payments, and food stamps."
                 },
          ]
      },
      {


          jobtitle:"Recreation Workers",
          description:"Conduct recreation activities with groups in public, private, or volunteer agencies or recreation facilities. Organize and promote activities, such as arts and crafts, sports, games, music, dramatics, social recreation, camping, and hobbies, taking into account the needs and interests of individual members.",
          salary:"$24,900",
          education: "",
          id_gamification:"ps-10",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/e33-81TNf8E",videoId:"e33-81TNf8E"},
                      
          communicationbuttons:[
                 {
                  title:"Authority",
                  innerText:"Enforce rules and regulations of recreational facilities to maintain discipline and ensure safety."
                  },
                 {
                  title:"Medical",
                  innerText:"Administer first aid according to prescribed procedures and notify emergency medical personnel when necessary."
                 },
                 {
                  title:"Event Planning",
                  innerText:"Direct special activities or events, such as aquatics, gymnastics, or performing arts."
                 },
                 {
                  title:"Social Skills",
                  innerText:"Greet new arrivals to activities, introducing them to other participants, explaining facility rules, and encouraging participation."
                 },
                 {
                  title:"Project Manage",
                  innerText:"Oversee the purchase, planning, design, construction, and upkeep of recreation facilities and areas."
                 },
                 {
                  title:"Analysis",
                  innerText:"Evaluate recreation areas, facilities, and services to determine if they are producing desired results."
                 },
          ]
      }

     ],
 
    description:{
      aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
    }
     
},
safety:{
  mediaInfo:{
    videoType:'youtube',link:"https://www.youtube.com/watch?v=cUIAZcjjB_g&list=TLPQMjQwMzIwMjHqCZpyRvoORg",videoId:'cUIAZcjjB_g',imageOrVideo:'image',srcOrHref:'safety-careers'
  },
    jobs:[ 
        {
          jobtitle:'Occupational Safety & Health',
          salary:"$46,000 - $104,000",
          description:"Collect data on work environments for analysis by occupational health and safety specialists. Implement and conduct evaluation of programs designed to limit chemical, physical, biological, and ergonomic risks to workers.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'8qj18Q4EhUQ',
          srcOrHref:''},
          education:"",
          id_gamification:"safety-1",
          communicationbuttons:[
                 {
                  title:"Quality Control",
                  innerText:"Test workplaces for environmental hazards, such as exposure to radiation, chemical or biological hazards, or excessive noise."
                 },
                 {
                  title:"Cleanliness",
                  innerText:"Prepare or calibrate equipment used to collect or analyze samples."
                 },
                {
                  title:"Analysis",
                  innerText:"Conduct worker studies to determine whether specific instances of disease or illness are job-related."
                 },
                 {
                  title:"Problem Solving",
                  innerText:"Recommend corrective measures to be applied based on results of environmental contaminant analyses."
                 },
                 {
                  title:"Organized",
                  innerText:"Prepare documents to be used in legal proceedings, testifying in such proceedings when necessary."
                 },
                 {
                  title:"Instructing",
                  innerText:"Train workers in safety procedures related to green jobs, such as the use of fall protection devices or maintenance of proper ventilation during wind turbine construction."
                 },
          ]
      },
                {



          jobtitle:'Construction Safety Specialist',
          salary:"$46,000 - $104,000",
          description:"Inspect structures using engineering skills to determine structural soundness and compliance with specifications, building codes, and other regulations. Inspections may be general in nature or may be limited to a specific area, such as electrical systems or plumbing.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',

          videoId:'PgnWqyol0Pk',
          srcOrHref:''},
          education:"",
          id_gamification:"safety-2",
          communicationbuttons:[
                 {
                  title:"Observant",
                  innerText:"Monitor installation of plumbing, wiring, equipment, or appliances to ensure that installation is performed properly and is in compliance with applicable regulations."
                 },
                 {
                  title:"Organized",
                  innerText:"Maintain daily logs and supplement inspection records with photographs."
                 },
                 {
                  title:"Equipment Usage",
                  innerText:"Conduct inspections, using survey instruments, metering devices, tape measures, or test equipment."
                 },
                 {
                  title:"Detail Oriented",
                  innerText:"Review and interpret plans, blueprints, site layouts, specifications, or construction methods to ensure compliance to legal requirements and safety regulations."
                 },
                 {
                  title:"Cleanliness",
                  innerText:"Evaluate premises for cleanliness, such as proper garbage disposal or lack of vermin infestation."
                 },
                 {
                  title:"Communicate",
                  innerText:"Confer with owners, violators, or authorities to explain regulations or recommend remedial actions."
                 },
          ]
      },
      {


          jobtitle:"Industrial Hygienist",
          description:"Promote worksite or product safety by applying knowledge of industrial processes, mechanics, chemistry, psychology, and industrial health and safety laws. Includes industrial product safety engineers.",
          salary:"$46,000 - $104,000",
          education: "",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"Z9gbU7KPAOg"},
          id_gamification:"safety-3",
          communicationbuttons:[
                 {
                  title:"Analysis",
                  innerText:"Conduct research to evaluate safety levels for products."
                  },
                 {
                  title:"Instructing",
                  innerText:"Conduct or coordinate worker training in areas such as safety laws and regulations, hazardous condition monitoring, and use of safety equipment."
                 },
                 {
                  title:"Active Learning",
                  innerText:"Maintain and apply knowledge of current policies, regulations, and industrial processes."
                 },
                 {
                  title:"Communicate",
                  innerText:"Report or review findings from accident investigations, facilities inspections, or environmental testing."
                 },
                 {
                  title:"Active Listening",
                  innerText:"Interview employers and employees to obtain information about work environments and workplace incidents."
                 },
                 {
                  title:"Organized",
                  innerText:"Compile, analyze, and interpret statistical data related to occupational illnesses and accidents."
                 },
          ]
      }

     ],
 
    description:{
      aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
    }
     
},
shipbuilding_and_repair:{
  mediaInfo:{
    videoType:'youtube',link:"https://www.youtube.com/watch?v=cUIAZcjjB_g&list=TLPQMjQwMzIwMjHqCZpyRvoORg",videoId:'E1t39-yp6YQ',imageOrVideo:'video',srcOrHref:'aerospace-careers'
  },
    jobs:[ 
        {
          jobtitle:'Marine Engineer',
          salary:"$85,700",
          description:"Design, develop, and evaluate the operation of marine vessels, ship machinery, and related equipment, such as power supply and propulsion systems.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'445nfaA6gdI',
          srcOrHref:''},
          education:"",
          id_gamification:"sar-1",
          communicationbuttons:[
                 {
                  title:"Design",
                  innerText:"Design complete hull and superstructure according to specifications and test data, in conformity with standards of safety, efficiency, and economy."
                 },
                 {
                  title:"Supervise",
                  innerText:"Supervise other engineers and crew members and train them for routine and emergency duties."
                 },
                {
                  title:"Analysis",
                  innerText:"Study design proposals and specifications to establish basic characteristics of craft, such as size, weight, speed, propulsion, displacement, and draft."
                 },
                 {
                  title:"Problem Solving",
                  innerText:"Evaluate performance of craft during dock and sea trials to determine design changes and conformance with national and international standards."
                 },
                 {
                  title:"Organized",
                  innerText:"Prepare plans, estimates, design and construction schedules, and contract specifications, including any special provisions."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Conduct environmental, operational, or performance tests on marine machinery and equipment."
                 },
          ]
      },
                {



          jobtitle:'Welder',
          salary:"$53,200",
          description:"Use hand-welding, flame-cutting, hand-soldering, or brazing equipment to weld or join metal components or to fill holes, indentations, or seams of fabricated metal products.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'Ydr62_uFPrA',
          srcOrHref:''},
          education:"",
          id_gamification:"sar-2",
          communicationbuttons:[
                 {
                  title:"Welding",
                  innerText:"Weld components in flat, vertical, or overhead positions."
                 },
                 {
                  title:"Safety",
                  innerText:"Operate safety equipment and use safe work habits."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
                 },
                 {
                  title:"Hand Tools",
                  innerText:"Position and secure workpieces, using hoists, cranes, wire, and banding machines or hand tools."
                 },
                 {
                  title:"Analysis",
                  innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
                 },
                 {
                  title:"Design",
                  innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
                 },
          ]
      },
      {


          jobtitle:"Carpenter",
          description:"Construct, erect, install, or repair structures and fixtures made of wood and comparable materials, such as concrete forms; building frameworks, including partitions, joists, studding, and rafters; and wood stairways, window and door frames, and hardwood floors. May also install cabinets, siding, drywall, and batt or roll insulation. Includes brattice builders who build doors or brattices (ventilation walls or partitions) in underground passageways.",
          salary:"$23,500 - $55,000",
          education: "",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"MWfr-7hUQ8c"},
          id_gamification:"sar-3",           
          communicationbuttons:[
                 {
                  title:"Safety",
                  innerText:"Follow established safety rules and regulations and maintain a safe and clean environment."
                  },
                 {
                  title:"Analysis",
                  innerText:"Study specifications in blueprints, sketches, or building plans to prepare project layout and determine dimensions and materials required."
                 },
                 {
                  title:"Measuring",
                  innerText:"Measure and mark cutting lines on materials, using a ruler, pencil, chalk, and marking gauge."
                 },
                 {
                  title:"Hand Tools",
                  innerText:"Shape or cut materials to specified measurements, using hand tools, machines, or power saws."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Verify trueness of structure, using plumb bob and level."
                 },
                 {
                  title:"Organization",
                  innerText:"Maintain records, document actions, and present written progress reports."
                 },
          ]
      },
      {


          jobtitle:"Plumbers, Pipefitters, and Steamfitters",
          description:"Assemble, install, alter, and repair pipelines or pipe systems that carry water, steam, air, or other liquids or gases. May install heating and cooling equipment and mechanical control systems. Includes sprinkler fitters.",
          salary:"$51,500",
          education: "",
          id_gamification:"sar-4",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/uF3-2wFnf0E",videoId:"uF3-2wFnf0E"},
                      
          communicationbuttons:[
                 {
                  title:"Hand Tools",
                  innerText:"Cut, thread, or hammer pipes to specifications, using tools such as saws, cutting torches, pipe threaders, or pipe benders."
                  },
                 {
                  title:"Organized",
                  innerText:"Lay out full scale drawings of pipe systems, supports, or related equipment, according to blueprints."
                 },
                 {
                  title:"Analysis",
                  innerText:"Inspect, examine, or test installed systems or pipe lines, using pressure gauge, hydrostatic testing, observation, or other methods."
                 },
                 {
                  title:"Supervise",
                  innerText:"Direct helpers engaged in pipe cutting, preassembly, or installation of plumbing systems or components."
                 },
                 {
                  title:"Welding",
                  innerText:"Weld small pipes or special piping, using specialized techniques, equipment, or materials, such as computer-assisted welding or microchip fabrication."
                 },
                 {
                  title:"Organized",
                  innerText:"Estimate time, material, or labor costs for use in project plans."
                 },
          ]
      },
      {


          jobtitle:"Team Assemblers",
          description:"Work as part of a team having responsibility for assembling an entire product or component of a product. Team assemblers can perform all tasks conducted by the team in the assembly process and rotate through all or most of them, rather than being assigned to a specific task on a permanent basis. May participate in making management decisions affecting the work. Includes team leaders who work as part of the team.",
          salary:"$44,500",
          education: "",
          id_gamification:"sar-5",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/TSn6ghApPKM",videoId:"TSn6ghApPKM"},
                      
          communicationbuttons:[
                 {
                  title:"Welding",
                  innerText:"Weld components in flat, vertical, or overhead positions."
                  },
                 {
                  title:"Safety",
                  innerText:"Operate safety equipment and use safe work habits."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Examine workpieces for defects and measure workpieces with straightedges or templates to ensure conformance with specifications."
                 },
                 {
                  title:"Hand Tools",
                  innerText:"Position and secure workpieces, using hoists, cranes, wire, and banding machines or hand tools."
                 },
                 {
                  title:"Analysis",
                  innerText:"Analyze engineering drawings, blueprints, specifications, sketches, work orders, and material safety data sheets to plan layout, assembly, and operations."
                 },
                 {
                  title:"Design",
                  innerText:"Develop templates and models for welding projects, using mathematical calculations based on blueprint information."
                 },
          ]
      },
      {


          jobtitle:"Electrician",
          description:"Install, maintain, and repair electrical wiring, equipment, and fixtures. Ensure that work is in accordance with relevant codes. May install or service street lights, intercom systems, or electrical control systems.",
          salary:"$51,600",
          education: "",
          id_gamification:"sar-6",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/l6IWPbx4ZXw",videoId:"l6IWPbx4ZXw"},
                      
          communicationbuttons:[
                 {
                  title:"Critical Analysis",
                  innerText:"Plan layout and installation of electrical wiring, equipment, or fixtures, based on job specifications and local codes."
                  },
                 {
                  title:"Design",
                  innerText:"Prepare sketches or follow blueprints to determine the location of wiring or equipment and to ensure conformance to building and safety codes."
                 },
                 {
                  title:"Troubleshoot",
                  innerText:"Diagnose malfunctioning systems, apparatus, or components, using test equipment and hand tools to locate the cause of a breakdown and correct the problem."
                 },
                 {
                  title:"Instructing",
                  innerText:"Advise management on whether continued operation of equipment could be hazardous."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Inspect electrical systems, equipment, or components to identify hazards, defects, or the need for adjustment or repair, and to ensure compliance with codes."
                 },
                 {
                  title:"Hand Tools",
                  innerText:"Repair or replace wiring, equipment, or fixtures, using hand tools or power tools."
                 },
          ]
      },
      {


          jobtitle:"Production Manager",
          description:"Directly supervise and coordinate the activities of production and operating workers, such as inspectors, precision workers, machine setters and operators, assemblers, fabricators, and plant and system operators. Excludes team or work leaders.",
          salary:"$73,300",
          education: "",
          id_gamification:"sar-7",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/dflNKhApmBE",videoId:"dflNKhApmBE"},
                      
          communicationbuttons:[
                 {
                  title:"Organized",
                  innerText:"Keep records of employees' attendance and hours worked."
                  },
                 {
                  title:"Detailed",
                  innerText:"Inspect materials, products, or equipment to detect defects or malfunctions."
                 },
                 {
                  title:"Communicate",
                  innerText:"Confer with other supervisors to coordinate operations and activities within or between departments."
                 },
                 {
                  title:"Training",
                  innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
                 },
                 {
                  title:"Leadership",
                  innerText:"Conduct employee training in equipment operations or work and safety procedures, or assign employee training to experienced workers."
                 },
                 {
                  title:"Analysis",
                  innerText:"Determine standards, budgets, production goals, and rates, based on company policies, equipment and labor availability, and workloads."
                 },
          ]
      },
      {


          jobtitle:"Precision Machinist",
          description:"Set up and operate a variety of machine tools to produce precision parts and instruments out of metal. Includes precision instrument makers who fabricate, modify, or repair mechanical instruments. May also fabricate and modify parts to make or repair machine tools or maintain industrial machines, applying knowledge of mechanics, mathematics, metal properties, layout, and machining procedures.",
          salary:"$52,400",
          education: "",
          id_gamification:"sar-8",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/OWNXI09WI14",videoId:"OWNXI09WI14"},
                      
          communicationbuttons:[
                 {
                  title:"Accuracy",
                  innerText:"Calculate dimensions or tolerances, using instruments, such as micrometers or vernier calipers."
                  },
                 {
                  title:"Monitor",
                  innerText:"Monitor the feed and speed of machines during the machining process."
                 },
                 {
                  title:"Analysis",
                  innerText:"Study sample parts, blueprints, drawings, or engineering information to determine methods or sequences of operations needed to fabricate products."
                 },
                 {
                  title:"Organize",
                  innerText:"Separate scrap waste and related materials for reuse, recycling, or disposal."
                 },
                 {
                  title:"Design",
                  innerText:"Design fixtures, tooling, or experimental parts to meet special engineering needs."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Maintain machine tools in proper operational condition."
                 },
          ]
      },
      {


          jobtitle:"Painter",
          description:"Paint walls, equipment, buildings, bridges, and other structural surfaces, using brushes, rollers, and spray guns. May remove old paint to prepare surface prior to painting. May mix colors or oils to obtain desired color or consistency.",
          salary:"$47,900",
          education: "",
          id_gamification:"sar-9",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/MhpyAMBkwIQ",videoId:"MhpyAMBkwIQ"},
                      
          communicationbuttons:[
                 {
                  title:"Detailed",
                  innerText:"Fill cracks, holes, or joints with caulk, putty, plaster, or other fillers, using caulking guns or putty knives."
                  },
                 {
                  title:"Cautious",
                  innerText:"Cover surfaces with dropcloths or masking tape and paper to protect surfaces during painting."
                 },
                 {
                  title:"Finishing",
                  innerText:"Smooth surfaces, using sandpaper, scrapers, brushes, steel wool, or sanding machines."
                 },
                 {
                  title:"Painting",
                  innerText:"Apply primers or sealers to prepare new surfaces, such as bare wood or metal, for finish coats."
                 },
                 {
                  title:"Physical Labor",
                  innerText:"Erect scaffolding or swing gates, or set up ladders, to work above ground level."
                 },
                 {
                  title:"Artistic",
                  innerText:"Mix and match colors of paint, stain, or varnish with oil or thinning and drying additives to obtain desired colors and consistencies."
                 },
                
          ]
      },
      {


          jobtitle:"Fabricator",
          description:"Fabricate, position, align, and fit parts of structural metal products.",
          salary:"$46,000",
          education: "",
          id_gamification:"sar-10",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/Pe3n9-r8TP0",videoId:"Pe3n9-r8TP0"},
                      
          communicationbuttons:[
                 {
                  title:"Measuring",
                  innerText:"Verify conformance of workpieces to specifications, using squares, rulers, and measuring tapes."
                  },
                 {
                  title:"Analyze",
                  innerText:"Study engineering drawings and blueprints to determine materials requirements and task sequences."
                 },
                 {
                  title:"Detailed",
                  innerText:"Remove high spots and cut bevels, using hand files, portable grinders, and cutting torches."
                 },
                 {
                  title:"Design",
                  innerText:"Design and construct templates and fixtures, using hand tools."
                 },
                 {
                  title:"Tool Usage",
                  innerText:"Hammer, chip, and grind workpieces to cut, bend, and straighten metal."
                 },
                 {
                  title:"Welding",
                  innerText:"Tack-weld fitted parts together."
                 },
          ]
      },
      {


          jobtitle:"Layout Workers, Metal and Plastic",
          description:"Lay out reference points and dimensions on metal or plastic stock or workpieces, such as sheets, plates, tubes, structural shapes, castings, or machine parts, for further processing. Includes shipfitters.",
          salary:"$41,900",
          education: "",
          id_gamification:"sar-11",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/176-HGCTLqE",videoId:"176-HGCTLqE"},
                      
          communicationbuttons:[
                 {
                  title:"Measuring",
                  innerText:"Locate center lines and verify template positions, using measuring instruments such as gauge blocks, height gauges, and dial indicators."
                  },
                 {
                  title:"Analyze",
                  innerText:"Add dimensional details to blueprints or drawings made by other workers."
                 },
                 {
                  title:"Physical Labor",
                  innerText:"Lift and position workpieces in relation to surface plates, manually or with hoists, and using parallel blocks and angle plates."
                 },
                 {
                  title:"Design",
                  innerText:"Design and prepare templates of wood, paper, or metal."
                 },
                 {
                  title:"Tool Usage",
                  innerText:"Mark curves, lines, holes, dimensions, and welding symbols onto workpieces, using scribes, soapstones, punches, and hand drills."
                 },
                 {
                  title:"Drafting",
                  innerText:"Plan and develop layouts from blueprints and templates, applying knowledge of trigonometry, design, effects of heat, and properties of metals."
                 },
          ]
      }

     ],
 
    description:{
      aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
    }
     
},
transportation_distribution_and_logistics:{
  mediaInfo:{
    videoType:'youtube',link:"https://www.youtube.com/watch?v=cUIAZcjjB_g&list=TLPQMjQwMzIwMjHqCZpyRvoORg",videoId:'w0wDnQP1dn8',imageOrVideo:'video',srcOrHref:'aerospace-careers'
  },
    jobs:[ 
        {
          jobtitle:'Truck Drivers',
          salary:"$40,100",
          description:"Drive a tractor-trailer combination or a truck with a capacity of at least 26,001 pounds Gross Vehicle Weight (GVW). May be required to unload truck. Requires commercial drivers' license. Includes tow truck drivers.",
          jobMedia:{imageOrVideo:'video',
          videoType:'youtube',
          videoId:'yz8Oku9fQfQ',
          srcOrHref:''},
          education:"",
          id_gamification:"tdal-1",
          communicationbuttons:[
                 {
                  title:"Quality Control",
                  innerText:"Check vehicles to ensure that mechanical, safety, and emergency equipment is in good working order."
                 },
                 {
                  title:"Organization",
                  innerText:"Maintain logs of working hours or of vehicle service or repair status, following applicable state and federal regulations."
                 },
                {
                  title:"Detail Oriented",
                  innerText:"Check all load-related documentation for completeness and accuracy."
                 },
                 {
                  title:"Vehicle Operation",
                  innerText:"Maneuver trucks into loading or unloading positions, following signals from loading crew and checking that vehicle and loading equipment are properly positioned."
                 },
                 {
                  title:"Vehicle Maintenance",
                  innerText:"Perform basic vehicle maintenance tasks, such as adding oil, fuel, or radiator fluid or performing minor repairs."
                 },
                 {
                  title:"Critical Thinking",
                  innerText:"Plan or adjust routes based on changing conditions, using computer equipment, global positioning systems (GPS) equipment, or other navigation devices, to minimize fuel consumption and carbon emissions."
                 },
          ]
      },
      {


          jobtitle:"Crane Operator",
          description:"Operate mechanical boom and cable or tower and cable equipment to lift and move materials, machines, or products in many directions.",
          salary:"$37,000 - $63,000",
          education: "",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",videoId:"t1fclgajTEs"
          },
          id_gamification:"tdal-3",      
          communicationbuttons:[
                 {
                  title:"Judgement ",
                  innerText:"Determine load weights and check them against lifting capacities to prevent overload."
                  },
                 {
                  title:"Machine Operation",
                  innerText:"Move levers, depress foot pedals, or turn dials to operate cranes, cherry pickers, electromagnets, or other moving equipment for lifting, moving, or placing loads."
                 },
                 {
                  title:"Quality Control",
                  innerText:"Inspect and adjust crane mechanisms or lifting accessories to prevent malfunctions or damage."
                 },
                 {
                  title:"Maintenance",
                  innerText:"Inspect cables or grappling devices for wear and install or replace cables, as needed."
                 },
                 {
                  title:"Teamwork",
                  innerText:"Direct helpers engaged in placing blocking or outrigging under cranes."
                 },
                 {
                  title:"Troubleshoot",
                  innerText:"Clean, lubricate, and maintain mechanisms such as cables, pulleys, or grappling devices, making repairs, as necessary."
                 },
          ]
      },
      {


          jobtitle:"Bus Driver",
          description:"Drive bus or motor coach, including regular route operations, charters, and private carriage. May assist passengers with baggage. May collect fares or tickets.",
          salary:"$20,300",
          education: "",
          id_gamification:"tdal-4",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/52b8l3plMBE",videoId:"52b8l3plMBE"},
                      
          communicationbuttons:[
                 {
                  title:"Driving",
                  innerText:"Park vehicles at loading areas so that passengers can board."
                  },
                 {
                  title:"Detailed",
                  innerText:"Inspect vehicles and check gas, oil, and water levels prior to departure."
                 },
                 {
                  title:"Physical Labor",
                  innerText:"Assist passengers, such as elderly or disabled individuals, on and off bus"
                 },
                 {
                  title:"Safety",
                  innerText:"Handle passenger emergencies or disruptions."
                 },
                 {
                  title:"Data Entry",
                  innerText:"Record information, such as cash receipts and ticket fares, and maintain log book."
                 },
                 {
                  title:"Cleaning",
                  innerText:"Maintain cleanliness of bus or motor coach."
                 },
          ]
      },
      {


          jobtitle:"Mail Carrier",
          description:"Sort and deliver mail for the United States Postal Service (USPS). Deliver mail on established route by vehicle or on foot. Includes postal service mail carriers employed by USPS contractors.",
          salary:"$51,500",
          education: "",
          id_gamification:"tdal-5",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/ddvVveMJ2mw",videoId:"ddvVveMJ2mw"},
                      
          communicationbuttons:[
                 {
                  title:"Detailed",
                  innerText:"Sort mail for delivery, arranging it in delivery sequence."
                  },
                 {
                  title:"Responsible",
                  innerText:"Meet schedules for the collection and return of mail."
                 },
                 {
                  title:"Organized",
                  innerText:"Turn in money and receipts collected along mail routes."
                 },
                 {
                  title:"Physical Labor",
                  innerText:"Deliver mail to residences and business establishments along specified routes."
                 },
                 {
                  title:"Analysis",
                  innerText:"Return incorrectly addressed mail to senders."
                 },
                 {
                  title:"Data Entry",
                  innerText:"Enter change of address orders into computers that process forwarding address stickers."
                 },
          ]
      },
      {


          jobtitle:"Delivery Driver",
          description:"Drive a light vehicle, such as a truck or van, with a capacity of less than 26,001 pounds Gross Vehicle Weight (GVW), primarily to pick up merchandise or packages from a distribution center and deliver. May load and unload vehicle.",
          salary:"$41,900",
          education: "",
          id_gamification:"tdal-6",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/nhDMoNCrniA",videoId:"nhDMoNCrniA"},
                      
          communicationbuttons:[
                 {
                  title:"Safety",
                  innerText:"Obey traffic laws and follow established traffic and transportation procedures."
                  },
                 {
                  title:"Organized",
                  innerText:"Turn in receipts and money received from deliveries."
                 },
                 {
                  title:"Navigation",
                  innerText:"Read maps and follow written or verbal geographic directions."
                 },
                 {
                  title:"Detailed",
                  innerText:"Verify the contents of inventory loads against shipping papers."
                 },
                 {
                  title:"Driving",
                  innerText:"Drive vehicles with capacities under three tons to transport materials to and from specified destinations."
                 },
                 {
                  title:"Data Entry",
                  innerText:"Maintain records, such as vehicle logs, records of cargo, or billing statements, in accordance with regulations."
                 },
          ]
      },
      {


          jobtitle:"Forklift Driver",
          description:"Operate industrial trucks or tractors equipped to move materials around a warehouse, storage yard, factory, construction site, or similar location.",
          salary:"$35,200",
          education: "",
          id_gamification:"tdal-7",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/0RYX43tWmw0",videoId:"0RYX43tWmw0"},
                      
          communicationbuttons:[
                 {
                  title:"Driving",
                  innerText:"Move controls to drive gasoline- or electric-powered trucks, cars, or tractors and transport materials between loading, processing, and storage areas."
                  },
                 {
                  title:"Physical Labor",
                  innerText:"Manually or mechanically load or unload materials from pallets, skids, platforms, cars, lifting devices, or other transport vehicles."
                 },
                 {
                  title:"Safety",
                  innerText:"Position lifting devices under, over, or around loaded pallets, skids, or boxes and secure material or products for transport to designated areas."
                 },
                 {
                  title:"Analysis",
                  innerText:"Inspect product load for accuracy and safely move it around the warehouse or facility to ensure timely and complete delivery."
                 },
                 {
                  title:"Detailed",
                  innerText:"Weigh materials or products and record weight or other production data on tags or labels."
                 },
                 {
                  title:"Communicate",
                  innerText:"Signal workers to discharge, dump, or level materials."
                 },
          ]
      },
      {


          jobtitle:"Cargo Supervisor",
          description:"Supervise and coordinate the activities of ground crew in the loading, unloading, securing, and staging of aircraft cargo or baggage. May determine the quantity and orientation of cargo and compute aircraft center of gravity. May accompany aircraft as member of flight crew and monitor and handle cargo in flight, and assist and brief passengers on safety and emergency procedures. Includes loadmasters.",
          salary:"$53,300",
          education: "",
          id_gamification:"tdal-8",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/1KyVS0PrQZM",videoId:"1KyVS0PrQZM"},
                      
          communicationbuttons:[
                 {
                  title:"Math & Physics",
                  innerText:"Determine the quantity and orientation of cargo, and compute an aircraft's center of gravity."
                  },
                 {
                  title:"Communicate",
                  innerText:"Direct ground crews in the loading, unloading, securing, or staging of aircraft cargo or baggage."
                 },
                 {
                  title:"Training",
                  innerText:"Train new employees in areas such as safety procedures or equipment operation."
                 },
                 {
                  title:"Computer Usage",
                  innerText:"Calculate load weights for different aircraft compartments, using charts and computers."
                 },
                 {
                  title:"Travel",
                  innerText:"Accompany aircraft as a member of the flight crew to monitor and handle cargo in flight."
                 },
                 {
                  title:"Analysis",
                  innerText:"Distribute cargo to maximize use of space."
                 },
          ]
      },
      {


          jobtitle:"Stocker",
          description:"Receive, store, and issue merchandise, materials, equipment, and other items from stockroom, warehouse, or storage yard to fill shelves, racks, tables, or customers' orders. May operate power equipment to fill orders. May mark prices on merchandise and set up sales displays.",
          salary:"$29,400",
          education: "",
          id_gamification:"tdal-9",
          jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/sgCea-gpmCo",videoId:"sgCea-gpmCo"},
                      
          communicationbuttons:[
                 {
                  title:"Organize",
                  innerText:"Store items in an orderly and accessible manner in warehouses, tool rooms, supply rooms, or other areas."
                  },
                 {
                  title:"Detailed",
                  innerText:"Examine and inspect stock items for wear or defects, reporting any damage to supervisors."
                 },
                 {
                  title:"Social Skills",
                  innerText:"Answer customers' questions about merchandise and advise customers on merchandise selection."
                 },
                 {
                  title:"Data Entry",
                  innerText:"Receive and count stock items, and record data manually or on computer."
                 },
                 {
                  title:"Physical Labor",
                  innerText:"Stock shelves, racks, cases, bins, and tables with new or transferred merchandise."
                 },
                 {
                  title:"Driving",
                  innerText:"Operate equipment such as forklifts."
                 },
          ]
      },
      {



jobtitle:'Diesel Mechanic',
salary:"$45,700",
description:"Diagnose, adjust, repair, or overhaul buses and trucks, or maintain and repair any type of diesel engines. Includes mechanics working primarily with automobile or marine diesel engines.",
jobMedia:{imageOrVideo:'video',
videoType:'youtube',
videoId:'-PcpkHT8xaU',
srcOrHref:''},
education:"",
id_gamification:"tdal-2",
communicationbuttons:[
       {
        title:"Vehicle Operation",
        innerText:"Test drive trucks and buses to diagnose malfunctions or to ensure that they are working properly."
       },
       {
        title:"Engine Repair",
        innerText:"Recondition and replace parts, pistons, bearings, gears, and valves."
       },
       {
        title:"Vehicle Maintenance",
        innerText:"Perform routine maintenance such as changing oil, checking batteries, and lubricating equipment and machinery."
       },
       {
        title:"Critical Thinking",
        innerText:"Inspect, test, and listen to defective equipment to diagnose malfunctions, using test instruments such as handheld computers, motor analyzers, chassis charts, or pressure gauges."
       },
       {
        title:"Measure",
        innerText:"Measure vehicle emissions to determine whether they are within acceptable limits."
       },
       {
        title:"Electrical",
        innerText:"Rewire ignition systems, lights, and instrument panels"
       },
]
},
{


jobtitle:"Cargo & Freight Agent",
description:"Expedite and route movement of incoming and outgoing cargo and freight shipments in airline, train, and trucking terminals and shipping docks. Take orders from customers and arrange pickup of freight and cargo for delivery to loading platform. Prepare and examine bills of lading to determine shipping charges and tariffs.",
salary:"$49,400",
education: "",
id_gamification:"tdal-10",
jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/V1mFGglgbBE",videoId:"V1mFGglgbBE"},
            
communicationbuttons:[
       {
        title:"Analysis",
        innerText:"Determine method of shipment and prepare bills of lading, invoices, and other shipping documents."
        },
       {
        title:"Logistics",
        innerText:"Track delivery progress of shipments."
       },
       {
        title:"Physical Labor",
        innerText:"Install straps, braces, and padding to loads to prevent shifting or damage during shipment."
       },
       {
        title:"Organized",
        innerText:"Keep records of all goods shipped, received, and stored."
       },
       {
        title:"Data Entry",
        innerText:"Enter shipping information into a computer by hand or by a hand-held scanner that reads bar codes on goods."
       },
       {
        title:"Communicate",
        innerText:"Notify consignees, passengers, or customers of freight or baggage arrival and arrange for delivery."
       },
]
}

     ],
 
    description:{
      aerospace:'Over 300 aerospace and aviation companies from more than 30 different countries have chosen Alabama, including industry giants such as Boeing, Lockheed Martin, GE Aviation, Raytheon, Collins Aerospace and GKN Aerospace. Another of these giants is Airbus, which produces A320 Family passenger jets at its only U.S. manufacturing facility in Mobile, where it is also adding a second production line for A220 aircraft. Learn about a few of the possible careers in this world.'
    }
     
},
}

